import Vue from 'vue';
import VideoSidePanel from '@/components/video/VideoSidePanel.vue';
import VideoPlayerAdmin from '@/components/video/player/VideoPlayerAdmin.vue';
import { mapGetters } from 'vuex';
import { startWith, map } from 'rxjs/operators';
import { VideoRoutes } from '@/router/router';
import { Role } from '@lumiere/db/types';
import { MUTATIONS } from '@/store/store.mutations';
import publicDB from '@/utils/publicDB';
import pickBy from 'lodash/pickBy';
import { SPACEBAR_KEYCODE, SPACEBAR_KEY } from '@lumiere/shared/utils/constants';
export default Vue.extend({
    props: {
        workspaceId: { type: String, required: true },
        videoId: { type: String, required: true },
        videoProp: Object,
    },
    data() {
        return {
            isAbilityResolved: !!this.$ability.relevantRuleFor('read', 'Video.File'),
            visitedChannelId: null,
            activeTab: null,
            snackbarKeyMap: {},
            video: this.$props.videoProp,
            features: null,
            unwatchVideo: null,
        };
    },
    computed: {
        ...mapGetters(['currentWorkspace', 'userRoleInWorkspace']),
        videoRoutes() {
            const userRole = this.userRoleInWorkspace;
            if (!this.isAbilityResolved) {
                // when ability is not yet resolved, grant the user the least possible routes;
                // what a REVIEWER role can access. (VIEWERs are redirected to viewer-app)
                if (userRole !== Role.VIEWER) {
                    return [VideoRoutes.Insights];
                }
            }
            if (this.$ability.can('manage', 'Video')) {
                return [VideoRoutes.Insights, VideoRoutes.Features];
            }
            else {
                if (this.$ability.can('read', 'Video') || this.isPublic) {
                    return [VideoRoutes.Insights];
                }
                return [];
            }
        },
        isPublic() {
            return !!this.$route.query.link;
        },
    },
    methods: {
        showTabComponent(routeName) {
            if (this.isPublic)
                return true;
            const canSeeTab = this.videoRoutes.includes(routeName);
            if (!canSeeTab) {
                const wid = this.currentWorkspace.id;
                // eslint-disable-next-line @typescript-eslint/no-floating-promises
                this.$router.push({
                    name: VideoRoutes.Insights,
                    params: { videoId: this.videoId, workspaceId: wid },
                });
            }
            return canSeeTab;
        },
        dismissSnackbar(k) {
            const snackbarKeyMap = { ...this.snackbarKeyMap };
            snackbarKeyMap[k].snackbar = false;
            this.snackbarKeyMap = snackbarKeyMap;
        },
        onkeydownPressed(e) {
            if ((e.key == SPACEBAR_KEY || e.keyCode == SPACEBAR_KEYCODE) &&
                e.target == document.body) {
                e.preventDefault();
            }
        },
        watchVideoHydration() {
            this.unwatchVideo = this.$watch('video', async (video) => {
                if (video?.title && this.unwatchVideo) {
                    this.unwatchVideo();
                    const ctx = await this.$auditLogger(this.$store.state.uid);
                    if (ctx) {
                        const { Asset_Model, EVENT_ACTION, ts } = ctx.config;
                        const asset = {
                            id: this.videoId,
                            type: Asset_Model.Video,
                            name: video.title,
                        };
                        await ctx.exec(asset, {
                            timestamp: ts(),
                            action: EVENT_ACTION.VIEWED,
                            data: {
                                type: 'INFO',
                                payload: {
                                    message: `Video management page accessed/viewed`,
                                    extraInfo: {
                                        isSharedPage: this.isPublic,
                                    },
                                },
                            },
                        });
                    }
                }
            }, {
                immediate: true,
            });
        },
    },
    watch: {
        videoRoutes(routes) {
            const activeRouteName = this.$route.name;
            if (routes.includes(activeRouteName)) {
                this.activeTab = this.$route.path;
            }
        },
        snackbarKeyMap: {
            handler(state) {
                var dismissedSnackbars = pickBy(state, (item) => !item.snackbar);
                for (let itemKey in dismissedSnackbars) {
                    delete this.snackbarKeyMap[itemKey];
                }
            },
            deep: true,
        },
    },
    created() {
        this.watchVideoHydration();
    },
    mounted() {
        if (!this.isAbilityResolved) {
            const unsubscribe = this.$ability.on('updated', (_res) => {
                this.isAbilityResolved = true;
                unsubscribe();
            });
        }
        this.$store.commit(MUTATIONS.CLEAR_VIDEO_ANALYTICS_DATA);
        window.scrollTo({
            top: 0,
        });
        // prevent page scrolling on spacebar pressed
        window.addEventListener('keydown', this.onkeydownPressed);
    },
    subscriptions() {
        const { videoId, videoProp } = this.$props;
        const { isPublic } = this;
        const database = isPublic ? publicDB() : this.$db;
        return {
            video: database.video(videoId).observe().pipe(startWith(videoProp)),
            features: database
                .video(videoId)
                .features((ref) => ref.where('enabled', '==', true))
                .observe()
                .pipe(map((items) => items.filter((item) => item.enabled && !item.deletedTime)), startWith(null)),
        };
    },
    events: {
        indexExternalData(params) {
            const { status, fid, vid } = params;
            let snackbarKeyMap = { ...this.snackbarKeyMap };
            let snackbar, message;
            switch (status) {
                case 'READY':
                    snackbar = true;
                    message = `We are indexing your external data id:${[fid]}`;
                    break;
                case 'COMPLETED':
                    snackbar = true;
                    message = `Upload of external data completed. It will take some time to process. id:${[
                        fid,
                    ]}`;
                    break;
                default:
                    snackbar = false;
                    message = ``;
                    break;
            }
            snackbarKeyMap[`${vid}-${fid}`] = { snackbar, message };
            this.snackbarKeyMap = snackbarKeyMap;
            // eslint-disable-next-line @typescript-eslint/no-floating-promises
            this.$sleep(5000).then(() => {
                snackbarKeyMap[`${vid}-${fid}`].snackbar = false;
                this.snackbarKeyMap = snackbarKeyMap;
            });
        },
    },
    components: {
        VideoSidePanel,
        VideoPlayerAdmin,
    },
    beforeRouteEnter(_to, _from, next) {
        next((vm) => {
            const isViewer = vm.$store.getters.userRoleInWorkspace === Role.VIEWER;
            if (isViewer) {
                // @ts-ignore
                const videoId = vm.$props?.videoId ?? vm.videoId;
                next(`/${vm.$route.params.workspaceId}/videos`);
                if (performance.navigation.type != 2) {
                    window.location.href = vm.$store.getters.videoURL(videoId);
                }
            }
            else {
                const channelId = _from.params.channelId || vm.$route.query.cid;
                // @ts-ignore
                vm.visitedChannelId = channelId || null;
                const curQuery = vm.$route.query;
                if (curQuery.cid !== channelId) {
                    void vm.$router.replace({
                        query: {
                            ...curQuery,
                            cid: channelId,
                        },
                    });
                }
                next();
            }
        });
    },
    beforeDestroy() {
        this.$store.commit(MUTATIONS.CLEAR_VIDEO_ANALYTICS_DATA);
        window.removeEventListener('keydown', this.onkeydownPressed);
    },
});
