import { doc } from 'rxfire/firestore';
import { map } from 'rxjs/operators';
import paths from './helpers/paths';
import { dbCollection, mapDoc, signatures } from './helpers/utils';
export const user = ({ firestore, FieldValue }) => (uid) => {
    const ref = firestore.doc(paths.users(uid));
    const { created, edited } = signatures(FieldValue);
    return {
        observe: () => doc(ref).pipe(map((v) => mapDoc(v))),
        get: async () => mapDoc(await ref.get()),
        async create(user) {
            await ref.set({ ...user, ...created(uid) });
        },
        async update(userUpdate) {
            await ref.update({ ...userUpdate, ...edited(uid) });
        },
        async delete(outerBatch) {
            const batch = outerBatch || firestore.batch();
            batch.delete(ref);
            if (!outerBatch) {
                await batch.commit();
            }
        },
    };
};
export const users = dbCollection(paths.users());
