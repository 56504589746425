import Vue from 'vue';
import ChannelDataManagementEditEidForm from './ChannelDataManagementEditEidForm.vue';
export default Vue.extend({
    name: 'ChannelDataManagementEditDataFilter',
    props: {
        channel: Object,
    },
    data() {
        return {
            saving: false,
            dialog: false,
            shoudSubmitEids: false,
        };
    },
    methods: {
        async onSubmitEids(eids) {
            this.saving = true;
            await this.$db.channel(this.channel.id).update({
                eids: eids,
            });
            this.shoudSubmitEids = false;
            this.saving = false;
            this.dialog = false;
        },
    },
    components: {
        ChannelDataManagementEditEidForm,
    },
});
