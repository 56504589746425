import Vue from 'vue';
import { FeatureType, QuestionType, } from '@lumiere/db/types';
import featureDefaults from './featureDefaults';
import FeatureOptions from './featureOptions/FeatureOptions.vue';
import FeatureTriggers from './featureTriggers/FeatureTriggers.vue';
import EmojiDataExport from './featureData/EmojiDataExport.vue';
import FeatureDeleteDialog from './FeatureDeleteDialog.vue';
import CommentDataExport from './featureData/CommentDataExport.vue';
import ExternalData_DataExport from './featureData/ExternalData_DataExport.vue';
import TextQuestionDataExport from './featureData/TextQuestionDataExport.vue';
import ChoiceQuestionDataExport from './featureData/ChoiceQuestionDataExport.vue';
import adminAPI from '@/services/adminAPI';
import logger from '@lumiere/shared/services/logger';
export default Vue.extend({
    name: 'FeatureEditForm',
    props: {
        feature: {
            type: Object,
            required: true,
        },
        videoId: {
            type: String,
            required: true,
        },
    },
    data() {
        return {
            loading: false,
            triggers: this.$props.feature.triggers,
            stoppers: this.$props.feature.stoppers,
            emojiLabel: '',
            windowWidth: 0,
            windowHeight: 0,
            renaming: false,
            dialog: false,
            deletingFeature: false,
            JSONFromCSV: null,
        };
    },
    computed: {
        FeatureType: () => FeatureType,
        featureDefaults: () => featureDefaults,
        isVerticalTabs() {
            return this.$vuetify.breakpoint.mdAndUp;
        },
        hasDataExport() {
            const type = this.feature.type;
            return [
                FeatureType.Comments,
                FeatureType.Emojis,
                FeatureType.ExternalData,
                FeatureType.Question,
            ].includes(type);
        },
        isExternalData() {
            return this.feature.type === FeatureType.ExternalData;
        },
        QuestionType: () => QuestionType,
    },
    components: {
        FeatureOptions,
        FeatureTriggers,
        EmojiDataExport,
        FeatureDeleteDialog,
        CommentDataExport,
        ExternalData_DataExport,
        TextQuestionDataExport,
        ChoiceQuestionDataExport,
    },
    methods: {
        async updateFeature() {
            this.loading = true;
            const { options, label, type } = this.feature;
            let newOptions = options;
            let isVideoExternalDataFeature = false;
            if (type === FeatureType.ExternalData && options && 'text' in options) {
                const { text, eid, cid, videoTime, rating, locationX, locationY } = options;
                newOptions = {
                    text,
                    eid,
                    cid,
                    videoTime,
                    rating,
                    locationX,
                    locationY,
                };
                isVideoExternalDataFeature = true;
            }
            const update = {
                options: newOptions,
                triggers: this.triggers,
                stoppers: this.stoppers,
                label,
            };
            try {
                await this.$db
                    .video(this.videoId)
                    .feature(this.feature.id)
                    .update(update)
                    .then(async () => {
                    // ingest video-external-data
                    if (isVideoExternalDataFeature) {
                        void this.updateVideoExternalData();
                    }
                    // save audit_log
                    const ctx = await this.$auditLogger(this.$store.state.uid);
                    if (ctx) {
                        const { Asset_Model, EVENT_ACTION, ts } = ctx.config;
                        const asset = {
                            id: this.feature.id,
                            name: this.feature.label,
                            type: Asset_Model['Video.Feature'],
                        };
                        await ctx.exec(asset, {
                            timestamp: ts(),
                            action: EVENT_ACTION.UPDATED,
                            data: {
                                type: 'INFO',
                                payload: {
                                    message: 'Video.Feature attributes changed',
                                    actorAssets: {
                                        type: Asset_Model.Video,
                                        ids: [this.videoId],
                                    },
                                    extraInfo: {
                                        ...update,
                                    },
                                },
                            },
                        });
                    }
                });
                this.close();
            }
            catch (err) {
                logger.error('updateFeature:Error', err);
            }
            this.loading = false;
            this.emojiLabel = label;
        },
        async remove() {
            this.deletingFeature = true;
            await this.$db
                .video(this.videoId)
                .feature(this.feature.id)
                .softDelete()
                .then(async () => {
                const ctx = await this.$auditLogger(this.$store.state.uid);
                if (ctx) {
                    const { Asset_Model, EVENT_ACTION, ts } = ctx.config;
                    const asset = {
                        id: this.feature.id,
                        name: this.feature.label,
                        type: Asset_Model['Video.Feature'],
                    };
                    await ctx.exec(asset, {
                        timestamp: ts(),
                        action: EVENT_ACTION.DELETED,
                        data: {
                            type: 'INFO',
                            payload: {
                                message: 'Video.Feature is deleted',
                                actorAssets: {
                                    type: Asset_Model.Video,
                                    ids: [this.videoId],
                                },
                            },
                        },
                    });
                }
            });
            this.deletingFeature = false;
            this.dialog = false;
        },
        close() {
            this.$emit('close');
        },
        saveTriggers({ triggers, stoppers, }) {
            this.triggers = triggers;
            this.stoppers = stoppers; // TODO: improve type support of different kinds of triggers
        },
        async updateTitle(label) {
            await this.$db
                .video(this.videoId)
                .feature(this.feature.id)
                .update({ label })
                .then(async () => {
                const ctx = await this.$auditLogger(this.$store.state.uid);
                if (ctx) {
                    const { Asset_Model, EVENT_ACTION, ts } = ctx.config;
                    const asset = {
                        id: this.feature.id,
                        name: this.feature.label,
                        type: Asset_Model['Video.Feature'],
                    };
                    await ctx.exec(asset, {
                        timestamp: ts(),
                        action: EVENT_ACTION.UPDATED,
                        data: {
                            type: 'INFO',
                            payload: {
                                message: 'Feature attributes changed',
                                actorAssets: {
                                    type: Asset_Model.Video,
                                    ids: [this.videoId],
                                },
                                extraInfo: {
                                    label,
                                },
                            },
                        },
                    });
                }
            });
            this.emojiLabel = label;
            this.renaming = false;
        },
        async updateVideoExternalData() {
            // synchronously update the data on elasticsearch
            this.$events.emit('indexExternalData', {
                status: 'READY',
                type: 'UPDATE',
                fid: this.feature.id,
                vid: this.videoId,
            });
            await adminAPI((api) => api.video.insights.updateVideoExternalData({
                fid: this.feature.id,
                vid: this.videoId,
                json: this.JSONFromCSV ?? [],
            })).then((res) => {
                logger.info('Done updating data import to ES', res);
                this.$events.emit('indexExternalData', {
                    status: 'COMPLETED',
                    type: 'UPDATE',
                    fid: this.feature.id,
                    vid: this.videoId,
                });
            });
        },
    },
    mounted() {
        this.emojiLabel = this.feature.label;
    },
    watch: {
        'feature.options': {
            handler(options) {
                if (options.json) {
                    this.JSONFromCSV = options.json;
                }
            },
        },
    },
});
