import Vue from 'vue';
import { AssetType } from '@lumiere/db/types';
import { FieldValue } from '@/services/firebase/firestore';
import VideoSceneEditWizard from './VideoSceneEditWizard.vue';
import adminAPI from '@/services/adminAPI';
export default Vue.extend({
    name: 'VideoSceneCard',
    props: {
        scene: Object,
        video: Object,
    },
    data() {
        return {
            animationLoaded: false,
            editWizard: false,
            thumbnail: null,
            liveThumbnail: null,
        };
    },
    watch: {
        video: {
            immediate: true,
            async handler({ id }) {
                this.thumbnail = await adminAPI(async (api) => api.video.getSignedURL({
                    vid: id,
                    assetType: AssetType.thumbnail,
                    params: { width: 640, time: this.midTime },
                }));
                this.liveThumbnail = await adminAPI(async (api) => api.video.getSignedURL({
                    vid: id,
                    assetType: AssetType.gif,
                    params: { start: this.startTime },
                }));
            },
        },
    },
    computed: {
        startTime() {
            return this.scene.startTime;
        },
        stopTime() {
            return this.scene.stopTime;
        },
        midTime() {
            return +((this.stopTime + this.startTime) / 2).toFixed(1);
        },
        label() {
            return this.scene.name;
        },
    },
    methods: {
        async deleteScene() {
            const toConfirm = await this.$confirm('Are you sure you want to delete this scene?', {
                buttonTrueText: 'Yes',
                buttonFalseText: 'Dismiss',
            });
            if (toConfirm) {
                await this.$db
                    .video(this.video.id)
                    .update({
                    [`scenes.${this.scene.id}`]: FieldValue.delete(),
                })
                    .then(async () => {
                    const ctx = await this.$auditLogger(this.$store.state.uid);
                    if (ctx) {
                        const { Asset_Model, EVENT_ACTION, ts } = ctx.config;
                        const asset = {
                            id: this.video.id,
                            name: this.video.title,
                            type: Asset_Model.Video,
                        };
                        await ctx.exec(asset, {
                            timestamp: ts(),
                            action: EVENT_ACTION.DELETED,
                            data: {
                                type: 'INFO',
                                payload: {
                                    message: `Video attributes changed: delete scene`,
                                    extraInfo: {
                                        scene: {
                                            id: this.scene.id,
                                        },
                                    },
                                },
                            },
                        });
                    }
                });
            }
        },
    },
    components: {
        VideoSceneEditWizard,
    },
});
