import Vue from 'vue';
import '@lumiere/shared/plugins/vue2-touch-events';
import isUndefined from 'lodash/isUndefined';
/**
 * @useMilliseconds
 *  Will this component use milliseconds as the v-model? If so,
 *  then the milliseconds field will be shown. If not, then it's going to use seconds.
 *
 * @rangeMinSeconds
 *  This is the minimum value in seconds for the field
 *
 * @rangeMaxSeconds
 *  This is the maximum value in seconds for the field
 *
 */
export default Vue.extend({
    name: 'TimePickerComponent',
    props: {
        useMilliseconds: Boolean,
        value: Number,
        rangeMinSeconds: Number,
        rangeMaxSeconds: Number,
    },
    data() {
        return {
            hours: '',
            minutes: '',
            seconds: '',
            milliseconds: '',
            cleanHours: 0,
            cleanMinutes: 0,
            cleanSeconds: 0,
            cleanMilliseconds: 0,
            currentFieldInFocus: '',
            focusTimer: 0,
            clearOutFieldOnFirstNumberPress: true,
        };
    },
    computed: {
        //return the difference between the max and the min in seconds.
        //return -1 if the ranges aren't specified, or return -1 if
        //the data is junk with min >= max
        rangeDelta() {
            const isRangeMinUndefined = isUndefined(this.rangeMinSeconds);
            const isRangeMaxUndefined = isUndefined(this.rangeMaxSeconds);
            if (isRangeMinUndefined && isRangeMaxUndefined) {
                return -1;
            }
            else {
                let min = 0;
                let max = 0;
                if (!isRangeMinUndefined) {
                    min = this.rangeMinSeconds;
                }
                if (!isRangeMaxUndefined) {
                    max = this.rangeMaxSeconds;
                }
                const delta = max - min > 0 ? max - min : -1;
                return delta;
            }
        },
        showHours() {
            return this.rangeDelta == -1 || this.rangeDelta > 3600;
        },
        showMinutes() {
            return this.rangeDelta == -1 || this.rangeDelta > 60;
        },
        showMilliseconds() {
            return this.useMilliseconds;
        },
        hh() {
            return this.lpad(this.cleanHours + '', '0', 2);
        },
        mm() {
            return this.lpad(this.cleanMinutes + '', '0', 2);
        },
        ss() {
            return this.lpad(this.cleanSeconds + '', '0', 2);
        },
        ms() {
            return this.lpad(this.cleanMilliseconds + '', '0', 3);
        },
        timeCode() {
            return `${this.hh}:${this.mm}:${this.ss}:${this.ms}`;
        },
        formatString() {
            const times = [];
            if (this.showHours) {
                times.push('hh');
            }
            if (this.showMinutes) {
                times.push('mm');
            }
            times.push('ss');
            if (this.showMilliseconds) {
                times.push('ms');
            }
            return `${times.join(':')}`;
        },
        hoursStyle() {
            return this.currentFieldInFocus == 'h'
                ? { color: '#000000', fontWeight: '500' }
                : null;
        },
        minutesStyle() {
            return this.currentFieldInFocus == 'm'
                ? { color: '#000000', fontWeight: '500' }
                : null;
        },
        secondsStyle() {
            return this.currentFieldInFocus == 's'
                ? { color: '#000000', fontWeight: '500' }
                : null;
        },
        millisecondsStyle() {
            return this.currentFieldInFocus == 'ms'
                ? { color: '#000000', fontWeight: '500' }
                : null;
        },
        composite() {
            return `${this.timeCode}:${this.currentFieldInFocus}`;
        },
    },
    methods: {
        lpad(str, padString, length) {
            while (str.length < length)
                str = padString + str;
            return str;
        },
        //We are hiding the cursor from the user because it's not really appropriate for a
        //clock-style interface. So in order to preserve the commonsense functioning of the
        //backspace key, we're catching the delete keypresses and substringing the values by
        //one each time.
        deleteKeyPressed(which) {
            this.clearOutFieldOnFirstNumberPress = false;
            if (which == 'h') {
                this.hours = this.hours.substr(0, this.hours.length - 1);
            }
            else if (which == 'm') {
                this.minutes = this.minutes.substr(0, this.minutes.length - 1);
            }
            else if (which == 's') {
                this.seconds = this.seconds.substr(0, this.seconds.length - 1);
            }
            else if (which == 'ms') {
                this.milliseconds = this.milliseconds.substr(0, this.milliseconds.length - 1);
            }
        },
        //There's an expectation that the first time the user clicks the field it
        //will just replace the field with whatever number is hit next, unless
        //the user starts out by backspacing in which case it will just start reducing
        //the string.
        numberKeyPressed(evt, which) {
            const { key } = evt;
            const numVal = parseInt(key);
            if (!isNaN(numVal)) {
                if (this.clearOutFieldOnFirstNumberPress) {
                    this.clearOutFieldOnFirstNumberPress = false;
                    if (which == 'h') {
                        this.hours = key;
                    }
                    else if (which == 'm') {
                        this.minutes = key;
                    }
                    else if (which == 's') {
                        this.seconds = key;
                    }
                    else if (which == 'ms') {
                        this.milliseconds = key;
                    }
                }
                else {
                    if (which == 'h') {
                        if (this.hours.length < 2 && parseInt(this.hours + key) < 60) {
                            this.hours += key;
                        }
                    }
                    else if (which == 'm') {
                        if (this.minutes.length < 2 && parseInt(this.minutes + key) < 60) {
                            this.minutes += key;
                        }
                    }
                    else if (which == 's') {
                        if (this.seconds.length < 2 && parseInt(this.seconds + key) < 60) {
                            this.seconds += key;
                        }
                    }
                    else if (which == 'ms') {
                        if (this.milliseconds.length < 4 &&
                            parseInt(this.milliseconds + key) < 1000) {
                            this.milliseconds += key;
                        }
                    }
                }
            }
        },
        focusHappened(which) {
            this.currentFieldInFocus = which;
            this.clearOutFieldOnFirstNumberPress = true;
        },
    },
    watch: {
        hours(newVal) {
            const numberValue = parseInt(newVal);
            if (!isNaN(numberValue) && numberValue >= 0 && numberValue < 60) {
                this.cleanHours = numberValue;
            }
            else if (newVal.trim() == '') {
                this.cleanHours = 0;
            }
        },
        minutes(newVal) {
            const numberValue = parseInt(newVal);
            if (!isNaN(numberValue) && numberValue >= 0 && numberValue < 60) {
                this.cleanMinutes = numberValue;
            }
            else if (newVal.trim() == '') {
                this.cleanMinutes = 0;
            }
        },
        seconds(newVal) {
            const numberValue = parseInt(newVal);
            if (!isNaN(numberValue) && numberValue >= 0 && numberValue < 60) {
                this.cleanSeconds = numberValue;
            }
            else if (newVal.trim() == '') {
                this.cleanSeconds = 0;
            }
        },
        milliseconds(newVal) {
            const numberValue = parseInt(newVal);
            if (!isNaN(numberValue) && numberValue >= 0 && numberValue < 1000) {
                this.cleanMilliseconds = numberValue;
            }
            else if (newVal.trim() == '') {
                this.cleanMilliseconds = 0;
            }
        },
        //after the user stops interacting for 5 seconds we're going to
        //reset the currentFieldInFocus so the highlight field goes away.
        composite(newVal, oldVal) {
            if (newVal != oldVal && this.currentFieldInFocus != '') {
                if (this.focusTimer) {
                    clearTimeout(this.focusTimer);
                }
                this.focusTimer = setTimeout(() => {
                    this.currentFieldInFocus = '';
                    this.clearOutFieldOnFirstNumberPress = true;
                }, 2000);
            }
        },
        timeCode(newVal, oldVal) {
            if (newVal != oldVal) {
                let secondsValue = this.cleanHours * 3600 + this.cleanMinutes * 60 + this.cleanSeconds;
                let millisecondsValue = secondsValue * 1000 + this.cleanMilliseconds;
                if (this.rangeDelta > 0) {
                    const rangeMinMS = this.rangeMinSeconds * 1000;
                    const rangeMaxMS = this.rangeMaxSeconds * 1000;
                    if (millisecondsValue < rangeMinMS) {
                        millisecondsValue = rangeMinMS;
                        secondsValue = this.rangeMinSeconds;
                    }
                    else if (millisecondsValue > rangeMaxMS) {
                        millisecondsValue = rangeMaxMS;
                        secondsValue = this.rangeMaxSeconds;
                    }
                }
                if (this.useMilliseconds) {
                    this.$emit('input', millisecondsValue);
                }
                else {
                    this.$emit('input', secondsValue);
                }
            }
        },
        //ingest the value from v-model.
        value: {
            handler(newVal, oldVal) {
                if (oldVal != newVal) {
                    //convert to milliseconds
                    const valMS = this.useMilliseconds ? newVal : newVal * 1000;
                    const hourMS = 60 * 60 * 1000;
                    const minMS = 60 * 1000;
                    const secMS = 1000;
                    const hourMSMod = valMS % hourMS;
                    const hours = Math.floor((valMS - hourMSMod) / hourMS);
                    const minMSMod = hourMSMod % minMS;
                    const minutes = Math.floor((hourMSMod - minMSMod) / minMS);
                    const secMSMod = minMSMod % secMS;
                    const seconds = Math.floor((minMSMod - secMSMod) / secMS);
                    const milliseconds = secMSMod;
                    this.hours = `${hours}`;
                    this.cleanHours = hours;
                    this.minutes = `${minutes}`;
                    this.cleanMinutes = minutes;
                    this.seconds = `${seconds}`;
                    this.cleanSeconds = seconds;
                    this.milliseconds = `${milliseconds}`;
                    this.cleanMilliseconds = milliseconds;
                }
            },
            immediate: true,
        },
    },
});
