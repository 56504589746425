import Vue from 'vue';
import { AssetType } from '@lumiere/db/types';
import { getBrowserQueryParam } from '@/utils/api';
import VideoPlayerPointPicker from '@/components/common/VideoPlayerPointPicker.vue';
import adminAPI from '@/services/adminAPI';
export default Vue.extend({
    name: 'VideoDetailsPosterScrubDialog',
    props: {
        video: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            dialog: false,
            state: null,
            currentTime: 0,
            alertDialog: false,
            alertDialogMessage: '',
        };
    },
    methods: {
        openDialog() {
            this.dialog = true;
        },
        async submitPosterTime() {
            const thumbnailURL = await adminAPI(async (api) => api.video.getSignedURL({
                vid: this.video.id,
                assetType: AssetType.thumbnail,
                params: { time: this.currentTime },
            }));
            this.$emit('poster-selected', { thumbnailURL, time: this.currentTime });
            this.dialog = false;
        },
    },
    watch: {
        dialog: {
            async handler(value) {
                await this.$sleep(0);
                if (value) {
                    const poster = this.video.appearance?.poster;
                    const time = getBrowserQueryParam('time', poster);
                    if (time) {
                        this.currentTime = +time;
                    }
                }
            },
            immediate: true,
        },
    },
    components: {
        VideoPlayerPointPicker,
    },
});
