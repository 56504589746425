import Vue from 'vue';
import { Routes } from '@/router/router';
import { mapGetters, mapState } from 'vuex';
const MAX_NUMBER_OF_WORKSPACES_IN_SELECT = 3;
export default Vue.extend({
    name: 'WorkspaceSelect',
    props: {
        workspaceId: String,
    },
    data() {
        return {
            selectedWorkspace: null,
        };
    },
    watch: {
        currentWorkspace: {
            immediate: true,
            handler(currentWorkspace) {
                if (currentWorkspace) {
                    this.selectedWorkspace = currentWorkspace;
                }
            },
        },
        selectedWorkspace(selectedWorkspace) {
            if (!selectedWorkspace)
                return;
            let workspaceId = selectedWorkspace.id;
            if (workspaceId &&
                this.currentWorkspace &&
                workspaceId != this.currentWorkspace.id) {
                this.goToWorkspace(workspaceId);
            }
        },
    },
    computed: {
        ...mapGetters([
            'workspacesList',
            'currentWorkspace',
            'sortedRecentWorkspaces',
            'defaultWorkspace',
        ]),
        ...mapState(['recentWorkspaces', 'workspaces']),
        Routes() {
            return Routes;
        },
        items() {
            return this.sortedRecentWorkspaces.slice(0, MAX_NUMBER_OF_WORKSPACES_IN_SELECT);
        },
        label() {
            return this.selectedWorkspace?.team?.name || 'Me (Personal Workspace)';
        },
    },
    methods: {
        goToWorkspace(workspaceId) {
            this.$router.push({
                name: Routes.Videos,
                params: {
                    workspaceId,
                },
            });
        },
    },
    components: {},
});
