import Vue from 'vue';
import { FeatureType, FeatureTriggerType, VideoTriggerEvent, } from '@lumiere/db/types';
import GenericFeatureTriggers from './GenericFeatureTriggers.vue';
import QuestionFeatureTriggers from './QuestionFeatureTriggers.vue';
const defaultFeatureTriggers = () => ({
    triggers: [
        {
            type: FeatureTriggerType.VideoEvent,
            event: VideoTriggerEvent.VideoTime,
            parameters: {},
        },
    ],
    stoppers: [
        {
            type: FeatureTriggerType.VideoEvent,
            event: VideoTriggerEvent.VideoTime,
            parameters: {},
        },
    ],
});
export default Vue.extend({
    name: 'FeatureTriggers',
    props: {
        type: {
            type: String,
            required: true,
        },
        videoId: { type: String, required: true },
        featureTriggers: {
            type: Object,
            default: () => defaultFeatureTriggers(),
        },
        featureId: String,
    },
    data() {
        return {
            video: null,
            triggers: (this.featureTriggers.triggers ?? [
                {},
            ]),
            stoppers: (this.featureTriggers.stoppers ?? [
                {},
            ]),
        };
    },
    subscriptions() {
        return {
            video: this.$db.video(this.videoId).observe(),
        };
    },
    computed: {
        isQuestionType() {
            return this.type === FeatureType.Question;
        },
    },
    components: {
        GenericFeatureTriggers,
        QuestionFeatureTriggers,
    },
});
