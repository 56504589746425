import { ACTIONS } from '@/store/store.actions';
import Vue from 'vue';
const VideoUploadMixin = Vue.extend({
    props: ['workspaceId'],
    methods: {
        async uploadFile(file, fileInputId, existingVideo) {
            const workspaceId = existingVideo
                ? existingVideo.workspaceId
                : this.workspaceId;
            this.resetFileElement(fileInputId);
            return await this.$store.dispatch(ACTIONS.UPLOAD_VIDEO_FILE, {
                file,
                workspaceId,
                existingVideo,
            });
        },
        addFiles(files, existingVideo) {
            const workspaceId = existingVideo
                ? existingVideo.workspaceId
                : this.workspaceId;
            return this.$videoUploadQueue.addFiles({ files, workspaceId });
        },
        uploadFiles(files, fileInputId, existingVideo) {
            this.addFiles(files, existingVideo);
            this.resetFileElement(fileInputId);
        },
        resetFileElement(fileInputId) {
            const inputEl = window.document.getElementById(fileInputId);
            inputEl.value = '';
        },
    },
});
export default VideoUploadMixin;
