import Vue from 'vue';
import { TeamRole } from '@lumiere/db/types';
import { map, startWith } from 'rxjs/operators';
import { Timestamp } from '@/services/firebase/firestore';
const defaultSelection = {
    name: 'Show all teams',
    id: 'default-selection',
    iconURL: '',
    roles: {},
    createdTime: Timestamp.now(),
    createdBy: 'no-creator',
    editedTime: Timestamp.now(),
    editedBy: 'no-editor',
};
export default Vue.extend({
    name: 'TeamSelectDialog',
    data() {
        return {
            selectedTeam: null,
            teams: [],
        };
    },
    subscriptions() {
        const { uid, user } = this.$store.state;
        return {
            teams: this.$db
                .teams((q) => q.where(`roles.${uid}`, 'in', Object.values(TeamRole)))
                .observe()
                .pipe(map((teams) => teams.filter(({ deleted }) => !deleted)), map((teams) => [
                defaultSelection,
                {
                    name: `Me (Personal)`,
                    id: 'personal',
                    iconURL: user.photoURL,
                    createdTime: Timestamp.now(),
                    createdBy: uid,
                    editedTime: Timestamp.now(),
                    editedBy: uid,
                },
                ...teams,
            ]), startWith([])),
        };
    },
    computed: {
        defaultSelection() {
            return defaultSelection;
        },
        isDefaultSelection() {
            return this.selectedTeam?.id === defaultSelection.id;
        },
    },
    methods: {
        onSelectionChanged(selectedTeam) {
            if (selectedTeam?.id === defaultSelection.id) {
                this.$emit('selected-team', null);
            }
            else {
                this.$emit('selected-team', selectedTeam);
            }
            this.selectedTeam = selectedTeam;
        },
    },
    mounted() {
        this.selectedTeam = defaultSelection;
    },
    watch: {
        selectedTeam(team) {
            if (team) {
                if (team.id === defaultSelection.id) {
                    if (team.name !== 'Filter by team') {
                        if (this.selectedTeam) {
                            this.selectedTeam.name = 'Filter by team';
                        }
                    }
                }
                else {
                    const defSel = this.teams.find((t) => t.id === defaultSelection.id);
                    if (defSel?.name === 'Filter by team') {
                        defSel.name = 'Show all teams';
                    }
                }
            }
        },
    },
});
