import Vue from 'vue';
import VideoCard from '@/components/workspace/videos/VideoCard.vue';
import AddVideoButton from '@/components/workspace/videos/AddVideoButton.vue';
import VideosFilters from '@/components/workspace/videos/VideosFilters.vue';
import VideoTag from '@/components/workspace/videos/VideoTag.vue';
import VideoFeature from '@/components/workspace/videos/VideoFeature.vue';
import omit from 'lodash/omit';
import { VideoStatus, } from '@lumiere/db/types';
import { pluck, switchMap, startWith, map, shareReplay } from 'rxjs/operators';
import { combineLatest } from 'rxjs';
import { mapGetters } from 'vuex';
import { VideoRoutes } from '@/router/router';
import { FieldValue } from '@/services/firebase/firestore';
import MUTATIONS from '@/store/MUTATIONS';
import differenceBy from 'lodash/differenceBy';
import { FileUploadStatus } from '../../store/store.state';
const NUMBER_OF_VIDEOS_PER_PAGE = 16;
export default Vue.extend({
    props: {
        workspaceId: { type: String, required: true },
    },
    data() {
        return {
            filters: {},
            numberOfPages: 1,
            alertDialogMessage: '',
            alertDialog: false,
            filterDialog: false,
            videos: [],
            erroredVideos: null,
            numberOfErroredVideos: 0,
            loading: null,
            totalNumberOfVideos: 0,
        };
    },
    computed: {
        ...mapGetters(['currentWorkspace', 'workspaceTags']),
        VideoStatus() {
            return VideoStatus;
        },
        routeLinkType() {
            return this.$ability.can('read', 'Video') ? 'to' : 'href';
        },
        videoURL() {
            return (video) => this.$ability.can('read', 'Video')
                ? {
                    name: VideoRoutes.Insights,
                    params: {
                        videoId: video.id,
                        videoProp: video,
                    },
                }
                : this.$store.getters.videoURL(video.id);
        },
        colProps() {
            return {
                cols: 12,
                sm: 6,
                md: 6,
                lg: 4,
            };
        },
        numberOfSkeletons() {
            return NUMBER_OF_VIDEOS_PER_PAGE;
        },
        notSelectedTags() {
            return (video) => this.workspaceTags.filter(({ id }) => !video.tags?.includes(id));
        },
    },
    methods: {
        removeTagFromFilters() {
            this.filters = omit(this.filters, 'tag');
        },
        removeFeatureFromFilters() {
            this.filters = omit(this.filters, 'feature');
        },
        triggerIntersect(entries) {
            if (entries[0].isIntersecting) {
                this.triggerMore();
            }
        },
        triggerMore() {
            this.numberOfPages++;
        },
        onAddVideoError(err) {
            this.alertDialogMessage = err;
            this.alertDialog = true;
        },
        workspaceTag(tagId) {
            return this.currentWorkspace.tags?.[tagId];
        },
        async deleteVideo(video) {
            const toConfirm = await this.$confirm(`Delete '${video.title}'?`, {
                buttonTrueText: 'Delete',
                buttonFalseText: 'Dismiss',
            });
            if (toConfirm) {
                await this.$db
                    .video(video.id)
                    .softDelete()
                    .then(async () => {
                    const ctx = await this.$auditLogger(this.$store.state.uid);
                    if (ctx) {
                        const { Asset_Model, EVENT_ACTION, ts } = ctx.config;
                        const asset = {
                            id: video.id,
                            type: Asset_Model.Video,
                            name: video.title,
                        };
                        await ctx.exec(asset, {
                            timestamp: ts(),
                            action: EVENT_ACTION.SOFT_DELETED,
                            data: {
                                type: 'INFO',
                                payload: {
                                    message: `Video has been soft deleted`,
                                },
                            },
                        });
                    }
                });
            }
        },
        async assignTagOnDrop(video, e) {
            const tagId = e.dataTransfer?.getData('text');
            if (tagId) {
                await this.addTagToVideo(video, tagId);
            }
        },
        async addTagToVideo(video, tagId) {
            await this.$db
                .video(video.id)
                .update({
                tags: FieldValue.arrayUnion(tagId),
            })
                .then(async () => {
                const ctx = await this.$auditLogger(this.$store.state.uid);
                if (ctx) {
                    const { Asset_Model, EVENT_ACTION, ts } = ctx.config;
                    const asset = {
                        id: video.id,
                        name: video.title,
                        type: Asset_Model.Video,
                    };
                    await ctx.exec(asset, {
                        timestamp: ts(),
                        action: EVENT_ACTION.ADDED,
                        data: {
                            type: 'INFO',
                            payload: {
                                message: `Video attributes changed: tag added`,
                                extraInfo: {
                                    tagId,
                                },
                            },
                        },
                    });
                }
            });
        },
    },
    watch: {
        workspaceId: {
            handler() {
                window.scrollTo({
                    top: 0,
                });
                this.numberOfPages = 1;
                this.filters = {};
            },
        },
        erroredVideos: {
            handler(newArray, oldArray) {
                const bothExist = newArray && oldArray;
                if (bothExist && newArray.length > oldArray.length) {
                    const errorsToShow = differenceBy(newArray, oldArray, (video) => {
                        return video.id;
                    });
                    errorsToShow.forEach((video) => {
                        this.$store.commit(MUTATIONS.ADD_FILE_UPLOAD, {
                            id: '',
                            name: video.title,
                            workspaceId: video.workspaceId,
                            status: FileUploadStatus.ERROR,
                            message: video.statusMessage,
                        });
                    });
                }
            },
        },
        // filters(filters) {},
    },
    subscriptions() {
        const workspaceId$ = this.$watchAsObservable('workspaceId', {
            immediate: true,
        }).pipe(pluck('newValue'));
        const filters$ = this.$watchAsObservable('filters', {
            immediate: true,
        }).pipe(pluck('newValue'));
        const numberOfPages$ = this.$watchAsObservable('numberOfPages', {
            immediate: true,
        }).pipe(pluck('newValue'));
        const allVideos$ = workspaceId$.pipe(switchMap((workspaceId) => this.$db
            .videos((ref) => {
            return ref
                .where('workspaceId', '==', workspaceId)
                .where('status', 'in', [
                VideoStatus.Ready,
                VideoStatus.Processing,
                VideoStatus.Errored,
            ])
                .orderBy('createdTime', 'desc');
        })
            .observe()), shareReplay(1));
        const erroredVideos$ = workspaceId$.pipe(switchMap((workspaceId) => this.$db
            .videos((ref) => {
            return ref
                .where('workspaceId', '==', workspaceId)
                .where('status', '==', VideoStatus.Errored)
                .orderBy('createdTime', 'desc');
        })
            .observe()), shareReplay(1));
        const filteredVideos$ = filters$.pipe(switchMap((filters) => allVideos$.pipe(map((allVideos) => allVideos.filter((video) => (filters.tag ? video.tags.includes(filters.tag) : true) &&
            (filters.feature
                ? video.features.includes(filters.feature)
                : true))))), shareReplay(1));
        const videos$ = combineLatest([filteredVideos$, numberOfPages$]).pipe(map(([filteredVideos, numberOfPages]) => {
            if (filteredVideos === null)
                return null;
            return filteredVideos.slice(0, numberOfPages * NUMBER_OF_VIDEOS_PER_PAGE);
        }));
        return {
            totalNumberOfVideos: filteredVideos$.pipe(map((videos) => videos?.length)),
            videos: videos$,
            erroredVideos: erroredVideos$,
            numberOfErroredVideos: erroredVideos$.pipe(map((videos) => videos?.length)),
            loading: filteredVideos$.pipe(startWith(null), map((videos) => videos === null)),
        };
    },
    components: {
        AddVideoButton,
        VideoCard,
        VideoTag,
        VideosFilters,
        VideoFeature,
    },
});
