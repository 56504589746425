var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{ref:"trackWrapper",staticClass:"track-wrapper",on:{"mouseenter":_vm.mouseEnter,"mouseleave":_vm.mouseLeave}},[_vm._t("default",[(_vm.duration > 0)?_c('VueSlider',_vm._b({key:"scrubber",attrs:{"dot-options":{
        disabled: !_vm.allowScrubbing,
        style: { backgroundColor: 'white', display: _vm.displayScrubHandle },
      },"process-style":{
        backgroundColor: _vm.isRangeSelection
          ? 'transparent'
          : 'var(--player-color)',
      },"rail-style":{
        backgroundColor: 'rgba(128, 123, 123, 0.8)',
      },"tooltip-style":{
        backgroundColor: 'rgba(0, 0, 0, 0.8)',
        borderColor: 'rgba(0, 0, 0, 0.8)',
      },"tooltip-formatter":function (val) { return ("" + (_vm.formatTime(val))); }},on:{"change":function (value) { return _vm.updateValue(value); },"drag-start":function () { return _vm.dragHandler(true, _vm.scrubbingStates.START); },"drag-end":function () { return _vm.dragHandler(false, _vm.scrubbingStates.STOP); },"dragging":function (value) { return _vm.dragHandler(null, _vm.scrubbingStates.ACTIVE); }},scopedSlots:_vm._u([(_vm.isRangeSelection)?{key:"dot",fn:function(ref){
      var focus = ref.focus;
return [_c('div',{staticClass:"custom-dot custom-dot__playhead",class:{ focus: focus }})]}}:null],null,true),model:{value:(_vm.scrubber),callback:function ($$v) {_vm.scrubber=$$v},expression:"scrubber"}},'VueSlider',_vm.options,false)):_vm._e()]),(_vm.isRangeSelection)?_vm._t("range-selector",[_c('div',{staticClass:"range-selector"},[_c('VueSlider',_vm._b({key:"range-selector",attrs:{"min-range":1,"max-range":_vm.duration,"dot-options":{
          style: { backgroundColor: 'white', display: 'flex' },
        },"process-style":{
          backgroundColor: '#ffeb008c',
          borderRadius: 0,
        },"rail-style":{
          backgroundColor: 'transparent',
        },"tooltip-formatter":function (val) { return ("" + (_vm.formatTime(val))); }},on:{"drag-start":_vm.rangeSelectorDragStartHandler,"drag-end":_vm.rangeSelectorDragEndHandler,"dragging":_vm.rangeSelectorDraggingHandler},scopedSlots:_vm._u([{key:"dot",fn:function(ref){
        var focus = ref.focus;
        var index = ref.index;
return [_c('div',{staticClass:"custom-dot custom-dot__range-selector",class:{
              focus: focus,
              'custom-dot__left': index === 0,
              'custom-dot__right': index === 1,
              'is-dragging': _vm.isRangeSelectorDragging,
            }},[_vm._v(" ] ")])]}},{key:"tooltip",fn:function(ref){
            var value = ref.value;
            var focus = ref.focus;
            var pos = ref.pos;
return [_c('div',{staticClass:"custom-tooltip",class:{
              focus: focus,
              'custom-tooltip__near-rear-left': pos <= 10,
              'custom-tooltip__near-rear-right': pos >= 90,
            }},[_c('div',{staticClass:"custom-tooltip__label",on:{"mouseover":function () {},"mouseleave":function () {}}},[_c('span',{staticClass:"label-text"},[_vm._v(" "+_vm._s(_vm.formatTime(value))+" ")])])])]}}],null,false,3895124610),model:{value:(_vm.rangeSelector),callback:function ($$v) {_vm.rangeSelector=$$v},expression:"rangeSelector"}},'VueSlider',_vm.rangeSelectorOptions,false))],1)]):_vm._e(),_c('div',{staticClass:"buffered"},[_c('span',{ref:"bufferIndicator",staticClass:"buffered-amount"})])],2)}
var staticRenderFns = []

export { render, staticRenderFns }