import Vue from 'vue';
import config from '@lumiere/shared/config';
export default Vue.extend({
    name: 'VideoIntelligenceThumbnail',
    props: {
        thumbnailId: { type: String, required: true },
    },
    computed: {
        thumbnail() {
            if (!this.thumbnailId)
                return null;
            return `https://storage.googleapis.com/${config.projectID}.appspot.com/video-ai/${this.thumbnailId}`;
        },
    },
});
