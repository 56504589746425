import Vue from 'vue';
import { getVideoThumbnail, getAnimatedThumbnail, } from '@lumiere/shared/utils/getVideoThumbnail';
import { mapGetters } from 'vuex';
export default Vue.extend({
    name: 'VideoCard',
    props: {
        video: Object,
        // to: Object, // we also use href when the user is a VIEWER thus v-bind=$attrs on v-card catches both
    },
    data() {
        return {
            animatedLoaded: false,
        };
    },
    computed: {
        ...mapGetters(['currentWorkspace']),
        thumbnail() {
            return getVideoThumbnail(this.video);
        },
        liveThumbnail() {
            return getAnimatedThumbnail(this.video);
        },
    },
});
