import Vue from 'vue';
import LayoutTwoColumns from '@/components/LayoutTwoColumns.vue';
import WorkspaceMembersList from '@/components/workspace/WorkspaceMembersList.vue';
import DeleteWorkspaceConfirmationForm from '@/components/workspace/DeleteWorkspaceConfirmationForm.vue';
import AddWorkspaceMemberForm from '@/components/workspace/members/AddWorkspaceMemberForm.vue';
import { TeamRole, VideoStatus, ChannelStatus, } from '@lumiere/db/types';
import { nanoid } from 'nanoid';
import { mapGetters } from 'vuex';
import COLORS from '@/colors';
import trim from 'lodash/trim';
import { map, pluck, shareReplay, startWith, switchMap } from 'rxjs/operators';
import WorkspaceInsights from './insights/WorkspaceInsights.vue';
import logger from '@lumiere/shared/services/logger';
import RestoreRecentlyDeletedAssets from '@/components/workspace/deletedAssets/RestoreRecentlyDeletedAssets.vue';
export default Vue.extend({
    data() {
        return {
            loading: false,
            addMemberDialog: false,
            assigningTeam: false,
            isAbilityResolved: !!this.$ability.relevantRuleFor('read', 'Video.File'),
            userTeams: [],
            deletedVideos: [],
            deletedChannels: [],
        };
    },
    props: {
        workspaceId: { type: String, required: true },
    },
    computed: {
        ...mapGetters([
            'currentWorkspace',
            'isAuthenticated',
            'userRoleInWorkspace',
        ]),
        COLORS: {
            get: () => Object.keys(COLORS),
        },
        canManageWorkspace() {
            return this.isAbilityResolved && this.$ability.can('manage', 'Workspace');
        },
        fileInput() {
            return this.$refs.fileInput;
        },
        shouldAssignTeam() {
            const ws = this.currentWorkspace;
            return (this.canManageWorkspace &&
                !ws.team &&
                ws.createdBy === this.$store.state.uid);
        },
    },
    mounted() {
        if (!this.isAbilityResolved) {
            const unsubscribe = this.$ability.on('updated', (_res) => {
                this.isAbilityResolved = true;
                unsubscribe();
            });
        }
        void this.$auditLogger(this.$store.state.uid).then((ctx) => {
            if (ctx) {
                const { Asset_Model, EVENT_ACTION, ts } = ctx.config;
                const asset = {
                    id: this.workspaceId,
                    type: Asset_Model.Workspace,
                    name: this.currentWorkspace.name,
                };
                return ctx.exec(asset, {
                    timestamp: ts(),
                    action: EVENT_ACTION.VIEWED,
                    data: {
                        type: 'INFO',
                        payload: {
                            message: `Workspace management page accessed/viewed`,
                        },
                    },
                });
            }
        });
    },
    methods: {
        async updateWorkspaceName(target) {
            const prevName = this.currentWorkspace.name;
            const name = trim(target.innerText);
            if (name && name !== prevName) {
                this.loading = true;
                await this.$db
                    .workspace(this.workspaceId)
                    .update({
                    name,
                })
                    .then(() => this.logWorkspaceUpdate({ prevName, name: name }));
                this.loading = false;
            }
            else {
                target.innerText = this.currentWorkspace.name;
            }
        },
        async updateColor(color) {
            this.loading = true;
            await this.$db
                .workspace(this.workspaceId)
                .update({
                theme: { color },
            })
                .then(() => this.logWorkspaceUpdate({ theme: { color } }));
            this.loading = false;
        },
        async updateWorkspaceDescription(target) {
            const description = trim(target.innerText);
            const prevDescription = this.currentWorkspace.description;
            if (description !== prevDescription) {
                this.loading = true;
                await this.$db
                    .workspace(this.workspaceId)
                    .update({
                    description,
                })
                    .then(() => this.logWorkspaceUpdate({ prevDescription, description }));
                this.loading = false;
            }
        },
        async uploadFile(file) {
            if (file) {
                this.loading = true;
                try {
                    const uploader = this.$uploadFileToStorage(file, `workspace-icon/${this.workspaceId}`, nanoid(6));
                    const iconURL = await uploader.getURL();
                    await this.$db
                        .workspace(this.workspaceId)
                        .update({
                        iconURL,
                    })
                        .then(() => this.logWorkspaceUpdate({ iconURL }));
                }
                catch (e) {
                    logger.error('uploadFile', e);
                }
                this.loading = false;
                if (this.fileInput) {
                    this.fileInput.value = '';
                }
            }
        },
        async assignTeam({ iconURL, id, name, roles, }) {
            this.assigningTeam = true;
            const rolesMap = {};
            let theRole;
            for (let uid in roles) {
                theRole = roles[uid];
                rolesMap[`roles.${uid}`] =
                    theRole === TeamRole.ADMIN ? TeamRole.OWNER : theRole;
            }
            const wsTeam = {
                ...(iconURL && { iconURL }),
                id,
                name,
                roles,
            };
            await this.$db
                .workspace(this.workspaceId)
                .update({
                team: wsTeam,
                ...rolesMap,
            })
                .then(() => this.logWorkspaceUpdate({
                rolesMap,
            }, wsTeam));
            this.assigningTeam = false;
        },
        async logWorkspaceUpdate(extraInfo, team) {
            const ctx = await this.$auditLogger(this.$store.state.uid);
            if (ctx) {
                const { Asset_Model, EVENT_ACTION, ts } = ctx.config;
                const asset = {
                    id: this.workspaceId,
                    type: Asset_Model.Workspace,
                    name: this.currentWorkspace.name,
                };
                return ctx.exec(asset, {
                    timestamp: ts(),
                    action: EVENT_ACTION.UPDATED,
                    data: {
                        type: 'INFO',
                        payload: {
                            message: `Workspace attributes changed`,
                            ...(team && {
                                actorAssets: {
                                    type: Asset_Model.Team,
                                    ids: [team.id],
                                },
                            }),
                            extraInfo,
                        },
                    },
                });
            }
        },
    },
    subscriptions() {
        const { uid } = this.$store.state;
        const workspaceId$ = this.$watchAsObservable('workspaceId', {
            immediate: true,
        }).pipe(pluck('newValue'));
        const deletedVideos$ = workspaceId$.pipe(switchMap((workspaceId) => this.$db
            .videos((ref) => {
            return ref
                .where('workspaceId', '==', workspaceId)
                .where('status', '==', VideoStatus.Deleted)
                .orderBy('deletedTime', 'desc');
        })
            .observe()
            .pipe(startWith([]))), shareReplay(1));
        const deletedChannels$ = workspaceId$.pipe(switchMap((workspaceId) => this.$db
            .channels((ref) => {
            return ref
                .where('workspaceId', '==', workspaceId)
                .where('status', '==', ChannelStatus.Deleted)
                .orderBy('deletedTime', 'desc');
        })
            .observe()
            .pipe(startWith([]))), shareReplay(1));
        return {
            userTeams: this.$db
                .teams((q) => q.where(`roles.${uid}`, '==', TeamRole.ADMIN))
                .observe()
                .pipe(map((teams) => teams.filter(({ deleted }) => !deleted))),
            deletedVideos: deletedVideos$,
            deletedChannels: deletedChannels$,
        };
    },
    components: {
        LayoutTwoColumns,
        WorkspaceMembersList,
        AddWorkspaceMemberForm,
        DeleteWorkspaceConfirmationForm,
        WorkspaceInsights,
        RestoreRecentlyDeletedAssets,
    },
});
