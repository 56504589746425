import Vue from 'vue';
import { AssetType } from '@lumiere/db/types';
import adminAPI from '@/services/adminAPI';
export default Vue.extend({
    name: 'VideoSettingsDownloadVideoFile',
    props: {
        video: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            signedVideoURL: null,
        };
    },
    computed: {
        downloadableAssets() {
            return this.video.assets
                ? this.video.assets
                    .map(({ name, width, height }) => ({
                    name,
                    width,
                    url: this.signedVideoURL
                        ? this.signedVideoURL.replace('.m3u8', '/' + name) +
                            (this.signedVideoURL.includes('?') ? '&' : '?') + // this ternary is here to support public and signed urls (signed will always have token in qs)
                            `download=${encodeURIComponent(`${this.video.title}.${name}`)}`
                        : '',
                    height,
                }))
                    .sort((a, b) => a.width - b.width)
                : null;
        },
        downloadMenuAvailable() {
            return (!!this.downloadableAssets &&
                this.downloadableAssets.length > 0 &&
                this.video.status == 'Ready');
        },
    },
    async mounted() {
        this.signedVideoURL = await adminAPI(async (api) => api.video.getSignedURL({
            vid: this.video.id,
            assetType: AssetType.video,
        }));
    },
});
