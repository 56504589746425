import Vue from 'vue';
import './icons';
import videojs from 'video.js';
import VideoPlayerWatermark from './VideoPlayerWatermark.vue';
import VideoPlayerVideoOverlay from './VideoPlayerVideoOverlay.vue';
import VideoPlayerControls from './VideoPlayerControls.vue';
import { VideoState, ScrubbingStates, } from '../types';
import debounce from 'lodash/debounce';
import { sleep } from '@lumiere/shared/utils/sleep';
import { isMobile } from '@lumiere/shared/utils/helpers';
import onVisibilityChange from '@lumiere/shared/utils/onVisibilityChange';
import logger from '@lumiere/shared/services/logger';
const TAP_TIMEOUT = 2500;
const TAP_END_TIMEOUT = 3000;
const PLAYER_CONTROLS_HEIGHT = 60;
/**
 * VideoPlayer is a wrapper around video.js element created with
 * the purpose of hiding videojs API and exposing only the set
 * of events and methods we will need. It also adds higher level
 * props, such as "allowScrubbing", which is not natively available
 * and needs to be implemented at the level of this component.
 *
 * The component should be kept unaware of Lumiere functionality, yet
 * provide specialized API to allow the necessary features.
 *
 * It will expose some slots, such as #overlay, to which the necessary
 * components would be rendered.
 */
export default Vue.extend({
    name: 'VideoPlayer',
    props: {
        src: {
            type: String,
            default: null,
        },
        storyboard: {
            type: String,
            default: null,
        },
        posterImage: {
            type: String,
        },
        aspectRatio: {
            type: Number,
            default: 16 / 9,
        },
        duration: {
            type: Number,
            required: true,
        },
        options: {
            type: Object,
            default() {
                return {};
            },
        },
        logo: {
            type: Object,
        },
        behaviors: {
            type: Object,
        },
        shouldAutoPlay: {
            type: Boolean,
            default: false,
        },
        showPlayerControls: {
            type: Boolean,
            default: true,
        },
        forceScrubbing: {
            type: [Object, Boolean],
            default: undefined,
        },
        scrubberMode: {
            type: String,
        },
        selectedRange: {
            type: Array,
        },
        stickyControls: {
            type: Boolean,
            default: false,
        },
        shouldPauseOnBlur: {
            type: Boolean,
            default: false,
        },
        loop: Boolean,
    },
    data() {
        return {
            vjs: null,
            state: null,
            currentTime: 0,
            isMouseOver: false,
            renderPlayerControls: false,
            bufferedPercent: 0,
            isBuffering: false,
            overlay: {
                width: 0,
                left: 0,
                top: 0,
                height: 0,
            },
            container: {
                width: 0,
                left: 0,
                top: 0,
                height: 0,
            },
            tapExpired: null,
            tapTimeout: undefined,
            wasPlaying: null,
            removeVisibilityChange: null,
            showingControls: null,
            preScrubbingState: null,
            showPosterFirstBuffer: true,
        };
    },
    computed: {
        videoRef() {
            return this.$refs.video;
        },
        VideoState() {
            return VideoState;
        },
        videoSetupData() {
            const { muxData } = this.options;
            const setupData = process.env.VUE_APP_PROJECT_ENV === 'local' || !muxData
                ? {}
                : {
                    plugins: {
                        mux: {
                            data: muxData,
                        },
                    },
                };
            return { ...this.setupDefault, ...setupData };
        },
        setupDefault() {
            return {
                controls: false,
                preload: 'metadata',
                playsinline: true,
                // start the IFrame video in muted state to avoid NotAllowedError: play()
                // read here for more: https://developers.google.com/web/updates/2017/09/autoplay-policy-changes#best-practices
                muted: false,
                loop: Boolean(this.loop),
            };
        },
        autoplay() {
            return this.shouldAutoPlay;
        },
        allowScrubbing() {
            return this.forceScrubbing ?? this.behaviors?.allowScrubbing ?? true;
        },
        prePostRoll() {
            return (!!this.state &&
                [this.VideoState.Ready, this.VideoState.Ended].includes(this.VideoState[this.state]));
        },
        playerControlsOverVideo() {
            if (!this.container || !this.showingControls) {
                return false;
            }
            const { width, height } = this.container;
            return width / (height - PLAYER_CONTROLS_HEIGHT) / this.aspectRatio > 1
                ? true
                : false;
        },
    },
    methods: {
        play() {
            return this.vjs?.play();
        },
        pause() {
            this.vjs?.pause();
        },
        seekTo(time) {
            if (typeof time === 'number' && isFinite(time)) {
                this.vjs?.currentTime(time);
            }
            if (this.state === VideoState.Playing) {
                this.play()?.catch((e) => logger.error('SEEKTO: Play could not activate', e));
            }
            else if (this.state === VideoState.Paused) {
                this.pause();
            }
        },
        togglePlay({ value }) {
            /* eslint-disable @typescript-eslint/no-floating-promises */
            if (value) {
                sleep(0).then(this.play);
            }
            else {
                sleep(0).then(this.pause);
            }
            /* eslint-enable @typescript-eslint/no-floating-promises */
            this.$emit('toggle-play');
        },
        mouseEnter() {
            this.isMouseOver = true;
        },
        mouseLeave() {
            this.isMouseOver = false;
        },
        // a simple implementation of vjs.userActive()
        onTap() {
            if (isMobile()) {
                this.isMouseOver = true;
                this.tapExpired = false;
                clearTimeout(this.tapTimeout);
                this.tapTimeout = window.setTimeout(() => {
                    this.tapExpired = true;
                }, TAP_TIMEOUT);
            }
        },
        onTapEnded() {
            if (isMobile()) {
                setTimeout(() => {
                    if (this.tapExpired) {
                        this.isMouseOver = false;
                    }
                }, TAP_END_TIMEOUT);
            }
        },
        updateVolume(volume) {
            this.vjs?.volume(volume / 100);
        },
        updateMuted: debounce(function ({ value }) {
            this.$nextTick(() => {
                this.vjs?.muted(value);
            });
        }, 500),
        _initVJS() {
            const vjs = videojs(this.videoRef, this.videoSetupData, async () => {
                this.state = this.VideoState.Ready;
            });
            vjs.on('timeupdate', () => {
                this.currentTime = vjs.currentTime();
                // this.bufferedPercent = vjs.bufferedPercent()
                this.$emit('timeupdate', this.currentTime);
            });
            vjs.on('play', () => {
                this.$emit('play');
            });
            vjs.on('pause', () => {
                this.state = this.VideoState.Paused;
                this.$emit('pause');
            });
            vjs.on('playing', () => {
                this.state = this.VideoState.Playing;
            });
            vjs.on('ended', () => {
                this.state = this.VideoState.Ended;
                if (vjs.isFullscreen()) {
                    vjs.exitFullscreen();
                }
                vjs.hasStarted(false);
            });
            vjs.on('waiting', () => {
                this.state = this.VideoState.Waiting;
            });
            vjs.on('progress', () => {
                const buffered = vjs.buffered();
                const currentTime = vjs.currentTime();
                const duration = this.duration || vjs.duration();
                if (duration > 0) {
                    for (let i = 0; i < buffered.length; i++) {
                        if (buffered?.start(buffered.length - 1 - i) < currentTime) {
                            this.bufferedPercent =
                                buffered.end(buffered.length - 1 - i) / duration;
                            break;
                        }
                    }
                }
            });
            this.vjs = vjs;
        },
        handleTabLoseFocus(isHidden) {
            if (isHidden) {
                this.wasPlaying = this.state === VideoState.Playing;
                if (this.wasPlaying) {
                    this.pause();
                }
            }
            else {
                // If the video was playing before we lost visibility, continue playing.
                if (this.wasPlaying) {
                    setTimeout(() => {
                        this.play()?.catch((e) => logger.error('HANDLE_TAB_LOSEFOCUS: Play could not activate', e));
                    }, 500);
                }
            }
        },
        onShowingPlayerControls(v) {
            this.showingControls = v;
            this.$emit('showing-player-controls', v);
        },
        onScrubbing(data) {
            this.$emit('scrubbing', data);
            const wasPlaying = this.state === VideoState.Playing;
            const isScrubbing = [
                ScrubbingStates.START,
                ScrubbingStates.ACTIVE,
            ].includes(data.state);
            if (isScrubbing) {
                if (!this.preScrubbingState) {
                    this.preScrubbingState = this.state;
                }
                if (wasPlaying) {
                    this.preScrubbingState = VideoState.Playing;
                    this.pause();
                }
            }
            else {
                // ScrubbingStates.STOP
                const wasPrepaused = this.preScrubbingState === VideoState.Paused;
                if (wasPrepaused) {
                    this.pause();
                }
                else {
                    this.play()?.catch((e) => logger.error('ONSCRUBBING: Play could not activate', e));
                }
                this.preScrubbingState = null;
            }
        },
    },
    watch: {
        src: {
            immediate: true,
            handler(src) {
                if (src) {
                    this.$nextTick(() => {
                        this._initVJS();
                    });
                }
            },
        },
        state(state, oldstate) {
            this.$emit('state-change', state);
            if (state === VideoState.Playing &&
                [VideoState.Ready, VideoState.Waiting].includes(oldstate)) {
                if (this.stickyControls) {
                    this.pause();
                }
            }
            if (!this.isBuffering && state === VideoState.Waiting) {
                this.isBuffering = true;
            }
            if (this.isBuffering && state === VideoState.Ended) {
                this.isBuffering = false;
            }
            //the first time it plays, turn the poster off.
            if (state === VideoState.Playing) {
                this.showPosterFirstBuffer = false;
            }
        },
        currentTime(time, oldTime) {
            // the logic below fixes an issue on Safari, where the browser does not
            // trigger state change from waiting to playing
            if (!this.isBuffering &&
                time === oldTime &&
                this.state !== VideoState.Paused) {
                this.isBuffering = true;
            }
            if (this.isBuffering && time !== oldTime) {
                this.isBuffering = false;
                this.state = VideoState.Playing;
            }
        },
        posterImage(url, oldUrl) {
            if (url !== oldUrl) {
                this.$nextTick(() => {
                    this.vjs?.poster(url || '');
                });
            }
        },
        overlay(size) {
            this.$emit('overlay-size-change', size);
        },
        container(size) {
            this.$emit('container-size-change', size);
        },
    },
    mounted() {
        this.renderPlayerControls = true;
        if (this.shouldPauseOnBlur) {
            this.removeVisibilityChange = onVisibilityChange(this.handleTabLoseFocus);
        }
    },
    beforeDestroy() {
        this.vjs?.dispose();
        if (this.removeVisibilityChange) {
            this.removeVisibilityChange();
        }
    },
    components: {
        VideoPlayerControls,
        VideoPlayerVideoOverlay,
        VideoPlayerWatermark,
    },
});
