import Vue from 'vue';
import RecentlyDeletedAssetsButton from './RecentlyDeletedAssetsButton.vue';
import RecentlyDeletedVideosList from './RecentlyDeletedVideosList.vue';
import RecentlyDeletedChannelsList from './RecentlyDeletedChannelsList.vue';
export default Vue.extend({
    name: 'RestoreRecentlyDeletedAssets',
    props: {
        deletedVideos: { type: Array, required: true },
        deletedChannels: { type: Array, required: true },
    },
    data() {
        return {
            dialog: false,
            restoring: false,
            selectedVideos: [],
            selectedChannels: [],
        };
    },
    methods: {
        async restoreVideo() {
            for (let video of this.selectedVideos) {
                await this.$db
                    .video(video.id)
                    .restore()
                    .then(async () => {
                    const ctx = await this.$auditLogger(this.$store.state.uid);
                    if (ctx) {
                        const { Asset_Model, EVENT_ACTION, ts } = ctx.config;
                        const asset = {
                            id: video.id,
                            type: Asset_Model.Video,
                            name: video.title,
                        };
                        await ctx.exec(asset, {
                            timestamp: ts(),
                            action: EVENT_ACTION.RESTORED,
                            data: {
                                type: 'INFO',
                                payload: {
                                    message: `Soft deleted video is restored`,
                                },
                            },
                        });
                    }
                });
            }
        },
        async restoreChannel() {
            for (let channel of this.selectedChannels) {
                await this.$db.channel(channel.id).restore();
            }
        },
        restoreDeletedAssets() {
            void this.$confirm(`Do you want to restore the selected assets?`, {
                buttonTrueText: 'Yes, restore',
                buttonFalseText: 'Dismiss',
            }).then(async (ok) => {
                if (ok) {
                    this.restoring = true;
                    await Promise.all([this.restoreVideo(), this.restoreChannel()]);
                    this.restoring = false;
                }
            });
        },
    },
    components: {
        RecentlyDeletedAssetsButton,
        RecentlyDeletedVideosList,
        RecentlyDeletedChannelsList,
    },
});
