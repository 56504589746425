import Vue from 'vue';
import { ChannelVisibility, LinkStatus } from '@lumiere/db/types';
export default Vue.extend({
    name: 'ChannelSettingsChannelVisibility',
    props: {
        channel: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            visibility: this.channel.visibility,
            updating: false,
        };
    },
    computed: {
        ChannelVisibility: () => ChannelVisibility,
    },
    watch: {
        'visibility': {
            async handler(visibility) {
                let payload = { visibility };
                if (visibility === ChannelVisibility.Private &&
                    this.channel.shareLink) {
                    if (this.channel.shareLink.status === LinkStatus.enabled) {
                        const disableShare = await this.$confirm(`Private channels are not shareable.\nDo you want to disable share on the channel?`, {
                            buttonTrueText: 'Continue',
                            buttonFalseText: 'Dismiss',
                        });
                        if (disableShare) {
                            payload['shareLink.status'] = LinkStatus.disabled;
                            await this.disableShareLink(this.channel.shareLink?.id);
                        }
                    }
                }
                this.updating = true;
                await this.$db.channel(this.channel.id).update(payload);
                this.updating = false;
            },
        },
        'channel.visibility': {
            handler(visibility, oldVal) {
                if (visibility !== oldVal)
                    this.visibility = visibility;
            },
        },
    },
    methods: {
        async disableShareLink(id) {
            if (id) {
                await this.$db.link(id).update({
                    status: 'disabled',
                });
            }
        },
    },
});
