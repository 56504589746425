var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"channel-metrics-table"},[(_vm.$vuetify.breakpoint.smAndUp)?_c('v-data-table',{staticClass:"channel-metrics-table__component elevation-0 px-0",attrs:{"headers":_vm.tableHeaders,"items":_vm.videoItems,"items-per-page":_vm.channelVideos.length,"hide-default-footer":true,"loading":_vm.fetchingMetrics,"loading-text":"Loading... Please wait"},scopedSlots:_vm._u([{key:"item.video",fn:function(ref){
var video = ref.item.video;
return [_c('div',{staticClass:"py-2 ml-n2",class:{ 'public-view': _vm.isPublic }},[_c('v-responsive',{attrs:{"aspect-ratio":16 / 9,"min-width":"180"}},[_c('VideoCard',_vm._b({class:{ 'video-card--disabled': !_vm.canDrillToVideo },attrs:{"video":video},scopedSlots:_vm._u([{key:"header",fn:function(){return [(_vm.$ability.can('manage', 'Channel'))?_c('v-card-actions',[_c('v-spacer'),_c('v-menu',{scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',{attrs:{"icon":""},on:{"mousedown":function($event){$event.stopPropagation();},"click":function($event){$event.stopPropagation();$event.preventDefault();return on.click($event)}}},[_c('v-icon',[_vm._v("mdi-dots-vertical")])],1)]}}],null,true)},[_c('v-list',{attrs:{"dense":""}},[_c('v-list-item',{on:{"click":function () { return _vm.removeVideo(video); }}},[_c('v-list-item-title',[_vm._v(" Remove from Channel ")])],1)],1)],1)],1):_vm._e()]},proxy:true}],null,true)},"VideoCard",_vm._d({},[_vm.routeLinkType,_vm.videoURL(video)])))],1)],1)]}},{key:"header.channel_score",fn:function(ref){
var header = ref.header;
return [_c('span',{staticStyle:{"display":"inline-flex","justify-content":"center","align-items":"center"}},[_c('v-img',{staticStyle:{"margin-right":"5px","object-fit":"contain"},attrs:{"src":"/img/icons/android-chrome-192x192.png","max-width":"10"}}),_vm._v(" "+_vm._s(header.text)+" ")],1)]}},{key:"item.total_views",fn:function(ref){
var total_views = ref.item.total_views;
return [_c('v-card-text',{staticClass:"primary--text px-0",staticStyle:{"font-weight":"400","font-size":"18px"}},[(_vm.fetchingMetrics)?_c('ValueSkeletonLoader'):_c('span',[_vm._v(" "+_vm._s(total_views)+" ")])],1)]}},{key:"item.avg_retention",fn:function(ref){
var avg_retention = ref.item.avg_retention;
return [_c('v-card-text',{staticClass:"black--text px-0",staticStyle:{"font-weight":"400","font-size":"18px","margin-left":"0","padding-left":"0"}},[(_vm.fetchingMetrics)?_c('ValueSkeletonLoader'):_c('span',[_vm._v(" "+_vm._s(_vm._f("avgPercentFilter")(avg_retention[0]))+" "),_c('span',{staticStyle:{"font-size":"14px"}},[_vm._v(" / "+_vm._s(_vm._f("formatTime")(avg_retention[1]))+" ")])])],1)]}},{key:"item.viewing_share",fn:function(ref){
var viewing_share = ref.item.viewing_share;
return [_c('v-card-text',{staticClass:"black--text px-0",staticStyle:{"font-weight":"400","font-size":"18px"}},[(_vm.fetchingMetrics)?_c('ValueSkeletonLoader'):_c('span',[_vm._v(" "+_vm._s(_vm._f("viewingShare")(viewing_share))+" ")])],1)]}},{key:"item.channel_score",fn:function(ref){
var channel_score = ref.item.channel_score;
return [_c('v-card-text',{staticClass:"primary--text channel-metrics-table__channel-score px-0"},[(_vm.fetchingMetrics)?_c('ValueSkeletonLoader',{staticStyle:{"margin-left":"10px"}}):_c('span',{staticClass:"text"},[_vm._v(" "+_vm._s(channel_score)+" ")])],1)]}}],null,false,3551567501)}):_c('v-row',{staticClass:"channel-metrics-table__mobile-view",class:{ 'public-view': _vm.isPublic }},_vm._l((_vm.videoItems),function(ref){
      var video = ref.video;
      var total_views = ref.total_views;
      var avg_retention = ref.avg_retention;
      var viewing_share = ref.viewing_share;
      var channel_score = ref.channel_score;
return _c('v-col',{key:video.id,staticClass:"my-2",attrs:{"cols":"12"}},[_c('div',{staticClass:"channel-metrics-table__mobile-view--video-card"},[_c('VideoCard',_vm._b({class:{ 'video-card--disabled': !_vm.canDrillToVideo },attrs:{"video":video},scopedSlots:_vm._u([{key:"header",fn:function(){return [(_vm.$ability.can('manage', 'Channel'))?_c('v-card-actions',[_c('v-spacer'),_c('v-menu',{scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
return [_c('v-btn',{attrs:{"icon":""},on:{"mousedown":function($event){$event.stopPropagation();},"click":function($event){$event.stopPropagation();$event.preventDefault();return on.click($event)}}},[_c('v-icon',[_vm._v("mdi-dots-vertical")])],1)]}}],null,true)},[_c('v-list',{attrs:{"dense":""}},[_c('v-list-item',{on:{"click":function () { return _vm.removeVideo(video); }}},[_c('v-list-item-title',[_vm._v("Remove from Channel")])],1)],1)],1)],1):_vm._e()]},proxy:true}],null,true)},"VideoCard",_vm._d({},[_vm.routeLinkType,_vm.videoURL(video)])))],1),_c('div',{staticClass:"channel-metrics-table__mobile-view--content"},[_c('table',{attrs:{"border":"0"}},[(_vm.fetchingMetrics)?_c('tr',[_c('td',{staticStyle:{"width":"100%"}},[_c('v-skeleton-loader',{attrs:{"type":"list-item"}})],1)]):(
              !_vm.fetchingMetrics &&
              _vm.canSeeShareOutput(_vm.channelOutputsEnum.VIDEO_VIEWS)
            )?_c('tr',[_c('td',[_vm._v("Views")]),_c('td',[_c('v-card-text',{staticClass:"primary--text",staticStyle:{"font-weight":"400","font-size":"18px"}},[_vm._v(" "+_vm._s(total_views)+" ")])],1)]):_vm._e(),(_vm.fetchingMetrics)?_c('tr',[_c('td',{staticStyle:{"width":"100%"}},[_c('v-skeleton-loader',{attrs:{"type":"list-item"}})],1)]):(
              !_vm.fetchingMetrics &&
              _vm.canSeeShareOutput(_vm.channelOutputsEnum.AVG_RETENTION)
            )?_c('tr',[_c('td',[_vm._v("Average Retention")]),_c('td',[_c('v-card-text',{staticClass:"black--text",staticStyle:{"font-weight":"400","font-size":"18px"}},[_vm._v(" "+_vm._s(_vm._f("avgPercentFilter")(avg_retention[0]))+" "),_c('span',{staticStyle:{"font-size":"14px"}},[_vm._v(" / "+_vm._s(_vm._f("formatTime")(avg_retention[1]))+" ")])])],1)]):_vm._e(),(_vm.fetchingMetrics)?_c('tr',[_c('td',{staticStyle:{"width":"100%"}},[_c('v-skeleton-loader',{attrs:{"type":"list-item"}})],1)]):(
              !_vm.fetchingMetrics &&
              _vm.canSeeShareOutput(_vm.channelOutputsEnum.VIEWING_SHARE)
            )?_c('tr',[_c('td',[_vm._v("Share of Watching")]),_c('td',[_c('v-card-text',{staticClass:"black--text",staticStyle:{"font-weight":"400","font-size":"18px"}},[_vm._v(" "+_vm._s(_vm._f("viewingShare")(viewing_share))+" ")])],1)]):_vm._e(),(_vm.fetchingMetrics)?_c('tr',[_c('td',{staticStyle:{"width":"100%"}},[_c('v-skeleton-loader',{attrs:{"type":"list-item"}})],1)]):(
              !_vm.fetchingMetrics &&
              _vm.canSeeShareOutput(_vm.channelOutputsEnum.CHANNEL_SCORE)
            )?_c('tr',[_c('td',[_c('span',{staticClass:"d-flex flex-row justify-start align-center"},[_c('v-img',{staticStyle:{"margin-right":"5px"},attrs:{"src":"/img/icons/android-chrome-192x192.png","max-width":"13"}}),_vm._v(" Score ")],1)]),_c('td',[_c('v-card-text',{staticClass:"primary--text channel-metrics-table__channel-score"},[_c('span',{staticClass:"text"},[_vm._v(" "+_vm._s(channel_score)+" ")])])],1)]):_vm._e()])])])}),1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }