import Vue from 'vue';
import { VideoOutputsEnum, VideoPageOptionsEnum, } from '@lumiere/db/types';
import ColorPicker from '@/components/common/SettingsColorPicker.vue';
export default Vue.extend({
    name: 'VideoSettingsShareOptions',
    props: {
        video: Object,
    },
    data() {
        return {
            configDialog: false,
            saving: false,
            selectedOutputs: [],
            selectedPageOptions: [],
            passwordText: '',
            showPassword: false,
            colorOverride: null,
            pickerOpen: false,
            safetyGlass: false,
        };
    },
    computed: {
        pageOptions() {
            return Object.values(VideoPageOptionsEnum);
        },
        sharedOutputs() {
            return Object.values(VideoOutputsEnum);
        },
        rules() {
            return {
                maxlength: (value) => value.length < 20 || 'Password may be too long',
            };
        },
    },
    methods: {
        async updateConfiguration() {
            // save the share link configuration
            const lid = this.video.shareLink?.id;
            if (!lid)
                return;
            this.saving = true;
            const payload = {
                outputs: this.selectedOutputs,
                pageOptions: {
                    options: this.selectedPageOptions,
                    password: this.passwordText,
                    color: this.colorOverride,
                },
            };
            await this.$db.link(lid).update({
                configuration: payload,
            });
            this.saving = false;
            this.configDialog = false;
        },
        onChangePassword(text) {
            this.passwordText = text.split(' ').join('');
        },
    },
    watch: {
        pickerOpen(newVal, oldVal) {
            if (newVal && !oldVal) {
                this.safetyGlass = true;
            }
            else if (!newVal && oldVal) {
                this.safetyGlass = false;
            }
        },
        $attrs: {
            handler(props) {
                const { selectedOutputs, selectedPageOptions, passwordText, colorOverride, } = props;
                this.selectedOutputs = selectedOutputs;
                this.selectedPageOptions = selectedPageOptions;
                this.passwordText = passwordText;
                this.colorOverride = colorOverride;
            },
            immediate: true,
            deep: true,
        },
    },
    components: {
        ColorPicker,
    },
});
