import { render, staticRenderFns } from "./CustomCSS.vue?vue&type=template&id=79b0ae66&scoped=true&"
import script from "./CustomCSS.vue?vue&type=script&lang=ts&"
export * from "./CustomCSS.vue?vue&type=script&lang=ts&"
import style0 from "./CustomCSS.vue?vue&type=style&index=0&id=79b0ae66&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/.pnpm/vue-loader@15.9.7_fb85a9817f99bdf862e5e3a442a1fb91/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "79b0ae66",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../../node_modules/.pnpm/vuetify-loader@1.7.2_c225a462abeaf467a2189c5bd12e241a/node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCol } from 'vuetify/lib/components/VGrid';
import { VExpansionPanel } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanelContent } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanelHeader } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanels } from 'vuetify/lib/components/VExpansionPanel';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTextarea } from 'vuetify/lib/components/VTextarea';
installComponents(component, {VCol,VExpansionPanel,VExpansionPanelContent,VExpansionPanelHeader,VExpansionPanels,VRow,VTextarea})
