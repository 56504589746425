/**
 * Fuzzy searching is an approximate string matching/search
 * read more here: https://fusejs.io
 */
import Fuse from 'fuse.js';
/**
 * @param array array of items to search against
 * @param options options object which specifies key and search score
 */
export default function FuzzySearch(array, options) {
    const baseOptions = {
        // isCaseSensitive: false,
        // includeScore: false,
        // includeMatches: false,
        // findAllMatches: false,
        useExtendedSearch: true,
        // ignoreLocation: false,
        // ignoreFieldNorm: false,
        // minMatchCharLength:0,
        threshold: 0.4,
        shouldSort: true,
        location: 0,
        distance: 100,
        keys: [],
    };
    const index = Fuse.createIndex(options.keys ?? [], array);
    return new Fuse(array, { ...baseOptions, ...options }, index);
}
