/**
 * data structure for elasticsearch query aggregates
 */
export interface AggBucketItem {
  [key: string]: string | number
  key: string
  /**
   * document count
   */
  doc_count: number
}

/**
 * a hashmap of AggBucketItem
 */
export interface HashAggBucket {
  doc_count: number
  buckets: {
    [key: string]: AggBucketItem
  }
}

/**
 * metadata of an elasticsearch query including:
 */
export interface QueryMetadata {
  [key: string]: any
  /**
   * query params e.g. ?size=500&from=10&field_path=hits.hits._source.{path-to-data}
   */
  filter_path?: string
  /**
   * a mapping which tells elasticsearch how to structure the response
   */
  _source?: {
    [key: string]: any
    includes?: string[]
    excludes?: string[]
  }
  /**
   * elasticsearch query aggregation
   */
  aggs?: {
    [key: string]: any
  }
}

export enum ESFeatureTypes {
  Emoji = 'emoji',
  Comment = 'comment',
  ExternalData = 'external.data',
  Question = 'question',
}
