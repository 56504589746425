var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"channel-metrics-header"},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',{staticStyle:{"display":"flex","justify-content":"center","align-items":"center"},attrs:{"elevation":"1"}},[_c('v-row',{staticClass:"mx-2",attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"12","sm":"6","md":"3","lg":"3"}},[_c('v-card',{staticClass:"d-flex flex-column justify-center align-center",attrs:{"elevation":"0"}},[_c('v-card-title',{class:[
                'title-text',
                { 'title-text--small-screen': _vm.$vuetify.breakpoint.smAndDown } ]},[_vm._v(" Video Views ")]),_c('v-card-subtitle',{staticClass:"display-1 primary--text mt-0"},[(_vm.fetchingMetrics)?_c('ValueSkeletonLoader'):_c('span',[_vm._v(" "+_vm._s(_vm._f("formatNumber")(_vm.total_video_views,'0'))+" ")])],1)],1)],1),_c('v-col',{attrs:{"cols":"12","sm":"6","md":"3","lg":"3"}},[_c('v-card',{staticClass:"d-flex flex-column justify-center align-center",attrs:{"elevation":"0"}},[_c('v-card-title',{class:[
                'title-text',
                { 'title-text--small-screen': _vm.$vuetify.breakpoint.smAndDown } ]},[_vm._v(" Total Watch Time ")]),_c('v-card-subtitle',{staticClass:"display-1 primary--text mt-0"},[(_vm.fetchingMetrics)?_c('ValueSkeletonLoader'):_c('span',[_vm._v(" "+_vm._s(_vm._f("formatTime")(_vm.total_viewing_time))+" ")])],1)],1)],1),_c('v-col',{attrs:{"cols":"12","sm":"6","md":"3","lg":"3"}},[_c('v-card',{staticClass:"d-flex flex-column justify-center align-center",attrs:{"elevation":"0"}},[_c('v-card-title',{class:[
                'title-text',
                { 'title-text--small-screen': _vm.$vuetify.breakpoint.smAndDown } ]},[_vm._v(" Avg Watch Amount ")]),_c('v-card-subtitle',{staticClass:"display-1 primary--text mt-0"},[(_vm.fetchingMetrics)?_c('ValueSkeletonLoader'):_c('span',[_vm._v(" "+_vm._s(_vm._f("avg_retention_percent")(_vm.avg_retention))+" ")])],1)],1)],1),_c('v-col',{attrs:{"cols":"12","sm":"6","md":"3","lg":"3"}},[_c('v-card',{staticClass:"d-flex flex-column justify-center align-center",attrs:{"elevation":"0"}},[_c('v-card-title',{class:[
                'title-text',
                { 'title-text--small-screen': _vm.$vuetify.breakpoint.smAndDown } ]},[_vm._v(" Avg Watch Time ")]),_c('v-card-subtitle',{staticClass:"display-1 primary--text mt-0"},[(_vm.fetchingMetrics)?_c('ValueSkeletonLoader'):_c('span',[_vm._v(" "+_vm._s(_vm._f("formatTime")(_vm.avg_views))+" ")])],1)],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }