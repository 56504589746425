import Vue from 'vue';
import { getBrowserQueryParam } from '@/utils/api';
import ColorSpinBG from '@/components/ColorSpinBG.vue';
import logger from '@lumiere/shared/services/logger';
import { Routes } from '@/router/router';
import { mapState } from 'vuex';
import { EmailModes } from './types';
import PasswordReset from './PasswordReset.vue';
import EmailVerification from './EmailVerification.vue';
import auth from '@/services/firebase/auth';
import EmailLinkLogin from './EmailLinkLogin.vue';
export default Vue.extend({
    name: 'EmailActionHandlers',
    data() {
        return {
            mode: null,
            actionCode: null,
            loading: false,
            loadingMessage: '',
            actionHandlerDialog: true,
            continueUrl: null,
        };
    },
    computed: {
        ...mapState(['uid']),
        isPasswordReset() {
            return this.mode === EmailModes.RESET_PASSWORD;
        },
        isEmailVerification() {
            return this.mode === EmailModes.VERIFY_EMAIL;
        },
        isEmailLink() {
            return auth.isSignInWithEmailLink(window.location.href);
        },
        Routes() {
            return Routes;
        },
    },
    methods: {
        onVerificationComplete() {
            if (this.continueUrl)
                location.href = this.continueUrl;
        },
        onVerficationError(e) {
            this.$router.push({
                name: Routes.Login,
                params: {
                    relayedErrorMessage: e.message,
                },
            });
        },
        onEmailLinkSuccess() {
            // navigate to app
        },
        onEmailLinkError(e) {
            this.$router.push({
                name: Routes.Login,
                params: {
                    relayedErrorMessage: e.message,
                },
            });
        },
        onPasswordResetError(e) {
            this.$router.push({
                name: Routes.Login,
                params: {
                    relayedErrorMessage: e.message,
                },
            });
        },
    },
    created() {
        // Get the action to complete.
        this.mode = getBrowserQueryParam('mode');
        // Get the one-time code from the query parameter.
        this.actionCode = getBrowserQueryParam('oobCode');
        // (Optional) Get the continue URL from the query parameter if available.
        this.continueUrl = getBrowserQueryParam('continueUrl');
        logger.info('EmailActionHandler Params', {
            mode: this.mode,
            actionCode: this.actionCode,
            continueUrl: this.continueUrl,
        });
    },
    watch: {
        uid: {
            handler(uid) {
                if (uid) {
                    this.$router.push({
                        name: Routes.Landing,
                    });
                }
            },
            immediate: true,
        },
    },
    components: {
        ColorSpinBG,
        PasswordReset,
        EmailVerification,
        EmailLinkLogin,
    },
});
