import Vue from 'vue';
import Logo from '@/components/navbar/Logo.vue';
import UserMenuList from '@/components/navbar/UserMenuList.vue';
import WorkspaceSelect from './WorkspaceSelect.vue';
import { mapGetters } from 'vuex';
export default Vue.extend({
    name: 'NavDrawer',
    props: {
        value: Boolean,
        content: Array,
    },
    data() {
        return {};
    },
    computed: {
        ...mapGetters(['currentWorkspace']),
        color() {
            return this.currentWorkspace?.theme.color ?? '';
        },
    },
    watch: {},
    methods: {},
    components: {
        Logo,
        UserMenuList,
        WorkspaceSelect,
    },
});
