import Vue from 'vue';
import anime from 'animejs';
import InteractiveEmoji from './EmojiFeedbackInteractiveEmoji.vue';
import GlobalFlyingEmojis from './EmojiFeedbackGlobalFlyingEmojis.vue';
const FLYOUT_DURATION = 3000;
export default Vue.extend({
    name: 'EmojiFeedbackEmojis',
    inheritAttrs: false,
    data() {
        return {
            flyingEmoji: [Date.now()],
        };
    },
    components: {
        InteractiveEmoji,
        GlobalFlyingEmojis,
    },
    methods: {
        onEnter(el, done) {
            anime({
                targets: el,
                translateY: [72, 0],
                opacity: [0, 1],
                easing: 'cubicBezier(0.345, 0.495, 0.445, 1.300)',
                duration: 500,
                complete() {
                    done();
                },
            });
        },
        onLeave(el, done) {
            el.classList.add('leaving');
            const rand = anime.random(-100, 100);
            anime({
                targets: el,
                duration: FLYOUT_DURATION,
                translateY: [0, -window.innerHeight * 0.9],
                translateX: [0, `${rand}%`, `${-rand / 2}%`, 0],
                scale: 0.5,
                opacity: 0,
                easing: 'easeOutSine',
                // easing: 'linear',
                complete() {
                    done();
                },
            });
        },
        onEngage(duration) {
            this.$emit('engage', duration);
            this.flyingEmoji.push(Date.now());
            this.flyingEmoji.shift();
        },
    },
});
