import Vue from 'vue';
import { mapState } from 'vuex';
import * as am4core from '@amcharts/amcharts4/core';
import { getHexColor } from '@/colors';
import { getBrowserQueryParam } from '@/utils/api';
const ThemeColor = Vue.extend({
    computed: {
        ...mapState(['link', 'currentWorkspace']),
        themeColorHex() {
            const linkColor = getBrowserQueryParam('link')
                ? this.link?.configuration?.pageOptions.color
                : null;
            const wsColor = this.$store.getters.currentWorkspace?.theme.color;
            const finalPrimaryColor = linkColor || wsColor;
            return getHexColor(finalPrimaryColor);
        },
        themeColorAm4Core() {
            return am4core.color(this.themeColorHex);
        },
    },
});
export default ThemeColor;
