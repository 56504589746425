import Vue from 'vue';
import LoginForm from './auth/LoginForm.vue';
import ColorSpinBG from '@/components/ColorSpinBG.vue';
import { Routes } from '@/router/router';
import { mapState, mapGetters } from 'vuex';
import Logo from '@/components/navbar/Logo.vue';
import auth from '@/services/auth';
import Welcome from './Welcome.vue';
import MUTATIONS from '@/store/MUTATIONS';
export default Vue.extend({
    data: () => ({
        loginDialog: false,
    }),
    props: {
        relayedErrorMessage: String,
    },
    computed: {
        Routes() {
            return Routes;
        },
        ...mapState(['uid', 'user', 'redirectAfterLogin']),
        ...mapGetters(['defaultWorkspace', 'workspacesList']),
        isPublic() {
            return !!this.$route.query.link;
        },
        userHasNoWorkspace() {
            return this.uid && this.workspacesList?.length === 0;
        },
    },
    //Handles home/login to home/
    beforeRouteUpdate(to, _from, next) {
        if (to.name == Routes.Login) {
            this.loginDialog = true;
        }
        else {
            this.loginDialog = false;
        }
        next();
    },
    //Handles home/ to home/login
    beforeRouteLeave(to, _from, next) {
        if (to.name == Routes.Login) {
            this.loginDialog = true;
        }
        else {
            this.loginDialog = false;
        }
        next();
    },
    //Handles the case of the user clearing out the dialog by clicking close
    //or clicking the background or something like that.
    watch: {
        loginDialog(newVal, oldVal) {
            if (oldVal == true && newVal == false) {
                this.$router.replace({ name: Routes.Landing });
            }
        },
        defaultWorkspace: {
            async handler(workspaceId) {
                if (this.redirectAfterLogin) {
                    const redirectAfterLogin = this.redirectAfterLogin;
                    this.$store.commit(MUTATIONS.SET_REDIRECT_AFTER_LOGIN, null);
                    this.$router.push(redirectAfterLogin);
                }
                else if (workspaceId && !this.isPublic) {
                    const hasPassword = await auth.userHasPassword();
                    if (hasPassword) {
                        this.$router.push({
                            name: Routes.Videos,
                            params: { workspaceId },
                        });
                    }
                    else {
                        this.$router.push({
                            name: Routes.Join,
                            params: {
                                workspaceId,
                                email: this.user?.email,
                                hasPassword: String(hasPassword),
                            },
                        });
                    }
                }
            },
            immediate: true,
        },
    },
    created() {
        if (this.$route.name == Routes.Login) {
            this.loginDialog = true;
        }
        else {
            this.loginDialog = false;
        }
    },
    components: {
        LoginForm,
        Logo,
        ColorSpinBG,
        Welcome,
    },
});
