import Vue from 'vue';
import InteractiveEmoji from './EmojiFeedbackInteractiveEmoji.vue';
import { nanoid } from 'nanoid';
import anime from 'animejs';
const FLYOUT_DURATION = 3000;
export default Vue.extend({
    name: 'EmojiFeedbackGlobalFlyingEmojis',
    data() {
        return {
            emojis: [],
        };
    },
    computed: {
        flyOutEmojis() {
            return this.$attrs?.flyOutEmojis;
        },
        globalFlyingEmojisData() {
            return (this.flyOutEmojis || []);
        },
        currentTime() {
            return (this.$attrs.currentTime ?? 0);
        },
    },
    methods: {
        flyoutOnEnter(el, done) {
            const rand = anime.random(-25, 25);
            anime({
                targets: el,
                translateY: [40, 0],
                translateX: [0, `${rand}%`, `${-rand / 2}%`],
                scale: 1,
                opacity: [0, 0.4],
                easing: 'cubicBezier(0.345, 0.495, 0.445, 1.300)',
                duration: 500,
                complete() {
                    done();
                },
            });
        },
        flyoutOnLeave(el, done) {
            el.classList.add('leaving');
            const rand = anime.random(-100, 100);
            anime({
                targets: el,
                duration: FLYOUT_DURATION,
                translateY: [0, -window.innerHeight * 0.1],
                translateX: [`${rand}%`, `${-rand / 2}%`, 0],
                scale: 0.8,
                opacity: [0.4, 0.1, 0],
                easing: 'easeOutCubic',
                complete() {
                    done();
                },
            });
        },
    },
    watch: {
        currentTime(time, oldTime) {
            if (time !== oldTime) {
                const emojisToFlyOut = this.globalFlyingEmojisData
                    .filter((el) => {
                    return Math.abs(el.currentTime - time) <= 0.1;
                })
                    .map((el) => ({ ...el.emoji, randomId: nanoid(5) }));
                this.$nextTick(() => {
                    this.emojis = emojisToFlyOut;
                });
            }
        },
    },
    components: {
        InteractiveEmoji,
    },
});
