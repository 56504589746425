import Vue from 'vue';
export default Vue.extend({
    name: 'WorkspaceCardImageItem',
    props: {
        color: String,
        workspace: {
            type: Object,
        },
        showUploadButton: {
            type: Boolean,
            default: true,
        },
        isCard: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            image: '',
        };
    },
    computed: {
        wsAvatarLabel() {
            let wsName = (this.workspace?.name || '" "').replace(/-/g, ' ').split(' ');
            return wsName
                .map((name) => name.charAt(0).toUpperCase())
                .slice(0, 2)
                .join('');
        },
    },
    methods: {
        openFileDialog() {
            if (this.isCard)
                return;
            const fileDialogRef = this.$refs.fileDialog;
            fileDialogRef.value = '';
            fileDialogRef.click();
        },
        onchangeFileDialog(files) {
            const file = files.item(0);
            if (!file)
                return;
            const fileReader = new FileReader();
            const vm = this;
            fileReader.onload = (e) => {
                vm.image = e.target?.result;
                const dataURL = vm.image;
                vm.$emit('file-selected', dataURL);
            };
            fileReader.readAsDataURL(file);
        },
    },
    watch: {
        'workspace.iconURL': {
            handler(url) {
                this.image = url ?? '';
            },
            immediate: true,
        },
    },
});
