import Vue from 'vue';
import adminAPI from '@/services/adminAPI';
export default Vue.extend({
    name: 'ChoiceQuestionDataExport',
    props: {
        videoId: {
            type: String,
            required: true,
        },
        featureId: {
            type: String,
            required: true,
        },
    },
    data() {
        return {
            isDownloading: false,
            snackbar: false,
            snackbarMessage: '',
            video: null,
        };
    },
    subscriptions() {
        return {
            video: this.$db.video(this.$props.videoId).observe(),
        };
    },
    computed: {
        cannotExportData() {
            return this.$ability.cannot('read', 'Elasticsearch.ExportAnalyticsData');
        },
    },
    methods: {
        downloadVideoChoiceQuestionData() {
            this.isDownloading = true;
            adminAPI((api) => api.video.exports.getVideoChoiceQuestionDataExport({
                vid: this.videoId,
                fid: this.featureId,
            }))
                .then((downloadData) => {
                this.downloadCSV(downloadData.csv);
            })
                .catch((err) => {
                this.snackbar = true;
                this.snackbarMessage = err.message;
            })
                .finally(() => {
                this.isDownloading = false;
            });
        },
        downloadCSV(csv) {
            const videoName = this.video?.title ?? '';
            const filename = `${videoName}_${new Date().toISOString()}_question.csv`;
            const data = encodeURIComponent(csv);
            this.$download(data, filename, { type: 'text/plain' });
            this.snackbar = true;
            this.snackbarMessage = `Question data download is complete`;
        },
    },
});
