import Vue from 'vue';
export default Vue.extend({
    name: 'ChannelDetailsLink',
    props: {
        channel: {
            type: Object,
            required: true,
        },
    },
    computed: {
        channelURL() {
            return this.$store.getters.channelURL(this.channel.id);
        },
    },
});
