var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:[
    'global-searchbar mx-0 pa-0',
    { 'sm--global-searchbar': _vm.isSmScreen } ]},[(_vm.isXsScreen && _vm.showSearchButton)?_c('v-btn',{attrs:{"icon":"","dark":""},on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.onClickSmallXsSearchPromptButton($event)}}},[_c('v-icon',[_vm._v("mdi-magnify")])],1):_vm._e(),(!_vm.isXsScreen ? true : !_vm.showSearchButton)?_c('v-text-field',{class:[
      'global-searchbar__input pt-0 px-1',
      {
        'animate-searchbar--input': !_vm.isSmScreen,
        'sm--searchbar--input': _vm.isSmScreen,
        'xs--searchbar--input': _vm.isXsScreen,
      } ],attrs:{"autocomplete":"off","hide-details":"","prepend-icon":"mdi-magnify","dense":_vm.isXsScreen,"dark":"","placeholder":_vm.placeholder,"clearable":"","autofocus":_vm.isXsScreen},on:{"focus":function () { return (_vm.placeholder = 'Search all content'); },"blur":_vm.onBlur,"click":function($event){$event.preventDefault();$event.stopPropagation();}},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}):_vm._e(),_c('div',{class:[
      'global-searchbar__menu--container pa-0 ma-0',
      { 'sm--searchbar__menu--container': _vm.isSmScreen } ],attrs:{"id":"menu--container"}},[_c('v-menu',{staticClass:"global-searchbar__menu--item",attrs:{"attach":"#menu--container","bottom":"","transition":"scroll-y-transition","close-on-content-click":false,"nudge-width":200,"max-width":450,"offset-x":""},model:{value:(_vm.searchDialog),callback:function ($$v) {_vm.searchDialog=$$v},expression:"searchDialog"}},[_c('v-card',{staticClass:"global-searchbar__content"},[_c('v-card-text',{staticClass:"global-searchbar__content--scrollable mx-0 pa-0"},[(_vm.queriedVideos.length)?_c('GlobalSearchVideos',{attrs:{"videos":_vm.queriedVideos},on:{"close-menu":function () { return (_vm.searchDialog = false); }}}):_vm._e(),_c('v-divider'),(_vm.queriedChannels.length)?_c('GlobalSearchChannels',{attrs:{"channels":_vm.queriedChannels},on:{"close-menu":function () { return (_vm.searchDialog = false); }}}):_vm._e(),_c('v-divider'),(_vm.queriedWorkspaces.length)?_c('GlobalSearchWorkspaces',{attrs:{"workspaces":_vm.queriedWorkspaces},on:{"close-menu":function () { return (_vm.searchDialog = false); }}}):_vm._e()],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }