import Vue from 'vue';
import { EMAIL_LINK_SIGN_IN_METHOD, EMAIL_PASSWORD_SIGN_IN_METHOD, SOCIAL_PROVIDER_IDS, getPlainName, SOCIAL_SIGN_IN_METHODS, SIGN_IN_METHODS, } from '@/services/auth';
import fauth, { FirebaseAuth } from '@/services/firebase/auth';
import emailIsValid from '@lumiere/shared/utils/emailIsValid';
import firestore from '@/services/firebase/firestore';
import paths from '@lumiere/db/src/helpers/paths';
import locallyStored from '@lumiere/shared/utils/locallyStored';
import stringIsTruthy from '@/utils/stringIsTruthy';
import LoginWithAccount from '@/components/auth/LoginWithAccount.vue';
import LoginWithPasswordForm from '@/components/auth/LoginWithPasswordForm.vue';
import LoginWithLinkForm from '@/components/auth/LoginWithLinkForm.vue';
import adminAPI from '@/services/adminAPI';
import logger from '@lumiere/shared/services/logger';
import intersection from 'lodash/intersection';
import LoginWithSocialAccount from '@/components/auth/LoginWithSocialAccount.vue';
import { Routes } from '@/router/router';
const locallyStoredEmail = locallyStored('EMAIL');
const locallyStoredPendingCred = locallyStored('PENDING_CRED');
export default Vue.extend({
    name: 'LoginForm',
    props: {
        showOrHide: Boolean,
        relayedErrorMessage: { type: String },
    },
    data: () => {
        const email = locallyStoredEmail.get() || '';
        return {
            errors: [],
            loadingMessage: '',
            email,
            submitState: false,
            emailErrors: [],
            emailConfirmed: false,
            sendSignInLinkErrors: [],
            signInMethod: null,
            signInLinkSent: false,
            password: '',
            passwordValid: false,
            passwordErrors: [],
            user: null,
            success: false,
            pendingCred: null,
            submitEmailError: '',
        };
    },
    computed: {
        loading() {
            return !!this.loadingMessage;
        },
        computedEmailErrors() {
            return this.submitState ? this.emailErrors : [];
        },
        emailValid() {
            return stringIsTruthy(this.email) && emailIsValid(this.email);
        },
        isSocialSignIn() {
            return SOCIAL_SIGN_IN_METHODS.includes(this.signInMethod);
        },
        authSignInMethods() {
            return SIGN_IN_METHODS;
        },
    },
    methods: {
        async getUserByEmail(email) {
            // Because user is not authenticated we're connecting to firestore service directly
            const users = await firestore
                .collection(paths.users())
                .where('email', '==', email)
                .limit(1)
                .get();
            if (users.empty) {
                this.$router.push({
                    name: Routes.Join,
                    params: { email: this.email },
                });
                throw new Error('No user with such email');
            }
            const user = users.docs[0];
            return { id: user.id, ...user.data() };
        },
        disconfirmEmail() {
            this.emailConfirmed = false;
            this.signInMethod = null;
            this.emailErrors = [];
            this.passwordErrors = [];
            this.password = '';
            this.email = '';
        },
        async submitEmail() {
            this.submitState = true;
            this.emailErrors = [];
            locallyStoredEmail.remove();
            try {
                const { email } = this;
                if (this.emailValid) {
                    this.loadingMessage = 'Checking our records...';
                    const [user, signInMethod] = await Promise.all([
                        this.getUserByEmail(email),
                        this.getSignInMethod(),
                    ]);
                    this.user = user;
                    this.signInMethod = signInMethod;
                    if (signInMethod) {
                        this.emailConfirmed = true;
                    }
                    else {
                        this.emailConfirmed = false;
                        this.submitEmailError =
                            'Email not verified. Please use social authentication or the invitation link sent to the email address';
                    }
                }
                else {
                    throw new Error(!email ? 'Email is required' : 'Email is invalid');
                }
            }
            catch (e) {
                this.emailErrors = [e.message];
            }
            this.loadingMessage = '';
        },
        async sendSignInLink() {
            this.loadingMessage = 'Sending Magic Link...';
            locallyStoredEmail.set(this.email);
            try {
                const actionURL = new URL(`/login`, location.origin);
                await adminAPI(async (api) => api.emails.sendMagicLink({
                    url: actionURL.href,
                    email: this.email,
                    name: this.user?.displayName?.split(' ')[0] ?? 'User',
                }));
                this.signInLinkSent = true;
            }
            catch (error) {
                this.sendSignInLinkErrors = [error.message];
            }
            this.loadingMessage = '';
        },
        async getSignInMethod() {
            const signInMethods = await fauth.fetchSignInMethodsForEmail(this.email);
            const isPassword = signInMethods.includes(EMAIL_PASSWORD_SIGN_IN_METHOD);
            const isEmailLink = signInMethods.includes(EMAIL_LINK_SIGN_IN_METHOD);
            // always show the password form
            if (isPassword || isEmailLink) {
                return SIGN_IN_METHODS.password;
            }
            // if (isEmailLink) {
            //   return SIGN_IN_METHODS.emailLink
            // }
            const socialSignInMethods = intersection(signInMethods, SOCIAL_SIGN_IN_METHODS);
            if (socialSignInMethods.length) {
                return socialSignInMethods[0];
            }
            return null;
        },
        async signInWithPassword() {
            if (this.passwordValid) {
                this.passwordErrors = [];
                const { email, password } = this;
                this.loadingMessage = 'Signing in...';
                try {
                    await fauth.signInWithEmailAndPassword(email, password);
                    await this.finaliseAccountLinking();
                    this.finalizeSignIn();
                }
                catch (error) {
                    this.loadingMessage = '';
                    this.passwordErrors = [error.message];
                    this.passwordValid = true;
                }
            }
        },
        finalizeSignIn() {
            this.loadingMessage = '';
            this.success = true;
            this.$emit('success');
        },
        onLinkAccounts(data) {
            this.signInMethod = data.method;
            this.pendingCred = data.pendingCred;
            this.email = data.email;
            this.emailConfirmed = true;
            locallyStoredPendingCred.set({ ...data.pendingCred });
        },
        async finaliseAccountLinking() {
            if (this.pendingCred) {
                try {
                    const providerId = this.pendingCred.providerId;
                    this.loadingMessage = `Linking your ${getPlainName(providerId)} account...`;
                    await fauth.currentUser?.linkWithCredential(this.pendingCred);
                }
                catch (err) {
                    logger.error('email-auth account-linking', { err });
                }
                this.pendingCred = null;
                locallyStoredPendingCred.remove();
            }
        },
        rebuildAuthProviderCredential(pendingCred) {
            const { providerId, idToken, accessToken = '', secret = '' } = pendingCred;
            switch (providerId) {
                case SOCIAL_PROVIDER_IDS.GOOGLE_PROVIDER_ID:
                    return FirebaseAuth.GoogleAuthProvider.credential(idToken);
                case SOCIAL_PROVIDER_IDS.FACEBOOK_PROVIDER_ID:
                    return FirebaseAuth.FacebookAuthProvider.credential(accessToken);
                case SOCIAL_PROVIDER_IDS.TWITTER_PROVIDER_ID:
                    return FirebaseAuth.TwitterAuthProvider.credential(accessToken, secret);
                default:
                    return pendingCred;
            }
        },
    },
    async created() {
        let pendingCred = (await locallyStoredPendingCred.get()) ?? null;
        if (pendingCred) {
            this.pendingCred = this.rebuildAuthProviderCredential(pendingCred);
        }
        // logger.info('LoginForm', { pendingCred: this.pendingCred })
    },
    filters: {
        plainName(providerId) {
            return getPlainName(providerId);
        },
    },
    watch: {
        email(newEmail, oldEmail) {
            if (newEmail !== oldEmail) {
                this.submitState = false;
            }
        },
        showOrHide(newVal, oldVal) {
            if (!newVal && oldVal) {
                this.submitState = false;
                this.emailErrors = [];
            }
        },
    },
    components: {
        LoginWithAccount,
        LoginWithPasswordForm,
        LoginWithLinkForm,
        LoginWithSocialAccount,
    },
});
