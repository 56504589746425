import Vue from 'vue';
import ColorSpinBG from '@/components/ColorSpinBG.vue';
import CreateWorkspaceForm from '@/components/CreateWorkspaceForm.vue';
export default Vue.extend({
    name: 'Welcome',
    data() {
        return {
            welcomeDialog: true,
            addWorkspaceDialog: false,
        };
    },
    methods: {
        workspaceCreated(wid) {
            this.addWorkspaceDialog = false;
            this.$router.push(`/${wid}/videos`);
        },
    },
    components: {
        ColorSpinBG,
        CreateWorkspaceForm,
    },
});
