import Vue from 'vue';
import { Routes } from '@/router/router';
export default Vue.extend({
    name: 'GlobalSearchWorkspaces',
    props: {
        workspaces: Array,
    },
    methods: {
        goToWorkspacePage(wid) {
            this.$router.push({
                name: Routes.Videos,
                params: {
                    workspaceId: wid,
                },
            });
            this.$sleep(0).then(() => {
                this.$emit('close-menu');
            });
        },
    },
});
