import Vue from 'vue';
import RecentlyDeletedTeamsButton from './RecentlyDeletedTeamsButton.vue';
import { timestampToDate } from '@/utils/helpers';
export default Vue.extend({
    name: 'RestoreRecentlyDeletedTeams',
    props: {
        deletedTeams: Array,
        videoId: String,
    },
    data() {
        return {
            dialog: false,
            restoring: false,
            selectedTeams: [],
        };
    },
    methods: {
        restoreTeam() {
            this.$confirm(`Do you want to restore the selected teams?`, {
                buttonTrueText: 'Yes, restore',
                buttonFalseText: 'Dismiss',
            }).then(async (ok) => {
                if (ok) {
                    this.restoring = true;
                    for (let team of this.selectedTeams) {
                        await this.$db.team(team.id).restore();
                    }
                    this.restoring = false;
                }
            });
        },
        timestampToDate(ts) {
            return timestampToDate(ts);
        },
    },
    watch: {},
    components: {
        RecentlyDeletedTeamsButton,
    },
});
