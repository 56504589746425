import Vue from 'vue';
export default Vue.extend({
    name: 'ListAvatar',
    props: {
        file: {
            type: File,
        },
    },
    data() {
        return {
            image: '',
        };
    },
    methods: {
        createEmojiPath(file) {
            const fileReader = new FileReader();
            const vm = this;
            fileReader.onload = (e) => {
                vm.image = e.target?.result;
            };
            fileReader.readAsDataURL(file);
        },
    },
    watch: {
        file: {
            handler(val) {
                if (val) {
                    this.createEmojiPath(val);
                }
            },
            immediate: true,
        },
    },
});
