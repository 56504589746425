var Languages;
(function (Languages) {
    Languages["ENGLISH"] = "en-US";
})(Languages || (Languages = {}));
export var VideoAIDataType;
(function (VideoAIDataType) {
    VideoAIDataType["FACE"] = "FACE";
    VideoAIDataType["SCENE"] = "SCENE";
    VideoAIDataType["SHOT"] = "SHOT";
    VideoAIDataType["TRANSCRIPT"] = "TRANSCRIPT";
})(VideoAIDataType || (VideoAIDataType = {}));
