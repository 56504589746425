import Vue from 'vue';
import { VideoAIExternalStatus, VideoAIJobStatus, } from '@lumiere/db/types';
import adminAPI from '@/services/adminAPI';
import logger from '@lumiere/shared/services/logger';
export default Vue.extend({
    name: 'VideoIntelligenceDecodeVideo',
    props: {
        video: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            decodeVideoLoading: false,
        };
    },
    computed: {
        decodeProcessing() {
            if (!this.video.videoAI?.state)
                return false;
            const { state, externalState } = this.video.videoAI;
            return ([
                VideoAIJobStatus.UPLOADING,
                VideoAIJobStatus.UPLOADED,
                VideoAIJobStatus.PROCESSING,
            ].includes(state) || externalState === VideoAIExternalStatus.INGESTING);
        },
    },
    methods: {
        // Microsoft Video Indexer
        async decodeVideo() {
            this.decodeVideoLoading = true;
            const result = await adminAPI(async (api) => api.video.videoIntelligence.decodeVideo({
                vid: this.video.id,
            })).catch((e) => {
                logger.error('decodeVideo', e);
            });
            this.decodeVideoLoading = false;
            logger.info('decodeVideo', result);
        },
    },
});
