import { render, staticRenderFns } from "./WorkspaceInsights.vue?vue&type=template&id=7dc6947e&"
import script from "./WorkspaceInsights.vue?vue&type=script&lang=ts&"
export * from "./WorkspaceInsights.vue?vue&type=script&lang=ts&"
import style0 from "./WorkspaceInsights.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/.pnpm/vue-loader@15.9.7_fb85a9817f99bdf862e5e3a442a1fb91/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../../../node_modules/.pnpm/vuetify-loader@1.7.2_c225a462abeaf467a2189c5bd12e241a/node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VResponsive } from 'vuetify/lib/components/VResponsive';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSkeletonLoader } from 'vuetify/lib/components/VSkeletonLoader';
import { VSparkline } from 'vuetify/lib/components/VSparkline';
installComponents(component, {VCard,VCardText,VCardTitle,VCol,VDataTable,VResponsive,VRow,VSkeletonLoader,VSparkline})
