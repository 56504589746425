import Vue from 'vue';
import { mapState } from 'vuex';
import UserMenuList from './UserMenuList.vue';
export default Vue.extend({
    methods: {},
    computed: {
        ...mapState(['user']),
    },
    components: {
        UserMenuList,
    },
});
