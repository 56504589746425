import Vue from 'vue';
import Vue2TouchEvents from 'vue2-touch-events';
import { isMobile, isTouchDevice } from '../utils/helpers';
let disableClick = false;
if (typeof navigator !== 'undefined') {
    // !process.env.VUE_APP_SERVER_SSR
    if (isMobile() || isTouchDevice()) {
        disableClick = true;
    }
}
Vue.use(Vue2TouchEvents, {
    disableClick,
});
