import Vue from 'vue';
import stringIsTruthy from '../../utils/stringIsTruthy';
import emailIsValid from '@lumiere/shared/utils/emailIsValid';
import LoginWithSocialAccount from './LoginWithSocialAccount.vue';
export default Vue.extend({
    name: 'LoginWithAccount',
    props: {
        submitEmail: {
            type: Function,
        },
        loading: Boolean,
        value: String,
        computedEmailErrors: {
            type: Array,
        },
        submitState: Boolean,
        storedPendingCred: {
            type: Object,
        },
        submitEmailError: String,
        relayedErrorMessage: String,
    },
    data() {
        return {
            email: this.$props.value || '',
            valert: false,
        };
    },
    computed: {
        rules() {
            return {
                validateEmailNotEmpty: (email) => {
                    if (!this.submitState) {
                        return true;
                    }
                    return !!email || 'Email is required';
                },
                validateEmailIsValid: (email) => {
                    // The emptiness is handled by the validateEmailNotEmpty function.
                    // So if it's empty, let it pass.
                    if (!stringIsTruthy(email)) {
                        return true;
                    }
                    return emailIsValid(email) || 'Email must be valid';
                },
            };
        },
        hasError() {
            return !!this.submitEmailError || !!this.relayedErrorMessage;
        },
    },
    methods: {
        clearErrors() {
            this.$emit('clear-errors');
            this.valert = false;
        },
    },
    watch: {
        hasError: {
            handler(error) {
                this.valert = error;
            },
            immediate: true,
        },
    },
    components: {
        LoginWithSocialAccount,
    },
});
