import Vue from 'vue';
import LinkSocialAccounts from '@/components/auth/LinkSocialAccounts.vue';
import { FirebaseAuth } from '@/services/firebase/auth';
import { SOCIAL_PROVIDER_IDS } from '@/services/auth';
import locallyStored from '@lumiere/shared/utils/locallyStored';
import { AuthLinkType } from '@lumiere/db/types';
import UserProfileAddPassword from './UserProfileAddPassword.vue';
const locallyStoredPendingCred = locallyStored('PENDING_CRED');
export default Vue.extend({
    name: 'UserLoginMethodsAddAccountButton',
    props: {
        providers: {
            type: Array,
        },
    },
    data() {
        return {
            dialog: false,
            pendingCred: null,
        };
    },
    computed: {
        providersIds() {
            return this.providers.map((provider) => provider?.providerId || null);
        },
        isProviderLinked() {
            return (providerId) => this.providersIds.includes(providerId);
        },
        authLinkType() {
            return AuthLinkType;
        },
    },
    methods: {
        async onLinkingSuccess(data) {
            const user = data.user;
            if (user) {
                await user.getIdToken(true);
            }
            this.dialog = false;
        },
        rebuildAuthProviderCredential(pendingCred) {
            const { providerId, idToken, accessToken = '', secret = '' } = pendingCred;
            switch (providerId) {
                case SOCIAL_PROVIDER_IDS.GOOGLE_PROVIDER_ID:
                    return FirebaseAuth.GoogleAuthProvider.credential(idToken);
                case SOCIAL_PROVIDER_IDS.FACEBOOK_PROVIDER_ID:
                    return FirebaseAuth.FacebookAuthProvider.credential(accessToken);
                case SOCIAL_PROVIDER_IDS.TWITTER_PROVIDER_ID:
                    return FirebaseAuth.TwitterAuthProvider.credential(accessToken, secret);
                default:
                    return pendingCred;
            }
        },
    },
    async created() {
        let pendingCred = (await locallyStoredPendingCred.get()) ?? null;
        if (pendingCred) {
            this.pendingCred = this.rebuildAuthProviderCredential(pendingCred);
        }
    },
    components: {
        LinkSocialAccounts,
        UserProfileAddPassword,
    },
});
