var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"text-center"},[_c('style',{tag:"component"},[_vm._v(" .v-list-item.primary--text .settings-form__select--item { color: "+_vm._s(_vm.themeColor)+" !important; } .settings-form__input.v-input .settings-form__select--selection { color: black !important; } .settings-form__input.v-input.v-input--is-focused .settings-form__select--selection { color: "+_vm._s(_vm.themeColor)+" !important; } ")]),_c('v-form',{ref:"form",on:{"submit":function($event){$event.preventDefault();return _vm.saveChanges($event)}}},[_c('v-card-text',{staticClass:"pa-6"},[_c('v-text-field',{ref:"nameField",staticClass:"settings-form__input",attrs:{"data-testid":"settings-form-workspace-name","outlined":"","label":"Workspace Name","required":"","color":_vm.themeColor,"rules":_vm.nameRules,"error-messages":_vm.customErrorMessageForNameInput,"disabled":_vm.cannotAddOrEditForm},model:{value:(_vm.name),callback:function ($$v) {_vm.name=(typeof $$v === 'string'? $$v.trim(): $$v)},expression:"name"}}),_c('v-textarea',{ref:"descriptionField",staticClass:"settings-form__input",attrs:{"data-testid":"settings-form-workspace-description","outlined":"","label":"Description","color":_vm.themeColor,"rules":_vm.descriptionRules,"disabled":_vm.cannotAddOrEditForm},model:{value:(_vm.description),callback:function ($$v) {_vm.description=(typeof $$v === 'string'? $$v.trim(): $$v)},expression:"description"}}),_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"12","xs":"12","sm":"12","md":"6","lg":"6"}},[_c('v-select',{ref:"colorField",staticClass:"settings-form__input",attrs:{"id":"settings-form-workspace-color","items":_vm.COLORS,"color":_vm.themeColor,"label":"Color","outlined":"","rules":_vm.colorRules,"disabled":_vm.cannotAddOrEditForm},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{attrs:{"color":item}},[_vm._v("mdi-circle")]),_c('span',{staticClass:"pl-1 settings-form__select--selection",staticStyle:{"text-transform":"capitalize"}},[_vm._v(" "+_vm._s(item)+" ")])]}},{key:"item",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{staticClass:"pr-2",attrs:{"color":item}},[_vm._v("mdi-circle")]),_c('span',{staticClass:"settings-form__select--item",staticStyle:{"text-transform":"capitalize"}},[_vm._v(" "+_vm._s(item)+" ")])]}}]),model:{value:(_vm.theme.color),callback:function ($$v) {_vm.$set(_vm.theme, "color", $$v)},expression:"theme.color"}})],1),_c('v-col',{attrs:{"cols":"12","xs":"12","sm":"12","md":"6","lg":"6"}},[(_vm.$can('update', 'Workspace'))?_c('WorkspaceCardImageItem',{staticClass:"mb-4",attrs:{"color":_vm.themeColor,"workspace":_vm.workspace},on:{"file-selected":_vm.onFileSelected}}):_vm._e()],1)],1),(_vm.teams && _vm.teams.length > 0)?_c('v-row',{staticClass:"settings-form__team-selector",attrs:{"no-gutters":""}},[_c('v-col',[_c('v-select',{ref:"teamField",staticClass:"settings-form__input",attrs:{"items":_vm.teams,"label":"Owner","outlined":"","item-text":"name","item-value":"id","color":_vm.themeColor,"rules":_vm.teamRules,"placeholder":"Which team will own this workspace?","disabled":_vm.cannotAddOrEditForm || !!_vm.teamId},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"pl-1 settings-form__select--selection"},[_vm._v(" "+_vm._s(item.name)+" ")])]}},{key:"item",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"settings-form__select--item"},[_vm._v(" "+_vm._s(item.name)+" ")])]}}],null,false,1287685641),model:{value:(_vm.selectedTeamID),callback:function ($$v) {_vm.selectedTeamID=$$v},expression:"selectedTeamID"}})],1)],1):_vm._e(),_vm._l((_vm.errors),function(error){return _c('v-alert',{key:error.message,staticClass:"ma-0",attrs:{"type":"error"}},[_vm._v(" "+_vm._s(error.message)+" ")])})],2),_vm._t("default",null,{"isDirty":_vm.isDirty,"loading":_vm.loading,"workspace":_vm.workspace,"admin":_vm.admin,"owner":_vm.owner,"deleteWorkspace":function () { return (_vm.dialog = true); }})],2),_c('WorkspaceDeleteDialog',{attrs:{"deletingWS":_vm.deletingWS,"showDialog":_vm.dialog,"workspaceId":_vm.workspace.id || ''},on:{"delete-workspace":_vm.deleteWorkspace,"dismiss-dialog":function($event){_vm.dialog = false}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }