import Vue from 'vue';
import { CommentTimeProp, } from './types';
import { sentimentInterpretation, findOffset, isInHtmlAttr, escapeInvalidRegex, escapeQuerySyntax, SKELETON_COUNT, PER_LOAD_SIZE, COLOR_GRADIENT, } from './helper';
import TimelineSkeletonLoader from './VideoInsightTimelineSkeletonLoader.vue';
import uniqBy from 'lodash/uniqBy';
import adminAPI from '@/services/adminAPI';
import VideoInsightSentimentTimelineMarker from './VideoInsightSentimentTimelineMarker.vue';
import VueSlider from '@lumiere/shared/components/VueSlider';
import VideoThumbnail from '@/components/common/VideoThumbnail.vue';
function hasVideoTime(p) {
    return p.videoTime !== undefined;
}
function payloadTimeProp(p) {
    return isQuestionTypePayload(p)
        ? null
        : hasVideoTime(p)
            ? p.videoTime
            : p.currentTime;
}
function isQuestionTypePayload(p) {
    return p.question !== undefined;
}
export default Vue.extend({
    name: 'VideoInsightSentimentTimeline',
    props: {
        sentimentsEmotionsArray: {
            type: Array,
            default: () => [],
        },
        totalInteractions: {
            type: Number,
        },
        loading: Boolean,
        filterTerm: String,
        filterAction: String,
        filtering: Boolean,
        loadSentimentsResponse: {
            type: Function,
        },
        timelineInsightData: {
            type: Array,
        },
        video: {
            type: Object,
            required: true,
        },
        filterZoomMinMax: {},
        filterRatingsMinMax: {},
        filterEmotions: {},
        timeProp: {
            type: String,
            required: true,
        },
    },
    data() {
        return {
            isFetchingVerbatim: false,
            alertDialog: false,
            alertDialogMessage: '',
            commentTime: 0,
            previousFrom: 0,
            currentItemIndex: null,
            showHiddenComments: false,
            hideComment: false,
            deletingComment: false,
            hidingComment: false,
            deletingCommentIndex: null,
            hidingCommentIndex: null,
            sentimentsTimeArray: [],
        };
    },
    filters: {
        highlightText(text, _data, search) {
            const searchTerm = search.toLowerCase();
            let newText = text;
            let regexp;
            if (searchTerm &&
                text.toLowerCase().includes(escapeQuerySyntax(searchTerm))) {
                regexp = new RegExp(`${escapeInvalidRegex(escapeQuerySyntax(searchTerm))}`, 'ig');
                newText = newText.toString().replace(regexp, (matchedText) => {
                    return `<span class="search-term">` + matchedText + `</span>`;
                });
            }
            const entitiesForType = isQuestionTypePayload(_data.payload)
                ? _data.payload.question.responses
                    .flatMap((r) => r.entities)
                    .filter((e) => e !== undefined)
                : _data.payload.comment.entities;
            const entities = entitiesForType || [];
            const contentHash = {};
            for (const entity of entities) {
                const mentions = entity.mentions || [];
                let strP1, strP2, beginOffset, textContent;
                for (const aMention of mentions) {
                    const emotion = sentimentInterpretation(aMention?.sentiment ?? entity?.sentiment);
                    if (!aMention.text?.content)
                        continue;
                    textContent = aMention.text?.content;
                    if (!contentHash[textContent]) {
                        contentHash[textContent] = 0;
                    }
                    let dummyText = newText;
                    for (let i = 0; i < contentHash[textContent]; i++) {
                        dummyText = dummyText.replace(textContent, '');
                    }
                    dummyText = String(dummyText);
                    if (!isInHtmlAttr(textContent)) {
                        beginOffset = dummyText.indexOf(textContent);
                    }
                    else {
                        beginOffset = findOffset(text, textContent, dummyText);
                    }
                    if (beginOffset > -1) {
                        strP1 = newText.substr(0, beginOffset);
                        strP2 = newText.substr(beginOffset);
                        regexp = new RegExp(`${escapeInvalidRegex(textContent)}`, 'i');
                        strP2 = strP2.replace(regexp, (matchedText) => {
                            return (`<span class="keyword ${emotion}">` + matchedText + `</span>`);
                        });
                        newText = strP1 + strP2;
                    }
                    contentHash[textContent]++;
                }
            }
            return newText;
        },
    },
    methods: {
        triggerIntersect(entries) {
            if (entries[0].isIntersecting) {
                const from = (this.timelineInsightData ?? []).length; //this.sentimentsEmotionsArray.length
                // prevent infinite loop
                if (from == this.previousFrom) {
                    this.isFetchingVerbatim = false;
                    return;
                }
                this.previousFrom = from;
                let size = this.totalInteractions
                    ? this.totalInteractions - from
                    : PER_LOAD_SIZE;
                if (size > PER_LOAD_SIZE) {
                    size = PER_LOAD_SIZE;
                }
                this.isFetchingVerbatim = true;
                if (size < 1) {
                    // nothing to fetch
                    this.isFetchingVerbatim = false;
                    return;
                }
                if (from + size > 10000) {
                    this.isFetchingVerbatim = false;
                    this.alertDialogMessage =
                        'There are too many comments to display. Please narrow the comment list by performing a search, or zooming into a region of interest.';
                    this.alertDialog = true;
                    return;
                }
                this.loadSentimentsResponse(from, '', true, this.filterZoomMinMax, // original: undefined,
                this.filterRatingsMinMax, this.filterEmotions, true, size).finally(() => {
                    this.isFetchingVerbatim = false;
                });
            }
            else {
                this.previousFrom = 0;
            }
        },
        verbatimClicked(data, index) {
            const time = Number(payloadTimeProp(data.payload));
            if (this.currentItemIndex !== index) {
                this.currentItemIndex = index;
                // verbatim current time: vctime
                // commentId is the ES docId for the comment
                this.$emit('verbatim-clicked', {
                    vctime: time,
                    commentId: data._id,
                });
                if (this.deletingComment) {
                    this.deletingComment = false;
                }
                if (this.hidingComment) {
                    this.hidingComment = false;
                }
            }
            this.commentTime = time;
        },
        deleteComment(data, index) {
            // eslint-disable-next-line @typescript-eslint/no-floating-promises
            this.$confirm(`Deleting this comment is irreversible. Do you want to proceed?`).then(async (ok) => {
                if (ok) {
                    const { _id: docId } = data;
                    // send a delete request to elasticsearch index
                    if (docId) {
                        this.deletingComment = true;
                        this.deletingCommentIndex = index;
                        if (this.timeProp === CommentTimeProp.videoTime) {
                            await adminAPI(async (api) => api.video.insights.deleteVideoExternalDataInsightsData({
                                vid: this.video.id,
                                docId,
                            }));
                        }
                        else {
                            await adminAPI(async (api) => api.video.insights.deleteVideoCommentInsightsData({
                                vid: this.video.id,
                                docId,
                            }));
                        }
                        // data = {}
                        await this.loadSentimentsResponse(0, '', true).finally(() => {
                            this.deletingComment = false;
                            this.deletingCommentIndex = null;
                        });
                    }
                }
            });
        },
        async onToggleHideComment(data, index) {
            const toHide = data.isHidden ? false : true;
            const { _id: docId } = data;
            // send a hide comment request to elasticsearch index
            if (docId) {
                this.hidingComment = true;
                this.hidingCommentIndex = index;
                if (this.timeProp === CommentTimeProp.videoTime) {
                    await adminAPI(async (api) => api.video.insights.hideVideoExternalDataInsightsData({
                        vid: this.video.id,
                        docId,
                        isHidden: toHide,
                    }));
                }
                else {
                    await adminAPI(async (api) => api.video.insights.hideVideoCommentInsightsData({
                        vid: this.video.id,
                        docId,
                        isHidden: toHide,
                    }));
                }
                // update the local copy before fetching fresh data
                data.isHidden = toHide;
                this.loadSentimentsResponse(0, '', true).finally(() => {
                    this.hidingComment = false;
                    this.hidingCommentIndex = null;
                });
            }
        },
        onToggleShowHiddenComments() { },
        deselectVerbatim(index) {
            if (this.currentItemIndex === index) {
                this.currentItemIndex = null;
            }
        },
    },
    computed: {
        numberOfSkeletons() {
            if (!this.totalInteractions)
                return SKELETON_COUNT;
            const toLoad = this.totalInteractions - this.sentimentsEmotionsArray.length;
            if (toLoad === 0)
                return 0;
            return toLoad < SKELETON_COUNT ? toLoad : SKELETON_COUNT;
        },
        uniqSentimentEmotionsArray() {
            return uniqBy(this.sentimentsEmotionsArray, '_id');
        },
        commentPlusRating() {
            return (data) => isQuestionTypePayload(data.payload)
                ? data.payload.question.responses.flatMap((r) => r.plus)[0]?.rating
                : data.payload.comment?.plus?.rating;
        },
        commentPlusRatingColor() {
            return (data) => {
                const rating = this.commentPlusRating(data);
                if (!rating)
                    return null;
                return COLOR_GRADIENT[rating];
            };
        },
        commentFeatureHasComments() {
            return !!this.uniqSentimentEmotionsArray?.length;
        },
        computedPayloadTimeProp() {
            return payloadTimeProp;
        },
        extractPayloadText() {
            return (payload) => isQuestionTypePayload(payload)
                ? payload.question.responses.flatMap((r) => r.text)[0]
                : payload.comment.text;
        },
    },
    watch: {
        '$route.query.vctime': {
            handler(t) {
                if (t == null) {
                    this.currentItemIndex = null;
                }
            },
            immediate: true,
        },
        'uniqSentimentEmotionsArray': {
            handler(data) {
                if (data) {
                    const timeArray = [];
                    for (let item of data) {
                        timeArray.push(Math.round(Number(payloadTimeProp(item.payload))));
                    }
                    this.sentimentsTimeArray = timeArray;
                }
            },
            immediate: true,
        },
    },
    events: {
        chartTapped(params) {
            let { vctime } = params;
            //autoscroll the timeline to the minute
            const container = this.$refs.timelineWrapper;
            if (container?.ownerDocument?.defaultView) {
                const scrollHeight = container.scrollHeight;
                const firstIndex = this.sentimentsTimeArray.indexOf(vctime);
                if (firstIndex) {
                    const indexPositionRatio = firstIndex / this.sentimentsTimeArray.length;
                    const DELTA = 0.1;
                    const scrollPosition = scrollHeight * indexPositionRatio * (1 - DELTA);
                    container.scrollTo({
                        top: scrollPosition,
                        behavior: 'smooth',
                    });
                }
            }
        },
    },
    components: {
        TimelineSkeletonLoader,
        VideoInsightSentimentTimelineMarker,
        VueSlider,
        VideoThumbnail,
    },
});
