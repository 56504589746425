import Vue from 'vue';
import '@lumiere/shared/plugins/vue2-touch-events';
import VideoPlayer from '@lumiere/video/src/components/VideoPlayer';
import { VideoState, ScrubberMode, } from '@lumiere/video';
import { AssetType } from '@lumiere/db/types';
import adminAPI from '@/services/adminAPI';
import { getVideoPoster } from '@lumiere/shared/utils/getVideoThumbnail';
export default Vue.extend({
    name: 'VideoPlayerAdminTimePicker',
    props: {
        video: {
            type: Object,
            required: true,
        },
        selectedRange: {
            type: Array,
            validator: function (value) {
                if (value)
                    return value.length === 2 && value[1] > value[0];
                return true;
            },
        },
        scrubberMode: {
            type: String,
            default: ScrubberMode.RANGE,
        },
    },
    data() {
        return {
            src: null,
            storyboard: null,
            state: null,
            videoScrubbingData: null,
        };
    },
    watch: {
        video: {
            async handler(video) {
                this.src = await adminAPI(async (api) => api.video.getSignedURL({ vid: video.id, assetType: AssetType.video }));
                this.storyboard = await adminAPI(async (api) => api.video.getSignedURL({
                    vid: video.id,
                    assetType: AssetType.storyboard,
                }));
            },
            immediate: true,
        },
    },
    computed: {
        videoPlayer() {
            return () => this.$refs.videoPlayer;
        },
        VideoState() {
            return VideoState;
        },
        videoPlaybackId() {
            return this.video.playbackIds[0].id;
        },
        playerColor() {
            return this.video.appearance?.color ?? '#03A9F4';
        },
        posterImage() {
            return getVideoPoster(this.video);
        },
    },
    methods: {
        onTimeUpdate(t) {
            this.$emit('timeupdate', t);
        },
        onStateChange(state) {
            this.$emit('state-change', state);
        },
        onScrubbing(data) {
            this.videoScrubbingData = data;
            this.$emit('scrubbing', data);
        },
        seekTo(time) {
            this.videoPlayer()?.seekTo(time);
        },
        play() {
            return this.videoPlayer()?.play();
        },
        pause() {
            this.videoPlayer()?.pause();
        },
        onRangeChange(range) {
            this.$emit('range-selection', range);
        },
    },
    components: {
        VideoPlayer,
    },
});
