import { FeatureTriggerType, VideoTriggerEvent, QuestionType, } from '@lumiere/db/types';
import { ESFeatureTypes, } from '@lumiere/shared/types';
import Vue from 'vue';
import { VideoState } from '../../components/types';
import QuestionFeedback from './QuestionFeedback.vue';
function isTimeTrigger(ft) {
    return (ft.type === FeatureTriggerType.VideoEvent &&
        ft.event === VideoTriggerEvent.VideoTime);
}
/**
 * A persistent empty array allows us to make quick comparison by reference in watch
 */
const emptyQuestionsArray = [];
const defaultValues = () => ({
    currentQuestionIndex: 0,
    activeQuestions: emptyQuestionsArray,
});
export default Vue.extend({
    name: 'QuestionFeedbackContainer',
    props: {
        questions: Array,
        currentTime: Number,
        videoState: String,
    },
    data() {
        return {
            answeredQuestionIds: {},
            ...defaultValues(),
        };
    },
    computed: {
        questionActivationsMap() {
            const questionActivationsMap = new Map();
            this.questions.forEach((question) => question.triggers
                .filter(isTimeTrigger)
                .forEach(({ parameters }) => questionActivationsMap.set(parameters.time, [
                ...(questionActivationsMap.get(parameters.time) ?? []),
                question,
            ])));
            return questionActivationsMap;
        },
        currentMomentQuestions() {
            if ([VideoState.Playing, VideoState.Paused].includes(this.videoState)) {
                return (this.questionActivationsMap.get(Math.floor(this.currentTime)) ??
                    emptyQuestionsArray);
            }
            else {
                return emptyQuestionsArray;
            }
        },
        showQuestions() {
            return this.activeQuestions.length > 0;
        },
        isLastQuestion() {
            return this.currentQuestionIndex === this.activeQuestions.length - 1;
        },
        currentQuestion() {
            return this.activeQuestions[this.currentQuestionIndex];
        },
        shouldPauseVideo() {
            // for now - always pause when question is activated
            return true;
            // return !!this.activeQuestions.find(
            //   ({ options }) => options.feedbackOptions.shouldPauseVideo,
            // )
        },
    },
    watch: {
        currentMomentQuestions: {
            immediate: true,
            handler(currentMomentQuestions, currentMomentQuestionsOld = emptyQuestionsArray) {
                if (currentMomentQuestionsOld !== currentMomentQuestions) {
                    const filteredQuestions = currentMomentQuestions.filter((q) => 
                    // the question has either not been answered yet
                    !this.answeredQuestionIds[q.id] ||
                        // or allows multiple responses
                        q.options.canRespondMoreThanOnce);
                    this.activeQuestions =
                        filteredQuestions.length > 0
                            ? filteredQuestions
                            : emptyQuestionsArray;
                }
            },
        },
        showQuestions(showQuestions) {
            if (showQuestions) {
                this.emitActivated();
            }
            else {
                this.emitFinished();
            }
        },
    },
    methods: {
        emitActivated() {
            this.$emit('activated', this.shouldPauseVideo);
        },
        emitFinished() {
            this.$emit('finished');
        },
        // TODO: define feedback object type
        emitFeedback(response) {
            const { id, options } = this.currentQuestion;
            const feedback = {
                type: ESFeatureTypes.Question,
                fid: id,
                payload: {
                    currentTime: this.currentTime,
                    // this will be different for non-interruptive questions
                    submittedTime: this.currentTime,
                    question: {
                        type: options.type,
                        variant: options.variant,
                        responses: typeof response === 'string'
                            ? [{ id: null, text: response }]
                            : response,
                    },
                },
            };
            this.$emit('feedback', feedback);
        },
        reset() {
            Object.assign(this, defaultValues());
        },
        continue() {
            if (this.isLastQuestion) {
                this.emitFinished();
                this.reset();
            }
            else {
                this.currentQuestionIndex++;
            }
        },
        onResponse(response) {
            if (response) {
                Object.assign(this.answeredQuestionIds, {
                    [this.currentQuestion.id]: true,
                });
                switch (this.currentQuestion.options.type) {
                    case QuestionType.Text:
                        if (response.value) {
                            this.emitFeedback(response.value);
                        }
                        break;
                    case QuestionType.Choice:
                        if (response.options) {
                            this.emitFeedback(response.options);
                        }
                        break;
                    default:
                        break;
                }
            }
            this.continue();
        },
    },
    components: {
        QuestionFeedback,
    },
});
