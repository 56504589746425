var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('transition',{attrs:{"appear":"","css":false},on:{"appear":_vm.onAppear,"leave":_vm.onLeave}},[_c('form',{directives:[{name:"touch",rawName:"v-touch:tap.stop",arg:"tap",modifiers:{"stop":true}}],class:[
      'comment-feedback',
      ("comment-feedback--" + (_vm.positionOverride || _vm.position)),
      ("comment-feedback--step-" + _vm.step),
      {
        'comment-feedback--active': _vm.active,
        'comment-feedback--hidden': _vm.hidden,
        'comment-feedback--fullscreen': _vm.fullscreen,
        'comment-feedback--not-fullscreen': !_vm.fullscreen,
        'comment-feedback--tagging-mode': _vm.taggingMode,
      } ],on:{"click":function($event){$event.stopPropagation();},"submit":function($event){$event.preventDefault();return _vm.submitComment($event)}}},[_c('div',{ref:"container",staticClass:"comment-feedback__container"},[_c('i',{ref:"pointOfContact",staticClass:"comment-feedback__point-of-contact"}),_c('label',{ref:"label",staticClass:"comment-feedback__content"},[_c('CommentFeedbackLabel',{directives:[{name:"show",rawName:"v-show",value:(!_vm.taggingMode),expression:"!taggingMode"}],attrs:{"prompt":_vm.prompt},nativeOn:{"click":function($event){$event.stopPropagation();return (function () { return _vm.toggleForm(true); })($event)}}}),_c('div',{staticClass:"comment-feedback__input-plus"},[_c('textarea',{directives:[{name:"show",rawName:"v-show",value:(_vm.showStepComment),expression:"showStepComment"}],ref:"input",staticClass:"comment-feedback__input",class:{
              'comment-feedback__input--no-prompt': !_vm.showPrompt,
              'comment-feedback__input--prompt': !!_vm.showPrompt,
              'comment-feedback__input--highlight': _vm.highlightInput,
            },style:(_vm.inputStyle),attrs:{"rows":"2","placeholder":_vm.placeholder},domProps:{"value":_vm.comment},on:{"input":function (e) { return (_vm.comment = e.target.value); }}}),(_vm.plus)?_c('PlusFeature',{directives:[{name:"show",rawName:"v-show",value:(_vm.showStepPlus),expression:"showStepPlus"}],ref:"plus",staticClass:"comment-feedback__plus",attrs:{"options":_vm.plus},model:{value:(_vm.plusInput),callback:function ($$v) {_vm.plusInput=$$v},expression:"plusInput"}}):_vm._e()],1)],1),_c('div',{staticClass:"comment-feedback__actions"},[_c('div',[(!!_vm.spatialTaggingPosition)?_c('button',{staticClass:"lumi-btn lumi-btn--cancel",on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.tagLocation($event)}}},[_c('fa-icon',{attrs:{"icon":"signal-stream"}}),_vm._v(" Move Tag ")],1):(
              _vm.isSpatialTaggingEnabled &&
              !_vm.spatialTaggingPosition &&
              !_vm.isSpatialTaggingRequired
            )?_c('button',{staticClass:"lumi-btn lumi-btn--cancel",on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.tagLocation($event)}}},[_c('fa-icon',{attrs:{"icon":"signal-stream"}}),_vm._v(" Add Tag ")],1):_vm._e()]),_c('div',[_c('button',{staticClass:"lumi-btn lumi-btn--cancel",attrs:{"type":"button"},on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.cancelComment($event)}}},[_vm._v(" Cancel ")]),(_vm.needToTagLocation)?_c('button',{staticClass:"lumi-btn lumi-btn--submit",on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.tagLocation($event)}}},[_c('fa-icon',{attrs:{"icon":"signal-stream"}}),_vm._v(" Tag a Location ")],1):(this.plus && _vm.showStepComment && !_vm.showStepPlus)?_c('button',{staticClass:"lumi-btn lumi-btn--submit",on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.onNextClicked($event)}}},[_vm._v(" Next ")]):_c('button',{staticClass:"lumi-btn lumi-btn--submit",class:{ loading: _vm.loading },attrs:{"type":"submit","disabled":_vm.loading}},[_vm._v(" Send ")])])])])])])}
var staticRenderFns = []

export { render, staticRenderFns }