import Vue from 'vue';
import { ChannelOutputsEnum } from '@lumiere/db/types';
import VideoCard from '@/components/workspace/videos/VideoCard.vue';
import { VideoRoutes } from '@/router/router';
import { FieldValue } from '@/services/firebase/firestore';
import isEmpty from 'lodash/isEmpty';
import ValueSkeletonLoader from '../ValueSkeletonLoader.vue';
import { mapGetters } from 'vuex';
const SHARE_WEIGHT = 0.25;
const VIEWS_WEIGHT = 0.25;
const RETENTION_WEIGHT = 0.5;
export default Vue.extend({
    name: 'ChannelMetricsTable',
    props: {
        channelVideos: {
            type: Array,
        },
        channelId: String,
        channelMetrics: {
            type: Object,
        },
        fetchingMetrics: Boolean,
        link: Object,
        canDrillToVideo: Boolean,
    },
    data() {
        return {
            total_views_by_video: {},
            retentions_per_video: {},
            total_viewing_time: 0,
            total_video_views: 0,
        };
    },
    computed: {
        ...mapGetters(['userRoleInWorkspace']),
        routeLinkType() {
            return this.$ability.can('read', 'Video') ? 'to' : 'href';
        },
        videoURL() {
            return (video) => this.$ability.can('read', 'Video') // || this.canDrillToVideo
                ? {
                    name: VideoRoutes.Insights,
                    params: {
                        videoId: video.id,
                        videoProp: video,
                    },
                }
                : this.$store.getters.videoURL(video.id);
        },
        videoItems() {
            if (!this.channelVideos.length)
                return [];
            const tableItems = this.channelVideos.map((video) => {
                return {
                    video: video,
                    total_views: this.total_views_by_video[video.id]?.doc_count ?? 0,
                    avg_retention: this.averageRetention(this.retentions_per_video[video.id], video.duration),
                    viewing_share: this.viewingShareFunc(this.retentions_per_video[video.id]?.viewing_share),
                    channel_score: this.channelScore(this.retentions_per_video[video.id]?.viewing_share, this.total_views_by_video[video.id]?.doc_count ?? 0, this.retentions_per_video[video.id], video.duration),
                };
            });
            return tableItems;
        },
        isPublic() {
            return !!this.$route.query.link;
        },
        channelOutputsEnum() {
            return ChannelOutputsEnum;
        },
        canSeeShareOutput() {
            return (outputType) => {
                if (this.userRoleInWorkspace) {
                    return true;
                }
                if (this.isPublic && this.link) {
                    const configOutputs = this.link.configuration?.outputs;
                    if (configOutputs) {
                        return configOutputs.includes(outputType);
                    }
                }
                return false;
            };
        },
        tableHeaders() {
            const headers = [
                {
                    text: '',
                    align: 'start',
                    sortable: false,
                    value: 'video',
                },
            ];
            if (this.canSeeShareOutput(ChannelOutputsEnum.VIDEO_VIEWS)) {
                headers.push({ text: 'Views', value: 'total_views', align: 'center' });
            }
            if (this.canSeeShareOutput(ChannelOutputsEnum.AVG_RETENTION)) {
                headers.push({
                    text: 'Avg Retention',
                    value: 'avg_retention',
                    align: 'center',
                    sort: (a, b) => {
                        if (a?.length & b?.length) {
                            return a[0] - b[0];
                        }
                        return a - b;
                    },
                });
            }
            if (this.canSeeShareOutput(ChannelOutputsEnum.VIEWING_SHARE)) {
                headers.push({
                    text: 'Share of Watching',
                    value: 'viewing_share',
                    align: 'center',
                });
            }
            if (this.canSeeShareOutput(ChannelOutputsEnum.CHANNEL_SCORE)) {
                headers.push({
                    text: 'Score',
                    value: 'channel_score',
                    align: 'center',
                });
            }
            return headers;
        },
    },
    methods: {
        removeVideo(video) {
            this.$db
                .channel(this.channelId)
                .update({ videos: FieldValue.arrayRemove(video.id) })
                .then(() => {
                this.$emit('video-removed', video);
            });
        },
        onFetchMetrics(data) {
            this.total_views_by_video = data.total_views_by_video ?? {};
            this.retentions_per_video = data.retentions_per_video ?? {};
            this.total_viewing_time = data.total_viewing_time ?? 0;
            this.total_video_views = data.total_video_views ?? 0;
        },
        viewingShareFunc(viewing_share) {
            if (this.total_viewing_time === 0)
                return 0;
            return ((viewing_share || 0) / this.total_viewing_time) * 100;
        },
        averageRetention(data, duration) {
            if (!data) {
                return [0, 0];
            }
            const average_view_duration = data.viewing_share / data.doc_count;
            const avg_percent_viewed = (average_view_duration / duration) * 100;
            return [avg_percent_viewed, average_view_duration];
        },
        channelScore(viewing_share, views, data, duration) {
            const weighted_share = 
            // where := this.viewingShareFunc(viewing_share) / 100 => shareOfWatching
            (this.viewingShareFunc(viewing_share) / 100) * SHARE_WEIGHT;
            const weighted_views = this.total_video_views > 0
                ? (views / this.total_video_views) * VIEWS_WEIGHT * 100
                : 0;
            // where := this.averageRetention(data, duration)[0] / 100 => avgRetentionPercent
            const weighted_retention = (this.averageRetention(data, duration)[0] / 100) * RETENTION_WEIGHT;
            const result = (weighted_share + weighted_views + weighted_retention) *
                this.channelVideos.length;
            return Math.round(result);
        },
    },
    watch: {
        channelMetrics: {
            handler(metrics) {
                if (!isEmpty(metrics)) {
                    this.onFetchMetrics(metrics);
                }
            },
            immediate: true,
        },
    },
    filters: {
        viewingShare(val) {
            return `${Math.round(val)}%`;
        },
        avgPercentFilter(val) {
            return `${Math.round(val)}%`;
        },
    },
    components: {
        VideoCard,
        ValueSkeletonLoader,
    },
});
