import Vue from 'vue';
import { VideoLogoPosition } from '@lumiere/db/types';
import getFilenameExtension from '@lumiere/shared/utils/getFilenameExtension';
import CornerPositionSelect from '@/components/common/CornerPositionSelect.vue';
import logger from '@lumiere/shared/services/logger';
const DEFAULT_LOGO_POSITION = VideoLogoPosition.BottomLeft;
export default Vue.extend({
    data() {
        return {
            showCustomBranding: !!this.logo,
            uploading: false,
            slider: 50,
        };
    },
    props: {
        logo: {
            type: Object,
        },
        videoId: {
            type: String,
            required: true,
        },
    },
    watch: {
        showCustomBranding(cur, prev) {
            if (prev && !cur) {
                this.unsetLogo();
            }
        },
    },
    methods: {
        updateLogoPosition(position) {
            this.$emit('change', {
                ...this.logo,
                position,
            });
        },
        async uploadFile(file) {
            if (file) {
                this.uploading = true;
                try {
                    const uploader = this.$uploadFileToStorage(file, `video/${this.videoId}/`, `logo.${getFilenameExtension(file.name)}`);
                    await uploader.getURL().then((url) => {
                        const logoPosition = this.logo?.position ?? DEFAULT_LOGO_POSITION;
                        this.$emit('change', {
                            position: logoPosition,
                            src: url,
                            opacity: 0.5,
                        });
                    });
                }
                catch (e) {
                    logger.error('uploadFile', e);
                }
                this.uploading = false;
            }
        },
        unsetLogo() {
            this.$emit('change', null);
        },
        updateOpacity(opacity) {
            const decimalValue = opacity / 100;
            this.$emit('change', {
                ...this.logo,
                opacity: decimalValue,
            });
        },
    },
    mounted() {
        this.slider = (this.logo?.opacity || 0.5) * 100;
    },
    computed: {
        VideoLogoPosition: () => VideoLogoPosition,
        fileInputRef() {
            return this.$refs.fileInput;
        },
    },
    components: {
        CornerPositionSelect,
    },
});
