import Vue from 'vue';
import { nanoid } from 'nanoid';
import { Routes } from '@/router/router';
export default Vue.extend({
    data() {
        return {
            loading: false,
            team: { name: '' },
        };
    },
    methods: {
        async createTeam() {
            const teamId = nanoid(10);
            this.loading = true;
            await this.$db
                .team(teamId)
                .create(this.team)
                .then(async () => {
                const ctx = await this.$auditLogger(this.$store.state.uid);
                if (ctx) {
                    const { Asset_Model, EVENT_ACTION, ts } = ctx.config;
                    const asset = {
                        id: teamId,
                        type: Asset_Model.Team,
                        name: this.team.name,
                    };
                    await ctx.exec(asset, {
                        timestamp: ts(),
                        action: EVENT_ACTION.CREATED,
                    });
                }
            });
            this.loading = false;
            this.$emit('close');
            void this.$router.push({ name: Routes.Team, params: { teamId } });
        },
    },
});
