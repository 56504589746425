import { render, staticRenderFns } from "./VideoDetailsPosterScrubDialog.vue?vue&type=template&id=4f46675d&scoped=true&"
import script from "./VideoDetailsPosterScrubDialog.vue?vue&type=script&lang=ts&"
export * from "./VideoDetailsPosterScrubDialog.vue?vue&type=script&lang=ts&"
import style0 from "./VideoDetailsPosterScrubDialog.vue?vue&type=style&index=0&id=4f46675d&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/.pnpm/vue-loader@15.9.7_fb85a9817f99bdf862e5e3a442a1fb91/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4f46675d",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../../../node_modules/.pnpm/vuetify-loader@1.7.2_c225a462abeaf467a2189c5bd12e241a/node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VSpacer } from 'vuetify/lib/components/VGrid';
installComponents(component, {VBtn,VCard,VCardActions,VCardTitle,VDialog,VDivider,VSpacer})
