import Vue from 'vue';
import { Emotions } from './types';
import * as am4core from '@amcharts/amcharts4/core';
import * as am4charts from '@amcharts/amcharts4/charts';
import am4themes_animated from '@amcharts/amcharts4/themes/animated';
import maxBy from 'lodash/maxBy';
am4core.useTheme(am4themes_animated);
export default Vue.extend({
    name: 'VideoInsightItemsCounts',
    props: {
        totalInteractions: {
            type: Number,
        },
        loading: Boolean,
        emotionColor: {
            type: Function,
        },
        sentiment_count: {
            type: Object,
        },
        average_rating: [Number, Object],
        filtering: Boolean,
        featureId: String,
        hasVideoTime: Boolean,
    },
    data() {
        return {
            amChart: null,
            chartLabel: null,
        };
    },
    computed: {
        orderedEmotion() {
            if (!this.sentiment_count)
                return [];
            let orderedEmotion = [];
            orderedEmotion.push({
                emotion: Emotions.POSITIVE,
                count: this.sentiment_count[Emotions.POSITIVE]?.doc_count ?? 0,
            });
            orderedEmotion.push({
                emotion: Emotions.NEUTRAL,
                count: this.sentiment_count[Emotions.NEUTRAL]?.doc_count ?? 0,
            });
            orderedEmotion.push({
                emotion: Emotions.NEGATIVE,
                count: this.sentiment_count[Emotions.NEGATIVE]?.doc_count ?? 0,
            });
            if (this.totalInteractions) {
                orderedEmotion.push({
                    emotion: Emotions.INDETERMINATE,
                    count: this.totalInteractions -
                        orderedEmotion.reduce((acc, cur) => (acc += cur.count), 0),
                });
            }
            return orderedEmotion;
        },
        chartData() {
            const data = [];
            for (let item of this.orderedEmotion) {
                data.push({
                    emotion: item.emotion,
                    value: item.count,
                    color: am4core.color(this.emotionColor(item.emotion)),
                });
            }
            return data;
        },
    },
    methods: {
        constructLabelText(data) {
            if (data) {
                const emotion = String(data.emotion)[0] + String(data.emotion).slice(1).toLowerCase();
                const value = this.totalInteractions
                    ? Math.round((data.value / this.totalInteractions) * 100)
                    : 0;
                return ('[' +
                    data.color.hex +
                    ' font-size:24px]' +
                    value +
                    '%' +
                    '[/]' +
                    '\n[#665 font-size:12px]' +
                    emotion +
                    '[/]');
            }
            return '';
        },
        createDonutChart() {
            if (this.amChart) {
                this.refreshtData();
                return;
            }
            // this.disposeOfCharts()
            // Create chart instance
            let chart = am4core.create(this.$refs.chartContainer, am4charts.PieChart);
            this.amChart = chart;
            // chart.height = am4core.percent(100)
            chart.hiddenState.properties.opacity = 0; // this creates initial fade-in
            chart.data = this.chartData;
            chart.radius = am4core.percent(70);
            chart.innerRadius = am4core.percent(40);
            chart.padding(10, 5, 0, 5);
            chart.startAngle = 160;
            chart.endAngle = 380;
            let series = chart.series.push(new am4charts.PieSeries());
            series.dataFields.value = 'value';
            series.dataFields.category = 'emotion';
            series.slices.template.stroke = new am4core.InterfaceColorSet().getFor('background');
            series.slices.template.strokeWidth = 1;
            series.slices.template.strokeOpacity = 1;
            series.slices.template.propertyFields.fill = 'color';
            // series.slices.template.propertyFields.stroke = 'color'
            // series.slices.template.cornerRadius = 10
            // series.slices.template.innerCornerRadius = 7
            series.slices.template.draggable = false;
            series.slices.template.inert = true;
            series.alignLabels = false;
            series.labels.template.disabled = true;
            series.ticks.template.disabled = true;
            if (series.tooltip) {
                series.tooltip.disabled = false;
            }
            series.slices.template.tooltipText = '{value.value}';
            series.hiddenState.properties.startAngle = 90;
            series.hiddenState.properties.endAngle = 90;
            // Disable sliding out of slices
            const properties = series.slices.template.states.getKey('hover')?.properties;
            if (properties) {
                properties.shiftRadius = 0.05;
                properties.scale = 1;
            }
            series.radius = am4core.percent(200);
            series.innerRadius = am4core.percent(75);
            // let rgm = new am4core.RadialGradientModifier()
            // rgm.brightnesses.push(-0.8, -0.8, -0.5, 0, -0.5)
            // series.slices.template.fillModifier = rgm
            // series.slices.template.strokeModifier = rgm
            // series.slices.template.strokeOpacity = 0.4
            // series.slices.template.strokeWidth = 0
            const label = series.createChild(am4core.Label);
            this.chartLabel = label;
            label.horizontalCenter = 'middle';
            label.verticalCenter = 'middle';
            // label.fontSize = 40
            label.textAlign = 'middle';
            const maxItem = maxBy(chart.data, 'value');
            if (maxItem) {
                label.text = this.constructLabelText(maxItem);
            }
            series.slices.template.events.on('over', (ev) => {
                let dataItem = ev.target.dataItem;
                if (dataItem?.values) {
                    let emotion = dataItem.properties.category;
                    let color = am4core.color(this.emotionColor(emotion));
                    label.text = this.constructLabelText({
                        emotion,
                        value: dataItem.values.value.value,
                        color: color,
                    });
                    this.$emit('mouseover', emotion);
                }
            });
            series.slices.template.events.on('out', (_ev) => {
                const maxItem = maxBy(chart.data, 'value');
                if (maxItem) {
                    label.text = this.constructLabelText(maxItem);
                    this.$emit('mouseleave', maxItem.emotion);
                }
            });
        },
        refreshtData() {
            this.amChart.data = this.chartData;
            const maxItem = maxBy(this.chartData, 'value');
            if (maxItem && this.chartLabel) {
                this.chartLabel.text = this.constructLabelText(maxItem);
            }
            this.amChart.series.each(function (series) {
                series.appear();
            });
        },
        disposeOfCharts() {
            this.amChart?.dispose();
            this.amChart = null;
        },
    },
    filters: {},
    watch: {
        featureId(fid) {
            if (fid) {
                this.disposeOfCharts();
            }
        },
        totalInteractions: {
            handler(value) {
                if (value != null) {
                    this.$nextTick(() => {
                        this.createDonutChart();
                    });
                }
            },
            immediate: true,
        },
        loading: {
            handler(val) {
                if (val) {
                    this.disposeOfCharts();
                }
            },
        },
    },
    beforeDestroy() {
        this.disposeOfCharts();
    },
});
