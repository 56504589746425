import { render, staticRenderFns } from "./ColorSpinBG.vue?vue&type=template&id=906ef226&scoped=true&"
var script = {}
import style0 from "./ColorSpinBG.vue?vue&type=style&index=0&id=906ef226&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/.pnpm/vue-loader@15.9.7_fb85a9817f99bdf862e5e3a442a1fb91/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "906ef226",
  null
  
)

export default component.exports