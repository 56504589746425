import { dayjs } from '@lumiere/shared/utils/dayjs';
export function timestampToDate(ts) {
    return dayjs(ts?.toDate()).format('MMM DD, YYYY @hh:mma');
}
export function isPowerOf2(value) {
    return (value & (value - 1)) === 0;
}
/** emoji design colors */
export const designColors = [
    '#ffb600',
    '#00ea9b',
    '#009cff',
    '#ff5733',
    '#f468f2',
    '#d6ef02',
    '#ff9428',
    '#fae917',
    '#51e064',
    '#7768f4',
    '#9013dd',
];
