import { FieldValue } from '@/services/firebase/firestore';
import DB from '@lumiere/db';
import { FeatureType } from '@lumiere/db/types';
import { Role, VideoStatus } from '@lumiere/db/types';
import { nanoid } from 'nanoid';
import { distinct, map } from 'rxjs/operators';
import auth from '../services/firebase/auth';
import { MUTATIONS } from './store.mutations';
import { locallyStoredState, FileUploadStatus, } from './store.state';
import ACTIONS from './ACTIONS';
import { fetchData, reqHeaders, getBrowserQueryParam } from '../utils/api';
import { captureException } from '@sentry/browser';
import { getFilenameWithoutExtension } from '@lumiere/shared/utils/getFilenameExtension';
import adminAPI from '@/services/adminAPI';
import storage from 'local-storage-fallback';
import pick from 'lodash/pick';
import logger from '@lumiere/shared/services/logger';
import { isValidVideoFormat } from '@/utils/uploadFileToStorage';
import { createOrUpdateVideoDoc, ingestVideoFile } from './helpers/video';
import { makeAuditLogger } from '@/services/auditLogger';
export { ACTIONS };
export default {
    [ACTIONS.SUBSCRIBE_TO_AUTH_STATE_CHANGE]({ state, commit, dispatch }) {
        auth.onAuthStateChanged(async (user) => {
            let claims = {};
            if (user) {
                if (!state.user) {
                    // eslint-disable-next-line @typescript-eslint/no-floating-promises
                    dispatch(ACTIONS.UPDATE_USER_RECORD, user);
                }
                commit(MUTATIONS.SET_USER, user);
                claims = (await user.getIdTokenResult(true))?.claims;
                if (!claims.user) {
                    try {
                        const idToken = await user.getIdToken();
                        await dispatch(ACTIONS.SET_CUSTOM_CLAIMS, { idToken });
                    }
                    catch (err) {
                        return auth.signOut();
                    }
                }
            }
            else {
                commit(MUTATIONS.SET_USER, null);
            }
            const uid = (user && user.uid) || null;
            if (!uid) {
                // eslint-disable-next-line @typescript-eslint/no-floating-promises
                dispatch(ACTIONS.CLEAR_AUTH_COOKIE);
                storage.clear();
            }
            const redirectAfterLogin = getBrowserQueryParam('redirect');
            if (redirectAfterLogin) {
                commit(MUTATIONS.SET_REDIRECT_AFTER_LOGIN, redirectAfterLogin);
            }
            commit(MUTATIONS.SET_UID, uid);
            if (uid && user) {
                /* eslint-disable @typescript-eslint/no-floating-promises */
                dispatch(ACTIONS.SUBSCRIBE_TO_USER_DOC_CHANGES);
                dispatch(ACTIONS.UPDATE_SIGN_IN_TIME);
                dispatch(ACTIONS.CHECK_IF_USER_IS_ADMIN, claims);
                dispatch(ACTIONS.REFRESH_AUTH_COOKIE);
                dispatch(ACTIONS.SAVE_STATE);
                setTimeout(() => {
                    dispatch(ACTIONS.SUBSCRIBE_TO_USER_WORKSPACES);
                }, 0);
                /* eslint-enable @typescript-eslint/no-floating-promises */
            }
        });
    },
    [ACTIONS.SUBSCRIBE_TO_USER_DOC_CHANGES]({ state, commit, dispatch }) {
        if (state.uid) {
            DB.getInstance()
                .user(state.uid)
                .observe()
                .pipe(distinct((v) => v?.editedTime?.seconds))
                .subscribe((user) => {
                if (user) {
                    commit(MUTATIONS.SET_RECENT_WORKSPACES, user.recentWorkspaces ?? {});
                    // eslint-disable-next-line @typescript-eslint/no-floating-promises
                    dispatch(ACTIONS.SAVE_STATE);
                }
                else {
                    logger.error('User doc is null');
                }
            });
        }
    },
    async [ACTIONS.UPDATE_SIGN_IN_TIME]({ state }) {
        if (state.uid) {
            const userUpdate = {
                lastSignInTime: FieldValue.serverTimestamp(),
                emailVerified: true,
            };
            await DB.getInstance().user(state.uid).update(userUpdate);
        }
    },
    async [ACTIONS.UPDATE_USER_RECORD]({ state }, user) {
        logger.log('update user record', user);
        if (state.uid) {
            const userUpdate = pick(user, ['displayName', 'photoURL']);
            await DB.getInstance().user(state.uid).update(userUpdate);
        }
    },
    [ACTIONS.CHECK_IF_USER_IS_ADMIN]({ commit }, claims) {
        const admin = !!claims.admin;
        commit(MUTATIONS.SET_ADMIN, admin);
    },
    [ACTIONS.SAVE_STATE]({ state }) {
        const { uid, user, workspaces, recentWorkspaces } = state;
        locallyStoredState.set({ user, uid, workspaces, recentWorkspaces });
    },
    [ACTIONS.SUBSCRIBE_TO_USER_WORKSPACES]({ state, commit, dispatch }) {
        return DB.getInstance()
            .workspaces((ref) => ref.where(`roles.${state.uid}`, 'in', Object.values(Role)))
            .observe()
            .pipe(map((workspaces) => workspaces.filter((w) => !w.deleted)), map((workspaces) => workspaces.reduce((acc, workspace) => ({ ...acc, [workspace.id]: workspace }), {})))
            .subscribe((workspaces) => {
            commit(MUTATIONS.SET_WORKSPACES, workspaces);
            // eslint-disable-next-line @typescript-eslint/no-floating-promises
            dispatch(ACTIONS.SAVE_STATE);
        });
    },
    async [ACTIONS.ADD_RECENT_WORKSPACE]({ state }, workspaceId) {
        if (state.uid) {
            await DB.getInstance()
                .user(state.uid)
                .update({ [`recentWorkspaces.${workspaceId}`]: Date.now() });
        }
    },
    async [ACTIONS.DELETE_RECENT_WORKSPACE]({ state }, workspaceId) {
        if (state.uid) {
            await DB.getInstance()
                .user(state.uid)
                .update({ [`recentWorkspaces.${workspaceId}`]: FieldValue.delete() });
        }
    },
    async [ACTIONS.UPLOAD_VIDEO_FILE]({ state, commit, dispatch }, { file, workspaceId, existingVideo }) {
        const updateUpload = (update) => {
            commit(MUTATIONS.UPDATE_FILE_UPLOAD, { id, ...update });
        };
        const uploadVideoTimer = logger.time('UPLOAD_VIDEO_FILE', {
            videoId: existingVideo?.id ?? file.id,
            workspaceId,
        });
        // 0. Check if file is of correct type
        if (!isValidVideoFormat(file)) {
            if (file.id) {
                updateUpload({
                    status: FileUploadStatus.ERROR,
                    message: `Cannot upload file of type ${file.type}`,
                });
                return Promise.resolve(true);
            }
            throw new Error(`Cannot upload file ${file.name} of type ${file.type}`);
        }
        // the case where a queued file gets cancelled - don't process
        if (file.id && !state.fileUploads[file.id]) {
            return Promise.resolve(true);
        }
        const id = existingVideo ? existingVideo.id : file.id ? file.id : nanoid(10);
        // if video replacement
        if (existingVideo) {
            // 1. Add file upload to the store
            commit(MUTATIONS.ADD_FILE_UPLOAD, {
                id,
                name: file.name,
                workspaceId,
                status: FileUploadStatus.QUEUED,
            });
        }
        try {
            // 2. Create/Update Firestore document for the video
            await createOrUpdateVideoDoc({
                existingVideo,
                workspaceId,
                filename: getFilenameWithoutExtension(file.name),
                docId: id,
            });
            // 3. Upload to Cloud Storage
            const uploadRes = (await ingestVideoFile({
                file,
                updateHandler: updateUpload,
                docId: id,
            }).catch((e) => ({ error: e })));
            if (uploadRes?.error) {
                if (uploadRes.error?.canceled) {
                    updateUpload({
                        status: FileUploadStatus.CANCELED,
                        message: `${file.name} - upload canceled`,
                    });
                    return Promise.resolve(true);
                }
                throw uploadRes.error;
            }
            logger.info('Video upload successful', uploadRes);
            updateUpload({ status: FileUploadStatus.COMPLETED });
            // when upload completes, log the event
            await makeAuditLogger(state.uid).then((ctx) => {
                if (ctx) {
                    const { Asset_Model, EVENT_ACTION, ts } = ctx.config;
                    const asset = {
                        id,
                        name: existingVideo?.title ?? file.name,
                        type: Asset_Model.Video,
                    };
                    return ctx.exec(asset, {
                        timestamp: ts(),
                        action: existingVideo ? EVENT_ACTION.UPDATED : EVENT_ACTION.CREATED,
                        data: {
                            type: 'INFO',
                            payload: {
                                message: existingVideo
                                    ? 'Video is replaced'
                                    : 'New Video is created',
                            },
                        },
                    });
                }
            });
            return Promise.resolve(true);
        }
        catch (e) {
            captureException(e, { tags: { videoId: id } });
            logger.error('Video file-upload', e);
            // eslint-disable-next-line @typescript-eslint/no-floating-promises
            dispatch(ACTIONS.DELETE_VIDEO_ONERROR, id);
            updateUpload({
                status: FileUploadStatus.ERROR,
                message: e?.message || e,
            });
            // (SPECIAL) when upload fails, log the event
            await makeAuditLogger(state.uid).then((ctx) => {
                if (ctx) {
                    const { Asset_Model, EVENT_ACTION, ts } = ctx.config;
                    const asset = {
                        id,
                        name: existingVideo?.title ?? file.name,
                        type: Asset_Model.Video,
                    };
                    return ctx.exec(asset, {
                        timestamp: ts(),
                        action: EVENT_ACTION.ERRORED,
                        data: {
                            type: 'ERROR',
                            payload: {
                                message: existingVideo
                                    ? 'Replace video operation failed'
                                    : 'Create new video operation failed',
                                extraInfo: {
                                    status: FileUploadStatus.ERROR,
                                    message: e?.message || e,
                                },
                            },
                        },
                    });
                }
            });
            return file.id ? Promise.resolve(true) : Promise.reject(e);
        }
        finally {
            file = null;
            uploadVideoTimer();
        }
    },
    [ACTIONS.CANCEL_FILE_UPLOAD]({ state, commit, dispatch }, id) {
        if (state.fileUploads[id]) {
            const { cancel } = state.fileUploads[id];
            cancel?.();
            commit(MUTATIONS.REMOVE_FILE_UPLOAD, id);
            // eslint-disable-next-line @typescript-eslint/no-floating-promises
            dispatch(ACTIONS.DELETE_VIDEO_ONERROR, id);
        }
    },
    // clear the video if it exists on Firestore
    async [ACTIONS.DELETE_VIDEO_ONERROR](_context, id) {
        try {
            const video = await DB.getInstance().video(id).get();
            // logger.info({ video })
            if (!video) {
                return;
            }
            else if (!video.appearance && !video.playbackIds) {
                await DB.getInstance().video(id).delete();
            }
            else {
                // an existing video doc with a failed or cancelled video upload event. Just restore it's prev states
                let uploadId = video.previousUploadId ?? video.uploadId;
                await DB.getInstance().video(id).update({
                    status: VideoStatus.Ready,
                    uploadId,
                    previousUploadId: video.uploadId,
                });
            }
        }
        catch (error) {
            logger.error('DELETE_VIDEO_ONERROR', { error });
        }
    },
    async [ACTIONS.REFRESH_AUTH_COOKIE]() {
        const headers = await reqHeaders();
        await fetchData('/api/auth/refresh-cookie', {
            method: 'POST',
            headers,
        }).catch((err) => {
            captureException(err);
            logger.error('REFRESH_AUTH_COOKIE', { err });
        });
    },
    async [ACTIONS.CLEAR_AUTH_COOKIE]() {
        await fetchData('/api/auth/clear-cookie', { method: 'POST' }).catch((err) => {
            captureException(err);
            logger.error('CLEAR_AUTH_COOKIE', { err });
        });
    },
    async [ACTIONS.GET_TOTAL_EMOJI_CLICKS](_context, { fid, vid }) {
        try {
            const headers = await reqHeaders();
            const { data } = await fetchData(`/api/viewer/total-emoji-clicks`, {
                method: 'POST',
                headers,
                body: JSON.stringify({
                    fid,
                    vid,
                    cid: getBrowserQueryParam('cid'),
                    standalone_video_filter: getBrowserQueryParam('standalone_video_filter'),
                }),
            });
            const { doc_count, buckets } = data;
            return { doc_count, buckets };
        }
        catch (e) {
            captureException(e);
            logger.error('GET_TOTAL_EMOJI_CLICKS', { e });
        }
    },
    async [ACTIONS.GET_FLY_OUT_EMOJIS](_context, { vid, range }) {
        try {
            const headers = await reqHeaders();
            const { data } = await fetchData(`/api/viewer/flyout-emojis`, {
                method: 'POST',
                headers,
                body: JSON.stringify({
                    vid,
                    range,
                    cid: getBrowserQueryParam('cid'),
                    standalone_video_filter: getBrowserQueryParam('standalone_video_filter'),
                }),
            });
            return data;
        }
        catch (e) {
            captureException(e);
            logger.error('GET_FLY_OUT_EMOJIS', { e });
        }
    },
    async [ACTIONS.SET_CUSTOM_CLAIMS]({ commit }, { idToken }) {
        // add a waiting state for the UI to show the loading indicator
        commit(MUTATIONS.SOCIAL_AUTH, { loading: true });
        return await fetchData('/api/auth/set-custom-claims', {
            method: 'POST',
            headers: {
                'X-Requested-With': 'XMLHttpRequest',
                'Authorization': `Bearer ${idToken}`,
            },
            body: JSON.stringify({ linkId: getBrowserQueryParam('link') }),
        })
            .catch((err) => {
            commit(MUTATIONS.SOCIAL_AUTH, {
                error: true,
                errorMessage: 'Sorry, Lumiere is not currently opened to the general public.',
            });
            throw err;
        })
            .finally(() => {
            commit(MUTATIONS.SOCIAL_AUTH, { loading: false });
        });
    },
    async [ACTIONS.GET_HEATMAP_DATA]({ commit }, { vid, fid, filterTerm, minMax, type }) {
        try {
            const func = type === FeatureType.Comments
                ? 'getVideoCommentsHeatmap'
                : 'getVideoExternalDataHeatmap';
            const { heatmapBucket } = await adminAPI((api) => api.video.insights[func]({
                vid,
                fid,
                cid: getBrowserQueryParam('cid'),
                filterTerm,
                minMax,
                standalone_video_filter: getBrowserQueryParam('standalone_video_filter'),
            }));
            logger.info('GET_HEATMAP_DATA', { type, heatmapBucket });
            commit(MUTATIONS.SAVE_VIDEO_ANALYTICS_DATA, {
                data: heatmapBucket,
                fid,
                type: 'heatmap',
            });
        }
        catch (e) {
            captureException(e);
        }
    },
    async [ACTIONS.GET_SHARE_LINK_WORKSPACE]({ state, commit, dispatch }, payload) {
        const { workspaceId: wid } = payload;
        try {
            const workspace = await DB.getInstance().workspace(wid).get();
            commit(MUTATIONS.SET_WORKSPACES, {
                ...state.workspaces,
                [wid]: workspace,
            });
            // eslint-disable-next-line @typescript-eslint/no-floating-promises
            dispatch(ACTIONS.SAVE_STATE);
        }
        catch (e) {
            captureException(e);
            logger.info(e);
        }
    },
    async [ACTIONS.SET_LINK]({ commit }) {
        const lid = getBrowserQueryParam('link');
        if (lid) {
            const link = await DB.getInstance().link(lid).get();
            commit(MUTATIONS.SET_LINK, link);
        }
    },
};
