import Vue from 'vue';
import chroma from 'chroma-js';
import { clientDynamicBucketing, hasNumberEl } from '../helper';
import { designColors } from '@/utils/helpers';
import { formatTime } from '@lumiere/shared/utils/numberFormatter';
import VideoInsightsEmojiCount from './VideoInsightsEmojiCount.vue';
import { sleep } from '@/utils/sleep';
import isEqual from 'lodash/isEqual';
import debounce from 'lodash/debounce';
import adminAPI from '@/services/adminAPI';
import { getBrowserQueryParam } from '@/utils/api';
import * as am4core from '@amcharts/amcharts4/core';
import * as am4charts from '@amcharts/amcharts4/charts';
import am4themes_animated from '@amcharts/amcharts4/themes/animated';
import VideoInsightsTrendsBucketPicker from '../VideoInsightsTrendsBucketPicker.vue';
import VideoInsightsVideoAIFacesOutput from '../VideoInsightsVideoAIFacesOutput.vue';
am4core.useTheme(am4themes_animated);
export default Vue.extend({
    name: 'VideoInsightsEmoji',
    data() {
        return {
            loading: false,
            insightData: null,
            // stride: 2,
            amChart: null,
            xAnnotation: null,
            amChartSeries: [],
            highlitIndex: -1,
            filtering: false,
            calculatedMinMax: null,
            chartsContainer: null,
            isResetChartData: false,
            scrollbarAmChart: null,
            scrollbarX: null,
            allCharts: [],
            cursorShowDisposers: [],
            shownCursorChangeDisposer: null,
            shownCursorZoomStartedDisposer: null,
            shownCursorZoomEndedDisposer: null,
            xAxis: null,
            customBucketSize: null,
            zoomMinMax: undefined,
            queryParamMinMax: null,
            switchingFeature: false,
            amChartWasCreated: false,
            dateAxis: null,
            externalFilterTriggeredZoom: false,
            externalFilterMinMax: null,
            baseInterval: 0,
            sceneObjects: [],
        };
    },
    components: {
        VideoInsightsEmojiCount,
        VideoInsightsTrendsBucketPicker,
        VideoInsightsVideoAIFacesOutput,
    },
    props: {
        video: {
            type: Object,
            required: true,
        },
        feature: {
            type: Object,
            required: true,
        },
        showFacesOutput: Boolean,
    },
    computed: {
        videoTime() {
            return this.$store.state.videoInfo.time;
        },
        tooltipFormat() {
            return this.video.duration > 3600 ? 'H:mm:ss' : 'mm:ss';
        },
        tickFormat() {
            return this.video.duration > 3600 ? 'H:mm:ss' : 'm:ss';
        },
        unixMidnight() {
            const d = new Date();
            d.setHours(0, 0, 0, 0);
            return d.getTime();
        },
        triggerStartEndTime() {
            let start = this.video.duration;
            let end = 0;
            const triggers = this.feature.triggers || [];
            const stoppers = this.feature.stoppers || [];
            //pay down the start
            triggers.forEach((trigger) => {
                const triggerStart = trigger.parameters?.time ?? 0;
                if (triggerStart < start) {
                    start = triggerStart;
                }
            });
            //pay up the end
            stoppers.forEach((stopper) => {
                const triggerEnd = stopper.parameters?.time ?? 0;
                if (triggerEnd > end) {
                    end = triggerEnd;
                }
            });
            //if for some reason this is not available, just use the video start and end
            if (!this.feature.triggers) {
                start = 0;
                end = this.video.duration;
            }
            return { start, end };
        },
        minMax() {
            let start, stop;
            if (this.calculatedMinMax) {
                start = this.calculatedMinMax.start;
                stop = this.calculatedMinMax.stop;
            }
            else {
                start = this.triggerStartEndTime.start;
                stop = this.triggerStartEndTime.end;
            }
            return { min: start, max: stop };
        },
        stride() {
            const { min, max } = this.minMax;
            return this.customBucketSize || clientDynamicBucketing(max - min);
        },
        //get the boundary of all the triggers
        normalizedStartAndEndTime() {
            const { start, end } = this.triggerStartEndTime;
            const stride = this.stride;
            //now normalize them to the intervals. make them divisible
            //by the stride.
            const startTimeInterval = start - (start % stride);
            const endTimeInterval = end + stride - (end % stride);
            return { start: startTimeInterval, end: endTimeInterval };
        },
        colors() {
            if (this.insightData) {
                const insightData = this.insightData;
                const dColors = designColors;
                if (insightData.length > dColors.length) {
                    return chroma
                        .scale(dColors)
                        .mode('lch')
                        .colors(insightData.length);
                }
                return dColors;
            }
            else {
                return [];
            }
        },
        darkenedColors() {
            return this.colors.map((color) => chroma(color).darken().css());
        },
        compositeAmChartsAndVideoScenes() {
            return {
                breakpoint1: this.$vuetify.breakpoint.mdAndDown,
                breakpoint2: this.$vuetify.breakpoint.xsOnly,
                created: this.amChartWasCreated,
                scenes: this.video.scenes,
                baseInterval: this.baseInterval,
            };
        },
        sceneLabelMaxWidth() {
            let width = 235;
            if (this.$vuetify.breakpoint.mdAndDown) {
                width = 200;
            }
            if (this.$vuetify.breakpoint.xsOnly) {
                width = 100;
            }
            return width;
        },
        emojisOrdering() {
            if (this.insightData) {
                const emojis = this.feature.options.emojis || [];
                let index = 0;
                const kvp = {};
                emojis.forEach((emoji) => {
                    kvp[emoji.name] = index;
                    index++;
                });
                return kvp;
            }
            else {
                return {};
            }
        },
        emojisAndTheirTotals() {
            if (this.insightData) {
                const emojis = this.feature.options.emojis || [];
                const insightData = this.insightData;
                const emojisAndTotals = [];
                const keyedData = insightData.reduce((acc, cur) => ({ [cur.key]: cur, ...acc }), {});
                for (let emoji of emojis) {
                    const bucketVal = keyedData[emoji.name];
                    const total = bucketVal?.doc_count ?? 0;
                    emojisAndTotals.push({ emoji, total });
                }
                //sort it according to the emojis ordering from the feature.
                const ordering = this.emojisOrdering;
                emojisAndTotals.sort((a, b) => {
                    return ordering[a.emoji.name] - ordering[b.emoji.name];
                });
                return emojisAndTotals;
            }
            else {
                return [];
            }
        },
        //An emoji in the data may or may not actually be attached to the feature.
        //This gives us a convenient way to test.
        currentEmojisInFeature() {
            return this.emojisAndTheirTotals.reduce((acc, cur) => ({ [cur.emoji.name]: cur, ...acc }), {});
        },
        totalInteractions() {
            if (this.insightData) {
                const insightData = this.insightData;
                let total = 0;
                insightData.forEach((bucket) => {
                    total += bucket.doc_count;
                });
                return total;
            }
            else {
                return null;
            }
        },
        chartFeverLinesByEmojiSeries() {
            if (this.insightData) {
                const insightData = this.insightData;
                const unixMidnight = this.unixMidnight;
                const feverlines = [];
                const featureEmojis = this.feature.options?.emojis || [];
                const stride = this.stride;
                const { start, end } = this.normalizedStartAndEndTime;
                //the buckets might not have certain emoji. If that's the case, then we
                //need to find the ones that it doesn't have and then back-fill them.
                const bucketEmojiNames = insightData.map((bucket) => bucket.key);
                const emojiDiff = featureEmojis.filter((emoji) => bucketEmojiNames.indexOf(emoji.name) === -1);
                for (let emoji of emojiDiff) {
                    const dummyBucketForZeros = {
                        key: emoji.name,
                        doc_count: 0,
                        particular_emoji_buckets: {
                            buckets: [{ key: start, doc_count: 0 }],
                        },
                    };
                    insightData.push(dummyBucketForZeros);
                }
                //loop through the buckets for each emoji and calculate the total
                //emojis for each of the buckets.
                insightData.forEach((bucket) => {
                    //bucket is like this:
                    // {
                    //   "key" : "lumiere laughing",
                    //   "doc_count" : 17,
                    //   "particular_emoji_buckets" : {
                    //   "buckets":[
                    //         { "key" : 0.0, "doc_count" : 1 },
                    //         { "key" : 2.0, "doc_count" : 3 },
                    //         ...
                    //         { "key" : 12.0, "doc_count" : 3 },
                    //     ],
                    //   }
                    // },
                    const emojiName = bucket.key;
                    //The emoji in the data may or may not actually be attached to the feature.
                    //the feature data can change and it's possible that the emoji is no longer present.
                    //Only calculate series for emojis that are currently in the data.
                    if (this.currentEmojisInFeature[emojiName]) {
                        const subBuckets = bucket.particular_emoji_buckets.buckets;
                        subBuckets.sort((elA, elB) => elA.key - elB.key);
                        //subBuckets is like this, and it's sorted by key, ascending.
                        //key = 2-second time-buckets. doc_count is the actual number of clicks.
                        //   [
                        //         { "key" : 0.0, "doc_count" : 1 },
                        //         { "key" : 2.0, "doc_count" : 3 },
                        //         ...
                        //         { "key" : 12.0, "doc_count" : 3 },
                        //     ],
                        //we're going to make an array of the data with all of the padding
                        //and all of the gaps filled in. when that's done we're going to transfer
                        //it to the feverline.
                        //Remember interval = time-buckets, counts = number of clicks
                        //interval = x, counts = y
                        const intervalAndCounts = [];
                        //create the interval-and-counts. This is the 2-dimensional data that
                        //we're going to use to make the series. but there's gaps in it. Not every
                        //bucket is represented. It might go 2,4,6 skip to 10.
                        subBuckets.forEach((subEl) => {
                            intervalAndCounts.push({
                                interval: subEl.key,
                                count: subEl.doc_count,
                            });
                        });
                        //now make a feverline element
                        const feverline = {
                            name: emojiName,
                            total: bucket.doc_count,
                            data: [],
                        };
                        //We're going to fill in the gaps. It might go 2,4,6 skip to 10, so
                        //what we're going to do is find the missing ones and fill them in
                        //with zeros. We do this by marching upward and seeing if the stack
                        //is greater than the interval of the [i]th bucket. If it's greater,
                        //then we have a gap that we have to manage and naturally the width
                        //of that gap is the delta between those two values.
                        if (intervalAndCounts.length > 0) {
                            const lastEl = intervalAndCounts[intervalAndCounts.length - 1];
                            let stack = start;
                            for (let i = 0; i < intervalAndCounts.length; i++) {
                                let el = intervalAndCounts[i];
                                //if el.interval greater than the stack, that means that the last one
                                //was the time=4 bucket and now we have the time=10 bucket, so that means
                                //that we skipped time=6 and time=8 so now we have to go and fill those in.
                                if (el.interval > stack) {
                                    //So how many do we need to add? Well if the stack is time=4 and we have time=10,
                                    //from this element then we need 10 - 4 / 2 = 3 elements to add
                                    //where our stride is 2, i.e. we're counting by 2s 2 4 6 8 10 12 etc.
                                    let numToAdd = (el.interval - stack) / stride;
                                    //so we're going to add these in *backwards* start with the last one,
                                    //and insert them all at this index.
                                    for (let k = numToAdd - 1; k >= 0; k--) {
                                        const newInterval = stride * (k + i) + start;
                                        intervalAndCounts.splice(i, 0, {
                                            interval: newInterval,
                                            count: 0,
                                        });
                                    }
                                    //increment these. We need to get to the next element and we don't
                                    //want to hit one of the ones that we added.
                                    i += numToAdd;
                                    stack += stride * numToAdd;
                                }
                                stack += stride;
                            }
                            //We might need to pad the end. So do that here. Easier because
                            //we're just appending so no tricky mechanics.
                            for (let i = lastEl.interval + stride; i <= end; i += stride) {
                                intervalAndCounts.push({ interval: i, count: 0 });
                            }
                            //transfer the data. note we're adding the timestamp because apexcharts
                            //wants timestamps for the datatime series.
                            intervalAndCounts.forEach((el) => {
                                feverline.data.push({
                                    x: unixMidnight + el.interval * 1000,
                                    y: el.count,
                                });
                            });
                        }
                        //finally, add it to the collection
                        feverlines.push(feverline);
                    }
                });
                //feverlines is now like this:
                // series: [
                //   {
                //     name: "lumiere laughing",
                //     data: [{x:29384747682, y:32}, {x:29384747684, y:10} ...]
                //   },
                //   {
                //     name: "lumiere love",
                //     data: [{x:484747682, y:20}, {x:484747684, y:92} ...]
                //   }
                // ],
                //And it's padded with 0s at the front and the end as well as all the little
                //gaps in between. So the intervals all go from the start of the trigger period
                //to the end of the trigger period by 2
                //now sort it by the emojis ordering from the feature.
                const ordering = this.emojisOrdering;
                feverlines.sort((a, b) => {
                    return ordering[a.name] - ordering[b.name];
                });
                //Now, in one final indiginity foisted on us by the imperatives of the tooltip,
                //we need to transpose the data. It needs to be like this:
                // [
                //  { date:483923, value0:4, value1:40, value2:38},
                //  { date:483925, value0:45, value1:2, value2:12},
                //  ...
                //  ...
                // ]
                //
                // And then we need to of course have an array of all those data-fields.
                // Everyone has their own weird little data formats, pretty sure there's an xkcd about that.
                const finalSeries = [];
                const len = feverlines[0].data.length;
                for (let i = 0; i < len; i++) {
                    const seriesDataPoint = { date: 0 };
                    feverlines.forEach((fLine, index) => {
                        const dataPoint = fLine.data[i];
                        if (dataPoint) {
                            if (index == 0) {
                                seriesDataPoint['date'] = dataPoint.x;
                            }
                            const dataFieldKey = `value${index}`;
                            seriesDataPoint[dataFieldKey] = dataPoint.y;
                        }
                    });
                    finalSeries.push(seriesDataPoint);
                }
                const dataFields = [];
                feverlines.forEach((fLine, index) => {
                    const dataFieldKey = `value${index}`;
                    const tuple = {
                        field: dataFieldKey,
                        name: fLine.name,
                    };
                    dataFields.push(tuple);
                });
                const seriesData = {
                    series: finalSeries,
                    dataFields,
                };
                return seriesData;
            }
            else {
                return {
                    series: [],
                    dataFields: [],
                };
            }
        },
        formattedTriggerTime() {
            const time = this.minMax;
            return {
                startTime: formatTime(time.min),
                stopTime: formatTime(time.max),
            };
        },
        showSkeleton() {
            return this.totalInteractions == null || this.loading;
        },
        triggerDuration() {
            return this.triggerStartEndTime.end - this.triggerStartEndTime.start;
        },
    },
    methods: {
        getColorAtIndex(index, darken) {
            const colors = darken ? this.darkenedColors : this.colors;
            return colors.length > index ? colors[index] : '';
        },
        async loadResponse(minMax, isFilter = false) {
            if (minMax || isFilter) {
                this.filtering = true;
            }
            else {
                this.loading = true;
            }
            minMax = {
                min: minMax?.min ?? this.triggerStartEndTime.start,
                max: minMax?.max ?? this.triggerStartEndTime.end,
            };
            // this.$emit('load-start')
            this.$emit('emoji-filter', {
                ...minMax,
            });
            try {
                const { buckets } = await adminAPI(async (api) => api.video.insights.getVideoEmojiInsights({
                    vid: this.video.id,
                    fid: this.feature.id,
                    cid: getBrowserQueryParam('cid'),
                    minMax,
                    customBucketSize: this.customBucketSize,
                    standalone_video_filter: getBrowserQueryParam('standalone_video_filter'),
                }));
                this.insightData = buckets;
                // await this.$nextTick()
                // this.createAmChartContainer()
            }
            catch (error) {
                console.log({ error });
            }
            finally {
                this.loading = false;
                this.filtering = false;
                // this.$emit('load-end')
            }
            await this.$nextTick();
            this.createAmChartContainer();
        },
        mouseOverEmojiAtIndex(index) {
            this.highlightLineAtIndex(index);
        },
        mouseLeaveEmojiAtIndex(_index) {
            this.unhighlightAll();
        },
        moveXAnnotation(videoTime) {
            try {
                const videoTimeDate = new Date(this.unixMidnight + videoTime * 1000);
                const xAnnotation = this.xAnnotation;
                if (xAnnotation) {
                    xAnnotation.date = videoTimeDate;
                    // xAnnotation.endDate = videoTimeDate
                    xAnnotation.label.text = this.formatToHHMMSS(videoTime);
                }
            }
            catch (e) {
                if (e.message != 'EventDispatcher is disposed') {
                    throw e;
                }
            }
        },
        highlightLineAtIndex(highlightThis) {
            const arr = this.amChartSeries;
            arr.forEach((series, index) => {
                if (index == highlightThis) {
                    series.strokeWidth = 5;
                    series.opacity = 1;
                }
                else {
                    series.strokeWidth = 3;
                    series.opacity = 0.3;
                }
            });
        },
        unhighlightAll() {
            const arr = this.amChartSeries;
            arr.forEach((series) => {
                series.strokeWidth = 3;
                series.opacity = 1;
            });
        },
        formatToHHMMSS(currentTime) {
            return formatTime(currentTime);
        },
        disposeOfCharts() {
            this.sceneObjects.splice(0, this.sceneObjects.length);
            this.amChartWasCreated = false;
            this.amChart?.dispose();
            this.amChart = null;
            this.amChartSeries = [];
            this.scrollbarAmChart?.dispose();
            this.scrollbarAmChart = null;
            this.isResetChartData = false;
            this.chartsContainer?.dispose();
            this.chartsContainer = null;
            this.zoomMinMax = undefined;
            this.customBucketSize = null;
            this.baseInterval = 0;
        },
        async createAmChartContainer() {
            this.isResetChartData = false;
            if (this.chartsContainer) {
                this.refreshChartData();
                return;
            }
            const container = am4core.create(this.$refs.chartContainer, am4core.Container);
            this.chartsContainer = container;
            container.width = am4core.percent(100);
            container.height = am4core.percent(100);
            container.layout = 'vertical';
            container.events.on('up', (_evt) => {
                for (let i = 0; i < allCharts.length; i++) {
                    const cursor = allCharts[i].cursor;
                    cursor.selection.hide(0);
                }
            });
            container.events.on('down', () => {
                this.isResetChartData = false;
            });
            const allCharts = [];
            // create scrollbar chart
            this.scrollbarAmChart = container.createChild(am4charts.XYChart);
            this.scrollbarAmChart.height = am4core.percent(7);
            this.scrollbarAmChart.plotContainer.visible = false;
            this.scrollbarAmChart.leftAxesContainer.visible = false;
            this.scrollbarAmChart.rightAxesContainer.visible = false;
            this.scrollbarAmChart.bottomAxesContainer.visible = false;
            this.scrollbarAmChart.zoomOutButton.parent =
                this.scrollbarAmChart.tooltipContainer;
            allCharts.push(this.scrollbarAmChart);
            // create retention chart
            this.amChart = container.createChild(am4charts.XYChart);
            //This is so that the scenes AxisLabels appear behind the plot-line
            this.amChart.bottomAxesContainer.zIndex = 0;
            this.amChart.yAxesAndPlotContainer.zIndex = 1;
            this.amChart.height = am4core.percent(60);
            allCharts.push(this.amChart);
            this.allCharts = allCharts;
            await this.$nextTick();
            this.scrollbarX = new am4core.Scrollbar();
            const firstChart = allCharts[0];
            firstChart.scrollbarX = this.scrollbarX;
            this.createGlobalScrollbarX();
            this.createEmojisAmChart();
            await sleep(0);
            firstChart.zoomOutButton.disabled = false;
            this.initCursorListeners();
            for (let i = 1; i < allCharts.length; i++) {
                const chart = allCharts[i];
                chart.events.on('up', (evt) => {
                    const cursor = evt.target.cursor;
                    // cursor.dispatchImmediately('zoomended')
                    for (let i = 1; i < allCharts.length; i++) {
                        const otherCursor = allCharts[i].cursor;
                        if (!isEqual(otherCursor, cursor)) {
                            otherCursor.triggerUp({ x: cursor.point.x, y: 0 });
                        }
                    }
                });
            }
        },
        initCursorListeners() {
            const cursorShowDisposers = [];
            for (let i = 0; i < this.allCharts.length; i++) {
                const cursor = this.allCharts[i].cursor;
                cursor.interactionsEnabled = true;
                cursorShowDisposers.push(cursor.events.on('shown', (event) => {
                    this.handleShowCursor(event.target);
                }));
            }
            this.cursorShowDisposers = cursorShowDisposers;
        },
        // disable mouse for all other cursors
        handleShowCursor(shownCursor) {
            const syncCursors = (syncWithCursor) => {
                for (let i = 0; i < this.allCharts.length; i++) {
                    const cursor = this.allCharts[i].cursor;
                    const point = { x: syncWithCursor.point.x, y: 0 };
                    if (!isEqual(cursor, syncWithCursor)) {
                        cursor.triggerMove(point);
                    }
                }
            };
            // disable mouse for all other cursors
            for (let i = 0; i < this.allCharts.length; i++) {
                const cursor = this.allCharts[i].cursor;
                if (!isEqual(cursor, shownCursor)) {
                    cursor.interactionsEnabled = false;
                }
                // remove show listener
                this.cursorShowDisposers[i].dispose();
            }
            // add change disposer to the hovered chart cursor
            this.shownCursorChangeDisposer = shownCursor.lineX.events.on('positionchanged', (_evt) => {
                syncCursors(shownCursor);
            });
            this.shownCursorZoomStartedDisposer = shownCursor.events.on('zoomstarted', (event) => {
                for (let i = 0; i < this.allCharts.length; i++) {
                    const cursor = this.allCharts[i].cursor;
                    if (!isEqual(cursor, event.target)) {
                        const point = { x: event.target.point.x, y: 0 };
                        cursor.triggerDown(point);
                    }
                }
            });
            this.shownCursorZoomEndedDisposer = shownCursor.events.on('zoomended', (event) => {
                for (let i = 0; i < this.allCharts.length; i++) {
                    const cursor = this.allCharts[i].cursor;
                    if (!isEqual(cursor, event.target)) {
                        const point = { x: event.target.point.x, y: 0 };
                        cursor.triggerUp(point);
                    }
                }
            });
            shownCursor.events.once('hidden', (_event) => {
                this.shownCursorChangeDisposer.dispose();
                this.shownCursorZoomStartedDisposer.dispose();
                this.shownCursorZoomEndedDisposer.dispose();
                for (let i = 0; i < this.allCharts.length; i++) {
                    const cursor = this.allCharts[i].cursor;
                    cursor.hide(0);
                    this.cursorShowDisposers[i]?.dispose();
                }
                this.initCursorListeners();
            });
        },
        syncDateAxes(syncWithAxis) {
            for (let i = 0; i < this.allCharts.length; i++) {
                const chart = this.allCharts[i];
                const dateAxis = chart.cursor.xAxis;
                if (!isEqual(dateAxis, syncWithAxis)) {
                    i > 0 && dateAxis.events.disableType('startendchanged');
                    dateAxis.start = syncWithAxis.start;
                    dateAxis.end = syncWithAxis.end;
                    i > 0 && dateAxis.events.enableType('startendchanged');
                }
            }
        },
        createEmojisAmChart() {
            let chart = this.amChart;
            const { dateAxis } = this.setChartProps(chart, {
                title: 'Emoji interactions',
                // valueAxis: { min: 0, max: 10 },
            });
            const emojiSeriesData = this.chartFeverLinesByEmojiSeries;
            chart.data = emojiSeriesData.series;
            chart.zoomOutButton.disabled = true;
            chart.legend = new am4charts.Legend();
            chart.legend.fontSize = 14;
            chart.legend.labels.template.opacity = 0.8;
            emojiSeriesData.dataFields.forEach((dataField, index) => {
                // Create series
                const series = chart.series.push(new am4charts.LineSeries());
                const tooltip = series.tooltip;
                //rollover.
                //https://github.com/amcharts/amcharts4/issues/1705
                series.segments.template.interactionsEnabled = true;
                series.segments.template.events.on('over', (_ev) => {
                    this.highlightLineAtIndex(index);
                    this.highlitIndex = index;
                });
                series.segments.template.events.on('out', (_ev) => {
                    this.unhighlightAll();
                    this.highlitIndex = -1;
                });
                series.name = dataField.name;
                series.dataFields.valueY = dataField.field;
                series.dataFields.dateX = 'date';
                series.minBulletDistance = 15;
                series.stroke = am4core.color(this.colors[index]); // red
                series.strokeWidth = 3; // 3px
                series.opacity = 1;
                series.tensionX = 0.77;
                if (tooltip) {
                    tooltip.dateFormatter.dateFormat = this.tooltipFormat;
                    // Set up tooltip
                    series.adapter.add('tooltipText', (_ev) => {
                        let text = '[bold]{dateX}[/]\n';
                        chart.series.each((item, index) => {
                            const highlightOpen = index == this.highlitIndex ? '[bold]' : '';
                            const highlightClose = index == this.highlitIndex ? '[/]' : '';
                            text +=
                                '[' +
                                    item.stroke.hex +
                                    ']●[/] ' +
                                    highlightOpen +
                                    item.name +
                                    ': {' +
                                    item.dataFields.valueY +
                                    '}' +
                                    highlightClose +
                                    '\n';
                        });
                        return text;
                    });
                    tooltip.getFillFromObject = false;
                    tooltip.background.fill = am4core.color('#fff');
                    tooltip.label.fill = am4core.color('#00');
                }
                this.amChartSeries.push(series);
            });
            const range = dateAxis.axisRanges.create();
            range.date = new Date(this.unixMidnight);
            range.axisFill.fill = am4core.color('#666666');
            range.axisFill.fillOpacity = 0.2;
            range.label.text = '';
            range.label.inside = true;
            range.label.rotation = 90;
            range.label.horizontalCenter = 'right';
            range.label.verticalCenter = 'bottom';
            range.label.fontSize = 12;
            range.grid.stroke = am4core.color('#666666');
            range.grid.strokeOpacity = 0.9;
            range.grid.strokeWidth = 3;
            range.grid.strokeDasharray = '2,2';
            this.xAnnotation = range;
            this.dateAxis = dateAxis;
            this.amChartWasCreated = true;
        },
        /**
         * All and Ratings Chart for Global ScrollbarX
         */
        createGlobalScrollbarX() {
            const emojiSeriesData = this.chartFeverLinesByEmojiSeries;
            const seriesData = emojiSeriesData.series;
            const chart = this.scrollbarAmChart;
            this.setChartProps(chart, {}); // returns {dateAxis, valueAxis}
            chart.data = seriesData;
            // const scrollbarX = this.scrollbarX!
            emojiSeriesData.dataFields.forEach((dataField, index) => {
                const series = chart.series.push(new am4charts.LineSeries());
                const tooltip = series.tooltip;
                series.name = dataField.name;
                series.dataFields.valueY = dataField.field;
                series.dataFields.dateX = 'date';
                series.minBulletDistance = 15;
                series.stroke = am4core.color(this.colors[index]); // red
                series.strokeWidth = 3; // 3px
                series.opacity = 1;
                series.tensionX = 0.77;
                series.segments.template.interactionsEnabled = true;
                series.interpolationDuration = 5;
                series.tensionX = 0.77;
                series.showOnInit = false;
                if (tooltip) {
                    tooltip.dateFormatter.dateFormat = this.tooltipFormat;
                    tooltip.getFillFromObject = false;
                    tooltip.background.fill = am4core.color('#fff');
                    tooltip.label.fill = am4core.color('#000');
                }
            });
            chart.padding(0, 20, 20, 25);
        },
        setBaseInterval(dateAxis) {
            if (dateAxis.baseInterval && dateAxis.baseInterval.count > 0) {
                this.baseInterval = dateAxis.baseInterval.count;
            }
        },
        setChartProps(chart, config) {
            // https://www.amcharts.com/docs/v4/concepts/formatters/formatting-date-time/
            // When chart parses externally loaded data, or even if you set the date-based data directly to the chart,
            // but use strings or integer timestamps instead of full-fledged Date objects chart expects, it will use
            // its dateFormatter to parse those into dates. Set input format for the dates there's different options
            // for this, but I'm going to use Unix timestamps, so we have to go with "x" here.
            chart.dateFormatter.inputDateFormat = 'x';
            chart.padding(10, 15, 10, 15);
            const dateAxis = chart.xAxes.push(new am4charts.DateAxis());
            dateAxis.cursorTooltipEnabled = false;
            dateAxis.renderer.labels.template.disabled = false;
            //Solves the problem with first label not quite aligning with the data.
            //https://codepen.io/CodeOwl/pen/XWXpmRV?editors=1011
            dateAxis.renderer.grid.template.location = 0.5;
            dateAxis.renderer.grid.template.disabled = true;
            dateAxis.startLocation = 0.5;
            dateAxis.endLocation = 0.5;
            //https://www.amcharts.com/docs/v4/concepts/axes/positioning-axis-elements/
            //this is workaround for location from the amcharts docs.
            dateAxis.renderer.labels.template.location = 0.001;
            //Reset the grid intervals. We need a little more sensitivity for minutes
            //because most of our videos are on the order of minutes.
            dateAxis.gridIntervals = new am4core.List([
                { timeUnit: 'second', count: 1 },
                { timeUnit: 'second', count: 5 },
                { timeUnit: 'second', count: 10 },
                { timeUnit: 'second', count: 30 },
                { timeUnit: 'minute', count: 1 },
                { timeUnit: 'minute', count: 2 },
                { timeUnit: 'minute', count: 3 },
                { timeUnit: 'minute', count: 4 },
                { timeUnit: 'minute', count: 5 },
                { timeUnit: 'minute', count: 6 },
                { timeUnit: 'minute', count: 8 },
                { timeUnit: 'minute', count: 10 },
                { timeUnit: 'minute', count: 30 },
                { timeUnit: 'hour', count: 1 },
                { timeUnit: 'hour', count: 3 },
                { timeUnit: 'hour', count: 6 },
                { timeUnit: 'hour', count: 12 },
            ]);
            dateAxis.keepSelection = true;
            if (dateAxis.tooltip) {
                dateAxis.tooltip.disabled = false;
                dateAxis.tooltip.animationDuration = 0;
            }
            // Create y-axis
            const valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
            if (valueAxis.tooltip) {
                valueAxis.tooltip.disabled = true;
            }
            valueAxis.title.text = config.title ?? '';
            valueAxis.title.fontSize = 14;
            valueAxis.title.fontWeight = '400';
            valueAxis.title.opacity = 0.8;
            chart.events.on('ready', (_ev) => {
                const now = this.unixMidnight;
                const { start, end } = this.triggerStartEndTime;
                dateAxis.min = now + start * 1000;
                dateAxis.max = now + end * 1000; //dateAxis.maxZoomed
                if (this.queryParamMinMax) {
                    let { min = start, max = end } = this.queryParamMinMax;
                    this.queryParamMinMax = null;
                    this.isResetChartData = true;
                    if (this.amChart) {
                        this.calculatedMinMax = { start: min, stop: max };
                        const dateAxis = this.amChart.cursor.xAxis;
                        if (dateAxis) {
                            const startDiff = min - start;
                            const begin = startDiff < 0.5 ? 0 : startDiff - 0.5;
                            const triggerDiff = end - start > 0 ? end - start : end;
                            dateAxis.start = begin / triggerDiff;
                            dateAxis.end = (max + 0.5 - start) / triggerDiff;
                        }
                    }
                }
                valueAxis.min = 0;
                valueAxis.max = config.valueAxis?.max ?? valueAxis.maxZoomed + 0.5;
                this.setBaseInterval(dateAxis);
            });
            dateAxis.events.on('startendchanged', (ev) => {
                const now = this.unixMidnight;
                const min = ev.target.minZoomed;
                const max = ev.target.maxZoomed;
                const { start, end: stop } = this.triggerStartEndTime;
                let newMin = min;
                let newMax = max;
                if (min < now + 1000) {
                    newMin = now + start * 1000;
                }
                if (max > now + stop * 1000) {
                    newMax = now + stop * 1000;
                }
                const xaxis = { min: newMin, max: newMax };
                this.onChartZoomed(null, { xaxis });
                this.syncDateAxes(ev.target);
                this.setBaseInterval(dateAxis);
            });
            chart.zoomOutButton.scale = 1.2;
            chart.zoomOutButton.events.on('hit', (_ev) => {
                // this.scrollbarSeriesData = []
                this.isResetChartData = false;
                const now = this.unixMidnight;
                const { start, end: stop } = this.triggerStartEndTime;
                dateAxis.min = now + start * 1000;
                dateAxis.max = now + stop * 1000;
                // reset the currently selected scene filter
                this.$events.emit('resetVideoSceneFilter');
            });
            chart.cursor = new am4charts.XYCursor();
            chart.cursor.lineY.disabled = true;
            chart.cursor.xAxis = dateAxis;
            // the -1 is so that we only ever have the ONE tooltip.
            // https://www.amcharts.com/docs/v4/reference/xycursor/#maxTooltipDistance_property
            chart.cursor.maxTooltipDistance = -1;
            chart.cursor.yAxis = valueAxis;
            let cursorXPosition = null;
            chart.cursor.events.on('cursorpositionchanged', function (ev) {
                var xAxis = ev.target.chart.xAxes.getIndex(0);
                if (xAxis) {
                    // @ts-ignore
                    cursorXPosition = xAxis.positionToDate(xAxis.toAxisPosition(ev.target.xPosition));
                }
            });
            chart.plotContainer.events.on('hit', (_ev) => {
                if (cursorXPosition) {
                    const dateStringToDate = new Date(cursorXPosition);
                    const sec = dateStringToDate.getSeconds();
                    const minToSec = dateStringToDate.getMinutes() * 60;
                    const hrToSec = dateStringToDate.getHours() * 60 * 60;
                    const vctime = sec + minToSec + hrToSec;
                    this.$events.emit('chartTapped', { vctime });
                }
            });
            // Set date label formatting
            //These things affect how it formats dates in different
            //scales. When it's in hours, it will format it one way, when you
            //zoom in to minutes, it's another thing, and finally when you zoom
            //all the way in to seconds it's another format.
            dateAxis.dateFormats.setKey('hour', this.tickFormat);
            dateAxis.dateFormats.setKey('minute', this.tickFormat);
            dateAxis.dateFormats.setKey('second', this.tickFormat);
            dateAxis.markUnitChange = false;
            return { dateAxis, valueAxis };
        },
        onChartZoomed: debounce(function (_chartContext, { xaxis }) {
            if (this.isResetChartData)
                return;
            const { min: chartMin, max: chartMax } = xaxis;
            const now = this.unixMidnight;
            let startStop;
            if (chartMin || chartMax) {
                const { start: trigger_start, end: trigger_stop } = this.triggerStartEndTime;
                const min = chartMin ?? now + trigger_start * 1000;
                const max = chartMax ?? now + trigger_stop * 1000;
                let start = Math.floor((min - now) / 1000);
                let stop = Math.ceil((max - now) / 1000);
                if (!this.externalFilterTriggeredZoom) {
                    const interval = this.customBucketSize ||
                        clientDynamicBucketing((max - min) / 1000);
                    if (start - interval >= trigger_start) {
                        start -= interval;
                    }
                    else {
                        start = trigger_start;
                    }
                    if (stop + interval <= trigger_stop) {
                        stop += interval;
                    }
                    else {
                        stop = trigger_stop;
                    }
                    startStop = {
                        start: +start.toFixed(2),
                        stop: +stop.toFixed(2),
                    };
                }
                else {
                    startStop = {
                        start: this.externalFilterMinMax.min,
                        stop: this.externalFilterMinMax.max,
                    };
                }
            }
            else {
                startStop = this.triggerStartEndTime;
            }
            this.calculatedMinMax = startStop;
            this.xAxis = this.calculatedMinMax;
            this.externalFilterTriggeredZoom = false;
            this.externalFilterMinMax = null;
            const minMax = {
                min: startStop.start,
                max: startStop.stop,
            };
            this.loadResponse(minMax).then(() => {
                this.zoomMinMax = minMax;
            });
        }, 500, {
            leading: false,
            trailing: true,
        }),
        resetSeries() {
            this.onChartZoomed(null, { xaxis: {}, yaxis: {} });
        },
        refreshChartData() {
            const emojiSeriesData = this.chartFeverLinesByEmojiSeries;
            const chart = this.amChart;
            if (chart) {
                chart.series.each(function (series) {
                    series.appear();
                });
                this.isResetChartData = true;
                chart.data = emojiSeriesData.series;
                const dateAxis = chart.cursor.xAxis;
                if (dateAxis) {
                    // @ts-ignore
                    dateAxis.keepSelection = true;
                }
                const valueAxis = chart.yAxes.getIndex(0);
                let tempMax = 0;
                if (valueAxis && chart.data) {
                    const valueMax = chart.data.reduce((acc, { date, ...values }) => {
                        tempMax = Math.max.apply(null, Object.values(values));
                        return tempMax > acc ? tempMax : acc;
                    }, 0);
                    // @ts-ignore
                    valueAxis.max = valueMax + 0.5;
                }
            }
        },
        onBucketSizeChanged(size) {
            this.customBucketSize = size;
            this.loadResponse(this.zoomMinMax, true);
        },
        drawScenesOnGraph(scenes, dateAxis) {
            const interval = this.baseInterval;
            //handle the scene object explicity because we don't want to delete the x-annotation accidentally
            this.sceneObjects.forEach((sceneObject) => {
                dateAxis.axisRanges.removeValue(sceneObject);
            });
            this.sceneObjects.splice(0, this.sceneObjects.length);
            const sceneArr = Object.values(scenes).sort((a, b) => a.startTime - b.startTime);
            sceneArr.forEach((scene, idx) => {
                const r = dateAxis.axisRanges.create();
                this.sceneObjects.push(r);
                const colorForScene = this.getColorAtIndex(idx, false);
                const darkColorForScene = this.getColorAtIndex(idx, true);
                //chuks uses 0.5 elsewhere to solve an alignment problem, that affects where
                //the charts starts at. but it doens't start at 0.5 it starts at 0.5*baseInterval.
                const startTime = Math.max(interval / 2, scene.startTime);
                r.date = new Date(this.unixMidnight + startTime * 1000);
                r.endDate = new Date(this.unixMidnight + scene.stopTime * 1000);
                r.axisFill.fill = am4core.color(colorForScene);
                r.axisFill.fillOpacity = 0.2;
                r.label.text = scene.name;
                r.label.inside = true;
                r.label.horizontalCenter = 'left';
                r.label.valign = 'bottom';
                r.label.verticalCenter = 'top';
                r.label.rotation = -90;
                r.label.fill = am4core.color(darkColorForScene);
                r.label.truncate = true;
                r.label.maxWidth = this.sceneLabelMaxWidth;
                r.label.fontSize = 10;
                r.grid.strokeOpacity = 0;
                r.label.padding(3, 3, 3, 6);
            });
        },
        externalFilterTriggerZoom(min, max) {
            const { start, end } = this.triggerStartEndTime;
            if (this.amChart) {
                this.isResetChartData = false;
                this.externalFilterTriggeredZoom = true;
                min || (min = start);
                max || (max = end);
                if (min < start) {
                    min = start;
                }
                if (max > end) {
                    max = end;
                }
                this.externalFilterMinMax = { min: +min, max: +max };
                const dateAxis = this.amChart.cursor.xAxis;
                console.log({ dateAxis });
                if (dateAxis) {
                    dateAxis.start = (min - start) / (end - start);
                    dateAxis.end = (max - start) / (end - start);
                }
            }
        },
    },
    beforeDestroy() {
        this.disposeOfCharts();
    },
    watch: {
        'feature'(_newVal) {
            this.disposeOfCharts();
            this.switchingFeature = true;
            this.$sleep(10).then(() => {
                this.switchingFeature = false;
                this.loadResponse();
            });
        },
        'videoTime'(newTime) {
            this.moveXAnnotation(newTime);
        },
        'triggerStartEndTime': {
            handler(minMax) {
                const { start, end: stop } = minMax;
                this.calculatedMinMax = { start, stop };
            },
            immediate: true,
        },
        'xAxis'() { },
        'video.eids': {
            async handler(eids, oldEids) {
                if (!isEqual(eids, oldEids)) {
                    // refresh the data with the new eids video filter
                    await this.$sleep(1000);
                    void this.loadResponse();
                }
            },
        },
        '$route.query.cid': {
            handler(c, oc) {
                if (c || oc)
                    void this.loadResponse();
            },
        },
        'compositeAmChartsAndVideoScenes': {
            handler(newVal) {
                if (newVal.created && newVal.scenes && this.dateAxis) {
                    this.drawScenesOnGraph(newVal.scenes, this.dateAxis);
                }
            },
            immediate: true,
        },
        '$route.query.standalone_video_filter': {
            handler(v, ov) {
                if (v || ov)
                    void this.loadResponse();
            },
        },
    },
    events: {
        videoSceneFilter(params) {
            let { min, max } = params;
            this.externalFilterTriggerZoom(min, max);
        },
        videoFacesFilter(params) {
            let { min, max } = params;
            this.externalFilterTriggerZoom(min, max);
        },
    },
    mounted() {
        const { min, max } = this.$route.query;
        const minMax = {};
        const { start, end } = this.triggerStartEndTime;
        if (hasNumberEl([min, max])) {
            if (hasNumberEl(min)) {
                minMax.min = +min >= start && +min < end ? +min : start;
            }
            if (hasNumberEl(max)) {
                minMax.max = +max <= end && +max > start ? +max : end;
            }
            this.queryParamMinMax = minMax;
        }
        this.zoomMinMax = minMax;
        this.loadResponse(minMax);
    },
});
