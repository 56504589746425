import Vue from 'vue';
import getRandomElement from '@lumiere/shared/utils/getRandomElement';
import COLORS from '@/colors';
import { nanoid } from 'nanoid';
const getRandomColor = getRandomElement(Object.values(COLORS));
export default Vue.extend({
    name: 'ChannelAddTagForm',
    props: {
        workspaceId: {
            required: true,
            type: String,
        },
        channelId: {
            type: String,
            required: true,
        },
    },
    data() {
        return {
            label: '',
            color: getRandomColor(),
            saving: false,
        };
    },
    computed: {
        inputRef() {
            return this.$refs.input;
        },
        COLORS() {
            return COLORS;
        },
    },
    watch: {},
    methods: {
        onSubmit() {
            this.saving = true;
            const { label, color } = this;
            const id = nanoid();
            this.$emit('saving');
            const payload = {
                label,
                color,
                nonce: Date.now(),
            };
            this.$db
                .workspace(this.workspaceId)
                .update({
                [`tags.${id}`]: payload,
            })
                .then(() => {
                this.$db
                    .channel(this.channelId)
                    .update({
                    [`channelTags.${id}`]: payload,
                })
                    .catch((err) => console.log(err));
            })
                .then(() => {
                this.$emit('complete', id);
                this.saving = false;
            });
        },
    },
    mounted() {
        this.inputRef.focus();
    },
    components: {},
});
