import Vue from 'vue';
import { SVG } from '@svgdotjs/svg.js';
import chroma from 'chroma-js';
import { designColors } from '@/utils/helpers';
import VideoThumbnail from './VideoThumbnail.vue';
import { nanoid } from 'nanoid';
import { getHexColor } from '@/colors';
const TIME_DELTA = 0.1;
export default Vue.extend({
    name: 'VueTimeSegments',
    props: {
        videoDuration: { type: Number, required: true },
        seenDuration: Number,
        seenDurationRatio: Number,
        videoId: {
            type: String,
            required: true,
        },
        faceIds: {
            type: Array,
            required: true,
        },
        instances: {
            type: Array,
            default: () => [],
        },
        personId: { type: String, required: true },
        videoScenes: {
            type: Object,
        },
        managementMode: Boolean,
        comparableInstances: {
            type: Array,
            default: () => [],
        },
        startPoint: {
            type: Number,
            default: 0,
        },
    },
    data() {
        return {
            container: { width: 0 },
            draw: null,
            hoverTime: null,
        };
    },
    computed: {
        workspaceColor() {
            return getHexColor(this.$store.getters.currentWorkspace?.theme.color || '#ff0000');
        },
        cursorHalfwayLeft() {
            if (!this.hoverTime)
                return true;
            const hoverRatio = (this.hoverTime - this.startPoint) / this.videoDuration;
            return hoverRatio < 0.5;
        },
        midHoverPoint() {
            if (!this.hoverTime)
                return null;
            const hoverTime = this.hoverTime;
            const theInstance = this.instances.find((inst) => hoverTime >= inst.start && hoverTime <= inst.end);
            if (!theInstance)
                return null;
            const { start, end } = theInstance;
            return (start + end) / 2;
        },
        colors() {
            if (this.videoScenes) {
                const numColors = Object.keys(this.videoScenes).length;
                const dColors = designColors;
                if (numColors > dColors.length) {
                    return chroma.scale(dColors).mode('lch').colors(numColors);
                }
                return dColors;
            }
            else {
                return [];
            }
        },
        internalKey() {
            return nanoid(5);
        },
    },
    methods: {
        drawTimeSegments(instances) {
            const draw = SVG()
                .addTo(`#time-segments-main__${this.internalKey}`)
                .size('100%', 10);
            // svg drawing area set to a 1x1 box so that everything can be calculated as a percentage of the video time
            // that 1x1 svg is then stretched to fit the parent container, in this case a 500x10 space
            const x0 = this.startPoint / this.videoDuration;
            draw.viewbox(x0, 0, 1, 1);
            draw.attr('preserveAspectRatio', 'none');
            // add some color to fill the whole background
            draw.rect(1, 1).fill('#e8e8e8').x(x0);
            // appearance rectangles are drawn against the 1x1 box
            // Add video scene coloration
            if (this.videoScenes) {
                Object.values(this.videoScenes)
                    .sort((a, b) => a.startTime - b.startTime)
                    .forEach((item, ix) => {
                    const { startTime, stopTime } = item;
                    const duration = stopTime - startTime;
                    const sceneColor = this.colors[ix];
                    draw
                        .rect(duration / this.videoDuration, 1)
                        .fill(sceneColor)
                        .x(startTime / this.videoDuration)
                        .opacity(0.2);
                });
            }
            // --
            const wsColor = this.workspaceColor;
            // Add face instances/appearance coloration
            for (const { duration, start, end } of instances) {
                const overlapsSet = this.checkOverlap({ start, end });
                draw
                    .rect((duration || 0) / this.videoDuration, 1)
                    .fill(wsColor)
                    .x(start / this.videoDuration);
                if (overlapsSet.length) {
                    for (const overlap of overlapsSet) {
                        const [overlapStart, overlapEnd] = overlap;
                        const overlapDuration = overlapEnd - overlapStart;
                        draw
                            .rect((overlapDuration || 0) / this.videoDuration, 1)
                            .fill('#ccc')
                            .x(overlapStart / this.videoDuration)
                            .opacity(0.3)
                            .animate();
                    }
                }
                // .rect(...) values are:
                // duration of the apperance, as percentage of total video
                // 1 (fit to full height of svg viewbox)
                // .x(...) value is the starting point of the appearance, as a percentage of the video duration
            }
            const enterHandler = (evt) => {
                const timeSegments = this.$refs.timeSegments;
                if (timeSegments) {
                    const { offsetX } = evt;
                    const { width } = timeSegments.getBoundingClientRect();
                    const hoverTime = (offsetX / width) * this.videoDuration - TIME_DELTA;
                    this.hoverTime = hoverTime + this.startPoint;
                }
            };
            // draw.mouseover(() => {
            //   console.log('I am mousing over this element')
            // })
            draw.on('mouseover', enterHandler);
            draw.on('touchstart', enterHandler);
            const exitHandler = () => {
                this.hoverTime = null;
            };
            draw.on('mouseout', exitHandler);
            draw.on('touchend', exitHandler);
            // COVERING OTHER NEEDS
            // 1. We can easily add listeners for hover events over apperance segments: https://svgjs.com/docs/3.0/events/#custom-events
            // 2. If showing appearance charts for multiple people, we can easily draw overlapping segments with additional, textured rectangles on top of each person's base appearance rectangles
            // 3. svg.js also includes some simple animation controls, so we can make this look nice
            // draw.addClass('time-segments__main--svg-item')
            // const elCSS = draw.css()
            // elCSS.display = 'none'
            this.draw = draw;
        },
        destroyTimeSegment() {
            if (this.draw) {
                this.draw.clear().remove();
            }
        },
        checkOverlap({ start, end }) {
            let isOverlapping = false;
            let overlapsSet = [];
            for (const o_inst of this.comparableInstances) {
                if ((start >= o_inst.start && start <= o_inst.end) ||
                    (o_inst.start >= start && o_inst.start <= end) ||
                    (start >= o_inst.start && end <= o_inst.end) ||
                    (o_inst.start >= start && o_inst.end <= end)) {
                    isOverlapping = true;
                }
                else {
                    isOverlapping = false;
                }
                if (isOverlapping) {
                    if (start >= o_inst.start && start <= o_inst.end) {
                        overlapsSet.push([start, o_inst.end]);
                    }
                    else if (o_inst.start >= start && o_inst.start <= end) {
                        overlapsSet.push([o_inst.start, end]);
                    }
                    else if (start >= o_inst.start && end <= o_inst.end) {
                        overlapsSet.push([start, end]);
                    }
                    else if (o_inst.start >= start && o_inst.end <= end) {
                        overlapsSet.push([o_inst.start, o_inst.end]);
                    }
                }
            }
            return overlapsSet;
        },
    },
    beforeDestroy() {
        this.destroyTimeSegment();
    },
    watch: {
        instances: {
            handler(instances) {
                // eslint-disable-next-line @typescript-eslint/no-floating-promises
                this.$sleep(0).then(() => {
                    if (instances?.length) {
                        this.destroyTimeSegment();
                        this.drawTimeSegments(instances);
                    }
                });
            },
            immediate: true,
        },
        comparableInstances: {
            handler() {
                // eslint-disable-next-line @typescript-eslint/no-floating-promises
                this.$sleep(0).then(() => {
                    this.destroyTimeSegment();
                    this.drawTimeSegments(this.instances);
                });
            },
            immediate: true,
        },
    },
    filters: {
        formatSeenDurationRatio(val) {
            return `${(val * 100).toFixed(2)}%`;
        },
    },
    components: {
        VideoThumbnail,
    },
});
