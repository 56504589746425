import Vue from 'vue';
export default Vue.extend({
    props: {
        step: {
            type: Number,
            required: true,
        },
        nextDisabled: {
            type: Boolean,
            default: false,
        },
        prevDisabled: {
            type: Boolean,
            default: false,
        },
    },
});
