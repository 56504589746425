import VideoUploadMixin from '@/utils/VideoUploadMixin';
import mixins from 'vue-typed-mixins';
export default mixins(VideoUploadMixin).extend({
    name: 'VideoSettingsReplaceVideoBtn',
    props: {
        video: Object,
    },
    data: () => ({
        dialog: false,
    }),
    methods: {
        uploadVideoReplacement(files) {
            const file = files.item(0);
            if (!file)
                return;
            try {
                this.uploadFile(file, 'replaceVideoInput', this.video);
            }
            catch (err) {
                alert(err?.message ?? err);
            }
        },
    },
});
