import Vue from 'vue';
import UserProfileEditEmail from './UserProfileEditEmail.vue';
import UserProfileTitle from './UserProfileTitle.vue';
import { nanoid } from 'nanoid';
import auth from '@/services/auth';
import MUTATIONS from '@/store/MUTATIONS';
import logger from '@lumiere/shared/services/logger';
export default Vue.extend({
    name: 'AccountUserProfile',
    props: {
        user: Object,
    },
    data() {
        return {
            loading: false,
            snackbar: false,
            snackbarMessage: '',
        };
    },
    methods: {
        async uploadFile(file) {
            if (file) {
                this.loading = true;
                const user = await auth.getCurrentUser();
                if (user) {
                    try {
                        const uploader = this.$uploadFileToStorage(file, `user/${this.user.id}/profile`, nanoid(6));
                        const photoURL = await uploader.getURL();
                        await user
                            .updateProfile({ photoURL })
                            .then(() => {
                            this.$db.user(this.user.id).update({ photoURL });
                            this.$store.commit(MUTATIONS.SET_USER, {
                                ...user,
                                photoURL,
                            });
                        })
                            .catch((e) => {
                            throw e;
                        });
                    }
                    catch (e) {
                        logger.error('Upload User Image', e);
                        this.snackbar = true;
                        this.snackbarMessage =
                            e.message || 'Upload Error: Please try again or use another image';
                    }
                }
                this.loading = false;
            }
        },
    },
    components: {
        UserProfileEditEmail,
        UserProfileTitle,
    },
});
