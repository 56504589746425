import { doc } from 'rxfire/firestore';
import { map } from 'rxjs/operators';
import { Role, TeamRole, } from '../types';
import paths from './helpers/paths';
import { dbCollection, getUID, mapDoc, signatures } from './helpers/utils';
import DB from './DB';
import { emoji, emojis } from './db.workspaces.emojis';
import mapValues from 'lodash/mapValues';
import pick from 'lodash/pick';
export const teamToWorkspaceRoleMap = {
    [TeamRole.ADMIN]: Role.OWNER,
    [TeamRole.OWNER]: Role.OWNER,
    [TeamRole.EDITOR]: Role.EDITOR,
    [TeamRole.REVIEWER]: Role.REVIEWER,
    [TeamRole.VIEWER]: Role.VIEWER,
};
export const workspace = (params) => (wid) => {
    const { firestore, dbAuth, FieldValue } = params;
    const workspaceRef = firestore.doc(paths.workspaces(wid));
    const { created, edited, deleted } = signatures(FieldValue);
    return {
        async create({ name, theme, description, }, team) {
            const uid = await getUID(dbAuth);
            const newWorkspace = {
                name,
                theme,
                description,
                tags: {},
                roles: {
                    [uid]: Role.OWNER,
                },
                ...created(uid),
            };
            if (team) {
                Object.assign(newWorkspace, {
                    roles: mapValues(team.roles, (teamRole) => teamToWorkspaceRoleMap[teamRole]),
                    team: pick(team, ['id', 'name', 'iconURL', 'roles']),
                });
            }
            await workspaceRef.set(newWorkspace);
        },
        async updateTeam(team, batch) {
            const uid = await getUID(dbAuth);
            const data = {
                team,
                ...edited(uid),
            };
            return batch
                ? batch.update(workspaceRef, data)
                : workspaceRef.update(data);
        },
        async update(update, batch) {
            const uid = await getUID(dbAuth);
            const data = {
                ...update,
                ...edited(uid),
            };
            batch ? batch.update(workspaceRef, data) : await workspaceRef.update(data);
        },
        async deleteTag(tagId) {
            const uid = await getUID(dbAuth);
            const batch = firestore.batch();
            // delete the tags from workspace videos
            await DB.getInstance()
                .videos((ref) => ref
                .where('workspaceId', '==', wid)
                .where('tags', 'array-contains', tagId))
                .update({
                tags: FieldValue.arrayRemove(tagId),
                ...edited(uid),
            }, batch);
            // delete the tag from containing channels
            const workspaceChannels = await DB.getInstance()
                .channels((ref) => ref.where('workspaceId', '==', wid))
                .get();
            const channelsWithTheTag = workspaceChannels.filter(({ channelTags }) => Object.keys(channelTags).includes(tagId));
            for (let channel of channelsWithTheTag) {
                const ref = firestore.doc(paths.channels(channel.id));
                batch.update(ref, {
                    [`channelTags.${tagId}`]: FieldValue.delete(),
                    ...edited(uid),
                });
            }
            // delete tag from the workspace
            await this.update({
                [`tags.${tagId}`]: FieldValue.delete(),
            }, batch);
            await batch.commit();
        },
        observe: () => doc(workspaceRef).pipe(map((v) => mapDoc(v))),
        get: async () => mapDoc(await workspaceRef.get()),
        async delete(outerBatch) {
            const batch = outerBatch || firestore.batch();
            const db = DB.getInstance();
            // delete all emojis subcollections too
            await this.emojis().delete(batch);
            await db
                .videos((ref) => ref.where('workspaceId', '==', wid))
                .delete(batch);
            await db
                .channels((ref) => ref.where('workspaceId', '==', wid))
                .delete(batch);
            batch.delete(workspaceRef);
            if (!outerBatch) {
                await batch.commit();
            }
        },
        async softDelete(outerBatch) {
            const uid = await getUID(dbAuth);
            const deleteUpdate = { ...deleted(uid) };
            return outerBatch
                ? outerBatch.update(workspaceRef, deleteUpdate)
                : workspaceRef.update(deleteUpdate);
        },
        async restore(outerBatch) {
            const uid = await getUID(dbAuth);
            const restoreUpdate = {
                ...edited(uid),
                deleted: FieldValue.delete(),
                deletedBy: FieldValue.delete(),
                deletedTime: FieldValue.delete(),
            };
            return outerBatch
                ? outerBatch.update(workspaceRef, restoreUpdate)
                : workspaceRef.update(restoreUpdate);
        },
        emojis: emojis(wid)(params),
        emoji: emoji(wid)(params),
    };
};
export const workspaces = dbCollection(paths.workspaces());
