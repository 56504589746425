import Vue from 'vue';
import { CommentPlusFeature } from '@lumiere/db/types';
import PlusFeatureEmojis from './PlusFeatureEmojis.vue';
import PlusFeatureRatings from './PlusFeatureRatings.vue';
import PlusFeatureStars from './PlusFeatureStars.vue';
import PlusFeatureTags from './PlusFeatureTags.vue';
const typeToComponentMap = {
    [CommentPlusFeature.Emojis]: PlusFeatureEmojis,
    [CommentPlusFeature.Ratings]: PlusFeatureRatings,
    [CommentPlusFeature.Stars]: PlusFeatureStars,
    [CommentPlusFeature.Tags]: PlusFeatureTags,
};
export default Vue.extend({
    data() {
        return {
            plus: this.$props.value,
        };
    },
    props: {
        options: {
            type: Object,
            required: true,
        },
        value: Object,
    },
    watch: {
        plus(plus) {
            this.$emit('input', plus);
        },
        value(v) {
            if (v === null) {
                this.plus = null;
            }
        },
    },
    computed: {
        componentName() {
            return (type) => typeToComponentMap[type];
        },
    },
    methods: {
        highlightInvalid() {
            const componentRef = this.$refs.component;
            if (componentRef && componentRef.highlightInvalid) {
                componentRef.highlightInvalid();
            }
        },
    },
    components: {
        PlusFeatureEmojis,
        PlusFeatureRatings,
        PlusFeatureStars,
        PlusFeatureTags,
    },
});
