import Vue from 'vue';
export default Vue.extend({
    name: 'EditableLabel',
    props: {
        label: {
            type: String,
            required: true,
        },
        isEditable: {
            type: Boolean,
            default: true,
        },
    },
    data() {
        return {
            editing: false,
        };
    },
    computed: {
        inputRef() {
            return this.$refs.input;
        },
    },
    watch: {},
    methods: {
        updateLabel(label) {
            if (label !== this.label) {
                this.$emit('update-label', label);
            }
            this.editing = false;
        },
        editTitle() {
            this.editing = true;
            // this.$nextTick(() => {
            //   this.inputRef.focus()
            // })
        },
    },
});
