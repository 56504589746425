import Vue from 'vue';
import { mapGetters } from 'vuex';
import FuzzySearch from '@lumiere/shared/services/FuzzySearch';
import { Routes } from '@/router/router';
import sortBy from 'lodash/sortBy';
const SEARCH_KEYS = ['name', 'description', 'team.name'];
const searchFuse = FuzzySearch([], { keys: SEARCH_KEYS });
export default Vue.extend({
    name: 'WorkspaceListItems',
    props: {
        workspaces: Array,
        team: Object,
        searchQuery: String,
    },
    filters: {
        lowercase(text) {
            return text.toLowerCase();
        },
    },
    computed: {
        ...mapGetters(['currentWorkspace']),
        filteredWorkspaces() {
            const team = this.team;
            const search = this.searchQuery;
            const workspaces = this.workspaces;
            const workspacesFilteredByTeam = team
                ? team.id === 'personal'
                    ? workspaces.filter((w) => !w.team)
                    : workspaces.filter((w) => w.team?.id === team.id)
                : workspaces;
            let response = [...workspacesFilteredByTeam];
            if (search) {
                searchFuse.setCollection(response);
                response = searchFuse.search(search).map(({ item }) => item);
            }
            return sortBy(response, ['name']);
        },
    },
    methods: {
        goToWorkspace(workspaceId) {
            if (workspaceId) {
                this.$emit('selected-workspace', workspaceId);
                if (this.currentWorkspace && workspaceId !== this.currentWorkspace.id) {
                    this.$router.push({
                        name: Routes.Videos,
                        params: {
                            workspaceId,
                        },
                    });
                    this.$sleep(0).then(() => {
                        this.$emit('parent-close');
                    });
                }
            }
        },
    },
    beforeRouteLeave(_to, _from, next) {
        next();
        this.$emit('parent-close');
    },
});
