import Vue from 'vue';
import Layout from '@/components/Layout.vue';
import { nanoid } from 'nanoid';
import AddTeamMemberForm from '@/components/team/members/AddTeamMemberForm.vue';
import TeamMembersList from '@/components/team/TeamMembersList.vue';
import CreateWorkspaceForm from '@/components/CreateWorkspaceForm.vue';
import { pluck, switchMap } from 'rxjs/operators';
import { of } from 'rxjs';
import { mapGetters, mapState } from 'vuex';
import { TeamRole } from '@lumiere/db/types';
import { Routes } from '@/router/router';
import MUTATIONS from '@/store/MUTATIONS';
import WorkspaceListItems from '@/components/workspace/WorkspaceListItems.vue';
import logger from '@lumiere/shared/services/logger';
export default Vue.extend({
    name: 'Team',
    props: {
        teamId: {
            type: String,
            required: true,
        },
    },
    data() {
        return {
            loading: false,
            addTeamMemberDialog: false,
            createWorkspaceDialog: false,
            team: null,
            unwatchTeam: null,
        };
    },
    created() {
        this.watchTeamHydration();
    },
    computed: {
        ...mapState(['user', 'uid']),
        ...mapGetters(['workspacesList']),
        userIsTeamAdmin() {
            return ((this.team && this.team.roles[this.user.id] === TeamRole.ADMIN) ?? false);
        },
        isPersonalTeam() {
            return this.team?.id === 'personal';
        },
        personalTeam() {
            return {
                id: 'personal',
                name: this.user.displayName,
                iconURL: this.user.photoURL,
                roles: {},
            };
        },
        teamWorkspaces() {
            return this.teamId
                ? this.workspacesList?.filter(({ team }) => this.teamId === 'personal' ? !team : team?.id === this.teamId) ?? null
                : this.workspacesList;
        },
        fileInput() {
            return this.$refs.fileInput;
        },
    },
    watch: {
        team: {
            handler(team) {
                this.$store.commit(MUTATIONS.SET_TEAM, team);
            },
        },
    },
    methods: {
        deleteTeam() {
            void this.$confirm('Confirm Team deletion? All associated workspaces will be disconnected', {
                buttonTrueText: 'Delete Team',
                buttonFalseText: 'Cancel',
            }).then(async (confirm) => {
                if (confirm) {
                    await this.$db
                        .team(this.teamId)
                        .softDelete()
                        .then(async () => {
                        const team = this.team;
                        if (team) {
                            const ctx = await this.$auditLogger(this.$store.state.uid);
                            if (ctx) {
                                const { Asset_Model, EVENT_ACTION, ts } = ctx.config;
                                const asset = {
                                    id: team.id,
                                    type: Asset_Model.Team,
                                    name: team.name,
                                };
                                await ctx.exec(asset, {
                                    timestamp: ts(),
                                    action: EVENT_ACTION.SOFT_DELETED,
                                    data: {
                                        type: 'INFO',
                                        payload: {
                                            message: `Team has been soft deleted`,
                                        },
                                    },
                                });
                            }
                        }
                    });
                    void this.$router.replace({ name: Routes.Landing });
                }
            });
        },
        async uploadFile(file) {
            if (file && this.team) {
                this.loading = true;
                try {
                    const uploader = this.$uploadFileToStorage(file, `teams/${this.team.id}`, nanoid(6));
                    const iconURL = await uploader.getURL();
                    await this.$db.team(this.team.id).update({ iconURL });
                }
                catch (e) {
                    logger.error('uploadFile', e);
                }
                this.loading = false;
                if (this.fileInput) {
                    this.fileInput.value = '';
                }
            }
        },
        async updateTeamName(target) {
            const team = this.team;
            if (team) {
                if (target.innerText && target.innerText !== team.name) {
                    this.loading = true;
                    await this.$db
                        .team(team.id)
                        .update({
                        name: target.innerText,
                    })
                        .then(() => this.$auditLogger(this.$store.state.uid).then((ctx) => {
                        if (ctx) {
                            const { Asset_Model, EVENT_ACTION, ts } = ctx.config;
                            const asset = {
                                id: team.id,
                                type: Asset_Model.Team,
                                name: target.innerText,
                            };
                            return ctx.exec(asset, {
                                timestamp: ts(),
                                action: EVENT_ACTION.UPDATED,
                            });
                        }
                    }));
                    this.loading = false;
                }
                else {
                    target.innerText = team.name;
                }
            }
        },
        goToWorkspace(wid) {
            void this.$router.push({
                name: Routes.Videos,
                params: { workspaceId: wid },
            });
        },
        watchTeamHydration() {
            this.unwatchTeam = this.$watch('team', async (team) => {
                if (team) {
                    if (this.unwatchTeam) {
                        this.unwatchTeam();
                    }
                    const ctx = await this.$auditLogger(this.$store.state.uid);
                    if (ctx) {
                        const { Asset_Model, EVENT_ACTION, ts } = ctx.config;
                        const asset = {
                            id: this.teamId,
                            type: Asset_Model.Team,
                            name: team.name,
                        };
                        await ctx.exec(asset, {
                            timestamp: ts(),
                            action: EVENT_ACTION.VIEWED,
                            data: {
                                type: 'INFO',
                                payload: {
                                    message: `Team management page accessed/viewed`,
                                },
                            },
                        });
                    }
                }
            }, {
                immediate: true,
            });
        },
    },
    subscriptions() {
        const teamId$ = this.$watchAsObservable('teamId', { immediate: true }).pipe(pluck('newValue'));
        const team$ = teamId$.pipe(switchMap((teamId) => {
            return teamId === 'personal'
                ? of(this.personalTeam)
                : this.$db.team(this.$props.teamId).observe();
        }));
        return {
            team: team$,
        };
    },
    components: {
        Layout,
        AddTeamMemberForm,
        TeamMembersList,
        CreateWorkspaceForm,
        WorkspaceListItems,
    },
});
