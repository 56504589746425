export enum ACTIONS {
  UPDATE_SIGN_IN_TIME = 'UPDATE_SIGN_IN_TIME',
  SAVE_STATE = 'SAVE_STATE',
  CLEAR_SAVED_STATE = 'CLEAR_SAVED_STATE',
  CHECK_IF_USER_IS_ADMIN = 'CHECK_IF_USER_IS_ADMIN',
  SUBSCRIBE_TO_AUTH_STATE_CHANGE = 'SUBSCRIBE_TO_AUTH_STATE_CHANGE',
  SUBSCRIBE_TO_USER_WORKSPACES = 'SUBSCRIBE_TO_USER_WORKSPACES',
  UNSUBSCRIBE_FROM_USER_WORKSPACES = 'SUBSCRIBE_TO_USER_WORKSPACES',
  UPLOAD_VIDEO_FILE = 'UPLOAD_VIDEO_FILE',
  CANCEL_FILE_UPLOAD = 'CANCEL_FILE_UPLOAD',
  REFRESH_AUTH_COOKIE = 'REFRESH_AUTH_COOKIE',
  SIGN_OUT = 'SIGN_OUT',
  CLEAR_AUTH_COOKIE = 'CLEAR_AUTH_COOKIE',
  GET_TOTAL_EMOJI_CLICKS = 'GET_TOTAL_EMOJI_CLICKS',
  GET_FLY_OUT_EMOJIS = 'GET_FLY_OUT_EMOJIS',
  SET_CUSTOM_CLAIMS = 'SET_CUSTOM_CLAIMS',
  DELETE_VIDEO_ONERROR = 'DELETE_VIDEO_ONERROR',
  BATCH_UPLOAD_VIDEO_FILES = 'BATCH_UPLOAD_VIDEO_FILES',
  GET_HEATMAP_DATA = 'GET_HEATMAP_DATA',
  GET_SHARE_LINK_WORKSPACE = 'GET_SHARE_LINK_WORKSPACE',
  SET_LINK = 'SET_LINK',
  ADD_RECENT_WORKSPACE = 'ADD_RECENT_WORKSPACE',
  UPDATE_USER_RECORD = 'UPDATE_USER_RECORD',
  DELETE_RECENT_WORKSPACE = 'DELETE_RECENT_WORKSPACE',
  CLEANUP_RECENT_WORKSPACES = 'CLEANUP_RECENT_WORKSPACES',
  SUBSCRIBE_TO_USER_DOC_CHANGES = 'SUBSCRIBE_TO_USER_DOC_CHANGES',
}

export default ACTIONS
