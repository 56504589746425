import Vue from 'vue';
import VideoPlayerAdminTimePicker from '../player/VideoPlayerAdminTimePicker.vue';
import VideoRangeSelectionPreview from '@/components/common/VideoRangeSelectionPreview.vue';
import TimePickerComponent from '@/components/common/TimePickerComponent.vue';
import VideoSceneWizard from './VideoSceneWizard.vue';
export default Vue.extend({
    name: 'VideoSceneEditWizard',
    props: {
        video: Object,
        scene: Object,
        value: Boolean,
    },
    data() {
        const initialRange = [
            this.$props.scene.startTime,
            this.$props.scene.stopTime,
        ];
        return {
            dialog: false,
            updating: false,
            rangeMin: this.$props.scene.startTime,
            rangeMax: this.$props.scene.stopTime,
            sceneTitle: this.$props.scene.name,
            selectedRange: initialRange,
            rangeMinSafe: this.$props.scene.startTime,
            rangeMaxSafe: this.$props.scene.stopTime,
            rangeMinPicker: this.$props.scene.startTime,
            rangeMaxPicker: this.$props.scene.stopTime,
        };
    },
    computed: {
        rangeFromScrubber() {
            return `${this.rangeMin}:${this.rangeMax}`;
        },
        rangeFromPickers() {
            return `${this.rangeMinPicker}:${this.rangeMaxPicker}`;
        },
    },
    methods: {
        onSelectRange(range) {
            const { min, max } = range;
            this.rangeMin = +min;
            this.rangeMax = +max;
        },
        async updateScene() {
            if (!this.sceneTitle) {
                // show title hint
                return;
            }
            const id = this.scene.id;
            this.updating = true;
            const payload = {
                name: this.sceneTitle,
                startTime: this.rangeMin,
                stopTime: this.rangeMax,
                nonce: this.scene.nonce || Date.now(),
            };
            await this.$db
                .video(this.video.id)
                .update({ [`scenes.${id}`]: payload })
                .then(async () => {
                const ctx = await this.$auditLogger(this.$store.state.uid);
                if (ctx) {
                    const { Asset_Model, EVENT_ACTION, ts } = ctx.config;
                    const asset = {
                        id: this.video.id,
                        name: this.video.title,
                        type: Asset_Model.Video,
                    };
                    await ctx.exec(asset, {
                        timestamp: ts(),
                        action: EVENT_ACTION.UPDATED,
                        data: {
                            type: 'INFO',
                            payload: {
                                message: `Video attributes changed: update scene`,
                                extraInfo: {
                                    scene: {
                                        id,
                                        ...payload,
                                    },
                                },
                            },
                        },
                    });
                }
            });
            this.updating = false;
            this.dialog = false;
            this.$emit('input', false);
        },
    },
    watch: {
        value: {
            handler(val) {
                this.dialog = val;
            },
            immediate: true,
        },
        dialog(val) {
            if (val === false) {
                this.$emit('input');
            }
        },
        rangeFromScrubber: {
            handler(newVal, oldVal) {
                if (newVal != oldVal) {
                    const delta = this.rangeMax - this.rangeMin;
                    if (delta > 0) {
                        this.rangeMinSafe = this.rangeMin;
                        this.rangeMaxSafe = this.rangeMax;
                        this.rangeMinPicker = this.rangeMin;
                        this.rangeMaxPicker = this.rangeMax;
                    }
                }
            },
            immediate: true,
        },
        rangeFromPickers: {
            handler(newVal, oldVal) {
                if (newVal != oldVal) {
                    const delta = this.rangeMaxPicker - this.rangeMinPicker;
                    if (delta > 0) {
                        this.rangeMinSafe = this.rangeMinPicker;
                        this.rangeMaxSafe = this.rangeMaxPicker;
                        this.rangeMin = this.rangeMinPicker;
                        this.rangeMax = this.rangeMaxPicker;
                    }
                }
            },
            immediate: true,
        },
    },
    components: {
        VideoPlayerAdminTimePicker,
        VideoRangeSelectionPreview,
        TimePickerComponent,
        VideoSceneWizard,
    },
});
