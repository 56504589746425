/**
 * BROWSER TAB VISIBILITY CHANGE:
 * to handle browser tab visibility change (lose/gain focus event)
 *
 * 1. inspiration from https://levelup.gitconnected.com/detecting-the-focus-of-browser-tab-in-javascript-d4460bc579ac
 * 2. code: https://codepen.io/matt-west/pen/hGFLs?editors=1010
 */
export function onVisibilityChange(cb) {
    const prefix = getPrefix();
    let hidden = 'hidden';
    let visibilityChangeEvent = 'visibilitychange';
    if (prefix) {
        hidden = prefix + 'Hidden';
        visibilityChangeEvent = prefix + 'visibilitychange';
    }
    function handler(_e) {
        //@ts-ignore Accessing hidden property on the DOM
        cb(document[hidden]);
    }
    document.addEventListener(visibilityChangeEvent, handler);
    return () => document.removeEventListener(visibilityChangeEvent, handler);
}
// Check to see if the browser supports the unprefixed property.
function getPrefix() {
    if ('hidden' in document)
        return null; // No prefix needed, return null.
    let prefixes = ['moz', 'ms', 'o', 'webkit']; // possible prefixes
    for (let i = 0; i < prefixes.length; i++) {
        let testPrefix = prefixes[i] + 'Hidden';
        if (testPrefix in document) {
            return prefixes[i];
        }
    }
    // The API must not be supported in this browser.
    return null;
}
export default onVisibilityChange;
