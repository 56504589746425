import Vue from 'vue';
import VideoDataManagementEditDataFilter from './VideoDataManagementEditDataFilter.vue';
import VideoDataManagementDataExport from './VideoDataManagementDataExport.vue';
import VideoDataManagementViewerResponseLanguage from './VideoDataManagementViewerResponseLanguage.vue';
export default Vue.extend({
    name: 'VideoDataManagement',
    props: {
        video: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {};
    },
    computed: {},
    watch: {},
    methods: {},
    subscriptions() {
        return {};
    },
    components: {
        VideoDataManagementViewerResponseLanguage,
        VideoDataManagementEditDataFilter,
        VideoDataManagementDataExport,
    },
});
