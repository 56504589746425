import Vue from 'vue';
import PlayerColor from './PlayerColor.vue';
import VideoBranding from './VideoBranding.vue';
import { FieldValue } from '@/services/firebase/firestore';
import VideoDetailsPoster from '../details/VideoDetailsPoster.vue';
import CustomCSS from '@/components/common/CustomCSS.vue';
export default Vue.extend({
    name: 'VideoAppearance',
    props: {
        video: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            loading: false,
        };
    },
    computed: {
        appearanceColor() {
            return this.video.appearance?.color;
        },
        appearanceLogo() {
            return this.video.appearance?.logo;
        },
        appearanceCSS() {
            return this.video.appearance?.css;
        },
    },
    watch: {},
    methods: {
        async saveAppearance(appearance) {
            const keys = Object.keys(appearance);
            const updateObject = keys.reduce((v, key) => {
                return {
                    ...v,
                    [`appearance.${key}`]: appearance[key] || FieldValue.delete(),
                };
            }, {});
            this.loading = true;
            await this.$db
                .video(this.video.id)
                .update(updateObject)
                .then(async () => {
                const ctx = await this.$auditLogger(this.$store.state.uid);
                if (ctx) {
                    const { Asset_Model, EVENT_ACTION, ts } = ctx.config;
                    const asset = {
                        id: this.video.id,
                        name: this.video.title,
                        type: Asset_Model.Video,
                    };
                    await ctx.exec(asset, {
                        timestamp: ts(),
                        action: EVENT_ACTION.UPDATED,
                        data: {
                            type: 'INFO',
                            payload: {
                                message: 'Video attributes changed',
                                extraInfo: {
                                    ...updateObject,
                                },
                            },
                        },
                    });
                }
            });
            this.loading = false;
        },
    },
    subscriptions() {
        return {};
    },
    components: {
        PlayerColor,
        VideoBranding,
        VideoDetailsPoster,
        CustomCSS,
    },
});
