import { Emotions as BaseEmotions, } from '@lumiere/shared/types';
// N.B: the order matters
export var Emotions;
(function (Emotions) {
    Emotions[Emotions["POSITIVE"] = BaseEmotions.POSITIVE] = "POSITIVE";
    Emotions[Emotions["NEGATIVE"] = BaseEmotions.NEGATIVE] = "NEGATIVE";
    Emotions[Emotions["NEUTRAL"] = BaseEmotions.NEUTRAL] = "NEUTRAL";
    Emotions[Emotions["INDETERMINATE"] = BaseEmotions.INDETERMINATE] = "INDETERMINATE";
})(Emotions || (Emotions = {}));
export var FilterActions;
(function (FilterActions) {
    FilterActions["WORDCLICK"] = "WORDCLICK";
    FilterActions["SEARCH"] = "SEARCH";
    FilterActions["CLEAR"] = "CLEAR";
    FilterActions["ZOOM"] = "ZOOM";
})(FilterActions || (FilterActions = {}));
export var MouseEvent;
(function (MouseEvent) {
    MouseEvent["MOUSEOVER"] = "MOUSEOVER";
    MouseEvent["MOUSELEAVE"] = "MOUSELEAVE";
})(MouseEvent || (MouseEvent = {}));
export var CommentTimeProp;
(function (CommentTimeProp) {
    CommentTimeProp["videoTime"] = "videoTime";
    CommentTimeProp["currentTime"] = "currentTime";
})(CommentTimeProp || (CommentTimeProp = {}));
