import Vue from 'vue';
import { Emotions, FilterActions, MouseEvent, } from '../types';
import { 
// smoothSeries,
colors, clientDynamicBucketing, hasNumberEl, } from '../helper';
import { designColors } from '@/utils/helpers';
import isEmpty from 'lodash/isEmpty';
import debounce from 'lodash/debounce';
import isEqual from 'lodash/isEqual';
import * as am4core from '@amcharts/amcharts4/core';
import * as am4charts from '@amcharts/amcharts4/charts';
import am4themes_animated from '@amcharts/amcharts4/themes/animated';
import { sleep } from '@/utils/sleep';
import { getHexColor } from '@/colors';
import chroma from 'chroma-js';
import { formatTime } from '@lumiere/shared/utils/numberFormatter';
import VideoInsightsTrendsBucketPicker from '../VideoInsightsTrendsBucketPicker.vue';
import maxBy from 'lodash/maxBy';
import VideoInsightCommenDataFilter from '../VideoInsightCommenDataFilter.vue';
am4core.useTheme(am4themes_animated);
export default Vue.extend({
    name: 'VideoInsightExternalDataTrend',
    props: {
        loading: Boolean,
        emotionColor: {
            type: Function,
        },
        feature: {
            type: Object,
            required: true,
        },
        video: {
            type: Object,
            required: true,
        },
        totalInteractions: {
            type: Number,
        },
        comment_count: {
            type: Object,
        },
        filterTerm: String,
        filterAction: String,
        commentCountMouseAction: Object,
        commentHasRatings: Boolean,
        filtering: Boolean,
        ratingsMinMax: {
            type: Object,
        },
        emotions: {
            type: Array,
        },
        filterWasChanged: Boolean,
    },
    data() {
        return {
            calculatedMinMax: null,
            comment: '',
            commentsAmChart: null,
            commentsAmChartSeries: [],
            highlitIndex: -1,
            xAxis: null,
            range: null,
            allCharts: [],
            chartsContainer: null,
            commentDataSeries: [],
            commentDataFields: [],
            cursorShowDisposers: [],
            commentRatingsDataSeries: [],
            scrollbarX: null,
            ratingsRange: null,
            ratingsAmChart: null,
            shownCursorChangeDisposer: null,
            shownCursorZoomStartedDisposer: null,
            shownCursorZoomEndedDisposer: null,
            globalScrollbarDataSeries: [],
            scrollbarAmChart: null,
            queryParamMinMax: null,
            resetZoomPress: false,
            isResetChartData: false,
            customBucketSize: null,
            switchingFeature: false,
            externalFilterTriggeredZoom: false,
            ratingsAmChartWasCreated: false,
            ratingsDateAxis: null,
            commentsAmChartWasCreated: false,
            commentsDateAxis: null,
            externalFilterMinMax: null,
            baseInterval: 0,
            sceneObjectsComments: [],
            sceneObjectsRatings: [],
        };
    },
    computed: {
        triggerTime() {
            const { triggers = [], stoppers = [] } = this.feature;
            const videoDuration = this.video.duration;
            let startTime = videoDuration;
            let stopTime = 0;
            triggers.forEach((t) => {
                if ((t.parameters?.time ?? 0) < startTime) {
                    startTime = t.parameters?.time ?? 0;
                }
            });
            stoppers.forEach((t) => {
                if ((t.parameters?.time ?? videoDuration) > stopTime) {
                    stopTime = t.parameters?.time ?? videoDuration;
                }
            });
            return {
                start: startTime,
                stop: stopTime,
            };
        },
        formattedTriggerTime() {
            const time = this.calculatedMinMax;
            return {
                startTime: formatTime(time.start),
                stopTime: formatTime(time.stop),
            };
        },
        tickFormat() {
            return this.video.duration > 3600 ? 'H:mm:ss' : 'm:ss';
        },
        unixMidnight() {
            const d = new Date();
            d.setHours(0, 0, 0, 0);
            return d.getTime();
        },
        minMax() {
            const { start: min, stop: max } = this.calculatedMinMax;
            return { min, max };
        },
        isZoomed() {
            return this.filterAction === FilterActions.ZOOM;
        },
        dateAxisFormatSecond() {
            return this.video.duration > 3600 ? 'H:mm:ss' : 'mm:ss';
        },
        dateAxisFormatMillis() {
            return this.dateAxisFormatSecond;
        },
        tooltipFormat() {
            return this.dateAxisFormatSecond;
        },
        videoTime() {
            return this.$store.state.videoInfo.time;
        },
        workspaceColor() {
            return am4core.color(getHexColor(this.$store.getters.currentWorkspace.theme.color));
        },
        showSkeleton() {
            return this.totalInteractions == null || this.loading;
        },
        triggerDuration() {
            return this.triggerTime.stop - this.triggerTime.start;
        },
        featureHasRatingsEnabled() {
            return !!this.feature.options.rating;
        },
        colors() {
            if (this.video.scenes) {
                const numColors = Object.keys(this.video.scenes).length;
                const dColors = designColors;
                if (numColors > dColors.length) {
                    return chroma.scale(dColors).mode('lch').colors(numColors);
                }
                return dColors;
            }
            else {
                return [];
            }
        },
        darkenedColors() {
            return this.colors.map((color) => chroma(color).darken().css());
        },
        compositeRatingsAmChartsAndVideoScenes() {
            return {
                breakpoint: this.$vuetify.breakpoint.xsOnly,
                created: this.ratingsAmChartWasCreated,
                scenes: this.video.scenes,
                baseInterval: this.baseInterval,
            };
        },
        compositeCommentsAmChartsAndVideoScenes() {
            return {
                breakpoint: this.$vuetify.breakpoint.xsOnly,
                created: this.commentsAmChartWasCreated,
                scenes: this.video.scenes,
                baseInterval: this.baseInterval,
            };
        },
        sceneLabelMaxWidth() {
            let smWidth = 95;
            let bgWidth = 230;
            if (this.$vuetify.breakpoint.xsOnly) {
                bgWidth = 100;
                smWidth = 40;
            }
            return this.commentHasRatings ? smWidth : bgWidth;
        },
    },
    methods: {
        onChartZoomed: debounce(function (_chartContext, { xaxis }) {
            if (this.isResetChartData)
                return;
            const { min: chartMin, max: chartMax } = xaxis;
            const now = this.unixMidnight;
            if (!this.resetZoomPress && (chartMin || chartMax)) {
                const { start: trigger_start, stop: trigger_stop } = this.triggerTime;
                const min = chartMin ?? now + trigger_start * 1000;
                const max = chartMax ?? now + trigger_stop * 1000;
                let start = Math.floor((min - now) / 1000);
                let stop = Math.ceil((max - now) / 1000);
                if (!this.externalFilterTriggeredZoom) {
                    const interval = this.customBucketSize ||
                        clientDynamicBucketing((max - min) / 1000);
                    if (start - interval >= trigger_start) {
                        start -= interval;
                    }
                    else {
                        start = trigger_start;
                    }
                    if (stop + interval <= trigger_stop) {
                        stop += interval;
                    }
                    else {
                        stop = trigger_stop;
                    }
                    this.calculatedMinMax = {
                        start: +start.toFixed(2),
                        stop: +stop.toFixed(2),
                    };
                }
                else {
                    this.calculatedMinMax = {
                        start: this.externalFilterMinMax.min,
                        stop: this.externalFilterMinMax.max,
                    };
                }
            }
            else {
                this.calculatedMinMax = this.triggerTime;
            }
            this.externalFilterTriggeredZoom = false;
            this.externalFilterMinMax = null;
            this.xAxis = this.calculatedMinMax;
            this.$emit('chart-zoomed', {
                min: this.calculatedMinMax.start,
                max: this.calculatedMinMax.stop,
            });
        }, 500, {
            leading: false,
            trailing: true,
        }),
        buildTrendData(bucket) {
            const data = {};
            const { min, max } = this.minMax;
            const interval = this.customBucketSize || clientDynamicBucketing(max - min);
            const now = this.unixMidnight;
            let per_sentiment = {};
            const floorMin = Math.floor(min);
            const ceilMax = Math.ceil(max);
            let timeAsNum;
            for (let time in bucket) {
                timeAsNum = +time;
                if (timeAsNum == interval ||
                    (timeAsNum >= floorMin && timeAsNum <= ceilMax)) {
                    if (!data['ALL']) {
                        data['ALL'] = {};
                    }
                    data['ALL'][timeAsNum] = {
                        x: now + timeAsNum * 1000,
                        y: bucket[time].doc_count,
                    };
                    per_sentiment = bucket[time].per_sentiment?.buckets ?? {};
                    for (let emotion in per_sentiment) {
                        if (!data[emotion]) {
                            data[emotion] = {};
                        }
                        data[emotion][timeAsNum] = {
                            x: now + timeAsNum * 1000,
                            y: per_sentiment[emotion].doc_count,
                        };
                    }
                }
            }
            const series = [];
            let dataPoint;
            for (let time in data['ALL']) {
                dataPoint = {
                    date: Math.floor(data['ALL'][+time].x),
                };
                for (let emotion in data) {
                    dataPoint[emotion.toLowerCase()] = data[emotion][+time].y;
                }
                series.push(dataPoint);
            }
            const dataFields = [];
            for (let emotion in Emotions) {
                dataFields.push({
                    field: emotion.toLowerCase(),
                    name: emotion.toLowerCase(),
                });
            }
            dataFields.unshift({
                field: 'all',
                name: 'all',
            });
            this.globalScrollbarDataSeries = series.map((el) => {
                return { date: el.date, value: el['all'] };
            });
            this.commentDataSeries = series;
            this.commentDataFields = dataFields;
            if (!series.length) {
                this.resetSeries();
            }
        },
        buildRatingsTrendData(bucket) {
            let dataPoint;
            const series = [];
            const { min, max } = this.minMax;
            const interval = this.customBucketSize || clientDynamicBucketing(max - min);
            const now = this.unixMidnight;
            const floorMin = Math.floor(min);
            const ceilMax = Math.ceil(max);
            let timeAsNum;
            for (let time in bucket) {
                timeAsNum = +time;
                if (timeAsNum == interval ||
                    (timeAsNum >= floorMin && timeAsNum <= ceilMax)) {
                    dataPoint = {
                        date: now + timeAsNum * 1000,
                        value: +(bucket[time].average_rating_per_sec.value ?? 0).toFixed(1),
                    };
                    series.push(dataPoint);
                }
            }
            this.commentRatingsDataSeries = series;
            if (!series.length) {
                this.resetSeries();
            }
        },
        resetSeries: debounce(function () {
            this.onChartZoomed(null, { xaxis: {}, yaxis: {} });
        }, 500),
        refreshChartData() {
            this.isResetChartData = true;
            if (this.commentHasRatings && this.ratingsAmChart) {
                const chart0 = this.ratingsAmChart;
                chart0.data = this.commentRatingsDataSeries;
                chart0.series.each(function (series) {
                    series.appear();
                });
                const dateAxis0 = chart0.cursor.xAxis;
                if (dateAxis0) {
                    // @ts-ignore
                    dateAxis0.keepSelection = true;
                }
                const valueAxis0 = chart0.yAxes.getIndex(0);
                if (valueAxis0) {
                    const max = maxBy(chart0.data, 'value');
                    // @ts-ignore
                    valueAxis0.max = max.value; // + 0.5
                }
            }
            if (this.commentsAmChart) {
                const chart1 = this.commentsAmChart;
                chart1.data = this.commentDataSeries;
                chart1.series.each(function (series) {
                    series.appear();
                });
                const dateAxis1 = chart1.cursor.xAxis;
                if (dateAxis1) {
                    // @ts-ignore
                    dateAxis1.keepSelection = true;
                }
                const valueAxis1 = chart1.yAxes.getIndex(0);
                if (valueAxis1) {
                    const max = maxBy(chart1.data, 'all');
                    // @ts-ignore
                    valueAxis1.max = max.all + 0.5;
                }
            }
        },
        async createAmChartContainer() {
            this.isResetChartData = false;
            if (this.chartsContainer) {
                this.refreshChartData();
                return;
            }
            const container = am4core.create(this.$refs.chartContainer, am4core.Container);
            this.chartsContainer = container;
            container.width = am4core.percent(100);
            container.height = am4core.percent(100);
            container.layout = 'vertical';
            container.events.on('up', (_evt) => {
                for (let i = 0; i < allCharts.length; i++) {
                    const cursor = allCharts[i].cursor;
                    cursor.selection.hide(0);
                }
            });
            container.events.on('down', () => {
                this.isResetChartData = false;
            });
            const allCharts = [];
            if (this.commentHasRatings) {
                // create all-ratings chart for scrollbarX
                this.scrollbarAmChart = container.createChild(am4charts.XYChart);
                this.scrollbarAmChart.height = am4core.percent(15);
                this.scrollbarAmChart.plotContainer.visible = false;
                this.scrollbarAmChart.leftAxesContainer.visible = false;
                this.scrollbarAmChart.rightAxesContainer.visible = false;
                this.scrollbarAmChart.bottomAxesContainer.visible = false;
                this.scrollbarAmChart.zoomOutButton.parent = this.scrollbarAmChart.tooltipContainer;
                allCharts.push(this.scrollbarAmChart);
                // create ratings chart
                this.ratingsAmChart = container.createChild(am4charts.XYChart);
                this.ratingsAmChart.height = am4core.percent(60);
                allCharts.push(this.ratingsAmChart);
            }
            // create comment chart
            this.commentsAmChart = container.createChild(am4charts.XYChart);
            allCharts.push(this.commentsAmChart);
            this.allCharts = allCharts;
            await this.$nextTick();
            this.scrollbarX = new am4core.Scrollbar();
            // this.scrollbarX.minHeight = 55
            const firstChart = allCharts[0];
            firstChart.scrollbarX = this.scrollbarX;
            if (this.commentHasRatings) {
                this.createGlobalScrollbarX();
                this.createRatingsAmChart();
            }
            this.createCommentsAmChart();
            await sleep(0);
            firstChart.zoomOutButton.disabled = false;
            this.initCursorListeners();
            // enable date axis labels for the last one
            const lastChart = this.commentsAmChart;
            const lastDateAxis = lastChart.xAxes.getIndex(0);
            if (lastDateAxis) {
                lastDateAxis.renderer.labels.template.disabled = false;
                lastDateAxis.cursorTooltipEnabled = true;
            }
            for (let i = 1; i < allCharts.length; i++) {
                const chart = allCharts[i];
                chart.events.on('up', (evt) => {
                    const cursor = evt.target.cursor;
                    // cursor.dispatchImmediately('zoomended')
                    for (let i = 1; i < allCharts.length; i++) {
                        const otherCursor = allCharts[i].cursor;
                        if (!isEqual(otherCursor, cursor)) {
                            otherCursor.triggerUp({ x: cursor.point.x, y: 0 });
                        }
                    }
                });
            }
        },
        initCursorListeners() {
            const cursorShowDisposers = [];
            for (let i = 0; i < this.allCharts.length; i++) {
                const cursor = this.allCharts[i].cursor;
                cursor.interactionsEnabled = true;
                cursorShowDisposers.push(cursor.events.on('shown', (event) => {
                    this.handleShowCursor(event.target);
                }));
            }
            this.cursorShowDisposers = cursorShowDisposers;
        },
        // disable mouse for all other cursors
        handleShowCursor(shownCursor) {
            const syncCursors = (syncWithCursor) => {
                for (let i = 0; i < this.allCharts.length; i++) {
                    const cursor = this.allCharts[i].cursor;
                    const point = { x: syncWithCursor.point.x, y: 0 };
                    if (!isEqual(cursor, syncWithCursor)) {
                        cursor.triggerMove(point);
                    }
                }
            };
            // disable mouse for all other cursors
            for (let i = 0; i < this.allCharts.length; i++) {
                const cursor = this.allCharts[i].cursor;
                if (!isEqual(cursor, shownCursor)) {
                    cursor.interactionsEnabled = false;
                }
                // remove show listener
                this.cursorShowDisposers[i].dispose();
            }
            // add change disposer to the hovered chart cursor
            this.shownCursorChangeDisposer = shownCursor.lineX.events.on('positionchanged', (_evt) => {
                syncCursors(shownCursor);
            });
            this.shownCursorZoomStartedDisposer = shownCursor.events.on('zoomstarted', (event) => {
                for (let i = 0; i < this.allCharts.length; i++) {
                    const cursor = this.allCharts[i].cursor;
                    if (!isEqual(cursor, event.target)) {
                        const point = { x: event.target.point.x, y: 0 };
                        cursor.triggerDown(point);
                    }
                }
            });
            this.shownCursorZoomEndedDisposer = shownCursor.events.on('zoomended', (event) => {
                for (let i = 0; i < this.allCharts.length; i++) {
                    const cursor = this.allCharts[i].cursor;
                    if (!isEqual(cursor, event.target)) {
                        const point = { x: event.target.point.x, y: 0 };
                        cursor.triggerUp(point);
                    }
                }
            });
            shownCursor.events.once('hidden', (_event) => {
                this.shownCursorChangeDisposer.dispose();
                this.shownCursorZoomStartedDisposer.dispose();
                this.shownCursorZoomEndedDisposer.dispose();
                for (let i = 0; i < this.allCharts.length; i++) {
                    const cursor = this.allCharts[i].cursor;
                    cursor.hide(0);
                    this.cursorShowDisposers[i]?.dispose();
                }
                this.initCursorListeners();
            });
        },
        syncDateAxes(syncWithAxis) {
            for (let i = 0; i < this.allCharts.length; i++) {
                const chart = this.allCharts[i];
                const dateAxis = chart.cursor.xAxis; //.xAxes.getIndex(0) //
                if (!isEqual(dateAxis, syncWithAxis)) {
                    i > 0 && dateAxis.events.disableType('startendchanged');
                    dateAxis.start = syncWithAxis.start;
                    dateAxis.end = syncWithAxis.end;
                    i > 0 && dateAxis.events.enableType('startendchanged');
                }
            }
        },
        /**
         * Comments Chart
         */
        createCommentsAmChart() {
            const seriesData = this.commentDataSeries;
            const dataFields = this.commentDataFields;
            const chart = this.commentsAmChart;
            const { dateAxis } = this.setChartProps(chart, {
                title: 'Comments',
                titleDx: 5,
                valueAxis: { min: 0 },
            });
            chart.data = seriesData;
            const scrollbarX = this.scrollbarX;
            chart.legend = new am4charts.Legend();
            chart.legend.fontSize = 14;
            chart.legend.labels.template.opacity = 0.8;
            chart.zoomOutButton.disabled = true;
            let series, tooltip;
            dataFields.forEach((dataField, index) => {
                // Create series
                series = chart.series.push(new am4charts.LineSeries());
                tooltip = series.tooltip;
                //rollover.
                //https://github.com/amcharts/amcharts4/issues/1705
                series.segments.template.interactionsEnabled = true;
                series.segments.template.events.on('over', (_ev) => {
                    this.highlightLineAtIndex(index);
                    this.highlitIndex = index;
                });
                series.segments.template.events.on('out', (_ev) => {
                    this.unhighlightAll();
                    this.highlitIndex = -1;
                });
                series.name = dataField.name;
                series.dataFields.valueY = dataField.field;
                series.dataFields.dateX = 'date';
                series.minBulletDistance = 15;
                series.stroke = am4core.color(colors[index]);
                series.strokeWidth = 3;
                series.opacity = 1;
                series.interpolationDuration = 5;
                series.tensionX = 0.77;
                series.showOnInit = false;
                if (tooltip) {
                    tooltip.dateFormatter.dateFormat = this.tooltipFormat;
                    // Set up tooltip
                    series.adapter.add('tooltipText', (_ev) => {
                        let text = ''; //'[bold font-size: 15]{dateX}[/]\n'
                        chart.series.each((item, index) => {
                            const highlightOpen = index == this.highlitIndex ? '[bold]' : '';
                            const highlightClose = index == this.highlitIndex ? '[/]' : '';
                            text +=
                                '[' +
                                    item.stroke.hex +
                                    ']●[/] ' +
                                    highlightOpen +
                                    item.name +
                                    ': {' +
                                    item.dataFields.valueY +
                                    '}' +
                                    highlightClose +
                                    '\n';
                        });
                        return text;
                    });
                    tooltip.getFillFromObject = false;
                    tooltip.background.fill = am4core.color('#fff');
                    tooltip.label.fill = am4core.color('#000');
                }
                if (!this.commentHasRatings &&
                    scrollbarX instanceof am4charts.XYChartScrollbar) {
                    scrollbarX.series.push(series);
                }
                this.commentsAmChartSeries.push(series);
            });
            dateAxis.keepSelection = true;
            //the -1 is so that we only ever have the ONE tooltip.
            //https://www.amcharts.com/docs/v4/reference/xycursor/#maxTooltipDistance_property
            // chart.cursor = new am4charts.XYCursor()
            // chart.cursor.lineY.disabled = true
            // chart.cursor.xAxis = dateAxis
            // chart.cursor.maxTooltipDistance = -1
            chart.padding(20, 15, 20, 15);
            this.range = dateAxis.axisRanges.create();
            this.commentsDateAxis = dateAxis;
            this.commentsAmChartWasCreated = true;
            // chart.exporting.extraSprites.push(this.ratingsAmChart!)
        },
        /**
         * Ratings Chart
         */
        createRatingsAmChart() {
            const seriesData = this.commentRatingsDataSeries;
            const chart = this.ratingsAmChart;
            chart.dateFormatter.inputDateFormat = 'x';
            const { dateAxis } = this.setChartProps(chart, {
                title: 'Ratings',
                valueAxis: { min: 0, max: 10 },
            });
            chart.data = seriesData;
            chart.zoomOutButton.disabled = true;
            let series, tooltip;
            // Create series
            series = chart.series.push(new am4charts.LineSeries());
            //rollover.
            series.segments.template.interactionsEnabled = true;
            series.name = 'avg. ratings';
            series.dataFields.valueY = 'value';
            series.dataFields.dateX = 'date';
            series.minBulletDistance = 15;
            series.stroke = this.workspaceColor;
            series.strokeWidth = 3;
            series.opacity = 1;
            series.interpolationDuration = 5;
            series.tensionX = 0.77;
            series.showOnInit = false;
            tooltip = series.tooltip;
            if (tooltip) {
                tooltip.dateFormatter.dateFormat = this.tooltipFormat;
                // Set up tooltip
                series.adapter.add('tooltipText', (_ev) => {
                    const text = '[' +
                        series.stroke.hex +
                        ']●[/] ' +
                        '[]' +
                        series.name +
                        ': {' +
                        series.dataFields.valueY +
                        '}' +
                        '[/]' +
                        '\n';
                    return text;
                });
                tooltip.getFillFromObject = false;
                tooltip.background.fill = am4core.color('#fff');
                tooltip.label.fill = am4core.color('#000');
            }
            dateAxis.keepSelection = true;
            this.ratingsRange = dateAxis.axisRanges.create();
            this.ratingsDateAxis = dateAxis;
            /*
            chart.exporting.menu = new am4core.ExportMenu()
            chart.exporting.menu.align = 'right'
            chart.exporting.menu.verticalAlign = 'bottom'
            */
            chart.padding(20, 15, 20, 20);
            this.ratingsAmChartWasCreated = true;
        },
        /**
         * All and Ratings Chart for Global ScrollbarX
         */
        createGlobalScrollbarX() {
            const seriesData = this.globalScrollbarDataSeries;
            const chart = this.scrollbarAmChart;
            chart.dateFormatter.inputDateFormat = 'x';
            const { dateAxis } = this.setChartProps(chart, {});
            chart.data = seriesData;
            // const scrollbarX = this.scrollbarX!
            let series, tooltip;
            // Push series
            series = chart.series.push(new am4charts.LineSeries());
            series.segments.template.interactionsEnabled = true;
            series.name = 'all';
            series.dataFields.valueY = 'value';
            series.dataFields.dateX = 'date';
            series.minBulletDistance = 15;
            series.stroke = this.workspaceColor;
            series.strokeWidth = 3;
            series.opacity = 1;
            series.interpolationDuration = 5;
            series.tensionX = 0.77;
            series.showOnInit = false;
            // scrollbarX.series.push(series)
            tooltip = series.tooltip;
            if (tooltip) {
                tooltip.dateFormatter.dateFormat = this.tooltipFormat;
                tooltip.getFillFromObject = false;
                tooltip.background.fill = am4core.color('#fff');
                tooltip.label.fill = am4core.color('#000');
            }
            dateAxis.keepSelection = true;
            chart.padding(0, 20, 20, 25);
            // chart.margin(0, 0, 10, 0)
        },
        setBaseInterval(dateAxis) {
            if (dateAxis.baseInterval && dateAxis.baseInterval.count > 0) {
                this.baseInterval = dateAxis.baseInterval.count;
            }
        },
        setChartProps(chart, config) {
            chart.dateFormatter.inputDateFormat = 'x';
            // Create x-axis
            const dateAxis = chart.xAxes.push(new am4charts.DateAxis());
            dateAxis.renderer.labels.template.disabled = true;
            dateAxis.cursorTooltipEnabled = false;
            dateAxis.renderer.grid.template.location = 0.5;
            dateAxis.renderer.grid.template.disabled = true;
            dateAxis.startLocation = 0.5;
            dateAxis.endLocation = 0.5;
            //https://www.amcharts.com/docs/v4/concepts/axes/positioning-axis-elements/
            //this is workaround for location from the amcharts docs.
            dateAxis.renderer.labels.template.location = 0.001;
            //Reset the grid intervals. We need a little more sensitivity for minutes
            //because most of our videos are on the order of minutes.
            dateAxis.gridIntervals = new am4core.List([
                { timeUnit: 'second', count: 1 },
                { timeUnit: 'second', count: 5 },
                { timeUnit: 'second', count: 10 },
                { timeUnit: 'second', count: 30 },
                { timeUnit: 'minute', count: 1 },
                { timeUnit: 'minute', count: 2 },
                { timeUnit: 'minute', count: 3 },
                { timeUnit: 'minute', count: 4 },
                { timeUnit: 'minute', count: 5 },
                { timeUnit: 'minute', count: 6 },
                { timeUnit: 'minute', count: 8 },
                { timeUnit: 'minute', count: 10 },
                { timeUnit: 'minute', count: 30 },
                { timeUnit: 'hour', count: 1 },
                { timeUnit: 'hour', count: 3 },
                { timeUnit: 'hour', count: 6 },
                { timeUnit: 'hour', count: 12 },
            ]);
            dateAxis.keepSelection = true;
            if (dateAxis.tooltip) {
                dateAxis.tooltip.disabled = false;
                dateAxis.tooltip.animationDuration = 0;
            }
            // dateAxis.renderer.minGridDistance = 20 //Math.round(this.video.duration / 2);
            // dateAxis.gridIntervals.setAll([
            //   { timeUnit: 'second', count: 1 },
            //   { timeUnit: 'second', count: Math.round(this.video.duration / 5) },
            // ])
            // Create y-axis
            const valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
            if (valueAxis.tooltip) {
                valueAxis.tooltip.disabled = true;
            }
            valueAxis.title.text = config.title ?? '';
            valueAxis.title.dx = config.titleDx || 0;
            valueAxis.title.fontSize = 14;
            valueAxis.title.fontWeight = '400';
            valueAxis.title.opacity = 0.8;
            //we only want whole numbers for these charts.
            valueAxis.adjustLabelPrecision = false;
            chart.events.on('ready', (_ev) => {
                const now = this.unixMidnight;
                const { start, stop } = this.triggerTime;
                dateAxis.min = now + start * 1000;
                dateAxis.max = now + stop * 1000; //dateAxis.maxZoomed
                if (this.queryParamMinMax) {
                    let { min = start, max = stop } = this.queryParamMinMax;
                    this.queryParamMinMax = null;
                    this.isResetChartData = true;
                    if (this.commentsAmChart) {
                        this.calculatedMinMax = { start: min, stop: max };
                        const dateAxis = this.commentsAmChart.cursor.xAxis;
                        if (dateAxis) {
                            const startDiff = min - start;
                            const begin = startDiff < 0.5 ? 0 : startDiff - 0.5;
                            const triggerDiff = stop - start > 0 ? stop - start : stop;
                            dateAxis.start = begin / triggerDiff;
                            dateAxis.end = (max + 0.5 - start) / triggerDiff;
                        }
                    }
                }
                valueAxis.min = config.valueAxis?.min ?? valueAxis.minZoomed;
                valueAxis.max = config.valueAxis?.max ?? valueAxis.maxZoomed; // + 0.5
                this.setBaseInterval(dateAxis);
            });
            dateAxis.events.on('startendchanged', (ev) => {
                const now = this.unixMidnight;
                const min = ev.target.minZoomed;
                const max = ev.target.maxZoomed;
                const { start, stop } = this.triggerTime;
                let newMin = min;
                let newMax = max;
                if (min < now + 1000) {
                    newMin = now + start * 1000;
                }
                if (max > now + stop * 1000) {
                    newMax = now + stop * 1000;
                }
                const xaxis = { min: newMin, max: newMax };
                this.onChartZoomed(null, { xaxis });
                if (this.commentHasRatings) {
                    this.syncDateAxes(ev.target);
                }
                this.setBaseInterval(dateAxis);
            });
            chart.zoomOutButton.scale = 1.2;
            chart.zoomOutButton.events.on('hit', (_ev) => {
                // this.resetZoomPress = true
                this.isResetChartData = false;
                this.globalScrollbarDataSeries = [];
                const now = this.unixMidnight;
                const { start, stop } = this.triggerTime;
                dateAxis.min = now + start * 1000;
                dateAxis.max = now + stop * 1000;
                // reset the currently selected scene filter
                this.$events.emit('resetVideoSceneFilter');
            });
            chart.cursor = new am4charts.XYCursor();
            chart.cursor.lineY.disabled = true;
            chart.cursor.xAxis = dateAxis;
            chart.cursor.maxTooltipDistance = -1;
            let cursorXPosition = null;
            chart.cursor.events.on('cursorpositionchanged', function (ev) {
                var xAxis = ev.target.chart.xAxes.getIndex(0);
                if (xAxis) {
                    // @ts-ignore
                    cursorXPosition = xAxis.positionToDate(xAxis.toAxisPosition(ev.target.xPosition));
                }
            });
            chart.plotContainer.events.on('hit', (_ev) => {
                if (cursorXPosition) {
                    const dateStringToDate = new Date(cursorXPosition);
                    const sec = dateStringToDate.getSeconds();
                    const minToSec = dateStringToDate.getMinutes() * 60;
                    const hrToSec = dateStringToDate.getHours() * 60 * 60;
                    const vctime = sec + minToSec + hrToSec;
                    this.$events.emit('chartTapped', { vctime });
                }
            });
            // Set date label formatting
            dateAxis.dateFormats.setKey('hour', this.tickFormat);
            dateAxis.dateFormats.setKey('minute', this.tickFormat);
            dateAxis.dateFormats.setKey('second', this.tickFormat);
            dateAxis.dateFormats.setKey('millisecond', 'mm:ss');
            dateAxis.markUnitChange = false;
            return { dateAxis, valueAxis };
        },
        highlightLineAtIndex(highlightThis) {
            const arr = this.commentsAmChartSeries;
            arr.forEach((series, index) => {
                if (index == highlightThis) {
                    series.strokeWidth = 5;
                    series.opacity = 1;
                }
                else {
                    series.strokeWidth = 3;
                    series.opacity = 0.3;
                }
            });
        },
        unhighlightAll() {
            const arr = this.commentsAmChartSeries;
            arr.forEach((series) => {
                series.strokeWidth = 3;
                series.opacity = 1;
            });
        },
        disposeOfCharts() {
            this.sceneObjectsComments.splice(0, this.sceneObjectsComments.length);
            this.sceneObjectsRatings.splice(0, this.sceneObjectsRatings.length);
            this.commentsAmChartWasCreated = false;
            this.ratingsAmChartWasCreated = false;
            this.commentsAmChart?.dispose();
            this.commentsAmChart = null;
            this.commentsAmChartSeries = [];
            this.ratingsAmChart?.dispose();
            this.ratingsAmChart = null;
            this.scrollbarAmChart?.dispose();
            this.scrollbarAmChart = null;
            this.chartsContainer?.dispose();
            this.chartsContainer = null;
            this.customBucketSize = null;
            this.baseInterval = 0;
        },
        formatToHHMMSS(videoTime) {
            return formatTime(videoTime);
        },
        moveXAnnotation(videoTime) {
            const xAnnotate = (range) => {
                if (range) {
                    const videoTimeDate = new Date(this.unixMidnight + videoTime * 1000);
                    range.date = videoTimeDate;
                    range.label.text = this.formatToHHMMSS(videoTime);
                }
            };
            xAnnotate(this.range);
            xAnnotate(this.ratingsRange);
        },
        buildRange(range) {
            range.date = new Date(this.unixMidnight);
            range.axisFill.fill = am4core.color('#666666');
            range.axisFill.fillOpacity = 0.2;
            range.label.text = '';
            range.label.inside = true;
            range.label.rotation = 90;
            range.label.horizontalCenter = 'right';
            range.label.verticalCenter = 'bottom';
            range.label.fontSize = 12;
            range.grid.stroke = am4core.color('#666666');
            range.grid.strokeOpacity = 0.9;
            range.grid.strokeWidth = 3;
            range.grid.strokeDasharray = '2,2';
        },
        onBucketSizeChanged(size) {
            this.customBucketSize = size;
            this.$emit('bucket-size', size);
        },
        getColorAtIndex(index, darken) {
            const colors = darken ? this.darkenedColors : this.colors;
            return colors.length > index ? colors[index] : '';
        },
        drawScenesOnGraph(scenes, dateAxis, sceneObjects) {
            const interval = this.baseInterval;
            //handle the scene object explicity because we don't want to delete the x-annotation accidentally
            sceneObjects.forEach((sceneObject) => {
                dateAxis.axisRanges.removeValue(sceneObject);
            });
            sceneObjects.splice(0, sceneObjects.length);
            const sceneArr = Object.values(scenes).sort((a, b) => a.startTime - b.startTime);
            sceneArr.forEach((scene, idx) => {
                const r = dateAxis.axisRanges.create();
                sceneObjects.push(r);
                const colorForScene = this.getColorAtIndex(idx, false);
                const darkColorForScene = this.getColorAtIndex(idx, true);
                //chuks uses 0.5 elsewhere to solve an alignment problem, that affects where
                //the charts starts at. but it doens't start at 0.5 it starts at 0.5*baseInterval.
                const startTime = Math.max(interval / 2, scene.startTime);
                r.date = new Date(this.unixMidnight + startTime * 1000);
                r.endDate = new Date(this.unixMidnight + scene.stopTime * 1000);
                r.axisFill.fill = am4core.color(colorForScene);
                r.axisFill.fillOpacity = 0.2;
                r.label.text = scene.name;
                r.label.inside = true;
                r.label.horizontalCenter = 'left';
                r.label.valign = 'bottom';
                r.label.verticalCenter = 'top';
                r.label.rotation = -90;
                r.label.fill = am4core.color(darkColorForScene);
                r.label.truncate = true;
                r.label.maxWidth = this.sceneLabelMaxWidth;
                r.label.fontSize = 10;
                r.grid.strokeOpacity = 0;
                r.label.padding(3, 3, 3, 6);
            });
        },
        externalFilterTriggerZoom(min, max) {
            const { start, stop } = this.triggerTime;
            if (this.commentsAmChart) {
                this.isResetChartData = false;
                this.externalFilterTriggeredZoom = true;
                min || (min = start);
                max || (max = stop);
                if (min < start) {
                    min = start;
                }
                if (max > stop) {
                    max = stop;
                }
                this.externalFilterMinMax = { min: +min, max: +max };
                const dateAxis = this.commentsAmChart.cursor.xAxis;
                console.log({ dateAxis });
                if (dateAxis) {
                    dateAxis.start = (min - start) / (stop - start);
                    dateAxis.end = (max - start) / (stop - start);
                }
            }
        },
    },
    watch: {
        triggerTime: {
            handler(minMax) {
                this.calculatedMinMax = minMax;
            },
            immediate: true,
        },
        comment_count: {
            handler(bucket) {
                if (!isEmpty(bucket)) {
                    if (this.commentHasRatings) {
                        this.buildRatingsTrendData(bucket);
                    }
                    this.buildTrendData(bucket);
                    this.$nextTick(() => {
                        this.createAmChartContainer();
                    });
                }
            },
            immediate: true,
        },
        feature: {
            handler(_feature) {
                // reset all the chart based manipulation fields
                this.isResetChartData = true;
                this.disposeOfCharts();
                this.switchingFeature = true;
                this.$sleep(10).then(() => {
                    this.switchingFeature = false;
                });
            },
            immediate: true,
            deep: true,
        },
        commentCountMouseAction: {
            handler(data) {
                const { index, event } = data;
                if (event === MouseEvent.MOUSELEAVE) {
                    this.unhighlightAll();
                }
                else {
                    this.highlightLineAtIndex(index);
                }
            },
            deep: true,
        },
        xAxis() { },
        videoTime(newTime) {
            this.moveXAnnotation(newTime);
        },
        range(rangeRef) {
            if (rangeRef) {
                this.buildRange(rangeRef);
            }
        },
        ratingsRange(rangeRef) {
            if (rangeRef) {
                this.buildRange(rangeRef);
            }
        },
        compositeRatingsAmChartsAndVideoScenes: {
            handler(newVal) {
                if (newVal.created && newVal.scenes && this.ratingsDateAxis) {
                    this.drawScenesOnGraph(newVal.scenes, this.ratingsDateAxis, this.sceneObjectsRatings);
                }
            },
            immediate: true,
        },
        compositeCommentsAmChartsAndVideoScenes: {
            handler(newVal) {
                if (newVal.created && newVal.scenes && this.commentsDateAxis) {
                    this.drawScenesOnGraph(newVal.scenes, this.commentsDateAxis, this.sceneObjectsComments);
                }
            },
            immediate: true,
        },
    },
    mounted() {
        const { min, max } = this.$route.query;
        const { start, stop } = this.triggerTime;
        if (hasNumberEl([min, max])) {
            const minMax = {};
            if (hasNumberEl(min)) {
                minMax.min = +min >= start && +min < stop ? +min : start;
            }
            if (hasNumberEl(max)) {
                minMax.max = +max <= stop && +max > start ? +max : stop;
            }
            this.queryParamMinMax = minMax;
        }
    },
    events: {
        videoSceneFilter(params) {
            let { min, max } = params;
            this.externalFilterTriggerZoom(min, max);
        },
        videoFacesFilter(params) {
            let { min, max } = params;
            this.externalFilterTriggerZoom(min, max);
        },
    },
    beforeDestroy() {
        this.disposeOfCharts();
    },
    components: {
        VideoInsightsTrendsBucketPicker,
        VideoInsightCommenDataFilter,
    },
});
