import Vue from 'vue';
import adminAPI from '@/services/adminAPI';
var DataExportTypes;
(function (DataExportTypes) {
    DataExportTypes["VIEWER_RETENTION"] = "Viewer Retention";
    DataExportTypes["COMMENTS"] = "Comments";
    DataExportTypes["COMMENT_ENTITIES"] = "Comment Entities";
    DataExportTypes["CHANNEL_VIEWS"] = "Channel Views";
    // EMOJIS = 'Emojis
})(DataExportTypes || (DataExportTypes = {}));
export default Vue.extend({
    name: 'ChannelDataManagementDataExport',
    props: {
        channel: Object,
    },
    data() {
        return {
            selectedOption: null,
            exportList: Object.values(DataExportTypes),
            fetchingDataExport: false,
            snackbar: false,
            snackbarMessage: '',
        };
    },
    methods: {
        async determineDataToExport() {
            const type = this.selectedOption;
            this.fetchingDataExport = true;
            try {
                switch (type) {
                    case DataExportTypes.VIEWER_RETENTION:
                        await this.downloadVideoRetentionData();
                        break;
                    case DataExportTypes.COMMENTS:
                        // fetch channel comment data
                        await this.downloadCommentsData();
                        break;
                    case DataExportTypes.COMMENT_ENTITIES:
                        await this.downloadCommentsEntitiesData();
                        break;
                    case DataExportTypes.CHANNEL_VIEWS:
                        await this.downloadChannelViewsData();
                        break;
                    default:
                        break;
                }
            }
            catch (error) {
                console.log({ error });
            }
            finally {
                this.fetchingDataExport = false;
            }
        },
        async downloadVideoRetentionData() {
            await adminAPI(async (api) => api.channel.exports.getChannelVideoRetentionDataExport({
                cid: this.channel.id,
            })).then(({ csv }) => {
                const date_str = new Date().toISOString();
                const filename = `${this.channel.title}_${date_str}_video-retention.csv`;
                const snackMessage = `Channel video retention data export is complete`;
                this.downloadHelper(csv, filename, snackMessage);
            });
        },
        async downloadCommentsData() {
            await adminAPI(async (api) => api.channel.exports.getChannelCommentsDataExport({
                cid: this.channel.id,
            })).then(({ csv }) => {
                const date_str = new Date().toISOString();
                const filename = `${this.channel.title}_${date_str}_comments.csv`;
                const snackMessage = `Channel comments data export is complete`;
                this.downloadHelper(csv, filename, snackMessage);
            });
        },
        async downloadCommentsEntitiesData() {
            await adminAPI(async (api) => api.channel.exports.getChannelCommentsEntitiesDataExport({
                cid: this.channel.id,
            })).then(({ csv }) => {
                const date_str = new Date().toISOString();
                const filename = `${this.channel.title}_${date_str}_comments-entities.csv`;
                const snackMessage = `Channel comments entities data export is complete`;
                this.downloadHelper(csv, filename, snackMessage);
            });
        },
        async downloadChannelViewsData() {
            await adminAPI(async (api) => api.channel.exports.getChannelViewsDataExport({
                cid: this.channel.id,
            })).then(({ csv }) => {
                const date_str = new Date().toISOString();
                const filename = `${this.channel.title}_${date_str}_channel-views.csv`;
                const snackMessage = `Channel views data export is complete`;
                this.downloadHelper(csv, filename, snackMessage);
            });
        },
        downloadHelper(csv, filename, snackMessage) {
            const data = encodeURIComponent(csv);
            this.$download(data, filename, { type: 'text/plain' });
            this.snackbar = true;
            this.snackbarMessage = snackMessage;
        },
    },
    watch: {
        selectedOption(selection) {
            console.log(selection);
        },
    },
});
