import { FieldValue } from '@/services/firebase/firestore'
import uploadFileToStorage from '@/utils/uploadFileToStorage'
import DB from '@lumiere/db'
import { Video, VideoStatus, VideoVisibility } from '@lumiere/db/types'
import logger from '@lumiere/shared/services/logger'
import { FileUpload, FileUploadStatus } from '../store.state'

interface CreateOrUpdateVideoDoc {
  existingVideo: Video | null
  docId: string
  filename: string
  workspaceId: string
}

function updateVideoReplacementPoster(video: Video) {
  if (!video.appearance?.poster) return {}

  return {
    [`${'appearance.poster'}`]: video.appearance.poster.match(/image\.mux/i)
      ? FieldValue.delete()
      : video.appearance.poster,
  }
}

export async function createOrUpdateVideoDoc({
  existingVideo,
  docId,
  filename,
  workspaceId,
}: CreateOrUpdateVideoDoc) {
  logger.info(
    existingVideo ? 'Updated video document' : 'Created video document',
  )

  if (existingVideo) {
    await DB.getInstance()
      .video(docId)
      .update({
        status: VideoStatus.Uploading,
        visibility: existingVideo.visibility,
        previousUploadId: existingVideo.uploadId,
        ...updateVideoReplacementPoster,
      })
  } else {
    await DB.getInstance().video(docId).create({
      workspaceId,
      title: filename,
      description: '',
      status: VideoStatus.Uploading,
      visibility: VideoVisibility.Public,
      assets: [],
      tags: [],
      channels: [],
      features: [],
    })
  }
}

interface IngestVideoFile {
  file: File
  updateHandler: (
    update: Omit<FileUpload, 'name' | 'id' | 'workspaceId'>,
  ) => void
  docId: string
}

export async function ingestVideoFile({
  file,
  updateHandler,
  docId,
}: IngestVideoFile): Promise<string | Error> {
  // 3. Upload to Cloud Storage
  return new Promise((resolve, reject) => {
    const uploader = uploadFileToStorage(file, `video-files/`, docId)

    uploader.percentage().subscribe(({ progress }) => {
      updateHandler({
        status: FileUploadStatus.UPLOADING,
        progress,
        cancel: () => {
          uploader.cancel()
          uploader.catch((e) => logger.info('Video upload error', e))
          reject({ canceled: true })
        },
      })
    })

    uploader.getURL().then(resolve).catch(reject)
  })
}
