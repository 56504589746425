import { Emotions } from './types';
// import chroma from 'chroma-js'
export function smoothSeries(series) {
    let newData;
    const newSeries = [];
    for (const item of series) {
        // apply the transformation
        newData = [];
        let oldPoint = null;
        let newY = 0;
        for (const dataPoint of item.data) {
            if (!oldPoint) {
                newData.push({ ...dataPoint });
                oldPoint = dataPoint;
            }
            else {
                newY = +(0.7 * (dataPoint.y ?? 0) + 0.3 * (oldPoint.y ?? 0)).toFixed(2);
                oldPoint = { ...dataPoint, y: newY };
                newData.push(oldPoint);
            }
        }
        newSeries.push({ ...item, data: newData });
    }
    return newSeries;
}
// https://cloud.google.com/natural-language/docs/basics#interpreting_sentiment_analysis_values
// How do we interpret the mixed emotions
export function sentimentInterpretation(sentiment) {
    if (!sentiment?.score)
        return Emotions.NEUTRAL;
    const { score } = sentiment;
    if (score >= 0.15) {
        return Emotions.POSITIVE;
    }
    else if (score <= -0.15) {
        return Emotions.NEGATIVE;
    }
    else {
        return Emotions.NEUTRAL;
    }
}
export function emotionColor(emotion) {
    switch (emotion) {
        case Emotions.POSITIVE:
            return colors[1];
        case Emotions.NEGATIVE:
            return colors[2];
        case Emotions.NEUTRAL:
            return colors[3];
        default:
            return '#ccc';
    }
}
export function clientDynamicBucketing(time) {
    switch (true) {
        case time < 30:
            return 1;
        case time < 60:
            return 2;
        case time < 119:
            return 3;
        case time < 299:
            return 5;
        case time < 599:
            return 8;
        case time < 899:
            return 10;
        case time < 1799:
            return 15;
        case time < 3599:
            return 30;
        case time < 7199:
            return 60;
        default:
            return 60;
    }
}
export function findOffset(comment, textContent, dummyText) {
    let offset;
    if (comment.includes(` ${textContent} `)) {
        offset = dummyText.indexOf(` ${textContent} `);
    }
    else if (comment.includes(`${textContent} `)) {
        offset = dummyText.indexOf(`${textContent} `);
    }
    else if (comment.includes(` ${textContent}`)) {
        const tagLikeWords = dummyText.indexOf(` ${textContent}=`); // to eliminate the words `class` and `style`
        if (tagLikeWords > -1) {
            offset = dummyText.indexOf(` ${textContent}`, tagLikeWords + 1);
        }
        else {
            offset = dummyText.indexOf(` ${textContent}`);
        }
    }
    else {
        offset = -1;
    }
    return offset;
}
export const escapeInvalidRegex = (str) => {
    return str.replace(/([.?*+^$[\]\\(){}'`_:;,<>#=§%"|-])/g, '\\$1');
};
export const escapeQuerySyntax = (str) => {
    return str.replace(/([*+^~$[\]\\(){}_=|-])/g, '');
};
export const SKELETON_COUNT = 10;
export const PER_LOAD_SIZE = 500;
/**
 * if it has a number element e.g. '2' or 3 or ['2', 3]
 */
export const hasNumberEl = (value) => {
    if (Array.isArray(value)) {
        return !!value.filter((val) => hasNumberEl(val)).length;
    }
    return isNumber(value);
};
export const isNumber = (value) => value != null && typeof +value === 'number' && isFinite(+value);
/**
 * color gradient is made with this tool: https://colordesigner.io/gradient-generator
 * @param n the sentiment average
 *
 * Color ranges are the same as the colors used in other parts if the Viz.
 * Where green: #4caf50, red: #f44336 and yellow: ffc107
 */
export const wordCloudColorGradient = (n) => {
    const binaryPoint = Math.abs(Math.round(10 + (n % 2) * 10));
    return gradientColorRange[binaryPoint];
};
/** Sentiment colors */
export const colors = [
    '#757575',
    '#4caf50',
    '#f44336',
    '#ffc107', // yellow - neutral
];
/** range of colors for word cloud */
const gradientColorRange = [
    '#f44336',
    '#f85131',
    '#fc5f2b',
    '#fe6c25',
    '#ff791f',
    '#ff8518',
    '#ff9211',
    '#ff9e09',
    '#ffaa04',
    '#ffb502',
    '#ffc107',
    '#ecc201',
    '#dac104',
    '#c8c10e',
    '#b6c019',
    '#a4be23',
    '#93bc2d',
    '#82b936',
    '#70b63f',
    '#5fb348',
    '#4caf50', // green
];
/** color gradient for sentiment ratings with vue-slider */
export const COLOR_GRADIENT = [
    '#ff6a2a',
    '#ff7327',
    '#ff8c1e',
    '#ffac19',
    '#ffcb23',
    '#ffea3b',
    '#d9e03b',
    '#b4d63f',
    '#91ca44',
    '#6ebd4b',
    '#4baf51', // green
];
const HTMLAttrWords = [
    'span',
    'class',
    'class=',
    'style',
    'style=',
    'font-family',
    'color',
    'inherit',
    'body',
    'background-color',
    ...colors,
];
export function isInHtmlAttr(word) {
    return !!HTMLAttrWords.filter((attr) => attr.includes(word)).length;
}
