import Vue from 'vue';
import { CommentPlusFeature } from '@lumiere/db/types';
import CommentPlusRatings from './CommentPlusRatings.vue';
const PlusFeatureComponent = {
    [CommentPlusFeature.Ratings]: CommentPlusRatings,
    [CommentPlusFeature.Stars]: () => null,
    [CommentPlusFeature.Tags]: () => null,
    [CommentPlusFeature.Emojis]: () => null,
};
const PlusFeatureLabel = {
    [CommentPlusFeature.Ratings]: 'Ratings Slider',
    [CommentPlusFeature.Stars]: 'Star Rating',
    [CommentPlusFeature.Tags]: 'Tags',
    [CommentPlusFeature.Emojis]: 'Emojis',
};
export default Vue.extend({
    props: {
        value: {
            type: Object,
            default: null,
        },
    },
    data() {
        const plus = this.$props?.value ?? null;
        return {
            plusType: plus?.type ?? null,
            plus,
        };
    },
    watch: {
        plus: {
            handler(plus) {
                this.$emit('input', plus);
            },
            deep: true,
        },
        plusType(val) {
            if (val == null) {
                this.$emit('input', val);
            }
        },
    },
    computed: {
        plusOptionsComponent() {
            return (type) => PlusFeatureComponent[type];
        },
        plusFeatureLabel() {
            return PlusFeatureLabel;
        },
        features() {
            const enabledFeatures = [CommentPlusFeature.Ratings];
            return [
                { text: 'None', value: null },
                ...enabledFeatures.map((value) => ({
                    text: PlusFeatureLabel[value],
                    value,
                })),
            ];
        },
    },
    components: {
        CommentPlusRatings,
    },
});
