import Vue from 'vue';
export const defaultOptions = () => ({
    viewerDismissable: false,
});
export default Vue.extend({
    name: 'TextPromptFeedbackOptions',
    props: {
        value: {
            type: Object,
        },
    },
    data() {
        return {
            options: { ...defaultOptions(), ...this.$props.value },
        };
    },
    watch: {
        options: {
            handler(options) {
                this.$emit('input', options);
            },
            deep: true,
        },
    },
});
