import Vue from 'vue';
import { mapGetters } from 'vuex';
import { VideoStatus, Role, ChannelStatus, } from '@lumiere/db/types';
import FuzzySearch from '@lumiere/shared/services/FuzzySearch';
import { combineLatest } from 'rxjs';
import { shareReplay, map, pluck, debounceTime, switchMap, startWith, tap, } from 'rxjs/operators';
import GlobalSearchVideos from './globalSearchItems/GlobalSearchVideos.vue';
import GlobalSearchChannels from './globalSearchItems/GlobalSearchChannels.vue';
import GlobalSearchWorkspaces from './globalSearchItems/GlobalSearchWorkspaces.vue';
const VIDEO_SEARCH_KEYS = ['id', 'title', 'description'];
const CHANNEL_SEARCH_KEYS = ['id', 'title', 'description'];
const WORKSPACE_SEARCH_KEYS = ['id', 'name', 'description', 'team.name'];
export default Vue.extend({
    name: 'GlobalSearchbar',
    data() {
        return {
            search: '',
            searchDialog: false,
            placeholder: 'Search',
            showSearchButton: false,
        };
    },
    computed: {
        ...mapGetters(['currentWorkspace', 'currentTeam']),
        isSmScreen() {
            return this.$vuetify.breakpoint.smAndDown;
        },
        isXsScreen() {
            return this.$vuetify.breakpoint.xsOnly;
        },
    },
    subscriptions() {
        const search$ = this.$watchAsObservable('search', {
            immediate: true,
        }).pipe(pluck('newValue'));
        // workspaces
        const userWorkspaces$ = this.$db
            .workspaces((q) => q.where(`roles.${this.$store.state.uid}`, 'in', Object.values(Role)))
            .observe()
            .pipe(map((workspaces) => workspaces.filter((w) => !w.deleted)));
        // videos
        const allVideos$ = userWorkspaces$.pipe(switchMap((workspaces) => combineLatest([...workspaces].map((w) => this.$db
            .videos((q) => q
            .where('workspaceId', '==', w.id)
            .where('status', '!=', VideoStatus.Deleted)
            .orderBy('status', 'asc')
            .orderBy('createdTime', 'desc'))
            .observe()
            .pipe(map((vids) => vids.map((v) => ({ ...v, workspace: w })))))).pipe(map((i) => i.flat()))));
        const videoSearch = FuzzySearch([], { keys: VIDEO_SEARCH_KEYS });
        const allVideosFuse$ = allVideos$.pipe(map((allVideos) => {
            videoSearch.setCollection(allVideos);
            return videoSearch;
        }), shareReplay(1));
        // channels
        const allChannels$ = userWorkspaces$.pipe(switchMap((workspaces) => combineLatest([...workspaces].map((w) => this.$db
            .channels((q) => q
            .where('workspaceId', '==', w.id)
            .where('status', '!=', ChannelStatus.Deleted)
            .orderBy('status', 'asc')
            .orderBy('createdTime', 'desc'))
            .observe()
            .pipe(map((chs) => chs.map((c) => ({ ...c, workspace: w })))))).pipe(map((i) => i.flat()))));
        const channelSearch = FuzzySearch([], {
            keys: CHANNEL_SEARCH_KEYS,
        });
        const allChannelsFuse$ = allChannels$.pipe(map((allChannels) => {
            channelSearch.setCollection(allChannels);
            return channelSearch;
        }), shareReplay(1));
        // workspaces
        const workspaceSearch = FuzzySearch([], {
            keys: WORKSPACE_SEARCH_KEYS,
        });
        const allWorkspacesFuse$ = userWorkspaces$.pipe(map((allWorkspaces) => {
            workspaceSearch.setCollection(allWorkspaces);
            return workspaceSearch;
        }), shareReplay(1));
        const debouncedSearch$ = search$.pipe(debounceTime(500));
        debouncedSearch$
            .pipe(tap((search) => {
            this.$data.searchDialog = !!search;
            this.$events.emit('globalSearch', { query: search });
        }))
            .subscribe();
        return {
            queriedVideos: debouncedSearch$.pipe(switchMap((query) => allVideosFuse$.pipe(map((videoFuse) => videoFuse.search(query).map(({ item }) => item)))), startWith([])),
            queriedChannels: debouncedSearch$.pipe(switchMap((query) => allChannelsFuse$.pipe(map((channelFuse) => channelFuse.search(query).map(({ item }) => item)))), startWith([])),
            queriedWorkspaces: debouncedSearch$.pipe(switchMap((query) => allWorkspacesFuse$.pipe(map((workspaceFuse) => workspaceFuse.search(query).map(({ item }) => item)))), startWith([])),
        };
    },
    mounted() {
        const pageBodyEl = document.getElementsByTagName('body').item(0);
        pageBodyEl?.addEventListener('click', this.onBodyClicked);
    },
    methods: {
        onBlur() {
            this.placeholder = 'Search';
            if (this.isXsScreen) {
                this.$sleep(250).then(() => {
                    this.showSearchButton = true;
                    this.$emit('show-other-navbar-items');
                });
            }
        },
        onClickSmallXsSearchPromptButton() {
            this.showSearchButton = false;
            this.$emit('xs-screen-search-prompt-button-clicked');
        },
        onBodyClicked() {
            if (this.isXsScreen && !this.showSearchButton) {
                this.$sleep(0).then(() => {
                    this.showSearchButton = true;
                    this.$emit('show-other-navbar-items');
                });
            }
        },
    },
    watch: {
        searchDialog(val) {
            if (!val) {
                this.search = '';
            }
        },
        isSmScreen: {
            handler(val) {
                this.showSearchButton = val;
            },
            immediate: true,
        },
    },
    filters: {
        extractWorkspaceName(workspace) {
            return workspace?.name ?? '';
        },
    },
    beforeRouteLeave(_to, _from, next) {
        next();
        this.searchDialog = false;
        this.$emit('show-other-navbar-items');
    },
    components: {
        GlobalSearchVideos,
        GlobalSearchChannels,
        GlobalSearchWorkspaces,
    },
});
