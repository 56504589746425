import Vue from 'vue';
import adminAPI from '@/services/adminAPI';
var ExportType;
(function (ExportType) {
    ExportType["Comments"] = "Comments";
    ExportType["Entities"] = "Entities";
})(ExportType || (ExportType = {}));
export default Vue.extend({
    name: 'CommentDataExport',
    props: {
        videoId: {
            type: String,
            required: true,
        },
        featureId: {
            type: String,
            required: true,
        },
    },
    data() {
        return {
            isCommentsDownloading: false,
            isEntitiesDownloading: false,
            snackbar: false,
            snackbarMessage: '',
            video: null,
        };
    },
    subscriptions() {
        return {
            video: this.$db.video(this.$props.videoId).observe(),
        };
    },
    computed: {
        ExportType() {
            return ExportType;
        },
        cannotExportCommentData() {
            return this.$ability.cannot('read', 'Elasticsearch.ExportAnalyticsData');
        },
    },
    methods: {
        downloadVideoCommentsData() {
            this.isCommentsDownloading = true;
            adminAPI(async (api) => api.video.exports.getVideoCommentsDataExport({
                vid: this.videoId,
                fid: this.featureId,
            }))
                .then((downloadData) => {
                this.downloadCSV(downloadData.csv, this.ExportType.Comments);
            })
                .catch((err) => {
                this.snackbar = true;
                this.snackbarMessage = err.message;
            })
                .finally(() => {
                this.isCommentsDownloading = false;
            });
        },
        downloadVideoCommentsEntitesData() {
            this.isEntitiesDownloading = true;
            adminAPI(async (api) => api.video.exports.getVideoCommentsEntitiesDataExport({
                vid: this.videoId,
                fid: this.featureId,
            }))
                .then((downloadData) => {
                this.downloadCSV(downloadData.csv, this.ExportType.Entities);
            })
                .catch((err) => {
                this.snackbar = true;
                this.snackbarMessage = err.message;
            })
                .finally(() => {
                this.isEntitiesDownloading = false;
            });
        },
        downloadCSV(csv, exportType) {
            const videoName = this.video?.title ?? '';
            const filename = `${videoName}_${new Date().toISOString()}_${exportType.toLowerCase()}.csv`;
            const data = encodeURIComponent(csv);
            this.$download(data, filename, { type: 'text/plain' });
            this.snackbar = true;
            this.snackbarMessage = `${this.ExportType[exportType]} data download is complete`;
        },
    },
});
