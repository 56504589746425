import type { AdminAPI } from '@lumiere/admin-api/src/api/admin-api'
import * as Sentry from '@sentry/browser'
import mapValues from 'lodash/mapValues'
import auth from '@/services/auth'
import config from '@lumiere/shared/config'
import logger from '@lumiere/shared/services/logger'
import { getBrowserQueryParam } from '@/utils/api'

// https://github.com/jonbern/fetch-retry
import FetchRetry from 'fetch-retry'

const fetch = FetchRetry(window.fetch, {
  retries: 2,
  retryDelay: 1000,
})

const apiHost = config.adminAppApiURL

const apiLogger = logger.extend('adminAPI')

const fetchJSON = async (url: string, req?: RequestInit) =>
  fetch(url, {
    mode: 'cors',
    ...req,
  }).then(
    async (fetchResponse) => {
      if (fetchResponse.ok) {
        return fetchResponse.json()
      }
      Sentry.captureMessage(fetchResponse.statusText, { tags: { url } })
      apiLogger.error(`💥 API call failed: ${fetchResponse.statusText}`)
      throw new Error(fetchResponse.statusText)
    },
    (reason) => {
      apiLogger.error('💥 Fetch request failed', reason)
      Sentry.captureException(reason, { tags: { url } })
      throw new Error(reason)
    },
  )

const processFunctionCall = (path: string[]) => async (...args: any[]) => {
  const name = path.join('.')
  const timeEnd = apiLogger.time(name, { args })
  return auth.getToken().then(async (token) => {
    const link = getBrowserQueryParam('link')

    return fetchJSON(`${apiHost}/${path.join('/')}`, {
      body: JSON.stringify({
        args,
        authorization: { token, link },
        referrer: location.href,
      }),
      method: 'POST',
      headers: {
        /** Authorization: token

            Fetch API draws distinction between 'simple' and 'complex' requests.
            The difference is that for complex requests a pre-flight OPTIONS request is required
            We want to avoid the pre-flight request as it delays the request we need to make
            To make the request simple we are limited to a set number of headers and data-types we can send
            Authorization header is not one of the allowed headers, thus the token was moved into request body
            More info here: https://javascript.info/fetch-crossorigin

         */
        'Content-Type': 'text/plain',
      },
    }).then(({ ok, error }) => {
      timeEnd({ response: ok || error })
      if (error) {
        apiLogger.error(`💩 Error in response`, error)
        Sentry.captureMessage(error, { tags: { name } })
        throw new Error(error)
      }
      return ok
    })
  })
}

const modelToFetchApi = (
  node: Object | String,
  path: string[] = [],
): AdminAPI => {
  return ((typeof node === 'object'
    ? mapValues(node, (v, key) => modelToFetchApi(v, [...path, key]))
    : processFunctionCall(path)) as unknown) as AdminAPI
}

const apiPromise: Promise<AdminAPI> = fetchJSON(apiHost).then(modelToFetchApi)

const adminAPI = async <T>(cb: (api: AdminAPI) => T | Promise<T>): Promise<T> =>
  apiPromise.then(cb)

export default adminAPI
