import Vue from 'vue';
import { ACTIONS } from '@/store/store.actions';
import { FileUploadStatus } from '@/store/store.state';
import { MUTATIONS } from '@/store/store.mutations';
import { mapGetters } from 'vuex';
import isEmpty from 'lodash/isEmpty';
export default Vue.extend({
    data() {
        return {
            hoveredItem: null,
        };
    },
    computed: {
        fileUploads() {
            return this.$store.state.fileUploads;
        },
        ...mapGetters(['getWorkspaceColor']),
        hasFilesToUpload() {
            return !isEmpty(this.fileUploads);
        },
        fileCount() {
            return Object.values(this.fileUploads).filter((item) => ![FileUploadStatus.ERROR, FileUploadStatus.CANCELED].includes(item.status)).length;
        },
        remainingUploadCount() {
            return this.fileCount - this.completedUploads.length;
        },
        completedUploads() {
            let completed = [];
            for (let id in this.fileUploads) {
                if (this.fileUploads[id].status === FileUploadStatus.COMPLETED) {
                    completed.push(this.fileUploads[id]);
                }
            }
            return completed;
        },
        numberErroredUploads() {
            let errored = 0;
            for (let id in this.fileUploads) {
                if (this.fileUploads[id].status === FileUploadStatus.ERROR) {
                    errored++;
                }
            }
            return errored;
        },
        allUploadIsCompleted() {
            return this.remainingUploadCount === 0 && this.numberErroredUploads == 0;
        },
        uploaderWidth() {
            return this.$vuetify.breakpoint.width < 420 ? 280 : 400;
        },
        fileUploadStatus() {
            return FileUploadStatus;
        },
    },
    methods: {
        cancelUpload(id) {
            this.$store.dispatch(ACTIONS.CANCEL_FILE_UPLOAD, id);
        },
        clearUploads() {
            this.$store.commit(MUTATIONS.CLEAR_FILE_UPLOADS);
        },
    },
    filters: {
        sanitizeMessage(text) {
            return text.replace(/Firebase .*:/gi, '').trim();
        },
    },
    watch: {
        allUploadIsCompleted(val) {
            if (val) {
                this.$sleep(2000).then(() => {
                    this.clearUploads();
                });
            }
        },
    },
});
