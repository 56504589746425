import Vue from 'vue';
import ValueSkeletonLoader from '../ValueSkeletonLoader.vue';
export default Vue.extend({
    name: 'ChannelMetricsHeader',
    props: {
        channelId: String,
        channelVideos: {
            type: Array,
        },
        channelMetrics: {
            type: Object,
        },
        fetchingMetrics: Boolean,
    },
    data() {
        return {
            metrics: null,
        };
    },
    computed: {
        totalVideoDuration() {
            return this.channelVideos.reduce((acc, cur) => acc + cur.duration, 0);
        },
        unique_viewers() {
            return this.metrics?.unique_viewers || 0;
        },
        total_video_views() {
            return this.metrics?.total_video_views || 0;
        },
        total_viewing_time() {
            return this.metrics?.total_viewing_time || 0;
        },
        avg_retention() {
            if (this.totalVideoDuration * this.unique_viewers === 0) {
                return 0;
            }
            else {
                return ((this.total_viewing_time * 100) /
                    (this.totalVideoDuration * this.unique_viewers));
            }
        },
        avg_views() {
            if (this.unique_viewers === 0) {
                return 0;
            }
            else {
                return this.total_viewing_time / this.unique_viewers;
            }
        },
    },
    methods: {},
    filters: {
        avg_retention_percent(val) {
            return `${val.toFixed(2)}%`;
        },
    },
    watch: {
        channelMetrics: {
            handler(metrics) {
                this.metrics = metrics;
            },
            immediate: true,
        },
    },
    components: {
        ValueSkeletonLoader,
    },
});
