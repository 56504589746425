import Vue from 'vue';
import BaseEmoji from '@lumiere/shared/components/Emoji.vue';
export default Vue.extend({
    name: 'AdminEmoji',
    props: {
        id: {
            type: String,
            required: true,
        },
        name: {
            type: String,
        },
        size: {
            type: Number,
            default: 64,
        },
        toolTipPosition: {
            type: String,
            default: 'top',
        },
        downloadURL: {
            type: String,
            required: false,
        },
        isPicked: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        tooltip() {
            return this.toolTipPosition;
        },
    },
    components: {
        BaseEmoji,
    },
});
