import Vue from 'vue';
import LumiereVideo, { LumiereApps, VideoState, } from '@lumiere/video';
import { AssetType, VideoStatus, } from '@lumiere/db/types';
import VideoPlayerAdminModeToggle, { VideoMode, } from './VideoPlayerAdminModeToggle.vue';
import VideoPlayerAdminMenu from './VideoPlayerAdminMenu.vue';
import MUTATIONS from '@/store/MUTATIONS';
import { VideoRoutes, ShareRoutes } from '@/router/router';
import VisualizationsContainer from '../visualizations/VisualizationsContainer.vue';
import adminAPI from '@/services/adminAPI';
export default Vue.extend({
    name: 'VideoPlayerAdmin',
    props: {
        video: {
            type: Object,
            required: true,
        },
        features: Array,
    },
    data() {
        return {
            videoMode: VideoMode.VIEWER_EXPERIENCE,
            selectedTaggedFeature: null,
            currentTime: 0,
            state: null,
        };
    },
    computed: {
        VideoMode() {
            return VideoMode;
        },
        appName() {
            return LumiereApps.ADMIN_APP;
        },
        spatiallyTaggedFeatures() {
            return (this.features?.filter(({ options }) => {
                return (!!options &&
                    'feedbackOptions' in options &&
                    !!options.feedbackOptions
                        ?.spacialTagging);
            }) ?? []).concat(this.spatiallyTaggedExternalDataFeatures);
        },
        isInsightsTab() {
            const insightRoutes = [
                VideoRoutes.Insights,
                ShareRoutes.ShareInsights,
            ];
            return insightRoutes.includes(this.$route.name ?? '');
        },
        showOnVideoViz() {
            return (this.videoMode === VideoMode.OUTPUT // && this.isInsightsTab && !!this.selectedTaggedFeature
            );
        },
        spatiallyTaggedExternalDataFeatures() {
            return (this.features?.filter(({ options }) => {
                const { locationX, locationY } = options;
                return locationX && locationY;
            }) ?? []);
        },
        lumiereVideo() {
            return () => this.$refs.lumiereVideo;
        },
        isViewerExperience() {
            return this.videoMode === VideoMode.VIEWER_EXPERIENCE;
        },
        isProcessing() {
            return this.video.status === VideoStatus.Processing;
        },
        isReady() {
            return this.video.status === VideoStatus.Ready;
        },
    },
    watch: {
        '$route.query.feature': {
            handler(feature) {
                if (feature) {
                    this.selectedTaggedFeature =
                        this.spatiallyTaggedFeatures.find((el) => el.id === feature) || null;
                }
            },
            immediate: true,
        },
        '$route.query.vctime': {
            handler(t) {
                if (t) {
                    this.$nextTick(() => {
                        const lumiereVideo = this.lumiereVideo();
                        lumiereVideo?.seekTo(+t);
                        if (this.state === VideoState.Ready) {
                            // lumiereVideo?.updateMuted({ value: true })
                            lumiereVideo
                                ?.play()
                                ?.then(() => {
                                lumiereVideo?.pause();
                                lumiereVideo?.updateMuted({ value: false });
                            })
                                .catch((_) => null);
                        }
                    });
                }
            },
        },
        '$route.query.cid': {
            handler(c, oc) {
                if (c || oc) {
                    const lumiereVideo = this.lumiereVideo();
                    lumiereVideo?.refreshFeatureData();
                }
            },
        },
        '$route.query.standalone_video_filter': {
            handler(v, ov) {
                if (v || ov) {
                    const lumiereVideo = this.lumiereVideo();
                    lumiereVideo?.refreshFeatureData();
                }
            },
        },
    },
    methods: {
        async getVideoSrcPromise() {
            return adminAPI((api) => api.video.getSignedURL({
                vid: this.video.id,
                assetType: AssetType.video,
            }));
        },
        onTimeUpdate(t) {
            this.currentTime = t;
            this.$store.commit(MUTATIONS.SET_VIDEO_TIME, t);
        },
        onStateUpdate(state) {
            this.state = state;
            this.$store.commit(MUTATIONS.SET_VIDEO_STATE, state);
        },
        updateRouteQuery() {
            const query = this.$route.query;
            if (query.vctime || query.commentId)
                // eslint-disable-next-line @typescript-eslint/no-floating-promises
                this.$router.replace({
                    query: {
                        ...query,
                        vctime: null,
                        commentId: null,
                    },
                });
        },
    },
    components: {
        LumiereVideo,
        VideoPlayerAdminModeToggle,
        VideoPlayerAdminMenu,
        VisualizationsContainer,
    },
});
