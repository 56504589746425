import Vue from 'vue';
import { AssetType } from '@lumiere/db/types';
import adminAPI from '@/services/adminAPI';
export default Vue.extend({
    name: 'VideoPlayerAdminMenu',
    props: {
        currentTime: Number,
        video: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            downloading: false,
        };
    },
    computed: {},
    watch: {},
    methods: {
        async downloadCurrentFrame() {
            this.downloading = true;
            const imgURL = await adminAPI(async (api) => api.video.getSignedURL({
                vid: this.video.id,
                assetType: AssetType.thumbnail,
                params: { time: this.currentTime },
            }));
            const filename = `${this.video.title}-${this.currentTime}.png`;
            const href = await fetch(imgURL)
                .then(async (response) => response.blob())
                .then((blob) => URL.createObjectURL(blob));
            this.$download(href, filename);
            this.downloading = false;
        },
    },
});
