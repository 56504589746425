var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{ref:"feedbackContainer",class:[
    'emoji-feedbacks-container',
    { hide: _vm.hide },
    ("emoji-feedbacks-container__" + _vm.size),
    _vm.scaleFeaturesTofit ]},_vm._l((_vm.emojiFeaturesByPosition),function(emojiFeatures,position){return _c('div',{key:position,class:("emoji-feedbacks-container__" + position)},_vm._l((emojiFeatures),function(ref){
  var fid = ref.id;
  var options = ref.options;
return _c('EmojiFeedback',{key:fid,ref:"emojiFeedback",refInFor:true,attrs:{"emojis":options.emojis,"prompt":options.prompt,"feedbackOptions":options.feedbackOptions,"size":_vm.size,"videoId":_vm.videoId,"featureId":fid,"appName":_vm.appName,"flyOutEmojis":_vm.getFlyOutEmojis(fid),"currentTime":_vm.currentTime,"isViewerExperience":_vm.isViewerExperience},on:{"feedback":function (feedback) { return _vm.$emit('feedback', {
            type: 'emoji',
            fid: fid,
            payload: Object.assign({}, feedback, {currentTime: _vm.currentTime}),
          }); }}})}),1)}),0)}
var staticRenderFns = []

export { render, staticRenderFns }