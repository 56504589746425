import { mapState } from 'vuex';
import Vue from 'vue';
export default Vue.extend({
    name: 'UserMenuList',
    computed: {
        ...mapState(['user']),
    },
    methods: {
        async logout() {
            await this.$auth.signOut();
        },
    },
});
