import truncateText from 'lodash/truncate';
export function formatTimeMillis(t, showMilliseconds = false) {
    const hours = Math.floor(t / 60 / 60);
    const minutes = Math.floor((t / 60) % 60);
    const seconds = Math.floor(t % 60);
    const millisecs = showMilliseconds
        ? Number((t - Math.floor(t)).toFixed(1)) * 10
        : 0;
    const theTime = (hours < 1 ? '' : hours + ':') +
        (minutes < 10 ? '0' + minutes : minutes) +
        ':' +
        (seconds < 10 ? '0' + seconds : seconds) +
        (showMilliseconds ? '.' + millisecs : '');
    return theTime;
}
export function initials(value, length = 2) {
    return value
        .split(' ')
        .map(([v]) => v)
        .join('')
        .substring(0, length)
        .toUpperCase();
}
export function truncate(text, length = 25) {
    return truncateText(text, {
        length,
        separator: /,? +/,
    });
}
