import './init';
import firebase from 'firebase/app';
import 'firebase/storage';
export const { TaskState, TaskEvent } = firebase.storage;
// define storage file metadata
export const FileMetadata = {
    cacheControl: 'public,max-age=31536000',
    customMetadata: {
        origin: location.origin,
    },
};
export default firebase.storage();
