import Vue from 'vue';
export default Vue.extend({
    name: 'ChannelColor',
    props: {
        channel: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            pickerModel: false,
        };
    },
    computed: {},
    watch: {},
    methods: {
        updateColor(color) {
            this.$emit('change', color);
            this.pickerModel = false;
            this.$db.channel(this.channel.id).update({ color });
        },
    },
    subscriptions() {
        return {};
    },
    components: {},
});
