import { Role, TeamRole } from '@lumiere/db/types';
export var AbilityModels;
(function (AbilityModels) {
    AbilityModels["USER"] = "User";
    AbilityModels["WORKSPACE"] = "Workspace";
    AbilityModels["WORKSPACE_EMOJI"] = "Workspace.Emoji";
    AbilityModels["VIDEO"] = "Video";
    AbilityModels["VIDEO_FEATURE"] = "Video.Feature";
    AbilityModels["CHANNEL"] = "Channel";
    AbilityModels["LINK"] = "Link";
    AbilityModels["TEAM"] = "Team";
    AbilityModels["VIDEO_PERSON"] = "Video.Person";
})(AbilityModels || (AbilityModels = {}));
export const LumiereModels = Object.values(AbilityModels);
export const LumiereRoles = Object.values(Role);
export const LumiereTeamRoles = Object.values(TeamRole);
