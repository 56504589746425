import urlJoin from 'url-join';
import config from '@lumiere/shared/config';
import auth from '@/services/auth';
export default {
    isAuthenticated(state) {
        return !!state.uid;
    },
    workspacesList({ workspaces }) {
        return workspaces && Object.values(workspaces);
    },
    sortedRecentWorkspaces({ recentWorkspaces, workspaces }) {
        return workspaces
            ? Object.keys(recentWorkspaces)
                .sort((a, z) => recentWorkspaces[z] - recentWorkspaces[a])
                .map((wid) => workspaces[wid])
                .filter((w) => !!w)
            : [];
    },
    currentWorkspace({ workspaces, route }) {
        const { workspaceId } = route.params;
        return workspaces ? workspaces[workspaceId] : undefined;
    },
    getLastWorkspaceId(_, { currentWorkspace, sortedRecentWorkspaces }) {
        return () => {
            return currentWorkspace
                ? currentWorkspace.id
                : sortedRecentWorkspaces[0].id;
        };
    },
    workspaceTags(_state, { currentWorkspace }) {
        const tags = currentWorkspace?.tags || {};
        return Object.keys(tags)
            .sort((a, b) => tags[a].nonce - tags[b].nonce)
            .map((id) => ({ ...tags[id], id }));
    },
    defaultWorkspace(_state, getters) {
        if (getters.sortedRecentWorkspaces[0]) {
            return getters.sortedRecentWorkspaces[0].id;
        }
        else if (getters.workspacesList) {
            return getters.workspacesList[0]?.id;
        }
    },
    videoURL() {
        return (videoId) => urlJoin(config.viewerAppURL, 'v', videoId);
    },
    channelURL() {
        return (channelId) => urlJoin(config.viewerAppURL, 'c', channelId);
    },
    getWorkspaceColor({ workspaces }) {
        return (workspaceId) => {
            return workspaces?.[workspaceId]?.theme.color ?? null;
        };
    },
    async getUser() {
        const user = await auth.getCurrentUser();
        return user;
    },
    userRoleInWorkspace({ uid }, { currentWorkspace }) {
        if (!uid || !currentWorkspace)
            return null;
        return currentWorkspace?.roles[uid];
    },
    currentTeam({ team }) {
        return team;
    },
};
