import config from '@lumiere/shared/config';
import logger from '@lumiere/shared/services/logger';
import { BrowserConsoleTransport } from '@lumiere/shared/services/logger/transports/BrowserConsoleTransport';
import { SentryBrowserTransport } from '@lumiere/shared/services/logger/transports/SentryBrowserTransport';
logger.init({
    enabled: true,
    namespace: 'admin-app',
    context: {
        version_id: process.env.VUE_APP_COMMIT_SHA,
    },
    transports: config.name === 'prod'
        ? [SentryBrowserTransport()]
        : config.name === 'dev'
            ? [SentryBrowserTransport(), BrowserConsoleTransport()]
            : [BrowserConsoleTransport()],
});
