import Vue from 'vue';
import GlobalSearchChannelPreviewCard from './GlobalSearchChannelPreviewCard.vue';
import { ChannelRoutes } from '@/router/router';
export default Vue.extend({
    name: 'GlobalSearchChannels',
    props: {
        channels: Array,
    },
    methods: {
        goToChannelPage(channel) {
            if (this.$ability.can('read', 'Channel')) {
                this.$router.push({
                    name: ChannelRoutes.Default,
                    params: {
                        channelId: channel.id,
                        channelProp: channel,
                        workspaceId: channel.workspaceId,
                    },
                });
            }
            else {
                location.href = this.$store.getters.channelURL(channel.id);
            }
            this.$sleep(0).then(() => {
                this.$emit('close-menu');
            });
        },
    },
    components: {
        GlobalSearchChannelPreviewCard,
    },
});
