import Vue from 'vue';
import FeatureCard from '@/components/video/features/FeatureCard.vue';
import FeatureAddWizard from '@/components/video/features/FeatureAddWizard.vue';
import { startWith, map } from 'rxjs/operators';
export default Vue.extend({
    name: 'VideoFeatures',
    props: {
        videoId: {
            type: String,
            required: true,
        },
        showTabComponent: {
            type: Function,
            required: true,
        },
    },
    data() {
        return {
            addFeatureDialog: false,
            features: null,
        };
    },
    components: {
        FeatureCard,
        FeatureAddWizard,
    },
    subscriptions() {
        const features = this.$db
            .video(this.$props.videoId)
            .features((ref) => ref.orderBy('createdTime'))
            .observe()
            .pipe(map((items) => items.filter((item) => !item.deletedTime)), startWith(null));
        return {
            features,
        };
    },
    watch: {
        features: {
            handler(val, oldVal) {
                if (val?.length === 1 && oldVal?.length === 0) {
                    this.addFeatureDialog = false;
                }
            },
        },
    },
});
