// Inspired by https://github.com/jgile/vue-csv-import
import Vue from 'vue';
import Papa from 'papaparse';
import drop from 'lodash/drop';
import every from 'lodash/every';
import forEach from 'lodash/forEach';
import get from 'lodash/get';
import map from 'lodash/map';
import set from 'lodash/set';
import logger from '@lumiere/shared/services/logger';
export default Vue.extend({
    props: {
        // value: [Array, Object],
        callback: {
            type: Function,
            default() {
                return () => { };
            },
        },
        catch: {
            type: Function,
            default() {
                return () => { };
            },
        },
        finally: {
            type: Function,
            default() {
                return () => { };
            },
        },
        parseConfig: {
            type: Object,
            default() {
                return {};
            },
        },
        headers: {
            type: Boolean,
            default: null,
        },
        loadBtnText: {
            type: String,
            default: 'Next',
        },
        submitBtnText: {
            type: String,
            default: 'Submit',
        },
        autoMatchFields: {
            type: Boolean,
            default: false,
        },
        autoMatchIgnoreCase: {
            type: Boolean,
            default: false,
        },
        tableClass: {
            type: String,
            default: 'table',
        },
        checkboxClass: {
            type: String,
            default: 'form-check-input',
        },
        buttonClass: {
            type: String,
            default: 'btn btn-primary',
        },
        inputClass: {
            type: String,
            default: 'form-control-file',
        },
        validation: {
            type: Boolean,
            default: true,
        },
        fileMimeTypes: {
            type: Array,
            default: () => {
                return [
                    'text/csv',
                    'text/x-csv',
                    'application/vnd.ms-excel',
                    'text/plain',
                ];
            },
        },
        tableSelectClass: {
            type: String,
            default: 'form-control',
        },
        canIgnore: {
            type: Boolean,
            default: false,
        },
        enableSubmit: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            form: {
                json: null,
            },
            fieldsToMap: [],
            map: {},
            hasHeaders: true,
            csv: null,
            sample: null,
            isValidFileMimeType: false,
            fileSelected: false,
            file: null,
            errorMap: {},
        };
    },
    mounted() {
        this.hasHeaders = this.headers;
        this.fieldsToMap = this.mapFields.map((f) => ({
            ...f,
            key: f.text,
            label: f.text,
        }));
    },
    computed: {
        firstRow() {
            return get(this, 'sample.0');
        },
        showErrorMessage() {
            return this.fileSelected && !this.isValidFileMimeType;
        },
        disabledNextButton() {
            return !this.isValidFileMimeType;
        },
        isAllRequiredFieldSelected() {
            const requiredFields = this.mapFields.filter((f) => f.required);
            return every(requiredFields, (f) => this.map[f.text] != null);
        },
        mapFields() {
            return [
                {
                    text: 'text',
                    value: 'text',
                    required: true,
                    description: 'Text field for analysis',
                },
                {
                    text: 'eid',
                    value: 'eid',
                    required: false,
                    description: 'External user id field',
                },
                {
                    text: 'cid',
                    value: 'cid',
                    required: false,
                    description: 'Channel id field, if this data is associated with a specific channel',
                },
                {
                    text: 'videoTime',
                    value: 'videoTime',
                    required: false,
                    description: 'Video timestamp for this data (in total seconds format as 0.0)',
                },
                {
                    text: 'rating',
                    value: 'rating',
                    required: false,
                    description: 'Rating value (whole number from 0 to 10)',
                },
                {
                    text: 'locationX',
                    value: 'locationX',
                    required: false,
                    description: 'On-video x-axis location (0-1 value from bottom left origin)',
                },
                {
                    text: 'locationY',
                    value: 'locationY',
                    required: false,
                    description: 'On-video y-axis location (0-1 value from bottom origin)',
                },
            ];
        },
    },
    methods: {
        submit() {
            if (!this.isAllRequiredFieldSelected) {
                this.$emit('input', { json: [] });
                return;
            }
            const mappedCsv = this.buildMappedCsv();
            this.form.json = mappedCsv.result;
            const fieldToImport = this.mapFields.reduce((acc, f) => ({ ...acc, [f.text]: this.map[f.text] != null }), {});
            // write simple validation for import fields
            const validationMap = mappedCsv.validationMap;
            this.errorMap = {};
            const errorMap = this.errorMap;
            for (const [f] of Object.entries(this.map)) {
                // logger.info('Iterating Object.entries', { [f]: validationMap[f] })
                if (validationMap[f] == null) {
                    errorMap[f] = `${f} field does not have valid entries`;
                }
            }
            this.errorMap = errorMap;
            if (Object.keys(errorMap).length) {
                this.$emit('input', { json: [] });
                return;
            }
            this.$emit('input', { json: this.form.json, ...fieldToImport });
            this.callback(this.form.json);
        },
        buildMappedCsv() {
            const csv = this.hasHeaders ? drop(this.csv) : this.csv;
            const validationMap = {};
            const result = map(csv, (row) => {
                let newRow = {};
                forEach(this.map, (column, field) => {
                    const cellVal = get(row, column);
                    const grc = cellVal?.toString() ?? null;
                    set(newRow, field, grc);
                    if (validationMap[field] == null) {
                        validationMap[field] = grc;
                    }
                });
                return newRow;
            });
            return { result, validationMap };
        },
        validFileMimeType(file) {
            if (file) {
                const mimeType = file.type === '' ? 'application/octet-stream' : file.type;
                this.fileSelected = true;
                if (mimeType === 'application/octet-stream') {
                    this.isValidFileMimeType = true;
                }
                else if (this.validation) {
                    this.isValidFileMimeType = this.fileMimeTypes.indexOf(mimeType) > -1;
                }
                else {
                    this.isValidFileMimeType = true;
                }
            }
            else {
                this.isValidFileMimeType = !this.validation;
                this.fileSelected = false;
            }
            this.file = file;
        },
        load() {
            this.readFile((output) => {
                this.sample = get(Papa.parse(output, {
                    preview: 2,
                    skipEmptyLines: true,
                    dynamicTyping: true,
                }), 'data');
                this.csv = get(Papa.parse(output, { skipEmptyLines: true, dynamicTyping: true }), 'data');
            });
        },
        readFile(callback) {
            let file = this.file;
            if (file) {
                let reader = new FileReader();
                reader.readAsText(file, 'UTF-8');
                reader.onload = function ({ target }) {
                    if (target?.result) {
                        callback(target.result.toString());
                    }
                    else if (file) {
                        callback(file);
                    }
                    else {
                        callback('');
                    }
                };
                reader.onerror = function (err) {
                    logger.error('FileReader', err);
                };
            }
            else {
                callback('');
            }
        },
        toggleHasHeaders() {
            this.hasHeaders = !this.hasHeaders;
        },
        updateSelection(v, index) {
            const map = { ...this.map };
            const indexOfField = this.firstRow.indexOf(v);
            if (indexOfField > -1) {
                map[this.fieldsToMap[index].key] = indexOfField;
            }
            else {
                delete map[this.fieldsToMap[index].key];
            }
            this.map = map;
            this.$nextTick(() => {
                if (!this.enableSubmit) {
                    this.submit();
                }
            });
        },
    },
    filters: {
        transformRequired(val) {
            return val ? '(required)' : '(optional)';
        },
    },
    watch: {
        sample: {
            handler(newVal) {
                if (newVal && this.autoMatchFields) {
                    this.fieldsToMap.forEach((field) => {
                        newVal[0].forEach((columnName, index) => {
                            if (this.autoMatchIgnoreCase === true) {
                                if (field.label.toLowerCase().trim() ===
                                    columnName.toLowerCase().trim()) {
                                    this.$set(this.map, field.key, index);
                                }
                            }
                            else {
                                if (field.label.trim() === columnName.trim()) {
                                    this.$set(this.map, field.key, index);
                                }
                            }
                        });
                    });
                }
            },
        },
    },
});
