import Vue from 'vue';
export default Vue.extend({
    name: 'RxConnect',
    props: {
        subscriptions: {
            type: Object,
            required: true,
        },
    },
    subscriptions() {
        return this.$props.subscriptions;
    },
    computed: {
        slotProps() {
            const self = this;
            return Object.keys(this.subscriptions).reduce((v, sub) => ({ ...v, [sub]: self[sub] }), {});
        },
    },
    render() {
        return this.$scopedSlots.default(this.slotProps)[0];
    },
});
