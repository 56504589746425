import Vue from 'vue';
export default Vue.extend({
    name: 'FeatureDeleteDialog',
    props: {
        deletingFeature: {
            type: Boolean,
            default: false,
        },
        showDialog: {
            type: Boolean,
        },
        featureId: {
            type: String,
            required: true,
        },
    },
    data() {
        return {
            dialog: false,
            confirmation: '',
        };
    },
    computed: {
        allowDelete() {
            return this.confirmation === 'DELETE';
        },
    },
    watch: {
        showDialog(val) {
            this.dialog = val;
        },
        dialog(val) {
            if (val === false) {
                this.$emit('dismiss-dialog');
            }
        },
    },
});
