import { Role, ChannelOutputsEnum } from '@lumiere/db/types';
import { startWith, shareReplay, filter } from 'rxjs/operators';
import VideoCard from '@/components/workspace/videos/VideoCard.vue';
import { VideoRoutes } from '@/router/router';
import ChannelSidePanel from '@/components/channel/ChannelSidePanel.vue';
import ChannelMetricsHeader from './ChannelMetricsHeader.vue';
import ChannelMetricsTable from './ChannelMetricsTable.vue';
import { mapGetters, mapState } from 'vuex';
import publicDB from '@/utils/publicDB';
import isEqual from 'lodash/isEqual';
import adminAPI from '@/services/adminAPI';
import mixins from 'vue-typed-mixins';
import ThemeColor from '@/utils/ThemeColor';
export default mixins(ThemeColor).extend({
    name: 'Channel',
    props: {
        workspaceId: { type: String, required: true },
        channelId: {
            type: String,
            required: true,
        },
        channelCache: {
            type: Object,
            default: null,
        },
    },
    data() {
        return {
            saving: false,
            selectingVideos: false,
            selectedVideos: [],
            channel: null,
            isAbilityResolved: !!this.$ability.relevantRuleFor('read', 'Video.File'),
            channelMetrics: null,
            fetchingMetrics: false,
            allVideos: [],
            channelVideos: [],
            fetchingVideos: false,
            addVideosToChannelFirstTime: false,
        };
    },
    computed: {
        ...mapState(['uid', 'link']),
        ...mapGetters(['currentWorkspace', 'userRoleInWorkspace']),
        channelURL() {
            return this.$store.getters.channelURL(this.channelId);
        },
        isSelected() {
            return (videoId) => this.selectedVideos.includes(videoId);
        },
        videoURL() {
            return (video) => this.$ability.can('read', 'Video')
                ? {
                    name: VideoRoutes.Insights,
                    params: {
                        videoId: video.id,
                        videoProp: video,
                    },
                }
                : this.$store.getters.videoURL(video.id);
        },
        routeLinkType() {
            return this.$ability.can('read', 'Video') ? 'to' : 'href';
        },
        textColor() {
            return this.$vuetify.breakpoint.mdAndUp ? 'white--text' : 'black--text';
        },
        isPublic() {
            return !!this.$route.query.link;
        },
        allOutputsDisabled() {
            if (this.userRoleInWorkspace) {
                return false;
            }
            if (this.isPublic && this.link) {
                const configOutputs = this.link.configuration?.outputs;
                if (configOutputs) {
                    return (configOutputs.filter((el) => el !== ChannelOutputsEnum.CHANNEL_VIEWS).length === 0);
                }
            }
            return true;
        },
        canDrillToVideo() {
            if (this.userRoleInWorkspace) {
                return true;
            }
            // need a little more info to continue
            // if (this.isPublic && this.link) {
            //   const configOptions = this.link.configuration?.pageOptions?.options
            //   if (configOptions) {
            //     return (configOptions as ChannelPageOptionsEnum[]).includes(
            //       ChannelPageOptionsEnum.SHOW_CHANNEL_LINK,
            //     )
            //   }
            // }
            return false;
        },
    },
    mounted() {
        if (!this.isAbilityResolved) {
            const unsubscribe = this.$ability.on('updated', (_res) => {
                this.isAbilityResolved = true;
                unsubscribe();
            });
        }
    },
    watch: {
        'uid': {
            handler(uid) {
                if (uid && this.channelId) {
                    this.loadMetrics();
                }
            },
            immediate: true,
        },
        'channel.eids': {
            handler(eids, oldEids) {
                if (!isEqual(eids, oldEids)) {
                    // refresh the data with the new eids channel filter
                    setTimeout(() => {
                        this.loadMetrics();
                    }, 1000);
                }
            },
        },
        '$route.query.link': {
            handler(link) {
                if (!this.uid && link && this.channelId) {
                    this.loadMetrics();
                }
            },
            immediate: true,
        },
    },
    methods: {
        async startSelectingVideos() {
            await this.getWorkspaceVideos();
            this.selectedVideos = this.channelVideos.map(({ id }) => id);
            this.selectingVideos = true;
        },
        async saveSelectedVideos() {
            const database = this.isPublic ? publicDB() : this.$db;
            this.saving = true;
            await database
                .channel(this.channelId)
                .update({ videos: this.selectedVideos })
                .finally(() => this.getChannelVideos());
            this.saving = false;
            this.selectingVideos = false;
        },
        async loadMetrics() {
            this.fetchingMetrics = true;
            this.channelMetrics = await adminAPI((api) => api.channel.getChannelMetrics({ cid: this.channelId })).finally(() => {
                this.fetchingMetrics = false;
            });
        },
        async getWorkspaceVideos() {
            this.fetchingVideos = true;
            const { workspaceVideos } = await adminAPI((api) => api.workspace.getWorkspaceVideos({
                wid: this.$props.workspaceId,
            }));
            this.allVideos = workspaceVideos ?? [];
            this.fetchingVideos = false;
        },
        ////
        async getChannelVideos() {
            this.fetchingVideos = true;
            const { channelVideos } = await adminAPI((api) => api.channel.getChannelVideos({
                cid: this.channelId,
            }));
            this.channelVideos = channelVideos ?? [];
            this.fetchingVideos = false;
        },
        onVideoRemoved(_video) {
            this.getChannelVideos();
        },
    },
    created() {
        this.getChannelVideos();
    },
    subscriptions() {
        const { isPublic } = this;
        const database = isPublic ? publicDB() : this.$db;
        const channel$ = database
            .channel(this.$props.channelId)
            .observe()
            .pipe(startWith(this.$props.channelCache), filter((v) => Boolean(v)), shareReplay(1));
        return {
            channel: channel$,
        };
    },
    beforeRouteEnter(_to, _from, next) {
        next((vm) => {
            const isViewer = vm.$store.getters.userRoleInWorkspace === Role.VIEWER;
            if (isViewer) {
                // @ts-ignore
                const channelId = vm.$props?.channelId ?? vm.channelId;
                next(`/${vm.$route.params.workspaceId}/channels`);
                if (performance.navigation.type != 2) {
                    window.location.href = vm.$store.getters.channelURL(channelId);
                }
            }
            else {
                next();
            }
        });
    },
    components: {
        VideoCard,
        ChannelSidePanel,
        ChannelMetricsHeader,
        ChannelMetricsTable,
    },
});
