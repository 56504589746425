import Vue from 'vue';
import { ChoiceQuestionVariant, } from '@lumiere/db/types';
import { OTHER_LABEL } from '@lumiere/db/src/constants';
import { knuthShuffle } from 'knuth-shuffle';
function composeResponseItem(id, label, text) {
    return { id, ...(label === OTHER_LABEL ? { text } : {}) };
}
export default Vue.extend({
    name: 'QuestionFeedbackChoice',
    props: {
        options: Object,
        response: {
            type: Object,
        },
    },
    data() {
        return {
            singleChoiceResponse: null,
            multiChoiceResponses: [],
            otherInput: '',
        };
    },
    computed: {
        ChoiceQuestionVariant: () => ChoiceQuestionVariant,
        responseOptions() {
            let options = this.options.responseOptions.filter(({ isOther }) => !isOther);
            if (this.options.randomizeResponseOrder) {
                options = knuthShuffle(options);
            }
            return this.otherResponseOption
                ? [...options, this.otherResponseOption]
                : options;
        },
        otherResponseOption() {
            return this.options.responseOptions.find(({ isOther, isActive }) => isOther && isActive);
        },
        isSingleChoice() {
            return this.options.variant === ChoiceQuestionVariant.Single;
        },
        isMultipleChoice() {
            return this.options.variant === ChoiceQuestionVariant.Multiple;
        },
        otherOptionSelected() {
            return this.isSingleChoice
                ? this.singleChoiceResponse?.label === OTHER_LABEL
                : !!this.multiChoiceResponses.find((r) => r.label === OTHER_LABEL);
        },
    },
    watch: {
        otherOptionSelected: {
            handler(v) {
                if (v) {
                    this.$nextTick(() => {
                        const inputRef = this.$refs.otherOptionInput;
                        inputRef?.focus();
                    });
                }
            },
        },
        singleChoiceResponse: {
            handler(response) {
                this.emitSingleChoiceResponse(response, this.otherInput);
            },
        },
        multiChoiceResponses: {
            handler(responses) {
                this.emitMultiChoiceResponses(responses, this.otherInput);
            },
        },
        otherInput: {
            handler(text) {
                if (this.singleChoiceResponse) {
                    this.emitSingleChoiceResponse(this.singleChoiceResponse, text);
                }
                else {
                    this.emitMultiChoiceResponses(this.multiChoiceResponses, text);
                }
            },
        },
    },
    methods: {
        emitSingleChoiceResponse({ id, label }, text) {
            this.$emit('input', {
                options: [composeResponseItem(id, label, text)],
            });
        },
        emitMultiChoiceResponses(responses, text) {
            this.$emit('input', {
                options: responses.map(({ id, label }) => composeResponseItem(id, label, text)),
            });
        },
    },
    components: {},
});
