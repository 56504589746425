var render = function () {
var this$1 = this;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-img',{staticClass:"rounded",attrs:{"dark":"","src":_vm.poster,"aspect-ratio":16 / 9}},[(_vm.$ability.can('manage', 'Video'))?_c('div',{staticClass:"d-flex justify-end"},[_c('v-menu',{scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":"","loading":_vm.loading}},on),[_c('v-icon',[_vm._v("mdi-dots-vertical")])],1)]}}],null,false,818138242)},[_c('v-list',{attrs:{"dense":""}},[_c('v-list-item',{on:{"click":function($event){return _vm.$refs.fileInput.click()}}},[_c('v-list-item-icon',{staticClass:"mr-4"},[_c('v-icon',[_vm._v("mdi-upload")])],1),_c('v-list-item-title',[_c('input',{ref:"fileInput",staticClass:"hidden-file-input",attrs:{"id":"VideoPoster_file","type":"file","accept":".png, .svg, .webp, .jpg, .jpeg, .gif"},on:{"change":function (e) { return _vm.uploadFile(e.target.files.item(0)); }}}),_vm._v(" Upload Poster ")])],1),_c('v-list-item',{on:{"click":function($event){return _vm.$refs.videoPosterScrubDialog.openDialog()}}},[_c('v-list-item-icon',{staticClass:"mr-4"},[_c('v-icon',[_vm._v("mdi-video")])],1),_c('v-list-item-title',[_vm._v("Select from Video")])],1),(!_vm.isCurrentPosterDefault)?_c('v-list-item',{on:{"click":_vm.selectDefaultPoster}},[_c('v-list-item-icon',{staticClass:"mr-4"},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-refresh")])],1),_c('v-list-item-title',[_vm._v("Revert to Default")])],1):_vm._e()],1)],1),_c('VideoDetailsPosterScrubDialog',{ref:"videoPosterScrubDialog",attrs:{"video":_vm.video},on:{"poster-selected":function (ref) {
	var thumbnailURL = ref.thumbnailURL;

	return this$1.savePoster(thumbnailURL);
}}})],1):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }