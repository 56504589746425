import { Emotions } from './types';
import includes from 'lodash/includes';
import mixins from 'vue-typed-mixins';
import ThemeColor from '@/utils/ThemeColor';
export default mixins(ThemeColor).extend({
    name: 'VideoInsightCommenDataFilter',
    props: {
        ratingsMinMax: {
            type: Object,
        },
        emotions: {
            type: Array,
        },
        applyFilter: {
            type: Function,
        },
        ratingsEnabled: {
            type: Boolean,
        },
        filterWasChanged: {
            type: Boolean,
        },
    },
    data() {
        return {
            filterRange: [0, 10],
            filterPositiveSentiment: true,
            filterNegativeSentiment: true,
            filterNeutralSentiment: true,
            filterMenu: false,
        };
    },
    computed: {
        filterEmotions() {
            const emotions = [];
            if (this.filterNegativeSentiment) {
                emotions.push(Emotions.NEGATIVE);
            }
            if (this.filterNeutralSentiment) {
                emotions.push(Emotions.NEUTRAL);
            }
            if (this.filterPositiveSentiment) {
                emotions.push(Emotions.POSITIVE);
            }
            return emotions;
        },
        filterColor() {
            let filtersInDefaultState = true;
            const ems = this.filterEmotions;
            const range = this.filterRange;
            if (range[0] != 0 || range[1] != 10) {
                filtersInDefaultState = false;
            }
            if (!includes(ems, Emotions.NEGATIVE) ||
                !includes(ems, Emotions.NEUTRAL) ||
                !includes(ems, Emotions.POSITIVE)) {
                filtersInDefaultState = false;
            }
            return this.filterMenu ||
                (this.filterWasChanged && !filtersInDefaultState)
                ? this.themeColorHex
                : null;
        },
        filterIsReady() {
            return (this.filterPositiveSentiment ||
                this.filterNegativeSentiment ||
                this.filterNeutralSentiment);
        },
        compositeFilterMaxMin() {
            return `${this.filterRange[0]}:${this.filterRange[1]}`;
        },
    },
    methods: {
        triggerFilter() {
            const { filterEmotions: emotions, filterRange } = this;
            const ratingsMinMax = { min: filterRange[0], max: filterRange[1] };
            this.filterMenu = false;
            this.applyFilter({ emotions, ratingsMinMax });
        },
    },
    watch: {
        ratingsMinMax: {
            handler(newVal) {
                if (newVal) {
                    this.filterRange[0] = newVal.min;
                    this.filterRange[1] = newVal.max;
                }
            },
            immediate: true,
        },
        emotions: {
            handler(newVal) {
                if (newVal) {
                    this.filterNegativeSentiment = includes(newVal, Emotions.NEGATIVE);
                    this.filterNeutralSentiment = includes(newVal, Emotions.NEUTRAL);
                    this.filterPositiveSentiment = includes(newVal, Emotions.POSITIVE);
                }
            },
            immediate: true,
        },
    },
});
