export function getNamespaceColor(colors: string[]) {
  const cache: { [namespace: string]: string } = {}

  let i = 0
  return function (namespace: string) {
    if (!cache[namespace]) {
      cache[namespace] = colors[i]
      // rotate through colors
      i = i + 1 === colors.length ? 0 : i + 1
    }
    return cache[namespace]
  }
}
