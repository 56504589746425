import Vue from 'vue';
import ChannelDetailsTitle from './ChannelDetailsTitle.vue';
import ChannelDetailsLink from './ChannelDetailsLink.vue';
import ChannelDetailsImage from './ChannelDetailsImage.vue';
import ChannelViewsTrend from '@/views/workspace/channel/ChannelViewsTrend.vue';
import { ChannelPageOptionsEnum, ChannelOutputsEnum, } from '@lumiere/db/types';
import { mapGetters, mapState } from 'vuex';
export default Vue.extend({
    name: 'ChannelDetails',
    props: {
        channel: {
            type: Object,
            required: true,
        },
        isAbilityResolved: Boolean,
    },
    computed: {
        ...mapState(['link']),
        ...mapGetters(['userRoleInWorkspace']),
        isPublic() {
            return !!this.$route.query.link;
        },
        showViewerLink() {
            if (this.userRoleInWorkspace) {
                return true;
            }
            if (this.isPublic && this.link) {
                const configOptions = this.link.configuration?.pageOptions?.options;
                if (configOptions) {
                    return configOptions.includes(ChannelPageOptionsEnum.SHOW_CHANNEL_LINK);
                }
            }
            return false;
        },
        showOverview() {
            if (this.userRoleInWorkspace) {
                return true;
            }
            if (this.isPublic && this.link) {
                const configOutputs = this.link.configuration?.outputs;
                if (configOutputs) {
                    return configOutputs.includes(ChannelOutputsEnum.CHANNEL_VIEWS);
                }
            }
            return false;
        },
    },
    methods: {},
    subscriptions() {
        return {};
    },
    components: {
        ChannelDetailsTitle,
        ChannelDetailsLink,
        ChannelViewsTrend,
        ChannelDetailsImage,
    },
});
