import Vue from 'vue';
import VideoInsightViewerRetentionCounts from './VideoInsightViewerRetentionCounts.vue';
import VideoInsightViewerRetentionTrend from './VideoInsightViewerRetentionTrend.vue';
import isEqual from 'lodash/isEqual';
import adminAPI from '@/services/adminAPI';
import { getBrowserQueryParam } from '@/utils/api';
import { hasNumberEl } from '../helper';
import isEmpty from 'lodash/isEmpty';
import VideoInsightsVideoAIFacesOutput from '../VideoInsightsVideoAIFacesOutput.vue';
export default Vue.extend({
    name: 'VideoInsightViewerRetention',
    props: {
        video: {
            type: Object,
            required: true,
        },
        feature: {
            type: Object,
            required: true,
        },
        showFacesOutput: Boolean,
    },
    data() {
        return {
            filtering: false,
            insightData: null,
            loading: false,
            zoomMinMax: undefined,
            totalVideoViews: null,
            aggregations: null,
            customBucketSize: null,
            loadResponseNoZoomMode: false,
        };
    },
    methods: {
        async loadResponse(minMax, isFilter = false, noZoom = false) {
            if (minMax || isFilter) {
                this.filtering = true;
            }
            else {
                this.loading = true;
            }
            if (noZoom) {
                this.filtering = false;
                this.loading = true;
                minMax = {};
                this.loadResponseNoZoomMode = true;
            }
            else {
                this.loadResponseNoZoomMode = false;
            }
            minMax = {
                min: minMax?.min ?? this.videoTime.start,
                max: minMax?.max ?? this.videoTime.stop,
            };
            this.$emit('retention-filter', {
                ...minMax,
            });
            try {
                const viewerRetentionData = await adminAPI((api) => api.video.insights.getVideoRetentionData({
                    vid: this.video.id,
                    cid: getBrowserQueryParam('cid'),
                    minMax,
                    customBucketSize: this.customBucketSize,
                    standalone_video_filter: getBrowserQueryParam('standalone_video_filter'),
                }));
                this.aggregations = viewerRetentionData.aggregations;
                this.totalVideoViews = viewerRetentionData.hits.total.value;
                return Promise.resolve(true);
            }
            catch (error) {
                return Promise.reject(true);
            }
            finally {
                this.loading = false;
                this.filtering = false;
            }
        },
        async handleRouteQueryParam(route) {
            const { min, max } = route.query;
            const minMax = {};
            if (hasNumberEl(min)) {
                minMax.min = +min;
            }
            if (hasNumberEl(max)) {
                minMax.max = +max;
            }
            this.zoomMinMax = minMax;
            await this.loadResponse(minMax);
        },
        chartZoomActivated(minMax) {
            void this.loadResponse(minMax).then(() => {
                this.zoomMinMax = minMax;
            });
        },
        onBucketSizeChanged(size) {
            this.customBucketSize = size;
            void this.loadResponse(this.zoomMinMax, true);
        },
    },
    computed: {
        isDataInCountAndTrend() {
            return false;
        },
        inAction() {
            return false;
        },
        videoTime() {
            return {
                start: 0,
                stop: this.video.duration,
            };
        },
        currentRange() {
            const max = this.zoomMinMax?.max ?? this.videoTime.stop;
            const min = this.zoomMinMax?.min ?? this.videoTime.start;
            const range = max - min;
            return range;
        },
        averagePercentViewed() {
            if (this.totalVideoViews == null) {
                // undefined or null but not 0
                return null;
            }
            if (this.loadResponseNoZoomMode) {
                return 0;
            }
            // filtered_range.doc_count / hits.total.value / current range duration (seconds)
            const filtered_range = this.aggregations?.retention_moments.filtered_range.doc_count ?? 0;
            return filtered_range / ((this.totalVideoViews || 1) * this.currentRange);
        },
        averageViewDuration() {
            if (this.averagePercentViewed == null) {
                // undefined or null but not 0
                return null;
            }
            return this.averagePercentViewed * this.currentRange;
        },
        retention_bucket() {
            return (this.aggregations?.retention_moments.filtered_range.retentions_by_t
                .buckets ?? {});
        },
    },
    async mounted() {
        await this.handleRouteQueryParam(this.$route);
        const zMax = this.zoomMinMax?.max ?? this.videoTime.stop;
        const zMin = this.zoomMinMax?.min ?? this.videoTime.start;
        const { start: vMin, stop: vMax } = this.videoTime;
        const zoomed = zMax != vMax || zMin != vMin;
        //if the bucket is empty, and we have a zoom, then we're going
        //to try to load with data again without the zoom because we need
        //to try and show the chart no matter what if there's a zoom
        //from the query-params.
        if (isEmpty(this.retention_bucket) && zoomed) {
            //This is used in circumstances in which the user has loaded the page
            //with non-trivial zoom parameters, and in which the data has returned
            //empty. In such a case, the user has zoomed in on a region of the chart
            //for which there is no data. But we still want to show the chart. So in
            //that case, we're loading the data up as-if there is no zoom at all. We
            //do this so that the chart still renders like usual, even though the user
            //is in an empty zone.
            void this.loadResponse({}, false, true);
        }
    },
    watch: {
        'video.eids': {
            async handler(eids, oldEids) {
                if (!isEqual(eids, oldEids)) {
                    // refresh the data with the new eids video filter
                    await this.$sleep(1000);
                    void this.loadResponse();
                }
            },
        },
        '$route.query.cid': {
            handler(c, oc) {
                if (c || oc)
                    void this.loadResponse();
            },
        },
        '$route.query.standalone_video_filter': {
            handler(v, ov) {
                if (v || ov)
                    void this.loadResponse();
            },
        },
    },
    components: {
        VideoInsightViewerRetentionCounts,
        VideoInsightViewerRetentionTrend,
        VideoInsightsVideoAIFacesOutput,
    },
});
