import '@/assets/vendor/webgl-heatmap';
import Vue from 'vue';
const TIMING_PRECISION = 0.25;
function isExternalDataType(p) {
    return p.videoTime !== undefined;
}
export default Vue.extend({
    name: 'VisualizationsHeatmap',
    props: {
        currentTime: Number,
        heatmapBucket: {
            type: Object,
        },
        activeCommentVerbatimId: [String, Object],
    },
    data() {
        return {
            heatmap: null,
            currentFeatureId: undefined,
        };
    },
    mounted() {
        try {
            const heatmapRef = this.$refs.heatmap;
            // @ts-ignore
            heatmapRef.globalAlpha = 0.75;
            const heatmap = window.createWebGLHeatmap({
                canvas: this.$refs.heatmap,
            });
            if (heatmap) {
                heatmap.addPoint(0, 0, 0);
                heatmap.update();
                heatmap.display();
                heatmap.blur();
            }
            this.heatmap = heatmap;
            // console.log('this.heatmap', this.heatmap)
        }
        catch (error) {
            // handle the error
            console.log(error);
        }
    },
    methods: {
        drawHeatmap(cur_time) {
            // console.log()
            if (this.heatmapBucket) {
                const roundTime = Math.round(cur_time).toFixed(1);
                let toRender = this.heatmapBucket[roundTime]?.features.filter((el) => Math.abs((isExternalDataType(el.payload)
                    ? el.payload.videoTime
                    : el.payload.currentTime) - cur_time) <= TIMING_PRECISION);
                // console.log(cur_time, roundTime, toRender?.length)
                if (this.heatmap) {
                    if (toRender) {
                        if (this.activeCommentVerbatimId) {
                            this.heatmap.multiply(0);
                            toRender = toRender.filter((el) => {
                                return el._id === this.activeCommentVerbatimId;
                            });
                        }
                        else {
                            this.heatmap.multiply(0.85);
                        }
                        const { width, height } = this.heatmap;
                        let xPos, yPos;
                        const size = this.activeCommentVerbatimId ? 100 : 82;
                        const intensity = size / 100;
                        for (let feature of toRender) {
                            const { locationX, locationY } = feature.payload;
                            if (locationX && locationY) {
                                xPos = locationX * width;
                                yPos = (1 - locationY) * height;
                                // console.log({
                                //   roundTime,
                                //   locationX,
                                //   locationY,
                                //   xPos,
                                //   yPos,
                                // })
                                // setup dataset for heatmap showing comments near current time
                                this.heatmap.addPoint(xPos, yPos, size, intensity);
                            }
                        }
                    }
                    else {
                        this.heatmap.multiply(0);
                    }
                    this.heatmap.update();
                    this.heatmap.display();
                }
            }
        },
    },
    computed: {},
    watch: {
        currentTime(time) {
            this.drawHeatmap(time);
        },
    },
});
