import Vue from 'vue';
import SectionItem from './SectionItem.vue';
import UserProfileChangePassword from './UserProfileChangePassword.vue';
import auth from '@/services/auth';
import { switchMap, map, startWith } from 'rxjs/operators';
import logger from '@lumiere/shared/services/logger';
import UserLoginMethodsAddAccountButton from './UserLoginMethodsAddAccountButton.vue';
const providerIdToIconNameMap = {
    'google.com': 'mdi-google',
    'facebook.com': 'mdi-facebook',
    'twitter.com': 'mdi-twitter',
    'password': 'mdi-key-variant',
};
const providerIdToNameMap = {
    'google.com': 'Google',
    'facebook.com': 'Facebook',
    'twitter.com': 'Twitter',
    'password': 'Password',
};
const lumiereAuthProviders = {
    EmailPasswordAuthProviderID: 'password',
    // EmailLinkAuthProviderID: 'emailLink',
    GoogleAuthProviderID: 'google.com',
    FacebookAuthProviderID: 'facebook.com',
    // TwitterAuthProviderID: 'twitter.com',
};
export default Vue.extend({
    name: 'UserLoginMethods',
    data() {
        return {
            unlinking: false,
            providers: [],
        };
    },
    computed: {
        providerIdToIconName() {
            return (iconName) => providerIdToIconNameMap[iconName];
        },
        providerIdToName() {
            return (name) => providerIdToNameMap[name];
        },
        isPassword() {
            return (providerId) => providerId === 'password';
        },
        providersIds() {
            return this.providers.map((provider) => provider?.providerId || null);
        },
        hasExhaustedLoginMethods() {
            const allProviders = Object.values(lumiereAuthProviders);
            const difference = allProviders.filter((p) => !this.providersIds.includes(p));
            return difference.length === 0;
        },
        hasPasswordProvider() {
            return this.providersIds.includes(lumiereAuthProviders.EmailPasswordAuthProviderID);
        },
    },
    subscriptions() {
        return {
            providers: auth.user$.pipe(switchMap(async (user) => Promise.resolve(user?.providerData)), map((data) => data.filter(Boolean)), startWith([])),
        };
    },
    methods: {
        async unlinkProvider(providerId) {
            const shouldUnlink = await this.$confirm(`Do you want to unlink your ${providerId} account`, {
                buttonTrueText: 'Continue',
                buttonFalseText: 'Dismiss',
            });
            if (shouldUnlink) {
                this.unlinking = true;
                const user = await auth.getCurrentUser();
                if (user) {
                    await user
                        .unlink(providerId)
                        .then(async () => {
                        logger.info('Auth provider unlinked from account');
                        // await auth.getCurrentUser(true)
                        await user.getIdToken(true);
                        // this.$forceUpdate()
                    })
                        .catch((err) => {
                        logger.error('An error occured while unlinking account', err);
                    });
                }
                this.unlinking = false;
            }
        },
    },
    components: {
        SectionItem,
        UserProfileChangePassword,
        UserLoginMethodsAddAccountButton,
    },
});
