export var QuestionType;
(function (QuestionType) {
    QuestionType["Text"] = "Text";
    QuestionType["Choice"] = "Choice";
})(QuestionType || (QuestionType = {}));
export var TextQuestionVariant;
(function (TextQuestionVariant) {
    TextQuestionVariant["Short"] = "Short";
    TextQuestionVariant["Essay"] = "Essay";
})(TextQuestionVariant || (TextQuestionVariant = {}));
export var ChoiceQuestionVariant;
(function (ChoiceQuestionVariant) {
    ChoiceQuestionVariant["Single"] = "Single";
    ChoiceQuestionVariant["Multiple"] = "Multiple";
})(ChoiceQuestionVariant || (ChoiceQuestionVariant = {}));
export var ChoiceQuestionResponsePlusFeature;
(function (ChoiceQuestionResponsePlusFeature) {
    ChoiceQuestionResponsePlusFeature["Ratings"] = "ratings";
    ChoiceQuestionResponsePlusFeature["Stars"] = "stars";
    ChoiceQuestionResponsePlusFeature["Emojis"] = "emojis";
    ChoiceQuestionResponsePlusFeature["Tags"] = "tags";
})(ChoiceQuestionResponsePlusFeature || (ChoiceQuestionResponsePlusFeature = {}));
export var QuestionFeatureEvent;
(function (QuestionFeatureEvent) {
    QuestionFeatureEvent["QuestionAnswered"] = "QuestionAnswered";
    QuestionFeatureEvent["QuestionSkipped"] = "QuestionSkipped";
})(QuestionFeatureEvent || (QuestionFeatureEvent = {}));
