import Vue from 'vue';
import VueCSVImport from '@/components/common/VueCSVImport.vue';
export default Vue.extend({
    name: 'FeatureOptionsExternalData',
    data() {
        return {
            importData: null,
        };
    },
    watch: {
        importData(importData) {
            this.$emit('input', importData);
        },
    },
    components: {
        VueCSVImport,
    },
});
