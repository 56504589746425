import Vue from 'vue';
export default Vue.extend({
    inheritAttrs: false,
    props: {
        label: {
            type: String,
            required: true,
        },
        color: {
            type: String,
            required: true,
        },
    },
    data() {
        return {};
    },
    computed: {},
    watch: {},
    methods: {},
    subscriptions() {
        return {};
    },
    components: {},
});
