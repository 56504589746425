import Vue from 'vue';
import { mapGetters } from 'vuex';
export default Vue.extend({
    name: 'VideoDetailsLink',
    props: {
        video: {
            type: Object,
            required: true,
        },
    },
    computed: {
        ...mapGetters(['videoURL']),
    },
});
