import Vue from 'vue';
import { nanoid } from 'nanoid';
import { ChannelVisibility, ChannelStatus, ChannelTileSize, } from '@lumiere/db/types';
import { mapGetters } from 'vuex';
import getRandomElement from '@lumiere/shared/utils/getRandomElement';
import COLORS from '@/colors';
const getRandomColor = getRandomElement(Object.values(COLORS));
const channelDefault = (workspaceId, color) => ({
    workspaceId,
    title: '',
    color,
    channelTags: {},
    videos: [],
    channelVideoTags: {},
    options: {
        showSearch: false,
        showTagNavigation: false,
        showTitle: true,
        showDuration: true,
        showDescription: false,
        showTags: false,
        groupByTag: false,
        shuffleVideos: false,
        shuffleGroups: false,
        tileSize: ChannelTileSize.Medium,
    },
    channelWidgets: [],
    videoWidgets: [],
    visibility: ChannelVisibility.Public,
    status: ChannelStatus.Active,
});
export default Vue.extend({
    name: 'CreateNewChannelBtn',
    props: {
        workspaceId: { type: String, required: true },
    },
    data() {
        return {
            loading: false,
            dialog: false,
            channel: channelDefault(this.workspaceId, getRandomColor()),
        };
    },
    computed: {
        ...mapGetters(['currentWorkspace']),
    },
    watch: {
        dialog(dialog) {
            if (dialog) {
                this.channel = channelDefault(this.workspaceId, getRandomColor());
            }
        },
    },
    methods: {
        submitForm() {
            if (this.channel.title) {
                const cid = nanoid(10);
                const { channel } = this;
                this.loading = true;
                this.$db
                    .channel(cid)
                    .create(channel)
                    .then(() => {
                    this.loading = false;
                    this.dialog = false;
                });
            }
        },
    },
    components: {},
});
