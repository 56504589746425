import Vue from 'vue';
import { Role } from '@lumiere/db/types';
import emailIsValid from '@lumiere/shared/utils/emailIsValid';
import adminAPI from '@/services/adminAPI';
import logger from '@lumiere/shared/services/logger';
import COLORS from '@/colors';
import { pluck, debounceTime, switchMap, shareReplay, startWith, map, } from 'rxjs/operators';
import { nanoid } from 'nanoid';
import AddMemberForm from '@/components/common/AddMemberForm.vue';
const nonUser = () => ({
    displayName: '',
    id: nanoid(10),
    isUser: false,
    role: null,
});
export default Vue.extend({
    name: 'AddWorkspaceMemberForm',
    props: {
        workspace: Object,
    },
    data() {
        return {
            errors: [],
            loading: false,
            email: '',
            role: Role.EDITOR,
            searchList: [],
        };
    },
    computed: {
        roles() {
            return Object.values(Role);
        },
        themeColor() {
            return COLORS[this.workspace.theme.color];
        },
    },
    methods: {
        close() {
            this.$emit('close');
        },
        async addMembers(wid, members) {
            logger.log('addMembers', { wid, members });
            await adminAPI((api) => api.workspace.addWorkspaceMembers({
                origin: location.origin,
                wid,
                members,
            })).then(async (result) => {
                const ctx = await this.$auditLogger(this.$store.state.uid);
                if (ctx) {
                    const { Asset_Model, EVENT_ACTION, ts } = ctx.config;
                    const asset = {
                        id: wid,
                        type: Asset_Model.Workspace,
                        name: this.workspace.name,
                    };
                    await ctx.exec(asset, {
                        timestamp: ts(),
                        action: EVENT_ACTION.MEMBERS_ADDED,
                        data: {
                            type: 'INFO',
                            payload: {
                                message: 'New members have been added to the workspace',
                                actorAssets: {
                                    type: Asset_Model.User,
                                    ids: result,
                                },
                            },
                        },
                    });
                }
            });
        },
        async submitForm(selectedUsers) {
            this.errors = [];
            try {
                this.loading = true;
                const members = Object.values(selectedUsers).map((m) => ({
                    email: m.email,
                    displayName: m.displayName || '',
                    role: m.role,
                }));
                await this.addMembers(this.workspace.id, members);
                logger.log('add member success');
                this.close();
            }
            catch (error) {
                this.errors = [error];
            }
            this.loading = false;
        },
    },
    subscriptions() {
        const workspace = this.$props.workspace;
        const email$ = this.$watchAsObservable('email', {
            immediate: true,
            deep: true,
        }).pipe(pluck('newValue'));
        return {
            searchList: email$.pipe(debounceTime(500), switchMap((email) => this.$db
                .users((q) => q.where('email', '==', (email ?? '').toLowerCase()))
                .get()), map((users) => users.length
                ? users.map((user) => ({
                    ...user,
                    isUser: true,
                    role: workspace.roles[user.id] || null,
                }))
                : emailIsValid(this.$data.email?.toLowerCase())
                    ? [{ email: this.$data.email.toLowerCase(), ...nonUser() }]
                    : []), shareReplay(1), startWith([])),
        };
    },
    components: {
        AddMemberForm,
    },
});
