import Vue from 'vue';
import ChannelSettingsChannelVisibility from './ChannelSettingsChannelVisibility.vue';
import ChannelSettingsDeleteChannelBtn from './ChannelSettingsDeleteChannelBtn.vue';
import ChannelSettingsShareLink from './ChannelSettingsShareLink.vue';
export default Vue.extend({
    name: 'ChannelSettings',
    props: {
        channel: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {};
    },
    computed: {},
    watch: {},
    methods: {},
    subscriptions() {
        return {};
    },
    components: {
        ChannelSettingsChannelVisibility,
        ChannelSettingsDeleteChannelBtn,
        ChannelSettingsShareLink,
    },
});
