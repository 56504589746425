import Vue from 'vue';
export default Vue.extend({
    name: 'VideoDetailsDescription',
    props: {
        video: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            editing: false,
        };
    },
    computed: {
        inputRef() {
            return this.$refs.input;
        },
        editable() {
            return this.$ability.can('manage', 'Video');
        },
    },
    watch: {},
    methods: {
        async updateVideoDescription(description) {
            const prevDescription = this.video.description;
            if (description !== prevDescription) {
                await this.$db
                    .video(this.video.id)
                    .update({ description })
                    .then(async () => {
                    const ctx = await this.$auditLogger(this.$store.state.uid);
                    if (ctx) {
                        const { Asset_Model, EVENT_ACTION, ts } = ctx.config;
                        const asset = {
                            id: this.video.id,
                            name: this.video.title,
                            type: Asset_Model.Video,
                        };
                        await ctx.exec(asset, {
                            timestamp: ts(),
                            action: EVENT_ACTION.UPDATED,
                            data: {
                                type: 'INFO',
                                payload: {
                                    message: 'Video attributes changed',
                                    extraInfo: {
                                        prevDescription,
                                        description,
                                    },
                                },
                            },
                        });
                    }
                });
            }
            this.editing = false;
        },
        editDescription() {
            this.editing = true;
        },
    },
});
