export var MUTATIONS;
(function (MUTATIONS) {
    MUTATIONS["SET_UID"] = "SET_UID";
    MUTATIONS["SET_USER"] = "SET_USER";
    MUTATIONS["SET_ADMIN"] = "SET_ADMIN";
    MUTATIONS["SET_WORKSPACES"] = "SET_WORKSPACES";
    MUTATIONS["ADD_FILE_UPLOAD"] = "ADD_FILE_UPLOAD";
    MUTATIONS["UPDATE_FILE_UPLOAD"] = "UPDATE_FILE_UPLOAD";
    MUTATIONS["REMOVE_FILE_UPLOAD"] = "REMOVE_FILE_UPLOAD";
    MUTATIONS["SOCIAL_AUTH"] = "SOCIAL_AUTH";
    MUTATIONS["SET_VIDEO_TIME"] = "SET_VIDEO_TIME";
    MUTATIONS["SET_VIDEO_STATE"] = "SET_VIDEO_STATE";
    MUTATIONS["CLEAR_FILE_UPLOADS"] = "CLEAR_FILE_UPLOADS";
    MUTATIONS["SAVE_VIDEO_ANALYTICS_DATA"] = "SAVE_VIDEO_ANALYTICS_DATA";
    MUTATIONS["CLEAR_VIDEO_ANALYTICS_DATA"] = "CLEAR_VIDEO_ANALYTICS_DATA";
    MUTATIONS["SET_LINK"] = "SET_LINK";
    MUTATIONS["SET_TEAM"] = "SET_TEAM";
    MUTATIONS["SET_RECENT_WORKSPACES"] = "SET_RECENT_WORKSPACES";
    MUTATIONS["SET_REDIRECT_AFTER_LOGIN"] = "SET_REDIRECT_AFTER_LOGIN";
})(MUTATIONS || (MUTATIONS = {}));
export default MUTATIONS;
