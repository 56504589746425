import Vue from 'vue';
import { FeatureType } from '@lumiere/db/types';
import FeatureOptionsComments from './FeatureOptionsComments.vue';
import FeatureOptionsDialTest from './FeatureOptionsDialTest.vue';
import FeatureOptionsEmoji from './FeatureOptionsEmoji.vue';
import FeatureOptionsInstruction from './FeatureOptionsInstruction.vue';
import FeatureOptionsQuestion from './FeatureOptionsQuestion.vue';
import FeatureOptionsRedirect from './FeatureOptionsRedirect.vue';
import FeatureOptionsSocialChat from './FeatureOptionsSocialChat.vue';
import FeatureOptionsTags from './FeatureOptionsTags.vue';
import FeatureOptionsTimer from './FeatureOptionsTimer.vue';
import FeatureOptionsTextPrompt from './FeatureOptionsTextPrompt.vue';
import FeatureOptionsExternalData from './FeatureOptionsExternalData.vue';
const FEATURE_OPTIONS = {
    [FeatureType.Emojis]: FeatureOptionsEmoji,
    [FeatureType.Tags]: FeatureOptionsTags,
    [FeatureType.Comments]: FeatureOptionsComments,
    [FeatureType.Question]: FeatureOptionsQuestion,
    [FeatureType.DialTest]: FeatureOptionsDialTest,
    [FeatureType.Instruction]: FeatureOptionsInstruction,
    [FeatureType.SocialChat]: FeatureOptionsSocialChat,
    [FeatureType.Timer]: FeatureOptionsTimer,
    [FeatureType.Redirect]: FeatureOptionsRedirect,
    [FeatureType.TextPrompt]: FeatureOptionsTextPrompt,
    [FeatureType.ExternalData]: FeatureOptionsExternalData,
    [FeatureType.Retention]: () => null,
};
export default Vue.extend({
    data() {
        return {
            options: this.$props.value,
        };
    },
    watch: {
        options(options) {
            this.$emit('input', options);
        },
    },
    props: {
        type: {
            type: String,
            required: true,
        },
        value: {
            type: Object,
            required: false,
        },
    },
    computed: {
        componentName() {
            return (type) => FEATURE_OPTIONS[type];
        },
    },
});
