import Vue from 'vue';
export default Vue.extend({
    name: 'PlayerControlExtraWidget',
    props: {
        showWidgets: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        shouldShowWidgets() {
            return this.showWidgets;
        },
    },
});
