import Vue from 'vue';
import logger from '@lumiere/shared/services/logger';
// import { FirebaseAuth } from '@/services/firebase/auth'
import adminAPI from '@/services/adminAPI';
export default Vue.extend({
    name: 'LoginWithPasswordForm',
    props: {
        signInWithPassword: {
            type: Function,
        },
        loading: Boolean,
        passwordErrors: {
            type: Array,
        },
        user: Object,
    },
    data() {
        return {
            passwordValid: false,
            password: this.$props.value || '',
            passwordRules: [(v) => !!v || 'Password is required'],
        };
    },
    methods: {
        checkPasswordSanitation() {
            const form = this.$refs.form;
            const yayOrNay = form.validate();
            this.$emit('password-valid', yayOrNay);
        },
        async sendPasswordResetEmail() {
            this.$emit('reset-password', {
                loadingMessage: 'Sending password reset email...',
            });
            // const auth = FirebaseAuth()
            const email = this.user?.email;
            if (!email)
                return;
            const actionURL = new URL(`/login`, location.origin);
            await adminAPI(async (api) => api.emails.sendPasswordReset({
                url: actionURL.href,
                email: email,
                name: this.user?.displayName?.split(' ')[0] ?? 'User',
            }))
                // await auth
                //   .sendPasswordResetEmail(email)
                .then(async () => {
                await this.$confirm('Check your email!  We sent a link to reset your password.', {
                    buttonTrueText: 'OK',
                    buttonFalseText: '',
                });
            })
                .catch(async (e) => {
                await this.$confirm(e.message ||
                    'Something went wrong while sending a password reset email', {
                    buttonTrueText: 'OK',
                    buttonFalseText: '',
                });
                logger.error('Something went wrong while sending a password reset email', e);
            });
            this.$emit('reset-password-complete');
        },
    },
    watch: {
        //Assigning the errors directly to the form with with the passwordErrors backs us into
        //a corner here. If the login fails, then passwordValid is always going to be false,
        //regardless of if the password passed the validation conditions or not.
        //So we have to perform the validation manually and then that's the "true" state of the password.
        passwordValid() {
            this.checkPasswordSanitation();
        },
        password() {
            this.checkPasswordSanitation();
        },
    },
});
