import Vue from 'vue';
export default Vue.extend({
    props: {
        prompt: {
            type: String,
            required: true,
            default: '',
        },
    },
});
