import Vue from 'vue';
import { FeatureType } from '@lumiere/db/types';
import featureDefaults from './featureDefaults';
import FeatureEditForm from './FeatureEditForm.vue';
import { nanoid } from 'nanoid';
import FeatureDeleteDialog from './FeatureDeleteDialog.vue';
import FeatureCopyToVideoDialog from './FeatureCopyToVideoDialog.vue';
export default Vue.extend({
    props: {
        videoId: {
            type: String,
            required: true,
        },
        feature: {
            type: Object,
            required: true,
        },
    },
    computed: {
        FeatureType: () => FeatureType,
        featureDefaults: () => featureDefaults,
    },
    data() {
        return {
            editFeatureDialog: false,
            loading: false,
            dialog: false,
            deletingFeature: false,
            copyToVideoDialog: false,
            featureEnabled: false,
        };
    },
    watch: {
        'feature.enabled': {
            handler(enabled) {
                if (this.featureEnabled !== enabled) {
                    this.featureEnabled = enabled;
                }
            },
            immediate: true,
        },
        'featureEnabled': {
            handler(isEnabled) {
                this.toggleEnabled(isEnabled);
            },
        },
    },
    components: {
        FeatureEditForm,
        FeatureDeleteDialog,
        FeatureCopyToVideoDialog,
    },
    methods: {
        async toggleEnabled(enabled) {
            this.loading = true;
            await this.$db.video(this.videoId).feature(this.feature.id).update({
                enabled,
            });
            this.loading = false;
        },
        async remove() {
            //
            this.deletingFeature = true;
            await this.$db
                .video(this.videoId)
                .feature(this.feature.id)
                .softDelete()
                .then(async () => {
                const ctx = await this.$auditLogger(this.$store.state.uid);
                if (ctx) {
                    const { Asset_Model, EVENT_ACTION, ts } = ctx.config;
                    const asset = {
                        id: this.feature.id,
                        name: this.feature.label,
                        type: Asset_Model['Video.Feature'],
                    };
                    await ctx.exec(asset, {
                        timestamp: ts(),
                        action: EVENT_ACTION.DELETED,
                        data: {
                            type: 'INFO',
                            payload: {
                                message: 'Video.Feature is deleted',
                                actorAssets: {
                                    type: Asset_Model.Video,
                                    ids: [this.videoId],
                                },
                            },
                        },
                    });
                }
            });
            this.deletingFeature = false;
            this.dialog = false;
        },
        async duplicate() {
            const fid = nanoid();
            let feature = { ...this.feature };
            delete feature.createdBy;
            delete feature.createdTime;
            delete feature.editedBy;
            delete feature.editedTime;
            delete feature.id;
            feature.label = feature.label + (' copy-' + nanoid(3)).toLowerCase();
            await this.$db.video(this.videoId).feature(fid).create(feature);
        },
    },
});
