import Vue from 'vue';
import { ScrubberMode, VideoState, ScrubbingStates, } from '@lumiere/video';
import VideoPlayerAdminTimePicker from '@/components/video/player/VideoPlayerAdminTimePicker.vue';
import TimePickerComponent from './TimePickerComponent.vue';
export default Vue.extend({
    name: 'VideoPlayerPointPicker',
    props: {
        video: {
            type: Object,
        },
        value: {
            type: Number,
        },
        preselectedPoint: {
            type: Number,
            default: 0,
        },
    },
    data() {
        return {
            pointPicker: this.$props.preselectedPoint,
            state: null,
            currentTime: this.$props.value ?? 0,
        };
    },
    computed: {
        ScrubberMode() {
            return ScrubberMode;
        },
        videoPlayerRef() {
            return () => this.$refs.videoPlayer;
        },
    },
    methods: {
        onTimeChange(t) {
            this.currentTime = Math.round(t);
        },
        onStateChange(s) {
            this.state = s;
        },
        onScrubbing({ state, currentTime: t }) {
            if (state === ScrubbingStates.STOP) {
                this.pointPicker = t;
            }
        },
    },
    watch: {
        pointPicker: {
            handler(t) {
                this.currentTime = t;
                const playerRef = this.videoPlayerRef();
                if (playerRef && this.state) {
                    const { Playing, Paused } = VideoState;
                    const okToSeek = [Paused, Playing].includes(this.state);
                    if (okToSeek) {
                        playerRef.seekTo(t);
                    }
                }
            },
        },
        currentTime: {
            handler(t) {
                this.$emit('input', t);
            },
        },
        state: {
            handler(state) {
                if (state === VideoState.Ready) {
                    const playerRef = this.videoPlayerRef();
                    if (playerRef) {
                        // eslint-disable-next-line @typescript-eslint/no-floating-promises
                        playerRef.play()?.then(() => {
                            playerRef.seekTo(this.preselectedPoint);
                            playerRef.pause();
                        });
                    }
                }
            },
            immediate: true,
        },
    },
    components: {
        VideoPlayerAdminTimePicker,
        TimePickerComponent,
    },
});
