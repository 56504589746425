import Vue from 'vue';
import '@lumiere/shared/plugins/vue2-touch-events';
import { FeatureType, DefaultVideoBehaviors, FeatureTriggerType, VideoTriggerEvent, EndAction, } from '@lumiere/db/types';
import VideoPlayer from './VideoPlayer';
import { VideoState, ScrubbingStates, LumiereApps, } from './types';
import EmojiFeedbackContainer from '../features/emoji/EmojiFeedbackContainer.vue';
import CommentFeedbacksContainer from '../features/comment/CommentFeedbacksContainer.vue';
import TextPromptFeedbackContainer from '../features/text-prompt/TextPromptFeedbackContainer.vue';
import QuestionFeedbackContainer from '../features/question/QuestionFeedbackContainer.vue';
import LumiereVideoFeaturesZone from './LumiereVideoFeaturesZone.vue';
import logger from '@lumiere/shared/services/logger';
import { getVideoPoster } from '@lumiere/shared/utils/getVideoThumbnail';
const PLAYBACK_LAST_TIME_FRAME = 1.2; // tentative last playback frame/window of a video
var TriggerType;
(function (TriggerType) {
    TriggerType["trigger"] = "trigger";
    TriggerType["stopper"] = "stopper";
})(TriggerType || (TriggerType = {}));
function isFeatureOfType(type) {
    return function (f) {
        return f.type === type;
    };
}
export default Vue.extend({
    name: 'LumiereVideo',
    props: {
        video: {
            type: Object,
            required: true,
        },
        getVideoSrcPromise: {
            type: Function,
            required: true,
        },
        features: {
            type: Array,
            default: () => [],
        },
        options: {
            type: Object,
            default() {
                return {};
            },
        },
        appName: {
            type: String,
            default: LumiereApps.VIEWER_APP,
        },
        forceScrubbing: {
            type: [Object, Boolean],
            default: undefined,
        },
        customPoster: {
            type: [String, Object],
            default: null,
        },
        startFrom: {
            type: Number,
            default: 0,
        },
    },
    data() {
        return {
            src: null,
            state: null,
            featureInFocus: null,
            currentTime: 0,
            videoScrubbingData: null,
            commentBoxActivated: false,
            activatedCommentBoxId: null,
            showVideoPlayerControls: true,
            triggeredFeatures: [],
        };
    },
    computed: {
        FeatureType: () => FeatureType,
        enabledFeatures() {
            if (!this.features)
                return [];
            return this.features.filter(({ enabled }) => !!enabled);
        },
        // features with configured time-based trigger and stopper
        enabledTimespanFeatures() {
            return this.enabledFeatures.filter(({ triggers, stoppers }) => {
                return (triggers.find(({ type, event }) => type === FeatureTriggerType.VideoEvent &&
                    event === VideoTriggerEvent.VideoTime) !== undefined &&
                    stoppers.find(({ type, event }) => type === FeatureTriggerType.VideoEvent &&
                        event === VideoTriggerEvent.VideoTime) !== undefined);
            });
        },
        featuresMap() {
            const featuresMap = new Map();
            this.enabledFeatures.forEach((feature) => featuresMap.set(feature.id, feature));
            return featuresMap;
        },
        emojiFeatures() {
            return this.triggeredFeatures.filter(isFeatureOfType(FeatureType.Emojis));
        },
        videoPlayer() {
            return this.$refs.videoPlayer;
        },
        VideoState() {
            return VideoState;
        },
        logo() {
            return this.video.appearance?.logo;
        },
        videoPosterURL() {
            return this.customPoster ?? getVideoPoster(this.video);
        },
        customCSS() {
            return this.video.appearance?.css;
        },
        playerColor() {
            return this.video.appearance?.color ?? '#03A9F4';
        },
        videoBehaviors() {
            return { ...DefaultVideoBehaviors, ...this.video.behaviors };
        },
        aspectRatio() {
            const [width, height] = this.video.aspectRatio.split(':');
            return +width / +height;
        },
        escapeNumericVideoId() {
            // read more:
            // https://mathiasbynens.be/notes/css-escapes
            // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Errors/Deprecated_octal
            return 'LumiereVideo_' + this.video.id;
        },
        commentFeatures() {
            return this.triggeredFeatures.filter(isFeatureOfType(FeatureType.Comments));
        },
        showFeatures() {
            const state = this.state;
            const condition = [VideoState.Ready, VideoState.Waiting];
            return (this.featureInFocus !== null ||
                state === VideoState.Playing ||
                (!condition.includes(state) && this.commentBoxActivated));
        },
        showEmojiInteractions() {
            const featuresWithShowInteractions = this.enabledFeatures.filter(({ type, options }) => type === FeatureType.Emojis &&
                !!options?.feedbackOptions?.showInteractions);
            return !!featuresWithShowInteractions.length;
        },
        isViewerExperience() {
            return !!this.enabledFeatures?.length;
        },
        isAdminApp() {
            return this.appName === LumiereApps.ADMIN_APP;
        },
        shouldAutoPlay() {
            return this.isAdminApp ? false : this.videoBehaviors?.autoplay ?? false;
        },
        shouldPauseOnBlur() {
            return this.isAdminApp ? false : !!this.videoBehaviors?.pauseOnBlur;
        },
        isPlaybackLastFrame() {
            return this.video.duration - this.currentTime <= PLAYBACK_LAST_TIME_FRAME;
        },
        textPromptFeatures() {
            return this.triggeredFeatures.filter(isFeatureOfType(FeatureType.TextPrompt));
        },
        currentSecond() {
            return Number(this.currentTime.toFixed(1));
        },
        /**
         * timespanTriggersChangeMap is a hashmap, which maps timestamps to an array of activated feature ids
         * this construct allows us to quickly locate active features that have to be active during playback
         */
        timespanTriggersChangeMap() {
            const timespanTriggersChangeMap = new Map();
            timespanTriggersChangeMap.set(0, []);
            this.enabledTimespanFeatures.forEach((feature) => {
                const { id } = feature;
                const triggers = feature.triggers;
                const stoppers = feature.stoppers;
                if (triggers && stoppers) {
                    let startTime = this.video.duration;
                    let stopTime = 0;
                    triggers.forEach((t) => {
                        if ((t.parameters.time ?? 0) < startTime) {
                            startTime = t.parameters.time ?? 0;
                        }
                    });
                    stoppers.forEach((t) => {
                        if ((t.parameters.time ?? this.video.duration) > stopTime) {
                            stopTime = t.parameters.time ?? this.video.duration;
                        }
                    });
                    // For trigger event on the feature
                    const othersAtTrigger = this.computeOtherFeaturesTriggers(id, startTime);
                    const noDupesTriggers = new Set([id, ...othersAtTrigger]);
                    timespanTriggersChangeMap.set(startTime, Array.from(noDupesTriggers));
                    // For stopper event on the feature
                    const othersAtStoppers = this.computeOtherFeaturesTriggers(id, stopTime, TriggerType.stopper);
                    const noDupesStopper = new Set([...othersAtStoppers]);
                    timespanTriggersChangeMap.set(stopTime, Array.from(noDupesStopper));
                }
            });
            return timespanTriggersChangeMap;
        },
        questionFeatures() {
            return this.enabledFeatures.filter(isFeatureOfType(FeatureType.Question));
        },
        triggeredFeatureIds() {
            let triggeredFeatureIds = this.timespanTriggersChangeMap.get(0);
            let maxValueLowerThanCurrentSecond = 0;
            this.timespanTriggersChangeMap.forEach((features, t) => {
                if (t <= this.currentSecond && t > maxValueLowerThanCurrentSecond) {
                    maxValueLowerThanCurrentSecond = t;
                    triggeredFeatureIds = features;
                }
            });
            return triggeredFeatureIds;
        },
        onEnd() {
            return !this.allowScrubbing
                ? EndAction.ShowEndScreen
                : this.videoBehaviors?.onEnd.action ?? EndAction.ResetToThumbnail;
        },
        allowScrubbing() {
            return this.forceScrubbing ?? this.videoBehaviors?.allowScrubbing ?? true;
        },
        loopVideo() {
            return this.onEnd === EndAction.Loop;
        },
    },
    methods: {
        getVideoSrc() {
            this.getVideoSrcPromise().then((src) => {
                this.src = src;
            });
        },
        emitFeedback(feedback) {
            this.$emit('feedback', feedback);
        },
        onTimeUpdate(t) {
            this.currentTime = t;
            this.$emit('timeupdate', t);
        },
        onStateChange(state) {
            this.state = state;
        },
        onScrubbing(data) {
            this.videoScrubbingData = data;
            this.$emit('scrubbing', data);
        },
        shouldPauseVideo(fid) {
            const activeComment = this.enabledFeatures.find(({ id, type }) => type === FeatureType.Comments && id === fid);
            if (!activeComment)
                return false;
            return (activeComment.options?.feedbackOptions
                ?.shouldPauseVideo ?? false);
        },
        onCommentBoxActivated(id, commentBoxActivated) {
            this.commentBoxActivated = commentBoxActivated;
            const shouldPauseVideo = this.shouldPauseVideo(id);
            if (commentBoxActivated) {
                this.activatedCommentBoxId = id;
                if (shouldPauseVideo) {
                    this.pause();
                }
            }
            else {
                this.activatedCommentBoxId = null;
                if (shouldPauseVideo || this.isPlaybackLastFrame) {
                    this.play()?.catch((e) => logger.error('OnCommentBoxActivated: Play could not activate', e));
                }
            }
            this.setTriggeredFeatures();
        },
        onToggleTaggingMode(taggingMode) {
            this.showVideoPlayerControls = !taggingMode;
        },
        seekTo(time) {
            this.videoPlayer?.seekTo(time);
        },
        play() {
            return this.videoPlayer?.play();
        },
        pause() {
            this.videoPlayer?.pause();
        },
        togglePlay() {
            this.$emit('toggle-play');
        },
        onBigPlayButtonClicked() {
            this.$emit('big-play-button-clicked');
        },
        // It is not clear what this method is for
        computeOtherFeaturesTriggers(fid, time, type) {
            return this.enabledFeatures
                .filter((f) => {
                if (f.id === fid)
                    return false;
                const triggers = f.triggers;
                const stoppers = f.stoppers;
                let startTime = this.video.duration;
                let stopTime = 0;
                if (triggers && stoppers) {
                    triggers.forEach((t) => {
                        if ((t.parameters?.time ?? 0) < startTime) {
                            startTime = t.parameters?.time ?? 0;
                        }
                    });
                    stoppers.forEach((t) => {
                        if ((t.parameters?.time ?? this.video.duration) > stopTime) {
                            stopTime = t.parameters?.time ?? this.video.duration;
                        }
                    });
                }
                if (type === TriggerType.stopper) {
                    return startTime < time && stopTime > time;
                }
                return startTime <= time && stopTime > time;
            })
                .map((f) => f.id);
        },
        setTriggeredFeatures() {
            let triggeredFeatureIds = this.triggeredFeatureIds;
            if (this.activatedCommentBoxId &&
                !triggeredFeatureIds.includes(this.activatedCommentBoxId)) {
                // include activated comment feature id in the list of triggered features
                triggeredFeatureIds = [
                    ...triggeredFeatureIds,
                    this.activatedCommentBoxId,
                ];
            }
            this.triggeredFeatures = triggeredFeatureIds.map((v) => this.featuresMap.get(v));
        },
        onQuestionFeatureActivated(pauseVideo) {
            if (pauseVideo) {
                this.pause();
            }
            this.showVideoPlayerControls = false;
            this.featureInFocus = FeatureType.Question;
        },
        onQuestionFeatureFinished() {
            this.showVideoPlayerControls = true;
            this.featureInFocus = null;
            // eslint-disable-next-line @typescript-eslint/no-floating-promises
            this.play();
        },
        refreshFeatureData() {
            const emojiFeedbackContainer = this.$refs.emojiFeedbackContainer;
            emojiFeedbackContainer?.refreshEmojiInteractionData();
        },
    },
    watch: {
        triggeredFeatureIds: {
            immediate: true,
            handler(triggeredFeatureIds, triggeredFeatureIdsOldValue) {
                if (triggeredFeatureIds !== triggeredFeatureIdsOldValue) {
                    this.setTriggeredFeatures();
                }
            },
        },
        state(newState, oldState) {
            this.$emit('state-change', newState);
            if (oldState === VideoState.Paused && newState === VideoState.Playing) {
                this.commentBoxActivated = false;
            }
            if (newState === VideoState.Ready) {
                if (this.startFrom > 0 && this.startFrom < this.video.duration) {
                    this.seekTo(this.startFrom);
                }
            }
            if (newState === VideoState.Ended) {
                switch (this.onEnd) {
                    case EndAction.Loop:
                        break;
                    case EndAction.ResetToThumbnail:
                        break;
                    case EndAction.PauseOnLastFrame:
                        break;
                    default:
                        break;
                }
            }
        },
        currentSecond: {
            handler() {
                // 1. check if the playback is at last frame
                if (this.isPlaybackLastFrame && this.activatedCommentBoxId) {
                    // 2. If comment+ box is open, pause the video
                    this.commentBoxActivated = true;
                    this.pause();
                }
            },
            immediate: true,
        },
        videoScrubbingData(data, oldData) {
            if (data.state === ScrubbingStates.STOP &&
                oldData.state === ScrubbingStates.ACTIVE) {
                // Effective scrub is recorded, thus poll
                const emojiFeedbackContainer = this.$refs.emojiFeedbackContainer;
                emojiFeedbackContainer?.flyOutEmojisPolling(this.currentTime, true);
            }
        },
    },
    components: {
        EmojiFeedbackContainer,
        VideoPlayer,
        CommentFeedbacksContainer,
        TextPromptFeedbackContainer,
        LumiereVideoFeaturesZone,
        QuestionFeedbackContainer,
    },
    created() {
        this.getVideoSrc();
    },
});
