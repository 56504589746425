import Vue from 'vue';
export default Vue.extend({
    name: 'VideoDetailsTitle',
    props: {
        video: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            editing: false,
        };
    },
    computed: {
        inputRef() {
            return this.$refs.input;
        },
        editable() {
            return this.$ability.can('manage', 'Video');
        },
    },
    watch: {},
    methods: {
        async updateVideoTitle(_title) {
            const title = _title.trim();
            const prevTitle = this.video.title;
            if (title !== prevTitle) {
                await this.$db
                    .video(this.video.id)
                    .update({ title })
                    .then(async () => {
                    const ctx = await this.$auditLogger(this.$store.state.uid);
                    if (ctx) {
                        const { Asset_Model, EVENT_ACTION, ts } = ctx.config;
                        const asset = {
                            id: this.video.id,
                            name: title,
                            type: Asset_Model.Video,
                        };
                        await ctx.exec(asset, {
                            timestamp: ts(),
                            action: EVENT_ACTION.UPDATED,
                            data: {
                                type: 'INFO',
                                payload: {
                                    message: 'Video attributes changed: title updated',
                                    extraInfo: {
                                        prevTitle,
                                        title,
                                    },
                                },
                            },
                        });
                    }
                });
            }
            this.editing = false;
        },
        editTitle() {
            this.editing = true;
            // this.$nextTick(() => {
            //   this.inputRef.focus()
            // })
        },
    },
});
