import Vue from 'vue';
import { mapGetters } from 'vuex';
import { startWith, map } from 'rxjs/operators';
import { VideoStatus, } from '@lumiere/db/types';
import { nanoid } from 'nanoid';
export default Vue.extend({
    name: 'FeatureCopyToVideoDialog',
    props: {
        feature: {
            type: Object,
            required: true,
        },
        showDialog: {
            type: Boolean,
        },
        videoId: String,
    },
    data() {
        return {
            dialog: false,
            selectedVideos: [],
            copyTrigger: false,
            copyingFeature: false,
            videos: [],
        };
    },
    subscriptions() {
        const { currentWorkspace: { id: workspaceId }, videoId, } = this;
        return {
            videos: this.$db
                .videos((ref) => {
                return ref
                    .where('workspaceId', '==', workspaceId)
                    .where('status', '==', VideoStatus.Ready)
                    .orderBy('editedTime', 'desc');
            })
                .observe()
                .pipe(startWith(null), map((el) => (el ?? []).filter((item) => item.id !== videoId))),
        };
    },
    computed: {
        ...mapGetters(['currentWorkspace']),
    },
    methods: {
        async copyFeatureToVideo() {
            this.copyingFeature = true;
            const { selectedVideos, copyTrigger } = this;
            const { createdBy, createdTime, editedBy, editedTime, id, ...rest_attrs } = this.feature;
            const feature = rest_attrs;
            for (let video of selectedVideos) {
                const fid = nanoid();
                const { triggers = [], stoppers = [] } = feature;
                feature.triggers = this.reTriggers(copyTrigger, triggers);
                feature.stoppers = this.reStoppers(copyTrigger, stoppers, video);
                await this.$db
                    .video(video.id)
                    .feature(fid)
                    .create(feature)
                    .then(async () => {
                    const ctx = await this.$auditLogger(this.$store.state.uid);
                    if (ctx) {
                        const { Asset_Model, EVENT_ACTION, ts } = ctx.config;
                        const asset = {
                            id: fid,
                            name: this.feature.label,
                            type: Asset_Model['Video.Feature'],
                        };
                        await ctx.exec(asset, {
                            timestamp: ts(),
                            action: EVENT_ACTION.CREATED,
                            data: {
                                type: 'INFO',
                                payload: {
                                    message: 'Video.Feature is created',
                                    actorAssets: {
                                        type: Asset_Model.Video,
                                        ids: [video.id],
                                    },
                                },
                            },
                        });
                    }
                });
            }
            this.copyingFeature = false;
            this.$emit('dismiss-dialog');
            this.selectedVideos = [];
        },
        reTriggers(copy, triggers) {
            let newTriggers = [];
            let startTime;
            for (let trigger of triggers) {
                startTime = trigger.parameters?.time ?? 0;
                newTriggers.push({
                    ...trigger,
                    parameters: {
                        ...trigger.parameters,
                        time: copy ? startTime : 0,
                    },
                });
            }
            return newTriggers;
        },
        reStoppers(copy, stoppers, video) {
            let newStoppers = [];
            let stopTime;
            for (let stopper of stoppers) {
                stopTime = stopper.parameters?.time ?? video.duration;
                newStoppers.push({
                    ...stopper,
                    parameters: {
                        ...stopper.parameters,
                        time: copy && video.duration >= stopTime ? stopTime : video.duration,
                    },
                });
            }
            return newStoppers;
        },
    },
    watch: {
        showDialog(val) {
            this.dialog = val;
        },
        dialog(val) {
            if (val === false) {
                this.$emit('dismiss-dialog');
            }
        },
    },
});
