import Vue from 'vue';
import adminAPI from '@/services/adminAPI';
export default Vue.extend({
    name: 'UserViewerStats',
    data() {
        return {
            total_video_views: 0,
            total_time_watched: 0,
            comments_left: 0,
            emojis_clicked: 0,
            fetchingStats: false,
        };
    },
    created() {
        this.getStats();
    },
    methods: {
        // fetch user viewer stats
        async getStats() {
            this.fetchingStats = true;
            const { total_video_views, total_time_watched, comments_left, emojis_clicked, } = await adminAPI(async (api) => api.user.getUserViewerStats());
            this.total_video_views = total_video_views;
            this.total_time_watched = total_time_watched;
            this.comments_left = comments_left;
            this.emojis_clicked = emojis_clicked;
            this.fetchingStats = false;
        },
    },
    filters: {
        totalWatchedHr(time) {
            return Math.floor(time / 3600);
        },
        totalWatchedMin(time) {
            return Math.ceil(((time / 3600) % 1) * 60);
        },
    },
});
