import paths from './helpers/paths';
import { dbCollection, signatures, getUID } from './helpers/utils';
import CGUDO from './helpers/CGUDO';
export const feature = (vid) => (params) => (fid) => {
    const { firestore, FieldValue, dbAuth } = params;
    const { edited, deleted } = signatures(FieldValue);
    const path = paths.features(vid)(fid);
    const featureRef = firestore.doc(path);
    return {
        ...CGUDO(params)(path),
        async softDelete(outerBatch) {
            const uid = await getUID(dbAuth);
            const deleteUpdate = { ...deleted(uid) };
            return outerBatch
                ? outerBatch.update(featureRef, deleteUpdate)
                : featureRef.update(deleteUpdate);
        },
        async restore(outerBatch) {
            const uid = await getUID(dbAuth);
            const restoreUpdate = {
                ...edited(uid),
                deleted: FieldValue.delete(),
                deletedBy: FieldValue.delete(),
                deletedTime: FieldValue.delete(),
            };
            return outerBatch
                ? outerBatch.update(featureRef, restoreUpdate)
                : featureRef.update(restoreUpdate);
        },
    };
};
export const features = (vid) => dbCollection(paths.features(vid)());
