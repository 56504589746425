import pick from 'lodash/pick';
import MUTATIONS from './MUTATIONS';
import locallyStored from '@lumiere/shared/utils/locallyStored';
export { MUTATIONS };
const locallyStoredLink = locallyStored('LINK');
const mutations = {
    [MUTATIONS.SET_UID](state, uid) {
        if (state.uid !== uid) {
            state.uid = uid;
        }
    },
    [MUTATIONS.SET_USER](state, user) {
        if (user) {
            state.user = Object.assign({ id: user.uid }, pick(user, ['email', 'emailVerified', 'displayName', 'photoURL']));
        }
        else {
            state.user = null;
        }
    },
    [MUTATIONS.SET_ADMIN](state, admin) {
        state.admin = admin;
    },
    [MUTATIONS.SET_WORKSPACES](state, workspaces) {
        state.workspaces = { ...workspaces };
    },
    [MUTATIONS.SET_RECENT_WORKSPACES](state, recentWorkspaces) {
        state.recentWorkspaces = recentWorkspaces;
    },
    [MUTATIONS.ADD_FILE_UPLOAD](state, fileUpload) {
        state.fileUploads = { ...state.fileUploads, [fileUpload.id]: fileUpload };
    },
    [MUTATIONS.UPDATE_FILE_UPLOAD](state, { id, ...update }) {
        state.fileUploads = {
            ...state.fileUploads,
            [id]: {
                ...state.fileUploads[id],
                ...update,
            },
        };
    },
    [MUTATIONS.REMOVE_FILE_UPLOAD](state, id) {
        const { [id]: removeUpload, ...fileUploads } = state.fileUploads;
        state.fileUploads = fileUploads;
    },
    [MUTATIONS.SET_VIDEO_TIME](state, videoTime) {
        state.videoInfo.time = videoTime;
    },
    [MUTATIONS.SET_VIDEO_STATE](state, videoState) {
        state.videoInfo.state = videoState;
    },
    [MUTATIONS.SOCIAL_AUTH](state, data) {
        state.socialAuth = {
            ...state.socialAuth,
            ...data,
        };
    },
    [MUTATIONS.CLEAR_FILE_UPLOADS](state) {
        state.fileUploads = {};
    },
    [MUTATIONS.SAVE_VIDEO_ANALYTICS_DATA](state, payload) {
        const { data, fid, type } = payload;
        switch (type) {
            case 'heatmap':
                if (fid) {
                    state.videoAnalyticsData = {
                        ...state.videoAnalyticsData,
                        heatmap: {
                            ...state.videoAnalyticsData?.heatmap,
                            [fid]: data,
                        },
                    };
                }
                break;
            default:
                break;
        }
    },
    [MUTATIONS.CLEAR_VIDEO_ANALYTICS_DATA](state) {
        state.videoAnalyticsData = {};
    },
    [MUTATIONS.SET_LINK](state, link) {
        state.link = link;
        if (state.link) {
            locallyStoredLink.set({ [state.link.id]: link });
        }
    },
    [MUTATIONS.SET_TEAM](state, team) {
        state.team = team;
    },
    [MUTATIONS.SET_REDIRECT_AFTER_LOGIN](state, redirectAfterLogin) {
        state.redirectAfterLogin = redirectAfterLogin;
    },
};
export default mutations;
