import { ChannelStatus } from '../types';
import CGUDO from './helpers/CGUDO';
import paths from './helpers/paths';
import { dbCollection, getUID, signatures } from './helpers/utils';
export const channel = (params) => (cid) => {
    const { firestore, dbAuth, FieldValue } = params;
    const path = paths.channels(cid);
    const ref = firestore.doc(path);
    const { edited, deleted } = signatures(FieldValue);
    return {
        ...CGUDO(params)(path),
        async softDelete(outerBatch) {
            const uid = await getUID(dbAuth);
            const deleteUpdate = { ...deleted(uid), status: ChannelStatus.Deleted };
            return outerBatch
                ? outerBatch.update(ref, deleteUpdate)
                : ref.update(deleteUpdate);
        },
        async restore(outerBatch) {
            const uid = await getUID(dbAuth);
            const restoreUpdate = {
                ...edited(uid),
                status: ChannelStatus.Active,
                deleted: FieldValue.delete(),
                deletedBy: FieldValue.delete(),
                deletedTime: FieldValue.delete(),
            };
            return outerBatch
                ? outerBatch.update(ref, restoreUpdate)
                : ref.update(restoreUpdate);
        },
    };
};
export const channels = dbCollection(paths.channels());
