import { render, staticRenderFns } from "./QuestionFeatureTriggers.vue?vue&type=template&id=60ff26ca&"
import script from "./QuestionFeatureTriggers.vue?vue&type=script&lang=ts&"
export * from "./QuestionFeatureTriggers.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../../../../../node_modules/.pnpm/vue-loader@15.9.7_fb85a9817f99bdf862e5e3a442a1fb91/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../../../../node_modules/.pnpm/vuetify-loader@1.7.2_c225a462abeaf467a2189c5bd12e241a/node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCol } from 'vuetify/lib/components/VGrid';
import { VRadio } from 'vuetify/lib/components/VRadioGroup';
import { VRadioGroup } from 'vuetify/lib/components/VRadioGroup';
import { VRow } from 'vuetify/lib/components/VGrid';
installComponents(component, {VCol,VRadio,VRadioGroup,VRow})
