import { Role } from '@lumiere/db/types';
/**
 *
 * @param user the user record: firebase.User
 * @param workspace the workspace: Workspace
 * @param _hasClaim a Function that resolves to boolean on whether a user has a claim
 */
export default function defineAbilityForReviewer(uid, workspace, _hasClaim) {
    return (can, _cannot) => {
        // user roles
        const userRoleInWorkspace = workspace.roles[uid];
        const isReviewer = userRoleInWorkspace === Role.REVIEWER;
        if (isReviewer) {
            // define reviewer abilities
            can('read', ['Video', 'Channel']);
            can('read', [
                'Elasticsearch.ViewsCountAnalyticsData',
                'Elasticsearch.InsightAnalyticsData',
                'Elasticsearch.HeatmapAnalyticsData',
                'Elasticsearch.ViewerRetentionAnalyticsData',
                'Elasticsearch.ChannelMetricsAnalyticsData',
                'Elasticsearch.VideoIntelligenceAnalyticsData',
            ]);
        }
    };
}
