var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-select',_vm._b({staticClass:"workspace-select-navbar",attrs:{"hide-details":"","dense":"","items":_vm.items,"return-object":"","item-text":"name","item-value":"id","label":_vm.label,"menu-props":{
    closeOnContentClick: true,
    maxHeight: 430,
  }},scopedSlots:_vm._u([{key:"prepend-item",fn:function(){return [_c('v-list',{staticClass:"pt-0",attrs:{"dense":""}},[_c('v-list-item',{on:{"click":function($event){return _vm.$emit('show-workspaces')}}},[_c('v-list-item-icon',[_c('v-icon',{attrs:{"small":"","right":""}},[_vm._v("mdi-view-list")])],1),_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"font-weight-medium"},[_vm._v(" View all workspaces ")])],1)],1),_c('v-list-item',{on:{"click":function($event){return _vm.$emit('add-workspace')}}},[_c('v-list-item-icon',[_c('v-icon',{attrs:{"small":"","right":""}},[_vm._v("mdi-plus")])],1),_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"font-weight-medium"},[_vm._v(" Create new workspace ")])],1)],1),(_vm.$can('manage', 'Workspace') && _vm.currentWorkspace)?_c('v-list-item',{attrs:{"to":{
          name: _vm.Routes.WorkspaceSettings,
          params: { workspaceId: _vm.currentWorkspace.id },
        }}},[_c('v-list-item-icon',[_c('v-icon',{attrs:{"small":"","right":""}},[_vm._v("mdi-settings")])],1),_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"font-weight-medium"},[_vm._v(" Manage current workspace ")])],1)],1):_vm._e(),(_vm.items.length)?_c('v-divider',{staticClass:"my-1"}):_vm._e(),_c('v-subheader',{staticClass:"subtitle-2 font-weight-medium"},[_vm._v(" Recent Workspaces ")])],1)]},proxy:true},{key:"item",fn:function(ref){
        var item = ref.item;
        var on = ref.on;
return [_c('v-list-item',_vm._g({staticClass:"py-1 my-0",staticStyle:{"max-width":"400px"},attrs:{"color":item.theme.color,"input-value":item.id === _vm.selectedWorkspace.id}},on),[_c('v-list-item-avatar',{staticClass:"justify-center",attrs:{"rounded":"lg","color":item.iconURL ? null : item.theme.color}},[(item.iconURL)?_c('v-img',{attrs:{"src":item.iconURL}}):_c('v-avatar',{staticClass:"white--text font-weight-medium",attrs:{"tile":""}},[_vm._v(" "+_vm._s(_vm._f("initials")(item.name))+" ")])],1),_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"body-2 font-weight-regular"},[_vm._v(" "+_vm._s(item.name)+" ")]),(item.team)?_c('v-list-item-subtitle',{staticClass:"font-weight-regular"},[_vm._v(" "+_vm._s(item.team.name)+" ")]):_c('v-list-item-subtitle',{staticClass:"font-weight-regular"},[_vm._v(" Me (Personal Workspace) ")])],1)],1)]}}]),model:{value:(_vm.selectedWorkspace),callback:function ($$v) {_vm.selectedWorkspace=$$v},expression:"selectedWorkspace"}},'v-select',_vm.$attrs,false))}
var staticRenderFns = []

export { render, staticRenderFns }