import Vue from 'vue';
import GradientBG from '@/components/GradientBG.vue';
import Logo from '@/components/navbar/Logo.vue';
export default Vue.extend({
    name: 'ShareLinkPasswordBlocker',
    props: {
        link: {
            type: Object,
        },
    },
    data() {
        return {
            passwordDialog: true,
            saving: false,
            passwordText: '',
            removeBlocker: false,
            passwordHint: '',
        };
    },
    mounted() {
        setTimeout(() => {
            this.passwordRef?.focus();
        }, 0);
    },
    computed: {
        passwordRef() {
            return this.$refs.password;
        },
    },
    methods: {
        onChangePassword(val) {
            if (val.length) {
                this.passwordHint = '';
            }
            this.passwordText = val;
        },
        validatePassword() {
            if (!this.passwordText.length) {
                this.passwordHint = 'Password is required';
                return;
            }
            if (this.link) {
                const linkPassword = this.link.configuration?.pageOptions?.password;
                if (this.passwordText === linkPassword) {
                    this.saving = true;
                    const CURRENT_PASSWORD_CACHE = JSON.parse(sessionStorage.getItem('SHARE_LINK_CACHE') ?? '{}');
                    const payload = JSON.stringify({
                        [this.link.id]: Date.now(),
                        ...CURRENT_PASSWORD_CACHE,
                    });
                    sessionStorage.setItem('SHARE_LINK_CACHE', payload);
                    setTimeout(() => {
                        this.passwordDialog = false;
                        this.removeBlocker = true;
                        this.saving = false;
                    }, 1000);
                    return;
                }
                this.passwordHint = 'Please enter the correct password';
            }
        },
    },
    components: {
        GradientBG,
        Logo,
    },
});
