import { Created, Edited, Id, Deleted } from './db.base.types'
import { LinkStatus } from './db.links.types'

export enum AssetType {
  video = 'video',
  thumbnail = 'thumbnail',
  gif = 'gif',
  storyboard = 'storyboard',
}

export enum VideoStatus {
  Uploading = 'Uploading',
  Processing = 'Processing',
  Ready = 'Ready',
  Deleted = 'Deleted',
  Errored = 'Errored',
}
export enum VideoVisibility {
  Public = 'Public',
  Private = 'Private',
}

export interface StaticAsset {
  name: string
  ext: string
  height: number
  width: number
  bitrate: number
  filesize: number
}

export interface VideoAsset {
  name: string
  ext: string
  width: number
  height: number
  bitrate: number
  filesize: number
}
export type PlaybackID = { id: string; policy: 'public' | 'signed' }

export interface VideoProps extends Partial<Deleted> {
  title: string
  workspaceId: string
  description: string
  language?: string
  visibility: VideoVisibility
  playbackIds: Array<PlaybackID> // to be deprecated
  playbackId?: PlaybackID // will require migration
  appearance?: VideoAppearance
  behaviors?: VideoBehaviors
  status: VideoStatus
  statusMessage?: string
  storyboard?: string
  assets?: Array<VideoAsset>
  uploadId?: string
  assetId?: string
  tags: string[]
  features: string[]
  /**
   * A ref to previous uploads to facilitate deleting orphaned assets
   */
  previousUploadId?: string
  eids?: string[]
  shareLink?: VideoShareLink
  scenes?: Record<string, VideoScene>
  channels: string[]
  videoAI?: {
    id: string
    state: VideoAIJobStatus
    errorMessage?: string
    externalState?: VideoAIExternalStatus
  }
}

export enum VideoAIJobStatus {
  UPLOADING = 'Uploading',
  UPLOADED = 'Uploaded',
  PROCESSING = 'Processing',
  PROCESSED = 'Processed',
  FAILED = 'Failed',
  QUARANTINED = 'Quarantined',
}

export enum VideoAIExternalStatus {
  INGESTING = 'Ingesting',
  INGESTED = 'Ingested',
}

export interface Video extends Id, VideoProps, Created, Edited {
  id: string
  duration: number
  aspectRatio: string
  master?: {
    status: VideoMasterAccessStatus
    url?: string
  }
}

export type VideoMasterAccessStatus =
  | 'ready'
  | 'preparing'
  | 'deleted'
  | 'errored'

export enum EndAction {
  PauseOnLastFrame = 'PauseOnLastFrame',
  ResetToThumbnail = 'ResetToThumbnail',
  Loop = 'Loop',
  ShowEndScreen = 'ShowEndScreen',
}

export interface VideoBehaviors {
  autoplay: boolean
  allowScrubbing: boolean
  pauseOnBlur: boolean
  onEnd: {
    action: EndAction
    endActionOptions?: any
  }
}

export enum VideoLogoPosition {
  TopLeft = 'TopLeft',
  TopRight = 'TopRight',
  BottomLeft = 'BottomLeft',
  BottomRight = 'BottomRight',
}

export interface VideoLogo {
  src: string
  position: VideoLogoPosition
  opacity?: number
}

export interface VideoAppearance {
  poster?: string
  defaultPoster?: string
  thumbnail?: string
  animatedThumbnail?: string
  color?: string
  css?: string
  logo?: VideoLogo
  sharing?: string
}

export interface VideoShareLink {
  id: string
  path: string
  fullPath: string
  status: LinkStatus
}

export interface VideoScene {
  name: string
  startTime: number
  stopTime: number
  nonce: number
}

export const DefaultVideoBehaviors: VideoBehaviors = {
  autoplay: false,
  allowScrubbing: true,
  pauseOnBlur: false,
  onEnd: {
    action: EndAction.ResetToThumbnail,
  },
}
