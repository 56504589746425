import Vue from 'vue';
import EmojiComponent from '@lumiere/shared/components/InteractionEmoji.vue';
import config from '@lumiere/shared/config';
const explosionSpriteSrc = `${config.adminAppURL}/img/explosion.min.svg`;
import anime from 'animejs';
const SCALE_DURATION = 1000;
const SHAKE_AFTER = 1000;
const EXPLODE_AFTER = 2000;
export default Vue.extend({
    inheritAttrs: false,
    data() {
        return {
            touching: false,
            shake: false,
            explode: false,
            touchStart: null,
            aspectWidthToHeight: 0,
            emojiHeight: 0,
        };
    },
    computed: {
        spriteSrc() {
            return explosionSpriteSrc;
        },
        numberOfExplosions() {
            const widthToHeight = this.aspectWidthToHeight;
            if (widthToHeight < 1.2) {
                return 1;
            }
            else if (widthToHeight > 1.2 && widthToHeight < 1.5) {
                return 2;
            }
            else {
                return 3;
            }
        },
        emojiCssHeight() {
            return `${this.emojiHeight * 2.2}px`;
        },
        emojiCssPaddingLeft() {
            return `${this.emojiHeight * 2.2}px`;
        },
    },
    components: {
        Emoji: EmojiComponent,
    },
    methods: {
        getDuration() {
            return this.touchStart ? performance.now() - this.touchStart : 0;
        },
        calculateAspectWidthToHeight() {
            const ref = this.$refs.emoji;
            if (ref) {
                const { width, height } = ref.$el.getBoundingClientRect();
                this.aspectWidthToHeight = height !== 0 ? width / height : 1;
                this.emojiHeight = height;
            }
        },
        getExplosionCssLeft(index) {
            const n = this.numberOfExplosions;
            const division = 100 / (n + 1);
            return `${(index + 1) * division}%`;
        },
        onTouchStart() {
            //when we touch, use this opportunity to calculate the aspect ratio which will
            //reactively create as many explosions as we need. calculateAspectWidthToHeight
            //isn't a computed property because it depends on refs and the load event of the
            //images and that's a mess.
            this.calculateAspectWidthToHeight();
            this.touchStart = performance.now();
            this.touching = true;
            const animation = anime({
                targets: this.$el,
                autoplay: false,
                scale: 1.8,
                translateY: '-10%',
                easing: 'cubicBezier(0.050, -0.600, 0.000, 1.005)',
                duration: SCALE_DURATION,
            });
            animation.seek(0);
            const intensify = () => {
                if (this.touching) {
                    if (this.getDuration() < SCALE_DURATION) {
                        animation.seek(Math.min(this.getDuration(), SCALE_DURATION));
                    }
                    else {
                        if (!this.shake && this.getDuration() > SHAKE_AFTER) {
                            this.shake = true;
                        }
                        if (!this.explode && this.getDuration() > EXPLODE_AFTER) {
                            this.explode = true;
                        }
                    }
                    window.requestAnimationFrame(intensify);
                }
            };
            window.requestAnimationFrame(intensify);
        },
        onTouchEnd() {
            this.touching = false;
            const durationInSeconds = this.getDuration() / 1000;
            this.$nextTick(() => {
                this.$emit('engage', durationInSeconds);
            });
        },
        onTouchMoved() {
            this.dispatchEndEvent();
        },
        dispatchEndEvent() {
            const element = this.$refs.interactiveEmoji;
            if (element) {
                element.dispatchEvent(new Event('mouseup'));
                element.dispatchEvent(new Event('touchend'));
            }
        },
    },
});
