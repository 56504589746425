import firebase from 'firebase/app'
import { DBParams, QueryBuilder, UpdateType } from './types'
import { from, Observable } from 'rxjs'
import { switchMap } from 'rxjs/operators'
import { collectionData } from 'rxfire/firestore'
import { DBAuth } from '../../types'

export const splitBy = (n: number) =>
  function <T>(arr: T[]): Array<T[]> {
    const response = []
    for (let i = 0; i < arr.length; i = i += n) {
      response.push(arr.slice(i, i + n))
    }
    return response
  }

function createdSignature(
  uid: string,
  FieldValue: typeof firebase.firestore.FieldValue,
) {
  return {
    createdBy: uid,
    createdTime: FieldValue.serverTimestamp(),
    ...editedSignature(uid, FieldValue),
  }
}
function editedSignature(
  uid: string,
  FieldValue: typeof firebase.firestore.FieldValue,
) {
  return {
    editedBy: uid,
    editedTime: FieldValue.serverTimestamp(),
  }
}

function deletedSignature(
  uid: string,
  FieldValue: typeof firebase.firestore.FieldValue,
) {
  return {
    deleted: true,
    deletedBy: uid,
    deletedTime: FieldValue.serverTimestamp(),
  }
}
export const signatures = (
  FieldValue: typeof firebase.firestore.FieldValue,
) => ({
  edited: (uid: string) => editedSignature(uid, FieldValue),
  created: (uid: string) => createdSignature(uid, FieldValue),
  deleted: (uid: string) => deletedSignature(uid, FieldValue),
})

export const dbCollection = <T>(path: string) => ({
  firestore,
  dbAuth,
  FieldPath,
}: DBParams) => {
  const ref = firestore.collection(path)
  return (query?: QueryBuilder) => ({
    observe: () =>
      from(getUID(dbAuth)).pipe(
        switchMap((uid) =>
          collectionData<T>(query ? query(ref, { uid, FieldPath }) : ref, 'id'),
        ),
      ),

    get: async () => {
      const result = await (query
        ? query(ref, { uid: await getUID(dbAuth), FieldPath })
        : ref
      ).get()

      return mapDocs<T>(result)
    },

    update: async (
      update: UpdateType<T>,
      originalBatch?: firebase.firestore.WriteBatch,
    ) => {
      const batch = originalBatch ?? firestore.batch()

      const docs = await (query
        ? query(ref, { uid: await getUID(dbAuth), FieldPath })
        : ref
      ).get()

      for (const doc of docs.docs) {
        batch ? batch.update(doc.ref, update) : await doc.ref.update(update)
      }

      if (!originalBatch)
        return (batch as firebase.firestore.WriteBatch).commit()
    },

    delete: async (outerBatch?: firebase.firestore.WriteBatch) => {
      const batch = outerBatch || firestore.batch()

      const docs = await (query
        ? query(ref, { uid: await getUID(dbAuth), FieldPath })
        : ref
      ).get()

      docs.forEach((docSnapshot) => {
        batch.delete(docSnapshot.ref)
      })

      if (!outerBatch) {
        await batch.commit()
      }
    },
  })
}

export function mapDoc<T>(doc: firebase.firestore.DocumentSnapshot): T | null {
  if (doc.exists) return ({ id: doc.id, ...doc.data() } as unknown) as T
  return null
}

export function mapDocs<T>(snapshot: firebase.firestore.QuerySnapshot): T[] {
  return snapshot.docs.map(
    (doc) => (({ id: doc.id, ...doc.data() } as unknown) as T),
  )
}

export async function getUID(dbAuth: DBAuth): Promise<string> {
  const uid = await dbAuth.uid()
  if (!uid) {
    throw new Error('Expected user to be authenticated')
  }
  return uid
}

export const observe = <T>(ref: firebase.firestore.DocumentReference) => () =>
  new Observable<T>((sub) =>
    ref.onSnapshot(
      (snapshot) =>
        snapshot.exists &&
        sub.next(Object.assign({ id: snapshot.id }, snapshot.data()) as any),
    ),
  )
