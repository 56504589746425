import Vue from 'vue';
import VueSlider from '@lumiere/shared/components/VueSlider';
import anime from 'animejs';
// https://colordesigner.io/gradient-generator
const GRADIENT = [
    '#ff6a2a',
    '#ff7327',
    '#ff8c1e',
    '#ffac19',
    '#ffcb23',
    '#ffea3b',
    '#d9e03b',
    '#b4d63f',
    '#91ca44',
    '#6ebd4b',
    '#4baf51',
];
const DEFAULT_SLIDER_VALUE = 5;
export default Vue.extend({
    props: {
        prompt: {
            type: String,
            required: false,
        },
        required: {
            type: Boolean,
            required: true,
        },
        labels: {
            type: Object,
            required: true,
        },
        value: Object,
    },
    data() {
        return {
            rating: null,
            slider: DEFAULT_SLIDER_VALUE,
            skipUpdateFromSlider: false,
        };
    },
    methods: {
        highlightInvalid() {
            anime({
                targets: this.$refs.dot,
                translateX: [
                    { value: 80, duration: 300, easing: 'easeInOutSine', delay: 0 },
                    { value: -60, duration: 400, easing: 'easeInOutSine', delay: 200 },
                    { value: 0, duration: 200, easing: 'easeInOutSine', delay: 200 },
                ],
                scale: [
                    {
                        value: 1.25,
                        duration: 500,
                        easing: 'easeOutElastic(1.2, .5)',
                        delay: 1300,
                    },
                    {
                        value: 1,
                        duration: 250,
                        easing: 'easeOutElastic(1.2, .5)',
                        delay: 100,
                    },
                ],
            });
        },
    },
    watch: {
        value(v) {
            if (v === null) {
                this.skipUpdateFromSlider = true;
                this.slider = DEFAULT_SLIDER_VALUE;
                this.rating = null;
            }
        },
        rating(rating) {
            if (rating !== null)
                this.$emit('input', { rating });
        },
        slider(slider) {
            if (this.skipUpdateFromSlider) {
                this.skipUpdateFromSlider = false;
            }
            else {
                this.rating = slider;
            }
        },
    },
    computed: {
        color() {
            return this.rating !== null ? GRADIENT[this.rating] : '#333';
        },
    },
    components: {
        VueSlider,
    },
});
