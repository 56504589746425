import Vue from 'vue';
import lineClamp from 'vue-line-clamp';
import VueRx from 'vue-rx';
import { sync } from 'vuex-router-sync';
import App from './App.vue';
import DB from '@lumiere/db';
import lumiereFire from './plugins/lumiereFire';
import vuetify from './plugins/vuetify';
import createRouter from './router/router';
import auth from './services/auth';
import firestore, { FieldPath, FieldValue } from './services/firebase/firestore';
import createStore from './store/store';
import { formatTimeMillis, initials, truncate } from './utils/filters';
import { formatTime, formatThousands, } from '@lumiere/shared/utils/numberFormatter';
import RxConnect from './components/RxConnect';
import { abilitiesPlugin } from '@casl/vue';
import { LumiereAbility } from '@lumiere/permissions/types';
import VideoUploadQueue from './utils/videoUploadQueue';
import fileDownloadPlugin from './plugins/downloadFile';
import $confirm from './plugins/$confirm';
import logger from '@lumiere/shared/services/logger';
import VueEvents from 'vue-events';
import { sleep } from './utils/sleep';
import * as am4core from '@amcharts/amcharts4/core';
import { uploadFileToStorage } from './utils/uploadFileToStorage';
import auditLogger from './services/auditLogger';
import './utils/icons';
am4core.addLicense('CH248217748');
export const store = createStore();
export const router = createRouter(store);
export default function start() {
    // import './registerServiceWorker'
    logger.log('TAG_NAME', process.env.VUE_APP_TAG_NAME);
    logger.log('COMMIT_SHA', process.env.VUE_APP_COMMIT_SHA);
    Vue.prototype.$videoUploadQueue = new VideoUploadQueue(store);
    Vue.prototype.$sleep = sleep;
    Vue.prototype.$uploadFileToStorage = uploadFileToStorage;
    const db = new DB({
        firestore,
        dbAuth: auth.user(),
        FieldValue,
        FieldPath,
    });
    Vue.component('RxConnect', RxConnect);
    Vue.use(lumiereFire, { db, auth });
    Vue.use(fileDownloadPlugin);
    Vue.use(lineClamp);
    Vue.use(VueRx);
    Vue.use(VueEvents);
    Vue.use(auditLogger);
    Vue.filter('formatTime', formatTime);
    Vue.filter('formatTimeMillis', formatTimeMillis);
    Vue.filter('formatNumber', formatThousands);
    Vue.filter('initials', initials);
    Vue.filter('truncate', truncate);
    Vue.use(abilitiesPlugin, new LumiereAbility([]));
    sync(store, router);
    Vue.config.productionTip = false;
    Vue.use($confirm, {
        vuetify,
        color: 'red',
        buttonTrueColor: 'red',
        width: 370,
    });
    new Vue({
        router,
        store,
        vuetify,
        render: (h) => h(App),
    }).$mount('#app');
}
