import Vue from 'vue';
import BaseEmoji from './Emoji.vue';
const numFormatter = (count) => count.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
export default Vue.extend({
    name: 'InteractionEmoji',
    props: {
        id: {
            type: String,
            required: true,
        },
        name: {
            type: String,
        },
        size: {
            type: Number,
            default: 64,
        },
        downloadURL: {
            type: String,
            required: false,
        },
        clickCount: {
            type: Number,
        },
        showCount: Boolean,
    },
    computed: {
        labelSize() {
            const width = this.size;
            if (!width)
                return 'XL';
            if (width >= 64) {
                return 'XL';
            }
            else if (width >= 48) {
                return 'L';
            }
            else if (width >= 38) {
                return 'M';
            }
            else if (width >= 30) {
                return 'S';
            }
            else {
                return 'XS';
            }
        },
    },
    filters: {
        countFormatter(count) {
            if (+count < 10000) {
                // 1 to 9999
                return numFormatter(count);
            }
            else if (+count / 10000 < 100) {
                // 10,000 to 999,999
                return +(+count / 1000).toFixed(1) + 'k';
            }
            return +(+count / 1000000).toFixed(1) + 'm'; // 1,000,000 an above
        },
    },
    components: {
        BaseEmoji,
    },
});
