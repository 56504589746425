import Vue from 'vue';
import QuestionFeedbackText from './QuestionFeedbackText.vue';
import QuestionFeedbackChoice from './QuestionFeedbackChoice.vue';
import { QuestionType, } from '@lumiere/db/types';
const questionComponentTypes = {
    [QuestionType.Text]: QuestionFeedbackText,
    [QuestionType.Choice]: QuestionFeedbackChoice,
};
export default Vue.extend({
    props: {
        question: Object,
    },
    data() {
        return {
            response: {
                value: '',
                options: [],
            },
        };
    },
    computed: {
        isValid() {
            switch (this.question.type) {
                case QuestionType.Text:
                    return !!this.response.value.trim();
                case QuestionType.Choice: {
                    if (!this.response.options.length)
                        return false;
                    const hasOther = this.response.options.find((o) => o.text != null);
                    if (hasOther && !hasOther.text?.length)
                        return false;
                    return true;
                }
                default:
                    return false;
            }
        },
    },
    methods: {
        getQuestionComponent: (type) => questionComponentTypes[type],
        submit() {
            if (this.isValid) {
                this.$emit('response', this.response);
            }
        },
        skip() {
            this.$emit('response');
        },
    },
    components: { QuestionFeedbackText, QuestionFeedbackChoice },
});
