export var LinkAssetType;
(function (LinkAssetType) {
    LinkAssetType["video"] = "video";
    LinkAssetType["channel"] = "channel";
})(LinkAssetType || (LinkAssetType = {}));
export var VideoOutputsEnum;
(function (VideoOutputsEnum) {
    VideoOutputsEnum["VIEWS"] = "Views";
    VideoOutputsEnum["RETENTION"] = "Retention";
    VideoOutputsEnum["COMMENT_PLUS"] = "Comments Plus";
    VideoOutputsEnum["EMOJIS"] = "Emojis";
    VideoOutputsEnum["EXTERNAL_DATA"] = "External Data";
    VideoOutputsEnum["QUESTION"] = "Question";
})(VideoOutputsEnum || (VideoOutputsEnum = {}));
export var ChannelOutputsEnum;
(function (ChannelOutputsEnum) {
    ChannelOutputsEnum["CHANNEL_VIEWS"] = "Channel Views";
    ChannelOutputsEnum["VIDEO_VIEWS"] = "Video Views";
    ChannelOutputsEnum["AVG_RETENTION"] = "Average Retention";
    ChannelOutputsEnum["VIEWING_SHARE"] = "Share of Watching";
    ChannelOutputsEnum["CHANNEL_SCORE"] = "Channel Score";
})(ChannelOutputsEnum || (ChannelOutputsEnum = {}));
export var VideoPageOptionsEnum;
(function (VideoPageOptionsEnum) {
    VideoPageOptionsEnum["SHOW_VIDEO_LINK"] = "Show Video Link";
    VideoPageOptionsEnum["SHOW_VIDEO_SCENE"] = "Include Scene Data Filters";
    VideoPageOptionsEnum["SHOW_FACES_OUTPUT"] = "Show Faces Output";
})(VideoPageOptionsEnum || (VideoPageOptionsEnum = {}));
export var ChannelPageOptionsEnum;
(function (ChannelPageOptionsEnum) {
    ChannelPageOptionsEnum["SHOW_CHANNEL_LINK"] = "Show Channel Link";
    /**
     * If this option is enabled, a videos share link becomes enabled
     * 1. Enable share link on video if disabled
     * 2. Update the link to the video share link on drill to specific video
     */
    // DRILL_VIDEO = 'Allow Drilling into Single Video Output',
})(ChannelPageOptionsEnum || (ChannelPageOptionsEnum = {}));
export var LinkStatus;
(function (LinkStatus) {
    LinkStatus["enabled"] = "enabled";
    LinkStatus["disabled"] = "disabled";
})(LinkStatus || (LinkStatus = {}));
