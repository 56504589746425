import Vue from 'vue';
import VideoDetailsPosterScrubDialog from './VideoDetailsPosterScrubDialog.vue';
import getFilenameExtension from '@lumiere/shared/utils/getFilenameExtension';
import logger from '@lumiere/shared/services/logger';
import { getVideoDefaultPoster, getVideoPoster, } from '@lumiere/shared/utils/getVideoThumbnail';
import { FieldValue } from '@/services/firebase/firestore';
export default Vue.extend({
    name: 'VideoDetailsPoster',
    props: {
        video: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            loading: false,
        };
    },
    computed: {
        poster() {
            return getVideoPoster(this.video);
        },
        fileInput() {
            return this.$refs.fileInput;
        },
        defaultPoster() {
            return getVideoDefaultPoster(this.video);
        },
        isCurrentPosterDefault() {
            return getVideoPoster(this.video) === this.defaultPoster;
        },
    },
    watch: {},
    methods: {
        async savePoster(posterUrl) {
            this.loading = true;
            await this.$db
                .video(this.video.id)
                .update({ 'appearance.poster': posterUrl })
                .then(() => {
                this.loading = false;
                return this.logVideoPosterUpdate(posterUrl);
            });
        },
        async selectDefaultPoster() {
            await this.$db
                .video(this.video.id)
                .update({ 'appearance.poster': FieldValue.delete() })
                .then(() => this.logVideoPosterUpdate(null, true));
        },
        async uploadFile(file) {
            if (file) {
                this.loading = true;
                try {
                    const uploader = this.$uploadFileToStorage(file, `video/${this.video.id}/`, `poster.${getFilenameExtension(file.name)}`);
                    await uploader.getURL().then((url) => {
                        this.fileInput.value = '';
                        return this.savePoster(url);
                    });
                }
                catch (e) {
                    logger.error('something went wrong while uploading the poster image', e);
                }
                this.loading = false;
            }
        },
        async logVideoPosterUpdate(url, defaultPoster = false) {
            const ctx = await this.$auditLogger(this.$store.state.uid);
            if (ctx) {
                const { Asset_Model, EVENT_ACTION, ts } = ctx.config;
                const asset = {
                    id: this.video.id,
                    name: this.video.title,
                    type: Asset_Model.Video,
                };
                await ctx.exec(asset, {
                    timestamp: ts(),
                    action: EVENT_ACTION.UPDATED,
                    data: {
                        type: 'INFO',
                        payload: {
                            message: 'Video attributes changed',
                            extraInfo: {
                                poster: url,
                                usingDefaultPoster: defaultPoster,
                            },
                        },
                    },
                });
            }
        },
    },
    subscriptions() {
        return {};
    },
    components: {
        VideoDetailsPosterScrubDialog,
    },
});
