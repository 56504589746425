import Vue from 'vue';
import ChannelCard from '@/components/workspace/channels/ChannelCard.vue';
import StandaloneVideoCard from './StandaloneVideoCard.vue';
import { ChannelRoutes } from '@/router/router';
export default Vue.extend({
    name: 'VideoChannelMembership',
    props: {
        video: {
            type: Object,
        },
        videoChannels: {
            type: Array,
        },
        visitedChannelId: [Object, String],
        standaloneVideoFilter: [Object, String],
    },
    data() {
        const selectedItem = this.$props.visitedChannelId ?? this.$props.standaloneVideoFilter;
        return {
            selectedItem: selectedItem || null,
            shouldNav: false,
        };
    },
    computed: {
        routeLinkType() {
            return this.$ability.can('read', 'Channel') ? 'to' : 'href';
        },
        channelURL() {
            return (channel) => this.$ability.can('read', 'Channel')
                ? {
                    name: ChannelRoutes.Default,
                    params: {
                        channelId: channel.id,
                        channelCache: channel,
                    },
                }
                : this.$store.getters.channelURL(channel.id);
        },
        isSelected() {
            return (channelId) => this.selectedItem === channelId;
        },
        standaloneValue() {
            return 'standalone_video_filter';
        },
    },
    watch: {
        selectedItem(selectedId) {
            const curQuery = this.$route.query;
            let cid = undefined;
            let standalone_video_filter = undefined;
            if (selectedId) {
                if (selectedId === this.standaloneValue) {
                    standalone_video_filter = 'true';
                }
                else if (curQuery.cid !== selectedId) {
                    cid = selectedId;
                }
            }
            void this.$router.replace({
                query: {
                    ...curQuery,
                    cid,
                    standalone_video_filter,
                },
            });
        },
    },
    components: {
        ChannelCard,
        StandaloneVideoCard,
    },
});
