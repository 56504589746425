import { render, staticRenderFns } from "./GlobalSearchWorkspaces.vue?vue&type=template&id=32188af6&"
import script from "./GlobalSearchWorkspaces.vue?vue&type=script&lang=ts&"
export * from "./GlobalSearchWorkspaces.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/.pnpm/vue-loader@15.9.7_fb85a9817f99bdf862e5e3a442a1fb91/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../../../node_modules/.pnpm/vuetify-loader@1.7.2_c225a462abeaf467a2189c5bd12e241a/node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VImg } from 'vuetify/lib/components/VImg';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemAvatar } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemSubtitle } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VSubheader } from 'vuetify/lib/components/VSubheader';
installComponents(component, {VImg,VList,VListItem,VListItemAvatar,VListItemContent,VListItemSubtitle,VListItemTitle,VSubheader})
