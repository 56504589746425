import Vue from 'vue';
import { nanoid } from 'nanoid';
import logger from '@lumiere/shared/services/logger';
export default Vue.extend({
    name: 'EmojiUploaderItem',
    props: {
        emojiFiles: {
            type: FileList,
        },
    },
    data: () => ({
        uploadedFilesCount: 0,
        workspaceId: '',
        isUploading: true,
        error_message: '',
    }),
    mounted() {
        this.workspaceId = this.$store.getters.currentWorkspace.id;
        this.uploadFiles();
    },
    computed: {
        uploadProgress() {
            return this.emojiFiles.length > 0
                ? Math.round((100 / this.emojiFiles.length) * this.uploadedFilesCount)
                : 100;
        },
    },
    methods: {
        async uploadFile(file) {
            const wid = this.workspaceId;
            const uploader = this.$uploadFileToStorage(file, `workspace-emoji/${wid}/`, nanoid(6));
            await uploader
                .getURL()
                .then((url) => {
                // save the downloadURL to firestore
                void this.updateWorkspaceEmojiSubcollection(file, url, wid);
                this.uploadedFilesCount++;
            })
                .catch((_e) => {
                throw new Error('Upload Error: please ensure you select a valid file type');
            });
        },
        uploadFiles() {
            for (const file of this.emojiFiles) {
                if (file) {
                    this.uploadFile(file).catch((e) => {
                        // something went wrong while uploading emojis
                        logger.log('UploadFile Error', e);
                        this.error_message = e.message;
                    });
                }
            }
        },
        /**
         * Update workspace emojis subcollection
         */
        async updateWorkspaceEmojiSubcollection(file, downloadURL, wid) {
            const eid = file.name.split('.').shift();
            const name = file.emojiName.toLowerCase();
            await this.$db.workspace(wid).emoji(eid).create({ downloadURL, name });
        },
    },
    watch: {
        uploadedFilesCount(val) {
            if (val === this.emojiFiles.length) {
                setTimeout(() => {
                    this.isUploading = false;
                    this.$emit('doneUploading');
                }, 500);
            }
        },
    },
});
