import Vue from 'vue';
import VideoSettingsRecentlyDeletedFeaturesButton from './VideoSettingsRecentlyDeletedFeaturesButton.vue';
import { timestampToDate } from '@/utils/helpers';
export default Vue.extend({
    name: 'VideoSettingsRecentlyDeletedFeatures',
    props: {
        deletedFeatures: Array,
        videoId: String,
    },
    data() {
        return {
            dialog: false,
            restoring: false,
            selectedFeatures: [],
        };
    },
    methods: {
        async restoreFeature() {
            const toConfirm = await this.$confirm(`Do you want to restore the selected features?`, {
                buttonTrueText: 'Yes, restore',
                buttonFalseText: 'Dismiss',
            });
            if (toConfirm) {
                this.restoring = true;
                for (let feature of this.selectedFeatures) {
                    await this.$db
                        .video(this.videoId)
                        .feature(feature.id)
                        .restore()
                        .then(async () => {
                        const ctx = await this.$auditLogger(this.$store.state.uid);
                        if (ctx) {
                            const { Asset_Model, EVENT_ACTION, ts } = ctx.config;
                            const asset = {
                                id: feature.id,
                                name: feature.label,
                                type: Asset_Model['Video.Feature'],
                            };
                            await ctx.exec(asset, {
                                timestamp: ts(),
                                action: EVENT_ACTION.RESTORED,
                                data: {
                                    type: 'INFO',
                                    payload: {
                                        message: `Soft deleted feature is restored`,
                                    },
                                },
                            });
                        }
                    });
                }
                this.restoring = false;
            }
        },
        timestampToDate(ts) {
            return timestampToDate(ts);
        },
    },
    watch: {},
    components: {
        VideoSettingsRecentlyDeletedFeaturesButton,
    },
});
