import COLORS from '@/colors';
// const app_theme_color = '#FB792C'
const default_color = '#FFFFFF';
/**
 * Update the color of the status bar (usually android) to match the workspace color
 * @param color the color to set the StatusBar to
 */
export function updateStatusBarColor(color = default_color) {
    color = COLORS[color] ?? color;
    const statusbar_meta = `
        <meta name="theme-color" content="${color}">
      `;
    document.head.insertAdjacentHTML('afterbegin', statusbar_meta);
}
