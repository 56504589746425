import Vue from 'vue';
export default Vue.extend({
    name: 'SectionItem',
    props: {
        title: {
            type: String,
        },
        media: {
            type: String,
            default: 'icon',
        },
    },
    computed: {
        isIcon() {
            return this.media === 'icon';
        },
    },
});
