import Vue from 'vue';
import MUTATIONS from '@/store/MUTATIONS';
import auth from '@/services/auth';
import logger from '@lumiere/shared/services/logger';
export default Vue.extend({
    name: 'UserProfileTitle',
    props: {
        user: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            editing: false,
        };
    },
    computed: {
        inputRef() {
            return this.$refs.input;
        },
    },
    watch: {},
    methods: {
        async updateUserDisplayName(displayName) {
            logger.info('update data', { displayName });
            if (displayName !== this.user.displayName) {
                const user = await auth.getCurrentUser();
                if (user) {
                    await Promise.all([
                        user.updateProfile({
                            displayName,
                        }),
                        this.$db.user(this.user.id).update({ displayName }),
                    ])
                        .then(() => {
                        logger.info('Update successful.');
                        this.$store.commit(MUTATIONS.SET_USER, { ...user, displayName });
                    })
                        .catch((err) => {
                        logger.info('Update user profile error', err);
                    });
                }
            }
            this.editing = false;
        },
        editDisplayName() {
            this.editing = true;
            this.$nextTick(() => {
                this.inputRef.focus();
            });
        },
    },
});
