import Vue from 'vue';
import { ChannelTileSize } from '@lumiere/db/types';
import ChannelColor from './ChannelColor.vue';
import CustomCSS from '@/components/common/CustomCSS.vue';
import { FieldValue } from '@/services/firebase/firestore';
export default Vue.extend({
    name: 'ChannelAppearance',
    props: {
        channel: {
            type: Object,
            required: true,
        },
    },
    data() {
        const { tileSize } = this.channel.options;
        return {
            loading: false,
            selectedTileSize: tileSize,
            tileSizeItems: [
                ChannelTileSize.Small,
                ChannelTileSize.Medium,
                ChannelTileSize.Large,
            ],
            tileSelectionMap: {
                [ChannelTileSize.Small]: 'Small (Max 4 Across)',
                [ChannelTileSize.Medium]: 'Medium (Max 3 Across)',
                [ChannelTileSize.Large]: 'Large (Max 2 Across)',
            },
        };
    },
    watch: {
        selectedTileSize(newVal, oldVal) {
            if (newVal != oldVal) {
                const tileSize = newVal;
                this.save({ tileSize });
            }
        },
    },
    computed: {
        css() {
            return this.channel.options?.css || '';
        },
    },
    methods: {
        async save(options) {
            this.loading = true;
            const keys = Object.keys(options);
            const updateObject = keys.reduce((v, key) => ({
                ...v,
                [`options.${key}`]: options[key] ?? FieldValue.delete(),
            }), {});
            await this.$db.channel(this.channel.id).update(updateObject);
            this.loading = false;
        },
    },
    subscriptions() {
        return {};
    },
    components: {
        ChannelColor,
        CustomCSS,
    },
});
