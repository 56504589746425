import Vue from 'vue';
import SignupWithOptIn from './SignupWithOptIn.vue';
import { Routes } from '@/router/router';
const IS_LUMIERE_OPEN = false;
export default Vue.extend({
    name: 'SignupForm',
    props: {
        nonUserEmail: {
            type: String,
        },
    },
    data() {
        return {
            form: false,
            loading: false,
            hasError: false,
            errorMessage: '',
            passwordDetails: {
                newPassword: '',
                confirmPassword: '',
            },
            revealNewPassword: false,
            revealConfirmPassword: false,
            email: '',
            fname: '',
            lname: '',
        };
    },
    computed: {
        disableSubmitBtn() {
            const { newPassword, confirmPassword } = this.passwordDetails;
            return (!(newPassword && confirmPassword) || newPassword !== confirmPassword);
        },
        rules() {
            return {
                mustMatchNewPassword: (value) => !value
                    ? ''
                    : value === this.passwordDetails.newPassword || 'Password mismatch',
            };
        },
        isLumiereOpen() {
            return IS_LUMIERE_OPEN;
        },
    },
    methods: {
        submit() { },
        gotoLogin() {
            this.$router.push({
                name: Routes.Login,
            });
        },
    },
    components: {
        SignupWithOptIn,
    },
});
