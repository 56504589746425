var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"lumiere-video",attrs:{"id":_vm.escapeNumericVideoId}},[(_vm.customCSS)?_c('style',{directives:[{name:"show",rawName:"v-show",value:(false),expression:"false"}],tag:"component"},[_vm._v(" "+_vm._s(_vm.customCSS)+" ")]):_vm._e(),_c('style',{tag:"component"},[_vm._v(" #"+_vm._s(_vm.escapeNumericVideoId)+" { --player-color: "+_vm._s(_vm.playerColor)+" } ")]),_c('VideoPlayer',{ref:"videoPlayer",attrs:{"src":_vm.src,"aspectRatio":_vm.aspectRatio,"duration":_vm.video.duration,"posterImage":_vm.videoPosterURL,"options":_vm.options,"behaviors":_vm.videoBehaviors,"logo":_vm.logo,"videoId":_vm.video.id,"shouldAutoPlay":_vm.$attrs.shouldAutoPlay || _vm.shouldAutoPlay,"forceScrubbing":_vm.forceScrubbing,"shouldPauseOnBlur":_vm.shouldPauseOnBlur,"showPlayerControls":_vm.showVideoPlayerControls && !_vm.commentBoxActivated,"loop":_vm.loopVideo},on:{"state-change":_vm.onStateChange,"timeupdate":_vm.onTimeUpdate,"scrubbing":_vm.onScrubbing,"toggle-play":_vm.togglePlay,"big-play-button-clicked":_vm.onBigPlayButtonClicked},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var overlay = ref.overlay;
var container = ref.container;
var showingControls = ref.showingControls;
return [_c('LumiereVideoFeaturesZone',{attrs:{"container":container,"overlay":overlay,"showingControls":showingControls && _vm.showVideoPlayerControls,"showFeatures":_vm.showFeatures},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var featuresZone = ref.featuresZone;
return [_c('CommentFeedbacksContainer',{directives:[{name:"show",rawName:"v-show",value:(_vm.featureInFocus === null),expression:"featureInFocus === null"}],attrs:{"features":_vm.commentFeatures,"getTime":function () { return _vm.currentTime; },"overlay":overlay,"container":container,"featuresZone":featuresZone,"videoState":_vm.state},on:{"feedback":_vm.emitFeedback,"comment-box-toggle":_vm.onCommentBoxActivated,"tagging-mode":_vm.onToggleTaggingMode}}),(!_vm.commentBoxActivated)?_c('EmojiFeedbackContainer',{directives:[{name:"show",rawName:"v-show",value:(_vm.featureInFocus === null),expression:"featureInFocus === null"}],ref:"emojiFeedbackContainer",attrs:{"emojiFeatures":_vm.emojiFeatures,"videoId":_vm.video.id,"appName":_vm.appName,"currentTime":_vm.currentTime,"isViewerExperience":_vm.isViewerExperience,"overlay":overlay},on:{"feedback":_vm.emitFeedback}}):_vm._e(),(!_vm.commentBoxActivated)?_c('TextPromptFeedbackContainer',{directives:[{name:"show",rawName:"v-show",value:(_vm.featureInFocus === null),expression:"featureInFocus === null"}],attrs:{"textPromptFeatures":_vm.textPromptFeatures,"currentTime":_vm.currentTime,"videoId":_vm.video.id,"overlay":overlay,"isViewerExperience":_vm.isViewerExperience}}):_vm._e(),_c('QuestionFeedbackContainer',{attrs:{"questions":_vm.questionFeatures,"videoState":_vm.state,"currentTime":_vm.currentTime,"emitFeedback":_vm.emitFeedback},on:{"feedback":_vm.emitFeedback,"activated":_vm.onQuestionFeatureActivated,"finished":_vm.onQuestionFeatureFinished}})]}}],null,true)})]}},{key:"overlay",fn:function(ref){return [_vm._t("default")]}},{key:"extensions",fn:function(ref){
var vjs = ref.vjs;
return [_vm._t("video-extensions",null,{"vjs":vjs})]}}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }