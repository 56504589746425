import Vue from 'vue';
import adminAPI from '@/services/adminAPI';
import logger from '@lumiere/shared/services/logger';
import VideoIntelligenceManagePersons from './VideoIntelligenceManagePersons.vue';
import { startWith, pluck, map, switchMap, shareReplay } from 'rxjs/operators';
import isEmpty from 'lodash/isEmpty';
import debounce from 'lodash/debounce';
export default Vue.extend({
    name: 'VideoIntelligenceViewResult',
    props: {
        video: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            reloadDecodedDataLoading: false,
            getVideoIntelligenceDataLoading: false,
            videoIntelligenceDataSummary: null,
            persons: [],
            facesMap: {},
        };
    },
    computed: {
        scenes_count() {
            return (this.videoIntelligenceDataSummary?.scenes.unique_detections.value ?? 0);
        },
        shots_count() {
            return (this.videoIntelligenceDataSummary?.shots.unique_detections.value ?? 0);
        },
        transcript_count() {
            return (this.videoIntelligenceDataSummary?.transcript.unique_detections.value ??
                0);
        },
    },
    methods: {
        // fetch result from ES onMount
        async getVideoIntelligenceDataSummary() {
            this.getVideoIntelligenceDataLoading = true;
            const result = await adminAPI(async (api) => api.video.videoIntelligence.getVideoIntelligenceDataSummary({
                vid: this.video.id,
            })).catch((e) => {
                logger.error('getVideoIntelligenceDataSummary', e);
            });
            this.getVideoIntelligenceDataLoading = false;
            if (result) {
                this.videoIntelligenceDataSummary = result;
                logger.info('getVideoIntelligenceDataSummary', result);
            }
        },
        reloadDecodedVideoData() {
            this.$confirm(`Do you want to overwrite the existing decoded data for this video`, {
                buttonTrueText: 'Continue',
                buttonFalseText: 'Dismiss',
            }).then(async (ok) => {
                if (ok) {
                    this.reloadDecodedDataLoading = true;
                    const result = await adminAPI(async (api) => api.video.videoIntelligence.reloadDecodedVideoData({
                        vid: this.video.id,
                    })).catch((e) => {
                        logger.error('reloadDecodedVideoData', e);
                    });
                    this.reloadDecodedDataLoading = false;
                    logger.info('reloadDecodedVideoData', result);
                }
            });
        },
        async getFaceDetections() {
            const facesMap = await adminAPI(async (api) => api.video.videoIntelligence.getVideoIntelligenceFaceDetections({
                vid: this.video.id,
            }));
            this.facesMap = facesMap;
        },
        fetchVideoAIData: debounce(function () {
            this.getVideoIntelligenceDataSummary();
            this.getFaceDetections();
        }, 500),
    },
    mounted() {
        this.fetchVideoAIData();
    },
    watch: {
        facesMap: {
            handler(facesMap) {
                if (this.persons.length && isEmpty(facesMap)) {
                    this.fetchVideoAIData();
                }
            },
        },
        persons: {
            handler(persons) {
                if (persons?.length && isEmpty(this.facesMap)) {
                    this.fetchVideoAIData();
                }
            },
        },
    },
    subscriptions() {
        const facesMap$ = this.$watchAsObservable('facesMap', {
            immediate: true,
            deep: true,
        }).pipe(pluck('newValue'));
        return {
            persons: facesMap$.pipe(switchMap((facesMap) => this.$db
                .video(this.$props.video.id)
                .persons()
                .observe()
                .pipe(map((persons) => {
                return persons.map((person) => {
                    const newPerson = { ...person };
                    newPerson['instances'] = person.faceIds.flatMap((fid) => facesMap[fid]?.instances ?? []);
                    return newPerson;
                });
            }))), shareReplay(1), startWith([])),
        };
    },
    components: {
        VideoIntelligenceManagePersons,
    },
});
