import config from '../config'
import * as Sentry from '@sentry/browser'
import { Vue as VueIntegration } from '@sentry/integrations'
import Vue from 'vue'

const sentryConfig = {
  release: process.env.VUE_APP_TAG_NAME || '',
  environment: config.name || '',
  dsn: process.env.VUE_APP_SENTRY_DSN || '',
  enabled: process.env.VUE_APP_PROJECT_ENV !== 'local',
  integrations: [
    new VueIntegration({
      Vue,
      attachProps: true,
      logErrors: true,
    }),
  ],
}

Sentry.init(sentryConfig)
