import Vue from 'vue';
import getRandomElement from '@lumiere/shared/utils/getRandomElement';
import COLORS from '@/colors';
import { nanoid } from 'nanoid';
import { mapGetters } from 'vuex';
const getRandomColor = getRandomElement(Object.values(COLORS));
export default Vue.extend({
    name: 'ManageTagsEditTagForm',
    props: {
        tag: Object,
        id: String,
        workspaceId: {
            required: true,
            type: String,
        },
    },
    data() {
        return {
            label: this.tag?.label || '',
            color: this.tag?.color || getRandomColor(),
            saving: false,
        };
    },
    computed: {
        ...mapGetters(['currentWorkspace']),
        inputRef() {
            return this.$refs.input;
        },
        COLORS() {
            return COLORS;
        },
    },
    watch: {},
    methods: {
        async onSubmit() {
            this.saving = true;
            const { label, color } = this;
            const id = this.id ?? nanoid();
            this.$emit('saving');
            const payload = {
                label,
                color,
                nonce: this.tag?.nonce || Date.now(),
            };
            await this.$db
                .workspace(this.workspaceId)
                .update({
                [`tags.${id}`]: payload,
            })
                .then(async () => {
                this.$emit('complete', id);
                this.saving = false;
                const ctx = await this.$auditLogger(this.$store.state.uid);
                if (ctx) {
                    const { Asset_Model, EVENT_ACTION, ts } = ctx.config;
                    const asset = {
                        id: this.workspaceId,
                        name: this.currentWorkspace.name,
                        type: Asset_Model.Workspace,
                    };
                    await ctx.exec(asset, {
                        timestamp: ts(),
                        action: this.id ? EVENT_ACTION.UPDATED : EVENT_ACTION.ADDED,
                        data: {
                            type: 'INFO',
                            payload: {
                                message: `Workspace attributes changed: ${this.id ? 'tag updated' : 'tag added'}`,
                                extraInfo: {
                                    tagId: id,
                                    payload,
                                },
                            },
                        },
                    });
                }
                const workspaceChannels = await this.$db
                    .channels((ref) => ref.where('workspaceId', '==', this.workspaceId))
                    .get();
                const channelsWithTheTag = workspaceChannels.filter(({ channelTags }) => Object.keys(channelTags).includes(id));
                for (let channel of channelsWithTheTag) {
                    this.$db
                        .channel(channel.id)
                        .update({
                        [`channelTags.${id}`]: payload,
                    })
                        .then(async () => {
                        const ctx = await this.$auditLogger(this.$store.state.uid);
                        if (ctx) {
                            const { Asset_Model, EVENT_ACTION, ts } = ctx.config;
                            const asset = {
                                id: channel.id,
                                name: channel.title,
                                type: Asset_Model.Channel,
                            };
                            await ctx.exec(asset, {
                                timestamp: ts(),
                                action: EVENT_ACTION.UPDATED,
                                data: {
                                    type: 'INFO',
                                    payload: {
                                        message: 'Channel attributes changed: tags updated',
                                        extraInfo: {
                                            tags: channel.channelTags,
                                            tagId: id,
                                            payload,
                                        },
                                    },
                                },
                            });
                        }
                    })
                        .catch((err) => console.log(err));
                }
            });
        },
    },
    mounted() {
        this.inputRef.focus();
    },
    components: {},
});
