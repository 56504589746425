import Vue from 'vue';
export const defaultOptions = () => ({
    shouldPauseVideo: false,
    alwaysExpanded: false,
    spacialTagging: false,
    spacialTaggingRequired: false,
    hidePromptWhenSpacialTaggingEnabled: false,
});
export default Vue.extend({
    name: 'CommentFeedbackOptions',
    props: {
        value: {
            type: Object,
        },
    },
    data() {
        return {
            options: { ...defaultOptions(), ...this.$props.value },
        };
    },
    watch: {
        options: {
            handler(options) {
                this.$emit('input', options);
                if (options.spacialTagging) {
                    this.options.alwaysExpanded = false;
                }
                else {
                    this.options.spacialTaggingRequired = false;
                }
            },
            deep: true,
        },
    },
});
