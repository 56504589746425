import Vue from 'vue';
export default Vue.extend({
    name: 'TextPromptFeedbackOptions',
    props: {
        value: Object,
        variant: String,
    },
    data() {
        return {
            options: { ...this.$props.value },
        };
    },
    computed: {
        rules() {
            return {
                maxDescriptionLength: (value) => value.length < 500 || 'Description is too long',
                maxPlaceholderLength: (value) => value.length < 150 || 'Placeholder is too long',
                required: (value) => !!value || 'Prompt is required.',
            };
        },
    },
    watch: {
        options: {
            handler(options) {
                this.$emit('input', options);
            },
            deep: true,
        },
    },
});
