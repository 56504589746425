import Vue from 'vue';
import { CornerPosition } from '@lumiere/db/types';
export default Vue.extend({
    props: {
        value: {
            type: String,
            required: true,
        },
        showCenterPositions: Boolean,
    },
    computed: {
        CornerPosition() {
            return CornerPosition;
        },
        positions() {
            const positions = [
                CornerPosition.TopLeft,
                CornerPosition.TopRight,
                CornerPosition.BottomLeft,
                CornerPosition.BottomRight,
            ];
            if (this.showCenterPositions) {
                positions.push(CornerPosition.TopCenter, CornerPosition.BottomCenter);
            }
            return positions;
        },
    },
    data() {
        return {
            position: this.$props.value,
        };
    },
    watch: {
        position(position) {
            this.$emit('input', position);
        },
    },
});
