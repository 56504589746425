import Vue from 'vue';
export default Vue.extend({
    name: 'ChannelDataManagementEditEidForm',
    props: {
        eids: {
            type: Array,
            required: true,
        },
        shoudSubmitEids: Boolean,
        saving: Boolean,
    },
    data() {
        return {
            editing: false,
            eidsList: this.$props.eids,
        };
    },
    computed: {
        inputRef() {
            return this.$refs.input;
        },
        eidsText() {
            if (this.eidsList.length) {
                return this.eidsList.join('\n');
            }
            return '';
        },
    },
    watch: {
        shoudSubmitEids(val) {
            if (val) {
                this.$emit('submit-eids', this.eidsList);
            }
        },
    },
    methods: {
        updateEid(eid) {
            if (eid !== this.eidsText) {
                this.eidsList = [];
                const eidsList = eid
                    .trim()
                    .replace(/[\n,]/gi, ' ')
                    .split(' ')
                    .filter(Boolean);
                this.$nextTick(() => {
                    this.eidsList = eidsList;
                });
            }
            this.editing = false;
        },
        editEid() {
            this.editing = true;
            this.$nextTick(() => {
                this.inputRef.focus();
            });
        },
    },
});
