import firebase from 'firebase/app';
import 'firebase/auth';
export const FirebaseAuth = firebase.auth;
const auth = FirebaseAuth();
export async function getCurrentUser() {
    return new Promise((resolve, reject) => {
        if (auth.currentUser) {
            resolve(auth.currentUser);
        }
        const unsubscribe = auth.onAuthStateChanged((user) => {
            unsubscribe();
            resolve(user);
        }, reject);
    });
}
export default auth;
