import Vue from 'vue';
import customAuth, { SOCIAL_PROVIDER_IDS } from '@/services/auth';
import logger from '@lumiere/shared/services/logger';
import { FirebaseAuth } from '@/services/firebase/auth';
import { mapState } from 'vuex';
const auth = FirebaseAuth();
export default Vue.extend({
    name: 'UserProfileAddPassword',
    props: {
        dark: Boolean,
        disabled: Boolean,
        providersIds: Array,
    },
    data() {
        return {
            addPasswordDialog: false,
            userDetails: {
                password: '',
                confirmPassword: '',
            },
            loading: false,
            addPasswordForm: false,
            revealPassword: false,
            revealConfirmPassword: false,
        };
    },
    computed: {
        ...mapState(['user']),
        disableSubmitBtn() {
            return !(this.userDetails.confirmPassword && this.userDetails.password);
        },
    },
    methods: {
        async googleAuth() {
            const provider = new FirebaseAuth.GoogleAuthProvider();
            auth.useDeviceLanguage();
            provider.setCustomParameters({
                login_hint: 'user@latd.com',
            });
            return await this.socialAuthHandler(provider);
        },
        async facebookAuth() {
            const provider = new FirebaseAuth.FacebookAuthProvider();
            auth.useDeviceLanguage();
            provider.setCustomParameters({
                display: 'popup',
                auth_type: 'rerequest',
            });
            return await this.socialAuthHandler(provider);
        },
        async twitterAuth() {
            const provider = new FirebaseAuth.TwitterAuthProvider();
            auth.useDeviceLanguage();
            provider.setCustomParameters({
                lang: 'en',
            });
            return await this.socialAuthHandler(provider);
        },
        async socialAuthHandler(provider) {
            // this is where we decide which flow to use.
            // N.B: You can remove popup flow and still get similar result
            const user = await customAuth.getCurrentUser();
            if (!user)
                throw new Error('User not authenticated');
            return await user
                .reauthenticateWithPopup(provider)
                .then((res) => res)
                .catch((e) => e);
        },
        async getCredential(providerId) {
            // token: string, secret: string = '') {
            switch (providerId) {
                case SOCIAL_PROVIDER_IDS.GOOGLE_PROVIDER_ID:
                    // return FirebaseAuth.GoogleAuthProvider.credential(token)
                    return await this.googleAuth();
                case SOCIAL_PROVIDER_IDS.FACEBOOK_PROVIDER_ID:
                    // return FirebaseAuth.FacebookAuthProvider.credential(token)
                    return await this.facebookAuth();
                case SOCIAL_PROVIDER_IDS.TWITTER_PROVIDER_ID:
                    // return FirebaseAuth.TwitterAuthProvider.credential(token, secret)
                    return await this.twitterAuth();
                default:
                    return null;
            }
        },
        async addPassword() {
            this.loading = true;
            const { email } = this.user;
            const { confirmPassword, password } = this.userDetails;
            if (password !== confirmPassword) {
                await this.$confirm('Password mismatch, please retype', {
                    buttonTrueText: 'Ok',
                    buttonFalseText: '',
                });
                this.loading = false;
                return;
            }
            const user = await customAuth.getCurrentUser();
            if (user) {
                const providerId = this.providersIds[0];
                const result = await this.getCredential(providerId);
                if (result && !(result instanceof Error)) {
                    const credential = FirebaseAuth.EmailAuthProvider.credential(email, password);
                    if (credential) {
                        await user
                            .linkWithCredential(credential)
                            .then(({ user, credential }) => {
                            logger.info('Account linking success', { user });
                            this.$emit('linking-success', { user, credential });
                        })
                            .catch((error) => {
                            logger.info('Account linking error', error);
                        });
                    }
                }
                else {
                    logger.info('Account linking error', result);
                    await this.$confirm(result?.message ||
                        'Account mismatch. Please try again with the right account', {
                        buttonTrueText: 'OK',
                        buttonFalseText: '',
                    });
                }
            }
            this.userDetails = {
                confirmPassword: '',
                password: '',
            };
            this.loading = false;
            this.addPasswordDialog = false;
        },
    },
});
