import Vue from 'vue';
import { ScrubbingStates, VideoState, } from '../types';
import { BigPlayButton, PlayPauseControl, ScrubTrack, VolumeControl, ExtraWidget, } from './controls';
import 'vue-slider-component/theme/default.css';
import { formatTime } from '@lumiere/shared/utils/numberFormatter';
import { sleep } from '@lumiere/shared/utils/sleep';
import { SPACEBAR_KEYCODE, SPACEBAR_KEY } from '@lumiere/shared/utils/constants';
export default Vue.extend({
    name: 'VideoPlayerControls',
    props: {
        state: {
            type: String,
            default: VideoState.Ready,
        },
        storyboard: {
            type: String,
            default: null,
        },
        currentTime: {
            type: Number,
            default: 0,
        },
        duration: {
            type: Number,
        },
        isMouseOver: {
            type: Boolean,
            default: false,
        },
        stickyControls: {
            type: Boolean,
            default: false,
        },
        bufferedPercent: {
            type: Number,
            default: 0,
        },
        isBuffering: {
            type: Boolean,
        },
        allowScrubbing: Boolean,
        scrubberMode: {
            type: String,
        },
        selectedRange: {
            type: Array,
        },
        controlsVisible: Boolean,
    },
    data() {
        return {
            buffers: [],
            isScrubbing: false,
        };
    },
    computed: {
        showBigPlayButton() {
            return this.state === VideoState.Ended || this.state === VideoState.Ready;
        },
        showControls() {
            if (this.stickyControls) {
                return true;
            }
            return this.showBigPlayButton
                ? false
                : this.state === VideoState.Paused || this.isMouseOver
                    ? true
                    : false;
        },
    },
    methods: {
        play() {
            this.$emit('toggle-play', { value: true });
        },
        pause() {
            this.$emit('toggle-play', { value: false });
        },
        bigPlayButtonClicked() {
            this.$emit('big-play-button-clicked');
            this.play();
        },
        onScrubbing(data) {
            this.$emit('scrubbing', data);
            this.isScrubbing = [
                ScrubbingStates.START,
                ScrubbingStates.ACTIVE,
            ].includes(data.state);
        },
        spacebarTogglePlayBack(e) {
            // handle this: https://github.com/latitudelabs/lumiere-v3/commit/052552e00f21f3f28241ee5700e4700dd4a15cad#commitcomment-43023843
            // and wait for the next event loop to activate
            /* eslint-disable @typescript-eslint/no-floating-promises */
            if (e.defaultPrevented)
                return;
            const inputElements = Array.from(document.getElementsByTagName('input'));
            const textAreaElements = Array.from(document.getElementsByTagName('textarea'));
            const activeInputElement = inputElements.find((el) => document.activeElement === el);
            const activeTextAreaElement = textAreaElements.find((el) => document.activeElement === el);
            if (activeInputElement || activeTextAreaElement)
                return;
            if ((e.key == SPACEBAR_KEY || e.keyCode == SPACEBAR_KEYCODE) &&
                this.controlsVisible) {
                if (this.state === VideoState.Playing) {
                    sleep(0).then(this.pause);
                }
                else if (this.state === VideoState.Paused) {
                    sleep(0).then(this.play);
                }
            }
            /* eslint-enable @typescript-eslint/no-floating-promises */
            e.cancelBubble = true;
            // this is meant/supposed to cancel any activated keyboard event in the process :)
            return false;
        },
    },
    mounted() {
        document.addEventListener('keyup', this.spacebarTogglePlayBack);
    },
    filters: {
        formatTime,
    },
    watch: {
        showControls(val) {
            this.$emit('showing-player-controls', val);
        },
    },
    components: {
        BigPlayButton,
        PlayPauseControl,
        ScrubTrack,
        VolumeControl,
        ExtraWidget,
    },
    beforeDestroy() {
        document.removeEventListener('keyup', this.spacebarTogglePlayBack);
    },
});
