import Vue from 'vue';
import { TeamRole } from '@lumiere/db/types';
import { map, startWith } from 'rxjs/operators';
import adminAPI from '@/services/adminAPI';
import CreateTeamForm from '@/components/team/CreateTeamForm.vue';
import RestoreRecentlyDeletedTeams from '@/components/team/RestoreRecentlyDeletedTeams.vue';
export default Vue.extend({
    name: 'UserTeams',
    data() {
        return {
            leaving: false,
            leavingTeamIds: {},
            createTeamDialog: false,
        };
    },
    computed: {
        userIsSystemAdmin() {
            return this.$store.state.admin;
        },
    },
    subscriptions() {
        const { uid } = this.$store.state;
        return {
            teams: this.$db
                .teams((ref) => ref.where(`roles.${uid}`, 'in', Object.values(TeamRole)))
                .observe()
                .pipe(map((teams) => teams
                .filter(({ deleted }) => !deleted)
                .map((team) => ({
                ...team,
                myRole: team.roles[uid],
            }))), startWith([])),
            deletedTeams: this.$db
                .teams((ref) => ref
                .where(`roles.${uid}`, '==', TeamRole.ADMIN)
                .where('deleted', '==', true))
                .observe()
                .pipe(startWith([])),
        };
    },
    methods: {
        showCreateTeamDialog() { },
        isTeamCreator(createdBy) {
            return createdBy === this.$store.state.uid;
        },
        async leaveTeam(teamId, teamName) {
            const toLeaveTeam = await this.$confirm(`You are about to leave the ${teamName} team.\nNote that this action is not reversible`, {
                buttonTrueText: 'Continue',
                buttonFalseText: 'Cancel',
            });
            if (toLeaveTeam) {
                const leavingIds = { ...this.leavingTeamIds };
                leavingIds[teamId] = true;
                this.leavingTeamIds = { ...leavingIds };
                this.leaving = true;
                await adminAPI(async (api) => api.user.leaveTeam({ tid: teamId }));
                this.leaving = false;
                leavingIds[teamId] = false;
                this.leavingTeamIds = { ...leavingIds };
            }
        },
    },
    filters: {
        lowercase(text) {
            return text.toLowerCase();
        },
    },
    components: {
        CreateTeamForm,
        RestoreRecentlyDeletedTeams,
    },
});
