import { FeatureType } from '@lumiere/db/types';
const featureDefaults = {
    [FeatureType.Retention]: {
        label: 'Retention',
        icon: 'mdi-eye',
        enabled: false,
        description: '',
    },
    [FeatureType.Emojis]: {
        label: 'Emojis',
        icon: 'mdi-face',
        enabled: true,
        description: 'React with a set of built-in or custom emojis',
    },
    [FeatureType.Comments]: {
        label: 'Comments+',
        icon: 'mdi-comment-plus-outline',
        enabled: true,
        description: 'React to moments in an open-ended way with comments',
    },
    [FeatureType.TextPrompt]: {
        label: 'Text Prompt',
        icon: 'mdi-format-quote-close-outline',
        enabled: true,
        description: 'Present texts to viewers directly on the video',
    },
    [FeatureType.ExternalData]: {
        label: 'External Data',
        icon: 'mdi-file-upload-outline',
        enabled: true,
        description: 'Import an external dataset for visualization alongside other features',
    },
    [FeatureType.Question]: {
        label: 'Question',
        icon: 'mdi-comment-question-outline',
        enabled: true,
        description: 'Answer directed and open-ended questions',
    },
    [FeatureType.Tags]: {
        label: 'Tags',
        icon: 'mdi-crosshairs-gps',
        enabled: false,
        description: 'Attach tags to moments and on-screen elements',
    },
    [FeatureType.DialTest]: {
        label: 'Dial Test',
        icon: 'mdi-pan-horizontal',
        enabled: false,
        description: 'Track reactions from moment-to-moment',
    },
    [FeatureType.Instruction]: {
        label: 'Instruction',
        icon: 'mdi-comment-alert-outline',
        enabled: false,
        description: 'Prompt viewers with specific instructions',
    },
    [FeatureType.SocialChat]: {
        label: 'Social Chat',
        icon: 'mdi-comment-text-multiple-outline',
        enabled: false,
        description: 'Engage with and react to other viewers',
    },
    [FeatureType.Timer]: {
        label: 'Timer',
        icon: 'mdi-timer',
        enabled: false,
        description: 'Enable timed actions in a survey workflow',
    },
    [FeatureType.Redirect]: {
        label: 'Redirect',
        icon: 'mdi-exit-to-app',
        enabled: false,
        description: 'Redirect viewers at a specific point in your video',
    },
};
export default featureDefaults;
