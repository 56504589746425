import { user, users } from './db.users';
import { video, videos } from './db.videos';
import { workspace, workspaces } from './db.workspaces';
import { channels, channel } from './db.channels';
import { links, link } from './db.links';
import { team, teams } from './db.teams';
import { waitlist, waitlists } from './db.waitlists';
import { invitation, invitations } from './db.invitations';
export default class DB {
    constructor(params) {
        this.video = video(params);
        this.videos = videos(params);
        this.channel = channel(params);
        this.channels = channels(params);
        this.workspace = workspace(params);
        this.workspaces = workspaces(params);
        this.user = user(params);
        this.users = users(params);
        this.link = link(params);
        this.links = links(params);
        this.team = team(params);
        this.teams = teams(params);
        this.waitlist = waitlist(params);
        this.waitlists = waitlists(params);
        this.invitation = invitation(params);
        this.invitations = invitations(params);
        DB.instance = this;
    }
    static getInstance() {
        return DB.instance;
    }
}
