import { render, staticRenderFns } from "./Team.vue?vue&type=template&id=1cc185a2&scoped=true&"
import script from "./Team.vue?vue&type=script&lang=ts&"
export * from "./Team.vue?vue&type=script&lang=ts&"
import style0 from "./Team.vue?vue&type=style&index=0&id=1cc185a2&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/.pnpm/vue-loader@15.9.7_fb85a9817f99bdf862e5e3a442a1fb91/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1cc185a2",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../../node_modules/.pnpm/vuetify-loader@1.7.2_c225a462abeaf467a2189c5bd12e241a/node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VAvatar } from 'vuetify/lib/components/VAvatar';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';
installComponents(component, {VAvatar,VBtn,VCard,VCardTitle,VCol,VContainer,VDialog,VDivider,VIcon,VImg,VRow,VSpacer})
