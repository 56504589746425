import Vue from 'vue';
import mapValues from 'lodash/mapValues';
import clamp from 'lodash/clamp';
const PLAYER_CONTROLS_HEIGHT = 60;
const CONSTRAIN_TO_OVERLAY_BREAKPOINT = 400;
export default Vue.extend({
    name: 'LumiereVideoFeaturesZone',
    props: {
        container: Object,
        overlay: Object,
        showingControls: Boolean,
        showFeatures: Boolean,
    },
    computed: {
        constrainToOverlay() {
            return (this.overlay.height > CONSTRAIN_TO_OVERLAY_BREAKPOINT &&
                this.overlay.width > CONSTRAIN_TO_OVERLAY_BREAKPOINT);
        },
        featuresZone() {
            const featuresZone = this.constrainToOverlay
                ? {
                    width: this.overlay.width,
                    top: this.overlay.top - this.container.top,
                    left: this.overlay.left - this.container.left,
                    height: this.overlay.height - this.playerControlsOverlap,
                }
                : {
                    left: 0,
                    top: 0,
                    width: this.container.width,
                    height: this.container.height - this.playerControlsOverlap,
                };
            return featuresZone;
        },
        style() {
            const { width, height, left, top } = this.featuresZone;
            return {
                '--fz-width': width,
                '--fz-height': height,
                '--fz-left': left,
                '--fz-top': top,
                '--player-controls-overlap': -this.playerControlsOverlap,
            };
        },
        playerControlsOverlap() {
            return this.showingControls
                ? this.constrainToOverlay
                    ? clamp(PLAYER_CONTROLS_HEIGHT -
                        (this.container.height - this.overlay.height) / 2, 0, PLAYER_CONTROLS_HEIGHT)
                    : PLAYER_CONTROLS_HEIGHT
                : 0;
        },
    },
    filters: {
        px(val) {
            return mapValues(val, (v) => v + 'px');
        },
    },
});
