import Vue from 'vue';
import PlusFeature from './plus/PlusFeature.vue';
import CommentFeedbackLabel from './CommentFeedbackLabel.vue';
import anime from 'animejs';
import { VideoState } from '../../components/types';
const PRETEND_SEND_TIMEOUT = 900;
const HIDE_FORM_TIMEOUT = 1000;
var FullscreenCommentStep;
(function (FullscreenCommentStep) {
    FullscreenCommentStep["Comment"] = "Comment";
    FullscreenCommentStep["Plus"] = "Plus";
})(FullscreenCommentStep || (FullscreenCommentStep = {}));
const DEFAULT_PLACEHOLDER = 'Enter your comment here';
export default Vue.extend({
    name: 'CommentFeedback',
    props: {
        hideWhenOtherSpatialTaggingFeatureActivated: Boolean,
        spatialTaggingPosition: Object,
        prompt: {
            type: String,
            required: false,
        },
        position: {
            type: String,
        },
        positionOverride: {
            type: String,
        },
        featuresZone: Object,
        getTime: {
            type: Function,
        },
        feedbackOptions: {
            type: Object,
        },
        plus: {
            type: Object,
        },
        fullscreen: Boolean,
        videoState: String,
        updatePointOfContactPosition: Function,
    },
    data() {
        return {
            active: false,
            inputStyle: {},
            comment: '',
            currentTime: null,
            loading: false,
            plusInput: null,
            showPlaceholder: true,
            placeholder: DEFAULT_PLACEHOLDER,
            step: FullscreenCommentStep.Comment,
            taggingMode: false,
            highlightInput: false,
        };
    },
    watch: {
        inputStyle() {
            this.updatePointOfContactPosition();
        },
        videoState(state, prevState) {
            if (state === VideoState.Playing && prevState === VideoState.Paused) {
                this.toggleForm(false);
                this.taggingMode = false;
            }
        },
        spatialTaggingPosition(spatialTaggingPosition) {
            if (spatialTaggingPosition)
                this.taggingMode = false;
        },
        comment() {
            if (!this.fullscreen)
                this.$nextTick(() => {
                    this.autoSize();
                });
        },
        alwaysExpanded: {
            handler(toggleMode) {
                if (toggleMode) {
                    this.active = true;
                }
            },
            immediate: true,
        },
        taggingMode(taggingMode) {
            this.$emit('tagging-mode', taggingMode);
        },
    },
    computed: {
        FullscreenCommentStep() {
            return FullscreenCommentStep;
        },
        hidden() {
            return (this.hideWhenOtherSpatialTaggingFeatureActivated ||
                (this.hidePromptWhenSpatialTaggingEnabled && !this.active));
        },
        inputRef() {
            return this.$refs.input;
        },
        labelRef() {
            return this.$refs.label;
        },
        alwaysExpanded() {
            return this.feedbackOptions?.alwaysExpanded ?? false;
        },
        showPrompt() {
            return !!this.prompt;
        },
        hidePromptWhenSpatialTaggingEnabled() {
            return this.feedbackOptions?.hidePromptWhenSpacialTaggingEnabled === true;
        },
        formDisabled() {
            const commentIsValid = !!this.comment;
            const plusIsValid = !this.plus ||
                !this.plus.required ||
                (this.plus.required && this.plusInput !== null);
            const spatialTaggingIsValid = this.feedbackOptions?.spacialTagging &&
                this.feedbackOptions?.spacialTaggingRequired
                ? Boolean(this.spatialTaggingPosition)
                : true;
            return (this.loading ||
                !(commentIsValid && plusIsValid && spatialTaggingIsValid));
        },
        isSpatialTaggingEnabled() {
            return !!this.feedbackOptions?.spacialTagging;
        },
        isSpatialTaggingRequired() {
            return !!this.feedbackOptions?.spacialTaggingRequired;
        },
        needToTagLocation() {
            return (this.feedbackOptions?.spacialTaggingRequired &&
                !this.spatialTaggingPosition);
        },
        showStepComment() {
            return this.fullscreen
                ? this.step === FullscreenCommentStep.Comment
                : true;
        },
        showStepPlus() {
            return this.fullscreen ? this.step === FullscreenCommentStep.Plus : true;
        },
    },
    methods: {
        highlightInvalid() {
            this.highlightInput = true;
            const placeholder = DEFAULT_PLACEHOLDER;
            const obj = {
                length: 0,
            };
            this.placeholder = '';
            anime({
                targets: obj,
                length: placeholder.length,
                easing: `easeInOutQuad`,
                duration: 700,
                update: () => {
                    this.placeholder = placeholder.substring(0, Math.round(obj.length));
                },
                complete: () => {
                    this.highlightInput = false;
                },
            });
            this.labelRef.click();
        },
        validateForm() {
            const commentIsValid = !!this.comment;
            const plusIsValid = !this.plus ||
                !this.plus.required ||
                (this.plus.required && this.plusInput !== null);
            const spatialTaggingIsValid = this.feedbackOptions?.spacialTagging &&
                this.feedbackOptions?.spacialTaggingRequired
                ? Boolean(this.spatialTaggingPosition)
                : true;
            if (!commentIsValid) {
                this.highlightInvalid();
            }
            else if (!plusIsValid) {
                const plusRef = this.$refs.plus;
                if (plusRef && plusRef.highlightInvalid)
                    plusRef.highlightInvalid();
            }
            return commentIsValid && plusIsValid && spatialTaggingIsValid;
        },
        toggleForm(v) {
            this.active = typeof v === 'boolean' ? v : !this.active;
            if (this.active) {
                this.currentTime = this.getTime();
                this.labelRef.click();
                this.$emit('comment-box-toggle', true);
            }
            else {
                this.comment = '';
                this.inputStyle = {};
                this.step = FullscreenCommentStep.Comment;
                this.plusInput = null;
                this.active = this.alwaysExpanded ? true : this.active;
                this.$emit('comment-box-toggle', false);
            }
        },
        getPointOfContact: function ({ width, height, left, top }, cb) {
            setTimeout(() => {
                const { x, y } = this.$refs
                    .pointOfContact.getBoundingClientRect();
                cb({
                    x: (x - left) / width,
                    y: (y - top) / height,
                });
            }, 100);
        },
        submitComment() {
            if (this.validateForm()) {
                const { comment, currentTime, plusInput: plus } = this;
                const payload = {
                    comment: {
                        text: comment,
                        plus,
                    },
                    currentTime,
                    submittedTime: this.getTime(),
                };
                // dispatch comment to store
                this.$emit('feedback', payload);
                this.loading = true;
                setTimeout(() => {
                    this.loading = false;
                }, PRETEND_SEND_TIMEOUT);
                setTimeout(() => {
                    this.toggleForm(false);
                }, HIDE_FORM_TIMEOUT);
            }
        },
        tagLocation() {
            this.$emit('unset-tag-location');
            this.active = false;
            this.taggingMode = true;
        },
        cancelComment() {
            this.toggleForm(false);
        },
        autoSize() {
            if (!this.fullscreen &&
                // shouldn't grow more than a third of the screen
                this.featuresZone.height / 3 > this.inputRef.scrollHeight) {
                this.inputStyle = { height: this.inputRef.scrollHeight + 'px' };
            }
        },
        onAppear(el, done) {
            anime({
                targets: el,
                translateY: [-10, 0],
                opacity: [0, 1],
                scale: [0, 1],
                duration: 1000,
                delay: 100,
                complete() {
                    done();
                },
            });
        },
        onLeave(el, done) {
            anime({
                targets: el,
                translateY: 20,
                scale: 0,
                opacity: 0,
                duration: 1000,
                complete() {
                    done();
                },
            });
        },
        onNextClicked() {
            !this.comment
                ? this.validateForm()
                : (this.step = FullscreenCommentStep.Plus);
        },
    },
    components: {
        PlusFeature,
        CommentFeedbackLabel,
    },
});
