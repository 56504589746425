import Vue from 'vue';
import { QuestionType, TextQuestionVariant, ChoiceQuestionVariant, } from '@lumiere/db/types';
import TextQuestionFeedbackOptions from './question/TextQuestionFeedbackOptions.vue';
import ChoiceQuestionFeedbackOptions from './question/ChoiceQuestionFeedbackOptions.vue';
const defaultTextQuestionOptions = () => ({
    prompt: '',
    description: '',
    placeholder: '',
    feedbackOptions: { shouldPauseVideo: true },
    type: QuestionType.Text,
    required: true,
    variant: TextQuestionVariant.Short,
    canRespondMoreThanOnce: false,
});
const defaultChoiceQuestionOptions = () => ({
    prompt: '',
    description: '',
    feedbackOptions: { shouldPauseVideo: true },
    type: QuestionType.Choice,
    required: true,
    variant: ChoiceQuestionVariant.Single,
    randomizeResponseOrder: true,
    responseOptions: [],
    canRespondMoreThanOnce: false,
});
const TextVariants = [
    {
        label: `${TextQuestionVariant.Short} (Single Line)`,
        value: TextQuestionVariant.Short,
        type: QuestionType.Text,
    },
    {
        label: `${TextQuestionVariant.Essay} (Multi Line)`,
        value: TextQuestionVariant.Essay,
        type: QuestionType.Text,
    },
];
const ChoiceVariants = [
    {
        label: `${ChoiceQuestionVariant.Single} Choice`,
        value: ChoiceQuestionVariant.Single,
        type: QuestionType.Choice,
    },
    {
        label: `${ChoiceQuestionVariant.Multiple} Choice`,
        value: ChoiceQuestionVariant.Multiple,
        type: QuestionType.Choice,
    },
];
export default Vue.extend({
    props: {
        value: {
            type: Object,
        },
        editMode: Boolean,
    },
    data() {
        return {
            options: {
                ...defaultTextQuestionOptions(),
                ...this.$props.value,
            },
            questionVariant: {},
        };
    },
    computed: {
        rules() {
            return {
                maxPromptLength: (value) => value.length < 150 || 'Use short descriptive prompt',
                maxDescriptionLength: (value) => value.length < 500 || 'Description is too long',
                maxPlaceholderLength: (value) => value.length < 150 || 'Placeholder is too long',
                required: (value) => !!value || 'Prompt is required.',
            };
        },
        questionVariants() {
            if (this.editMode) {
                if (this.value.type === QuestionType.Text) {
                    return TextVariants;
                }
                return ChoiceVariants;
            }
            return TextVariants.concat(ChoiceVariants);
        },
        isTextQuestion() {
            if (!this.questionVariant?.type)
                return true;
            return this.questionVariant.type === QuestionType.Text;
        },
    },
    methods: {
        onQuestionInput(options) {
            const { prompt, variant } = this.options;
            this.options = {
                ...options,
                prompt,
                variant,
            };
        },
        isTextVariant(value) {
            const { Short, Essay } = TextQuestionVariant;
            return [Short, Essay].includes(value);
        },
        onQuestionTypeChange({ value, type }) {
            let options = { ...this.options };
            if (type === QuestionType.Text) {
                options = { ...defaultTextQuestionOptions(), ...options };
            }
            else {
                options = { ...defaultChoiceQuestionOptions(), ...options };
            }
            options['variant'] = value;
            options['type'] = type;
            this.options = options;
        },
    },
    watch: {
        options: {
            handler(options) {
                this.$emit('input', options);
            },
            immediate: true,
            deep: true,
        },
        value: {
            handler(options) {
                const variant = this.questionVariants.find((v) => v.value === options.variant && v.type === options.type);
                if (variant) {
                    this.questionVariant = variant;
                }
            },
        },
    },
    components: {
        TextQuestionFeedbackOptions,
        ChoiceQuestionFeedbackOptions,
    },
});
