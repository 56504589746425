import Vue from 'vue';
export default Vue.extend({
    data() {
        return {
            hasColor: !!this.color,
            pickerModel: false,
        };
    },
    props: {
        color: {
            type: [Object, String],
        },
    },
    methods: {
        updateColor(color) {
            this.$emit('change', color);
            this.pickerModel = false;
        },
        resetColor() {
            this.$emit('change', null);
        },
    },
    watch: {
        hasColor(color, prevColor) {
            if (prevColor && !color) {
                this.resetColor();
            }
        },
    },
});
