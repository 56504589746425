import { render, staticRenderFns } from "./ChannelDetailsTitle.vue?vue&type=template&id=26fee097&scoped=true&"
import script from "./ChannelDetailsTitle.vue?vue&type=script&lang=ts&"
export * from "./ChannelDetailsTitle.vue?vue&type=script&lang=ts&"
import style0 from "./ChannelDetailsTitle.vue?vue&type=style&index=0&id=26fee097&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/.pnpm/vue-loader@15.9.7_fb85a9817f99bdf862e5e3a442a1fb91/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "26fee097",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../../../node_modules/.pnpm/vuetify-loader@1.7.2_c225a462abeaf467a2189c5bd12e241a/node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VTextField } from 'vuetify/lib/components/VTextField';
installComponents(component, {VTextField})


/* vuetify-loader */
import installDirectives from "!../../../../../../../node_modules/.pnpm/vuetify-loader@1.7.2_c225a462abeaf467a2189c5bd12e241a/node_modules/vuetify-loader/lib/runtime/installDirectives.js"
import ClickOutside from 'vuetify/lib/directives/click-outside'
installDirectives(component, {ClickOutside})
