import Vue from 'vue';
import * as am4core from '@amcharts/amcharts4/core';
import * as am4charts from '@amcharts/amcharts4/charts';
import am4themes_animated from '@amcharts/amcharts4/themes/animated';
import VideoInsightsTrendsBucketPicker from '../VideoInsightsTrendsBucketPicker.vue';
import VideoInsightCommenDataFilter from '../VideoInsightCommenDataFilter.vue';
import { designColors } from '@/utils/helpers';
import isEmpty from 'lodash/isEmpty';
am4core.useTheme(am4themes_animated);
am4core.options.autoSetClassName = true;
export default Vue.extend({
    name: 'VideoInsightQuestionResponseCountStackedBarChart',
    props: {
        feature: {
            type: Object,
            required: true,
        },
        totalInteractions: {
            type: Number,
        },
        loading: Boolean,
        filtering: Boolean,
        ratingsMinMax: {
            type: Object,
        },
        emotions: {
            type: Array,
        },
        filterWasChanged: Boolean,
        counts_per_response: {
            type: Array,
        },
    },
    data() {
        return {
            amChart: null,
            chartLabel: null,
        };
    },
    computed: {
        getResponseOption() {
            return (rId) => this.feature.options.responseOptions.find((r) => r.id === rId);
        },
        orderedResponses() {
            if (!this.counts_per_response)
                return [];
            let responseOptionsWithCount = [];
            for (const response of this.counts_per_response) {
                const responseOption = this.getResponseOption(response.key);
                if (responseOption?.isActive) {
                    responseOptionsWithCount.push({
                        count: response.doc_count,
                        response: responseOption,
                    });
                }
            }
            return responseOptionsWithCount;
        },
        chartData() {
            let data = { year: '2000' };
            for (let item of this.orderedResponses) {
                data[item.response.label] = item.count;
            }
            return [data];
        },
        featureHasRatingsEnabled() {
            return Boolean(this.feature.options.responseOptions.find((p) => p.plus?.type === 'ratings'));
        },
    },
    methods: {
        getResponseWithMaxCount(theChartData) {
            const data = Object.keys(theChartData).reduce((acc, cur) => {
                if (!acc['value']) {
                    acc['value'] = theChartData[cur];
                    acc['label'] = cur;
                }
                if (theChartData[cur] > acc['value']) {
                    acc['value'] = theChartData[cur];
                    acc['label'] = cur;
                }
                return acc;
            }, {});
            return data;
        },
        constructLabelText(theChartData) {
            const data = this.getResponseWithMaxCount(theChartData);
            if (!isEmpty(data)) {
                const { value, label } = data;
                const caplabel = label[0] + label.slice(1).toLowerCase();
                const percentValue = this.totalInteractions
                    ? Math.round((value / this.totalInteractions) * 100)
                    : 0;
                const color = am4core.color(designColors[0]);
                return ('[' +
                    color.hex +
                    ' font-size:22px]' +
                    percentValue +
                    '%' +
                    '[/]' +
                    '\n[#665 font-size:11px]' +
                    caplabel.substr(0, 10) +
                    '[/]');
            }
            return '';
        },
        createStackedBarChart() {
            if (this.amChart) {
                this.refreshData();
                return;
            }
            // this.disposeOfCharts()
            // Create chart instance
            let chart = am4core.create(this.$refs.chartContainer, am4charts.XYChart);
            this.amChart = chart;
            chart.height = am4core.percent(75);
            chart.hiddenState.properties.opacity = 0; // this creates initial fade-in
            chart.data = this.chartData;
            chart.padding(10, 0, 10, 0);
            // chart.marginLeft = 20
            // Create axes
            const categoryAxis = chart.yAxes.push(new am4charts.CategoryAxis());
            categoryAxis.dataFields.category = 'year';
            categoryAxis.renderer.grid.template.opacity = 0;
            categoryAxis.renderer.labels.template.disabled = true;
            const valueAxis = chart.xAxes.push(new am4charts.ValueAxis());
            valueAxis.min = 0;
            valueAxis.renderer.grid.template.disabled = true;
            valueAxis.renderer.line.disabled = true;
            valueAxis.renderer.baseGrid.disabled = true;
            valueAxis.renderer.minGridDistance = 40;
            valueAxis.renderer.labels.template.disabled = true;
            const theChartData = { ...chart.data[0] };
            delete theChartData.year;
            const sortResponses = Object.entries(theChartData).sort((a, b) => b[1] - a[1]);
            sortResponses.forEach(([e, _], ix) => {
                this.createSeries(chart, e, designColors[ix]);
            });
            const title = chart.chartContainer.createChild(am4core.Label);
            title.text = 'Responses Count';
            title.align = 'center';
            title.horizontalCenter = 'middle';
            title.fontSize = 12;
            title.marginTop = 10;
            const label = chart.createChild(am4core.Label);
            this.chartLabel = label;
            label.isMeasured = false;
            label.textAlign = 'middle';
            if (this.$vuetify.breakpoint.mdAndUp) {
                title.paddingLeft = am4core.percent(13);
                label.align = 'left';
                label.x = 0;
                label.y = am4core.percent(20);
                chart.width = am4core.percent(95);
                chart.plotContainer.paddingLeft = am4core.percent(12);
            }
            else {
                title.marginTop = am4core.percent(25);
                title.paddingLeft = am4core.percent(-13);
                label.horizontalCenter = 'middle';
                label.x = am4core.percent(50);
                label.y = 0;
                label.paddingTop = am4core.percent(-13);
                label.paddingLeft = am4core.percent(-13);
                chart.width = am4core.percent(110);
                chart.plotContainer.paddingTop = am4core.percent(40);
            }
            label.text = this.constructLabelText(theChartData);
        },
        createSeries(chart, field, color) {
            const series = chart.series.push(new am4charts.ColumnSeries());
            series.dataFields.valueX = field;
            series.dataFields.categoryY = 'year';
            series.stacked = true;
            series.name = field;
            if (series.tooltip) {
                series.tooltip.disabled = false;
            }
            series.fill = am4core.color(color);
            let rgm = new am4core.RadialGradientModifier();
            rgm.brightnesses.push(-0.8, -0.8, -0.5, 0, -0.5);
            series.fillModifier = rgm;
            series.strokeModifier = rgm;
            series.strokeOpacity = 0.4;
            series.strokeWidth = 0;
            // const labelBullet = series.bullets.push(new am4charts.LabelBullet())
            // labelBullet.locationX = 0.5
            // labelBullet.label.text = '{valueX}'
            // labelBullet.label.fill = am4core.color('#fff')
            // labelBullet.toFront()
            series.columns.template.tooltipText =
                '[font-size:14px]{name}: {valueX}[/]';
        },
        refreshData() {
            if (this.amChart) {
                this.amChart.data = this.chartData;
                const theChartData = { ...this.chartData[0] };
                delete theChartData.year;
                if (this.chartLabel) {
                    this.chartLabel.text = this.constructLabelText(theChartData);
                }
                this.amChart.series.each((series) => {
                    series.appear();
                });
            }
        },
        disposeOfCharts() {
            this.amChart?.dispose();
            this.amChart = null;
        },
    },
    watch: {
        'feature.id'(fid) {
            if (fid) {
                this.disposeOfCharts();
            }
        },
        'totalInteractions': {
            handler(newVal, oldVal) {
                if (newVal && !oldVal) {
                    this.$nextTick(() => {
                        this.createStackedBarChart();
                    });
                }
                else if (oldVal) {
                    this.refreshData();
                }
            },
            immediate: true,
        },
    },
    beforeDestroy() {
        this.disposeOfCharts();
    },
    components: {
        VideoInsightsTrendsBucketPicker,
        VideoInsightCommenDataFilter,
    },
});
