import Vue from 'vue';
export default Vue.extend({
    name: 'Dashboards',
    props: {},
    data() {
        return {};
    },
    computed: {},
    watch: {},
    methods: {},
    subscriptions() {
        return {};
    },
    components: {},
});
