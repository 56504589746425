import Vue from 'vue';
import { mapGetters } from 'vuex';
import isEqual from 'lodash/isEqual';
import omit from 'lodash/omit';
import ChannelAddTagForm from './ChannelAddTagForm.vue';
export default Vue.extend({
    name: 'ChannelTags',
    props: {
        channel: {
            type: Object,
        },
    },
    data() {
        return {
            selectedTags: [],
            showAddTagForm: false,
            saving: false,
        };
    },
    computed: {
        ...mapGetters(['currentWorkspace']),
        workspaceId() {
            return this.channel.workspaceId;
        },
        workspaceTags() {
            let tags = this.currentWorkspace.tags;
            return Object.entries(tags)
                .map(([k, v]) => ({ ...v, id: k }))
                .sort((a, b) => a.nonce - b.nonce);
        },
    },
    watch: {
        'channel.channelTags': {
            handler(tags, oldTags) {
                if (!isEqual(tags, oldTags)) {
                    const selectedTags = Object.entries(tags).map(([k, v]) => ({
                        ...v,
                        id: k,
                    }));
                    this.selectedTags = selectedTags;
                }
            },
            immediate: true,
        },
        'workspaceTags'() {
            const tempTags = [...this.selectedTags];
            this.selectedTags = [];
            setTimeout(() => {
                this.selectedTags = tempTags;
            }, 0);
        },
    },
    methods: {
        // save/update tags to channels
        async updateTags(tags) {
            const channelTags = [...tags].reduce((acc, cur) => {
                const id = cur.id;
                return { ...acc, [id]: omit(cur, 'id') };
            }, {});
            await this.$db.channel(this.channel.id).update({
                channelTags: channelTags,
            });
        },
        onChangeItem(tags) {
            this.updateTags(tags);
        },
    },
    subscriptions() {
        return {};
    },
    components: {
        ChannelAddTagForm,
    },
});
