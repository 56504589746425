import Vue from 'vue';
import { mapGetters } from 'vuex';
// import emojiStore from '@/assets/emoji.json'
import Emoji from './Emoji.vue';
// import { startWith } from 'rxjs/operators'
import EmojiUploader from './EmojiUploader.vue';
import EmojiSkeletonLoader from './EmojiSkeletonLoader.vue';
import debounce from 'lodash/debounce';
import FuzzySearch from '@lumiere/shared/services/FuzzySearch';
const searchKeys = ['name'];
const loadEmojiJSON = () => fetch('/emoji.json').then((res) => res.json());
export default Vue.extend({
    name: 'EmojiPicker',
    props: {
        pickedEmojis: {
            type: Array,
            default: () => [],
        },
    },
    data() {
        return {
            search: '',
            debouncedSearch: '',
            renderEmojioneCount: 100,
            renderCustomCount: 100,
            renderWorkspaceEmojisCount: 100,
            allEmoji: { workspace: null, custom: null, emojione: null },
            loading: true,
            emojiIndex: {
                workspace: FuzzySearch([], { keys: searchKeys }),
                custom: FuzzySearch([], { keys: searchKeys }),
                emojione: FuzzySearch([], { keys: searchKeys }),
            },
        };
    },
    mounted() {
        this.loadEmoji();
        this.$subscribeTo(this.$db.workspace(this.currentWorkspace.id).emojis().observe(), (workspaceEmojis) => {
            this.allEmoji.workspace = workspaceEmojis;
        });
    },
    computed: {
        ...mapGetters(['currentWorkspace']),
        emoji() {
            if (this.debouncedSearch) {
                return {
                    custom: this.emojiIndex.custom
                        .search(this.debouncedSearch)
                        .map((el) => el.item),
                    emojione: this.emojiIndex.emojione
                        .search(this.debouncedSearch)
                        .map((el) => el.item),
                    workspace: this.emojiIndex.workspace
                        .search(this.debouncedSearch)
                        .map((el) => el.item),
                };
            }
            else {
                return this.allEmoji;
            }
        },
    },
    methods: {
        async loadEmoji() {
            const { custom, emojione } = await loadEmojiJSON();
            this.allEmoji.custom = custom;
            this.allEmoji.emojione = emojione;
            this.updateSearchIndex();
        },
        updateSearchIndex() {
            if (this.allEmoji.custom)
                this.emojiIndex.custom.setCollection(this.allEmoji.custom);
            if (this.allEmoji.emojione)
                this.emojiIndex.emojione.setCollection(this.allEmoji.emojione);
        },
        pickEmoji(emoji) {
            this.$emit('input', { ...emoji });
        },
        uploadIconClicked() {
            const emojiUploaderRef = this.$refs.emojiUploader;
            emojiUploaderRef.openFileDialog();
        },
        isEmojiPicked(e) {
            return !!this.pickedEmojis.find((emoji) => emoji.id === e.id);
        },
        triggerIntersectForEmojione(entries) {
            if (entries[0].isIntersecting) {
                this.triggerMoreEmojione();
            }
        },
        triggerMoreEmojione() {
            this.renderEmojioneCount += 150;
        },
        triggerIntersectForCustom(entries) {
            if (entries[0].isIntersecting) {
                this.triggerMoreCustom();
            }
        },
        triggerMoreCustom() {
            this.renderCustomCount += 150;
        },
        triggerIntersectForWorkspaceEmojis(entries) {
            if (entries[0].isIntersecting) {
                this.triggerMoreWorkspaceEmojis();
            }
        },
        triggerMoreWorkspaceEmojis() {
            this.renderWorkspaceEmojisCount += 150;
        },
    },
    watch: {
        search: debounce(function (val) {
            this.debouncedSearch = val;
        }, 500),
        workspaceEmojis(workspaceEmoji) {
            this.allEmoji.workspace = workspaceEmoji;
            this.emojiIndex.workspace.setCollection(workspaceEmoji);
        },
    },
    components: {
        Emoji,
        EmojiUploader,
        EmojiSkeletonLoader,
    },
});
