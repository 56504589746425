import Vue from 'vue';
import { CornerPosition, } from '@lumiere/db/types';
import CornerPositionSelect from '@/components/common/CornerPositionSelect.vue';
import CommentFeedbackOptions, { defaultOptions as defaultFeedbackOptions, } from './comment/CommentFeedbackOption.vue';
import CommentPlus from './comment/CommentPlus.vue';
const defaultOptions = () => ({
    prompt: '',
    position: CornerPosition.TopRight,
    feedbackOptions: defaultFeedbackOptions(),
    plus: null,
});
export default Vue.extend({
    data() {
        return {
            options: { ...defaultOptions(), ...this.$props.value },
        };
    },
    props: {
        value: {
            type: Object,
        },
    },
    watch: {
        options: {
            handler(options) {
                this.$emit('input', options);
            },
            immediate: true,
            deep: true,
        },
    },
    computed: {
        rules() {
            return {
                maxlength: (value) => value.length < 50 || 'Use short descriptive prompt',
            };
        },
    },
    methods: {
        updateFeedbackOption(data) {
            this.options.feedbackOptions = data;
        },
    },
    components: {
        CornerPositionSelect,
        CommentFeedbackOptions,
        CommentPlus,
    },
});
