import Vue from 'vue'
import Vuex, { Store } from 'vuex'
import state, { StoreState } from './store.state'
import actions from './store.actions'
import mutations from './store.mutations'
import getters from './store.getters'

Vue.use(Vuex)

export default function createStore(): Store<StoreState> {
  return new Vuex.Store<StoreState>({
    state,
    mutations,
    actions,
    getters,
    strict: process.env.NODE_ENV !== 'production',
  })
}
