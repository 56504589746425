import Vue from 'vue';
import isEmpty from 'lodash/isEmpty';
import debounce from 'lodash/debounce';
import { FilterActions, } from './types';
import VueWordCloud from 'vuewordcloud';
import { wordCloudColorGradient } from './helper';
function isQuestionTypePayload(p) {
    return p.question !== undefined;
}
export default Vue.extend({
    name: 'VideoInsightEntitiesWordCloud',
    props: {
        sentimentsEmotionsArray: {
            type: Array,
            default: () => [],
        },
        totalInteractions: {
            type: Number,
        },
        loading: Boolean,
        filtering: Boolean,
        filterTerm: String,
    },
    data() {
        return {
            search: '',
            entitiesReHash: {},
            termFilterActivated: false,
            entityText: '',
        };
    },
    computed: {
        wordCloudData() {
            if (isEmpty(this.entitiesReHash)) {
                return [];
            }
            let wordCloud = [];
            for (let entityName in this.entitiesReHash) {
                wordCloud.push({
                    ...this.entitiesReHash[entityName],
                    text: entityName,
                    weight: this.entitiesReHash[entityName].mentions,
                });
            }
            return wordCloud.sort((d1, d2) => d2.mentions - d1.mentions).slice(0, 30);
        },
    },
    methods: {
        constructEntityWordData(sentimentEmotions) {
            let entitiesReHash = {};
            let mentionsCount, _sentimentEmotions, sentimentAverage;
            for (let sentimentEmotion of sentimentEmotions) {
                const entitiesForType = isQuestionTypePayload(sentimentEmotion.payload)
                    ? sentimentEmotion.payload.question.responses
                        .flatMap((r) => r.entities)
                        .filter((e) => e !== undefined)
                    : sentimentEmotion.payload.comment.entities;
                const entities = entitiesForType || [];
                for (const entity of entities) {
                    if (!entity.mentions)
                        continue;
                    for (const mention of entity.mentions) {
                        if (!mention.text?.content)
                            continue;
                        const hashKey = mention.text.content.toLowerCase();
                        if (!entitiesReHash[hashKey]) {
                            entitiesReHash[hashKey] = {
                                sentimentAverage: 0,
                                sentimentEmotions: [],
                                mentions: 0,
                            };
                        }
                        entitiesReHash[hashKey].mentions++;
                        mentionsCount = entitiesReHash[hashKey].mentions;
                        // 1. Calculate avg: (a*(N-1) + b) / N
                        // 2. Use averaged entity-level sentiment score where there's none for entity-mentions-level
                        sentimentAverage =
                            (entitiesReHash[hashKey].sentimentAverage * (mentionsCount - 1) +
                                (mention?.sentiment?.score ?? entity?.sentiment?.score ?? 0)) /
                                mentionsCount;
                        _sentimentEmotions = entitiesReHash[hashKey].sentimentEmotions;
                        _sentimentEmotions.push({ ...sentimentEmotion });
                        entitiesReHash[hashKey] = {
                            ...entitiesReHash[hashKey],
                            sentimentAverage,
                            sentimentEmotions: _sentimentEmotions,
                        };
                    }
                }
            }
            this.entitiesReHash = entitiesReHash;
        },
        wordClickHandler(term) {
            this.search = this.entityText = term;
            this.$emit('word-clicked', {
                query: term,
                action: FilterActions.WORDCLICK,
            });
        },
        cancelTermFilter() {
            this.search = '';
        },
        color(_weight, sentimentAverage) {
            return wordCloudColorGradient(sentimentAverage);
        },
    },
    watch: {
        sentimentsEmotionsArray: {
            handler(sentimentEmotions) {
                this.constructEntityWordData(sentimentEmotions);
            },
            immediate: true,
        },
        search: debounce(function (val) {
            // @ts-ignore
            const self = this;
            if (val) {
                self.termFilterActivated = true;
                if (val !== self.entityText) {
                    self.$emit('comment-search-activated', {
                        query: val,
                        action: FilterActions.SEARCH,
                    });
                }
            }
            else {
                self.termFilterActivated = false;
                self.$emit('comment-search-activated', {
                    query: '',
                    action: FilterActions.CLEAR,
                });
            }
        }, 500),
        loading() {
            this.search = '';
        },
        filterTerm: {
            handler(term) {
                if (term && this.search !== term) {
                    this.search = term;
                }
            },
            immediate: true,
        },
    },
    components: {
        VueWordCloud,
    },
});
