import Vue from 'vue';
import Heatmap from './VisualizationsHeatmap.vue';
import isEqual from 'lodash/isEqual';
import ACTIONS from '@/store/ACTIONS';
import debounce from 'lodash/debounce';
function removeEmpty(obj) {
    if (!obj)
        return obj;
    let newObj = {};
    Object.keys(obj).forEach((key) => {
        if (obj[key] === Object(obj[key]))
            newObj[key] = removeEmpty(obj[key]);
        else if (obj[key] != null)
            newObj[key] = obj[key];
    });
    return newObj;
}
const isNumber = (value) => value != null && typeof +value === 'number' && isFinite(+value);
export default Vue.extend({
    name: 'VisualizationsContainer',
    props: {
        hide: Boolean,
        spatiallyTaggedFeature: {
            type: Object,
        },
        video: {
            type: Object,
        },
        currentTime: Number,
    },
    data() {
        return {
            containerSize: null,
            currentFeatureId: undefined,
            activeCommentVerbatimId: null,
        };
    },
    computed: {
        size() {
            const width = this.containerSize?.width;
            if (!width)
                return 'XL';
            if (width >= 640) {
                return 'XL';
            }
            else if (width >= 480 && width < 640) {
                return 'L';
            }
            else if (width >= 415 && width < 480) {
                return 'M';
            }
            else if (width >= 320 && width < 415) {
                return 'S';
            }
            else {
                return 'XS';
            }
        },
        heatmapBucket() {
            const videoAnalyticsData = this.$store.state.videoAnalyticsData;
            if (!this.spatiallyTaggedFeature) {
                return null;
            }
            return (videoAnalyticsData?.heatmap?.[this.spatiallyTaggedFeature.id] || null);
        },
    },
    methods: {
        getContainerSize() {
            const { width, height } = this.$refs.visualizationContainer.getBoundingClientRect();
            this.containerSize = { width, height };
        },
        loadHeatmapData: debounce(async function (fid, type, routeParams = {}) {
            const { filterTerm, minMax } = routeParams;
            // make a call to Elasticsearch here
            await this.$store.dispatch(ACTIONS.GET_HEATMAP_DATA, {
                vid: this.video.id,
                fid,
                filterTerm,
                minMax,
                type,
            });
        }, 250),
        checkLoadDataConditions() {
            const { filterTerm, min, max, commentId } = this.$route.query;
            const hasANonEmpty = !!filterTerm || min != null || max != null;
            let minMax;
            if (isNumber(min)) {
                minMax = {
                    ...minMax,
                    min: +min,
                };
            }
            if (isNumber(max)) {
                minMax = {
                    ...minMax,
                    max: +max,
                };
            }
            const routeParams = { filterTerm, minMax };
            const feature = this.spatiallyTaggedFeature;
            this.activeCommentVerbatimId = commentId;
            if (feature && hasANonEmpty) {
                void this.loadHeatmapData(feature.id, feature.type, routeParams);
            }
        },
    },
    mounted() {
        this.getContainerSize();
        window.addEventListener('resize', this.getContainerSize);
    },
    watch: {
        // 'spatiallyTaggedFeature': {
        //   handler(feature: Feature | null) {
        //     if (feature?.id && feature.id !== this.currentFeatureId) {
        //       this.loadHeatmapData(feature.id, feature.type)
        //       this.currentFeatureId = feature.id
        //     }
        //   },
        //   immediate: true,
        // },
        '$route.query': {
            handler(q, oQ) {
                if (q && !isEqual(removeEmpty(q), removeEmpty(oQ))) {
                    this.checkLoadDataConditions();
                }
            },
            immediate: true,
            deep: true,
        },
        'video.eids': {
            async handler(eids, oldEids) {
                if (!isEqual(eids, oldEids)) {
                    await this.$sleep(1000);
                    void this.checkLoadDataConditions();
                }
            },
        },
    },
    beforeDestroy() {
        window.removeEventListener('resize', this.getContainerSize);
    },
    events: {
        videoSceneFilter(params) {
            const { min, max } = params;
            let minMax;
            if (isNumber(min)) {
                minMax = {
                    ...minMax,
                    min: +min,
                };
            }
            if (isNumber(max)) {
                minMax = {
                    ...minMax,
                    max: +max,
                };
            }
            const { filterTerm } = this.$route.query;
            const routeParams = { filterTerm, minMax };
            const feature = this.spatiallyTaggedFeature;
            if (feature) {
                void this.loadHeatmapData(feature.id, feature.type, routeParams);
            }
        },
    },
    components: {
        Heatmap,
    },
});
