import Vue from 'vue';
import { getVideoThumbnail } from '@lumiere/shared/utils/getVideoThumbnail';
export default Vue.extend({
    name: 'GlobalSearchVideoPreviewCard',
    props: {
        video: Object,
    },
    computed: {
        thumbnail() {
            return getVideoThumbnail(this.video);
        },
    },
});
