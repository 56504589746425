import { clientDynamicBucketing, hasNumberEl } from '../helper';
import { designColors } from '@/utils/helpers';
import isEmpty from 'lodash/isEmpty';
import debounce from 'lodash/debounce';
import isEqual from 'lodash/isEqual';
import maxBy from 'lodash/maxBy';
import * as am4core from '@amcharts/amcharts4/core';
import * as am4charts from '@amcharts/amcharts4/charts';
import am4themes_animated from '@amcharts/amcharts4/themes/animated';
am4core.useTheme(am4themes_animated);
import chroma from 'chroma-js';
import { sleep } from '@/utils/sleep';
import { formatTime } from '@lumiere/shared/utils/numberFormatter';
import VideoInsightsTrendsBucketPicker from '../VideoInsightsTrendsBucketPicker.vue';
import mixins from 'vue-typed-mixins';
import ThemeColor from '@/utils/ThemeColor';
export default mixins(ThemeColor).extend({
    name: 'CommentTrend',
    props: {
        loading: Boolean,
        feature: {
            type: Object,
            required: true,
        },
        video: {
            type: Object,
            required: true,
        },
        totalVideoViews: {
            type: Number,
        },
        retention_bucket: {
            type: Object,
        },
    },
    data() {
        return {
            calculatedMinMax: null,
            comment: '',
            xAxis: null,
            allCharts: [],
            chartsContainer: null,
            cursorShowDisposers: [],
            retentionDataSeries: [],
            scrollbarX: null,
            retentionRange: null,
            retentionAmChart: null,
            scrollbarAmChart: null,
            shownCursorChangeDisposer: null,
            shownCursorZoomStartedDisposer: null,
            shownCursorZoomEndedDisposer: null,
            queryParamMinMax: null,
            isResetChartData: false,
            scrollbarSeriesData: [],
            customBucketSize: null,
            amChartWasCreated: false,
            dateAxis: null,
            externalFilterTriggeredZoom: false,
            externalFilterMinMax: null,
            baseInterval: 0,
            sceneObjects: [],
        };
    },
    computed: {
        isZoomed() {
            const { start: tStart, stop: tStop } = this.triggerTime;
            const { min: qStart, max: qStop } = this.queryParamMinMax || {
                min: tStart,
                max: tStop,
            };
            const { start: cStart, stop: cStop } = this.calculatedMinMax;
            return (tStart != cStart || tStop != cStop || tStart != qStart || tStop != qStop);
        },
        triggerTime() {
            return {
                start: 0,
                stop: this.video.duration,
            };
        },
        formattedTriggerTime() {
            const time = this.calculatedMinMax;
            return {
                startTime: formatTime(time.start),
                stopTime: formatTime(time.stop),
            };
        },
        tickFormat() {
            return this.video.duration > 3600 ? 'H:mm:ss' : 'm:ss';
        },
        unixMidnight() {
            const d = new Date();
            d.setHours(0, 0, 0, 0);
            return d.getTime();
        },
        minMax() {
            const { start: min, stop: max } = this.calculatedMinMax;
            return { min, max };
        },
        chartHasData() {
            return !isEmpty(this.retention_bucket);
        },
        tooltipFormat() {
            return this.video.duration > 3600 ? 'H:mm:ss' : 'mm:ss';
        },
        videoTime() {
            return this.$store.state.videoInfo.time;
        },
        showSkeleton() {
            return this.totalVideoViews == null || this.loading;
        },
        colors() {
            if (this.video.scenes) {
                const numColors = Object.keys(this.video.scenes).length;
                const dColors = designColors;
                if (numColors > dColors.length) {
                    return chroma.scale(dColors).mode('lch').colors(numColors);
                }
                return dColors;
            }
            else {
                return [];
            }
        },
        darkenedColors() {
            return this.colors.map((color) => chroma(color).darken().css());
        },
        compositeAmChartsAndVideoScenes() {
            return {
                created: this.amChartWasCreated,
                scenes: this.video.scenes,
                baseInterval: this.baseInterval,
            };
        },
    },
    methods: {
        onChartZoomed: debounce(function (_chartContext, { xaxis }) {
            if (this.isResetChartData)
                return;
            const { min: chartMin, max: chartMax } = xaxis;
            const now = this.unixMidnight;
            if (chartMin || chartMax) {
                const { start: trigger_start, stop: trigger_stop } = this.triggerTime;
                const min = chartMin ?? now + trigger_start * 1000;
                const max = chartMax ?? now + trigger_stop * 1000;
                let start = Math.floor((min - now) / 1000);
                let stop = Math.ceil((max - now) / 1000);
                if (!this.externalFilterTriggeredZoom) {
                    const interval = this.customBucketSize ||
                        clientDynamicBucketing((max - min) / 1000);
                    if (start - interval >= trigger_start) {
                        start -= interval;
                    }
                    else {
                        start = trigger_start;
                    }
                    if (stop + interval <= trigger_stop) {
                        stop += interval;
                    }
                    else {
                        stop = trigger_stop;
                    }
                    this.calculatedMinMax = {
                        start: +start.toFixed(2),
                        stop: +stop.toFixed(2),
                    };
                }
                else {
                    this.calculatedMinMax = {
                        start: this.externalFilterMinMax.min,
                        stop: this.externalFilterMinMax.max,
                    };
                }
            }
            else {
                this.calculatedMinMax = this.triggerTime;
            }
            this.externalFilterTriggeredZoom = false;
            this.externalFilterMinMax = null;
            this.xAxis = this.calculatedMinMax;
            this.$emit('chart-zoomed', {
                min: this.calculatedMinMax.start,
                max: this.calculatedMinMax.stop,
            });
        }, 500, {
            leading: false,
            trailing: true,
        }),
        buildRetentionTrendData(bucket) {
            let dataPoint;
            const series = [];
            const { min, max } = this.minMax;
            const interval = this.customBucketSize || clientDynamicBucketing(max - min);
            let bucketArr = Object.keys(bucket).map((el) => +el);
            const bucketMin = Math.min.apply(null, bucketArr);
            const now = this.unixMidnight;
            let floorMin0 = Math.floor(min);
            while (floorMin0 < bucketMin) {
                dataPoint = {
                    date: now + floorMin0 * 1000,
                    value: 0,
                };
                series.push(dataPoint);
                floorMin0 += interval;
            }
            const floorMin = Math.floor(min);
            const ceilMax = Math.ceil(max);
            let timeAsNum;
            let tx = min;
            let doc_count;
            for (let time in bucket) {
                timeAsNum = +time;
                if (timeAsNum == interval ||
                    (timeAsNum >= floorMin && timeAsNum <= ceilMax)) {
                    doc_count = bucket[time].doc_count ?? 0;
                    dataPoint = {
                        date: now + timeAsNum * 1000,
                        value: +(doc_count / (interval * this.totalVideoViews)).toFixed(2),
                    };
                    series.push(dataPoint);
                    tx = timeAsNum + interval;
                }
            }
            while (tx <= ceilMax) {
                dataPoint = {
                    date: now + tx * 1000,
                    value: 0,
                };
                series.push(dataPoint);
                tx += interval;
            }
            this.retentionDataSeries = series;
            if (!this.scrollbarSeriesData.length) {
                this.scrollbarSeriesData = series;
            }
            if (!series.length) {
                this.resetSeries();
            }
        },
        resetSeries() {
            this.onChartZoomed(null, { xaxis: {}, yaxis: {} });
        },
        refreshChartData() {
            const chart = this.retentionAmChart;
            if (chart) {
                chart.series.each(function (series) {
                    series.appear();
                });
                this.isResetChartData = true;
                chart.data = this.retentionDataSeries;
                const dateAxis = chart.cursor.xAxis; // xAxes.getIndex(0)
                if (dateAxis) {
                    // @ts-ignore
                    dateAxis.keepSelection = true;
                }
                const valueAxis = chart.yAxes.getIndex(0);
                if (valueAxis) {
                    const max = maxBy(chart.data, 'value');
                    // @ts-ignore
                    valueAxis.max = max.value + 0.5;
                }
            }
        },
        async createAmChartContainer() {
            // this.isResetChartData = false
            if (this.chartsContainer) {
                this.refreshChartData();
                return;
            }
            const container = am4core.create(this.$refs.chartContainer, am4core.Container);
            this.chartsContainer = container;
            container.width = am4core.percent(100);
            container.height = am4core.percent(100);
            container.layout = 'vertical';
            container.events.on('up', (_evt) => {
                for (let i = 0; i < allCharts.length; i++) {
                    const cursor = allCharts[i].cursor;
                    cursor.selection.hide(0);
                }
            });
            container.events.on('down', () => {
                this.isResetChartData = false;
            });
            const allCharts = [];
            // create scrollbar chart
            this.scrollbarAmChart = container.createChild(am4charts.XYChart);
            this.scrollbarAmChart.height = am4core.percent(5);
            this.scrollbarAmChart.plotContainer.visible = false;
            this.scrollbarAmChart.leftAxesContainer.visible = false;
            this.scrollbarAmChart.rightAxesContainer.visible = false;
            this.scrollbarAmChart.bottomAxesContainer.visible = false;
            this.scrollbarAmChart.zoomOutButton.parent = this.scrollbarAmChart.tooltipContainer;
            allCharts.push(this.scrollbarAmChart);
            // create retention chart
            this.retentionAmChart = container.createChild(am4charts.XYChart);
            this.retentionAmChart.height = am4core.percent(50);
            allCharts.push(this.retentionAmChart);
            this.allCharts = allCharts;
            //This is so that the scenes AxisLabels appear behind the plot-line
            this.scrollbarAmChart.bottomAxesContainer.zIndex = 0;
            this.scrollbarAmChart.yAxesAndPlotContainer.zIndex = 1;
            await this.$nextTick();
            this.scrollbarX = new am4core.Scrollbar();
            const firstChart = allCharts[0];
            firstChart.scrollbarX = this.scrollbarX;
            this.createGlobalScrollbarX();
            this.createRetentionAmChart();
            await sleep(0);
            firstChart.zoomOutButton.disabled = false;
            this.initCursorListeners();
            // enable date axis labels for the last one
            // const lastChart = this.retentionAmChart
            // const lastDateAxis = lastChart.xAxes.getIndex(0)
            for (let i = 1; i < allCharts.length; i++) {
                const chart = allCharts[i];
                chart.events.on('up', (evt) => {
                    const cursor = evt.target.cursor;
                    // cursor.dispatchImmediately('zoomended')
                    for (let i = 1; i < allCharts.length; i++) {
                        const otherCursor = allCharts[i].cursor;
                        if (!isEqual(otherCursor, cursor)) {
                            otherCursor.triggerUp({ x: cursor.point.x, y: 0 });
                        }
                    }
                });
            }
        },
        initCursorListeners() {
            const cursorShowDisposers = [];
            for (let i = 0; i < this.allCharts.length; i++) {
                const cursor = this.allCharts[i].cursor;
                cursor.interactionsEnabled = true;
                cursorShowDisposers.push(cursor.events.on('shown', (event) => {
                    this.handleShowCursor(event.target);
                }));
            }
            this.cursorShowDisposers = cursorShowDisposers;
        },
        // disable mouse for all other cursors
        handleShowCursor(shownCursor) {
            const syncCursors = (syncWithCursor) => {
                for (let i = 0; i < this.allCharts.length; i++) {
                    const cursor = this.allCharts[i].cursor;
                    const point = { x: syncWithCursor.point.x, y: 0 };
                    if (!isEqual(cursor, syncWithCursor)) {
                        cursor.triggerMove(point);
                    }
                }
            };
            // disable mouse for all other cursors
            for (let i = 0; i < this.allCharts.length; i++) {
                const cursor = this.allCharts[i].cursor;
                if (!isEqual(cursor, shownCursor)) {
                    cursor.interactionsEnabled = false;
                }
                // remove show listener
                this.cursorShowDisposers[i].dispose();
            }
            // add change disposer to the hovered chart cursor
            this.shownCursorChangeDisposer = shownCursor.lineX.events.on('positionchanged', (_evt) => {
                syncCursors(shownCursor);
            });
            this.shownCursorZoomStartedDisposer = shownCursor.events.on('zoomstarted', (event) => {
                for (let i = 0; i < this.allCharts.length; i++) {
                    const cursor = this.allCharts[i].cursor;
                    if (!isEqual(cursor, event.target)) {
                        const point = { x: event.target.point.x, y: 0 };
                        cursor.triggerDown(point);
                    }
                }
            });
            this.shownCursorZoomEndedDisposer = shownCursor.events.on('zoomended', (event) => {
                for (let i = 0; i < this.allCharts.length; i++) {
                    const cursor = this.allCharts[i].cursor;
                    if (!isEqual(cursor, event.target)) {
                        const point = { x: event.target.point.x, y: 0 };
                        cursor.triggerUp(point);
                    }
                }
            });
            shownCursor.events.once('hidden', (_event) => {
                this.shownCursorChangeDisposer.dispose();
                this.shownCursorZoomStartedDisposer.dispose();
                this.shownCursorZoomEndedDisposer.dispose();
                for (let i = 0; i < this.allCharts.length; i++) {
                    const cursor = this.allCharts[i].cursor;
                    cursor.hide(0);
                    this.cursorShowDisposers[i]?.dispose();
                }
                this.initCursorListeners();
            });
        },
        syncDateAxes(syncWithAxis) {
            for (let i = 0; i < this.allCharts.length; i++) {
                const chart = this.allCharts[i];
                const dateAxis = chart.cursor.xAxis;
                if (!isEqual(dateAxis, syncWithAxis)) {
                    i > 0 && dateAxis.events.disableType('startendchanged');
                    dateAxis.start = syncWithAxis.start;
                    dateAxis.end = syncWithAxis.end;
                    i > 0 && dateAxis.events.enableType('startendchanged');
                }
            }
        },
        /**
         * Ratings Chart
         */
        createRetentionAmChart() {
            const seriesData = this.retentionDataSeries;
            const chart = this.retentionAmChart;
            const dateAxis = this.setChartProps(chart, {
                title: 'Viewer Retention',
                // valueAxis: { min: 0, max: 10 },
            });
            chart.data = seriesData;
            chart.zoomOutButton.disabled = true;
            let series, tooltip;
            // const scrollbarX = new am4charts.XYChartScrollbar()
            // Create series
            series = chart.series.push(new am4charts.LineSeries());
            //rollover.
            series.segments.template.interactionsEnabled = true;
            series.name = 'viewer retention';
            series.dataFields.valueY = 'value';
            series.dataFields.dateX = 'date';
            series.minBulletDistance = 15;
            series.stroke = this.themeColorAm4Core;
            series.strokeWidth = 3;
            series.opacity = 1;
            series.interpolationDuration = 5;
            series.tensionX = 0.77;
            // scrollbarX.series.push(series)
            // chart.scrollbarX = scrollbarX
            // chart.legend = new am4charts.Legend()
            tooltip = series.tooltip;
            if (tooltip) {
                tooltip.dateFormatter.dateFormat = this.tooltipFormat;
                // Set up tooltip
                series.adapter.add('tooltipText', (_ev) => {
                    const text = '[bold]{dateX}[/]\n' +
                        '[' +
                        series.stroke.hex +
                        ']●[/] ' +
                        '[]' +
                        series.name +
                        ': {' +
                        series.dataFields.valueY +
                        '}' +
                        '[/]' +
                        '\n';
                    return text;
                });
                tooltip.getFillFromObject = false;
                tooltip.background.fill = am4core.color('#fff');
                tooltip.label.fill = am4core.color('#000');
            }
            this.retentionRange = dateAxis.axisRanges.create();
            this.dateAxis = dateAxis;
            this.amChartWasCreated = true;
        },
        /**
         * All and Ratings Chart for Global ScrollbarX
         */
        createGlobalScrollbarX() {
            const seriesData = this.scrollbarSeriesData;
            const chart = this.scrollbarAmChart;
            this.setChartProps(chart, {}); // returns dateAxis
            chart.data = seriesData;
            // const scrollbarX = this.scrollbarX!
            let series, tooltip;
            // Push series
            series = chart.series.push(new am4charts.LineSeries());
            series.segments.template.interactionsEnabled = true;
            series.name = 'preview';
            series.dataFields.valueY = 'value';
            series.dataFields.dateX = 'date';
            series.minBulletDistance = 15;
            series.strokeWidth = 3;
            series.opacity = 1;
            series.interpolationDuration = 5;
            series.tensionX = 0.77;
            series.showOnInit = false;
            // scrollbarX.series.push(series)
            tooltip = series.tooltip;
            if (tooltip) {
                tooltip.dateFormatter.dateFormat = this.tooltipFormat;
                tooltip.getFillFromObject = false;
                tooltip.background.fill = am4core.color('#fff');
                tooltip.label.fill = am4core.color('#000');
            }
            chart.padding(0, 20, 20, 25);
        },
        setBaseInterval(dateAxis) {
            if (dateAxis.baseInterval && dateAxis.baseInterval.count > 0) {
                this.baseInterval = dateAxis.baseInterval.count;
            }
        },
        setChartProps(chart, config) {
            chart.dateFormatter.inputDateFormat = 'x';
            chart.padding(10, 15, 10, 15);
            const dateAxis = chart.xAxes.push(new am4charts.DateAxis());
            dateAxis.cursorTooltipEnabled = false;
            dateAxis.renderer.labels.template.disabled = false;
            dateAxis.renderer.grid.template.location = 0.5;
            dateAxis.renderer.grid.template.disabled = true;
            //https://www.amcharts.com/docs/v4/concepts/axes/positioning-axis-elements/
            //this is workaround for location from the amcharts docs.
            dateAxis.renderer.labels.template.location = 0.001;
            //Reset the grid intervals. We need a little more sensitivity for minutes
            //because most of our videos are on the order of minutes.
            dateAxis.gridIntervals = new am4core.List([
                { timeUnit: 'second', count: 1 },
                { timeUnit: 'second', count: 5 },
                { timeUnit: 'second', count: 10 },
                { timeUnit: 'second', count: 30 },
                { timeUnit: 'minute', count: 1 },
                { timeUnit: 'minute', count: 2 },
                { timeUnit: 'minute', count: 3 },
                { timeUnit: 'minute', count: 4 },
                { timeUnit: 'minute', count: 5 },
                { timeUnit: 'minute', count: 6 },
                { timeUnit: 'minute', count: 8 },
                { timeUnit: 'minute', count: 10 },
                { timeUnit: 'minute', count: 30 },
                { timeUnit: 'hour', count: 1 },
                { timeUnit: 'hour', count: 3 },
                { timeUnit: 'hour', count: 6 },
                { timeUnit: 'hour', count: 12 },
            ]);
            dateAxis.startLocation = 0.5;
            dateAxis.endLocation = 0.5;
            dateAxis.keepSelection = true;
            if (dateAxis.tooltip) {
                dateAxis.tooltip.disabled = false;
                dateAxis.tooltip.animationDuration = 0;
            }
            // Create y-axis
            const valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
            if (valueAxis.tooltip) {
                valueAxis.tooltip.disabled = true;
            }
            valueAxis.title.text = config.title ?? '';
            valueAxis.title.fontSize = 14;
            valueAxis.title.fontWeight = '400';
            valueAxis.title.opacity = 0.8;
            valueAxis.numberFormatter.numberFormat = '#,##%';
            chart.events.on('ready', (_ev) => {
                const now = this.unixMidnight;
                const { start, stop } = this.triggerTime;
                dateAxis.min = now + start * 1000;
                dateAxis.max = now + stop * 1000; //dateAxis.maxZoomed
                if (this.queryParamMinMax) {
                    let { min = start, max = stop } = this.queryParamMinMax;
                    this.queryParamMinMax = null;
                    this.isResetChartData = true;
                    if (this.retentionAmChart) {
                        this.calculatedMinMax = { start: min, stop: max };
                        const dateAxis = this.retentionAmChart.cursor.xAxis;
                        if (dateAxis) {
                            const startDiff = min - start;
                            const begin = startDiff < 0.5 ? 0 : startDiff - 0.5;
                            const triggerDiff = stop - start > 0 ? stop - start : stop;
                            dateAxis.start = begin / triggerDiff;
                            dateAxis.end = (max + 0.5 - start) / triggerDiff;
                        }
                    }
                }
                valueAxis.min = 0;
                valueAxis.max = config.valueAxis?.max ?? valueAxis.maxZoomed + 0.5;
                this.setBaseInterval(dateAxis);
            });
            dateAxis.events.on('startendchanged', (ev) => {
                const now = this.unixMidnight;
                const min = ev.target.minZoomed;
                const max = ev.target.maxZoomed;
                const { start, stop } = this.triggerTime;
                let newMin = min;
                let newMax = max;
                if (min < now + 1000) {
                    newMin = now + start * 1000;
                }
                if (max > now + stop * 1000) {
                    newMax = now + stop * 1000;
                }
                const xaxis = { min: newMin, max: newMax };
                this.onChartZoomed(null, { xaxis });
                this.syncDateAxes(ev.target);
                this.setBaseInterval(dateAxis);
            });
            chart.zoomOutButton.scale = 1.2;
            chart.zoomOutButton.events.on('hit', (_ev) => {
                this.scrollbarSeriesData = [];
                this.isResetChartData = false;
                const now = this.unixMidnight;
                const { start, stop } = this.triggerTime;
                dateAxis.min = now + start * 1000;
                dateAxis.max = now + stop * 1000;
                // reset the currently selected scene filter
                this.$events.emit('resetVideoSceneFilter');
            });
            chart.cursor = new am4charts.XYCursor();
            chart.cursor.lineY.disabled = true;
            chart.cursor.xAxis = dateAxis;
            chart.cursor.maxTooltipDistance = -1;
            let cursorXPosition = null;
            chart.cursor.events.on('cursorpositionchanged', function (ev) {
                var xAxis = ev.target.chart.xAxes.getIndex(0);
                if (xAxis) {
                    // @ts-ignore
                    cursorXPosition = xAxis.positionToDate(xAxis.toAxisPosition(ev.target.xPosition));
                }
            });
            chart.plotContainer.events.on('hit', (_ev) => {
                if (cursorXPosition) {
                    const dateStringToDate = new Date(cursorXPosition);
                    const sec = dateStringToDate.getSeconds();
                    const minToSec = dateStringToDate.getMinutes() * 60;
                    const hrToSec = dateStringToDate.getHours() * 60 * 60;
                    const vctime = sec + minToSec + hrToSec;
                    this.$events.emit('chartTapped', { vctime });
                }
            });
            // Set date label formatting
            dateAxis.dateFormats.setKey('hour', this.tickFormat);
            dateAxis.dateFormats.setKey('minute', this.tickFormat);
            dateAxis.dateFormats.setKey('second', this.tickFormat);
            dateAxis.markUnitChange = false;
            return dateAxis;
        },
        disposeOfCharts() {
            this.sceneObjects.splice(0, this.sceneObjects.length);
            this.amChartWasCreated = false;
            this.retentionAmChart?.dispose();
            this.retentionAmChart = null;
            this.scrollbarAmChart?.dispose();
            this.scrollbarAmChart = null;
            this.chartsContainer?.dispose();
            this.chartsContainer = null;
            this.baseInterval = 0;
        },
        formatToHHMMSS(currentTime) {
            return formatTime(currentTime);
        },
        moveXAnnotation(videoTime) {
            try {
                const xAnnotate = (range) => {
                    if (range) {
                        const videoTimeDate = new Date(this.unixMidnight + videoTime * 1000);
                        range.date = videoTimeDate;
                        range.label.text = this.formatToHHMMSS(videoTime);
                    }
                };
                xAnnotate(this.retentionRange);
            }
            catch (e) {
                if (e.message != 'EventDispatcher is disposed') {
                    throw e;
                }
            }
        },
        buildRange(range) {
            range.date = new Date(this.unixMidnight);
            range.axisFill.fill = am4core.color('#666666');
            range.axisFill.fillOpacity = 0.2;
            range.label.text = '';
            range.label.inside = true;
            range.label.rotation = 90;
            range.label.horizontalCenter = 'right';
            range.label.verticalCenter = 'bottom';
            range.label.fontSize = 12;
            range.grid.stroke = am4core.color('#666666');
            range.grid.strokeOpacity = 0.9;
            range.grid.strokeWidth = 3;
            range.grid.strokeDasharray = '2,2';
        },
        onBucketSizeChanged(size) {
            this.customBucketSize = size;
            this.$emit('bucket-size', size);
        },
        getColorAtIndex(index, darken) {
            const colors = darken ? this.darkenedColors : this.colors;
            return colors.length > index ? colors[index] : '';
        },
        drawScenesOnGraph(scenes, dateAxis) {
            const interval = this.baseInterval;
            //handle the scene object explicity because we don't want to delete the x-annotation accidentally
            this.sceneObjects.forEach((sceneObject) => {
                dateAxis.axisRanges.removeValue(sceneObject);
            });
            this.sceneObjects.splice(0, this.sceneObjects.length);
            const sceneArr = Object.values(scenes).sort((a, b) => a.startTime - b.startTime);
            sceneArr.forEach((scene, idx) => {
                const r = dateAxis.axisRanges.create();
                this.sceneObjects.push(r);
                const colorForScene = this.getColorAtIndex(idx, false);
                const darkColorForScene = this.getColorAtIndex(idx, true);
                //chuks uses 0.5 elsewhere to solve an alignment problem, that affects where
                //the charts starts at. but it doens't start at 0.5 it starts at 0.5*baseInterval.
                const startTime = Math.max(interval / 2, scene.startTime);
                r.date = new Date(this.unixMidnight + startTime * 1000);
                r.endDate = new Date(this.unixMidnight + scene.stopTime * 1000);
                r.axisFill.fill = am4core.color(colorForScene);
                r.axisFill.fillOpacity = 0.2;
                r.label.text = scene.name;
                r.label.inside = true;
                r.label.horizontalCenter = 'left';
                r.label.valign = 'bottom';
                r.label.verticalCenter = 'top';
                r.label.rotation = -90;
                r.label.fill = am4core.color(darkColorForScene);
                r.label.truncate = true;
                r.label.maxWidth = 295;
                r.label.fontSize = 10;
                r.grid.strokeOpacity = 0;
                r.label.padding(3, 3, 3, 6);
            });
        },
        externalFilterTriggerZoom(min, max) {
            const { start, stop } = this.triggerTime;
            if (this.retentionAmChart) {
                this.isResetChartData = false;
                this.externalFilterTriggeredZoom = true;
                min || (min = start);
                max || (max = stop);
                if (min < start) {
                    min = start;
                }
                if (max > stop) {
                    max = stop;
                }
                this.externalFilterMinMax = { min: +min, max: +max };
                const dateAxis = this.retentionAmChart.cursor.xAxis;
                console.log({ dateAxis });
                if (dateAxis) {
                    dateAxis.start = (min - start) / (stop - start);
                    dateAxis.end = (max - start) / (stop - start);
                }
            }
        },
    },
    mounted() {
        const { min, max } = this.$route.query;
        const { start, stop } = this.triggerTime;
        if (hasNumberEl([min, max])) {
            const minMax = {};
            if (hasNumberEl(min)) {
                minMax.min = +min >= start && +min < stop ? +min : start;
            }
            if (hasNumberEl(max)) {
                minMax.max = +max <= stop && +max > start ? +max : stop;
            }
            this.queryParamMinMax = minMax;
        }
    },
    watch: {
        triggerTime: {
            handler(minMax) {
                this.calculatedMinMax = minMax;
            },
            immediate: true,
        },
        retention_bucket: {
            handler(bucket) {
                if (!isEmpty(bucket)) {
                    this.buildRetentionTrendData(bucket);
                    this.$nextTick(() => {
                        this.createAmChartContainer();
                    });
                }
            },
            immediate: true,
        },
        compositeAmChartsAndVideoScenes: {
            handler(newVal) {
                if (newVal.created && newVal.scenes && this.dateAxis) {
                    this.drawScenesOnGraph(newVal.scenes, this.dateAxis);
                }
            },
            immediate: true,
        },
        feature: {
            handler(_feature) { },
            immediate: true,
            deep: true,
        },
        xAxis() { },
        videoTime(newTime) {
            this.moveXAnnotation(newTime);
        },
        retentionRange(rangeRef) {
            if (rangeRef) {
                try {
                    this.buildRange(rangeRef);
                }
                catch (e) {
                    //AmCharts has these bogus "EventDispatcher is disposed" errors
                    //it's an ongoing issue with the library that makes numerous
                    //appearances on github and we're just going to quiet this error
                    //down if it appears. Any other error we'll let through.
                    if (e.message != 'EventDispatcher is disposed') {
                        throw e;
                    }
                }
            }
        },
    },
    events: {
        videoSceneFilter(params) {
            let { min, max } = params;
            this.externalFilterTriggerZoom(min, max);
        },
        videoFacesFilter(params) {
            let { min, max } = params;
            this.externalFilterTriggerZoom(min, max);
        },
    },
    beforeDestroy() {
        this.disposeOfCharts();
    },
    components: {
        VideoInsightsTrendsBucketPicker,
    },
});
