import Vue from 'vue';
import { CornerPosition } from '@lumiere/db/types';
import CornerPositionSelect from '@/components/common/CornerPositionSelect.vue';
import TextPromptFeedbackOptions, { defaultOptions as defaultFeedbackOptions, } from './text-prompt/TextPromptFeedbackOptions.vue';
const PROMPT_LENGTH = 75;
const defaultOptions = () => ({
    prompt: '',
    position: CornerPosition.BottomCenter,
    feedbackOptions: defaultFeedbackOptions(),
});
export default Vue.extend({
    name: 'FeatureOptionsTextPrompt',
    data() {
        return {
            options: { ...defaultOptions(), ...this.$props.value },
        };
    },
    props: {
        value: {
            type: Object,
        },
    },
    watch: {
        options: {
            handler(options) {
                this.$emit('input', options);
            },
            deep: true,
        },
    },
    computed: {
        rules() {
            return {
                maxlength: (value) => value.length < PROMPT_LENGTH || 'Use short descriptive prompt',
                required: (value) => !!value || 'Text prompt is required.',
            };
        },
    },
    methods: {},
    components: {
        CornerPositionSelect,
        TextPromptFeedbackOptions,
    },
});
