import Vue from 'vue';
import { mapGetters } from 'vuex';
import VideoTag from '@/components/workspace/videos/VideoTag.vue';
import EditTagForm from '@/components/workspace/videos/ManageTagsEditTagForm.vue';
import { FieldValue } from '@/services/firebase/firestore';
export default Vue.extend({
    name: 'VideoDetailsTags',
    props: {
        video: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            showAddTagForm: false,
        };
    },
    computed: {
        ...mapGetters(['currentWorkspace', 'workspaceTags']),
        notSelectedTags() {
            return this.workspaceTags.filter(({ id }) => !this.video.tags?.includes(id));
        },
        hasTag() {
            return Boolean(this.video.tags.length);
        },
    },
    methods: {
        async removeTag(tagId) {
            await this.$db
                .video(this.video.id)
                .update({
                tags: FieldValue.arrayRemove(tagId),
            })
                .then(() => this.logVideoTagUpdate(tagId, false));
        },
        async addTag(tagId) {
            await this.$db
                .video(this.video.id)
                .update({
                tags: FieldValue.arrayUnion(tagId),
            })
                .then(() => this.logVideoTagUpdate(tagId));
        },
        onEditComplete(tagId) {
            this.showAddTagForm = false;
            void this.addTag(tagId);
        },
        async logVideoTagUpdate(tagId, isAddEvent = true) {
            const ctx = await this.$auditLogger(this.$store.state.uid);
            if (ctx) {
                const { Asset_Model, EVENT_ACTION, ts } = ctx.config;
                const asset = {
                    id: this.video.id,
                    name: this.video.title,
                    type: Asset_Model.Video,
                };
                await ctx.exec(asset, {
                    timestamp: ts(),
                    action: isAddEvent ? EVENT_ACTION.ADDED : EVENT_ACTION.REMOVED,
                    data: {
                        type: 'INFO',
                        payload: {
                            message: `Video attributes changed: ${isAddEvent ? 'tag added' : 'tad removed'}`,
                            extraInfo: {
                                tagId,
                            },
                        },
                    },
                });
            }
        },
    },
    components: {
        VideoTag,
        EditTagForm,
    },
});
