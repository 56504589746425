import Vue from 'vue';
export default Vue.extend({
    name: 'InsightCommentCounts',
    props: {
        averagePercentViewed: {
            type: Number,
        },
        loading: Boolean,
        averageViewDuration: {
            type: Number,
        },
        totalVideoViews: [Number, Object],
    },
    computed: {},
    methods: {},
    filters: {
        formatPecentage(value) {
            return Math.round(+value * 100) + '%';
        },
    },
});
