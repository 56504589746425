import { ACTIONS } from './store/store.actions';
import { mapState, mapGetters } from 'vuex';
import clientAbilities from '@lumiere/permissions/src/apps/clientAbilities';
import { updateStatusBarColor } from './utils/updateStatusBarColor';
import mixins from 'vue-typed-mixins';
import ThemeColor from '@/utils/ThemeColor';
export default mixins(ThemeColor).extend({
    data() {
        return {
            loading: true,
            globalSnackbar: false,
            globalSnackbarMessage: '',
        };
    },
    computed: {
        ...mapState(['uid', 'recentWorkspaces', 'link']),
        ...mapGetters([
            'currentWorkspace',
            'currentTeam',
            'defaultWorkspace',
            'sortedRecentWorkspaces',
        ]),
    },
    watch: {
        uid: {
            immediate: true,
            handler(newUID, oldUID) {
                if (!newUID && !!oldUID) {
                    this.onSignOut();
                }
                else if (newUID) {
                    void this.initializeUserAbilities();
                }
            },
        },
        link(val) {
            if (val) {
                this.setPrimaryColor();
            }
        },
        currentWorkspace: {
            async handler(val, oldVal) {
                this.setPrimaryColor();
                if (val && val.id !== oldVal?.id) {
                    const rules = await clientAbilities(val, this.currentTeam);
                    this.$ability.update(rules);
                    updateStatusBarColor(val.theme.color);
                }
            },
            immediate: true,
        },
        currentTeam: {
            async handler(team) {
                if (team) {
                    const rules = await clientAbilities(null, team);
                    this.$ability.update(rules);
                }
            },
            immediate: true,
        },
    },
    methods: {
        setPrimaryColor() {
            this.$vuetify.theme.themes.light.primary = this.themeColorHex;
        },
        onSignOut() {
            this.$ability.update([]);
            location.href = location.origin;
            // Firestore: clears fragments/cache thus ensuring easy hydration on next login
            location.reload();
        },
        async initializeUserAbilities() {
            // initialise user abilities
            let workspace = this.currentWorkspace;
            if (!workspace) {
                const wid = this.$store.getters.defaultWorkspace;
                if (wid) {
                    workspace = await this.$db.workspace(wid).get();
                }
            }
            const rules = await clientAbilities(workspace, this.currentTeam);
            this.$ability.update(rules);
        },
    },
    mounted() {
        updateStatusBarColor();
    },
    async created() {
        this.loading = true;
        await Promise.all([
            this.$store.dispatch(ACTIONS.SUBSCRIBE_TO_AUTH_STATE_CHANGE),
            this.$store.dispatch(ACTIONS.SET_LINK),
        ]);
        this.loading = false;
    },
    events: {
        globalMessage(params) {
            const { message } = params;
            if (message) {
                this.globalSnackbarMessage = message;
                this.globalSnackbar = true;
            }
        },
    },
});
