import Vue from 'vue';
export default Vue.extend({
    props: {
        position: String,
        src: String,
        opacity: {
            type: Number,
            default: 1,
        },
    },
});
