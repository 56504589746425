const docPath = (collection) => (id) => collection + (id ? `/${id}` : ``);
const join = (a, b) => docPath([a, b].join('/'));
const videos = docPath('videos');
const workspaces = docPath('workspaces');
const teams = docPath('teams');
const __migrations = docPath('__migrations');
export default {
    __migrations,
    teams,
    workspaces,
    videos,
    users: docPath('users'),
    channels: docPath('channels'),
    features: (vid) => join(videos(vid), 'features'),
    emojis: (wid) => join(workspaces(wid), 'emojis'),
    links: docPath('links'),
    waitlists: docPath('waitlists'),
    invitations: docPath('invitations'),
    persons: (vid) => join(videos(vid), 'persons'),
};
