import Vue from 'vue';
const logo = {
    full: {
        white: require('@/assets/logo-white.svg'),
        color: require('@/assets/logo.svg'),
    },
    icon: {
        white: require('@/assets/logo-icon-white.svg'),
        color: require('@/assets/logo-icon.svg'),
    },
};
export default Vue.extend({
    props: {
        white: Boolean,
        icon: Boolean,
    },
    computed: {
        src() {
            return this.icon
                ? this.white
                    ? logo.icon.white
                    : logo.icon.color
                : this.white
                    ? logo.full.white
                    : logo.full.color;
        },
    },
});
