import Vue from 'vue';
import { isNumber } from './helper';
import VideoInsightsChartExportMenu from './VideoInsightsChartExportMenu.vue';
const bucketRange = [1, 3, 5, 10, 15, 30, 60];
export default Vue.extend({
    name: 'VideoInsightsTrendsBucketPicker',
    props: {
        triggerDuration: Number,
        chart: Object,
        chart2: Object,
        chartTitle: {
            type: String,
            default: '',
        },
        hideBucketing: Boolean,
    },
    data() {
        return {
            bucketPickerModel: null,
            selectedBucketMobile: 0,
            filterEmotions: undefined,
            filterRatingsMinMax: undefined,
            filterBucketSize: null,
        };
    },
    computed: {
        bucketSeries() {
            const size = Math.round(this.triggerDuration / 10);
            const tags = bucketRange.filter((el) => el <= size).map(String);
            tags.unshift('AUTO');
            return tags;
        },
    },
    methods: {
        applyFilter(fromFilter) {
            if (fromFilter?.emotions) {
                this.filterEmotions = fromFilter.emotions;
            }
            if (fromFilter?.ratingsMinMax) {
                this.filterRatingsMinMax = fromFilter.ratingsMinMax;
            }
            this.$emit('filter-change', {
                emotions: this.filterEmotions,
                ratingsMinMax: this.filterRatingsMinMax,
            });
        },
        applyBucketSize() {
            this.$emit('bucket-size', this.filterBucketSize);
        },
        onBucketSelectedMobile(value) {
            this.selectedBucketMobile = value;
        },
    },
    filters: {
        formatTags(val) {
            return !isNumber(+val) ? val : `${val}S`;
        },
    },
    watch: {
        bucketPickerModel: {
            handler(val, oldVal) {
                if (isNumber(oldVal)) {
                    this.filterBucketSize = val > 0 ? bucketRange[val - 1] : null;
                    this.applyBucketSize();
                }
            },
        },
        selectedBucketMobile: {
            handler(val) {
                this.filterBucketSize = val > 0 ? bucketRange[val - 1] : null;
                this.applyBucketSize();
            },
        },
    },
    components: {
        VideoInsightsChartExportMenu,
    },
});
