import Vue from 'vue';
import UserMenu from '@/components/navbar/UserMenu.vue';
import GradientBG from '@/components/GradientBG.vue';
import LayoutTwoColumns from '@/components/LayoutTwoColumns.vue';
import Logo from '@/components/navbar/Logo.vue';
import { Routes } from '@/router/router';
import { mapGetters, mapState } from 'vuex';
import GlobalSearchbar from '@/components/navbar/GlobalSearchbar.vue';
export default Vue.extend({
    name: 'Layout',
    props: {
        color: {
            type: String,
        },
    },
    data() {
        return {
            showOnlyNavbarSearch: false,
        };
    },
    computed: {
        ...mapGetters(['getLastWorkspaceId', 'userRoleInWorkspace']),
        ...mapState(['uid']),
        Routes() {
            return Routes;
        },
        isPublic() {
            return !!this.$route.query.link;
        },
        canNavToVideos() {
            return (this.userRoleInWorkspace ||
                (this.uid && this.getLastWorkspaceId() && !this.isPublic));
        },
    },
    watch: {},
    methods: {},
    components: {
        GradientBG,
        UserMenu,
        Logo,
        LayoutTwoColumns,
        GlobalSearchbar,
    },
});
