import Vue from 'vue';
import { QuestionType } from '@lumiere/db/types';
import adminAPI from '@/services/adminAPI';
import { getBrowserQueryParam } from '@/utils/api';
import { PER_LOAD_SIZE, sentimentInterpretation, emotionColor as emotionColorHelper, } from '../helper';
import { Emotions, CommentTimeProp, } from '../types';
import VideoInsightEntitiesWordCloud from '../VideoInsightEntitiesWordCloud.vue';
import VideoInsightSentimentTimeline from '../VideoInsightSentimentTimeline.vue';
import VideoInsightItemsCounts from '../VideoInsightItemsCounts.vue';
import VideoInsightSentimentEmotionsStackedBarChart from '../VideoInsightSentimentEmotionsStackedBarChart.vue';
import VideoInsightQuestionResponseCountStackedBarChart from './VideoInsightQuestionResponseCountStackedBarChart.vue';
import logger from '@lumiere/shared/services/logger';
import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';
export default Vue.extend({
    name: 'VideoInsightQuestion',
    props: {
        video: {
            type: Object,
            required: true,
        },
        feature: {
            type: Object,
            required: true,
        },
        showFacesOutput: Boolean,
    },
    data() {
        return {
            filtering: false,
            totalInteractions: null,
            loading: false,
            aggregations: null,
            insightData: null,
            filterQuery: '',
            filterAction: '',
            filterRatingsMinMax: undefined,
            filterEmotions: undefined,
            timelineInsightData: null,
            sentimentsLoading: false,
            sentimentsFiltering: false,
            timelineTotalInteractions: null,
            filterWasChanged: true,
        };
    },
    mounted() {
        this.handleRouteQueryParam();
    },
    computed: {
        isTextQuestion() {
            return this.feature.options.type === QuestionType.Text;
        },
        isChoiceQuestion() {
            return this.feature.options.type === QuestionType.Choice;
        },
        sentiment_count() {
            return (this.aggregations?.question_responses.sentiment_count?.buckets ?? {});
        },
        sentimentsEmotions() {
            if (!this.insightData)
                return {};
            let emotions = {};
            let emotionType;
            for (const ems of this.insightData) {
                const responses = ems._source.data.payload.question
                    .responses;
                for (const response of responses) {
                    if (response.sentiment?.documentSentiment) {
                        emotionType = sentimentInterpretation(response.sentiment.documentSentiment);
                        if (!emotions[emotionType]) {
                            emotions[emotionType] = [];
                        }
                        emotions[emotionType].push(ems);
                    }
                    else if (response?.text) {
                        if (!emotions[Emotions.INDETERMINATE]) {
                            emotions[Emotions.INDETERMINATE] = [];
                        }
                        emotions[Emotions.INDETERMINATE].push(ems);
                    }
                }
            }
            for (let ems in Emotions) {
                if (!emotions[ems]) {
                    emotions[ems] = [];
                }
            }
            return emotions;
        },
        sentimentsEmotionsArray() {
            if (isEmpty(this.sentimentsEmotions))
                return [];
            let sentimentsEmotionsArray = [];
            for (const emotion in this.sentimentsEmotions) {
                for (const insightData of this.sentimentsEmotions[emotion]) {
                    sentimentsEmotionsArray.push({
                        ...insightData._source.data,
                        _id: insightData._id,
                        emotion: emotion,
                        color: this.emotionColor(emotion),
                    });
                }
            }
            const sortedEmotionsArray = sentimentsEmotionsArray.sort((a, b) => {
                return +a?.payload.currentTime - +b?.payload.currentTime;
            });
            return sortedEmotionsArray;
        },
        emotions() {
            return Emotions;
        },
        timelineSentimentsEmotions() {
            if (!this.timelineInsightData)
                return {};
            let emotions = {};
            let emotionType;
            for (const ems of this.timelineInsightData) {
                const responses = ems._source.data.payload.question
                    .responses;
                for (const response of responses) {
                    if (response.sentiment?.documentSentiment) {
                        emotionType = sentimentInterpretation(response.sentiment.documentSentiment);
                        if (!emotions[emotionType]) {
                            emotions[emotionType] = [];
                        }
                        emotions[emotionType].push(ems);
                    }
                    else if (response?.text) {
                        if (!emotions['INDETERMINATE']) {
                            emotions['INDETERMINATE'] = [];
                        }
                        emotions['INDETERMINATE'].push(ems);
                    }
                }
            }
            for (let ems in Emotions) {
                if (!emotions[ems]) {
                    emotions[ems] = [];
                }
            }
            return emotions;
        },
        timelineSentimentsEmotionsArray() {
            if (isEmpty(this.timelineSentimentsEmotions))
                return [];
            let timelineSentimentsEmotionsArray = [];
            for (const emotion in this.timelineSentimentsEmotions) {
                for (const insightData of this.timelineSentimentsEmotions[emotion]) {
                    timelineSentimentsEmotionsArray.push({
                        ...insightData._source.data,
                        _id: insightData._id,
                        emotion: emotion,
                        color: this.emotionColor(emotion),
                    });
                }
            }
            const sortedEmotionsArray = timelineSentimentsEmotionsArray.sort((a, b) => {
                return +a?.payload.currentTime - +b?.payload.currentTime;
            });
            return sortedEmotionsArray;
        },
        CommentTimeProp() {
            return CommentTimeProp;
        },
        average_rating() {
            return this.aggregations?.question_responses.average_rating.value ?? null;
        },
        counts_per_response() {
            return (this.aggregations?.question_responses.counts_per_response?.buckets ?? []);
        },
    },
    methods: {
        async loadDataResponse(param) {
            if (this.loading)
                return;
            const { size = PER_LOAD_SIZE, filterTerm = '', from = 0, ratingsRange, includedEmotions = this.filterEmotions || [1, 1, 1], isFetchingVerbatim = false, partialLoading = false, skipCommentFilterEvent = false, } = param;
            if (filterTerm || isFetchingVerbatim) {
                this.loading = false;
                if (!partialLoading) {
                    this.filtering = true;
                }
            }
            else {
                this.loading = true;
            }
            const negative = includedEmotions.includes(Emotions.NEGATIVE) ? 1 : 0;
            const neutral = includedEmotions.includes(Emotions.NEUTRAL) ? 1 : 0;
            const positive = includedEmotions.includes(Emotions.POSITIVE) ? 1 : 0;
            const emotions = [negative, neutral, positive];
            const ratings_range = { min: 0, max: 10, ...ratingsRange };
            this.$emit('question-filter', {
                filterTerm,
                ...(!skipCommentFilterEvent && {
                    rMin: ratings_range.min,
                    rMax: ratings_range.max,
                    ems: `${negative},${neutral},${positive}`,
                }),
                vctime: null,
                questionId: null,
            });
            try {
                const response = await adminAPI(async (api) => api.video.insights.getVideoQuestionInsights({
                    vid: this.video.id,
                    fid: this.feature.id,
                    cid: getBrowserQueryParam('cid'),
                    size,
                    filterTerm,
                    ratingsRange: ratings_range,
                    emotions,
                    from,
                    standalone_video_filter: getBrowserQueryParam('standalone_video_filter'),
                }));
                if (filterTerm || from === 0) {
                    this.insightData = null;
                }
                let insightData = [...(this.insightData ?? [])];
                let hits = [...(response.hits?.hits ?? [])];
                this.insightData = [...insightData, ...hits];
                this.aggregations = response.aggregations;
                this.totalInteractions =
                    response.aggregations.question_responses.doc_count;
            }
            catch (e) {
                // do something about the error
                logger.error('loadDataResponse', e);
            }
            finally {
                this.loading = false;
                this.filtering = false;
            }
            // eslint-disable-next-line @typescript-eslint/no-floating-promises
            this.loadSentimentsDataResponse({
                from,
                filterTerm,
                isFetchingVerbatim,
                ratingsRange,
                includedEmotions,
                partialLoading,
                size,
            });
        },
        async loadSentimentsDataResponse(param) {
            if (this.sentimentsLoading)
                return;
            const { size = PER_LOAD_SIZE, filterTerm = '', from = 0, ratingsRange, includedEmotions = this.filterEmotions || [1, 1, 1], isFetchingVerbatim = false, partialLoading = false, } = param;
            if (filterTerm || isFetchingVerbatim) {
                this.sentimentsLoading = false;
                if (!partialLoading) {
                    this.sentimentsFiltering = true;
                }
            }
            else {
                this.sentimentsLoading = true;
            }
            const ratings_range = { min: 0, max: 10, ...ratingsRange };
            const negative = includedEmotions.includes(Emotions.NEGATIVE) ? 1 : 0;
            const neutral = includedEmotions.includes(Emotions.NEUTRAL) ? 1 : 0;
            const positive = includedEmotions.includes(Emotions.POSITIVE) ? 1 : 0;
            const emotions = [negative, neutral, positive];
            try {
                const cid = getBrowserQueryParam('cid');
                const response = await adminAPI(async (api) => api.video.insights.getVideoQuestionInsightsSentimentData({
                    vid: this.video.id,
                    fid: this.feature.id,
                    cid,
                    filterTerm,
                    ratingsRange: ratings_range,
                    emotions,
                    from,
                    size,
                    standalone_video_filter: getBrowserQueryParam('standalone_video_filter'),
                }));
                if (filterTerm || from === 0) {
                    this.timelineInsightData = null;
                }
                let timelineInsightData = [...(this.timelineInsightData ?? [])];
                let timeline_hits = [
                    ...(response.timeline_hits?.hits ?? []),
                ];
                this.timelineInsightData = [...timelineInsightData, ...timeline_hits];
                this.timelineTotalInteractions = response.timeline_hits.total.value;
            }
            catch (e) {
                logger.error('loadSentimentsDataResponse', e);
            }
            finally {
                this.sentimentsLoading = false;
                this.sentimentsFiltering = false;
            }
            return Promise.resolve(true);
        },
        emotionColor(emotion) {
            return emotionColorHelper(emotion);
        },
        async filterTermActivated(data) {
            this.filterAction = data.action;
            this.filterQuery = data.query;
            await this.loadDataResponse({
                from: 0,
                filterTerm: data.query,
                isFetchingVerbatim: true,
                ratingsRange: this.filterRatingsMinMax,
                includedEmotions: this.filterEmotions,
            });
        },
        onFilterChanged({ emotions, ratingsMinMax }) {
            this.filterEmotions = emotions;
            this.filterRatingsMinMax = ratingsMinMax;
            this.filterWasChanged = true;
            // eslint-disable-next-line @typescript-eslint/no-floating-promises
            this.loadDataResponse({
                from: 0,
                filterTerm: this.filterQuery,
                isFetchingVerbatim: true,
                ratingsRange: this.filterRatingsMinMax,
                includedEmotions: this.filterEmotions,
            });
        },
        handleRouteQueryParam() {
            const { ems } = this.$route.query;
            const filterEmotions = [];
            if (ems) {
                const emsArr = ems.split(',');
                if (+emsArr[0] === 1) {
                    filterEmotions.push(Emotions.NEGATIVE);
                }
                if (+emsArr[1] === 1) {
                    filterEmotions.push(Emotions.NEUTRAL);
                }
                if (+emsArr[2] === 1) {
                    filterEmotions.push(Emotions.POSITIVE);
                }
            }
            else {
                filterEmotions.push(Emotions.NEGATIVE, Emotions.NEUTRAL, Emotions.POSITIVE);
            }
            this.filterRatingsMinMax = { min: 0, max: 10 };
            this.filterEmotions = filterEmotions;
            void this.loadDataResponse({});
        },
    },
    watch: {
        'video.eids': {
            async handler(eids, oldEids) {
                if (!isEqual(eids, oldEids)) {
                    // refresh the data with the new eids video filter
                    await this.$sleep(1000);
                    void this.loadDataResponse({});
                }
            },
        },
        '$route.query.cid': {
            handler(c, oc) {
                if (c || oc)
                    void this.loadDataResponse({});
            },
        },
        '$route.query.standalone_video_filter': {
            handler(v, ov) {
                if (v || ov)
                    void this.loadDataResponse({});
            },
        },
    },
    components: {
        VideoInsightEntitiesWordCloud,
        VideoInsightSentimentTimeline,
        VideoInsightItemsCounts,
        VideoInsightSentimentEmotionsStackedBarChart,
        VideoInsightQuestionResponseCountStackedBarChart,
    },
});
