import Vue from 'vue';
import EditTagForm from './ManageTagsEditTagForm.vue';
import VideoTag from './VideoTag.vue';
export default Vue.extend({
    name: 'ManageTagsEditableTagItem',
    props: {
        tag: Object,
        id: String,
        workspaceId: {
            required: true,
            type: String,
        },
    },
    data() {
        return {
            editing: false,
        };
    },
    watch: {},
    methods: {
        async deleteTag() {
            const toConfirm = await this.$confirm(`Delete '${this.tag.label}' tag?`, {
                buttonTrueText: 'Delete',
                buttonFalseText: 'Dismiss',
            });
            if (toConfirm) {
                this.$emit('deleting');
                await this.$db
                    .workspace(this.workspaceId)
                    .deleteTag(this.id)
                    .then(async () => {
                    const ctx = await this.$auditLogger(this.$store.state.uid);
                    if (ctx) {
                        const { Asset_Model, EVENT_ACTION, ts } = ctx.config;
                        const asset = {
                            id: this.workspaceId,
                            name: this.$store.getters.currentWorkspace.name,
                            type: Asset_Model.Workspace,
                        };
                        await ctx.exec(asset, {
                            timestamp: ts(),
                            action: EVENT_ACTION.DELETED,
                            data: {
                                type: 'INFO',
                                payload: {
                                    message: 'Workspace attributes changed: tags deleted',
                                    extraInfo: {
                                        tagId: this.id,
                                    },
                                },
                            },
                        });
                    }
                });
            }
        },
    },
    components: {
        EditTagForm,
        VideoTag,
    },
});
