import Vue from 'vue';
import debounce from 'lodash/debounce';
function toObject({ width, height, left, top }) {
    return {
        width,
        height,
        left,
        top,
    };
}
export default Vue.extend({
    data() {
        return {
            container: null,
            overlay: null,
        };
    },
    props: {
        aspectRatio: {
            type: Number,
            required: true,
        },
    },
    computed: {
        overlayStyle() {
            if (!this.container) {
                return {};
            }
            const { width, height } = this.container;
            return width / height / this.aspectRatio > 1
                ? {
                    width: height * this.aspectRatio + 'px',
                }
                : { height: width / this.aspectRatio + 'px' };
        },
    },
    methods: {
        updateContainerSize() {
            this.container = toObject(this.$el.getBoundingClientRect());
        },
        updateOverlaySize() {
            this.overlay = toObject(this.$refs.overlay.getBoundingClientRect());
        },
        updateSize: debounce(function () {
            this.updateContainerSize();
            this.updateOverlaySize();
        }, 100),
    },
    watch: {
        overlayStyle() {
            this.$nextTick(() => {
                this.updateOverlaySize();
            });
        },
        overlay() {
            this.$emit('overlay-size-change', this.overlay);
        },
        container() {
            this.$emit('container-size-change', this.container);
        },
    },
    mounted() {
        this.$nextTick(() => {
            this.updateSize();
        });
        window.addEventListener('resize', this.updateSize, {
            passive: true,
        });
        window.addEventListener('scroll', this.updateSize, {
            passive: true,
        });
    },
    beforeDestroy() {
        window.removeEventListener('resize', this.updateSize);
        window.removeEventListener('scroll', this.updateSize);
    },
});
