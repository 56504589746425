import Vue from 'vue';
import { EmojiPosition, } from '@lumiere/db/types';
import EmojiPicker from './emoji/EmojiPicker.vue';
import Emoji from './emoji/Emoji.vue';
import pick from 'lodash/pick';
import EmojiPromptAndPosition from './emoji/EmojiPromptAndPosition.vue';
import EmojiFeedbackOptions from './emoji/EmojiFeedbackOptions.vue';
const defaultPromptAndPosition = () => ({
    prompt: '',
    position: EmojiPosition.Center,
});
export default Vue.extend({
    data() {
        return {
            emojiPickerDialog: false,
            emojiToReplace: null,
            emojis: this.$props.value.emojis ?? [],
            prompt: this.$props.value.prompt ?? defaultPromptAndPosition().prompt,
            position: this.$props.value.position ?? defaultPromptAndPosition().position,
            feedbackOptions: this.$props.value.feedbackOptions ?? {
                showCount: false,
                showInteractions: false,
            },
        };
    },
    watch: {
        emojis(emojis) {
            this.$emit('input', {
                emojis,
                prompt: this.prompt,
                position: this.position,
                feedbackOptions: this.feedbackOptions,
            });
        },
        prompt(prompt) {
            this.$emit('input', {
                emojis: this.emojis,
                prompt,
                position: this.position,
                feedbackOptions: this.feedbackOptions,
            });
        },
        position(position) {
            this.$emit('input', {
                emojis: this.emojis,
                prompt: this.prompt,
                position,
                feedbackOptions: this.feedbackOptions,
            });
        },
        feedbackOptions(feedbackOptions) {
            this.$emit('input', {
                emojis: this.emojis,
                prompt: this.prompt,
                position: this.position,
                feedbackOptions,
            });
        },
    },
    props: {
        value: {
            type: Object,
            default() {
                return {
                    emojis: [],
                    prompt: defaultPromptAndPosition().prompt,
                    position: defaultPromptAndPosition().position,
                    feedbackOptions: {
                        showCount: false,
                        showInteractions: false,
                    },
                };
            },
        },
    },
    methods: {
        addEmoji() {
            this.emojiPickerDialog = true;
            this.emojiToReplace = null;
        },
        deleteEmoji(emoji) {
            this.emojis = [
                ...this.emojis.filter((v) => v.id !== emoji.id),
            ];
        },
        replaceEmoji(emoji) {
            this.emojiPickerDialog = true;
            this.emojiToReplace = emoji.id;
        },
        pickEmoji(inputEmoji) {
            const isPicked = this.emojis.find((emoji) => emoji.id === inputEmoji.id);
            if (!isPicked) {
                this.emojiPickerDialog = false;
                if (this.emojiToReplace) {
                    this.emojis = [
                        ...this.emojis.map((v) => {
                            return pick(v.id === this.emojiToReplace ? inputEmoji : v, [
                                'id',
                                'name',
                                'downloadURL',
                            ]);
                        }),
                    ];
                    this.emojiToReplace = null;
                }
                else {
                    this.emojis = [
                        ...this.emojis,
                        pick(inputEmoji, ['id', 'name', 'downloadURL']),
                    ];
                }
            }
        },
        updatePromptAndPosition(data) {
            this.prompt = data.prompt;
            this.position = data.position;
        },
        updateFeedbackOption(data) {
            this.feedbackOptions = data;
        },
    },
    components: {
        EmojiPicker,
        Emoji,
        EmojiPromptAndPosition,
        EmojiFeedbackOptions,
    },
});
