import Vue from 'vue';
import VueSlider from '@lumiere/shared/components/VueSlider';
import { ScrubbingStates, ScrubberMode } from '../../types';
import { formatTime } from '@lumiere/shared/utils/numberFormatter';
import memoize from 'lodash/memoize';
const TIME_DELTA = 0.001;
const EDGE_GAP = 0.005;
const findTile = (time, tiles) => {
    if (tiles.length > 1) {
        const middleItemIndex = Math.floor(tiles.length / 2);
        return tiles[middleItemIndex].start > time
            ? findTile(time, tiles.slice(0, middleItemIndex))
            : findTile(time, tiles.slice(middleItemIndex));
    }
    else {
        return tiles[0];
    }
};
export default Vue.extend({
    name: 'ScrubTrack',
    props: {
        currentTime: {
            type: Number,
        },
        storyboard: {
            type: String,
            default: null,
        },
        duration: {
            type: Number,
        },
        bufferedPercent: {
            type: Number,
            default: 0,
        },
        allowScrubbing: {
            type: Boolean,
        },
        scrubberMode: {
            type: String,
            default: ScrubberMode.TIMER,
        },
        videoPlaybackId: String,
        selectedRange: {
            type: Array,
            validator: function (value) {
                if (value)
                    return value.length === 2 && value[1] > value[0];
                return true;
            },
        },
    },
    data() {
        const rangeMax = this.$props.duration - EDGE_GAP;
        let initialRange = this.$props.selectedRange;
        if (!initialRange) {
            initialRange = [0, +rangeMax];
        }
        initialRange[1] = +initialRange[1].toFixed(2);
        return {
            findTile: memoize(findTile),
            storyboardJSON: null,
            scrubber: 0,
            mouseOver: false,
            onDrag: false,
            scrubbingTimer: null,
            scrubberOriginalTime: 0,
            scrubberBarrierState: false,
            scrubberBarrierCount: 0,
            rangeSelector: initialRange,
            isRangeSelectorDragging: false,
            options: {
                dotSize: 15,
                width: 'auto',
                height: 10,
                min: 0,
                max: 100,
                interval: 0.01,
                contained: false,
                disabled: false,
                clickable: true,
                duration: 0.01,
                adsorb: false,
                lazy: false,
                tooltip: 'active',
                tooltipPlacement: 'top',
                useKeyboard: false,
                keydownHook: null,
                dragOnClick: false,
                enableCross: true,
                fixed: false,
                minRange: void 0,
                maxRange: void 0,
                order: true,
                marks: false,
                process: true,
                stepStyle: void 0,
                stepActiveStyle: void 0,
                labelStyle: void 0,
                labelActiveStyle: void 0,
            },
            rangeSelectorOptions: {
                dotSize: 20,
                width: 'auto',
                height: 10,
                interval: 0.01,
                clickable: false,
                duration: 0.01,
                tooltip: 'active',
                min: 0,
                max: 100,
            },
        };
    },
    methods: {
        updateValue(e) {
            const currentTime = +e;
            this.scrubber = Math.round(currentTime);
            this.$emit('seek-to', currentTime);
        },
        mouseEnter() {
            this.options = {
                ...this.options,
                dotSize: 24,
                tooltip: 'always',
            };
        },
        mouseLeave() {
            this.options = {
                ...this.options,
                dotSize: 20,
                tooltip: 'active',
            };
        },
        formatTime(currentTime) {
            return formatTime(currentTime);
        },
        dragHandler(onDrag, state) {
            if (typeof onDrag === 'boolean') {
                this.onDrag = onDrag;
            }
            this.$emit('scrubbing', {
                state,
                currentTime: this.scrubber,
            });
        },
        rangeSelectorDragStartHandler() {
            this.isRangeSelectorDragging = true;
        },
        rangeSelectorDragEndHandler() {
            this.isRangeSelectorDragging = false;
            const [min, max] = this.rangeSelector;
            this.$emit('range-selection', { min, max });
        },
        rangeSelectorDraggingHandler() {
            this.isRangeSelectorDragging = true;
        },
        scrubPreviewStyle(time) {
            if (this.storyboardJSON) {
                const tile = this.findTile(Math.floor(time), this.storyboardJSON.tiles);
                return {
                    width: `${this.storyboardJSON.tile_width}px`,
                    height: `${this.storyboardJSON.tile_height}px`,
                    backgroundImage: `url('${this.storyboardJSON.url}')`,
                    backgroundPosition: `-${tile.x}px -${tile.y}px`,
                };
            }
            return {};
        },
    },
    computed: {
        showTooltip() {
            return this.mouseOver ? 'always' : 'none';
        },
        displayScrubHandle() {
            return this.allowScrubbing ? 'flex' : 'none';
        },
        scrubbingStates() {
            return ScrubbingStates;
        },
        isRangeSelection() {
            return this.scrubberMode === ScrubberMode.RANGE;
        },
    },
    watch: {
        async storyboard(storyboard) {
            if (storyboard) {
                this.storyboardJSON = Object.freeze(await fetch(storyboard).then(async (v) => v.json()));
            }
        },
        currentTime(newVal, oldVal) {
            if (!this.allowScrubbing) {
                const timeDelta = Math.abs(Math.abs(newVal) - Math.abs(oldVal));
                //If the user tries to click away, then lock in this
                //scrubberOriginalTime.
                if (timeDelta > 2 && !this.scrubberBarrierState) {
                    this.scrubberOriginalTime = oldVal;
                    this.scrubberBarrierState = true;
                }
                //else, if the user is behaving themselves, then tick upward.
                //if the user behaves themselves for 3 updates, then reset the barrier.
                else if (timeDelta > 2 && this.scrubberBarrierState) {
                    this.scrubberBarrierCount++;
                    if (this.scrubberBarrierCount == 3) {
                        this.scrubberBarrierState = false;
                        this.scrubberBarrierCount = 0;
                    }
                }
                //if we're in a barrier-state and the user is clicking
                if (this.scrubberBarrierState && timeDelta > 2) {
                    this.$emit('seek-to', this.scrubberOriginalTime);
                    this.scrubberBarrierCount = 0;
                }
            }
            if (newVal !== oldVal) {
                if (newVal < this.duration) {
                    this.scrubber = newVal;
                }
                else {
                    if (oldVal < this.duration) {
                        this.scrubber = this.duration - EDGE_GAP; // this.options.interval * 2
                    }
                }
            }
        },
        duration: {
            handler(val) {
                this.options = {
                    ...this.options,
                    max: +val.toFixed(2),
                };
                this.rangeSelectorOptions = {
                    ...this.rangeSelectorOptions,
                    max: +val.toFixed(2),
                };
            },
            immediate: true,
        },
        bufferedPercent: {
            handler(val) {
                const bufferIndicator = this.$refs.bufferIndicator;
                if (bufferIndicator) {
                    let dt = -TIME_DELTA;
                    bufferIndicator.style.width = (val + dt) * 100 + '%';
                }
            },
            immediate: true,
        },
        rangeSelector([min, max], [prevMin, prevMax]) {
            if (min !== prevMin) {
                this.updateValue(min);
            }
            else if (max !== prevMax) {
                this.updateValue(max);
            }
        },
        selectedRange: {
            handler(val) {
                if (val && this.rangeSelector) {
                    const [min, max] = val;
                    this.rangeSelector = [min, max];
                    this.rangeSelectorDragEndHandler();
                }
            },
            immediate: true,
        },
    },
    components: {
        VueSlider,
    },
});
