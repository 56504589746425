import Vue from 'vue';
export default Vue.extend({
    name: 'LoginWithLinkForm',
    props: {
        sendSignInLink: {
            type: Function,
        },
        loading: Boolean,
        signInLinkSent: Boolean,
        email: String,
    },
});
