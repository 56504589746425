import { Subject } from 'rxjs';
import { mergeMap, map } from 'rxjs/operators';
import { ACTIONS } from '@/store/store.actions';
import { MUTATIONS } from '@/store/store.mutations';
import { nanoid } from 'nanoid';
import { FileUploadStatus } from '@/store/store.state';
const PER_BATCH = 3;
export class VideoUploadQueue {
    constructor(store) {
        this.store = store;
        this.queue = new Subject();
        this.queue
            .pipe(map((payload) => {
            const { file, workspaceId } = payload;
            file.id = nanoid(10);
            this.store.commit(MUTATIONS.ADD_FILE_UPLOAD, {
                id: file.id,
                name: file.name,
                workspaceId,
                status: FileUploadStatus.QUEUED,
            });
            return {
                file,
                workspaceId,
            };
        }), mergeMap(async (payload) => this.store.dispatch(ACTIONS.UPLOAD_VIDEO_FILE, payload), PER_BATCH))
            .subscribe();
    }
    addFiles(data) {
        const { files, workspaceId } = data;
        for (let file of files) {
            this.queue.next({ file, workspaceId });
        }
    }
}
export default VideoUploadQueue;
