import Vue from 'vue';
import { VideoPageOptionsEnum } from '@lumiere/db/types';
import VideoDetails from '@/components/video/details/VideoDetails.vue';
import VideoAppearance from '@/components/video/appearance/VideoAppearance.vue';
import VideoBehaviors from '@/components/video/behaviors/VideoBehaviors.vue';
import VideoSettings from '@/components/video/settings/VideoSettings.vue';
import VideoChannelMembership from '@/components/video/membership/VideoChannelMembership.vue';
import VideoDataManagement from '@/components/video/data-management/VideoDataManagement.vue';
import VideoSceneManagement from '@/components/video/scene-management/VideoSceneManagement.vue';
import VideoIntelligence from '@/components/video/video-intelligence/VideoIntelligence.vue';
import { mapGetters, mapState } from 'vuex';
import { startWith } from 'rxjs/operators';
import { from } from 'rxjs';
import isEmpty from 'lodash/isEmpty';
var ChannelPanelIds;
(function (ChannelPanelIds) {
    ChannelPanelIds["Appearance"] = "appearance";
    ChannelPanelIds["Behaviors"] = "behaviors";
    ChannelPanelIds["Scenes"] = "scenes";
    ChannelPanelIds["Channel_Membership"] = "channel_membership";
    ChannelPanelIds["Videocode_AI"] = "videocode_ai";
    ChannelPanelIds["Data_Management"] = "data_management";
    ChannelPanelIds["Settings"] = "settings";
})(ChannelPanelIds || (ChannelPanelIds = {}));
export default Vue.extend({
    name: 'VideoSidePanel',
    props: {
        video: {
            type: Object,
            required: true,
        },
        visitedChannelId: [Object, String],
    },
    data() {
        return {
            videoChannels: null,
            expansion_panels: undefined,
            unwatchField: null,
        };
    },
    computed: {
        ...mapState(['link']),
        ...mapGetters(['currentWorkspace', 'videoURL', 'userRoleInWorkspace']),
        isPublic() {
            return !!this.$route.query.link;
        },
        showVideoScene() {
            if (this.userRoleInWorkspace) {
                return true;
            }
            if (this.isPublic && this.link) {
                const configOptions = this.link.configuration?.pageOptions?.options;
                if (configOptions) {
                    return (configOptions.includes(VideoPageOptionsEnum.SHOW_VIDEO_SCENE) && !isEmpty(this.video.scenes));
                }
            }
            return false;
        },
        panels() {
            const panels = [];
            if (this.$ability.can('manage', 'Video')) {
                panels.push({
                    name: 'Appearance',
                    component: VideoAppearance,
                    id: ChannelPanelIds.Appearance,
                }, {
                    name: 'Behaviors',
                    component: VideoBehaviors,
                    id: ChannelPanelIds.Behaviors,
                }, {
                    name: 'Scenes',
                    component: VideoSceneManagement,
                    id: ChannelPanelIds.Scenes,
                });
                if (this.videoChannels?.length) {
                    panels.splice(3, 0, {
                        name: 'Channel Membership',
                        component: VideoChannelMembership,
                        id: ChannelPanelIds.Channel_Membership,
                    });
                }
                panels.push({
                    name: 'Videocode AI',
                    component: VideoIntelligence,
                    id: ChannelPanelIds.Videocode_AI,
                }, {
                    name: 'Data Management',
                    component: VideoDataManagement,
                    id: ChannelPanelIds.Data_Management,
                }, {
                    name: 'Settings',
                    component: VideoSettings,
                    id: ChannelPanelIds.Settings,
                });
            }
            else if (this.showVideoScene) {
                panels.splice(2, 0, {
                    name: 'Scenes',
                    component: VideoSceneManagement,
                    id: ChannelPanelIds.Scenes,
                });
            }
            return panels;
        },
        standaloneVideoFilter() {
            return this.$route.query.standalone_video_filter === 'true'
                ? 'standalone_video_filter'
                : undefined;
        },
    },
    created() {
        this.watchVideoChannels();
    },
    methods: {
        watchVideoChannels() {
            this.unwatchField = this.$watch('videoChannels', (channels) => {
                if (channels) {
                    this.unwatchField?.();
                    if (channels.length &&
                        (this.visitedChannelId || this.standaloneVideoFilter)) {
                        const channelMembershipPanelIndex = this.panels.findIndex((e) => e.id === ChannelPanelIds.Channel_Membership);
                        if (channelMembershipPanelIndex > -1) {
                            this.expansion_panels = channelMembershipPanelIndex;
                        }
                    }
                }
            }, {
                immediate: true,
            });
        },
    },
    subscriptions() {
        const { userRoleInWorkspace } = this;
        if (!userRoleInWorkspace) {
            return {
                videoChannels: from([]),
            };
        }
        const video = this.$props.video;
        return {
            videoChannels: this.$db
                .channels((query) => query
                .where('workspaceId', '==', video.workspaceId)
                .where('videos', 'array-contains', video.id))
                .observe()
                .pipe(startWith(null)),
        };
    },
    components: {
        VideoDetails,
    },
});
