import { TeamRole } from '@lumiere/db/types';
import { splitBy } from '@lumiere/db/src/helpers/utils';
import { pluck, switchMap, map } from 'rxjs/operators';
import { combineLatest } from 'rxjs';
import { FieldValue } from '@/services/firebase/firestore';
import Vue from 'vue';
import { mapState } from 'vuex';
import ChangeTeamMemberRole from './members/ChangeTeamMemberRole.vue';
import adminAPI from '@/services/adminAPI';
const splitBy10 = splitBy(10);
export default Vue.extend({
    name: 'TeamMembersList',
    props: {
        team: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            userForRoleForm: null,
            showUserRoleChangeForm: false,
            members: [],
        };
    },
    computed: {
        ...mapState(['user']),
        userIsTeamAdmin() {
            return this.team.roles[this.user.id] === TeamRole.ADMIN;
        },
    },
    methods: {
        async removeMember(member) {
            const toConfirm = await this.$confirm(`${member.displayName || member.email} will lose access to all of team's workspaces.`, {
                buttonTrueText: 'Ok, remove',
                buttonFalseText: 'Cancel',
            });
            if (toConfirm) {
                await this.$db
                    .team(this.team.id)
                    .update({
                    [`roles.${member.id}`]: FieldValue.delete(),
                })
                    .then(async () => {
                    const ctx = await this.$auditLogger(this.$store.state.uid);
                    if (ctx) {
                        const { Asset_Model, EVENT_ACTION, ts } = ctx.config;
                        const asset = {
                            id: this.team.id,
                            type: Asset_Model.Team,
                            name: this.team.name,
                        };
                        await ctx.exec(asset, {
                            timestamp: ts(),
                            action: EVENT_ACTION.MEMBERS_REMOVED,
                            data: {
                                type: 'INFO',
                                payload: {
                                    message: 'Team members removed',
                                    actorAssets: {
                                        type: Asset_Model.User,
                                        ids: [member.id],
                                    },
                                },
                            },
                        });
                    }
                });
            }
        },
        deletable(role, uid) {
            return (role !== TeamRole.ADMIN ||
                (this.$store.state.uid !== uid &&
                    Object.keys(this.team.roles).length > 1));
        },
        changeRole(user) {
            this.userForRoleForm = user;
            this.showUserRoleChangeForm = true;
        },
        async resendInvitation(member) {
            await adminAPI((api) => api.emails.inviteUser({
                origin: location.origin,
                email: member.email,
                item: {
                    name: this.team.name,
                    type: 'team',
                },
            }));
        },
    },
    subscriptions() {
        const team$ = this.$watchAsObservable('team', {
            immediate: true,
        }).pipe(pluck('newValue'));
        return {
            members: team$.pipe(switchMap(({ roles }) => {
                // Firestore imposes a limit on 'in' query to ten elements
                // To work around the limit, we
                // 1) split the uids into groups of 10
                const uidGroups = splitBy10(Object.keys(roles)); // [['uid1', 'uid2',..., 'uid10'],['uid11',...]]
                // 2) create an observable for each group and combine the result into a single observable
                return combineLatest(uidGroups.map((uids) => this.$db
                    .users((ref, { FieldPath }) => ref.where(FieldPath.documentId(), 'in', uids))
                    .observe()
                    .pipe(map((users) => users.map((user) => ({
                    ...user,
                    role: roles[user.id],
                })))))).pipe(map((groupsOfUsers) => groupsOfUsers.flat()));
            })),
        };
    },
    components: {
        ChangeTeamMemberRole,
    },
});
