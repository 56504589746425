function downloadFile(data, filename, options = {}) {
    filename = filename ?? Date.now();
    const element = document.createElement('a');
    if (options.type === 'text/plain') {
        data = 'data:text/plain;charset=utf-8,' + data;
    }
    element.setAttribute('href', data);
    element.setAttribute('download', filename);
    element.style.display = 'none';
    document.body.appendChild(element);
    element.click();
    document.body.removeChild(element);
}
const fileDownloadPlugin = {
    install(vue, _options) {
        vue.prototype.$download = downloadFile;
    },
};
export default fileDownloadPlugin;
