export var TypeEnums;
(function (TypeEnums) {
    TypeEnums["UNKNOWN"] = "UNKNOWN";
    TypeEnums["PERSON"] = "PERSON";
    TypeEnums["LOCATION"] = "LOCATION";
    TypeEnums["ORGANIZATION"] = "ORGANIZATION";
    TypeEnums["EVENT"] = "EVENT";
    TypeEnums["WORK_OF_ART"] = "WORK_OF_ART";
    TypeEnums["CONSUMER_GOOD"] = "CONSUMER_GOOD";
    TypeEnums["OTHER"] = "OTHER";
    TypeEnums["PHONE_NUMBER"] = "PHONE_NUMBER";
    TypeEnums["ADDRESS"] = "ADDRESS";
    TypeEnums["DATE"] = "DATE";
    TypeEnums["NUMBER"] = "NUMBER";
    TypeEnums["PRICE"] = "PRICE";
})(TypeEnums || (TypeEnums = {}));
