import Vue from 'vue';
export default Vue.extend({
    props: {
        css: [Object, String],
        loading: Boolean,
    },
    data() {
        return {
            hasFocus: true,
        };
    },
    methods: {
        onBlur(v) {
            this.hasFocus = false;
            if (v !== this.css) {
                this.$emit('change', v);
            }
        },
    },
});
