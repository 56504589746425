import Vue from 'vue';
export default Vue.extend({
    name: 'SettingsColorPicker',
    data() {
        return {
            pickerModel: false,
        };
    },
    props: {
        color: {
            type: String,
        },
        hasColorButtonText: {
            type: String,
            default: 'Color',
        },
        hasNoColorButtonText: {
            type: String,
            default: 'Pick Color',
        },
    },
    methods: {
        updateColor(color) {
            this.$emit('change', color);
        },
        resetColor() {
            this.$emit('change', null);
        },
    },
    watch: {
        pickerModel(newVal, oldVal) {
            if (newVal && !oldVal) {
                this.$emit('open-close', true);
            }
            else if (!newVal && oldVal) {
                this.$emit('open-close', false);
            }
        },
    },
});
