import { render, staticRenderFns } from "./EmojiFeedbackInteractiveEmoji.vue?vue&type=template&id=4f0b0eb9&"
import script from "./EmojiFeedbackInteractiveEmoji.vue?vue&type=script&lang=ts&"
export * from "./EmojiFeedbackInteractiveEmoji.vue?vue&type=script&lang=ts&"
import style0 from "./EmojiFeedbackInteractiveEmoji.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/.pnpm/vue-loader@15.9.7_fb85a9817f99bdf862e5e3a442a1fb91/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installDirectives from "!../../../../../../node_modules/.pnpm/vuetify-loader@1.7.2_c225a462abeaf467a2189c5bd12e241a/node_modules/vuetify-loader/lib/runtime/installDirectives.js"
import Touch from 'vuetify/lib/directives/touch'
installDirectives(component, {Touch})
