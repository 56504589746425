import Vue from 'vue';
import { timestampToDate } from '@/utils/helpers';
export default Vue.extend({
    name: 'RecentlyDeletedChannelsList',
    props: {
        deletedChannels: {
            type: Array,
            required: true,
        },
    },
    data() {
        return {
            selectedChannels: [],
        };
    },
    methods: {
        timestampToDate(ts) {
            return timestampToDate(ts);
        },
    },
    watch: {
        selectedChannels(channels) {
            this.$emit('selectedChannels', channels);
        },
    },
});
