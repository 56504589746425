import Vue from 'vue';
import VideoSceneAddWizard from './VideoSceneAddWizard.vue';
import VideoSceneCard from './VideoSceneCard.vue';
import { map } from 'rxjs/operators';
function reorderScenes(scenes) {
    return Object.keys(scenes)
        .map((id) => ({ ...scenes[id], id }))
        .sort((a, b) => a.startTime - b.startTime);
}
export default Vue.extend({
    name: 'VideoSceneManagement',
    props: {
        video: { type: Object },
    },
    data() {
        return {
            scenes: null,
            selectedScene: null,
            isExternallyReset: false,
        };
    },
    computed: {
        isSelected() {
            return (sceneId) => this.selectedScene === sceneId;
        },
    },
    subscriptions() {
        const video = this.$props.video;
        return {
            scenes: this.$db
                .video(video.id)
                .observe()
                .pipe(map((video) => video?.scenes ?? {}), map((scenes) => reorderScenes(scenes))),
        };
    },
    watch: {
        selectedScene(sceneId) {
            if (sceneId) {
                this.$events.emit('resetVideoFacesFilter');
            }
            let min = null;
            let max = null;
            if (sceneId && this.scenes) {
                const scene = this.scenes.find((s) => s.id === sceneId);
                if (scene) {
                    min = scene.startTime.toString();
                    max = scene.stopTime.toString();
                }
            }
            if (!this.isExternallyReset) {
                this.$events.emit('videoSceneFilter', {
                    min,
                    max,
                    scene: sceneId,
                });
            }
            this.isExternallyReset = false;
        },
    },
    events: {
        resetVideoSceneFilter() {
            this.selectedScene = null;
            this.isExternallyReset = true;
        },
    },
    components: {
        VideoSceneAddWizard,
        VideoSceneCard,
    },
});
