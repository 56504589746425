import './init';
import firebase from 'firebase/app';
import 'firebase/performance';
import logger from '@lumiere/shared/services/logger';
logger.log('initialize firebase performance');
const performance = firebase.performance();
const performanceEnabled = process.env.VUE_APP_PROJECT_ENV !== 'local';
performance.dataCollectionEnabled = performanceEnabled;
performance.instrumentationEnabled = performanceEnabled;
export default performance;
