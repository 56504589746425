import Vue from 'vue';
import { VideoVisibility, LinkStatus } from '@lumiere/db/types';
export default Vue.extend({
    name: 'VideoSettingsVideoVisibility',
    props: {
        video: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            visibility: this.video.visibility,
        };
    },
    computed: {
        VideoVisibility: () => VideoVisibility,
    },
    watch: {
        'visibility': {
            async handler(visibility) {
                let payload = { visibility };
                if (visibility === VideoVisibility.Private && this.video.shareLink) {
                    if (this.video.shareLink.status === LinkStatus.enabled) {
                        const disableShare = await this.$confirm(`Private videos are not shareable.\nDo you want to disable share on the video?`, {
                            buttonTrueText: 'Continue',
                            buttonFalseText: 'Dismiss',
                        });
                        if (disableShare) {
                            payload['shareLink.status'] = LinkStatus.disabled;
                            await this.disableShareLink(this.video.shareLink?.id);
                        }
                    }
                }
                this.$db.video(this.video.id).update(payload);
            },
        },
        'video.visibility': {
            handler(visibility, oldVal) {
                if (visibility !== oldVal)
                    this.visibility = visibility;
            },
        },
    },
    methods: {
        async disableShareLink(id) {
            if (id) {
                await this.$db.link(id).update({
                    status: 'disabled',
                });
            }
        },
    },
    subscriptions() {
        return {};
    },
    components: {},
});
