import VideoUploadMixin from '@/utils/VideoUploadMixin';
import mixins from 'vue-typed-mixins';
import { VIDEOFORMATS } from '@/utils/uploadFileToStorage';
export default mixins(VideoUploadMixin).extend({
    props: {
        workspaceId: String,
    },
    data: () => ({
        dialog: false,
    }),
    methods: {
        makeFileUploads(e) {
            const files = e.target.files;
            if (!files)
                return;
            if (files.length > 45) {
                this.$emit('addVideoError', 'Please keep your uploads below 45 videos at a time');
                e.preventDefault();
                return;
            }
            this.uploadFiles(files, 'file', null);
        },
    },
    computed: {
        acceptedFormats() {
            return VIDEOFORMATS;
        },
    },
});
