import Vue from 'vue';
import { nanoid } from 'nanoid';
import { FeatureType, FeatureTriggerType, VideoTriggerEvent, } from '@lumiere/db/types';
import FeatureTypeSelect from './FeatureTypeSelect.vue';
import FeatureAddWizardStep from './FeatureAddWizardStep.vue';
import FeatureOptions from './featureOptions/FeatureOptions.vue';
import FeatureTriggers from './featureTriggers/FeatureTriggers.vue';
import adminAPI from '@/services/adminAPI';
import logger from '@lumiere/shared/services/logger';
var FeatureConfigStep;
(function (FeatureConfigStep) {
    FeatureConfigStep[FeatureConfigStep["Type"] = 1] = "Type";
    FeatureConfigStep[FeatureConfigStep["Options"] = 2] = "Options";
    FeatureConfigStep[FeatureConfigStep["Triggers"] = 3] = "Triggers";
    FeatureConfigStep[FeatureConfigStep["Preview"] = 4] = "Preview";
})(FeatureConfigStep || (FeatureConfigStep = {}));
const defaultFeatureProps = () => ({
    type: null,
    label: '',
    enabled: true,
    options: {},
});
export default Vue.extend({
    name: 'FeatureAddWizard',
    props: {
        videoId: {
            type: String,
            required: true,
        },
        value: {
            type: Boolean,
        },
    },
    data() {
        return {
            step: 1,
            loading: false,
            feature: defaultFeatureProps(),
            triggers: null,
            stoppers: null,
            JSONFromCSV: null,
        };
    },
    computed: {
        FeatureConfigStep: () => FeatureConfigStep,
        title() {
            return 'Select Feature Type';
        },
        featureOptionsHasValue() {
            const feature = this.feature;
            const { type, options } = feature;
            if (type === FeatureType.Comments) {
                return true;
            }
            if (options) {
                switch (type) {
                    case FeatureType.Emojis:
                        return 'emojis' in options ? !!options.emojis?.length : false;
                    case FeatureType.TextPrompt:
                        return 'prompt' in options ? !!options.prompt : false;
                    case FeatureType.ExternalData:
                        return 'text' in options ? options.text : false;
                    case FeatureType.Question:
                        return 'prompt' in options ? !!options.prompt : false;
                    default:
                        return false;
                }
            }
            return false;
        },
        questionFeatureTriggerHasValue() {
            const { type } = this.feature;
            if (type !== FeatureType.Question) {
                return true;
            }
            const trigger = this.triggers?.[0];
            if (trigger) {
                switch (trigger.type) {
                    case FeatureTriggerType.VideoEvent:
                        return (trigger.event === VideoTriggerEvent.VideoTime &&
                            trigger.parameters.time != null);
                    case FeatureTriggerType.FeatureEvent:
                        return !!trigger.parameters.featureId;
                    default:
                        return false;
                }
            }
            return false;
        },
    },
    methods: {
        next() {
            if (this.step === 2 && this.feature.type === FeatureType.ExternalData) {
                this.step = this.step + 1;
            }
            this.step = this.step + 1;
        },
        prev() {
            if (this.step === 4 && this.feature.type === FeatureType.ExternalData) {
                this.step = this.step - 1;
            }
            this.step = this.step - 1;
        },
        close() {
            this.$emit('input', false);
        },
        async saveFeature() {
            const fid = nanoid();
            this.loading = true;
            try {
                this.feature.triggers = this.triggers;
                this.feature.stoppers = this.stoppers; // TODO: improve type support of different kinds of triggers
                const { type, options } = this.feature;
                let isVideoExternalDataFeature = false;
                if (type === FeatureType.ExternalData && options && 'text' in options) {
                    const { text, eid, cid, videoTime, rating, locationX, locationY } = options;
                    this.feature.options = {
                        text,
                        eid,
                        cid,
                        videoTime,
                        rating,
                        locationX,
                        locationY,
                    };
                    isVideoExternalDataFeature = true;
                }
                await this.$db
                    .video(this.videoId)
                    .feature(fid)
                    .create(this.feature)
                    .then(async () => {
                    // ingest video-external-data
                    if (isVideoExternalDataFeature) {
                        void this.saveVideoExternalData(fid);
                    }
                    // save audit_log
                    const ctx = await this.$auditLogger(this.$store.state.uid);
                    if (ctx) {
                        const { Asset_Model, EVENT_ACTION, ts } = ctx.config;
                        const asset = {
                            id: fid,
                            name: this.feature.label,
                            type: Asset_Model['Video.Feature'],
                        };
                        await ctx.exec(asset, {
                            timestamp: ts(),
                            action: EVENT_ACTION.CREATED,
                            data: {
                                type: 'INFO',
                                payload: {
                                    message: 'Video.Feature is created',
                                    actorAssets: {
                                        type: Asset_Model.Video,
                                        ids: [this.videoId],
                                    },
                                },
                            },
                        });
                    }
                });
                this.close();
            }
            catch (err) {
                logger.error('saveFeature:Error', err);
            }
            this.loading = false;
        },
        saveTriggers({ triggers, stoppers, }) {
            this.triggers = triggers;
            this.stoppers = stoppers;
        },
        async saveVideoExternalData(fid) {
            // synchronously save the data on elasticsearch
            this.$events.emit('indexExternalData', {
                status: 'READY',
                type: 'CREATE',
                fid,
                vid: this.videoId,
            });
            await adminAPI((api) => api.video.insights.saveVideoExternalData({
                fid,
                vid: this.videoId,
                json: this.JSONFromCSV ?? [],
            })).then((res) => {
                logger.info('Done saving data import to ES', res);
                this.$events.emit('indexExternalData', {
                    status: 'COMPLETED',
                    type: 'CREATE',
                    fid,
                    vid: this.videoId,
                });
            });
        },
    },
    watch: {
        'value'(value) {
            if (!value) {
                this.feature = defaultFeatureProps();
                this.step = FeatureConfigStep.Type;
                this.loading = false;
            }
        },
        'feature.options': {
            handler(options) {
                if (options.json) {
                    this.JSONFromCSV = options.json;
                }
            },
        },
    },
    components: {
        FeatureAddWizardStep,
        FeatureTypeSelect,
        FeatureOptions,
        FeatureTriggers,
    },
});
