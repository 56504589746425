import Vue from 'vue';
import auth from '@/services/firebase/auth';
import logger from '@lumiere/shared/services/logger';
import locallyStored from '@lumiere/shared/utils/locallyStored';
const locallyStoredEmail = locallyStored('EMAIL');
export default Vue.extend({
    name: 'EmailVerification',
    data() {
        return {
            overlay: true,
            email: locallyStoredEmail.get() || '',
            loading: false,
            promptEmail: false,
        };
    },
    created() {
        if (this.email) {
            this.signInWithLink();
        }
        else {
            // prompt the user for the email
            this.promptEmail = true;
        }
    },
    methods: {
        async signInWithLink() {
            this.loading = true;
            try {
                await auth.signInWithEmailLink(this.email, window.location.href);
                locallyStoredEmail.remove();
                this.$emit('email-link-success');
            }
            catch (e) {
                let signInWithLinkErrorMessage;
                switch (e.code) {
                    case 'auth/invalid-email':
                        signInWithLinkErrorMessage =
                            'Email address does not match the emailLink being used. Please try again.';
                        break;
                    case 'auth/invalid-action-code':
                        signInWithLinkErrorMessage =
                            'The sign-in emailLink is no longer valid. Please request a new emailLink below.';
                        break;
                    case 'auth/expired-action-code':
                        signInWithLinkErrorMessage =
                            'The sign-in emailLink has expired. Please request a new emailLink below.';
                        break;
                    default:
                        signInWithLinkErrorMessage =
                            'Something does not look right. Please try again.';
                        break;
                }
                logger.error('signInWithLink', { e, signInWithLinkErrorMessage });
                this.$emit('email-link-error', { message: signInWithLinkErrorMessage });
                this.loading = false;
            }
        },
    },
    beforeRouteLeave(_to, _from, next) {
        this.promptEmail = false;
        next();
    },
});
