var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{ref:"timeSegments",staticClass:"time-segments"},[(!_vm.instances)?_c('v-progress-linear',{attrs:{"indeterminate":"","color":"primary","height":10}}):_c('div',{staticClass:"time-segments-main",attrs:{"id":("time-segments-main__" + _vm.internalKey)}}),_c('div',{staticClass:"time-segments__content"},[_c('span',{staticClass:"time-segments__content--text grey--text"},[_vm._v(" Appears in "+_vm._s(_vm._f("formatSeenDurationRatio")(_vm.seenDurationRatio))+" of video "),(
          _vm.faceIds.length > 1 &&
          _vm.$can('manage', 'Video.Person') &&
          _vm.managementMode
        )?_c('span',{staticClass:"unmerge-faces",on:{"click":function () { return _vm.$emit('unmerge-persons', { pid: _vm.personId }); }}},[_vm._v(" ( "),_c('label',{staticClass:"primary--text"},[_vm._v(" unmerge "+_vm._s(_vm.faceIds.length)+" faces ")]),_vm._v(" ) ")]):_vm._e()])]),(_vm.midHoverPoint)?_c('div',{class:[
      'time-segments__image-preview',
      {
        'cursor-halfway-left': _vm.cursorHalfwayLeft,
        'cursor-halfway-right': !_vm.cursorHalfwayLeft,
      } ]},[_c('VideoThumbnail',{attrs:{"vid":_vm.videoId,"time":_vm.midHoverPoint},scopedSlots:_vm._u([{key:"default",fn:function(ref){
    var thumbnail = ref.thumbnail;
return [_c('transition',{attrs:{"name":"fade"}},[_c('v-img',{staticClass:"time-segments__image-preview--image-item",attrs:{"aspect-ratio":16 / 9,"src":thumbnail},scopedSlots:_vm._u([{key:"placeholder",fn:function(){return [_c('v-skeleton-loader',{staticClass:"skeleton-loader",attrs:{"type":"image"}})]},proxy:true}],null,true)})],1)]}}],null,false,15113490)})],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }