import Vue from 'vue';
export const FeatureLabels = {
    Retention: 'Retention',
    Emojis: 'Emojis',
    Comments: 'Comments +',
    TextPrompt: 'Text Prompt',
    ExternalData: 'External Data',
    Tags: 'Tags',
    Question: 'Question',
    DialTest: 'Dial Test',
    Instruction: 'Instruction',
    SocialChat: 'Social Chat',
    Timer: 'Timer',
    Redirect: 'Redirect',
};
export default Vue.extend({
    inheritAttrs: false,
    props: {
        feature: {
            type: String,
            required: true,
        },
        color: {
            type: String,
        },
    },
    computed: {
        label() {
            return FeatureLabels[this.feature];
        },
    },
});
