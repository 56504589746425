import { TextQuestionVariant } from '@lumiere/db/types';
import Vue from 'vue';
export default Vue.extend({
    name: 'QuestionFeedbackText',
    computed: {
        TextQuestionVariant: () => TextQuestionVariant,
        input() {
            return this.$refs.input;
        },
    },
    props: {
        options: Object,
        response: {
            type: Object,
        },
    },
    mounted() {
        this.input.focus();
    },
});
