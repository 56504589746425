import { doc } from 'rxfire/firestore';
import { map } from 'rxjs/operators';
import { VideoStatus } from '../types';
import { person, persons } from './db.videos.persons';
import { feature, features } from './db.videos.features';
import paths from './helpers/paths';
import { dbCollection, getUID, mapDoc, signatures } from './helpers/utils';
export const video = (params) => (vid) => {
    const { firestore, dbAuth, FieldValue } = params;
    const videoRef = firestore.doc(paths.videos(vid));
    const { created, edited, deleted } = signatures(FieldValue);
    return {
        async update(update, batch) {
            const uid = await getUID(dbAuth);
            const data = {
                ...update,
                ...edited(uid),
            };
            return batch ? batch.update(videoRef, data) : await videoRef.update(data);
        },
        async create(videoProps) {
            const uid = await getUID(dbAuth);
            const data = {
                ...videoProps,
                ...edited(uid),
                ...created(uid),
            };
            await videoRef.set(data);
        },
        observe: () => doc(videoRef).pipe(map((v) => mapDoc(v))),
        get: async () => mapDoc(await videoRef.get()),
        async delete(outerBatch) {
            const batch = outerBatch || firestore.batch();
            await this.features().delete(batch);
            batch.delete(videoRef);
            // batch will be committed by the batch creator
            if (!outerBatch) {
                await batch.commit();
            }
        },
        async softDelete(outerBatch) {
            const uid = await getUID(dbAuth);
            const deleteUpdate = { ...deleted(uid), status: VideoStatus.Deleted };
            return outerBatch
                ? outerBatch.update(videoRef, deleteUpdate)
                : videoRef.update(deleteUpdate);
        },
        async restore(outerBatch) {
            const uid = await getUID(dbAuth);
            const restoreUpdate = {
                ...edited(uid),
                status: VideoStatus.Ready,
                deleted: FieldValue.delete(),
                deletedBy: FieldValue.delete(),
                deletedTime: FieldValue.delete(),
            };
            return outerBatch
                ? outerBatch.update(videoRef, restoreUpdate)
                : videoRef.update(restoreUpdate);
        },
        features: features(vid)(params),
        feature: feature(vid)(params),
        persons: persons(vid)(params),
        person: person(vid)(params),
    };
};
export const videos = dbCollection(paths.videos());
