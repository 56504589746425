import Vue from 'vue';
import { VIDEOFORMATS, isValidVideoFormat } from '@/utils/uploadFileToStorage';
export default Vue.extend({
    data() {
        return {
            active: false,
            alertDialog: false,
            alertDialogMessage: '',
        };
    },
    computed: {
        canUploadVideo() {
            return this.$ability.can('manage', 'Video');
        },
    },
    methods: {
        addFiles(files) {
            try {
                const workspaceId = this.$store.getters.currentWorkspace.id;
                return this.$videoUploadQueue.addFiles({ files, workspaceId });
            }
            catch (err) {
                this.alertDialogMessage = err?.message ?? err;
                this.alertDialog = true;
            }
        },
        onDrop(e) {
            if (e.dataTransfer?.effectAllowed !== 'link') {
                // logger.info('drop', e)
                if (!this.canUploadVideo) {
                    e.stopPropagation();
                    return;
                }
                this.active = false;
                if (e.dataTransfer) {
                    const files = e.dataTransfer.files;
                    if (files.length > 45) {
                        this.alertDialogMessage =
                            'Please keep your uploads below 45 videos at a time';
                        this.alertDialog = true;
                        e.preventDefault();
                        return;
                    }
                    for (const file of files) {
                        const acceptedFormats = VIDEOFORMATS.split(',');
                        acceptedFormats.shift();
                        if (!isValidVideoFormat(file)) {
                            this.alertDialogMessage = `Video file(s) must be of the appropriate formats: ${acceptedFormats.join(',')}`;
                            this.alertDialog = true;
                            e.preventDefault();
                            return;
                        }
                    }
                    this.addFiles(files);
                }
            }
        },
        onDragOver(e) {
            if (e.dataTransfer?.effectAllowed !== 'link') {
                if (!this.canUploadVideo) {
                    e.stopPropagation();
                    return;
                }
                this.active = true;
            }
        },
        onDragLeave() {
            this.active = false;
        },
        onDragEnter(e) {
            if (e.dataTransfer?.effectAllowed !== 'link') {
                if (!this.canUploadVideo) {
                    e.stopPropagation();
                    return;
                }
                this.active = true;
            }
        },
    },
});
