/* eslint-disable no-console */
import { Severity } from '../logger';
import { getNamespaceColor } from '../utils/getNamespaceColor';
export function BrowserConsoleTransport() {
    const getColor = getNamespaceColor([
        'red',
        'green',
        'yellow',
        'blue',
        'magenta',
        'cyan',
    ]);
    const consoleMethodMap = {
        [Severity.DEFAULT]: console.log,
        [Severity.WARNING]: console.warn,
        [Severity.DEBUG]: console.log,
        [Severity.INFO]: console.info,
        [Severity.ERROR]: console.error,
        [Severity.CRITICAL]: console.error,
    };
    return ({ severity, namespace, data, message }) => {
        consoleMethodMap[severity]('%c' + `${namespace}`, `color: ${getColor(namespace)}`, message, data);
    };
}
