import Vue from 'vue';
import { Routes } from '@/router/router';
import { switchMap, startWith, debounceTime, shareReplay, pluck, map, } from 'rxjs/operators';
import adminAPI from '@/services/adminAPI';
import logger from '@lumiere/shared/services/logger';
import publicDB from '@/utils/publicDB';
export default Vue.extend({
    name: 'SignupWithOptIn',
    props: {
        nonUserEmail: {
            type: String,
            default: '',
        },
    },
    data() {
        return {
            loading: false,
            userWaitListDoc: null,
            email: this.$props.nonUserEmail,
            validateEmailEntry: null,
            joinWaitListSuccess: false,
            validationError: [],
        };
    },
    computed: {
        userInWaitList() {
            return !!this.userWaitListDoc;
        },
        entryEmailInWaitList() {
            return !!this.validateEmailEntry;
        },
        isValidEmail() {
            return (email) => /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(email);
        },
    },
    methods: {
        gotoLogin() {
            this.$router.push({
                name: Routes.Login,
            });
        },
        async joinWaitingList() {
            this.loading = true;
            // send email to the server
            await adminAPI(async (api) => api.emails.joinWaitingList({
                email: this.email,
            }))
                .then((res) => {
                logger.info('joinWaitingList Response', res);
                this.$emit('join-wait-list-success');
                this.joinWaitListSuccess = true;
                // this.gotoLogin()
            })
                .catch((e) => {
                logger.error('joinWaitingList Error', e);
                this.validationError = [e.message];
            })
                .finally(() => {
                this.loading = false;
            });
        },
    },
    subscriptions() {
        const nonUserEmail = this.$props.nonUserEmail;
        const email$ = this.$watchAsObservable('email', {
            immediate: true,
            deep: true,
        }).pipe(pluck('newValue'));
        return {
            userWaitListDoc: publicDB()
                .waitlists((q) => q.where('email', '==', nonUserEmail).limit(1))
                .observe()
                .pipe(switchMap(async (waitlist) => Promise.resolve(waitlist)), map((waitlist) => waitlist[0]), startWith(null), shareReplay(1)),
            validateEmailEntry: email$.pipe(debounceTime(500), switchMap((email) => publicDB()
                .waitlists((q) => q.where('email', '==', email).limit(1))
                .observe()
                .pipe(map((waitlist) => waitlist[0]), startWith(null))), shareReplay(1)),
        };
    },
    watch: {
        userInWaitList: {
            handler(val) {
                if (val)
                    this.$emit('already-in-wait-list');
            },
            immediate: true,
        },
        entryEmailInWaitList: {
            handler(val) {
                if (val)
                    this.validationError = ['Email is already in waitlist'];
                else
                    this.validationError = [];
            },
            immediate: true,
        },
        // validationError
    },
    beforeRouteLeave(_to, _from, next) {
        this.joinWaitListSuccess = false;
        next();
    },
});
