import Vue from 'vue';
import { ChannelRoutes } from '@/router/router';
export default Vue.extend({
    name: 'channel-card',
    props: {
        channel: {
            type: Object,
            required: true,
        },
        shouldDisableMenu: Boolean,
    },
    data() {
        return {
            showMenu: false,
        };
    },
    computed: {
        ChannelRoutes() {
            return ChannelRoutes;
        },
        hasImage() {
            return !!this.channel.image;
        },
    },
    watch: {},
    methods: {
        deleteChannel() {
            this.$confirm(`Are you sure you want to delete ${this.channel.title}`).then(async (ok) => {
                if (ok) {
                    await this.$db.channel(this.channel.id).softDelete();
                }
            });
        },
    },
    subscriptions() {
        return {};
    },
    components: {},
});
