import ACTIONS from '@/store/ACTIONS';
import Vue from 'vue';
export default Vue.extend({
    name: 'DeleteWorkspaceConfirmationForm',
    props: {
        workspace: Object,
    },
    data() {
        return {
            deleting: false,
            name: '',
        };
    },
    methods: {
        async deleteWorkspace() {
            this.deleting = true;
            void this.$store.dispatch(ACTIONS.DELETE_RECENT_WORKSPACE, this.workspace.id);
            await this.$db
                .workspace(this.workspace.id)
                .softDelete()
                .then(async () => {
                const ctx = await this.$auditLogger(this.$store.state.uid);
                if (ctx) {
                    const { Asset_Model, EVENT_ACTION, ts } = ctx.config;
                    const asset = {
                        id: this.workspace.id,
                        type: Asset_Model.Workspace,
                        name: this.workspace.name,
                    };
                    await ctx.exec(asset, {
                        timestamp: ts(),
                        action: EVENT_ACTION.SOFT_DELETED,
                        data: {
                            type: 'INFO',
                            payload: {
                                message: `Workspace has been soft deleted`,
                            },
                        },
                    });
                }
            });
        },
    },
});
