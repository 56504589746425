import Vue from 'vue';
import config from '@lumiere/shared/config';
export default Vue.extend({
    name: 'BaseEmoji',
    props: {
        id: {
            type: String,
            required: true,
        },
        name: {
            type: String,
        },
        size: {
            type: Number,
            default: 64,
        },
        downloadURL: {
            type: String,
            required: false,
        },
    },
    computed: {
        src() {
            return (this.downloadURL ?? `${config.adminAppURL}/img/emoji/${this.id}.svg`);
        },
    },
});
