import Vue from 'vue';
import { EndAction, DefaultVideoBehaviors, } from '@lumiere/db/types';
import { FieldValue } from '@/services/firebase/firestore';
export default Vue.extend({
    data() {
        return {
            loading: false,
        };
    },
    props: {
        video: {
            type: Object,
        },
    },
    watch: {},
    computed: {
        behaviors() {
            return { ...DefaultVideoBehaviors, ...this.video.behaviors };
        },
        EndAction: () => EndAction,
    },
    methods: {
        async save(behaviors) {
            this.loading = true;
            const keys = Object.keys(behaviors);
            const updateObject = keys.reduce((v, key) => {
                return {
                    ...v,
                    [`behaviors.${key}`]: behaviors[key] ?? FieldValue.delete(),
                };
            }, {});
            await this.$db
                .video(this.video.id)
                .update(updateObject)
                .then(async () => {
                const ctx = await this.$auditLogger(this.$store.state.uid);
                if (ctx) {
                    const { Asset_Model, EVENT_ACTION, ts } = ctx.config;
                    const asset = {
                        id: this.video.id,
                        name: this.video.title,
                        type: Asset_Model.Video,
                    };
                    await ctx.exec(asset, {
                        timestamp: ts(),
                        action: EVENT_ACTION.UPDATED,
                        data: {
                            type: 'INFO',
                            payload: {
                                message: 'Video attributes changed',
                                extraInfo: {
                                    ...updateObject,
                                },
                            },
                        },
                    });
                }
            });
            this.loading = false;
        },
    },
});
