import Vue from 'vue';
import EmojiListAvatar from './EmojiListAvatar.vue';
import EmojiUploaderItem from './EmojiUploaderItem.vue';
export default Vue.extend({
    name: 'EmojiListDialog',
    props: {
        showDialog: {
            type: Boolean,
            default: false,
        },
        emojiFiles: {
            type: FileList,
        },
        existingEmojis: {
            type: Array,
            default: () => [],
        },
    },
    data() {
        return {
            dialog: false,
            emojiNamesForm: null,
            error__helper: false,
            error__message: '',
            canSubmit: false,
            showEmojiUploader: false,
            emojisHash: {},
        };
    },
    methods: {
        toggleDialog(show) {
            if (typeof show === 'boolean') {
                this.dialog = show;
            }
            else {
                this.dialog = !this.dialog;
            }
        },
        onSubmit(formEvt) {
            this.error__helper = false;
            const formElements = Array.from(formEvt.target.elements);
            const { emojiNames, validFields, allEmojiNamesAreUnique, } = this.validateFieldEntry(formElements);
            // ensure no 2 emojis have the same name
            if (new Set(emojiNames).size < emojiNames.length ||
                validFields.length < emojiNames.length) {
                this.error__helper = true;
                this.error__message = 'Please give a unique & valid name to each emoji';
                return;
            }
            // reject if all emoji names are not unique
            if (!allEmojiNamesAreUnique) {
                this.error__helper = true;
                this.error__message = 'Please do not reuse existing emoji names';
                return;
            }
            this.showEmojiUploader = true;
        },
        dismissEmojiDialog() {
            this.dialog = false;
            this.$emit('dismissEmojiDialog');
            this.showEmojiUploader = false;
        },
        validateFieldEntry(formElements) {
            const emojiNames = formElements
                .filter((el) => el.type === 'text')
                .map((el) => el.value.trim());
            // validate empty field entry
            const validFields = emojiNames.filter((e) => e.toString().trim() && e.toString().length >= 3);
            // ensure new emojis don't bear the name of existing emojis
            let allEmojiNamesAreUnique = true;
            for (let i = 0; i < emojiNames.length; i++) {
                if (this.checkNameInEmojisHash(emojiNames[i])) {
                    allEmojiNamesAreUnique = false;
                    break;
                }
            }
            return { emojiNames, validFields, allEmojiNamesAreUnique };
        },
        checkEmpty() {
            const formRef = this.$refs.emojiNamesForm;
            const formElements = Array.from(formRef.elements);
            const { emojiNames, validFields, allEmojiNamesAreUnique, } = this.validateFieldEntry(formElements);
            this.canSubmit =
                validFields.length === emojiNames.length && allEmojiNamesAreUnique
                    ? true
                    : false;
        },
        onIntersect(entries) {
            // More information about these options
            // is located here: https://developer.mozilla.org/en-US/docs/Web/API/Intersection_Observer_API
            const isIntersecting = entries.find((el) => el.isIntersecting);
            if (isIntersecting) {
                this.checkEmpty();
            }
        },
        doneUploading() {
            this.dismissEmojiDialog();
        },
        checkNameInEmojisHash(name) {
            return !!this.emojisHash[name.trim().toLowerCase()];
        },
    },
    computed: {
        rules() {
            return {
                required: (value) => !!value || 'emoji name is required',
                minlength: (value) => (!!value && value.length > 2) || 'enter a valid description',
                existingname: (value) => (!!value && !this.checkNameInEmojisHash(value)) ||
                    'Please do not use existing emoji names',
            };
        },
    },
    watch: {
        showDialog(val) {
            this.toggleDialog(val);
        },
        emojiFiles: {
            handler(files) {
                if (files) {
                    // transform the files to include a new emojiName key
                    Array.from(files).map((el, ix) => {
                        const fileItem = files.item(ix);
                        fileItem.emojiName = el.name.split('.').shift();
                        return fileItem;
                    });
                }
            },
            immediate: true,
        },
        existingEmojis: {
            handler(emojis) {
                const emojisHash = {};
                for (let i = 0; i < emojis.length; i++) {
                    emojisHash[emojis[i].name] = emojis[i];
                }
                this.emojisHash = emojisHash;
            },
            immediate: true,
        },
    },
    components: {
        EmojiListAvatar,
        EmojiUploaderItem,
    },
});
