let numberFormatter: Intl.NumberFormat | null = null

try {
  if (window.Intl) {
    numberFormatter = window.Intl.NumberFormat()
  }
} catch {
  //take no action
}

export const formatThousands = (
  value: number | null | undefined,
  defaultReturn: string = '',
): string => {
  if (value != null) {
    // undefined == null, undefined !== null
    if (numberFormatter) {
      return numberFormatter.format(+value)
    }

    return `${value}`
  }

  return defaultReturn
}

export function formatTime(currentTime: number | string) {
  const roundTime = Math.round(+currentTime)
  const hours = Math.floor(roundTime / 3600)
  const minutes = Math.floor(roundTime / 60) - hours * 60
  const seconds = roundTime % 60

  let hourValue = hours
  let minuteValue = minutes < 10 && hourValue ? '0' + minutes : minutes
  let secondValue = seconds < 10 ? '0' + seconds : seconds

  let time = minuteValue + ':' + secondValue
  time = hourValue ? hourValue + ':' + time : time

  return time
}
