import Vue from 'vue';
import { FirebaseAuth } from '@/services/firebase/auth';
import customAuth, { SOCIAL_SIGN_IN_METHODS, SOCIAL_PROVIDER_IDS, Socials, getPlainName, } from '@/services/auth';
import { mapState } from 'vuex';
import locallyStored from '@lumiere/shared/utils/locallyStored';
import { isMobile } from '@lumiere/shared/utils/helpers';
import { AuthLinkType } from '@lumiere/db/types';
const auth = FirebaseAuth();
const socialAuthProviderColors = {
    GOOGLE: 'red',
    FACEBOOK: '#4267B2',
    TWITTER: 'blue',
    DEFAULT: 'primary',
};
const locallyStoredPendingCred = locallyStored('PENDING_CRED');
const locallyStoredSocialAuthLoginAttr = locallyStored('SOCIAL_AUTH');
export default Vue.extend({
    name: 'LinkSocialAccounts',
    props: {
        storedPendingCred: {
            type: Object,
        },
        authLinkType: {
            type: String,
            default: AuthLinkType.SIGN_IN_AND_LINK,
        },
    },
    data() {
        return {
            alertDialog: false,
            alertDialogMessage: '',
            whichSocial: Socials.GOOGLE,
            authDialog: false,
            authDialogMessage: '',
            provider: null,
            pendingCred: null,
            linkingSocialProviderAccount: false,
            socialAuthLoginAttr: null,
        };
    },
    methods: {
        async socialAuthHandler(provider, pendingCred) {
            const socialAuthLoginAttr = {
                providerId: provider.providerId,
                isRedirect: isMobile(),
                isPopup: !isMobile(),
            };
            locallyStoredSocialAuthLoginAttr.set({ ...socialAuthLoginAttr });
            // this is where we decide which flow to use.
            // N.B: You can remove popup flow and still get similar result
            if (this.authLinkType === AuthLinkType.SIGN_IN_AND_LINK) {
                if (isMobile()) {
                    await this.signInWithRedirectHandler(provider, pendingCred);
                }
                else {
                    await this.signInWithPopupHandler(provider, pendingCred);
                }
            }
            else {
                const user = await customAuth.getCurrentUser();
                if (user) {
                    if (isMobile()) {
                        await user.linkWithRedirect(provider);
                    }
                    else {
                        await user
                            .linkWithPopup(provider)
                            .then(({ user, credential }) => {
                            this.$emit('linking-success', { user, credential });
                        })
                            .catch(async (e) => await this.handleErrors(e));
                    }
                }
            }
        },
        async signInWithRedirectHandler(provider, pendingCred) {
            if (pendingCred) {
                locallyStoredPendingCred.set({ ...pendingCred });
            }
            await auth.signInWithRedirect(provider);
        },
        async signInWithPopupHandler(provider, pendingCred) {
            await auth
                .signInWithPopup(provider)
                .then(async (result) => {
                if (pendingCred) {
                    await result.user
                        ?.linkAndRetrieveDataWithCredential(pendingCred)
                        .then(() => this.$emit('linking-success'))
                        .catch((e) => {
                        throw e;
                    });
                }
                this.$emit('login-success');
            })
                .catch(async (e) => await this.handleErrors(e));
        },
        async googleAuth(pendingCred) {
            this.whichSocial = Socials.GOOGLE;
            const provider = new FirebaseAuth.GoogleAuthProvider();
            auth.useDeviceLanguage();
            provider.setCustomParameters({
                login_hint: 'user@latd.com',
            });
            await this.socialAuthHandler(provider, pendingCred);
        },
        async facebookAuth(pendingCred) {
            this.whichSocial = Socials.FACEBOOK;
            const provider = new FirebaseAuth.FacebookAuthProvider();
            auth.useDeviceLanguage();
            provider.setCustomParameters({
                display: 'popup',
                auth_type: 'rerequest',
            });
            await this.socialAuthHandler(provider, pendingCred);
        },
        async twitterAuth(pendingCred) {
            this.whichSocial = Socials.TWITTER;
            const provider = new FirebaseAuth.TwitterAuthProvider();
            auth.useDeviceLanguage();
            provider.setCustomParameters({
                lang: 'en',
            });
            await this.socialAuthHandler(provider, pendingCred);
        },
        /**
         * This is called if the user had been previously authenticated with social auth.
         */
        async performAccountLinking() {
            const provider = this.provider;
            const pendingCred = this.pendingCred;
            if (provider && pendingCred) {
                this.linkingSocialProviderAccount = true;
                this.authDialogMessage = 'Linking your social accounts';
                switch (provider) {
                    case SOCIAL_PROVIDER_IDS.GOOGLE_PROVIDER_ID:
                        await this.googleAuth(pendingCred);
                        this.whichSocial = Socials.GOOGLE;
                        break;
                    case SOCIAL_PROVIDER_IDS.FACEBOOK_PROVIDER_ID:
                        await this.facebookAuth(pendingCred);
                        this.whichSocial = Socials.FACEBOOK;
                        break;
                    case SOCIAL_PROVIDER_IDS.TWITTER_PROVIDER_ID:
                        await this.twitterAuth(pendingCred);
                        this.whichSocial = Socials.TWITTER;
                        break;
                    default:
                        break;
                }
            }
            this.linkingSocialProviderAccount = false;
            this.authDialog = false;
            this.provider = null;
            this.pendingCred = null;
        },
        async handleErrors(error) {
            // console.log(error)
            const errorCode = error.code;
            if (errorCode === 'auth/account-exists-with-different-credential') {
                // error.email: The provider account's email address.
                // error.credential=pendingCred: The provider account's credential
                await this.checkProviderAndInitiateAccountLinking(error.email, error.credential);
            }
            else if (errorCode === 'auth/user-disabled') {
                if (this.socialAuthLoginAttr) {
                    this.socialAuthLoginAttr = {
                        ...this.socialAuthLoginAttr,
                        userIsDisabled: true,
                    };
                }
                this.alertDialogMessage =
                    'Sorry, Lumiere is not currently opened to the general public.';
                this.alertDialog = true;
            }
            else {
                throw error; // notify user: social auth cancelled
            }
        },
        async checkProviderAndInitiateAccountLinking(email, pendingCred) {
            if (email && pendingCred) {
                await auth.fetchSignInMethodsForEmail(email).then((methods) => {
                    // if not a social signinMethod, use emailAuthProviders
                    if (!SOCIAL_SIGN_IN_METHODS.includes(methods[0])) {
                        let method = methods[0] === 'password' ? 'password' : 'emailLink';
                        this.$emit('link-accounts', {
                            method,
                            pendingCred,
                            email,
                        });
                    }
                    else {
                        // call the appropriate social auth
                        let provider = methods[0];
                        // notify the users that (s)he already has an account but with a different provider
                        this.authDialogMessage = `A ${getPlainName(provider)} login is already linked to this account. Would you add ${getPlainName(pendingCred.providerId)} too?`;
                        this.authDialog = true;
                        this.provider = provider;
                        this.pendingCred = pendingCred;
                    }
                });
            }
        },
        async handleRedirectResult() {
            this.socialAuthLoginAttr = (await locallyStoredSocialAuthLoginAttr.get());
            if (this.socialAuthLoginAttr?.isRedirect) {
                this.alertDialogMessage = 'Signing in...';
                this.alertDialog = true;
                this.whichSocial = getPlainName(this.socialAuthLoginAttr.providerId);
            }
            const pendingCred = this.storedPendingCred;
            await auth
                .getRedirectResult()
                .then(async ({ user, credential }) => {
                if (user) {
                    if (this.authLinkType === AuthLinkType.BASIC_LINKING) {
                        this.$emit('linking-success', { user, credential });
                    }
                    else {
                        if (pendingCred) {
                            await user
                                ?.linkAndRetrieveDataWithCredential(pendingCred)
                                .then(() => this.$emit('linking-success'))
                                .catch((e) => {
                                throw e;
                            })
                                .finally(() => locallyStoredPendingCred.remove());
                        }
                    }
                    this.$emit('login-success');
                }
            })
                .catch(async (e) => {
                await this.handleErrors(e);
            })
                .finally(() => {
                const closeAlertDialogConditions = [
                    this.socialAuthLoginAttr?.userIsDisabled,
                ];
                if (!closeAlertDialogConditions.includes(true)) {
                    this.alertDialogMessage = '';
                    this.alertDialog = false;
                }
                this.socialAuthLoginAttr = null;
                locallyStoredSocialAuthLoginAttr.remove();
            });
        },
    },
    computed: {
        ...mapState(['socialAuth']),
        socials() {
            return Socials;
        },
        color() {
            switch (this.whichSocial) {
                case Socials.GOOGLE:
                    return socialAuthProviderColors.GOOGLE;
                case Socials.FACEBOOK:
                    return socialAuthProviderColors.FACEBOOK;
                case Socials.TWITTER:
                    return socialAuthProviderColors.TWITTER;
                default:
                    return socialAuthProviderColors.DEFAULT;
            }
        },
        isRedirectLogin() {
            return this.socialAuthLoginAttr?.isRedirect ?? false;
        },
    },
    watch: {
        socialAuth: {
            handler(data) {
                const { loading, error, errorMessage } = data;
                if (loading) {
                    this.alertDialog = loading;
                    this.alertDialogMessage =
                        'Hold tight while we verify your social account';
                }
                if (error) {
                    this.alertDialog = true;
                    this.alertDialogMessage = errorMessage;
                }
            },
            immediate: true,
            deep: true,
        },
    },
    mounted() {
        this.handleRedirectResult();
    },
});
