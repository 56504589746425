import Vue from 'vue';
import { nanoid } from 'nanoid';
import DraggableList from '@/components/common/DraggableList.vue';
export default Vue.extend({
    name: 'ChoiceQuestionDraggableOptionsList',
    props: {
        value: {
            type: Array,
        },
        variant: String,
    },
    data() {
        return {
            items: [...this.$props.value],
            newItem: '',
        };
    },
    methods: {
        addItem() {
            if (!this.newItem.length) {
                return;
            }
            this.items.push({
                label: this.newItem,
                id: nanoid(10),
                isActive: true,
                plus: null,
            });
            this.newItem = '';
        },
        onRefreshItems(newItems) {
            this.items = newItems;
        },
    },
    // watch items change for localStorage persistence
    watch: {
        items: {
            handler(items) {
                this.$emit('input', items);
            },
            deep: true,
        },
    },
    components: {
        DraggableList,
    },
});
