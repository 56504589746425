import { render, staticRenderFns } from "./NotFound.vue?vue&type=template&id=6dd4db31&"
var script = {}


/* normalize component */
import normalizer from "!../../../../../node_modules/.pnpm/vue-loader@15.9.7_fb85a9817f99bdf862e5e3a442a1fb91/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports