import Vue from 'vue';
import { FeatureType } from '@lumiere/db/types';
import featureDefaults from './featureDefaults';
export default Vue.extend({
    name: 'FeatureTypeSelect',
    props: {
        value: String,
    },
    data() {
        return {};
    },
    computed: {
        selectableFeatureType() {
            const { Retention, ...OtherFeatureTypes } = FeatureType;
            return OtherFeatureTypes;
        },
        featureDefaults: () => featureDefaults,
    },
});
