import Vue from 'vue';
import { AssetType } from '@lumiere/db/types';
import adminAPI from '@/services/adminAPI';
export default Vue.extend({
    name: 'VideoRangeSelectionPreview',
    props: {
        video: Object,
        timeLabel: {
            type: String,
            default: 'Show time',
        },
        time: Number,
        showTime: {
            type: Boolean,
            default: true,
        },
    },
    watch: {
        time: {
            immediate: true,
            async handler(time) {
                this.thumbnail = await adminAPI(async (api) => api.video.getSignedURL({
                    vid: this.video.id,
                    assetType: AssetType.thumbnail,
                    params: {
                        time,
                    },
                }));
                this.liveThumbnail = await adminAPI(async (api) => api.video.getSignedURL({
                    vid: this.video.id,
                    assetType: AssetType.gif,
                    params: { start: time },
                }));
            },
        },
    },
    data() {
        return {
            animationLoaded: false,
            thumbnail: null,
            liveThumbnail: null,
        };
    },
});
