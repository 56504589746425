var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"upload-progress-cards pa-5 mx-auto"},[(_vm.hasFilesToUpload)?_c('v-card',{staticClass:"mx-auto",attrs:{"max-width":_vm.uploaderWidth,"width":_vm.uploaderWidth}},[_c('v-toolbar',{attrs:{"color":"white","elevation":1}},[(_vm.allUploadIsCompleted)?_c('v-toolbar-title',[_vm._v(" "+_vm._s(_vm.fileCount)+" "+_vm._s(_vm.fileCount > 1 ? 'uploads' : 'upload')+" completed ")]):_c('v-toolbar-title',[_vm._v(" Uploading "+_vm._s(_vm.fileCount > 1 ? 'videos...' : 'a video...')+" ")]),_c('v-spacer'),(_vm.allUploadIsCompleted)?_c('v-btn',{attrs:{"icon":""}},[_c('v-icon',[_vm._v("mdi-checkbox-marked-circle")])],1):_vm._e()],1),_c('v-list',{attrs:{"two-line":"","dense":""}},[_c('div',{staticClass:"scrollable"},[_c('v-list-item-group',{attrs:{"multiple":""}},[_vm._l((_vm.fileUploads),function(ref,_id,index){
var id = ref.id;
var name = ref.name;
var status = ref.status;
var progress = ref.progress;
var workspaceId = ref.workspaceId;
var message = ref.message;
return [_c('v-list-item',{key:id,attrs:{"ripple":false},on:{"mouseenter":function($event){_vm.hoveredItem = _id},"mouseleave":function($event){_vm.hoveredItem = null}}},[[_c('v-list-item-content',[_c('v-list-item-title',[_c('label',{staticClass:"overline"},[_vm._v(_vm._s(status))])]),_c('v-list-item-subtitle',{staticClass:"text--primary"},[_c('label',{staticClass:"body-2 text-truncate"},[_vm._v(" "+_vm._s(name)+" ")])]),(message)?_c('v-list-item-subtitle',{staticClass:"red--text text-truncate"},[_vm._v(" "+_vm._s(_vm._f("sanitizeMessage")(message))+" ")]):_vm._e()],1),_c('v-list-item-icon',[(status === _vm.fileUploadStatus.COMPLETED)?_c('v-btn',{attrs:{"icon":""}},[_c('v-icon',[_vm._v("mdi-checkbox-marked-circle")])],1):(
                      ![
                        _vm.fileUploadStatus.CANCELED,
                        _vm.fileUploadStatus.ERROR ].includes(status)
                    )?_c('v-progress-circular',{attrs:{"color":_vm.getWorkspaceColor(workspaceId),"indeterminate":progress === undefined &&
                      status === _vm.fileUploadStatus.UPLOADING,"value":status === _vm.fileUploadStatus.QUEUED
                        ? 0
                        : status === _vm.fileUploadStatus.UPLOADING
                        ? progress
                        : undefined,"rounded":"","size":33}},[_c('v-btn',{directives:[{name:"show",rawName:"v-show",value:(_vm.hoveredItem === _id),expression:"hoveredItem === _id"}],key:_id,attrs:{"icon":"","small":"","title":"Cancel Upload"},on:{"click":function($event){return _vm.cancelUpload(id)}}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1):(status === _vm.fileUploadStatus.ERROR)?_c('v-btn',{key:_id,attrs:{"icon":"","small":"","title":"Remove failed upload"},on:{"click":function($event){return _vm.cancelUpload(id)}}},[_c('v-icon',[_vm._v("mdi-close")])],1):_vm._e()],1)]],2),(
                index + 1 < _vm.fileCount ||
                (index > 0 && status === _vm.fileUploadStatus.ERROR)
              )?_c('v-divider',{key:_id + name}):_vm._e()]})],2)],1)])],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }