import Vue from 'vue';
import { timestampToDate } from '@/utils/helpers';
import { getVideoThumbnail } from '@lumiere/shared/utils/getVideoThumbnail';
export default Vue.extend({
    name: 'RecentlyDeletedVideosList',
    props: {
        deletedVideos: {
            type: Array,
            required: true,
        },
    },
    data() {
        return {
            selectedVideos: [],
        };
    },
    computed: {
        deletedVideosWithImgSrc() {
            return this.deletedVideos.map((video) => {
                video.imgSrc = getVideoThumbnail(video);
                return video;
            });
        },
    },
    methods: {
        timestampToDate(ts) {
            return timestampToDate(ts);
        },
    },
    watch: {
        selectedVideos(videos) {
            this.$emit('selectedVideos', videos);
        },
    },
});
