import Vue from 'vue';
import LinkSocialAccounts from './LinkSocialAccounts.vue';
export default Vue.extend({
    name: 'LoginWithSocialAccount',
    props: {
        storedPendingCred: {
            type: Object,
        },
    },
    components: {
        LinkSocialAccounts,
    },
});
