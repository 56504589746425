import Vue from 'vue';
import ACTIONS from '@/store/ACTIONS';
import { mapGetters } from 'vuex';
import MUTATIONS from '@/store/MUTATIONS';
export default Vue.extend({
    name: 'Share',
    props: {
        workspaceId: String,
    },
    mounted() {
        window.addEventListener('beforeunload', () => {
            if (!this.userRoleInWorkspace) {
                this.cleanUp();
            }
        });
    },
    computed: {
        ...mapGetters(['currentWorkspace', 'userRoleInWorkspace']),
    },
    methods: {
        hydrateWorkspace(wid) {
            this.$store.dispatch(ACTIONS.GET_SHARE_LINK_WORKSPACE, {
                workspaceId: wid,
            });
        },
        cleanUp() {
            const { workspaces } = this.$store.state;
            this.$store.dispatch(ACTIONS.DELETE_RECENT_WORKSPACE, this.workspaceId);
            delete workspaces[this.workspaceId];
            this.$store.commit(MUTATIONS.SET_WORKSPACES, {
                ...workspaces,
            });
            this.$store.dispatch(ACTIONS.SAVE_STATE);
        },
    },
    watch: {
        workspaceId: {
            handler(wid) {
                if (wid && !this.userRoleInWorkspace) {
                    this.hydrateWorkspace(wid);
                }
            },
            immediate: true,
        },
    },
    beforeRouteLeave(_to, _from, next) {
        next();
        if (!this.userRoleInWorkspace) {
            this.cleanUp();
        }
    },
});
