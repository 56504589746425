import Vue from 'vue';
import { mapGetters } from 'vuex';
import { pluck, switchMap, shareReplay, startWith } from 'rxjs/operators';
import { ChannelStatus } from '@lumiere/db/types';
import ChannelCard from '@/components/workspace/channels/ChannelCard.vue';
import CreateNewChannelBtn from '@/components/workspace/channels/CreateNewChannelBtn.vue';
import { ChannelRoutes } from '@/router/router';
import auth from '@/services/auth';
export default Vue.extend({
    name: 'Channels',
    props: {
        workspaceId: { type: String, required: true },
    },
    data() {
        return {
            deletedChannels: [],
        };
    },
    computed: {
        ...mapGetters(['currentWorkspace']),
        routeLinkType() {
            return this.$ability.can('read', 'Channel') ? 'to' : 'href';
        },
        channelURL() {
            return (channel) => this.$ability.can('read', 'Channel')
                ? {
                    name: ChannelRoutes.Default,
                    params: {
                        channelId: channel.id,
                        channelCache: channel,
                    },
                }
                : this.$store.getters.channelURL(channel.id);
        },
    },
    methods: {},
    subscriptions() {
        const workspaceId$ = this.$watchAsObservable('workspaceId', {
            immediate: true,
        }).pipe(pluck('newValue'));
        const channels$ = workspaceId$.pipe(switchMap((workspaceId) => this.$db
            .channels((ref) => {
            return ref
                .where('status', '!=', ChannelStatus.Deleted)
                .where('workspaceId', '==', workspaceId)
                .orderBy('status', 'asc')
                .orderBy('createdTime', 'desc');
        })
            .observe()), shareReplay(1));
        const deletedChannels$ = workspaceId$.pipe(switchMap((workspaceId) => this.$db
            .channels((ref) => {
            return ref
                .where('workspaceId', '==', workspaceId)
                .where('status', '==', ChannelStatus.Deleted)
                .orderBy('deletedTime', 'desc');
        })
            .observe()
            .pipe(startWith([]))), shareReplay(1));
        return {
            channels: channels$.pipe(startWith(null)),
            deletedChannels: deletedChannels$,
        };
    },
    beforeRouteEnter(_to, _from, next) {
        next(async () => {
            const user = await auth.getCurrentUser();
            if (user?.isAnonymous) {
                // await vm.$auth.signOut()
                next('/');
            }
            else {
                next();
            }
        });
    },
    components: {
        ChannelCard,
        CreateNewChannelBtn,
    },
});
