import Vue from 'vue';
import '@/assets/vendor/webgl-heatmap';
export default Vue.extend({
    name: 'VideoInsightSentimentTimelineMarker',
    props: {
        commentData: {
            type: Object,
        },
    },
    data() {
        return {
            marker: null,
        };
    },
    mounted() {
        try {
            const markerRef = this.$refs.marker;
            // @ts-ignore
            markerRef.globalAlpha = 0.75;
            const marker = window.createWebGLHeatmap({
                canvas: this.$refs.marker,
            });
            if (marker) {
                marker.addPoint(0, 0, 0);
                marker.update();
                marker.display();
                marker.blur();
            }
            this.marker = marker;
            this.drawMarker(this.commentData);
        }
        catch (error) {
            // handle the error
            console.log(error);
        }
    },
    methods: {
        drawMarker({ payload }) {
            if (payload && 'locationX' in payload && 'locationY' in payload) {
                const { locationX, locationY } = payload;
                // console.log(locationX, locationY, this.marker)
                if (locationX && locationY && this.marker) {
                    this.marker.multiply(0);
                    const { width, height } = this.marker;
                    const size = 80;
                    const intensity = size / 100;
                    let xPos = locationX * width;
                    let yPos = (1 - locationY) * height;
                    // setup dataset for heatmap showing comments near current time
                    this.marker.addPoint(xPos, yPos, size, intensity);
                    // this.marker.multiply(0.85)
                    this.marker.update();
                    this.marker.display();
                }
            }
        },
    },
});
