import Vue from 'vue';
export var VideoMode;
(function (VideoMode) {
    VideoMode["VIDEO"] = "Video Only Mode";
    VideoMode["OUTPUT"] = "On-Video Visualization Mode";
    VideoMode["VIEWER_EXPERIENCE"] = "Viewer Experience Mode";
})(VideoMode || (VideoMode = {}));
const icons = {
    [VideoMode.VIDEO]: 'mdi-eye',
    [VideoMode.OUTPUT]: 'mdi-chart-bubble',
    [VideoMode.VIEWER_EXPERIENCE]: 'mdi-account',
};
export default Vue.extend({
    name: 'VideoModeButtonToggle',
    props: {
        value: {
            type: String,
        },
        showOutputOption: Boolean,
    },
    data() {
        return {
            videoMode: this.value,
        };
    },
    computed: {
        VideoMode() {
            return VideoMode;
        },
        availableModes() {
            return this.showOutputOption
                ? [VideoMode.VIDEO, VideoMode.VIEWER_EXPERIENCE, VideoMode.OUTPUT]
                : [VideoMode.VIDEO, VideoMode.VIEWER_EXPERIENCE];
        },
    },
    methods: {
        icon(mode) {
            return icons[mode];
        },
    },
    watch: {
        showOutputOption: {
            handler(showOutputOption) {
                if (showOutputOption) {
                    this.videoMode = VideoMode.OUTPUT;
                }
                else {
                    this.videoMode = VideoMode.VIEWER_EXPERIENCE;
                }
            },
            immediate: true,
        },
        value(mode) {
            if (mode !== this.videoMode) {
                this.videoMode = mode;
            }
        },
        videoMode: {
            handler(mode) {
                this.$emit('input', mode);
            },
            immediate: true,
        },
    },
});
