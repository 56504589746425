var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"pa-4",staticStyle:{"overflow":"hidden"},attrs:{"elevation":"7"}},[(_vm.success)?_c('div',[_c('v-card-title',{staticClass:"justify-center py-6"},[_vm._v(" Login Successful 🎉 ")])],1):(!_vm.emailConfirmed && !_vm.signInMethod)?_c('LoginWithAccount',{attrs:{"submitEmail":function () { return _vm.submitEmail(); },"submitEmailError":_vm.submitEmailError,"loading":_vm.loading,"computedEmailErrors":_vm.computedEmailErrors,"storedPendingCred":_vm.pendingCred,"relayedErrorMessage":_vm.relayedErrorMessage},on:{"link-accounts":_vm.onLinkAccounts,"login-success":_vm.finalizeSignIn,"clear-errors":function () {
        _vm.submitEmailError = ''
      }},model:{value:(_vm.email),callback:function ($$v) {_vm.email=$$v},expression:"email"}}):_c('div',[_c('v-card-title',{staticClass:"justify-center py-2"},[(!(_vm.user && _vm.user.displayName))?_c('span',[_vm._v("Hi!")]):_vm._e()]),_c('v-card-text',{staticClass:"text-center"},[_c('v-chip',{staticClass:"ma-0",attrs:{"outlined":"","close":""},on:{"click:close":_vm.disconfirmEmail}},[_c('v-avatar',{attrs:{"left":""}},[_c('v-icon',[_vm._v("mdi-account-circle")])],1),_c('span',[_vm._v(_vm._s(_vm.email))])],1)],1),(_vm.pendingCred && !_vm.signInLinkSent)?_c('v-card-text',{staticClass:"text-center grey--text"},[_vm._v(" To finish linking "+_vm._s(_vm._f("plainName")((_vm.pendingCred.providerId || 'social')))+" authentication to your account, please verify your identity by logging in below. ")]):_vm._e(),(_vm.signInMethod === _vm.authSignInMethods.password)?_c('LoginWithPasswordForm',{attrs:{"signInWithPassword":function () { return _vm.signInWithPassword(); },"passwordErrors":_vm.passwordErrors,"loading":_vm.loading,"user":_vm.user},on:{"password-valid":function (val) {
          _vm.passwordValid = val
        },"signin-with-link":function (ref) {
              var sign_in_method = ref.sign_in_method;

              return (_vm.signInMethod = sign_in_method);
},"reset-password":function (data) { return (_vm.loadingMessage = data.loadingMessage); },"reset-password-complete":function () {
          _vm.loadingMessage = ''
          _vm.disconfirmEmail()
        }},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}}):(
        _vm.emailConfirmed && _vm.signInMethod === _vm.authSignInMethods.emailLink
      )?_c('LoginWithLinkForm',{attrs:{"signInLinkSent":_vm.signInLinkSent,"sendSignInLink":function () { return _vm.sendSignInLink(); },"loading":_vm.loading,"email":_vm.email}}):(_vm.isSocialSignIn)?_c('LoginWithSocialAccount',{on:{"link-accounts":_vm.onLinkAccounts,"login-success":_vm.finalizeSignIn},scopedSlots:_vm._u([{key:"divider-content",fn:function(){return [_c('span')]},proxy:true},{key:"title",fn:function(){return [_c('p',{staticStyle:{"color":"var(--lumiere-variant-m)"}},[_vm._v(" Continue with social login "),_c('span',{staticStyle:{"display":"block","color":"grey"}},[_c('small',[_vm._v("Your last login was "+_vm._s(_vm.signInMethod))])])])]},proxy:true}])}):_vm._e()],1),_c('v-overlay',{attrs:{"value":_vm.loading,"color":"white","absolute":"","opacity":".7"}},[_c('div',{staticClass:"text-center"},[_c('v-progress-circular',{attrs:{"indeterminate":"","color":"var(--lumiere-blue)","size":"48"}}),_c('div',{class:['mt-10 font-weight-medium', "primary--text"]},[_vm._v(" "+_vm._s(_vm.loadingMessage)+" ")])],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }