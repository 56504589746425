import Vue from 'vue';
import Draggable from 'vuedraggable';
import EditableLabel from './EditableLabel.vue';
export default Vue.extend({
    name: 'DraggableList',
    props: {
        items: {
            type: Array,
        },
        isEditable: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            drag: false,
            localItems: this.items,
        };
    },
    methods: {
        removeItemAt(index) {
            const localItems = this.localItems;
            localItems.splice(index, 1);
            this.localItems = localItems;
            this.$emit('refresh-items', localItems);
        },
        onDragEnd() {
            this.drag = false;
            this.$emit('refresh-items', this.localItems);
        },
    },
    computed: {
        dragOptions() {
            return {
                animation: 200,
                group: 'description',
                disabled: false,
                ghostClass: 'ghost',
            };
        },
    },
    // watch items change for localStorage persistence
    watch: {
        items: {
            handler: function (items) {
                if (items?.length) {
                    this.localItems = items;
                }
            },
            deep: true,
        },
    },
    components: {
        Draggable,
        EditableLabel,
    },
});
