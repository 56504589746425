import { render, staticRenderFns } from "./VideoBranding.vue?vue&type=template&id=1fb31a90&scoped=true&"
import script from "./VideoBranding.vue?vue&type=script&lang=ts&"
export * from "./VideoBranding.vue?vue&type=script&lang=ts&"
import style0 from "./VideoBranding.vue?vue&type=style&index=0&id=1fb31a90&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/.pnpm/vue-loader@15.9.7_fb85a9817f99bdf862e5e3a442a1fb91/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1fb31a90",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../../../node_modules/.pnpm/vuetify-loader@1.7.2_c225a462abeaf467a2189c5bd12e241a/node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSlider } from 'vuetify/lib/components/VSlider';
import { VSwitch } from 'vuetify/lib/components/VSwitch';
installComponents(component, {VBtn,VIcon,VSlider,VSwitch})
