import Vue from 'vue';
import { TeamRole } from '@lumiere/db/types';
import emailIsValid from '@lumiere/shared/utils/emailIsValid';
import adminAPI from '@/services/adminAPI';
import logger from '@lumiere/shared/services/logger';
import { pluck, debounceTime, switchMap, shareReplay, startWith, map, } from 'rxjs/operators';
import { nanoid } from 'nanoid';
import AddMemberForm from '@/components/common/AddMemberForm.vue';
const nonUser = () => ({
    displayName: '',
    id: nanoid(10),
    isUser: false,
    role: null,
});
export default Vue.extend({
    name: 'AddTeamMemberForm',
    props: {
        team: Object,
    },
    data() {
        return {
            errors: [],
            loading: false,
            email: '',
            role: TeamRole.EDITOR,
            searchList: [],
        };
    },
    computed: {
        roles() {
            return Object.values(TeamRole);
        },
        themeColor() {
            return 'var(--lumiere-variant-m)';
        },
    },
    methods: {
        close() {
            this.$emit('close');
        },
        async addMembers(tid, members) {
            logger.log('addMembers', { tid, members });
            await adminAPI((api) => api.team.addTeamMembers({
                origin: location.origin,
                tid,
                members,
            })).then(async (result) => {
                const ctx = await this.$auditLogger(this.$store.state.uid);
                if (ctx) {
                    const { Asset_Model, EVENT_ACTION, ts } = ctx.config;
                    const asset = {
                        id: tid,
                        type: Asset_Model.Team,
                        name: this.team.name,
                    };
                    await ctx.exec(asset, {
                        timestamp: ts(),
                        action: EVENT_ACTION.MEMBERS_ADDED,
                        data: {
                            type: 'INFO',
                            payload: {
                                message: 'New members have been added to the team',
                                actorAssets: {
                                    type: Asset_Model.User,
                                    ids: result,
                                },
                            },
                        },
                    });
                }
            });
        },
        async submitForm(selectedUsers) {
            this.errors = [];
            try {
                this.loading = true;
                const members = Object.values(selectedUsers).map((m) => ({
                    email: m.email,
                    displayName: m.displayName || '',
                    role: m.role,
                }));
                await this.addMembers(this.team.id, members);
                logger.log('add member success');
                this.close();
            }
            catch (error) {
                this.errors = [error];
            }
            this.loading = false;
        },
    },
    subscriptions() {
        const team = this.$props.team;
        const email$ = this.$watchAsObservable('email', {
            immediate: true,
            deep: true,
        }).pipe(pluck('newValue'));
        return {
            searchList: email$.pipe(debounceTime(500), switchMap(async (email) => this.$db
                .users((q) => q.where('email', '==', (email ?? '')?.toLowerCase()))
                .get()), map((users) => users.length
                ? users.map((user) => ({
                    ...user,
                    isUser: true,
                    role: team.roles[user.id] || null,
                }))
                : emailIsValid(this.$data.email?.toLowerCase())
                    ? [{ email: this.$data.email.toLowerCase(), ...nonUser() }]
                    : []), shareReplay(1), startWith([])),
        };
    },
    components: {
        AddMemberForm,
    },
});
