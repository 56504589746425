import adminAPI from '@/services/adminAPI';
import { AssetType } from '@lumiere/db/types';
import Vue from 'vue';
const loadedThumbnails = {};
export default Vue.extend({
    name: 'VideoThumbnail',
    props: {
        vid: String,
        time: Number,
    },
    data() {
        return {
            thumbnail: null,
        };
    },
    computed: {
        isLoaded() {
            return !!this.thumbnail;
        },
    },
    watch: {
        vid: {
            immediate: true,
            handler(vid) {
                if (!loadedThumbnails[vid]) {
                    loadedThumbnails[vid] = {};
                }
            },
        },
        time: {
            immediate: true,
            handler() {
                this.loadURL();
            },
        },
    },
    methods: {
        async loadURL() {
            var _a;
            const { time, vid } = this;
            this.thumbnail = (_a = loadedThumbnails[vid])[time] || (_a[time] = await adminAPI(async (api) => api.video.getSignedURL({
                vid: this.vid,
                assetType: AssetType.thumbnail,
                params: { time: this.time },
            })));
        },
    },
    components: {},
});
