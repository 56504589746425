var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.currentWorkspace)?_c('Layout',{attrs:{"color":_vm.themeColorHex,"id":("workspace-" + _vm.workspaceId)},scopedSlots:_vm._u([(!_vm.$vuetify.breakpoint.smAndDown && _vm.shouldShowNavbar)?{key:"navbar",fn:function(){return [_c('WorkspaceSelect',{staticStyle:{"max-width":"300px"},attrs:{"dark":"","filled":"","workspaceId":_vm.currentWorkspaceID,"workspacesSelectDialogSelectedWorkspaceId":_vm.workspacesSelectDialogSelectedWorkspaceId},on:{"show-workspaces":_vm.showWorkspacesListDialog,"add-workspace":_vm.showAddWorkspaceDialog}}),_c('div',{staticClass:"ml-4"},_vm._l((_vm.content),function(ref){
var route = ref.route;
var label = ref.label;
return _c('v-btn',{key:route,staticClass:"nav-item pa-2",attrs:{"rounded":"","text":"","small":"","to":{ name: route },"active-class":"nav-item--active"}},[_vm._v(" "+_vm._s(label)+" ")])}),1),_c('v-spacer')]},proxy:true}:(_vm.isPublic)?{key:"navbar",fn:function(){return [_c('v-spacer')]},proxy:true}:null,(_vm.$vuetify.breakpoint.smAndDown && _vm.shouldShowNavbar)?{key:"navbar-menu",fn:function(){return [_c('v-app-bar-nav-icon',{attrs:{"dark":"","large":""},on:{"click":function($event){_vm.showNavDrawer = !_vm.showNavDrawer}}})]},proxy:true}:null,{key:"container",fn:function(){return [_c('DropZone',[_c('router-view',{key:_vm.$route.params.videoId || _vm.$route.params.channelId})],1)]},proxy:true}],null,true)},[_c('style',{tag:"component"},[_vm._v(" :root { --workspace-color: "+_vm._s(_vm.themeColorAm4Core)+" } ")]),(_vm.shouldLockShareLink)?_c('ShareLinkPasswordBlocker',{attrs:{"link":_vm.link}}):_vm._e(),_c('UploadProgressCards'),_c('v-dialog',{staticClass:"px-8",staticStyle:{"padding-left":"20px"},attrs:{"max-width":"750","fullscreen":_vm.$vuetify.breakpoint.smAndDown},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var value = ref.value;
return [(value)?_c('WorkspacesSelectDialog',{on:{"close":function($event){_vm.listWorkspacesDialog = false},"create-workspace":_vm.showAddWorkspaceDialog,"selected-workspace":function (wid) { return (_vm.workspacesSelectDialogSelectedWorkspaceId = wid); }}}):_vm._e()]}}],null,false,2115243009),model:{value:(_vm.listWorkspacesDialog),callback:function ($$v) {_vm.listWorkspacesDialog=$$v},expression:"listWorkspacesDialog"}}),_c('v-dialog',{attrs:{"max-width":"700","fullscreen":_vm.$vuetify.breakpoint.smAndDown},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var value = ref.value;
return [(value)?_c('CreateWorkspaceForm',{attrs:{"teamId":_vm.teamId},on:{"workspace-created":_vm.workspaceCreated,"close":function($event){_vm.addWorkspaceDialog = false}}}):_vm._e()]}}],null,false,153695466),model:{value:(_vm.addWorkspaceDialog),callback:function ($$v) {_vm.addWorkspaceDialog=$$v},expression:"addWorkspaceDialog"}}),_c('NavDrawer',{directives:[{name:"show",rawName:"v-show",value:(_vm.$vuetify.breakpoint.smAndDown && _vm.isAuthenticated),expression:"$vuetify.breakpoint.smAndDown && isAuthenticated"}],attrs:{"content":_vm.content},on:{"show-workspaces":_vm.showWorkspacesListDialog,"add-workspace":_vm.showAddWorkspaceDialog},model:{value:(_vm.showNavDrawer),callback:function ($$v) {_vm.showNavDrawer=$$v},expression:"showNavDrawer"}})],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }