export var FeatureType;
(function (FeatureType) {
    FeatureType["Retention"] = "Retention";
    FeatureType["Emojis"] = "Emojis";
    FeatureType["Comments"] = "Comments";
    FeatureType["TextPrompt"] = "TextPrompt";
    FeatureType["ExternalData"] = "ExternalData";
    FeatureType["Question"] = "Question";
    FeatureType["Tags"] = "Tags";
    FeatureType["DialTest"] = "DialTest";
    FeatureType["Instruction"] = "Instruction";
    FeatureType["SocialChat"] = "SocialChat";
    FeatureType["Timer"] = "Timer";
    FeatureType["Redirect"] = "Redirect";
})(FeatureType || (FeatureType = {}));
export var FeatureTriggerType;
(function (FeatureTriggerType) {
    FeatureTriggerType["VideoEvent"] = "VideoEvent";
    FeatureTriggerType["FeatureEvent"] = "FeatureEvent";
    FeatureTriggerType["ViewerBehavior"] = "ViewerBehavior";
})(FeatureTriggerType || (FeatureTriggerType = {}));
export var CornerPosition;
(function (CornerPosition) {
    CornerPosition["TopLeft"] = "TopLeft";
    CornerPosition["BottomLeft"] = "BottomLeft";
    CornerPosition["TopRight"] = "TopRight";
    CornerPosition["BottomRight"] = "BottomRight";
    CornerPosition["TopCenter"] = "TopCenter";
    CornerPosition["BottomCenter"] = "BottomCenter";
})(CornerPosition || (CornerPosition = {}));
export var VideoTriggerEvent;
(function (VideoTriggerEvent) {
    VideoTriggerEvent["VideoStart"] = "VideoStart";
    VideoTriggerEvent["VideoEnd"] = "VideoEnd";
    VideoTriggerEvent["VideoTime"] = "VideoTime";
})(VideoTriggerEvent || (VideoTriggerEvent = {}));
export * from './db.video.features-comments.types';
export * from './db.video.features-emojis.types';
export * from './db.video.features-externalData.types';
export * from './db.video.features-questions.types';
export * from './db.video.features-textPrompt.types';
export * from './db.video.features-retention.types';
