import Vue from 'vue';
import auth from '@/services/auth';
import logger from '@lumiere/shared/services/logger';
import { FirebaseAuth } from '@/services/firebase/auth';
export default Vue.extend({
    name: 'UserProfileEditEmail',
    props: {
        user: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            editEmailDialog: false,
            userDetails: {
                email: '',
                password: '',
            },
            loading: false,
            editEmailForm: false,
            revealPassword: false,
        };
    },
    computed: {
        disableSubmitBtn() {
            return !(this.userDetails.email && this.userDetails.password);
        },
    },
    methods: {
        async updateUserEmail() {
            this.loading = true;
            const { email } = this.user;
            const { email: newEmail, password } = this.userDetails;
            const user = await auth.getCurrentUser();
            if (user) {
                const credential = await FirebaseAuth.EmailAuthProvider.credential(email, password);
                await user
                    .reauthenticateWithCredential(credential)
                    .then(async (res) => {
                    logger.info('Credentials reauthentication successful. Proceeding to updating the email address', res);
                    await user
                        .updateEmail(newEmail)
                        .then((res) => {
                        logger.info('Update email address successful.', res);
                        this.editEmailDialog = false;
                    })
                        .catch(async (err) => {
                        logger.info('Something went wrong while updating the email address', err);
                        await this.$confirm(err.message ||
                            'Something went wrong while updating the email address, ensure you are specifying the correct email address', {
                            buttonTrueText: 'OK',
                            buttonFalseText: '',
                        });
                    });
                    this.userDetails = {
                        email: '',
                        password: '',
                    };
                })
                    .catch(async (err) => {
                    logger.info('Something went wrong while reauthenticating with credentials', err);
                    await this.$confirm(err.message ||
                        'Your current login credentials are wrong. Please try again with the right credentials', {
                        buttonTrueText: 'OK',
                        buttonFalseText: '',
                    });
                });
            }
            // this.userDetails = {
            //   email: '',
            //   password: '',
            // }
            this.loading = false;
            // this.editEmailDialog = false
        },
    },
});
