/** Combination of collections, subcollections and field records */
export var Asset_Model;
(function (Asset_Model) {
    Asset_Model["User"] = "User";
    Asset_Model["Team"] = "Team";
    Asset_Model["Workspace"] = "Workspace";
    Asset_Model["Video"] = "Video";
    Asset_Model["Channel"] = "Channel";
    Asset_Model["Workspace.Emoji"] = "Workspace.Emoji";
    Asset_Model["Video.Feature"] = "Video.Feature";
    Asset_Model["Video.Person"] = "Video.Person";
    Asset_Model["Video.Scene"] = "Video.Scene";
})(Asset_Model || (Asset_Model = {}));
export var EVENT_ACTION;
(function (EVENT_ACTION) {
    EVENT_ACTION["ADDED"] = "ADDED";
    EVENT_ACTION["ASSIGNED"] = "ASSIGNED";
    EVENT_ACTION["CREATED"] = "CREATED";
    EVENT_ACTION["UPDATED"] = "UPDATED";
    EVENT_ACTION["DELETED"] = "DELETED";
    EVENT_ACTION["SOFT_DELETED"] = "SOFT_DELETED";
    EVENT_ACTION["REMOVED"] = "REMOVED";
    EVENT_ACTION["READ"] = "READ";
    EVENT_ACTION["VIEWED"] = "VIEWED";
    EVENT_ACTION["INITIATED"] = "INITIATED";
    EVENT_ACTION["MERGED"] = "MERGED";
    EVENT_ACTION["UNMERGED"] = "UNMERGED";
    EVENT_ACTION["APPLIED"] = "APPLIED";
    EVENT_ACTION["UTILIZED"] = "UTILIZED";
    EVENT_ACTION["SELECTED"] = "SELECTED";
    EVENT_ACTION["LOGIN"] = "LOGIN";
    EVENT_ACTION["LOGOUT"] = "LOGOUT";
    EVENT_ACTION["VALIDATED"] = "VALIDATED";
    EVENT_ACTION["MEMBERS_ADDED"] = "MEMBERS_ADDED";
    EVENT_ACTION["MEMBERS_REMOVED"] = "MEMBERS_REMOVED";
    EVENT_ACTION["ROLE_CHANGED"] = "ROLE_CHANGED";
    EVENT_ACTION["RESTORED"] = "RESTORED";
    EVENT_ACTION["ERRORED"] = "ERRORED";
})(EVENT_ACTION || (EVENT_ACTION = {}));
export var ACTION_STATUS;
(function (ACTION_STATUS) {
    ACTION_STATUS["SUCCESS"] = "SUCCESS";
    ACTION_STATUS["COMPLETED"] = "COMPLETED";
    ACTION_STATUS["FAILED"] = "FAILED";
    ACTION_STATUS["CANCELED"] = "CANCELED";
    ACTION_STATUS["PROCESSING"] = "PROCESSING";
    ACTION_STATUS["PROCESSED"] = "PROCESSED";
    ACTION_STATUS["UPLOADING"] = "UPLOADING";
    ACTION_STATUS["UPLOADED"] = "UPLOADED";
    ACTION_STATUS["QUARANTINED"] = "QUARANTINED";
    ACTION_STATUS["IN_PROGRESS"] = "IN_PROGRESS";
})(ACTION_STATUS || (ACTION_STATUS = {}));
