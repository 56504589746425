import Vue from 'vue';
import VideoPlayerAdminTimePicker from '../player/VideoPlayerAdminTimePicker.vue';
import VideoRangeSelectionPreview from '@/components/common/VideoRangeSelectionPreview.vue';
import TimePickerComponent from '@/components/common/TimePickerComponent.vue';
import VideoSceneWizard from './VideoSceneWizard.vue';
import { nanoid } from 'nanoid';
export default Vue.extend({
    name: 'VideoSceneAddWizard',
    props: {
        video: Object,
    },
    data() {
        return {
            dialog: false,
            updating: false,
            feature: null,
            rangeMin: 0,
            rangeMax: this.$props.video.duration - 0.25,
            sceneTitle: '',
            selectedRange: [],
            rangeMinSafe: 0,
            rangeMaxSafe: 0,
            rangeMinPicker: 0,
            rangeMaxPicker: this.$props.video.duration - 0.25,
        };
    },
    computed: {
        rangeFromScrubber() {
            return `${this.rangeMin}:${this.rangeMax}`;
        },
        rangeFromPickers() {
            return `${this.rangeMinPicker}:${this.rangeMaxPicker}`;
        },
    },
    watch: {
        rangeFromScrubber: {
            handler(newVal, oldVal) {
                if (newVal != oldVal) {
                    const delta = this.rangeMax - this.rangeMin;
                    if (delta > 0) {
                        this.rangeMinSafe = this.rangeMin;
                        this.rangeMaxSafe = this.rangeMax;
                        this.rangeMinPicker = this.rangeMin;
                        this.rangeMaxPicker = this.rangeMax;
                    }
                }
            },
            immediate: true,
        },
        rangeFromPickers: {
            handler(newVal, oldVal) {
                if (newVal != oldVal) {
                    const delta = this.rangeMaxPicker - this.rangeMinPicker;
                    if (delta > 0) {
                        this.rangeMinSafe = this.rangeMinPicker;
                        this.rangeMaxSafe = this.rangeMaxPicker;
                        this.rangeMin = this.rangeMinPicker;
                        this.rangeMax = this.rangeMaxPicker;
                    }
                }
            },
            immediate: true,
        },
    },
    methods: {
        onSelectRange(range) {
            const { min, max } = range;
            this.rangeMin = +min;
            this.rangeMax = +max;
        },
        async addScene() {
            if (!this.sceneTitle) {
                // show title hint
                return;
            }
            const id = nanoid(8);
            this.updating = true;
            const payload = {
                name: this.sceneTitle,
                startTime: this.rangeMin,
                stopTime: this.rangeMax,
                nonce: Date.now(),
            };
            await this.$db
                .video(this.video.id)
                .update({ [`scenes.${id}`]: payload })
                .then(async () => {
                const ctx = await this.$auditLogger(this.$store.state.uid);
                if (ctx) {
                    const { Asset_Model, EVENT_ACTION, ts } = ctx.config;
                    const asset = {
                        id: this.video.id,
                        name: this.video.title,
                        type: Asset_Model.Video,
                    };
                    await ctx.exec(asset, {
                        timestamp: ts(),
                        action: EVENT_ACTION.CREATED,
                        data: {
                            type: 'INFO',
                            payload: {
                                message: `Video attributes changed: add scene`,
                                extraInfo: {
                                    scene: {
                                        id,
                                        ...payload,
                                    },
                                },
                            },
                        },
                    });
                }
            });
            this.updating = false;
            this.dialog = false;
            // should scene variable to defaults
            this.sceneTitle = '';
            this.rangeMin = 0;
            this.rangeMax = this.video.duration - 0.25;
        },
    },
    components: {
        VideoPlayerAdminTimePicker,
        VideoRangeSelectionPreview,
        TimePickerComponent,
        VideoSceneWizard,
    },
});
