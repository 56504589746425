import Vue from 'vue';
import anime from 'animejs';
export default Vue.extend({
    name: 'TextPromptFeedback',
    props: {
        position: {
            type: String,
            required: true,
        },
        feedbackOptions: {
            type: Object,
        },
        prompt: String,
        size: {
            type: String,
        },
    },
    data() {
        return {
            dismissPrompt: false,
        };
    },
    computed: {
        viewerDismissable() {
            return !!this.feedbackOptions?.viewerDismissable;
        },
    },
    methods: {
        onAppear(el, done) {
            // there's a known issue where animate overwrites transform properties
            // defined on an element via css. So this is a little hack to resolve that
            let JSTransform = {};
            if (this.position.toLowerCase().includes('center')) {
                JSTransform = {
                    translateX: ['0%', '-50%'],
                };
            }
            anime({
                targets: el,
                translateY: [150, 0],
                ...JSTransform,
                opacity: [0, 1],
                scale: [0, 1],
                duration: 1000,
                delay: 100,
                complete() {
                    done();
                },
            });
        },
        onLeave(el, done) {
            anime({
                targets: el,
                translateY: 150,
                scale: 0,
                opacity: 0,
                duration: 1000,
                complete() {
                    done();
                },
            });
        },
        dismiss() {
            if (this.viewerDismissable) {
                this.dismissPrompt = true;
            }
        },
    },
});
