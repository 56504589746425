import Vue from 'vue';
import { VideoAIJobStatus, VideoAIExternalStatus, } from '@lumiere/db/types';
import VideoIntelligenceDecodeVideo from './VideoIntelligenceDecodeVideo.vue';
import VideoIntelligenceViewResult from './VideoIntelligenceViewResult.vue';
export default Vue.extend({
    name: 'VideoIntelligence',
    props: {
        video: {
            type: Object,
            required: true,
        },
    },
    computed: {
        isDecodedVideoReady() {
            if (!this.video.videoAI?.state)
                return false;
            const { state, externalState } = this.video.videoAI;
            return (state === VideoAIJobStatus.PROCESSED &&
                externalState === VideoAIExternalStatus.INGESTED);
        },
    },
    watch: {
        'video.videoAI': {
            handler(videoAI) {
                if (videoAI?.errorMessage) {
                    // push to global snackbar
                    this.$events.emit('globalEvent', { message: videoAI?.errorMessage });
                }
            },
            immediate: true,
            deep: true,
        },
    },
    components: {
        VideoIntelligenceDecodeVideo,
        VideoIntelligenceViewResult,
    },
});
