var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{directives:[{name:"intersect",rawName:"v-intersect",value:({
    options: {
      root: null,
      threshold: [0, 0.5, 1],
    },
  }),expression:"{\n    options: {\n      root: null,\n      threshold: [0, 0.5, 1],\n    },\n  }"}],staticClass:"d-inline align-left"},[_c('v-tooltip',_vm._b({scopedSlots:_vm._u([{key:"activator",fn:function(ref){
  var on = ref.on;
return [_c('BaseEmoji',_vm._g({class:{ dimout: _vm.isPicked },attrs:{"title":"","id":_vm.id,"name":_vm.name,"downloadURL":_vm.downloadURL,"size":_vm.size}},on))]}}])},"v-tooltip",_vm._d({},[_vm.tooltip,true])),[_c('span',[_vm._v(_vm._s(_vm.name))])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }