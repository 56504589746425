import { Role } from '@lumiere/db/types';
/**
 *
 * @param user the user record: firebase.User
 * @param workspace the workspace: Workspace
 * @param _hasClaim a Function that resolves to boolean on whether a user has a claim
 */
export default function defineAbilityForViewer(uid, workspace, _hasClaim) {
    return (_can, _cannot) => {
        // user roles
        const userRoleInWorkspace = workspace.roles[uid];
        const isViewer = userRoleInWorkspace === Role.VIEWER;
        if (isViewer) {
            // define viewer abilities
            // can('read', 'Channel')
        }
    };
}
