import Vue from 'vue';
import auth from '@/services/auth';
import logger from '@lumiere/shared/services/logger';
import { FirebaseAuth } from '@/services/firebase/auth';
import { mapState } from 'vuex';
export default Vue.extend({
    name: 'UserProfileChangePassword',
    data() {
        return {
            changePasswordDialog: false,
            userDetails: {
                password: '',
                newPassword: '',
                confirmPassword: '',
            },
            loading: false,
            changePasswordForm: false,
            revealPassword: false,
            revealNewPassword: false,
            revealConfirmPassword: false,
            sendingPasswordResetEmail: false,
        };
    },
    computed: {
        ...mapState(['user']),
        disableSubmitBtn() {
            return !(this.userDetails.newPassword && this.userDetails.password);
        },
    },
    methods: {
        async changePassword() {
            this.loading = true;
            const { email } = this.user;
            const { password, newPassword, confirmPassword } = this.userDetails;
            if (newPassword !== confirmPassword) {
                await this.$confirm('Password mismatch, please retype', {
                    buttonTrueText: 'Ok',
                    buttonFalseText: '',
                });
                this.loading = false;
                return;
            }
            const user = await auth.getCurrentUser();
            if (user) {
                const credential = await FirebaseAuth.EmailAuthProvider.credential(email, password);
                await user
                    .reauthenticateWithCredential(credential)
                    .then(async (res) => {
                    logger.info('Credentials reauthentication successful. Proceeding to updating the password', res);
                    await user
                        .updatePassword(newPassword)
                        .then((res) => {
                        logger.info('Update password successful.', res);
                    })
                        .catch((err) => {
                        logger.info('Something went wrong while updating the password', err);
                    });
                })
                    .catch(async (err) => {
                    logger.info('Something went wrong while reauthenticating with credentials', err);
                    await this.$confirm(err.message ||
                        'Your current login credentials are wrong. Please try again with the right credentials', {
                        buttonTrueText: 'OK',
                        buttonFalseText: '',
                    });
                });
            }
            this.userDetails = {
                newPassword: '',
                password: '',
                confirmPassword: '',
            };
            this.loading = false;
            this.changePasswordDialog = false;
        },
        async sendPasswordResetEmail() {
            this.sendingPasswordResetEmail = true;
            const auth = FirebaseAuth();
            const { email } = this.user;
            await auth
                .sendPasswordResetEmail(email)
                .then(async () => {
                await this.$confirm('Password reset email is sent!', {
                    buttonTrueText: 'OK',
                    buttonFalseText: '',
                });
            })
                .catch(async (e) => {
                await this.$confirm(e.message ||
                    'Something went wrong while sending password reset email', {
                    buttonTrueText: 'OK',
                    buttonFalseText: '',
                });
                logger.error('Something went wrong while sending password reset email', e);
            });
            this.sendingPasswordResetEmail = false;
            this.changePasswordDialog = false;
        },
    },
});
