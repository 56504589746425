import Vue from 'vue';
export default Vue.extend({
    name: 'WorkspaceDeleteDialog',
    props: {
        deletingWS: {
            type: Boolean,
            default: false,
        },
        showDialog: {
            type: Boolean,
        },
        workspaceId: {
            type: String,
            required: true,
        },
    },
    data() {
        return {
            dialog: false,
            confirmation: '',
        };
    },
    computed: {
        allowDelete() {
            return this.confirmation === this.workspaceId;
        },
    },
    watch: {
        showDialog(val) {
            this.dialog = val;
        },
        dialog(val) {
            if (val === false) {
                this.$emit('dismiss-dialog');
            }
        },
    },
});
