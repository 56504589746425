import Vue from 'vue';
import ChannelAppearance from '@/views/workspace/channel/ChannelAppearance.vue';
import ChannelOptions from '@/views/workspace/channel/ChannelOptions.vue';
// import ChannelWidgets from '@/views/workspace/channel/ChannelWidgets.vue'
import ChannelTags from '@/views/workspace/channel/ChannelTags.vue';
import ChannelSettings from './settings/ChannelSettings.vue';
import ChannelDetails from './details/ChannelDetails.vue';
import ChannelDataManagement from './data-management/ChannelDataManagement.vue';
export default Vue.extend({
    name: 'ChannelSidePanel',
    props: {
        channel: {
            type: Object,
        },
        cols: {
            type: String,
            default: '',
        },
        isAbilityResolved: Boolean,
    },
    computed: {
        channelURL() {
            return this.$store.getters.channelURL(this.channel.id);
        },
        panels() {
            const panels = [
                { name: 'Appearance', component: ChannelAppearance },
                {
                    name: 'Options',
                    component: ChannelOptions,
                },
                // {name: 'Widgets', component: ChannelWidgets,}
            ];
            panels.push({ name: 'Channel Tags', component: ChannelTags });
            panels.push({ name: 'Data Management', component: ChannelDataManagement });
            panels.push({ name: 'Settings', component: ChannelSettings });
            return panels;
        },
    },
    components: {
        ChannelDetails,
    },
});
