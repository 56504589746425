import Vue from 'vue';
import VideoIntelligenceThumbnail from './VideoIntelligenceThumbnail.vue';
export default Vue.extend({
    name: 'VideoIntelligenceThumbnailAvatar',
    props: {
        thumbnailId: String,
        avatarSize: {
            type: Number,
            default: 60,
        },
        person: { type: Object, required: true },
        showBioOnHover: {
            type: Boolean,
            default: false,
        },
    },
    components: {
        VideoIntelligenceThumbnail,
    },
});
