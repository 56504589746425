import DB from '@lumiere/db';
import firestore, { FieldPath, FieldValue } from '@/services/firebase/firestore';
export default function publicDB(uid = 'public') {
    new DB({
        FieldPath,
        dbAuth: {
            uid: async () => Promise.resolve(uid),
            admin: Promise.resolve(false),
        },
        FieldValue,
        firestore,
    });
    return DB.getInstance();
}
