import { TeamRole } from '../types/db.teams.types';
import DB from './DB';
import CGUDO from './helpers/CGUDO';
import paths from './helpers/paths';
import { dbCollection, signatures, getUID } from './helpers/utils';
export const team = (params) => (tid) => {
    const { firestore, dbAuth, FieldValue } = params;
    const ref = firestore.doc(paths.teams(tid));
    const { created, edited, deleted } = signatures(FieldValue);
    return {
        ...CGUDO(params)(paths.teams(tid)),
        async create(createTeamProps) {
            const uid = await getUID(dbAuth);
            const data = {
                ...createTeamProps,
                roles: {
                    [uid]: TeamRole.ADMIN,
                },
                ...edited(uid),
                ...created(uid),
            };
            return ref.set(data);
        },
        async delete() {
            const teamWorkspaces = await DB.getInstance()
                .workspaces((r) => r.where(`team.id`, '==', tid))
                .get();
            const batch = firestore.batch();
            await Promise.all(teamWorkspaces.map((workspace) => DB.getInstance().workspace(workspace.id).update({
                team: FieldValue.delete(),
            }, batch)));
            batch.delete(ref);
            await batch.commit();
        },
        async softDelete(outerBatch) {
            const uid = await getUID(dbAuth);
            const deleteUpdate = { ...deleted(uid) };
            return outerBatch
                ? outerBatch.update(ref, deleteUpdate)
                : ref.update(deleteUpdate);
        },
        async restore(outerBatch) {
            const uid = await getUID(dbAuth);
            const restoreUpdate = {
                ...edited(uid),
                deleted: FieldValue.delete(),
                deletedBy: FieldValue.delete(),
                deletedTime: FieldValue.delete(),
            };
            return outerBatch
                ? outerBatch.update(ref, restoreUpdate)
                : ref.update(restoreUpdate);
        },
    };
};
export const teams = dbCollection(paths.teams());
