import muxAssetURL from './muxAssetURL';
export function getVideoDefaultPoster(video) {
    let videoPoster = video.appearance?.defaultPoster;
    if (!videoPoster) {
        const pid = video.playbackIds.find((pid) => pid.policy === 'public')?.id;
        if (pid)
            videoPoster = muxAssetURL['video'](pid);
    }
    return videoPoster;
}
export function getVideoPoster(video) {
    return video.appearance?.poster ?? getVideoDefaultPoster(video);
}
export function getVideoThumbnail(video) {
    let videoThumbnail = video.appearance?.poster ??
        video.appearance?.thumbnail ??
        video.appearance?.defaultPoster;
    if (!videoThumbnail) {
        const pid = video.playbackIds.find((pid) => pid.policy === 'public')?.id;
        if (pid)
            videoThumbnail = muxAssetURL['video'](pid) + '?width=640';
    }
    return videoThumbnail;
}
export function getAnimatedThumbnail(video) {
    let animatedThumbnail = video.appearance?.animatedThumbnail;
    if (!animatedThumbnail) {
        const pid = video.playbackIds.find((pid) => pid.policy === 'public')?.id;
        if (pid)
            animatedThumbnail = muxAssetURL['gif'](pid);
    }
    return animatedThumbnail;
}
