import Vue from 'vue';
import { CommentPlusFeature } from '@lumiere/db/types';
const defaultOptions = () => ({
    type: CommentPlusFeature.Ratings,
    prompt: 'How enjoyable is this moment?',
    required: true,
    labels: {
        min: 'Hate It',
        max: 'Love It',
    },
});
export default Vue.extend({
    props: {
        value: {
            type: Object,
        },
    },
    data() {
        return {
            options: { ...defaultOptions(), ...this.$props.value },
        };
    },
    watch: {
        options: {
            handler(options) {
                this.$emit('input', options);
            },
            immediate: true,
            deep: true,
        },
    },
});
