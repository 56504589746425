import Vue from 'vue';
import { mapState } from 'vuex';
import { TeamRole } from '@lumiere/db/types';
import COLORS from '@/colors';
import SettingsForm from './workspace/SettingsForm.vue';
import { map } from 'rxjs/operators';
import { nanoid } from 'nanoid';
export default Vue.extend({
    name: 'CreateWorkspaceForm',
    props: {
        teamId: {
            type: String,
            required: false,
        },
    },
    data() {
        const workspace = {
            name: '',
            theme: { color: '' },
            description: '',
            roles: {},
            iconURL: '',
        };
        return {
            workspaceInFocus: workspace,
            created: false,
            loading: false,
            addMemberDialog: false,
            stepperStep: 1,
            teams: [],
            newWorkspaceID: null,
            readyToSubmit: false,
        };
    },
    components: {
        SettingsForm,
    },
    computed: {
        ...mapState(['uid']),
        colors: {
            get: () => Object.keys(COLORS),
        },
        colorDefaultPrimary() {
            return this.workspaceInFocus.theme.color || 'primary';
        },
        colorDefaultGreyDarken3() {
            return this.workspaceInFocus.theme.color || 'grey darken-3';
        },
    },
    subscriptions() {
        return {
            teams: this.$db
                .teams((ref) => ref.where(`roles.${this.$store.state.uid}`, 'in', Object.values(TeamRole)))
                .observe()
                .pipe(map((teams) => teams.filter(({ deleted }) => !deleted)), map((teams) => [
                {
                    name: 'Me (Personal Workspace)',
                    id: null,
                },
                ...teams,
            ])),
        };
    },
    methods: {
        async submit() {
            const settingsPanel = this.$refs.settingsPanel;
            await this.createWorkspace()
                .then((wid) => {
                this.newWorkspaceID = wid;
                this.$emit('workspace-created', wid);
            })
                .catch(() => {
                settingsPanel.appendError('Could not create workspace');
            });
        },
        settingsFormChange(workspaceFields) {
            const { name, description, theme, team } = workspaceFields;
            this.workspaceInFocus.name = name;
            this.workspaceInFocus.description = description;
            this.workspaceInFocus.theme = theme;
            this.workspaceInFocus.team = team;
            this.readyToSubmit = !!name && !!team;
        },
        loadingChange(loading) {
            this.loading = loading;
        },
        async createWorkspace() {
            this.loading = true;
            const { name, theme, description, team } = this.workspaceInFocus;
            const id = nanoid(10);
            const wsTeam = team?.id ? team : undefined;
            const settingsPanel = this.$refs.settingsPanel;
            // create the workspace
            await this.$db
                .workspace(id)
                .create({ name, theme, description }, wsTeam)
                .then(async () => {
                settingsPanel.updateIcon(id);
                const ctx = await this.$auditLogger(this.$store.state.uid);
                if (ctx) {
                    const { Asset_Model, EVENT_ACTION, ts } = ctx.config;
                    const asset = { id, type: Asset_Model.Workspace, name };
                    await ctx.exec(asset, {
                        timestamp: ts(),
                        action: EVENT_ACTION.CREATED,
                        data: {
                            type: 'INFO',
                            payload: {
                                message: 'Workspace is created' +
                                    (team?.id ? ' and added to team' : ''),
                                ...(team?.id && {
                                    actorAssets: {
                                        type: Asset_Model.Team,
                                        ids: [team.id],
                                    },
                                }),
                            },
                        },
                    });
                }
            })
                .catch((e) => {
                throw new Error(e.message);
            })
                .finally(() => {
                this.loading = false;
            });
            this.workspaceInFocus.id = id;
            this.$db
                .workspace(id)
                .observe()
                .subscribe((workspace) => {
                if (workspace)
                    this.workspaceInFocus = workspace;
            });
            return id;
        },
        close() {
            this.$emit('close');
        },
    },
});
