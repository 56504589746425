import Vue from 'vue';
import ChoiceQuestionDraggableOptionsList from './ChoiceQuestionDraggableOptionsList.vue';
export default Vue.extend({
    name: 'ChoiceQuestionDraggableOptions',
    props: {
        value: Array,
        variant: String,
    },
    data() {
        const responseOptions = [
            ...this.$props.value,
        ];
        return {
            responseOptions: responseOptions.filter((i) => !i.isOther),
        };
    },
    watch: {
        responseOptions: {
            handler(responseOptions) {
                this.$emit('input', responseOptions);
            },
            deep: true,
        },
    },
    components: {
        ChoiceQuestionDraggableOptionsList,
    },
});
