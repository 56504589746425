import locallyStored from '@lumiere/shared/utils/locallyStored';
import { getBrowserQueryParam } from '../utils/api';
const locallyStoredLink = locallyStored('LINK');
const storedLink = locallyStoredLink.get()?.[getBrowserQueryParam('link') ?? ''];
export var FileUploadStatus;
(function (FileUploadStatus) {
    FileUploadStatus["QUEUED"] = "queued";
    FileUploadStatus["PREPARING"] = "preparing";
    FileUploadStatus["UPLOADING"] = "uploading";
    FileUploadStatus["ERROR"] = "error";
    FileUploadStatus["SAVING"] = "saving";
    FileUploadStatus["COMPLETED"] = "completed";
    FileUploadStatus["CANCELED"] = "canceled";
})(FileUploadStatus || (FileUploadStatus = {}));
const defaultState = {
    uid: null,
    user: null,
    admin: false,
    recentWorkspaces: {},
    workspaces: null,
    fileUploads: {},
    videoInfo: {
        time: 0,
        state: null,
    },
    socialAuth: {},
    videoAnalyticsData: {},
    link: storedLink || null,
    team: null,
    redirectAfterLogin: null,
};
export const locallyStoredState = locallyStored('STATE');
const state = {
    ...defaultState,
    ...locallyStoredState.get(),
};
export default () => state;
