import Vue from 'vue';
import VideoSettingsDownloadVideoBtn from './VideoSettingsDownloadVideoBtn.vue';
import VideoSettingsReplaceVideoBtn from './VideoSettingsReplaceVideoBtn.vue';
import VideoSettingsDeleteVideoBtn from './VideoSettingsDeleteVideoBtn.vue';
import VideoSettingsVideoVisibility from './VideoSettingsVideoVisibility.vue';
import VideoSettingsShareLink from './VideoSettingsShareLink.vue';
import VideoSettingsRestoreRecentlyDeletedFeatures from './VideoSettingsRestoreRecentlyDeletedFeatures.vue';
import { pluck, switchMap, startWith, shareReplay } from 'rxjs/operators';
export default Vue.extend({
    name: 'VideoSettings',
    props: {
        video: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            deletedFeatures: [],
        };
    },
    computed: {},
    watch: {},
    methods: {},
    subscriptions() {
        const videoId$ = this.$watchAsObservable('video.id', {
            immediate: true,
        }).pipe(pluck('newValue'));
        const deletedFeatures$ = videoId$.pipe(switchMap((videoId) => this.$db
            .video(videoId)
            .features((q) => q.where('deleted', '==', true).orderBy('deletedTime', 'desc'))
            .observe()
            .pipe(startWith([]))), shareReplay(1));
        return {
            deletedFeatures: deletedFeatures$,
        };
    },
    components: {
        VideoSettingsDownloadVideoBtn,
        VideoSettingsReplaceVideoBtn,
        VideoSettingsDeleteVideoBtn,
        VideoSettingsVideoVisibility,
        VideoSettingsShareLink,
        VideoSettingsRestoreRecentlyDeletedFeatures,
    },
});
