export var VideoState;
(function (VideoState) {
    VideoState["Ready"] = "Ready";
    VideoState["Playing"] = "Playing";
    VideoState["Paused"] = "Paused";
    VideoState["Ended"] = "Ended";
    VideoState["Error"] = "Error";
    VideoState["Waiting"] = "Waiting";
})(VideoState || (VideoState = {}));
export var LumiereApps;
(function (LumiereApps) {
    LumiereApps["ADMIN_APP"] = "ADMIN_APP";
    LumiereApps["VIEWER_APP"] = "VIEWER_APP";
})(LumiereApps || (LumiereApps = {}));
/**
 * Video Scrubbing states
 */
export var ScrubbingStates;
(function (ScrubbingStates) {
    ScrubbingStates["START"] = "START";
    ScrubbingStates["STOP"] = "STOP";
    ScrubbingStates["ACTIVE"] = "ACTIVE";
})(ScrubbingStates || (ScrubbingStates = {}));
export var ScrubberMode;
(function (ScrubberMode) {
    ScrubberMode["TIMER"] = "TIMER";
    ScrubberMode["RANGE"] = "RANGE";
    ScrubberMode["PICKER"] = "PICKER";
})(ScrubberMode || (ScrubberMode = {}));
