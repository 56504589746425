import { mapState } from 'vuex';
import * as am4core from '@amcharts/amcharts4/core';
import * as am4charts from '@amcharts/amcharts4/charts';
import am4themes_animated from '@amcharts/amcharts4/themes/animated';
import isEqual from 'lodash/isEqual';
am4core.useTheme(am4themes_animated);
import { dayjs } from '@lumiere/shared/utils/dayjs';
import adminAPI from '@/services/adminAPI';
import { getBrowserQueryParam } from '@/utils/api';
import mixins from 'vue-typed-mixins';
import ThemeColor from '@/utils/ThemeColor';
// import UTC from 'dayjs/plugin/utc'
// import dayjs from 'dayjs'
// dayjs.extend(UTC)
const POLLING_INTERVAL = 30000;
export default mixins(ThemeColor).extend({
    name: 'VideoViewsTrend',
    props: {
        video: {
            type: Object,
        },
        showTabComponent: {
            type: Function,
            required: true,
        },
    },
    data() {
        return {
            amChart: null,
            showTotalCount: true,
            refreshing: false,
            seriesData: [],
            pollingInterval: null,
            activeViewers: null,
            viewsCount: 0,
            uniqueViewers: 0,
            viewsPerDay: null,
        };
    },
    methods: {
        constructChartProps(seriesData) {
            this.disposeOfChart();
            // Create chart instance
            const chart = am4core.create(this.$refs.chartContainer, am4charts.XYChart);
            this.amChart = chart;
            // Create axes
            const dateAxis = chart.xAxes.push(new am4charts.DateAxis());
            //Solves the problem with first label not quite aligning with the data.
            //https://codepen.io/CodeOwl/pen/XWXpmRV?editors=1011
            dateAxis.renderer.grid.template.location = 0.5;
            dateAxis.renderer.grid.template.disabled = true;
            dateAxis.renderer.labels.template.disabled = true;
            if (dateAxis.tooltip) {
                dateAxis.tooltip.disabled = true;
            }
            const valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
            if (valueAxis.tooltip) {
                valueAxis.tooltip.disabled = true;
            }
            chart.events.on('ready', (_ev) => {
                // valueAxis.min = valueAxis.minZoomed
                valueAxis.min = 0;
                valueAxis.max = valueAxis.maxZoomed;
            });
            valueAxis.renderer.baseGrid.disabled = true;
            valueAxis.renderer.labels.template.disabled = true;
            valueAxis.renderer.grid.template.disabled = true;
            chart.data = seriesData;
            dateAxis.dateFormatter = new am4core.DateFormatter();
            dateAxis.dateFormatter.utc = true;
            const series = chart.series.push(new am4charts.ColumnSeries());
            const tooltip = series.tooltip;
            if (tooltip) {
                tooltip.background.fill = am4core.color('#fff');
                tooltip.label.fill = am4core.color('#00');
                tooltip.getFillFromObject = false;
                tooltip.dateFormatter.dateFormat = 'dd MMM yyyy';
                tooltip.dateFormatter.utc = true;
            }
            //rollover.
            // series.segments.template.interactionsEnabled = true
            series.name = 'Views';
            series.dataFields.valueY = 'value';
            series.dataFields.dateX = 'date';
            series.minBulletDistance = 15;
            series.tooltipHTML = `{dateX}<br/>Views: <b>{valueY}</b>`;
            series.stroke = this.themeColorAm4Core;
            series.strokeWidth = 1;
            series.fill = this.themeColorAm4Core;
            series.fillOpacity = 1;
            chart.cursor = new am4charts.XYCursor();
            chart.cursor.xAxis = dateAxis;
            chart.cursor.lineX.disabled = true;
            chart.cursor.lineY.disabled = true;
            chart.cursor.maxTooltipDistance = -1;
            chart.height = 30;
            chart.paddingLeft = 0;
            chart.paddingRight = 0;
            chart.paddingBottom = 0;
            chart.paddingTop = 0;
            chart.cursor.behavior = 'none';
            const fillModifier = new am4core.LinearGradientModifier();
            fillModifier.opacities = [1, 0];
            fillModifier.offsets = [0, 1];
            fillModifier.gradient.rotation = 90;
            // series.segments.template.fillModifier = fillModifier
            //This is how you do smoothing. There's a tensionY as well
            //but I don't recommend using it.
            series.tensionX = 0.77;
            // Create vertical scrollbar and place it before the value axis
            // chart.scrollbarY = new am4core.Scrollbar()
            // chart.scrollbarY.parent = chart.leftAxesContainer
            // chart.scrollbarY.toBack()
            // Create a horizontal scrollbar with preview and place it underneath the date axis
            //https://github.com/amcharts/amcharts4/issues/1443
            //create it and assign it like this otherwise you get typescript errors.
            // const scrollbarX = new am4charts.XYChartScrollbar()
            // chart.scrollbarX = scrollbarX
            // scrollbarX.series.push(series)
            // Enable export
            // chart.exporting.menu = new am4core.ExportMenu()
        },
        disposeOfChart() {
            this.amChart?.dispose();
            this.amChart = null;
        },
        async loadViewsCount() {
            const cid = getBrowserQueryParam('cid');
            const standalone_video_filter = getBrowserQueryParam('standalone_video_filter');
            const response = await adminAPI((api) => api.video.insights.getVideoViewsCount({
                vid: this.video.id,
                cid,
                standalone_video_filter,
            }));
            this.viewsCount = response.views_count;
            this.uniqueViewers = response.unique_viewers;
            this.viewsPerDay = response.views_per_day;
            this.seriesData = response.seriesData;
        },
        async pollVideoViewsActiveViewing() {
            const cid = getBrowserQueryParam('cid');
            const standalone_video_filter = getBrowserQueryParam('standalone_video_filter');
            const { unique_active_viewers } = await adminAPI(async (api) => api.video.insights.getVideoViewsActiveViewing({
                vid: this.video.id,
                cid,
                standalone_video_filter,
            }));
            this.activeViewers = unique_active_viewers;
        },
    },
    computed: {
        ...mapState(['uid']),
        unixMidnight() {
            const d = new Date();
            d.setHours(0, 0, 0, 0);
            return d.getTime();
        },
        startDate() {
            const date = this.seriesData[0]?.date;
            if (!date)
                return '';
            return dayjs.utc(date).format('MMM DD');
        },
        endDate() {
            const len = this.seriesData.length - 1;
            const date = this.seriesData[len]?.date;
            if (!date)
                return '';
            return dayjs.utc(date).format('MMM DD');
        },
    },
    mounted() {
        this.pollVideoViewsActiveViewing();
        this.pollingInterval = window.setInterval(() => {
            this.pollVideoViewsActiveViewing();
        }, POLLING_INTERVAL);
    },
    watch: {
        'uid': {
            handler(uid) {
                if (uid && this.video?.id) {
                    this.loadViewsCount();
                }
            },
            immediate: true,
        },
        'seriesData': {
            handler(data) {
                if (data?.length) {
                    this.$sleep(0).then(() => {
                        this.constructChartProps(data);
                    });
                }
            },
            immediate: true,
        },
        'video.eids': {
            handler(eids, oldEids) {
                if (!isEqual(eids, oldEids)) {
                    // refresh the data with the new eids video filter
                    this.refreshing = true;
                    setTimeout(async () => {
                        await this.loadViewsCount();
                        this.refreshing = false;
                    }, 1000);
                }
            },
        },
        '$route.query.link': {
            handler(link) {
                if (!this.uid && link && this.video?.id) {
                    this.loadViewsCount();
                }
            },
            immediate: true,
        },
        '$route.query.cid': {
            async handler(c, oc) {
                if (this.video?.id && (c || oc)) {
                    // refresh the data with the new cid filter
                    this.refreshing = true;
                    await this.loadViewsCount();
                    this.refreshing = false;
                }
            },
        },
        '$route.query.standalone_video_filter': {
            async handler(v, ov) {
                if (this.video?.id && (v || ov)) {
                    this.refreshing = true;
                    await this.loadViewsCount();
                    this.refreshing = false;
                }
            },
        },
    },
    beforeDestroy() {
        this.disposeOfChart();
        if (this.pollingInterval) {
            clearInterval(this.pollingInterval);
        }
    },
});
