import Vue from 'vue';
import { EmojiPosition, } from '@lumiere/db/types';
import EmojiFeedback from './EmojiFeedback.vue';
const POLLING_INTERVAL = 15;
export default Vue.extend({
    name: 'EmojiFeedbackContainer',
    props: {
        emojiFeatures: {
            type: Array,
            required: true,
        },
        hide: Boolean,
        videoId: String,
        // TODO remove appName prop
        appName: String,
        currentTime: Number,
        isViewerExperience: Boolean,
        overlay: Object,
    },
    data() {
        return {
            flyOutEmojis: {},
        };
    },
    computed: {
        emojiFeaturesByPosition() {
            const featuresByPosition = (position) => this.emojiFeatures.filter((ef) => ef.options?.position === position);
            const emojiFeaturesByPosition = {
                left: featuresByPosition(EmojiPosition.Left),
                center: featuresByPosition(EmojiPosition.Center),
                right: featuresByPosition(EmojiPosition.Right),
            };
            return emojiFeaturesByPosition;
        },
        scaleFeaturesTofit() {
            const featuresCount = this.emojiFeatures.length;
            return featuresCount < 2
                ? null
                : featuresCount === 2
                    ? 'double__features'
                    : 'three-and-more__features';
        },
        size() {
            const width = this.overlay?.width;
            if (!width)
                return 'XL';
            if (width >= 640) {
                return 'XL';
            }
            else if (width >= 480 && width < 640) {
                return 'L';
            }
            else if (width >= 415 && width < 480) {
                return 'M';
            }
            else if (width >= 320 && width < 415) {
                return 'S';
            }
            else {
                return 'XS';
            }
        },
        floorCurrentTime() {
            return Math.floor(this.currentTime);
        },
    },
    methods: {
        // poll new data every 15s
        async flyOutEmojisPolling(time, forcePoll = false) {
            if (!this.isViewerExperience)
                return;
            const shouldFetchData = forcePoll || time % POLLING_INTERVAL === 0;
            if (shouldFetchData) {
                // poll new data: hashed by {fid: {emoji_ids} }
                const flyOutEmojis = await this.$store.dispatch('GET_FLY_OUT_EMOJIS', {
                    vid: this.videoId,
                    range: { min: time, max: time + 18 }, // use 3s buffer for data fetching
                });
                this.flyOutEmojis = Object.assign({}, this.flyOutEmojis, flyOutEmojis);
            }
        },
        getFlyOutEmojis(fid) {
            return this.flyOutEmojis[fid] || {};
        },
        refreshEmojiInteractionData() {
            // eslint-disable-next-line @typescript-eslint/no-floating-promises
            this.flyOutEmojisPolling(this.currentTime, true);
            this.$nextTick(() => {
                const emojiFeedback = this.$refs.emojiFeedback;
                if (emojiFeedback?.length) {
                    for (const ref of emojiFeedback) {
                        ref?.fetchTotalEmojiClicks(this.currentTime, true);
                    }
                }
            });
        },
    },
    watch: {
        floorCurrentTime: {
            handler(t) {
                // eslint-disable-next-line @typescript-eslint/no-floating-promises
                this.flyOutEmojisPolling(t ?? 0);
            },
            immediate: true,
        },
        emitFeedback() { },
    },
    components: {
        EmojiFeedback,
    },
});
