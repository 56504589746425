import Vue from 'vue';
import { SKELETON_COUNT } from './helper';
export default Vue.extend({
    name: 'VideoInsightTimelineSkeletonLoader',
    props: {
        numberOfSkeletons: {
            type: Number,
            default: SKELETON_COUNT,
        },
        width: {
            type: [Number, String],
            default: 50,
        },
    },
});
