import Vue from 'vue';
import TextPromptFeedback from './TextPromptFeedback.vue';
export default Vue.extend({
    name: 'TextPromptFeedbackContainer',
    props: {
        textPromptFeatures: {
            type: Array,
        },
        videoId: String,
        currentTime: Number,
        isViewerExperience: Boolean,
        overlay: Object,
        hide: Boolean,
    },
    computed: {
        scaleFeaturesTofit() {
            const featuresCount = this.textPromptFeatures.length;
            return featuresCount < 2
                ? null
                : featuresCount > 2
                    ? 'three-and-more__features'
                    : 'double__features';
        },
        size() {
            const width = this.overlay?.width;
            if (!width)
                return 'XL';
            if (width >= 640) {
                return 'XL';
            }
            else if (width >= 480 && width < 640) {
                return 'L';
            }
            else if (width >= 415 && width < 480) {
                return 'M';
            }
            else if (width >= 320 && width < 415) {
                return 'S';
            }
            else {
                return 'XS';
            }
        },
    },
    components: {
        TextPromptFeedback,
    },
});
