import Vue from 'vue';
const viewerLanguages = {
    zh: 'Chinese (Simplified)',
    'zh-Hant': 'Chinese (Traditional)',
    en: 'English',
    fr: 'French',
    de: 'German',
    it: 'Italian',
    ja: 'Japanese',
    ko: 'Korean',
    pt: 'Portuguese (Brazilian & Continental)',
    ru: 'Russian',
    es: 'Spanish',
};
const defaultLanguage = 'en';
export default Vue.extend({
    name: 'VideoDataManagementEditDataFilter',
    props: {
        video: Object,
    },
    data() {
        return {
            saving: false,
            language: this.video.language || defaultLanguage,
            viewerLanguagesArray: Object.entries(viewerLanguages).map(([value, text]) => ({
                value,
                text,
            }))
        };
    },
    methods: {
        async onChangeLanguage(language) {
            this.saving = true;
            await this.$db.video(this.video.id).update({
                language
            });
            this.saving = false;
        },
    },
    components: {},
});
