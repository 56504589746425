import Vue from 'vue';
import * as am4core from '@amcharts/amcharts4/core';
import * as am4charts from '@amcharts/amcharts4/charts';
import am4themes_animated from '@amcharts/amcharts4/themes/animated';
import config from '@lumiere/shared/config';
import { isNumber } from '../helper';
am4core.useTheme(am4themes_animated);
export default Vue.extend({
    props: {
        totalInteractions: [Number, Object],
        emojisAndTheirTotals: {
            type: Array,
        },
        getColorAtIndex: Function,
        mouseOverEmojiAtIndex: Function,
        mouseLeaveEmojiAtIndex: Function,
        loading: Boolean,
        featureId: String,
    },
    data() {
        return {
            amChart: null,
        };
    },
    computed: {
        chartEmojiData() {
            if (!this.totalInteractions)
                return [];
            return this.emojisAndTheirTotals
                .map((item, ix) => ({
                ...item.emoji,
                click_count: item.total,
                href: item.emoji?.downloadURL ??
                    `${config.adminAppURL}/img/emoji/${item.emoji.id}.svg`,
                color: this.getColorAtIndex(ix),
                click_count_percent: Math.round((item.total / this.totalInteractions) * 100),
                originalIndex: ix,
            }))
                .sort((ex, ey) => ex.click_count - ey.click_count);
        },
    },
    methods: {
        createBarChart() {
            if (this.amChart) {
                this.refreshtData();
                return;
            }
            // Create chart instance
            let chart = am4core.create(this.$refs.chartContainer, am4charts.XYChart);
            this.amChart = chart;
            chart.hiddenState.properties.opacity = 0; // this creates initial fade-in
            chart.data = this.chartEmojiData;
            chart.colors.list = this.chartEmojiData
                .slice(0)
                .map(({ color }) => am4core.color(color));
            // chart.marginLeft = -20
            chart.padding(10, 10, 10, 40);
            chart.leftAxesContainer.visible = false;
            // const maxCount = maxBy(chart.data, function (o) {
            //   return o.click_count
            // }).click_count
            let categoryAxis = chart.yAxes.push(new am4charts.CategoryAxis());
            categoryAxis.dataFields.category = 'name';
            categoryAxis.renderer.grid.template.strokeOpacity = 0;
            categoryAxis.renderer.minGridDistance = 10;
            categoryAxis.renderer.labels.template.dx = -40;
            // categoryAxis.renderer.minWidth = 30 // 120
            categoryAxis.renderer.labels.template.disabled = true;
            categoryAxis.renderer.grid.template.disabled = true;
            categoryAxis.cursorTooltipEnabled = false;
            // if (categoryAxis.renderer.tooltip) {
            //   categoryAxis.renderer.tooltip.dx = -40
            // }
            let valueAxis = chart.xAxes.push(new am4charts.ValueAxis());
            valueAxis.renderer.inside = true;
            valueAxis.renderer.labels.template.fillOpacity = 0.3;
            valueAxis.renderer.grid.template.strokeOpacity = 0;
            valueAxis.min = 0;
            valueAxis.cursorTooltipEnabled = false;
            valueAxis.renderer.baseGrid.strokeOpacity = 0;
            valueAxis.renderer.labels.template.dy = 20;
            valueAxis.renderer.labels.template.disabled = true;
            let series = chart.series.push(new am4charts.ColumnSeries());
            series.dataFields.valueX = 'click_count_percent';
            series.dataFields.valueY = 'click_count';
            series.dataFields.categoryX = 'originalIndex';
            series.dataFields.categoryY = 'name';
            series.tooltipText = '[font-size:12px]{categoryY}[/]';
            if (series.tooltip) {
                // series.tooltip.disabled = true
                series.tooltip.pointerOrientation = 'vertical';
                series.tooltip.dy = -30;
                series.tooltip.dx = -30;
            }
            series.columnsContainer.zIndex = 100;
            let columnTemplate = series.columns.template; // 50
            columnTemplate.height = am4core.percent(60);
            columnTemplate.maxHeight = 70; // 50
            columnTemplate.column.cornerRadius(10, 5, 10, 5); // 60, 10
            columnTemplate.strokeOpacity = 0;
            columnTemplate.events.once('inited', function (event) {
                event.target.fill = chart.colors.getIndex(event.target.dataItem?.index || 0);
            });
            columnTemplate.events.on('over', (event) => {
                // @ts-ignore
                const index = event.target.dataItem?.categoryX; // categoryX := originalIndex
                if (isNumber(index)) {
                    this.mouseOverEmojiAtIndex(index);
                }
            });
            columnTemplate.events.on('out', (event) => {
                // @ts-ignore
                const index = event.target.dataItem?.categoryX; // categoryX := originalIndex
                if (isNumber(index)) {
                    this.mouseLeaveEmojiAtIndex(index);
                }
            });
            series.mainContainer.mask = undefined;
            const cursor = new am4charts.XYCursor();
            chart.cursor = cursor;
            cursor.lineX.disabled = true;
            cursor.lineY.disabled = true;
            cursor.behavior = 'none';
            const bullet = columnTemplate.createChild(am4charts.CircleBullet);
            bullet.circle.radius = 17; // 10
            bullet.valign = 'middle';
            bullet.align = 'left';
            bullet.isMeasured = true;
            bullet.interactionsEnabled = false;
            bullet.horizontalCenter = 'right';
            bullet.interactionsEnabled = false;
            // let hoverState =
            bullet.states.create('hover');
            const outlineCircle = bullet.createChild(am4core.Circle);
            outlineCircle.adapter.add('radius', function (_radius, target) {
                let circleBullet = target.parent;
                if (circleBullet) {
                    // @ts-ignore
                    return circleBullet.circle.pixelRadius + 5; // 10
                }
                return 10;
            });
            let image = bullet.createChild(am4core.Image);
            image.width = 40;
            image.height = 40;
            image.horizontalCenter = 'middle';
            image.verticalCenter = 'middle';
            image.propertyFields.href = 'href';
            image.adapter.add('mask', function (_mask, target) {
                let circleBullet = target.parent;
                // @ts-ignore
                return circleBullet.circle;
            });
            const valueLabel = series.bullets.push(new am4charts.LabelBullet());
            valueLabel.label.text = '{valueY.value}';
            valueLabel.label.fontSize = 13;
            valueLabel.label.horizontalCenter = 'right';
            valueLabel.label.dx = -3; // -5
            valueLabel.label.truncate = false;
            valueLabel.label.hideOversized = false;
            valueLabel.label.fill = am4core.color('#fff');
            valueLabel.label.adapter.add('text', (text, target) => {
                target.reinit();
                if (target.availableWidth < 40) {
                    target.dx = 25;
                    target.fill = am4core.color('#000');
                }
                else {
                    target.dx = -3;
                    target.fill = am4core.color('#fff');
                }
                return text;
            });
            valueLabel.label.adapter.add('horizontalCenter', (center, target) => {
                target.reinit();
                if (target.availableWidth < 40) {
                    target.dx = 25;
                    target.fill = am4core.color('#000');
                }
                else {
                    target.dx = -3;
                    target.fill = am4core.color('#fff');
                }
                return center;
            });
            // columnTemplate.events.on('sizechanged', (_evt) => {
            //   this.adjustLabelPosition()
            // })
        },
        refreshtData() {
            this.amChart.data = this.chartEmojiData;
            this.amChart.series.each(function (series) {
                series.appear();
            });
        },
        disposeOfCharts() {
            this.amChart?.dispose();
            this.amChart = null;
        },
    },
    watch: {
        featureId(fid) {
            if (fid) {
                this.disposeOfCharts();
            }
        },
        totalInteractions: {
            handler(value) {
                if (value) {
                    this.$nextTick(() => {
                        this.createBarChart();
                    });
                }
            },
            immediate: true,
        },
    },
    beforeDestroy() {
        this.disposeOfCharts();
    },
});
