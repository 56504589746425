import Vue from 'vue';
import logger from '@lumiere/shared/services/logger';
export default Vue.extend({
    name: 'SimulateError',
    methods: {
        generateError() {
            logger.info('about to throw an error', { data: 123 });
            return new URL('a bad url');
        },
    },
});
