import Vue from 'vue';
import ChannelDataManagementEditDataFilter from './ChannelDataManagementEditDataFilter.vue';
import ChannelDataManagementDataExport from './ChannelDataManagementDataExport.vue';
export default Vue.extend({
    name: 'ChannelDataManagement',
    props: {
        channel: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {};
    },
    computed: {},
    watch: {},
    methods: {},
    subscriptions() {
        return {};
    },
    components: {
        ChannelDataManagementEditDataFilter,
        ChannelDataManagementDataExport,
    },
});
