var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{attrs:{"loading":_vm.loading,"disabled":_vm.loading}},[_c('v-card-title',[_c('v-icon',[_vm._v(_vm._s(_vm.featureDefaults[_vm.feature.type].icon))]),_c('v-row',{staticClass:"px-5",attrs:{"no-gutters":""}},[(_vm.renaming)?_c('v-col',{attrs:{"align-self":"center"}},[_c('v-text-field',{staticClass:"mt-n1",staticStyle:{"height":"52px","font-size":"20px"},attrs:{"data-testid":"feature-edit-form-label","autocomplete":"off","single-line":"","hide-details":"","autofocus":""},on:{"change":_vm.updateTitle,"blur":function($event){_vm.renaming = false}},model:{value:(_vm.feature.label),callback:function ($$v) {_vm.$set(_vm.feature, "label", $$v)},expression:"feature.label"}})],1):_c('v-col',{staticClass:"title",attrs:{"align-self":"center"}},[_c('span',{staticClass:"pr-1",staticStyle:{"line-height":"48px"}},[_vm._v(" "+_vm._s(_vm.feature.label)+" ")]),_c('v-menu',{scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"pb-2",attrs:{"data-testid":"feature-edit-form-label-edit-button","icon":"","small":""},on:{"click":function($event){_vm.renaming = true}}},on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-pencil")])],1)]}}])})],1)],1),_c('v-spacer'),_c('v-btn',{attrs:{"icon":""},on:{"click":_vm.close}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1),_c('v-tabs',{staticStyle:{"min-height":"300px"},attrs:{"vertical":_vm.isVerticalTabs,"centered":!_vm.isVerticalTabs,"show-arrows":"","grow":!_vm.isVerticalTabs}},[_c('v-tab',{attrs:{"data-testid":"feature-edit-form-options-tab"}},[_vm._v("Options")]),(!_vm.isExternalData)?_c('v-tab',{attrs:{"data-testid":"feature-edit-form-triggers-tab"}},[_vm._v(" Triggers ")]):_vm._e(),(_vm.hasDataExport)?_c('v-tab',[_vm._v("Data")]):_vm._e(),_c('v-tab-item',{staticClass:"px-4"},[_c('FeatureOptions',{attrs:{"type":_vm.feature.type,"editMode":""},model:{value:(_vm.feature.options),callback:function ($$v) {_vm.$set(_vm.feature, "options", $$v)},expression:"feature.options"}})],1),(!_vm.isExternalData)?_c('v-tab-item',[_c('v-card',{attrs:{"flat":""}},[_c('v-card-text',[_c('FeatureTriggers',{attrs:{"type":_vm.feature.type,"featureTriggers":{ triggers: _vm.triggers, stoppers: _vm.stoppers },"featureId":_vm.feature.id,"videoId":_vm.videoId},on:{"input":_vm.saveTriggers}})],1)],1)],1):_vm._e(),(_vm.hasDataExport)?_c('v-tab-item',[_c('v-card',{staticClass:"ma-4",attrs:{"flat":""}},[_c('v-card-text',[(_vm.feature.type === _vm.FeatureType.Emojis)?_c('EmojiDataExport',{attrs:{"videoId":this.videoId,"featureId":this.feature.id}}):_vm._e(),(_vm.feature.type === _vm.FeatureType.Comments)?_c('CommentDataExport',{attrs:{"videoId":this.videoId,"featureId":this.feature.id}}):_vm._e(),(_vm.feature.type === _vm.FeatureType.ExternalData)?_c('ExternalData_DataExport',{attrs:{"videoId":this.videoId,"featureId":this.feature.id}}):_vm._e(),(
              _vm.feature.type === _vm.FeatureType.Question &&
              _vm.feature.options.type === _vm.QuestionType.Text
            )?_c('TextQuestionDataExport',{attrs:{"videoId":this.videoId,"featureId":this.feature.id}}):_vm._e(),(
              _vm.feature.type === _vm.FeatureType.Question &&
              _vm.feature.options.type === _vm.QuestionType.Choice
            )?_c('ChoiceQuestionDataExport',{attrs:{"videoId":this.videoId,"featureId":this.feature.id}}):_vm._e()],1)],1)],1):_vm._e()],1),_c('v-divider'),_c('v-card-actions',[_c('v-btn',{attrs:{"text":"","color":"red"},on:{"click":function($event){_vm.dialog = true}}},[_vm._v("Remove")]),_c('v-spacer'),_c('v-btn',{attrs:{"text":""},on:{"click":function () {
          _vm.$emit('close')
          _vm.feature.label = _vm.emojiLabel
        }}},[_vm._v(" Close ")]),_c('v-btn',{attrs:{"data-testid":"feature-edit-form-save-button","depressed":"","disabled":_vm.loading ||
        !_vm.feature.label ||
        (_vm.isExternalData && (!_vm.JSONFromCSV || !_vm.JSONFromCSV.length)),"loading":_vm.loading,"color":"primary"},on:{"click":_vm.updateFeature}},[_vm._v(" Save ")])],1),_c('FeatureDeleteDialog',{attrs:{"deletingFeature":_vm.deletingFeature,"showDialog":_vm.dialog,"featureId":_vm.feature.id},on:{"delete-feature":_vm.remove,"dismiss-dialog":function($event){_vm.dialog = false}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }