import Vue from 'vue';
import cloneDeep from 'lodash/cloneDeep';
import { Role, } from '@lumiere/db/types';
import logger from '@lumiere/shared/services/logger';
import COLORS from '@/colors';
import { getRandomColor } from '@/colors';
import { Routes } from '@/router/router';
import WorkspaceDeleteDialog from './WorkspaceDeleteDialog.vue';
import WorkspaceCardImageItem from './CardImageItem.vue';
import { FieldValue } from '@/services/firebase/firestore';
import { teamToWorkspaceRoleMap } from '@lumiere/db/src/db.workspaces';
import storage from '@/services/firebase/storage';
export default Vue.extend({
    name: 'SettingsForm',
    props: {
        workspace: Object,
        formAction: String,
        teams: Array,
        teamId: String,
    },
    data() {
        const { name, description, theme } = this.workspace;
        return {
            loading: false,
            error: null,
            name,
            description: description || '',
            theme: cloneDeep(theme),
            errors: [],
            customErrorMessageForNameInput: [],
            selectedTeamID: this.$props.teamId,
            selectedTeam: null,
            previouslySelectedTeam: null,
            errorColor: this.$vuetify.theme.themes.light.error,
            nameRules: [
                (v) => !!v || 'Name is required',
                (v) => (v && !v.match(/([\t\n]+)/)) ||
                    'Name cannot contain tabs or new-lines.',
                (v) => (v && !v.match(/(\s{2,})/)) || 'Name can only contain single spaces.',
                (v) => (v && !!v.match(/^[a-zA-Z0-9]/)) ||
                    'Name must start with an alphanumeric.',
                (v) => (v && v.length <= 30) || 'Name must be less than 30 characters',
            ],
            descriptionRules: [
                (v) => (v && !v.match(/([\t\n]+)/)) ||
                    !v ||
                    'Description cannot contain tabs or new-lines.',
                (v) => (v && !!v.match(/^[a-zA-Z0-9]/)) ||
                    !v ||
                    'Description must start with an alphanumeric.',
                (v) => (v && !v.match(/(\s{2,})/)) ||
                    !v ||
                    'Description can only contain single spaces.',
                (v) => (v && v.length <= 250) ||
                    !v ||
                    'Description must be less than 250 characters.',
            ],
            colorRules: [(v) => (v && v !== '') || 'Color is required'],
            teamRules: [(v) => v !== '' || 'Owner is required'],
            dialog: false,
            deletingWS: false,
            dataURL: null,
            isAbilityResolved: false,
        };
    },
    //auto-assign color if none exists.
    mounted() {
        if (!this.color || this.color == '') {
            this.color = getRandomColor();
        }
        this.$ability.on('updated', (_res) => {
            this.isAbilityResolved = true;
        });
    },
    computed: {
        composite() {
            return `${this.name}${this.description}${this.theme.color}${this.selectedTeamID ?? 'null'}${this.dataURL}`;
        },
        defaultTeamName() {
            return this.teams?.[0].name || null;
        },
        workspaceComposite() {
            const ws = this.workspace;
            return `${ws.name}${ws.description}${ws.theme.color}${ws.iconURL}`;
        },
        isDirty() {
            return this.composite != this.workspaceComposite;
        },
        COLORS: {
            get: () => Object.keys(COLORS),
        },
        uid() {
            return this.$store.state.uid;
        },
        admin() {
            return this.$store.state.admin;
        },
        color: {
            get() {
                return this.theme.color || '';
            },
            set(c) {
                this.theme.color = c;
            },
        },
        themeColor() {
            return COLORS[this.theme.color];
        },
        owner() {
            return this.workspace.roles[this.uid] === Role.OWNER;
        },
        cannotAddOrEditForm() {
            if (!this.isAbilityResolved) {
                return false;
            }
            else if (this.formAction === 'CREATE') {
                return this.$ability.cannot('create', 'Workspace');
            }
            else {
                return this.$ability.cannot('manage', 'Workspace');
            }
        },
    },
    methods: {
        appendError(message) {
            this.errors.push({ message });
        },
        reset() {
            this.selectedTeamID = '';
            this.selectedTeam = null;
            this.previouslySelectedTeam = null;
            this.errors = [];
            this.color = '';
            const form = this.$refs.form;
            form.resetValidation();
        },
        deleteWorkspace() {
            this.deletingWS = true;
            this.$db
                .workspace(this.workspace.id)
                .softDelete()
                .catch((e) => {
                logger.error('error', e);
            })
                .then(() => {
                this.close();
            })
                .finally(() => {
                this.deletingWS = false;
                this.dialog = false;
            });
            if (this.$route.params.workspaceId === this.workspace.id) {
                this.$router.replace({ name: Routes.Landing });
            }
        },
        async saveChanges() {
            if (!this.isDirty) {
                return true;
            }
            this.loading = true;
            let success = false;
            const { name, description, theme, selectedTeam, previouslySelectedTeam, } = this;
            const updatedDoc = {
                name,
                description,
                theme,
                roles: this.workspace.roles,
            };
            if (selectedTeam && selectedTeam.id) {
                const updatedDocAny = updatedDoc;
                updatedDocAny.team = selectedTeam;
            }
            const { roles } = updatedDoc;
            //if there was a previously selected team, then remove the team from the roles
            if (previouslySelectedTeam) {
                const { roles: rolesFromPreviousTeam } = previouslySelectedTeam;
                if (rolesFromPreviousTeam) {
                    Object.keys(rolesFromPreviousTeam).forEach((key) => {
                        delete roles[key];
                    });
                }
            }
            //if there was a selected team, then add the team to the roles. handle
            //the conversion from TeamRole to Role. If it's the empty-id team, then
            //then assign the user as the owner.
            if (selectedTeam) {
                if (!selectedTeam.id) {
                    updatedDoc.roles[this.uid] = Role.OWNER;
                }
                else {
                    const { roles: rolesFromNewTeam } = selectedTeam;
                    Object.keys(rolesFromNewTeam).forEach((key) => {
                        const teamRole = rolesFromNewTeam[key];
                        const workspaceRole = teamToWorkspaceRoleMap[teamRole];
                        roles[key] = workspaceRole;
                    });
                }
            }
            await this.$db
                .workspace(this.workspace.id)
                .update(updatedDoc)
                .catch((e) => {
                logger.error('error', e);
                this.errors.push({ message: "Couldn't update workspace" });
                success = false;
            })
                .then(() => {
                this.close();
                success = true;
            });
            this.loading = false;
            return success;
        },
        close() {
            this.$emit('close');
        },
        onFileSelected(dataURL) {
            this.dataURL = dataURL;
            if (this.workspace?.id) {
                this.updateIcon(this.workspace.id);
            }
        },
        updateIcon(wid) {
            const dataURL = this.dataURL;
            // if iconURL is present, upload the file as dataURL, get the download URL and update the workspace
            if (dataURL) {
                try {
                    const uploader = this.$uploadFileToStorage(dataURL, `workspace-icon/${wid}/`, wid);
                    uploader.getURL().then(async (url) => {
                        const updatedDoc = {
                            iconURL: url,
                        };
                        // save the iconURL to firestore
                        await this.$db
                            .workspace(wid)
                            .update(updatedDoc)
                            .catch((e) => {
                            logger.error('error', e);
                        })
                            .then(() => {
                            logger.info('done updating workspace with iconURL');
                        });
                    });
                }
                catch (e) {
                    logger.error('something went wrong while uploading workspace icon', e);
                }
            }
            else {
                storage
                    .ref()
                    .child('workspace-icon/')
                    .child(`${wid}/`)
                    .listAll()
                    .then(async (res) => {
                    res.items.forEach(function (iconRef) {
                        // All the items under listRef.
                        iconRef
                            .delete()
                            .then(() => {
                            logger.error('File deleted successfully');
                        })
                            .catch((error) => {
                            logger.error('Uh-oh, an error occurred!', error);
                        });
                    });
                    if (res.items.length) {
                        // remove the iconURL on firestore
                        await this.$db
                            .workspace(wid)
                            .update({
                            iconURL: FieldValue.delete(),
                        })
                            .catch((e) => {
                            logger.error('error', e);
                        })
                            .then(() => {
                            logger.info('done removing iconURL from workspace');
                        });
                    }
                })
                    .catch((error) => {
                    logger.error('unknown error occurred.', error);
                });
            }
        },
    },
    watch: {
        composite() {
            const { name, description, theme, selectedTeamID } = this;
            const team = this.teams
                ? this.teams.find(({ id }) => id === selectedTeamID) || null
                : null;
            this.previouslySelectedTeam = this.selectedTeam;
            this.selectedTeam = team;
            const workspaceFields = {
                name,
                description,
                theme,
                team,
            };
            this.$emit('fieldsChange', { ...workspaceFields });
        },
        teams: {
            handler(newVal) {
                if (newVal?.length) {
                    this.selectedTeamID = this.teamId ?? newVal[0].id;
                }
            },
            immediate: true,
        },
        loading(newVal, oldVal) {
            if (newVal && !oldVal) {
                this.$emit('loadingChange', true);
            }
            else if (!newVal && oldVal) {
                this.$emit('loadingChange', false);
            }
        },
        workspace: {
            immediate: true,
            handler() {
                const { name, description, theme } = this.workspace;
                this.name = name;
                this.description = description || '';
                this.theme = cloneDeep(theme);
            },
        },
        //clear out the errors after 3 seconds so that they don't annoy the user.
        customErrorMessageForNameInput: {
            immediate: true,
            handler(newVal) {
                if (newVal.length > 0) {
                    setTimeout(() => {
                        this.customErrorMessageForNameInput = [];
                    }, 3000);
                }
            },
        },
        errors: {
            immediate: true,
            handler(newVal) {
                if (newVal.length > 0) {
                    setTimeout(() => {
                        this.errors = [];
                    }, 3000);
                }
            },
        },
    },
    components: {
        WorkspaceDeleteDialog,
        WorkspaceCardImageItem,
    },
});
