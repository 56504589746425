import Vue from 'vue';
import ChoiceQuestionDraggableOptions from './ChoiceQuestionDraggableOptions.vue';
import { OTHER_LABEL } from '@lumiere/db/src/constants';
import { nanoid } from 'nanoid';
export default Vue.extend({
    name: 'ChoiceQuestionFeedbackOptions',
    props: {
        value: Object,
        variant: String,
    },
    data() {
        return {
            options: { ...this.$props.value },
            responseOptions: (this.$props.value?.responseOptions ??
                []),
        };
    },
    computed: {
        rules() {
            return {
                maxDescriptionLength: (value) => value.length < 500 || 'Description is too long',
                required: (value) => !!value || 'Prompt is required.',
            };
        },
    },
    methods: {
        onResponseOptionsInput(options) {
            const responseOptions = [...options];
            const otherOption = this.responseOptions.find(({ isOther }) => isOther);
            if (otherOption) {
                responseOptions.push(otherOption);
            }
            this.responseOptions = responseOptions;
        },
        onAddOtherOptionChange(e) {
            let isActive = false;
            let otherOption;
            const responseOptions = [...this.responseOptions];
            if (e) {
                isActive = true;
                // check if there's an otherOption
                const hasOtherOption = responseOptions.find((o) => o.isOther);
                if (!hasOtherOption) {
                    otherOption = {
                        id: nanoid(10),
                        label: OTHER_LABEL,
                        isOther: true,
                        isActive,
                        plus: null,
                    };
                    responseOptions.push(otherOption);
                }
            }
            const indexOfOther = responseOptions.findIndex(({ isOther }) => isOther);
            otherOption = responseOptions[indexOfOther];
            // extraneous check - done to safeguard against flacky switch state
            if (otherOption) {
                otherOption.isActive = isActive;
                responseOptions[indexOfOther] = otherOption;
            }
            this.responseOptions = responseOptions;
        },
    },
    watch: {
        options: {
            handler(options) {
                this.$emit('input', options);
            },
            deep: true,
        },
        responseOptions: {
            handler(responseOptions) {
                this.options.responseOptions = responseOptions;
            },
            immediate: true,
        },
    },
    components: {
        ChoiceQuestionDraggableOptions,
    },
});
