import Vue from 'vue';
import { ChannelVisibility, ChannelOutputsEnum, ChannelPageOptionsEnum, LinkStatus, LinkAssetType, } from '@lumiere/db/types';
import { nanoid } from 'nanoid';
import config from '@lumiere/shared/config';
import lumiereConfig from '@lumiere/config/lumiere.config.json';
import { tap, pluck, switchMap, shareReplay } from 'rxjs/operators';
import ChannelSettingsShareOptions from './ChannelSettingsShareOptions.vue';
export default Vue.extend({
    name: 'ChannelSettingsShareLink',
    props: {
        channel: {
            type: Object,
        },
    },
    data() {
        return {
            snackbar: false,
            saving: false,
            configDialog: false,
            selectedOutputs: [],
            selectedPageOptions: [],
            passwordText: '',
            colorOverride: null,
            link: null,
            showPassword: false,
            channelShareLink: false,
        };
    },
    subscriptions() {
        const shareLink$ = this.$watchAsObservable('channel.shareLink', {
            immediate: true,
            deep: true,
        }).pipe(pluck('newValue'));
        const link$ = shareLink$.pipe(tap((share) => {
            this.$data.channelShareLink = share
                ? share.status === LinkStatus.enabled
                : false;
        }), switchMap((share) => this.$db.link(share?.id ?? '-').observe()), shareReplay(1));
        return {
            link: link$.pipe(tap((data) => {
                if (data) {
                    this.$data.selectedOutputs = data.configuration?.outputs ?? [];
                    this.$data.selectedPageOptions =
                        data.configuration?.pageOptions?.options ?? [];
                    this.$data.passwordText =
                        data.configuration?.pageOptions?.password ?? '';
                    this.$data.colorOverride =
                        data.configuration?.pageOptions?.color || null;
                }
            })),
        };
    },
    computed: {
        shareLink() {
            if (!this.channel.shareLink)
                return;
            return `${config.adminAppURL}${this.channel.shareLink.path}`;
        },
        appURL() {
            return process.env.VUE_APP_PROJECT_ENV === 'lumiere-prod'
                ? lumiereConfig['lumiere-prod'].adminAppURL
                : lumiereConfig['lumiere-c3d11'].adminAppURL;
        },
        sharedOutputs() {
            return Object.values(ChannelOutputsEnum);
        },
        pageOptions() {
            return Object.values(ChannelPageOptionsEnum);
        },
    },
    methods: {
        async save(canShare) {
            const { id: channelId, visibility, workspaceId } = this.channel;
            let extraPayload = {};
            if (canShare) {
                if (visibility === ChannelVisibility.Private) {
                    const toMakePublic = await this.$confirm(`You cannot share private channel.\nDo you want to make the channel public?`, {
                        buttonTrueText: 'YES',
                        buttonFalseText: 'Dismiss',
                    });
                    if (!toMakePublic) {
                        this.channel.shareLink = undefined;
                        return;
                    }
                    extraPayload = { visibility: ChannelVisibility.Public };
                }
                const id = this.link?.id ?? nanoid(10);
                const path = `/share?link=${id}`;
                const fullPath = `/share/${workspaceId}/channels/${channelId}/insights?link=${id}`;
                this.saving = true;
                await this.$db.channel(channelId).update({
                    ...extraPayload,
                    shareLink: {
                        id,
                        path,
                        fullPath,
                        status: LinkStatus.enabled,
                    },
                });
                if (this.link) {
                    this.$db.link(id).update({
                        configuration: {
                            outputs: this.sharedOutputs,
                            pageOptions: {
                                options: this.pageOptions,
                                password: '',
                            },
                        },
                        status: 'enabled',
                    });
                }
                else {
                    this.$db.link(id).create({
                        url: this.appURL + path,
                        asset: {
                            id: this.channel.id,
                            type: LinkAssetType.channel,
                        },
                        workspaceId: this.channel.workspaceId,
                        pageSnapshot: `${this.appURL}/img/video_image_placeholder.jpg`,
                        configuration: {
                            outputs: this.sharedOutputs,
                            pageOptions: {
                                options: this.pageOptions,
                                password: '',
                            },
                        },
                        status: 'enabled',
                    });
                }
                this.saving = false;
            }
            else {
                const shareLink = this.channel.shareLink;
                if (shareLink) {
                    const { id } = shareLink;
                    await this.$db.channel(this.channel.id).update({
                        'shareLink.status': 'disabled',
                    });
                    if (id) {
                        await this.$db.link(id).update({
                            status: 'disabled',
                        });
                    }
                }
            }
        },
        onCopyClicked() {
            let textContent = document.getElementById('share-link-url')?.textContent;
            if (textContent) {
                const el = document.createElement('textarea');
                el.value = textContent.trim();
                document.body.appendChild(el);
                el.select();
                document.execCommand('copy');
                document.body.removeChild(el);
                setTimeout(() => {
                    this.snackbar = true;
                }, 250);
            }
        },
    },
    components: {
        ChannelSettingsShareOptions,
    },
});
