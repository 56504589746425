export var ChannelTileSize;
(function (ChannelTileSize) {
    ChannelTileSize["Small"] = "small";
    ChannelTileSize["Medium"] = "medium";
    ChannelTileSize["Large"] = "large";
})(ChannelTileSize || (ChannelTileSize = {}));
export var ChannelVisibility;
(function (ChannelVisibility) {
    ChannelVisibility["Public"] = "Public";
    ChannelVisibility["Private"] = "Private";
})(ChannelVisibility || (ChannelVisibility = {}));
export var ChannelStatus;
(function (ChannelStatus) {
    ChannelStatus["Active"] = "Active";
    ChannelStatus["Deleted"] = "Deleted";
})(ChannelStatus || (ChannelStatus = {}));
