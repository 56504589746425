import Vue from 'vue';
import { Role } from '@lumiere/db/types';
export default Vue.extend({
    name: 'ChangeMemberRole',
    props: {
        workspace: Object,
        user: Object,
    },
    data() {
        return {
            errors: [],
            loading: false,
            role: Role.EDITOR,
        };
    },
    mounted() {
        const id = this.user.id;
        if (id) {
            this.role = this.workspace.roles[id];
        }
    },
    computed: {
        roles() {
            return Object.values(Role);
        },
    },
    methods: {
        close() {
            this.$emit('close');
        },
        async submitForm() {
            this.errors = [];
            try {
                this.loading = true;
                await this.$db
                    .workspace(this.workspace.id)
                    .update({ [`roles.${this.user.id}`]: this.role })
                    .then(async () => {
                    const ctx = await this.$auditLogger(this.$store.state.uid);
                    if (ctx) {
                        const { Asset_Model, EVENT_ACTION, ts } = ctx.config;
                        const asset = {
                            id: this.workspace.id,
                            type: Asset_Model.Workspace,
                            name: this.workspace.name,
                        };
                        await ctx.exec(asset, {
                            timestamp: ts(),
                            action: EVENT_ACTION.ROLE_CHANGED,
                            data: {
                                type: 'INFO',
                                payload: {
                                    message: 'User role has changed',
                                    actorAssets: {
                                        type: Asset_Model.User,
                                        ids: [this.user.id],
                                    },
                                    extraInfo: { newRole: this.role },
                                },
                            },
                        });
                    }
                });
                this.close();
            }
            catch (error) {
                this.errors = [error];
            }
            this.loading = false;
        },
    },
});
