import { signatures, getUID, mapDoc, observe } from './utils';
const CGUDO = (params) => function (path) {
    const { FieldValue, dbAuth, firestore } = params;
    const ref = firestore.doc(path);
    const { created, edited } = signatures(FieldValue);
    return {
        async create(props, batch) {
            const data = {
                ...props,
                ...created(await getUID(dbAuth)),
            };
            return batch ? batch.set(ref, data) : ref.set(data);
        },
        get: async () => mapDoc(await ref.get()),
        async update(props, batch) {
            const data = {
                ...props,
                ...edited(await getUID(dbAuth)),
            };
            return batch ? batch.update(ref, data) : ref.update(data);
        },
        async delete(batch) {
            batch ? batch.delete(ref) : await ref.delete();
        },
        observe: observe(ref),
    };
};
export default CGUDO;
