import Vue from 'vue';
import { VideoState } from '../../types';
export default Vue.extend({
    name: 'PlayPauseControl',
    props: {
        state: {
            type: String,
            default: VideoState.Ready,
        },
    },
    computed: {
        VideoState() {
            return VideoState;
        },
    },
});
