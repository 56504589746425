var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"text-center term-filter-container mb-3"},[(_vm.totalInteractions == null || _vm.loading)?_c('v-responsive',{staticClass:"mx-auto my-auto",attrs:{"aspect-ratio":16 / 9,"max-height":"170"}},[_c('v-skeleton-loader',{staticClass:"mx-auto",staticStyle:{"border-radius":"8px","position":"absolute","height":"100%","width":"100%"},attrs:{"type":"image"}})],1):_c('v-card',{attrs:{"elevation":1}},[(_vm.wordCloudData.length)?_c('div',{staticClass:"wordcloud-wrapper"},[(_vm.termFilterActivated)?_c('button',{staticClass:"reset-button",on:{"click":function () { return _vm.cancelTermFilter(); }}},[_c('v-icon',[_vm._v("mdi-close")])],1):_vm._e(),_c('VueWordCloud',{staticClass:"wordcloud",attrs:{"words":_vm.wordCloudData,"color":function (ref) {
                  var weight = ref.weight;
                  var sentimentAverage = ref.sentimentAverage;

                  return _vm.color(weight, sentimentAverage);
},"fontFamily":"roboto","font-style":"normal","font-weight":"normal","font-size-ratio":5,"spacing":0.7,"animation-duration":150},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var text = ref.text;
var weight = ref.weight;
var word = ref.word;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('div',_vm._g({staticStyle:{"cursor":"pointer"},on:{"click":function($event){return _vm.wordClickHandler(text)}}},on),[_c('span',{style:({
                    fontSize: _vm.wordCloudData.length > 1 ? '1em' : '0.8em',
                  })},[_vm._v(" "+_vm._s(text)+" ")])])]}}],null,true)},[_c('span',[_vm._v(" #Mentions: "+_vm._s(weight)+" "),_c('br'),_vm._v(" Sentiment: "+_vm._s(word.sentimentAverage.toFixed(2))+" ")])])]}}],null,false,1617300204)})],1):_vm._e(),_c('div',{staticClass:"search-wrapper"},[_c('v-text-field',{staticClass:"justify-center pt-3 pb-n3",attrs:{"label":"Search","value":_vm.filterTerm,"prepend-inner-icon":"mdi-magnify","placeholder":"Filter by term","outlined":"","clearable":""},on:{"click:clear":function () { return _vm.cancelTermFilter(); }},model:{value:(_vm.search),callback:function ($$v) {_vm.search=(typeof $$v === 'string'? $$v.trim(): $$v)},expression:"search"}})],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }