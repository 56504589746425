import Vue from 'vue';
export default Vue.extend({
    name: 'EmojiSkeletonLoader',
    computed: {
        colProps() {
            return {
                cols: 4,
                sm: 3,
                md: 3,
            };
        },
    },
});
