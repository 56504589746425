import Vue from 'vue';
import { FieldValue } from '@/services/firebase/firestore';
export default Vue.extend({
    name: 'ChannelOptions',
    props: {
        channel: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            loading: false,
        };
    },
    computed: {},
    watch: {},
    methods: {
        async save(options) {
            this.loading = true;
            const keys = Object.keys(options);
            const updateObject = keys.reduce((v, key) => ({
                ...v,
                [`options.${key}`]: options[key] ?? FieldValue.delete(),
            }), {});
            await this.$db.channel(this.channel.id).update(updateObject);
            this.loading = false;
        },
    },
    subscriptions() {
        return {};
    },
    components: {},
});
