import { Severity } from '../logger';
import * as Sentry from '@sentry/browser';
const severityLevelMap = {
    [Severity.DEFAULT]: Sentry.Severity.Log,
    [Severity.DEBUG]: Sentry.Severity.Debug,
    [Severity.INFO]: Sentry.Severity.Info,
    [Severity.WARNING]: Sentry.Severity.Warning,
    [Severity.ERROR]: Sentry.Severity.Error,
    [Severity.CRITICAL]: Sentry.Severity.Critical,
};
export function SentryBrowserTransport() {
    return ({ severity, namespace, data, message }) => {
        Sentry.addBreadcrumb({
            level: severityLevelMap[severity],
            data,
            message: `[${namespace}] ${message}`,
        });
    };
}
