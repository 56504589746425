import { TeamRole } from '@lumiere/db/types';
import { LumiereModels as models } from '../constants';
/**
 *
 * @param user the user record: firebase.User | auth.UserRecord
 * @param team the team: Team
 * @param _hasClaim a Function that resolves to boolean on whether a user has a claim
 */
export default function defineAbilityForTeamAdmin(uid, team, _hasClaim) {
    return (can, _cannot) => {
        // user roles
        const userRoleInTeam = team.roles[uid];
        const isTeamAdmin = userRoleInTeam === TeamRole.ADMIN;
        if (isTeamAdmin) {
            can('manage', models);
        }
    };
}
