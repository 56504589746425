import Vue from 'vue';
import adminAPI from '@/services/adminAPI';
var ExportType;
(function (ExportType) {
    ExportType["AnalyzedText"] = "AnalyzedText";
    ExportType["Keywords"] = "Keywords";
})(ExportType || (ExportType = {}));
export default Vue.extend({
    name: 'ExternalData_DataExport',
    props: {
        videoId: {
            type: String,
            required: true,
        },
        featureId: {
            type: String,
            required: true,
        },
    },
    data() {
        return {
            isAnalyzedTextDownloading: false,
            isKeywordDownloading: false,
            snackbar: false,
            snackbarMessage: '',
            video: null,
        };
    },
    subscriptions() {
        return {
            video: this.$db.video(this.$props.videoId).observe(),
        };
    },
    computed: {
        ExportType() {
            return ExportType;
        },
        cannotExportData() {
            return this.$ability.cannot('read', 'Elasticsearch.ExportAnalyticsData');
        },
    },
    methods: {
        downloadAnalyzedTextData() {
            this.isAnalyzedTextDownloading = true;
            adminAPI(async (api) => api.video.exports.getVideoExternalData_DataExport({
                vid: this.videoId,
                fid: this.featureId,
            }))
                .then((downloadData) => {
                this.downloadCSV(downloadData.csv, this.ExportType.AnalyzedText);
            })
                .catch((err) => {
                this.snackbar = true;
                this.snackbarMessage = err.message;
            })
                .finally(() => {
                this.isAnalyzedTextDownloading = false;
            });
        },
        downloadKeywordsData() {
            this.isKeywordDownloading = true;
            adminAPI(async (api) => api.video.exports.getVideoExternalDataEntitiesDataExport({
                vid: this.videoId,
                fid: this.featureId,
            }))
                .then((downloadData) => {
                this.downloadCSV(downloadData.csv, this.ExportType.Keywords);
            })
                .catch((err) => {
                this.snackbar = true;
                this.snackbarMessage = err.message;
            })
                .finally(() => {
                this.isKeywordDownloading = false;
            });
        },
        downloadCSV(csv, exportType) {
            const type = exportType === this.ExportType.AnalyzedText ? 'comments' : 'entities';
            const videoName = this.video?.title ?? '';
            const filename = `${videoName}_${new Date().toISOString()}_${type}.csv`;
            const data = encodeURIComponent(csv);
            this.$download(data, filename, { type: 'text/plain' });
            this.snackbar = true;
            this.snackbarMessage = `${this.ExportType[exportType]} data download is complete`;
        },
    },
});
