import Vue from 'vue';
import { VideoState } from '../../types';
export default Vue.extend({
    name: 'BigPlayButton',
    props: {
        state: {
            type: String,
        },
        isBuffering: {
            type: Boolean,
        },
    },
    data() {
        return {
            readyToPlay: false,
        };
    },
    computed: {
        showSpinner() {
            return this.readyToPlay || this.isBuffering;
        },
    },
    methods: {
        click() {
            this.$emit('click');
            this.readyToPlay = true;
        },
    },
    watch: {
        state: {
            handler(_state, oldState) {
                if (oldState === VideoState.Playing) {
                    this.readyToPlay = false;
                }
            },
            immediate: true,
        },
    },
});
