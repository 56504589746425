import Vue from 'vue';
import { FeatureType, VideoOutputsEnum, VideoAIExternalStatus, VideoAIJobStatus, VideoPageOptionsEnum, } from '@lumiere/db/types';
import VideoInsightsEmoji from './emoji/VideoInsightsEmoji.vue';
import VideoInsightComment from './comment/VideoInsightComment.vue';
import VideoInsightViewerRetention from './viewer-retention/VideoInsightViewerRetention.vue';
import VideoInsightExternalData from './external-data/VideoInsightExternalData.vue';
import VideoInsightQuestion from './question/VideoInsightQuestion.vue';
import { Timestamp } from '@/services/firebase/firestore';
import isEqual from 'lodash/isEqual';
import isEmpty from 'lodash/isEmpty';
import { mapGetters, mapState } from 'vuex';
const configOutputsMap = {
    [VideoOutputsEnum.RETENTION]: FeatureType.Retention,
    [VideoOutputsEnum.COMMENT_PLUS]: FeatureType.Comments,
    [VideoOutputsEnum.EMOJIS]: FeatureType.Emojis,
    [VideoOutputsEnum.EXTERNAL_DATA]: FeatureType.ExternalData,
    [VideoOutputsEnum.QUESTION]: FeatureType.Question,
};
const VIEWER_RETENTION_ID = 'viewer-retention';
export default Vue.extend({
    name: 'VideoInsights',
    data() {
        return {
            selectedFeature: null,
            loading: false,
            showInsightsType: null,
            addFeatureDialog: false,
            queryFeatureId: null,
            currentQuery: null,
            unwatchField: null,
        };
    },
    props: {
        video: {
            type: Object,
            required: true,
        },
        features: {
            type: Array,
        },
        showTabComponent: {
            type: Function,
            required: true,
        },
    },
    computed: {
        ...mapState(['link']),
        ...mapGetters(['userRoleInWorkspace']),
        FeatureType() {
            return FeatureType;
        },
        queryParamFeature() {
            if (this.features?.length &&
                this.queryFeatureId !== VIEWER_RETENTION_ID) {
                if (this.queryFeatureId) {
                    const queryFeature = this.features.find((el) => el.id === this.queryFeatureId);
                    if (queryFeature) {
                        return queryFeature; // selected feature is coming from the browser query
                    }
                }
                return this.features[0];
            }
            return this.viewerRetention;
        },
        viewerRetention() {
            return {
                id: VIEWER_RETENTION_ID,
                type: FeatureType.Retention,
                label: 'Retention',
                enabled: true,
                triggers: [],
                stoppers: [],
                options: {},
                createdBy: this.$store.state.uid,
                editedBy: this.$store.state.uid,
                createdTime: Timestamp.now(),
                editedTime: Timestamp.now(),
            };
        },
        isPublic() {
            return !!this.$route.query.link;
        },
        withDefaultFeature() {
            const filteredFeatures = this.features
                ? this.features.filter((f) => f.type !== FeatureType.TextPrompt)
                : [];
            let features = [...filteredFeatures, this.viewerRetention];
            if (this.userRoleInWorkspace) {
                return features;
            }
            if (this.isPublic && this.link) {
                const configOutputs = this.link.configuration?.outputs;
                if (configOutputs) {
                    const outputsToShow = configOutputs
                        .map((i) => configOutputsMap[i])
                        .filter(Boolean);
                    return features.filter((f) => outputsToShow.includes(f.type));
                }
            }
            return [];
        },
        isDecodedVideoReady() {
            const { video } = this;
            if (!video?.videoAI)
                return false;
            return (video.videoAI.state === VideoAIJobStatus.PROCESSED &&
                video.videoAI.externalState === VideoAIExternalStatus.INGESTED);
        },
        showFacesOutput() {
            let showFaces = false;
            if (this.userRoleInWorkspace) {
                showFaces = true;
            }
            if (this.isPublic && this.link) {
                const configOptions = this.link.configuration?.pageOptions?.options;
                if (configOptions) {
                    showFaces = configOptions.includes(VideoPageOptionsEnum.SHOW_FACES_OUTPUT);
                }
            }
            return showFaces && this.isDecodedVideoReady;
        },
    },
    methods: {
        updateRouteQuery(update, reset = false) {
            let newQuery = {
                ...this.currentQuery,
                ...this.$route.query,
                ...update,
            };
            if (reset) {
                newQuery = { ...update };
            }
            const link = this.$route.query.link;
            if (link) {
                newQuery.link = link;
            }
            newQuery = Object.entries(newQuery).reduce((a, [k, v]) => (v == null ? a : ((a[k] = String(v)), a)), {});
            if (!isEmpty(newQuery) && !isEqual(this.currentQuery, newQuery)) {
                void this.$router.replace({
                    query: {
                        ...newQuery,
                    },
                });
                this.currentQuery = newQuery;
            }
        },
        computeDefaultFeatures() {
            this.unwatchField = this.$watch('withDefaultFeature', (features) => {
                if (features.length && !this.userRoleInWorkspace) {
                    if (!this.selectedFeature) {
                        const queryFeature = this.queryParamFeature;
                        // is valid query feature
                        const isValidQF = features.find((f) => f.id === queryFeature.id);
                        if (isValidQF) {
                            this.selectedFeature = queryFeature;
                        }
                        else {
                            this.selectedFeature = features[0];
                        }
                        this.updateRouteQuery({ feature: this.selectedFeature.id });
                    }
                    if (this.unwatchField) {
                        this.unwatchField();
                    }
                }
            }, {
                immediate: true,
            });
        },
        onSelectFeature(_feature) {
            this.$events.emit('resetVideoFacesFilter');
        },
    },
    beforeRouteEnter(_to, _from, next) {
        next((vm) => {
            const query = vm.$route.query;
            const { feature, link, filterTerm, cid, min, max, rMin, rMax, ems } = query;
            const qp = {
                feature,
                link,
                filterTerm,
                cid,
                min,
                max,
                rMin,
                rMax,
                ems,
            };
            const urlQuery = Object.keys(qp)
                .filter((key) => !!qp[key])
                .map((key) => {
                return `${encodeURIComponent(key)}=${encodeURIComponent(qp[key.toString()])}`;
            })
                .join('&');
            next(); //vm.$route.path)
            if (feature) {
                history.replaceState({}, '', `${vm.$route.path}?${urlQuery}`);
            }
        });
    },
    watch: {
        selectedFeature: {
            handler(newVal, oldVal) {
                if (newVal) {
                    this.showInsightsType = null;
                    this.$nextTick(() => {
                        this.showInsightsType = newVal.type;
                    });
                    const fid = this.$route.query.feature;
                    if (newVal.id !== fid) {
                        const resetFilterTermToEmpty = oldVal &&
                            (oldVal.type == FeatureType.Comments ||
                                oldVal.type == FeatureType.ExternalData);
                        if (resetFilterTermToEmpty) {
                            this.updateRouteQuery({ feature: newVal.id }, true);
                        }
                        else {
                            this.updateRouteQuery({ feature: newVal.id });
                        }
                    }
                    this.$events.emit('resetVideoSceneFilter');
                }
            },
            immediate: true,
        },
        queryParamFeature: {
            handler(newVal) {
                const allFeatures = this.withDefaultFeature;
                const isValidFeature = allFeatures.find((f) => f.id === newVal.id);
                if (isValidFeature) {
                    this.selectedFeature = newVal;
                }
                else if (allFeatures?.length) {
                    this.selectedFeature = allFeatures[0];
                }
            },
            immediate: true,
        },
    },
    created() {
        const currentQuery = this.$route.query;
        delete currentQuery.vctime;
        delete currentQuery.commentId;
        this.currentQuery = currentQuery;
        this.queryFeatureId = this.$route.query.feature;
        this.computeDefaultFeatures();
    },
    events: {
        chartTapped(params) {
            let { vctime } = params;
            this.updateRouteQuery({ vctime });
        },
    },
    components: {
        VideoInsightsEmoji,
        VideoInsightComment,
        VideoInsightViewerRetention,
        VideoInsightExternalData,
        VideoInsightQuestion,
    },
});
