import storage from 'local-storage-fallback';
function locallyStored(KEY) {
    return {
        get() {
            const locallyStoredItem = storage.getItem(KEY);
            if (locallyStoredItem) {
                try {
                    const { value } = JSON.parse(locallyStoredItem);
                    return value;
                }
                catch {
                    return locallyStoredItem;
                }
            }
        },
        set(value) {
            storage.setItem(KEY, JSON.stringify({ value }));
        },
        remove: () => {
            storage.removeItem(KEY);
        },
    };
}
export default locallyStored;
