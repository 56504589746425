import Vue from 'vue';
import EditTagForm from './ManageTagsEditTagForm.vue';
import EditableTagItem from './ManageTagsEditableTagItem.vue';
import { mapGetters } from 'vuex';
export default Vue.extend({
    name: 'VideosFiltersManageTagsBtn',
    props: {
        workspace: Object,
        addFirstTag: [Object, Boolean],
    },
    data() {
        return {
            showAddTagForm: false,
            dialog: false,
            loading: false,
        };
    },
    computed: {
        ...mapGetters(['workspaceTags']),
    },
    watch: {
        workspaceTags() {
            this.loading = false;
        },
        addFirstTag() {
            this.$nextTick(() => {
                this.dialog = true;
            });
        },
    },
    methods: {},
    components: {
        EditTagForm,
        EditableTagItem,
    },
});
