import Vue from 'vue';
import { mapGetters, mapState } from 'vuex';
import { Role } from '@lumiere/db/types';
import TeamSelectDialog from '@/components/team/TeamSelectDialog.vue';
import WorkspaceListItems from '@/components/workspace/WorkspaceListItems.vue';
import RestoreRecentlyDeletedWorkspaces from '@/components/workspace/RestoreRecentlyDeletedWorkspaces.vue';
import debounce from 'lodash/debounce';
import { startWith } from 'rxjs/operators';
const MAX_NUMBER_OF_WORKSPACES_IN_SELECT = 5;
export default Vue.extend({
    name: 'WorkspacesSelectDialog',
    data() {
        return {
            teamId: null,
            tab: null,
            activeTab: null,
            selectedTeam: null,
            search: '',
            searchQuery: '',
            deletedWorkspaces: [],
        };
    },
    computed: {
        ...mapGetters([
            'workspacesList',
            'sortedRecentWorkspaces',
            'currentWorkspace',
        ]),
        ...mapState(['uid']),
        workspaces() {
            return this.teamId
                ? this.workspacesList?.filter(({ team }) => this.teamId === 'personal' ? !team : team?.id === this.teamId) ?? null
                : this.workspacesList;
        },
        cappedRecentWorkspaces() {
            return this.sortedRecentWorkspaces.slice(0, MAX_NUMBER_OF_WORKSPACES_IN_SELECT);
        },
        hasFilters() {
            return !!this.selectedTeam || !!this.searchQuery;
        },
    },
    subscriptions() {
        const { uid } = this.$store.state;
        return {
            deletedWorkspaces: this.$db
                .workspaces((ref) => ref
                .where(`roles.${uid}`, '==', Role.OWNER)
                .where('deleted', '==', true))
                .observe()
                .pipe(startWith([])),
        };
    },
    methods: {
        onClose() {
            this.$emit('close');
        },
        async storeAuditLog() {
            const ctx = await this.$auditLogger(this.$store.state.uid);
            if (ctx) {
                const { Asset_Model, EVENT_ACTION, ts } = ctx.config;
                const asset = {
                    id: this.currentWorkspace.id,
                    type: Asset_Model.Workspace,
                    name: this.currentWorkspace.name,
                };
                await ctx.exec(asset, {
                    timestamp: ts(),
                    action: EVENT_ACTION.VIEWED,
                    data: {
                        type: 'INFO',
                        payload: {
                            message: 'Workspace selector opened',
                            actorAssets: {
                                type: Asset_Model.Workspace,
                                ids: this.cappedRecentWorkspaces.map(({ id }) => id),
                            },
                        },
                    },
                });
            }
        },
    },
    mounted() {
        void this.storeAuditLog();
    },
    watch: {
        search: debounce(function (search) {
            this.searchQuery = search;
        }, 300),
    },
    components: {
        TeamSelectDialog,
        WorkspaceListItems,
        RestoreRecentlyDeletedWorkspaces,
    },
});
