import Vue from 'vue';
import Router from 'vue-router';
import Landing from '@/views/Landing.vue';
import NotFound from '@/views/NotFound.vue';
import Share from '@/views/Share.vue';
import Workspace from '@/views/workspace/WorkspaceNext.vue';
// import WorkspaceOverview from '@/views/workspace/Overview.vue'
import WorkspaceSettings from '@/views/workspace/WorkspaceSettings.vue';
import WorkspaceVideos from '@/views/workspace/Videos.vue';
import WorkspaceDashboards from '@/views/workspace/Dashboards.vue';
import WorkspaceChannels from '@/views/workspace/Channels.vue';
import Team from '@/views/team/Team.vue';
import Account from '@/views/user/UserAccount.vue';
import Video from '@/views/workspace/video/Video.vue';
import VideoFeatures from '@/views/workspace/video/VideoFeatures.vue';
import VideoInsights from '@/views/workspace/video/insights/VideoInsights.vue';
import Channel from '@/views/workspace/channel/Channel.vue';
import SimulateError from '@/views/SimulateError.vue';
import EmailActionHandlers from '@/views/auth/EmailActionHandlers.vue';
import JoinForm from '@/views/auth/JoinForm.vue';
Vue.use(Router);
export var Routes;
(function (Routes) {
    Routes["Landing"] = "Landing";
    Routes["Login"] = "Login";
    Routes["Workspace"] = "Workspace";
    Routes["Home"] = "Home";
    Routes["Videos"] = "Videos";
    Routes["Dashboards"] = "Dashboards";
    Routes["Channels"] = "Channels";
    Routes["Share"] = "Share";
    Routes["WorkspaceSettings"] = "WorkspaceSettings";
    Routes["Team"] = "Team";
    Routes["Account"] = "Account";
    Routes["EmailActionHandlers"] = "EmailActionHandlers";
    Routes["Join"] = "JoinForm";
    Routes["Invitation"] = "Invitation";
})(Routes || (Routes = {}));
export var VideoRoutes;
(function (VideoRoutes) {
    VideoRoutes["Features"] = "Features";
    VideoRoutes["Insights"] = "Insights";
})(VideoRoutes || (VideoRoutes = {}));
export var ChannelRoutes;
(function (ChannelRoutes) {
    ChannelRoutes["Default"] = "ChannelDefault";
})(ChannelRoutes || (ChannelRoutes = {}));
export var ShareRoutes;
(function (ShareRoutes) {
    ShareRoutes["ShareInsights"] = "ShareInsights";
    ShareRoutes["ShareChannelDefault"] = "ShareChannelDefault";
})(ShareRoutes || (ShareRoutes = {}));
export default function createRouter(store) {
    const router = new Router({
        mode: 'history',
        base: process.env.BASE_URL,
        routes: [
            {
                path: '/',
                component: Landing,
                props: true,
                name: Routes.Landing,
                meta: {
                    requiresAuth: false,
                },
            },
            {
                path: '/login',
                component: Landing,
                props: true,
                name: Routes.Login,
                meta: {
                    requiresAuth: false,
                },
            },
            {
                path: '/simulate-error',
                component: SimulateError,
                props: true,
                name: 'SimulateError',
                meta: {
                    requiresAuth: false,
                },
            },
            {
                path: '/auth/action',
                component: EmailActionHandlers,
                props: true,
                name: Routes.EmailActionHandlers,
                meta: {
                    requiresAuth: false,
                },
            },
            {
                path: '/join',
                component: JoinForm,
                props: true,
                name: Routes.Join,
                meta: {
                    requiresAuth: false,
                },
            },
            {
                path: '/invitation/:invitationId',
                props: true,
                name: Routes.Invitation,
                meta: {
                    requiresAuth: false,
                },
                redirect: (_to) => {
                    if (window.INVITATION_ID) {
                        return {
                            name: Routes.Join,
                            params: {
                                email: window.INVITED_USER_EMAIL ?? '',
                                invitationId: window.INVITATION_ID,
                                invitationValidity: window.INVITATION_VALIDITY ?? '',
                            },
                        };
                    }
                    return '/login';
                },
            },
            {
                path: '/share',
                component: Share,
                props: true,
                meta: {
                    requiresAuth: false,
                },
                redirect: (_to) => {
                    if (window.SHARE_PATH) {
                        return window.SHARE_PATH;
                    }
                    return '/';
                },
                children: [
                    {
                        path: ':workspaceId',
                        component: Workspace,
                        props: true,
                        meta: {
                            requiresAuth: false,
                        },
                        children: [
                            {
                                path: 'videos/:videoId',
                                props: true,
                                component: Video,
                                meta: {
                                    requiresAuth: false,
                                },
                                children: [
                                    {
                                        path: '',
                                        redirect: 'insights',
                                        props: true,
                                    },
                                    {
                                        name: ShareRoutes.ShareInsights,
                                        path: 'insights',
                                        props: true,
                                        component: VideoInsights,
                                        meta: {
                                            requiresAuth: false,
                                        },
                                    },
                                ],
                            },
                            {
                                name: ShareRoutes.ShareChannelDefault,
                                path: 'channels/:channelId',
                                props: true,
                                component: Channel,
                                meta: {
                                    requiresAuth: false,
                                },
                            },
                        ],
                    },
                ],
            },
            {
                path: '/account',
                name: Routes.Account,
                component: Account,
            },
            {
                path: '/teams',
                // name: Routes.Team,
                props: true,
                component: Team,
                redirect: (_to) => '/',
                children: [
                    {
                        path: ':teamId',
                        name: Routes.Team,
                        props: true,
                        component: Team,
                    },
                ],
            },
            {
                path: '/:workspaceId',
                component: Workspace,
                props: true,
                children: [
                    {
                        path: '',
                        name: Routes.Workspace,
                        props: true,
                        // component: WorkspaceOverview,
                        redirect: 'videos',
                    },
                    {
                        path: 'settings',
                        name: Routes.WorkspaceSettings,
                        props: true,
                        component: WorkspaceSettings,
                    },
                    {
                        path: 'dashboards',
                        name: Routes.Dashboards,
                        props: true,
                        component: WorkspaceDashboards,
                    },
                    {
                        path: 'videos',
                        name: Routes.Videos,
                        props: true,
                        component: WorkspaceVideos,
                    },
                    {
                        path: 'videos/:videoId',
                        props: true,
                        component: Video,
                        children: [
                            {
                                path: '',
                                redirect: 'insights',
                                props: true,
                            },
                            {
                                name: VideoRoutes.Insights,
                                path: 'insights',
                                props: true,
                                component: VideoInsights,
                            },
                            {
                                name: VideoRoutes.Features,
                                path: 'features',
                                props: true,
                                component: VideoFeatures,
                            },
                        ],
                    },
                    {
                        path: 'channels',
                        name: Routes.Channels,
                        props: true,
                        component: WorkspaceChannels,
                    },
                    {
                        path: 'channels/:channelId',
                        props: true,
                        component: Channel,
                        name: ChannelRoutes.Default,
                    },
                ],
            },
            {
                path: '*',
                name: '404',
                component: NotFound,
            },
        ],
    });
    router.beforeEach((to, _from, next) => {
        return to.matched.some((record) => record.meta.requiresAuth !== false)
            ? !store.state.uid
                ? next({ path: '/', query: { redirect: to.fullPath } })
                : next()
            : next();
    });
    return router;
}
