// type Severity = 'default' | 'debug' | 'info' | 'warn' | 'error' | 'alert'
export var Severity;
(function (Severity) {
    Severity["DEFAULT"] = "DEFAULT";
    Severity["DEBUG"] = "DEBUG";
    Severity["INFO"] = "INFO";
    Severity["WARNING"] = "WARNING";
    Severity["ERROR"] = "ERROR";
    Severity["CRITICAL"] = "CRITICAL";
})(Severity || (Severity = {}));
function createServerLogger(baseNamespace = '', baseContext = () => ({}), baseTransports = () => [], baseEnabled = () => true) {
    let initContext = {};
    let namespace = baseNamespace;
    let transports = baseTransports();
    let context = {};
    let enabled = true;
    const isEnabled = () => enabled && baseEnabled();
    const post = (severity) => (message, data) => {
        if (isEnabled()) {
            transports.forEach(async (transport) => transport({
                namespace,
                context: { ...baseContext(), ...context, ...initContext },
                severity,
                data,
                message,
                timestamp: new Date(),
            }));
        }
    };
    return {
        log: post(Severity.DEFAULT),
        /**
         * Fine-grained informational events useful for debugging
         */
        debug: post(Severity.DEBUG),
        /**
         * Informational messages that highlight the progress of the app at coarse-grained level
         */
        info: post(Severity.INFO),
        /**
         * Highlight potentially harmful situations
         */
        warn: post(Severity.WARNING),
        /**
         * Error events are likely to cause problems
         */
        error: post(Severity.ERROR),
        /**
         * A person must take action immediately
         */
        alert: post(Severity.CRITICAL),
        async timePromise(key, p, data) {
            return p.finally(this.time(key, data));
        },
        time(key, startData) {
            this.info(`⏱ ${key}`, startData);
            const startTime = Date.now();
            return (endData) => {
                const time = Date.now() - startTime;
                this.info(`🏁 ${key} ${time}ms`, endData);
            };
        },
        extend(extendNamespace) {
            return createServerLogger(namespace ? [namespace, extendNamespace].join(':') : extendNamespace, () => ({ ...baseContext(), ...context, ...initContext }), () => transports, () => isEnabled());
        },
        addContext(extraContext) {
            Object.assign(context, extraContext);
            return this;
        },
        setContext(newContext) {
            context = { ...newContext };
            return this;
        },
        clearContext() {
            context = {};
            return this;
        },
        disable() {
            enabled = false;
        },
        enable() {
            enabled = true;
        },
        isEnabled,
        logMethodCalls(obj) {
            if (isEnabled()) {
                const mappedObj = {};
                for (const key in obj) {
                    Object.assign(mappedObj, {
                        [key]: (...args) => {
                            this.debug(`=> ${key}`, args);
                            const result = obj[key].apply(mappedObj, args);
                            this.debug(`<= ${key}`, result);
                            return result;
                        },
                    });
                }
                return mappedObj;
            }
            else {
                return obj;
            }
        },
        init(initOptions) {
            enabled = initOptions.enabled;
            namespace = initOptions.namespace;
            initContext = initOptions.context;
            if (initOptions.transports) {
                transports = initOptions.transports;
            }
        },
    };
}
const logger = createServerLogger();
export default logger;
