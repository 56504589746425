import Vue from 'vue';
import { Routes } from '@/router/router';
export default Vue.extend({
    name: 'VideoSettingsDeleteVideoBtn',
    props: {
        video: {
            type: Object,
            required: true,
        },
    },
    methods: {
        async deleteVideo() {
            const toConfirm = await this.$confirm(`Delete '${this.video.title}'?`, {
                buttonTrueText: 'Delete',
                buttonFalseText: 'Dismiss',
            });
            if (toConfirm) {
                await this.$db
                    .video(this.video.id)
                    .softDelete()
                    .then(async () => {
                    const ctx = await this.$auditLogger(this.$store.state.uid);
                    if (ctx) {
                        const { Asset_Model, EVENT_ACTION, ts } = ctx.config;
                        const asset = {
                            id: this.video.id,
                            type: Asset_Model.Video,
                            name: this.video.title,
                        };
                        await ctx.exec(asset, {
                            timestamp: ts(),
                            action: EVENT_ACTION.SOFT_DELETED,
                            data: {
                                type: 'INFO',
                                payload: {
                                    message: `Video has been soft deleted`,
                                },
                            },
                        });
                    }
                });
                void this.$router.push({
                    name: Routes.Videos,
                    params: { workspaceId: this.$store.getters.currentWorkspace.id },
                });
            }
        },
    },
});
