import Vue from 'vue';
import { FirebaseAuth } from '@/services/firebase/auth';
import logger from '@lumiere/shared/services/logger';
import { InvitationValidity } from '@lumiere/db/types';
import { Routes } from '@/router/router';
import adminAPI from '@/services/adminAPI';
const auth = FirebaseAuth();
export default Vue.extend({
    name: 'UpdateUserInfoForm',
    props: {
        email: String,
        invitationId: String,
        invitationValidity: String,
    },
    data() {
        return {
            form: false,
            fname: '',
            lname: '',
            loading: false,
            hasError: false,
            errorMessage: '',
            passwordDetails: {
                newPassword: '',
                confirmPassword: '',
            },
            revealNewPassword: false,
            revealConfirmPassword: false,
            validatePasswordError: [],
            waitingToRedirect: false,
        };
    },
    computed: {
        disableSubmitBtn() {
            const { newPassword, confirmPassword } = this.passwordDetails;
            return (!(newPassword && confirmPassword) || newPassword !== confirmPassword);
        },
        isInvitationValid() {
            return this.invitationValidity === InvitationValidity.VALID;
        },
        Routes() {
            return Routes;
        },
    },
    methods: {
        async submit() {
            this.loading = true;
            const { newPassword } = this.passwordDetails;
            const { email, fname, lname } = this;
            try {
                // update and link the user password
                await adminAPI(async (api) => api.user.updateUserAuthRecord({
                    email: email,
                    update: {
                        password: newPassword,
                        displayName: `${fname} ${lname}`,
                    },
                })).catch((e) => {
                    throw e;
                });
                // sign-in again with the new password
                const user = await auth
                    .signInWithEmailAndPassword(this.email, newPassword)
                    .then(({ user }) => user)
                    .catch((e) => {
                    throw e;
                });
                logger.info('Password linking success', { user });
                if (this.invitationId) {
                    // update the invitation collection
                    adminAPI(async (api) => api.user.updateInvitation({
                        invitationId: this.invitationId,
                        update: {
                            validity: InvitationValidity.INVALID,
                        },
                    }));
                }
                this.waitingToRedirect = true;
                this.$emit('update-user-info-success', { user });
            }
            catch (e) {
                this.hasError = true;
                this.errorMessage = e.message;
                logger.error('Password linking error', e);
            }
            this.loading = false;
        },
        gotoLogin() {
            this.$router.push({
                name: Routes.Login,
            });
        },
    },
    watch: {
        isInvitationValid: {
            handler(isValid) {
                if (!isValid && this.invitationId) {
                    this.hasError = true;
                    this.errorMessage = 'The link you used has expired';
                }
                else {
                    this.hasError = false;
                    this.errorMessage = '';
                }
            },
            immediate: true,
        },
        passwordDetails: {
            handler(details) {
                const { newPassword, confirmPassword } = details;
                if (confirmPassword.length && newPassword.length) {
                    if (newPassword !== confirmPassword) {
                        this.validatePasswordError = ['Password mismatch'];
                    }
                    else {
                        this.validatePasswordError = [];
                    }
                }
            },
            deep: true,
        },
    },
    beforeRouteLeave(_to, _from, next) {
        this.waitingToRedirect = false;
        next();
    },
});
