import { render, staticRenderFns } from "./VideoPlayerWatermark.vue?vue&type=template&id=f39bbdba&"
import script from "./VideoPlayerWatermark.vue?vue&type=script&lang=ts&"
export * from "./VideoPlayerWatermark.vue?vue&type=script&lang=ts&"
import style0 from "./VideoPlayerWatermark.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/.pnpm/vue-loader@15.9.7_fb85a9817f99bdf862e5e3a442a1fb91/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports