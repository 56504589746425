import Vue from 'vue';
import VideoPlayerPointPicker from '@/components/common/VideoPlayerPointPicker.vue';
import { VideoTriggerEvent, FeatureTriggerType, } from '@lumiere/db/types';
export default Vue.extend({
    name: 'QuestionFeatureTriggers',
    props: {
        video: {
            type: Object,
        },
        triggers: {
            type: Array,
        },
        stoppers: {
            type: Array,
        },
        featureId: {
            type: [String, Object],
        },
    },
    data() {
        return {
            currentTime: 0,
            questionPrompt: FeatureTriggerType.VideoEvent,
            selectedFeature: null,
            preselectedPoint: 0,
        };
    },
    computed: {
        FeatureTriggerType() {
            return FeatureTriggerType;
        },
        showAtSpecificTime() {
            return this.questionPrompt === FeatureTriggerType.VideoEvent;
        },
    },
    methods: {
        dispatchInput() {
            const payload = {
                triggers: [
                    {
                        event: VideoTriggerEvent.VideoTime,
                        type: FeatureTriggerType.VideoEvent,
                        parameters: { time: this.currentTime },
                    },
                ],
                stoppers: [],
            };
            this.$emit('dispatch-input', payload);
        },
    },
    watch: {
        triggers: {
            handler(t) {
                const trigger = t[0];
                if (trigger?.type === FeatureTriggerType.VideoEvent) {
                    this.preselectedPoint = trigger.parameters?.time ?? 0;
                }
                this.dispatchInput();
            },
            immediate: true,
        },
        preselectedPoint: {
            handler(t) {
                this.$nextTick(() => {
                    if (t) {
                        this.currentTime = t;
                    }
                });
            },
            immediate: true,
        },
        currentTime: {
            handler() {
                this.dispatchInput();
            },
        },
    },
    components: {
        VideoPlayerPointPicker,
    },
});
