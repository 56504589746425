import Vue from 'vue';
import Layout from '@/components/Layout.vue';
import { mapState } from 'vuex';
import UserProfile from './UserProfile.vue';
import UserLoginMethods from './UserLoginMethods.vue';
import UserTeams from './UserTeams.vue';
import UserCreatorStats from './UserCreatorStats.vue';
import UserViewerStats from './UserViewerStats.vue';
export default Vue.extend({
    name: 'Account',
    props: {},
    data() {
        return {};
    },
    computed: {
        ...mapState(['user']),
    },
    watch: {},
    methods: {},
    subscriptions() {
        return {};
    },
    components: {
        Layout,
        UserProfile,
        UserLoginMethods,
        UserTeams,
        UserCreatorStats,
        UserViewerStats,
    },
});
