import Confirm from './Confirm.vue';
function install(vue, options = {}) {
    const property = options.property || '$confirm';
    delete options.property;
    const vuetify = options.vuetify;
    delete options.vuetify;
    if (!vuetify) {
        console.warn('Module vuetify-confirm needs vuetify instance. Use Vue.use(VuetifyConfirm, { vuetify })');
    }
    const Ctor = vue.extend(Object.assign({ vuetify }, Confirm));
    async function createDialogCmp(options) {
        const container = document.querySelector('[data-app=true]') || document.body;
        return new Promise((resolve) => {
            const cmp = new Ctor(Object.assign({}, {
                propsData: Object.assign({}, vue.prototype[property].options, options),
                destroyed: () => {
                    container.removeChild(cmp.$el);
                    resolve(cmp.value);
                },
            }));
            container.appendChild(cmp.$mount().$el);
        });
    }
    async function show(message, options = {}) {
        options.message = message;
        return createDialogCmp(options);
    }
    vue.prototype[property] = show;
    vue.prototype[property].options = options || {};
}
const $confirm = {
    install,
};
export default $confirm;
