import Vue from 'vue';
export default Vue.extend({
    name: 'VideoSceneWizard',
    props: {
        video: Object,
        updating: Boolean,
        value: String,
    },
    data() {
        return {
            title: this.$props.value,
        };
    },
    watch: {
        title(text) {
            this.$emit('input', text);
        },
    },
});
