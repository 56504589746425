import Vue from 'vue';
export default Vue.extend({
    name: 'ChannelTitle',
    props: {
        channel: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            editing: false,
        };
    },
    computed: {
        inputRef() {
            return this.$refs.input;
        },
        editable() {
            return true;
        },
    },
    watch: {},
    methods: {
        updateChannelTitle(title) {
            if (title !== this.channel.title) {
                this.$db.channel(this.channel.id).update({ title });
            }
            this.editing = false;
        },
        editTitle() {
            this.editing = true;
            this.$nextTick(() => {
                this.inputRef.focus();
            });
        },
    },
});
