import { render, staticRenderFns } from "./GradientBG.vue?vue&type=template&id=10eb2334&scoped=true&"
var script = {}
import style0 from "./GradientBG.vue?vue&type=style&index=0&id=10eb2334&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/.pnpm/vue-loader@15.9.7_fb85a9817f99bdf862e5e3a442a1fb91/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "10eb2334",
  null
  
)

export default component.exports