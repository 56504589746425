import Vue from 'vue';
import { VideoRoutes } from '@/router/router';
import GlobalSearchVideoPreviewCard from './GlobalSearchVideoPreviewCard.vue';
export default Vue.extend({
    name: 'GlobalSearchVideos',
    props: {
        videos: Array,
    },
    methods: {
        goToVideoPage(video) {
            if (this.$ability.can('read', 'Video')) {
                this.$router.push({
                    name: VideoRoutes.Insights,
                    params: {
                        videoId: video.id,
                        videoProp: video,
                        workspaceId: video.workspaceId,
                    },
                });
            }
            else {
                location.href = this.$store.getters.videoURL(video.id);
            }
            this.$sleep(0).then(() => {
                this.$emit('close-menu');
            });
        },
    },
    components: {
        GlobalSearchVideoPreviewCard,
    },
});
