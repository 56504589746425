import Vue from 'vue';
import debounce from 'lodash/debounce';
import { CornerPosition, } from '@lumiere/db/types';
import CommentFeedback from './CommentFeedback.vue';
import CommentFeedbackLabel from './CommentFeedbackLabel.vue';
import TaggingArea from './TaggingArea.vue';
const DENSITY_BREAKPOINT = { WIDTH: 800, HEIGHT: 600 };
const FULLSCREEN_BREAKPOINT = { WIDTH: 350, HEIGHT: 250 };
var UIDensity;
(function (UIDensity) {
    UIDensity["normal"] = "normal";
    UIDensity["dense"] = "dense";
})(UIDensity || (UIDensity = {}));
export default Vue.extend({
    props: {
        features: {
            type: Array,
            required: true,
        },
        getTime: Function,
        container: Object,
        overlay: Object,
        featuresZone: Object,
        videoState: String,
    },
    data() {
        return {
            taggingMode: false,
            pointOfContact: null,
            spatialTaggingPosition: null,
            selectedSpatialTaggingFeatureId: null,
        };
    },
    computed: {
        fullscreen() {
            return (this.featuresZone.width < FULLSCREEN_BREAKPOINT.WIDTH ||
                this.featuresZone.height < FULLSCREEN_BREAKPOINT.HEIGHT);
        },
        density() {
            return this.container.width > DENSITY_BREAKPOINT.WIDTH &&
                this.container.height > DENSITY_BREAKPOINT.HEIGHT
                ? UIDensity.normal
                : UIDensity.dense;
        },
        dense() {
            return this.density === UIDensity.dense;
        },
        commentFeedbacksRefs() {
            return this.$refs.commentFeedbacks;
        },
        taggingAreaRef() {
            return this.$refs.taggingArea;
        },
        spatialTaggingActivated() {
            return this.spatialTaggingPosition !== null;
        },
        feedbackPosition() {
            if (!this.spatialTaggingPosition || this.fullscreen)
                return null;
            return this.spatialTaggingPosition.locationY > 0.5
                ? CornerPosition.BottomCenter
                : CornerPosition.TopCenter;
        },
        spatialTaggingEnabledFeatures() {
            return this.features.filter(({ options }) => options?.feedbackOptions?.spacialTagging === true);
        },
        spatialTaggingEnabled() {
            return this.spatialTaggingEnabledFeatures.length > 0;
        },
        showListOfSpatialTaggingFeatures() {
            return (this.spatialTaggingActivated &&
                this.selectedSpatialTaggingFeatureId === null);
        },
    },
    watch: {
        taggingMode(taggingMode) {
            this.$emit('tagging-mode', taggingMode);
        },
    },
    methods: {
        getSelectedCommentFeedbackRef(fid) {
            return this.commentFeedbacksRefs.find(({ $attrs }) => $attrs.id === fid);
        },
        hideSpatialTaggingFeature(fid) {
            const ref = this.getSelectedCommentFeedbackRef(fid);
            if (ref) {
                ref.toggleForm(false);
            }
        },
        showSpatialTaggedFeature(fid) {
            this.selectedSpatialTaggingFeatureId = fid;
            const selectedCommentFeedbackRef = this.getSelectedCommentFeedbackRef(fid);
            if (selectedCommentFeedbackRef) {
                this.$nextTick(() => {
                    selectedCommentFeedbackRef.toggleForm(true);
                    if (!this.fullscreen) {
                        selectedCommentFeedbackRef.getPointOfContact(this.overlay, (pointOfContact) => {
                            this.pointOfContact = pointOfContact;
                        });
                    }
                });
            }
        },
        activateFeedback({ x, y }) {
            this.spatialTaggingPosition = { locationX: x, locationY: y };
            if (this.spatialTaggingEnabledFeatures.length === 1) {
                this.showSpatialTaggedFeature(this.spatialTaggingEnabledFeatures[0].id);
            }
        },
        deactivateSpatialTagging() {
            this.unsetTagLocation();
            this.selectedSpatialTaggingFeatureId = null;
        },
        unsetTagLocation() {
            this.spatialTaggingPosition = null;
            this.pointOfContact = null;
            this.taggingAreaRef.unfix();
        },
        getPrompt(options) {
            return options?.prompt;
        },
        onCommentBoxToggle(feature, state) {
            if (state === false && this.spatialTaggingEnabled) {
                this.deactivateSpatialTagging();
            }
            this.$emit('comment-box-toggle', feature.id, state);
        },
        onTaggingMode(taggingMode) {
            this.taggingMode = taggingMode;
        },
        onFeedback(fid, payload) {
            this.$emit('feedback', {
                type: 'comment',
                fid,
                payload: {
                    ...payload,
                    ...(this.spatialTaggingPosition ?? {}),
                },
            });
        },
        updatePointOfContactPosition: debounce(function () {
            if (this.selectedSpatialTaggingFeatureId) {
                this.showSpatialTaggedFeature(this.selectedSpatialTaggingFeatureId);
            }
        }, 100),
    },
    mounted() {
        window.addEventListener('resize', this.updatePointOfContactPosition, {
            passive: true,
        });
    },
    beforeDestroy() {
        window.removeEventListener('resize', this.updatePointOfContactPosition);
    },
    components: {
        TaggingArea,
        CommentFeedback,
        CommentFeedbackLabel,
    },
});
