import Vue from 'vue';
import ColorSpinBG from '@/components/ColorSpinBG.vue';
import { Routes } from '@/router/router';
import { mapState, mapGetters } from 'vuex';
import UpdateUserInfoForm from '@/components/auth/UpdateUserInfoForm.vue';
import SignupForm from '@/components/auth/SignupForm.vue';
export default Vue.extend({
    name: 'JoinForm',
    props: {
        email: {
            type: String,
        },
        invitationId: String,
        hasPassword: String,
        invitationValidity: String,
    },
    data() {
        return {
            joinDialog: true,
            userInWaitList: false,
            dialogTitle: "Hey, you're new around here!",
        };
    },
    computed: {
        Routes() {
            return Routes;
        },
        ...mapState(['uid']),
        ...mapGetters(['defaultWorkspace', 'currentWorkspace']),
        isPublic() {
            return !!this.$route.query.link;
        },
        isInvitation() {
            return !!this.invitationId;
        },
        shouldAddPassword() {
            return Boolean(this.hasPassword);
        },
        // dialogTitle(): string {
        //   if (this.userInWaitList) {
        //     return 'Hi Again!'
        //   }
        //   return "Hey, you're new around here!"
        // },
    },
    methods: {
        onUpdateUserInfoSuccess() {
            this.navigateToApp();
        },
        navigateToApp() {
            const wid = this.defaultWorkspace;
            if (wid) {
                this.$router.push({
                    name: Routes.Videos,
                    params: { workspaceId: wid },
                });
            }
        },
    },
    watch: {
        defaultWorkspace: {
            handler(wid) {
                if (wid && !this.isPublic) {
                    if (!this.shouldAddPassword) {
                        this.navigateToApp();
                    }
                }
            },
            immediate: true,
        },
        // userInWaitList: {
        //   handler(val) {
        //     console.log({ userInWaitList: val })
        //   },
        // },
    },
    beforeRouteEnter(_t, _f, next) {
        next((vm) => {
            const invitationId = vm.$route.query.invitation;
            if (invitationId) {
                return next(`/invitation/${invitationId}`);
            }
            next();
        });
    },
    components: {
        ColorSpinBG,
        UpdateUserInfoForm,
        SignupForm,
    },
});
