import { Role } from '@lumiere/db/types';
import { LumiereModels as models, AbilityModels } from '../constants';
const editorModels = models.filter((model) => ![AbilityModels.USER, AbilityModels.WORKSPACE, AbilityModels.TEAM].includes(model));
/**
 *
 * @param user the user record: firebase.User
 * @param workspace the workspace: Workspace
 * @param _hasClaim a Function that resolves to boolean on whether a user has a claim
 */
export default function defineAbilityForEditor(uid, workspace, _hasClaim) {
    return (can, _cannot) => {
        // user roles
        const userRoleInWorkspace = workspace.roles[uid];
        const isEditor = userRoleInWorkspace === Role.EDITOR;
        if (isEditor) {
            can('manage', editorModels);
            can('read', [
                'Elasticsearch.ViewsCountAnalyticsData',
                'Elasticsearch.ExportAnalyticsData',
                'Elasticsearch.InsightAnalyticsData',
                'Elasticsearch.HeatmapAnalyticsData',
                'Elasticsearch.ViewerRetentionAnalyticsData',
                'Elasticsearch.ChannelMetricsAnalyticsData',
                'Elasticsearch.VideoIntelligenceAnalyticsData',
            ]);
            can(['update', 'delete'], 'Elasticsearch.InsightAnalyticsData');
            can('manage', 'Elasticsearch.VideoExternalData');
            can('create', 'VideoIndexer.DecodeVideo');
        }
    };
}
