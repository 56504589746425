import Vue from 'vue';
import VueSlider from '@lumiere/shared/components/VueSlider';
import debounce from 'lodash/debounce';
import locallyStored from '@lumiere/shared/utils/locallyStored';
import { sleep } from '@lumiere/shared/utils/sleep';
const locallyStoredVolumeControl = locallyStored('VOLUME_CONTROL');
export default Vue.extend({
    name: 'VolumeControl',
    data() {
        return {
            volume: 0,
            options: {
                dotSize: 20,
                width: 'auto',
                height: 10,
                tooltip: 'none',
                contained: true,
            },
            isMuted: false,
        };
    },
    mounted() {
        const volume = locallyStoredVolumeControl.get();
        this.volume = volume?.value ?? 50;
        this.isMuted = volume?.isMuted ?? false;
        // on next event loop
        sleep(0).then(() => {
            this.$emit('update-muted', { value: this.isMuted });
            this.$emit('update-volume', this.volume);
        });
    },
    methods: {
        toggleMute() {
            this.isMuted = !this.isMuted;
            this.volume = this.isMuted ? 0 : 50;
            this.isMuted && this.$emit('update-muted', { value: true });
            this.locallyStoreVolume(this.volume, this.isMuted);
        },
        locallyStoreVolume: debounce(function (value, isMuted) {
            locallyStoredVolumeControl.set({
                value,
                isMuted,
            });
        }, 500),
    },
    computed: {
        volumeIcon() {
            const { volume } = this;
            if (this.isMuted)
                return 'volume-mute';
            const icon = volume >= 50 ? 'volume-up' : volume > 0 ? 'volume-down' : 'volume-off';
            return icon;
        },
    },
    watch: {
        volume(val, oldVal) {
            this.$emit('update-volume', val);
            if (val > 0) {
                this.$emit('update-muted', { value: false });
                if (this.isMuted && val > oldVal) {
                    this.isMuted = false;
                }
            }
            this.locallyStoreVolume(val, this.isMuted);
        },
    },
    components: {
        VueSlider,
    },
});
