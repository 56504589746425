import Vue from 'vue';
import adminAPI from '@/services/adminAPI';
import VideoCard from '@/components/workspace/videos/VideoCard.vue';
import { VideoRoutes } from '@/router/router';
import ValueSkeletonLoader from '../ValueSkeletonLoader.vue';
export default Vue.extend({
    name: 'WorkspaceInsights',
    props: {
        workspaceId: { type: String, required: true },
    },
    data() {
        return {
            workspaceVideos: [],
            insightsData: [],
            fetchingInsight: false,
        };
    },
    created() {
        this.getInsightsData();
    },
    computed: {
        tableHeaders() {
            const headers = [
                {
                    text: '',
                    align: 'start',
                    sortable: false,
                    value: 'video',
                },
                { text: 'TOTAL VIEWS', value: 'total_views', align: 'center' },
                {
                    text: 'VIEWS TREND (LAST 30 DAYS)',
                    value: 'views_trend',
                    align: 'center',
                    sort: (a, b) => a - b,
                    sortable: false,
                },
                {
                    text: 'TOTAL BEHAVIORS',
                    value: 'total_behaviors',
                    align: 'center',
                },
                {
                    text: 'CREATOR',
                    value: 'creator',
                    align: 'center',
                    sort: (a, b) => {
                        return a.name > b.name ? 1 : -1;
                    },
                },
            ];
            return headers;
        },
        videoItems() {
            if (!this.insightsData.length)
                return [];
            const tableItems = this.insightsData
                .map((item) => ({
                video: item.video,
                total_views: item.doc_count,
                views_trend: item.last30DaysFlattened,
                total_behaviors: item.total_behaviors,
                creator: { name: item.creatorName, createdOn: item.createdOn },
            }))
                .filter(({ video }) => !!video);
            return tableItems;
        },
        routeLinkType() {
            return this.$ability.can('read', 'Video') ? 'to' : 'href';
        },
        videoURL() {
            return (video) => this.$ability.can('read', 'Video')
                ? {
                    name: VideoRoutes.Insights,
                    params: {
                        videoId: video.id,
                        videoProp: video,
                    },
                }
                : this.$store.getters.videoURL(video.id);
        },
    },
    methods: {
        async getInsightsData() {
            this.fetchingInsight = true;
            const wid = this.workspaceId;
            const { data: insightsData } = await adminAPI(async (api) => api.workspace.getWorkspaceVideoInsights({ wid }));
            this.insightsData = insightsData;
            this.fetchingInsight = false;
        },
    },
    components: {
        VideoCard,
        ValueSkeletonLoader,
    },
});
