import Vue from 'vue';
import without from 'lodash/without';
import ManageTagsBtn from './ManageTagsBtn.vue';
import VideoTag from './VideoTag.vue';
import VideoFeature from './VideoFeature.vue';
import { mapGetters } from 'vuex';
import { FeatureType } from '@lumiere/db/types';
import { Routes } from '@/router/router';
export default Vue.extend({
    name: 'VideosFilters',
    props: {
        value: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            addFirstTag: null,
        };
    },
    computed: {
        ...mapGetters(['currentWorkspace', 'workspaceTags']),
        notSelectedTags() {
            return this.workspaceTags.filter(({ id }) => this.value.tag !== id);
        },
        notSelectedFeatures() {
            const features = [
                FeatureType.Emojis,
                FeatureType.Comments,
                FeatureType.Tags,
                FeatureType.TextPrompt,
                FeatureType.ExternalData,
                FeatureType.Question,
            ];
            return this.value.feature
                ? without(features, this.value.feature)
                : features;
        },
        hasTag() {
            return !!this.workspaceTags?.length;
        },
        Routes() {
            return Routes;
        },
    },
    mounted() {
        this.getCardHeight();
        window.addEventListener('resize', this.getCardHeight);
    },
    watch: {},
    methods: {
        selectTag(tagId) {
            this.$emit('input', {
                ...this.value,
                tag: tagId,
                search: '',
            });
        },
        startDragging(e, tagId) {
            if (e.dataTransfer && this.$ability.can('manage', 'Video')) {
                e.dataTransfer.setData('text', tagId);
                e.dataTransfer.effectAllowed = 'link';
            }
        },
        selectFeature(featureType) {
            this.$emit('input', {
                ...this.value,
                feature: featureType,
                search: '',
            });
        },
        getCardHeight() {
            const cardRef = this.$refs.videosFiltersCard;
            if (cardRef) {
                const { height } = cardRef.getBoundingClientRect();
                document.documentElement.style.setProperty('--rdv-button-top', `${height}px`);
            }
        },
    },
    components: {
        ManageTagsBtn,
        VideoTag,
        VideoFeature,
    },
});
