import Vue from 'vue';
import auth from '@/services/firebase/auth';
import logger from '@lumiere/shared/services/logger';
import { Routes } from '@/router/router';
import { mapState } from 'vuex';
export default Vue.extend({
    name: 'PasswordReset',
    props: {
        actionCode: {
            type: [String, Object],
            required: true,
        },
    },
    data() {
        return {
            userEmail: null,
            hasError: false,
            errorMessage: '',
            passwordDetails: {
                newPassword: '',
                confirmPassword: '',
            },
            loading: false,
            revealNewPassword: false,
            revealConfirmPassword: false,
            loadingMessage: '',
            passwordResetSuccess: false,
            validatePasswordError: [],
        };
    },
    computed: {
        ...mapState(['uid']),
        disableSubmitBtn() {
            const { newPassword, confirmPassword } = this.passwordDetails;
            return (!(newPassword && confirmPassword) || newPassword !== confirmPassword);
        },
        Routes() {
            return Routes;
        },
    },
    methods: {
        submitPassword() {
            this.loading = true;
            const { newPassword, confirmPassword } = this.passwordDetails;
            if (newPassword !== confirmPassword) {
                this.loading = false;
                return;
            }
            this.loadingMessage = 'Resetting your password...';
            auth
                .confirmPasswordReset(this.actionCode, newPassword)
                .then(() => {
                auth
                    .signInWithEmailAndPassword(this.userEmail, newPassword)
                    .then(() => {
                    // make the user wait until thre auth object is hydrated
                    this.passwordResetSuccess = true;
                    this.$emit('loading-message', 'Applying changes...');
                })
                    .catch((e) => {
                    throw e;
                });
            })
                .catch((e) => {
                this.hasError = true;
                this.errorMessage = e.message;
                logger.error('Error', e);
            })
                .finally(() => {
                this.loading = false;
                this.loadingMessage = '';
            });
        },
    },
    mounted() {
        auth
            .verifyPasswordResetCode(this.actionCode ?? '')
            .then((email) => {
            this.userEmail = email;
            logger.info('Account Email', { email });
        })
            .catch((e) => {
            logger.error('Error', e);
            this.$emit('password-reset-error', e);
        });
    },
    watch: {
        loadingMessage(msg) {
            this.$emit('loading-message', msg);
        },
        uid: {
            handler(uid) {
                if (uid) {
                    this.passwordResetSuccess = false;
                    this.$emit('loading-message', '');
                }
            },
            immediate: true,
        },
        passwordDetails: {
            handler(details) {
                const { newPassword, confirmPassword } = details;
                if (confirmPassword.length && newPassword.length) {
                    if (newPassword !== confirmPassword) {
                        this.validatePasswordError = ['Password mismatch'];
                    }
                    else {
                        this.validatePasswordError = [];
                    }
                }
            },
            deep: true,
        },
    },
});
