import Vue from 'vue';
import { Emotions } from './types';
import * as am4core from '@amcharts/amcharts4/core';
import * as am4charts from '@amcharts/amcharts4/charts';
import am4themes_animated from '@amcharts/amcharts4/themes/animated';
import VideoInsightsTrendsBucketPicker from './VideoInsightsTrendsBucketPicker.vue';
import VideoInsightCommenDataFilter from './VideoInsightCommenDataFilter.vue';
import { emotionColor } from './helper';
am4core.useTheme(am4themes_animated);
am4core.options.autoSetClassName = true;
const capitalize = (s) => {
    const str = String(s);
    return str[0].toUpperCase() + str.slice(1).toLowerCase();
};
export default Vue.extend({
    name: 'VideoInsightSentimentEmotionsStackedBarChart',
    props: {
        feature: {
            type: Object,
            required: true,
        },
        totalInteractions: {
            type: Number,
        },
        loading: Boolean,
        sentiment_count: {
            type: Object,
        },
        average_rating: [Number, Object],
        filtering: Boolean,
        ratingsMinMax: {
            type: Object,
        },
        emotions: {
            type: Array,
        },
        filterWasChanged: Boolean,
    },
    data() {
        return {
            amChart: null,
            chartLabel: null,
        };
    },
    computed: {
        orderedEmotion() {
            if (!this.sentiment_count)
                return [];
            let orderedEmotion = [];
            orderedEmotion.push({
                emotion: Emotions.POSITIVE,
                count: this.sentiment_count[Emotions.POSITIVE]?.doc_count ?? 0,
            });
            orderedEmotion.push({
                emotion: Emotions.NEUTRAL,
                count: this.sentiment_count[Emotions.NEUTRAL]?.doc_count ?? 0,
            });
            orderedEmotion.push({
                emotion: Emotions.NEGATIVE,
                count: this.sentiment_count[Emotions.NEGATIVE]?.doc_count ?? 0,
            });
            if (this.totalInteractions) {
                orderedEmotion.push({
                    emotion: Emotions.INDETERMINATE,
                    count: this.totalInteractions -
                        orderedEmotion.reduce((acc, cur) => (acc += cur.count), 0),
                });
            }
            return orderedEmotion;
        },
        chartData() {
            let data = { year: '2000' };
            for (let item of this.orderedEmotion) {
                data[item.emotion] = item.count;
            }
            return [data];
        },
        featureHasRatingsEnabled() {
            return !!this.feature.options.rating;
        },
    },
    methods: {
        getEmotionWithMaxValue(theChartData) {
            const data = Object.keys(theChartData).reduce((acc, item, ix) => {
                const cur = item;
                if (ix === 0) {
                    acc['value'] = theChartData[cur];
                    acc['emotion'] = cur;
                }
                else if (theChartData[cur] > acc['value']) {
                    acc['value'] = theChartData[cur];
                    acc['emotion'] = cur;
                }
                return acc;
            }, {});
            return data;
        },
        constructLabelText(theChartData) {
            const data = this.getEmotionWithMaxValue(theChartData);
            if (data) {
                const value = this.totalInteractions
                    ? Math.round((data.value / this.totalInteractions) * 100)
                    : 0;
                const color = am4core.color(emotionColor(data.emotion));
                return ('[' +
                    color.hex +
                    ' font-size:22px]' +
                    value +
                    '%' +
                    '[/]' +
                    '\n[#665 font-size:11px]' +
                    capitalize(data.emotion) +
                    '[/]');
            }
            return '';
        },
        createStackedBarChart() {
            if (this.amChart) {
                this.refreshData();
                return;
            }
            // this.disposeOfCharts()
            // Create chart instance
            let chart = am4core.create(this.$refs.chartContainer, am4charts.XYChart);
            this.amChart = chart;
            chart.height = am4core.percent(75);
            chart.hiddenState.properties.opacity = 0; // this creates initial fade-in
            chart.data = this.chartData;
            chart.padding(10, 0, 10, 0);
            // chart.marginLeft = 20
            // Create axes
            const categoryAxis = chart.yAxes.push(new am4charts.CategoryAxis());
            categoryAxis.dataFields.category = 'year';
            categoryAxis.renderer.grid.template.opacity = 0;
            categoryAxis.renderer.labels.template.disabled = true;
            const valueAxis = chart.xAxes.push(new am4charts.ValueAxis());
            valueAxis.min = 0;
            valueAxis.renderer.grid.template.disabled = true;
            valueAxis.renderer.line.disabled = true;
            valueAxis.renderer.baseGrid.disabled = true;
            valueAxis.renderer.minGridDistance = 40;
            valueAxis.renderer.labels.template.disabled = true;
            const _theChartData = { ...chart.data[0] };
            delete _theChartData.year;
            const theChartData = _theChartData;
            const sortEmotions = Object.entries(theChartData).sort((a, b) => b[1] - a[1]);
            for (const [e, _] of sortEmotions) {
                this.createSeries(chart, e);
            }
            const title = chart.chartContainer.createChild(am4core.Label);
            title.text = 'Entry Sentiment';
            title.align = 'center';
            title.horizontalCenter = 'middle';
            title.fontSize = 12;
            title.marginTop = 10;
            const label = chart.createChild(am4core.Label);
            this.chartLabel = label;
            label.isMeasured = false;
            label.textAlign = 'middle';
            if (this.$vuetify.breakpoint.mdAndUp) {
                title.paddingLeft = am4core.percent(13);
                label.align = 'left';
                label.x = 0;
                label.y = am4core.percent(20);
                chart.width = am4core.percent(88);
                chart.plotContainer.paddingLeft = am4core.percent(12);
            }
            else {
                title.marginTop = am4core.percent(25);
                title.paddingLeft = am4core.percent(-13);
                label.horizontalCenter = 'middle';
                label.x = am4core.percent(50);
                label.y = 0;
                label.paddingTop = am4core.percent(-13);
                label.paddingLeft = am4core.percent(-13);
                chart.width = am4core.percent(100);
                chart.plotContainer.paddingTop = am4core.percent(40);
            }
            label.text = this.constructLabelText(theChartData);
        },
        createSeries(chart, field) {
            const series = chart.series.push(new am4charts.ColumnSeries());
            series.dataFields.valueX = String(field);
            series.dataFields.categoryY = 'year';
            series.stacked = true;
            series.name = String(field);
            if (series.tooltip) {
                series.tooltip.disabled = false;
            }
            series.fill = am4core.color(emotionColor(field));
            let rgm = new am4core.RadialGradientModifier();
            rgm.brightnesses.push(-0.8, -0.8, -0.5, 0, -0.5);
            series.fillModifier = rgm;
            series.strokeModifier = rgm;
            series.strokeOpacity = 0.4;
            series.strokeWidth = 0;
            // const labelBullet = series.bullets.push(new am4charts.LabelBullet())
            // labelBullet.locationX = 0.5
            // labelBullet.label.text = '{valueX}'
            // labelBullet.label.fill = am4core.color('#fff')
            // labelBullet.toFront()
            series.columns.template.tooltipText =
                '[font-size:12px]{name}: {valueX}[/]';
        },
        refreshData() {
            this.amChart.data = this.chartData;
            const theChartData = { ...this.chartData[0] };
            delete theChartData.year;
            if (this.chartLabel) {
                this.chartLabel.text = this.constructLabelText(theChartData);
            }
            this.amChart.series.each(function (series) {
                series.appear();
            });
        },
        disposeOfCharts() {
            this.amChart?.dispose();
            this.amChart = null;
        },
    },
    watch: {
        'feature.id'(fid) {
            if (fid) {
                this.disposeOfCharts();
            }
        },
        'totalInteractions': {
            handler(newVal, oldVal) {
                if (newVal && !oldVal) {
                    this.$nextTick(() => {
                        this.createStackedBarChart();
                    });
                }
                else if (oldVal) {
                    this.refreshData();
                }
            },
            immediate: true,
        },
    },
    beforeDestroy() {
        this.disposeOfCharts();
    },
    components: {
        VideoInsightsTrendsBucketPicker,
        VideoInsightCommenDataFilter,
    },
});
