import Vue from 'vue';
import anime from 'animejs';
import EmojiFeedbackEmojis from './EmojiFeedbackEmojis.vue';
import { LumiereApps } from '../../components/types';
export default Vue.extend({
    name: 'EmojiFeedback',
    props: {
        emojis: {
            type: Array,
            required: true,
        },
        prompt: {
            type: String,
            required: false,
        },
        size: {
            type: String,
        },
        videoId: String,
        featureId: String,
        appName: {
            type: String,
            default: LumiereApps.VIEWER_APP,
        },
        flyOutEmojis: {
            type: Object,
        },
        currentTime: Number,
        feedbackOptions: {
            type: Object,
        },
        isViewerExperience: Boolean,
    },
    data() {
        return {
            totalEmojiClicks: {},
            pollingInterval: null,
            timer: 0,
        };
    },
    methods: {
        onAppear(el, done) {
            anime({
                targets: el,
                translateY: [150, 0],
                opacity: [0, 1],
                scale: [0, 1],
                duration: 1000,
                delay: 100,
                complete() {
                    done();
                },
            });
        },
        onLeave(el, done) {
            anime({
                targets: el,
                translateY: 150,
                scale: 0,
                opacity: 0,
                duration: 1000,
                complete() {
                    done();
                },
            });
        },
        getTotalEmojiClicks(emojiId) {
            return this.totalEmojiClicks?.buckets?.[emojiId]?.doc_count || null;
        },
        // subscription to poll new data every 10s starting at 0s
        async fetchTotalEmojiClicks(timer, forcePoll = false) {
            if (!(this.isViewerExperience && this.showCount))
                return;
            let dymInterval;
            if (this.clickCountRange < 999) {
                dymInterval = 15;
            }
            else if (this.clickCountRange < 9999) {
                dymInterval = 60;
            }
            else {
                dymInterval = 60 * 5;
            }
            const shouldFetchData = forcePoll || timer % dymInterval === 0;
            if (shouldFetchData) {
                // poll new data
                const totalEmojiClicks = await this.$store.dispatch('GET_TOTAL_EMOJI_CLICKS', { fid: this.featureId, vid: this.videoId });
                this.totalEmojiClicks = Object.assign({}, this.totalEmojiClicks, totalEmojiClicks);
            }
        },
        getFlyOutEmojis(emojiId) {
            return this.flyOutEmojis[emojiId] || null;
        },
        onEngage(emoji, duration) {
            this.$emit('feedback', { emoji, duration });
            if (this.appName === 'VIEWER_APP' &&
                this.totalEmojiClicks?.buckets?.[emoji.id]?.doc_count) {
                this.totalEmojiClicks.buckets[emoji.id].doc_count++;
            }
        },
    },
    computed: {
        emojiSize() {
            return {
                XL: 64,
                L: 48,
                M: 38,
                S: 30,
                XS: 20,
            }[this.size];
        },
        showCount() {
            return this.feedbackOptions?.showCount || null;
        },
        clickCountRange() {
            const bucket = this.totalEmojiClicks?.buckets ?? {};
            const countsArr = Object.keys(bucket).map((el) => {
                return bucket[el].doc_count;
            });
            return countsArr.length ? Math.min.apply(null, countsArr) : 0;
        },
    },
    mounted() {
        try {
            this.fetchTotalEmojiClicks(this.timer++);
            if (this.showCount) {
                // create a timer subscription
                this.pollingInterval = window.setInterval(() => {
                    this.fetchTotalEmojiClicks(this.timer++);
                }, 1000);
            }
        }
        catch (err) {
            console.warn('Action can only be dispatched in viewer-app');
        }
    },
    beforeDestroy() {
        if (this.pollingInterval) {
            clearInterval(this.pollingInterval);
        }
    },
    components: {
        EmojiFeedbackEmojis,
    },
});
