import Vue from 'vue';
import auth from '@/services/firebase/auth';
import logger from '@lumiere/shared/services/logger';
export default Vue.extend({
    name: 'EmailVerification',
    props: {
        actionCode: {
            type: [String, Object],
            required: true,
        },
    },
    data() {
        return {
            overlay: true,
        };
    },
    mounted() {
        // this.$emit('loading-message', 'Finalising email verification...')
        auth
            .applyActionCode(this.actionCode ?? '')
            .then(() => {
            this.$emit('verification-complete');
        })
            .catch((e) => {
            logger.error('EmailVerification', e);
            this.$emit('verification-error', e);
        });
    },
});
