import { WaitList } from '../types'
import CGUDO from './helpers/CGUDO'
import paths from './helpers/paths'
import { DBParams } from './helpers/types'
import { dbCollection } from './helpers/utils'

export const waitlist = (params: DBParams) => (docId: string) =>
  CGUDO(params)<WaitList>(paths.waitlists(docId))

export const waitlists = dbCollection<WaitList>(paths.waitlists())
