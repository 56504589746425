import Vue from 'vue';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faPlay, faPause, faCog, faVolumeUp, faVolumeDown, faVolumeOff, faVolumeMute, faImage, faSmile, faCaretLeft, faCaretRight, faSignalStream, faCommentAltPlus, faCommentPlus, } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { config } from '@fortawesome/fontawesome-svg-core';
config.autoReplaceSvg = 'nest';
library.add(faPlay);
library.add(faPause);
library.add(faCog);
library.add(faVolumeUp);
library.add(faVolumeDown);
library.add(faVolumeOff);
library.add(faVolumeMute);
library.add(faImage);
library.add(faSmile);
library.add(faCaretLeft);
library.add(faCaretRight);
library.add(faSignalStream);
library.add(faCommentAltPlus);
library.add(faCommentPlus);
Vue.component('fa-icon', FontAwesomeIcon);
