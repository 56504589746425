import Vue from 'vue';
import VideoIntelligenceThumbnailAvatar from '../video/video-intelligence/VideoIntelligenceThumbnailAvatar.vue';
import VueTimeSegments from './VueTimeSegments.vue';
export default Vue.extend({
    name: 'PersonContentItem',
    props: {
        person: { type: Object, required: true },
        videoId: { type: String, required: true },
        videoDuration: { type: Number, required: true },
        videoScenes: {
            type: Object,
        },
        managementMode: {
            type: Boolean,
            default: false,
        },
        comparablePersons: {
            type: Array,
            default: () => [],
        },
    },
    data() {
        return {
            editingName: false,
            editingTitle: false,
        };
    },
    computed: {
        comparableInstances() {
            return this.comparablePersons.flatMap((p) => p.instances);
        },
    },
    methods: {
        async updateTitle(title) {
            this.editingTitle = true;
            await this.$db
                .video(this.videoId)
                .person(this.person.id)
                .update({ title });
            this.editingTitle = false;
        },
        async updateName(name) {
            this.editingName = true;
            await this.$db.video(this.videoId).person(this.person.id).update({ name });
            this.editingName = false;
        },
    },
    components: {
        VueTimeSegments,
        VideoIntelligenceThumbnailAvatar,
    },
});
