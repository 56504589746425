import Vue from 'vue';
import VideoDataManagementEditEidForm from './VideoDataManagementEditEidForm.vue';
export default Vue.extend({
    name: 'VideoDataManagementEditDataFilter',
    props: {
        video: Object,
    },
    data() {
        return {
            saving: false,
            dialog: false,
            shoudSubmitEids: false,
        };
    },
    methods: {
        async onSubmitEids(eids) {
            this.saving = true;
            await this.$db.video(this.video.id).update({
                eids: eids,
            });
            this.shoudSubmitEids = false;
            this.saving = false;
            this.dialog = false;
        },
    },
    components: {
        VideoDataManagementEditEidForm,
    },
});
