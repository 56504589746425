import Vue from 'vue';
import getFilenameExtension from '@lumiere/shared/utils/getFilenameExtension';
import { FieldValue } from '@/services/firebase/firestore';
import logger from '@lumiere/shared/services/logger';
export default Vue.extend({
    name: 'ChannelDetailsImage',
    props: {
        channel: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            loading: false,
        };
    },
    computed: {
        fileInput() {
            return this.$refs.fileInput;
        },
        channelImage() {
            return this.channel.image;
        },
        isCurrentImageDefault() {
            return !this.channel.image;
        },
    },
    watch: {},
    methods: {
        saveImage(imageUrl) {
            this.$db.channel(this.channel.id).update({ image: imageUrl });
        },
        selectDefaultImage() {
            this.$db
                .channel(this.channel.id)
                .update({ image: FieldValue.delete() });
        },
        async uploadFile(file) {
            if (file) {
                this.loading = true;
                try {
                    const uploader = this.$uploadFileToStorage(file, `channel/${this.channel.id}/`, `image.${getFilenameExtension(file.name)}`);
                    await uploader.getURL().then((url) => {
                        this.saveImage(url);
                        this.fileInput ? (this.fileInput.value = '') : null;
                    });
                }
                catch (e) {
                    logger.error('uploadFile', e);
                }
                this.loading = false;
            }
        },
    },
    subscriptions() {
        return {};
    },
    components: {},
});
