import storage, { FileMetadata, TaskEvent } from '@/services/firebase/storage';
import logger from '@lumiere/shared/services/logger';
import { fromTask, percentage } from 'rxfire/storage';
import { getFilenameExtension } from '@lumiere/shared/utils/getFilenameExtension';
import { captureException } from '@sentry/browser';
const ONE_SECOND = 1000; // in ms
const ONE_MINUTE = 60 * ONE_SECOND;
const ONE_HOUR = 60 * ONE_MINUTE;
const RETRY_TIME = 12 * ONE_HOUR;
storage.setMaxUploadRetryTime(RETRY_TIME);
export const VIDEOFORMATS = 'video/*,.mov,.mpeg,.mpg,.ffmpeg,.avi,.flv,.fv4,.mp4,.m4v,.asf,.wmv,.vob,.mod,.3gp,.3gpp,.mkv,.divx,.xvid,.webm,.quicktime';
const specialVideoTypes = '.mkv';
export function isValidVideoFormat(file) {
    const isValidFile = VIDEOFORMATS.includes(file.type.split('/')[1]);
    if (isValidFile) {
        return true;
    }
    const ext = getFilenameExtension(file.name);
    if (file.type === '' && VIDEOFORMATS.includes(ext ?? 'undefined')) {
        return true;
    }
    return false;
}
export function uploadFileToStorage(file, path, filename) {
    const stopTimer = logger.time('uploadFile', filename);
    const fileRef = storage.ref(path).child(filename);
    let metadata = FileMetadata;
    if (file instanceof File) {
        const ext = getFilenameExtension(file.name);
        if (specialVideoTypes.includes(ext ?? 'undefined')) {
            metadata = {
                ...metadata,
                contentType: 'video/webm',
            };
        }
    }
    const uploadTask = file instanceof File
        ? fileRef.put(file, metadata)
        : fileRef.putString(file, 'data_url', metadata);
    // bubble the error, not reject/throw, to keep it going up the pipeline
    uploadTask
        .then(() => stopTimer())
        .catch((e) => {
        captureException(e);
        Promise.resolve(e);
    });
    uploadTask.on(TaskEvent.STATE_CHANGED, {
        error: (e) => {
            // bubble the error
            captureException(e);
            Promise.resolve(e);
        },
    });
    //👆 in both cases for Promise.resolve(e), resolve the error allowing it to be handled by the consuming logic
    return {
        cancel: () => uploadTask.cancel(),
        pause: () => uploadTask.pause(),
        resume: () => uploadTask.resume(),
        observe: () => fromTask(uploadTask),
        percentage: () => percentage(uploadTask),
        /** return the download url */
        getURL: async () => uploadTask.then(async ({ ref }) => ref.getDownloadURL()),
        /** get the file ref */
        getRef: () => fileRef,
        catch: uploadTask.catch,
    };
}
export default uploadFileToStorage;
