import Vue from 'vue';
import EmojiListDialog from './EmojiListDialog.vue';
export default Vue.extend({
    name: 'EmojiUploader',
    props: {
        emoji: {
            type: Object,
            default: () => ({
                custom: [],
                emojione: [],
                workspace: [],
            }),
            required: true,
        },
        pickedEmojis: {
            type: Array,
            default: () => [],
        },
    },
    data() {
        return {
            uploadAlertDialog: false,
            emojiFiles: null,
            showEmojiListDialog: false,
            uploadAlertDialogMessage: '',
        };
    },
    methods: {
        onchangeFileDialog(target) {
            const files = target.files;
            if (files.length) {
                // validate the number of multiple files selected
                if (files.length > 10) {
                    this.uploadAlertDialogMessage =
                        'You cannot upload more than 10 emojis at a time';
                    this.uploadAlertDialog = true;
                    return;
                }
                // ensure the emoji hasn't been picked
                let pickedEmojisHash = {};
                let file, filename;
                for (let i = 0; i < files.length; i++) {
                    file = files.item(i);
                    filename = file.name.split('.').shift();
                    pickedEmojisHash[filename] = file;
                }
                let pickedId;
                for (let j = 0; j < this.pickedEmojis.length; j++) {
                    pickedId = this.pickedEmojis[j].id;
                    if (pickedEmojisHash[pickedId]) {
                        this.uploadAlertDialogMessage = `Emoji has been selected.
              First unselect the emoji if you want to upload the same emoji under a different name.`;
                        this.uploadAlertDialog = true;
                        return;
                    }
                }
                this.emojiFiles = files;
                this.showEmojiListDialog = true;
            }
        },
        openFileDialog() {
            const fileDialogRef = this.$refs.fileDialog;
            fileDialogRef.value = '';
            fileDialogRef.click();
        },
        dismissEmojiDialog() {
            this.showEmojiListDialog = false;
            this.emojiFiles = null;
        },
    },
    computed: {
        existingEmojis() {
            return [
                ...(this.emoji.workspace || []),
                ...(this.emoji.custom || []),
                ...(this.emoji.emojione || []),
            ];
        },
    },
    components: {
        EmojiListDialog,
    },
});
