import Vue from 'vue';
import { mapGetters } from 'vuex';
import FuzzySearch from '@lumiere/shared/services/FuzzySearch';
import sortBy from 'lodash/sortBy';
import { timestampToDate } from '@/utils/helpers';
const SEARCH_KEYS = ['name', 'description', 'team.name'];
const searchFuse = FuzzySearch([], { keys: SEARCH_KEYS });
export default Vue.extend({
    name: 'RestoreRecentlyDeletedWorkspaces',
    props: {
        workspaces: Array,
        team: Object,
        searchQuery: String,
    },
    data() {
        return {
            selectedWorkspaces: [],
            restoring: false,
        };
    },
    filters: {
        lowercase(text) {
            return text.toLowerCase();
        },
    },
    computed: {
        ...mapGetters(['currentWorkspace']),
        filteredWorkspaces() {
            const team = this.team;
            const search = this.searchQuery;
            const workspaces = this.workspaces;
            const workspacesFilteredByTeam = team
                ? team.id === 'personal'
                    ? workspaces.filter((w) => !w.team)
                    : workspaces.filter((w) => w.team?.id === team.id)
                : workspaces;
            let response = [...workspacesFilteredByTeam];
            if (search) {
                searchFuse.setCollection(response);
                response = searchFuse.search(search).map(({ item }) => item);
            }
            return sortBy(response, ['name']);
        },
    },
    methods: {
        restoreWorkspace() {
            this.$confirm(`Do you want to restore the selected workspaces?`, {
                buttonTrueText: 'Yes, restore',
                buttonFalseText: 'Dismiss',
            }).then(async (ok) => {
                if (ok) {
                    this.restoring = true;
                    for (let workspace of this.selectedWorkspaces) {
                        await this.$db.workspace(workspace.id).restore();
                    }
                    this.restoring = false;
                }
            });
        },
        timestampToDate(ts) {
            return timestampToDate(ts);
        },
    },
    beforeRouteLeave(_to, _from, next) {
        next();
        this.$emit('parent-close');
    },
});
