var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.workspace.team)?_c('v-list',{attrs:{"subheader":""}},[_c('div',{staticClass:"text-subtitle-2 mx-4 text--secondary"},[_vm._v("Team")]),_c('v-list-item',{attrs:{"two-line":""}},[_c('v-list-item-avatar',{attrs:{"rounded":"lg"}},[(_vm.workspace.team.iconURL)?_c('v-img',{attrs:{"src":_vm.workspace.team.iconURL}}):_c('v-icon',[_vm._v("mdi-account-group")])],1),_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"body-1 font-weight-medium"},[_vm._v(" "+_vm._s(_vm.workspace.team.name)+" ")]),_c('v-list-item-subtitle',[_vm._v(" "+_vm._s(((_vm.teamMemberIds.length) + " " + (_vm.teamMemberIds.length > 1 ? 'members' : 'member')))+" ")])],1),(_vm.$can('manage', 'Team'))?_c('v-list-item-action',[_c('v-menu',{scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":""}},on),[_c('v-icon',[_vm._v("mdi-dots-vertical")])],1)]}}],null,false,2097855828)},[_c('v-list',{attrs:{"dense":""}},[_c('v-list-item',{attrs:{"to":{
                name: _vm.Routes.Team,
                params: { teamId: _vm.workspace.team.id },
              }}},[_c('v-list-item-title',[_vm._v("Manage Team")])],1)],1)],1)],1):_vm._e()],1)],1):_vm._e(),(_vm.members && _vm.members.length > 0)?_c('v-list',{attrs:{"data-testid":"workspace-members-list-list","subheader":""}},[_c('div',{staticClass:"text-subtitle-2 mx-4 text--secondary"},[_vm._v(" "+_vm._s(_vm.workspace.team ? 'Other members' : 'Members')+" ")]),_vm._l((_vm.members),function(member){return _c('v-list-item',{key:member.id,attrs:{"two-line":"","title":member.email}},[_c('v-list-item-avatar',{attrs:{"color":"#F2F3F4"}},[(member.photoURL)?_c('v-img',{attrs:{"src":member.photoURL}}):_c('v-icon',[_vm._v("mdi-account")])],1),_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"font-weight-normal body-1"},[_vm._v(" "+_vm._s(member.displayName || member.email)+" ")]),(_vm.workspace.roles[member.id])?_c('v-list-item-subtitle',{staticClass:"text-capitalize"},[_vm._v(" "+_vm._s(_vm._f("lower")(_vm.workspace.roles[member.id]))+" ")]):_vm._e()],1),(_vm.$can('manage', 'Workspace') || _vm.$can('manage', 'Team'))?_c('v-list-item-action',[_c('v-menu',{scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":""}},on),[_c('v-icon',[_vm._v("mdi-dots-vertical")])],1)]}}],null,true)},[_c('v-list',{attrs:{"dense":""}},[_c('v-list-item',{on:{"click":function($event){return _vm.changeRole(member)}}},[_c('v-list-item-title',[_vm._v("Change Role")])],1),_c('v-list-item',{on:{"click":function($event){return _vm.removeMember(member)}}},[_c('v-list-item-title',[_vm._v("Remove Member")])],1),(!member.emailVerified)?_c('v-list-item',{on:{"click":function($event){return _vm.resendInvitation(member)}}},[_c('v-list-item-title',[_vm._v("Send reminder")])],1):_vm._e()],1)],1)],1):_vm._e()],1)})],2):_vm._e(),_c('v-dialog',{attrs:{"width":"350"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var value = ref.value;
return [(_vm.userForRoleForm && value)?_c('ChangeMemberRole',{attrs:{"workspace":_vm.workspace,"user":_vm.userForRoleForm},on:{"close":function($event){_vm.showUserRoleChangeForm = false}}}):_vm._e()]}}]),model:{value:(_vm.showUserRoleChangeForm),callback:function ($$v) {_vm.showUserRoleChangeForm=$$v},expression:"showUserRoleChangeForm"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }