var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.totalInteractions == null || _vm.loading)?_c('div',[_c('v-divider',{staticClass:"mt-0"}),_c('TimelineSkeletonLoader',{attrs:{"numberOfSkeletons":2,"width":"85"}})],1):_c('div',[_c('v-divider',{staticClass:"mt-0"}),(_vm.filtering)?_c('v-progress-linear',{attrs:{"relative":"","indeterminate":"","color":"primary","height":"2","top":""}}):_vm._e(),_c('div',{ref:"timelineWrapper",staticStyle:{"overflow":"auto","max-height":"520px"}},[_c('v-timeline',[_c('transition-group',{attrs:{"name":"timeline-fade"}},_vm._l((_vm.uniqSentimentEmotionsArray),function(data,i){return _c('v-timeline-item',{directives:[{name:"show",rawName:"v-show",value:(
              (!data.isHidden || _vm.showHiddenComments) &&
              _vm.deletingCommentIndex !== i
            ),expression:"\n              (!data.isHidden || showHiddenComments) &&\n              deletingCommentIndex !== i\n            "}],key:data.emotion + i,staticClass:"v-timelime-item__custom",class:{
              'v-timelime-item__custom--mobile-view':
                _vm.$vuetify.breakpoint.smAndDown,
              'active-item': i === _vm.currentItemIndex,
              'active-item__mobile-view':
                i === _vm.currentItemIndex && _vm.$vuetify.breakpoint.smAndDown,
            },attrs:{"color":data.color,"small":"","right":""},nativeOn:{"click":function($event){return (function () { return _vm.verbatimClicked(data, i); })($event)}},scopedSlots:_vm._u([{key:"opposite",fn:function(){return [(_vm.computedPayloadTimeProp(data.payload) != null)?_c('span',{class:("video-time caption font-weight-normal " + ('grey') + "--text")},[_vm._v(" "+_vm._s(_vm._f("formatTime")(_vm.computedPayloadTimeProp(data.payload)))+" ")]):_vm._e()]},proxy:true}],null,true)},[_c('v-row',{staticClass:"py-0 sentiment-highlighted",attrs:{"dense":""}},[(
                  _vm.computedPayloadTimeProp(data.payload) != null &&
                  i === _vm.currentItemIndex
                )?_c('v-col',{staticClass:"thumbnail-preview",attrs:{"cols":"12","sm":"12","md":"4","lg":"4"}},[_c('VideoThumbnail',{attrs:{"vid":_vm.video.id,"time":_vm.commentTime}}),_c('VideoInsightSentimentTimelineMarker',{attrs:{"commentData":data}})],1):_vm._e(),_c('v-col',{staticClass:"sentiment-text__wrapper",class:{ 'is-hidden': data.isHidden }},[(data.isHidden)?_c('span',[_c('v-icon',{staticClass:"mr-2",attrs:{"small":"","color":"grey"}},[_vm._v("mdi-eye-off")])],1):_vm._e(),(_vm.$options.filters)?_c('div',{staticClass:"sentiment-text",domProps:{"innerHTML":_vm._s(
                    _vm.$options.filters.highlightText(
                      _vm.extractPayloadText(data.payload),
                      data,
                      _vm.filterTerm
                    )
                  )}}):_vm._e()]),(
                  _vm.commentPlusRating(data) &&
                  (i === _vm.currentItemIndex || _vm.$vuetify.breakpoint.smAndUp)
                )?_c('v-col',{staticClass:"rating-indicator",attrs:{"cols":"2"}},[_c('VueSlider',{attrs:{"min":0,"max":10,"dotSize":25,"height":14,"disabled":true,"clickable":false,"value":("" + (_vm.commentPlusRating(data))),"adsorb":"","marks":false,"contained":"","duration":0.2,"tooltip":"none","rail-style":{
                    backgroundColor: 'transparent',
                  },"process-style":{
                    backgroundColor: _vm.commentPlusRatingColor(data),
                  }},scopedSlots:_vm._u([{key:"dot",fn:function(){return [_c('div',{ref:"dot",refInFor:true,staticClass:"rating-indicator__dot",style:({
                        'background-color': _vm.commentPlusRatingColor(data),
                      })},[_vm._v(" "+_vm._s(_vm.commentPlusRating(data))+" ")])]},proxy:true}],null,true)})],1):(_vm.$vuetify.breakpoint.smAndDown)?_c('v-col',{staticClass:"rating-indicator__inactive--mobile-view",attrs:{"cols":"2"}},[_c('span',{staticClass:"rating-indicator__text",style:({
                    'background-color': _vm.commentPlusRatingColor(data),
                  })},[_vm._v(" "+_vm._s(_vm.commentPlusRating(data))+" ")])]):_vm._e()],1),(i === _vm.currentItemIndex && _vm.$can('manage', 'Video'))?_c('div',{staticClass:"action-buttons"},[_c('v-btn',{attrs:{"text":"","small":"","ripple":false,"loading":_vm.hidingComment && _vm.hidingCommentIndex == i,"disabled":_vm.hidingComment && _vm.hidingCommentIndex == i},on:{"click":function($event){$event.stopPropagation();return (function () { return _vm.onToggleHideComment(data, i); })($event)}}},[_c('v-icon',{staticClass:"mr-1",attrs:{"small":""}},[_vm._v(" "+_vm._s(data.isHidden ? 'mdi-eye' : 'mdi-eye-off')+" ")]),_vm._v(" "+_vm._s(data.isHidden ? 'UNHIDE' : 'HIDE')+" ")],1),_c('v-btn',{attrs:{"text":"","small":"","ripple":false,"loading":_vm.deletingComment && _vm.deletingCommentIndex == i,"disabled":_vm.deletingComment && _vm.deletingCommentIndex == i},on:{"click":function($event){$event.stopPropagation();return (function () { return _vm.deleteComment(data, i); })($event)}}},[_c('v-icon',{staticClass:"mr-1",attrs:{"small":""}},[_vm._v("mdi-trash-can-outline")]),_vm._v(" DELETE ")],1)],1):_vm._e(),(i === _vm.currentItemIndex)?_c('button',{staticClass:"deselect-verbatim",on:{"click":function($event){$event.stopPropagation();return (function () { return _vm.deselectVerbatim(i); })($event)}}},[_c('v-icon',[_vm._v("mdi-close")])],1):_vm._e()],1)}),1),(!_vm.isFetchingVerbatim)?_c('v-row',{staticClass:"mt-3 px-5"},[(
              _vm.sentimentsEmotionsArray &&
              _vm.totalInteractions > _vm.sentimentsEmotionsArray.length
            )?_c('v-col',{directives:[{name:"intersect",rawName:"v-intersect",value:(_vm.triggerIntersect),expression:"triggerIntersect"}],staticStyle:{"height":"50px"},attrs:{"cols":"12","xs":"12"}}):_vm._e()],1):_vm._e()],1),(_vm.isFetchingVerbatim)?_c('TimelineSkeletonLoader',{attrs:{"numberOfSkeletons":_vm.numberOfSkeletons}}):_vm._e()],1)],1),(_vm.filtering)?_c('v-progress-linear',{attrs:{"relative":"","indeterminate":"","color":"primary","height":"2","bottom":""}}):_vm._e(),(_vm.$can('read', 'Video') && _vm.commentFeatureHasComments)?_c('div',{staticClass:"show-hidden-comment"},[_c('v-switch',{attrs:{"hide-details":"","dense":"","color":"primary","ripple":false,"label":"Show hidden comments","disabled":false},on:{"change":_vm.onToggleShowHiddenComments},model:{value:(_vm.showHiddenComments),callback:function ($$v) {_vm.showHiddenComments=$$v},expression:"showHiddenComments"}})],1):_vm._e(),_c('v-dialog',{attrs:{"width":"450"},on:{"click:outside":function($event){_vm.alertDialog = false}},model:{value:(_vm.alertDialog),callback:function ($$v) {_vm.alertDialog=$$v},expression:"alertDialog"}},[_c('v-card',[_c('v-card-title',{staticClass:"headline amber darken-3 white--text",attrs:{"primary-title":""}},[_c('v-icon',{staticClass:"mr-4",attrs:{"medium":"","dark":""}},[_vm._v("mdi-exclamation")]),_vm._v(" Comment Verbatim ")],1),_c('v-card-text',{staticClass:"body lighten-2 mt-4"},[_vm._v(" "+_vm._s(_vm.alertDialogMessage)+" ")]),_c('v-divider'),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary"},on:{"click":function($event){_vm.alertDialog = false}}},[_vm._v("OK")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }