import Vue from 'vue';
import { startWith, map } from 'rxjs/operators';
import { Role } from '@lumiere/db/types';
import adminAPI from '@/services/adminAPI';
export default Vue.extend({
    name: 'UserCreatorStats',
    data() {
        return {
            videos_count: 0,
            channels_count: 0,
            fetchingStats: false,
        };
    },
    created() {
        this.getStats();
    },
    methods: {
        // fetch user creator stats
        async getStats() {
            this.fetchingStats = true;
            const { videos_count, channels_count } = await adminAPI(async (api) => api.user.getUserCreatorStats());
            this.videos_count = videos_count;
            this.channels_count = channels_count;
            this.fetchingStats = false;
        },
    },
    subscriptions() {
        const { uid } = this.$store.state;
        return {
            workspaces: this.$db
                .workspaces((query) => query
                .where(`roles.${uid}`, 'in', [
                Role.EDITOR,
                Role.OWNER,
                Role.VIEWER,
                Role.REVIEWER,
            ])
                .where('createdBy', '==', uid))
                .observe()
                .pipe(map((data) => data.filter((w) => !w.deletedTime)), startWith(null)),
        };
    },
});
