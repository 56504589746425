import Vue from 'vue';
import Vuetify from 'vuetify/lib';
Vue.use(Vuetify);
const vuetify = new Vuetify({
    icons: {
        iconfont: 'mdi',
    },
    theme: {
        options: {
            customProperties: true,
        },
        themes: {
            light: {
                background: '#f2f3f4',
            },
        },
    },
});
export default vuetify;
