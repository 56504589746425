export var Role;
(function (Role) {
    Role["OWNER"] = "OWNER";
    Role["EDITOR"] = "EDITOR";
    Role["VIEWER"] = "VIEWER";
    Role["REVIEWER"] = "REVIEWER";
})(Role || (Role = {}));
export var CustomClaims;
(function (CustomClaims) {
    CustomClaims["ADMIN"] = "admin";
    CustomClaims["USER"] = "user";
    CustomClaims["DEVELOPER"] = "developer";
})(CustomClaims || (CustomClaims = {}));
export var AuthLinkType;
(function (AuthLinkType) {
    AuthLinkType["SIGN_IN_AND_LINK"] = "SIGN_IN_AND_LINK";
    AuthLinkType["BASIC_LINKING"] = "BASIC_LINKING";
})(AuthLinkType || (AuthLinkType = {}));
