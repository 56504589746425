import { render, staticRenderFns } from "./ChannelDetailsImage.vue?vue&type=template&id=45a9a772&"
import script from "./ChannelDetailsImage.vue?vue&type=script&lang=ts&"
export * from "./ChannelDetailsImage.vue?vue&type=script&lang=ts&"
import style0 from "./ChannelDetailsImage.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/.pnpm/vue-loader@15.9.7_fb85a9817f99bdf862e5e3a442a1fb91/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../../../node_modules/.pnpm/vuetify-loader@1.7.2_c225a462abeaf467a2189c5bd12e241a/node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemIcon } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VResponsive } from 'vuetify/lib/components/VResponsive';
installComponents(component, {VBtn,VIcon,VImg,VList,VListItem,VListItemIcon,VListItemTitle,VMenu,VProgressCircular,VResponsive})
