import { Role } from '@lumiere/db/types';
import { LumiereModels as models, AbilityModels } from '../constants';
const ownerModels = models.filter((model) => model !== AbilityModels.TEAM);
/**
 *
 * @param user the user record: firebase.User
 * @param workspace the workspace: Workspace
 * @param _hasClaim a Function that resolves to boolean on whether a user has a claim
 */
export default function defineAbilityForOwner(uid, workspace, _hasClaim) {
    return (can, _cannot) => {
        // user roles
        const userRoleInWorkspace = workspace.roles[uid];
        const isOwner = userRoleInWorkspace === Role.OWNER;
        if (isOwner) {
            can('manage', ownerModels);
            can('read', [
                'Elasticsearch.ViewsCountAnalyticsData',
                'Elasticsearch.ExportAnalyticsData',
                'Elasticsearch.InsightAnalyticsData',
                'Elasticsearch.HeatmapAnalyticsData',
                'Elasticsearch.ViewerRetentionAnalyticsData',
                'Elasticsearch.ChannelMetricsAnalyticsData',
                'Elasticsearch.VideoIntelligenceAnalyticsData',
            ]);
            can(['update', 'delete'], 'Elasticsearch.InsightAnalyticsData');
            can('manage', 'Elasticsearch.VideoExternalData');
            can('manage', 'VideoIndexer.DecodeVideo');
        }
    };
}
