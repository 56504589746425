import Vue from 'vue';
export default Vue.extend({
    name: 'EmojiFeedbackOptions',
    props: {
        feedbackOptions: {
            type: Object,
        },
    },
    data() {
        return {
            emojiCount: this.feedbackOptions?.showCount ?? false,
            emojiInteractions: this.feedbackOptions?.showInteractions ?? false,
        };
    },
    watch: {
        emojiCount(showCount) {
            this.$emit('input', {
                showCount,
                showInteractions: this.emojiInteractions,
            });
        },
        emojiInteractions(showInteractions) {
            this.$emit('input', {
                showCount: this.emojiCount,
                showInteractions,
            });
        },
    },
});
