import { FieldValue } from '@/services/firebase/firestore';
import { splitBy } from '@lumiere/db/src/helpers/utils';
import logger from '@lumiere/shared/services/logger';
import { combineLatest, of } from 'rxjs';
import { pluck, switchMap, map } from 'rxjs/operators';
import Vue from 'vue';
import { mapState } from 'vuex';
import ChangeMemberRole from './members/ChangeMemberRole.vue';
import { Routes } from '@/router/router';
import adminAPI from '@/services/adminAPI';
const splitBy10 = splitBy(10);
export default Vue.extend({
    name: 'WorkspaceMembersList',
    props: {
        workspace: Object,
    },
    data() {
        return {
            userForRoleForm: null,
            showUserRoleChangeForm: false,
            members: [],
        };
    },
    computed: {
        ...mapState(['uid']),
        teamMemberIds() {
            return this.workspace.team ? Object.keys(this.workspace.team.roles) : [];
        },
        Routes() {
            return Routes;
        },
    },
    watch: {},
    methods: {
        async removeMember(member) {
            const confirm = await this.$confirm(`The removed member won't have access to the workspace. Do you want to proceed?`, {
                buttonTrueText: 'Remove',
                buttonFalseText: 'Cancel',
            });
            if (confirm) {
                await this.$db
                    .workspace(this.workspace.id)
                    .update({
                    [`roles.${member.id}`]: FieldValue.delete(),
                })
                    .then(async () => {
                    const ctx = await this.$auditLogger(this.$store.state.uid);
                    if (ctx) {
                        const { Asset_Model, EVENT_ACTION, ts } = ctx.config;
                        const asset = {
                            id: this.workspace.id,
                            type: Asset_Model.Workspace,
                            name: this.workspace.name,
                        };
                        await ctx.exec(asset, {
                            timestamp: ts(),
                            action: EVENT_ACTION.MEMBERS_REMOVED,
                            data: {
                                type: 'INFO',
                                payload: {
                                    message: 'Workspace members removed',
                                    actorAssets: {
                                        type: Asset_Model.User,
                                        ids: [member.id],
                                    },
                                },
                            },
                        });
                    }
                })
                    .catch((error) => {
                    logger.error('Failed to remove workspace member', error);
                });
            }
        },
        changeRole(user) {
            this.userForRoleForm = user;
            this.showUserRoleChangeForm = true;
        },
        async resendInvitation(member) {
            await adminAPI((api) => api.emails.inviteUser({
                origin: location.origin,
                email: member.email,
                item: {
                    name: this.workspace.name,
                    type: 'workspace',
                },
            }));
        },
    },
    subscriptions() {
        const workspace$ = this.$watchAsObservable('workspace', {
            immediate: true,
        }).pipe(pluck('newValue'));
        return {
            members: workspace$.pipe(switchMap(({ team, roles }) => {
                // filter out team members
                const workspaceUserIdsWithoutTeamUserIds = Object.keys(roles).filter((uid) => !team?.roles[uid]);
                const uidGroups = splitBy10(workspaceUserIdsWithoutTeamUserIds);
                return uidGroups.length === 0
                    ? of([])
                    : combineLatest(uidGroups.map((uids) => this.$db
                        .users((ref, { FieldPath }) => ref.where(FieldPath.documentId(), 'in', uids))
                        .observe()
                        .pipe(map((users) => users.map((user) => ({
                        ...user,
                        role: roles[user.id],
                    })))))).pipe(map((groupsOfUsers) => groupsOfUsers.flat()));
            })),
        };
    },
    components: {
        ChangeMemberRole,
    },
    filters: {
        lower(v) {
            return v ? v.toLowerCase() : '';
        },
    },
});
