import DB from '@lumiere/db';
import { Asset_Model, EVENT_ACTION, } from '@lumiere/shared/types';
import adminAPI from './adminAPI';
import pick from 'lodash/pick';
import { dayjs } from '@lumiere/shared/utils/dayjs';
/**
 *
 * @param uid gotten from this.$store.state.uid
 * @returns
 */
export const makeAuditLogger = async (uid) => {
    if (!uid)
        return null;
    const user = await getUser(uid);
    if (!user)
        return null;
    return {
        config: {
            Asset_Model,
            EVENT_ACTION,
            ts: () => dayjs().toDate(),
        },
        exec: async (asset, event) => adminAPI((api) => api.saveBrowserAuditLog({
            creator: pick(user, ['displayName', 'email', 'id', 'lastSignInTime']),
            asset,
            event,
        })),
    };
};
const getUser = (uid) => DB.getInstance().user(uid).get();
const auditLogger = {
    install(vue, _options) {
        vue.prototype.$auditLogger = makeAuditLogger;
    },
};
export default auditLogger;
