import Vue from 'vue';
import { FeatureType } from '@lumiere/db/types';
import { startWith, map } from 'rxjs/operators';
import logger from '@lumiere/shared/services/logger';
import adminAPI from '@/services/adminAPI';
var DataExportTypes;
(function (DataExportTypes) {
    DataExportTypes["VIEWS"] = "Views";
    DataExportTypes["VIEWER_RETENTION"] = "Viewer Retention";
    DataExportTypes["COMMENTS"] = "Comments";
    DataExportTypes["COMMENT_ENTITIES"] = "Comment Entities";
    DataExportTypes["EMOJIS"] = "Emojis";
})(DataExportTypes || (DataExportTypes = {}));
export default Vue.extend({
    name: 'VideoDataManagementDataExport',
    props: {
        video: Object,
    },
    data() {
        return {
            selectedOption: null,
            fetchingDataExport: false,
            snackbar: false,
            snackbarMessage: '',
            features: null,
        };
    },
    computed: {
        exportList() {
            const features = this.features;
            if (features && features.length > 0) {
                let hasComments = false;
                let hasEmojis = false;
                features.forEach((feature) => {
                    if (feature.type == FeatureType.Comments) {
                        hasComments = true;
                    }
                    else if (feature.type == FeatureType.Emojis) {
                        hasEmojis = true;
                    }
                });
                return [
                    DataExportTypes.VIEWS,
                    DataExportTypes.VIEWER_RETENTION,
                    ...(hasComments
                        ? [DataExportTypes.COMMENTS, DataExportTypes.COMMENT_ENTITIES]
                        : []),
                    ...(hasEmojis ? [DataExportTypes.EMOJIS] : []),
                ];
            }
            else {
                return [];
            }
        },
    },
    methods: {
        async determineDataToExport() {
            const type = this.selectedOption;
            this.fetchingDataExport = true;
            try {
                switch (type) {
                    case DataExportTypes.VIEWS:
                        await this.downloadCommentsViewsData();
                        break;
                    case DataExportTypes.VIEWER_RETENTION:
                        await this.downloadVideoRetentionData();
                        break;
                    case DataExportTypes.COMMENTS:
                        await this.downloadCommentsData();
                        break;
                    case DataExportTypes.COMMENT_ENTITIES:
                        await this.downloadCommentsEntitiesData();
                        break;
                    case DataExportTypes.EMOJIS:
                        await this.downloadEmojisData();
                        break;
                    default:
                        break;
                }
            }
            catch (error) {
                logger.error(error.message);
            }
            finally {
                this.fetchingDataExport = false;
            }
        },
        async downloadCommentsViewsData() {
            await adminAPI(async (api) => api.video.exports.getVideoViewsDataExport({
                vid: this.video.id,
            })).then(({ csv }) => {
                const videoTitle = this.video?.title ?? '';
                const filename = `${videoTitle}_${new Date().toISOString()}_video-views.csv`;
                const data = encodeURIComponent(csv);
                this.$download(data, filename, { type: 'text/plain' });
                this.snackbar = true;
                this.snackbarMessage = `Video views data export is complete`;
            });
        },
        async downloadEmojisData() {
            await adminAPI(async (api) => api.video.exports.getVideoEmojisDataExport({
                vid: this.video.id,
            })).then(({ csv }) => {
                const videoTitle = this.video?.title ?? '';
                const filename = `${videoTitle}_${new Date().toISOString()}_emojis.csv`;
                const data = encodeURIComponent(csv);
                this.$download(data, filename, { type: 'text/plain' });
                this.snackbar = true;
                this.snackbarMessage = `Video emojis data export is complete`;
            });
        },
        async downloadVideoRetentionData() {
            await adminAPI(async (api) => api.video.exports.getVideoRetentionDataExport({
                vid: this.video.id,
            })).then(({ csv }) => {
                const videoTitle = this.video?.title ?? '';
                const filename = `${videoTitle}_${new Date().toISOString()}_video-retention.csv`;
                const data = encodeURIComponent(csv);
                this.$download(data, filename, { type: 'text/plain' });
                this.snackbar = true;
                this.snackbarMessage = `Video retention data export is complete`;
            });
        },
        async downloadCommentsData() {
            await adminAPI(async (api) => api.video.exports.getVideoCommentsDataExport({
                vid: this.video.id,
            })).then(({ csv }) => {
                const videoTitle = this.video?.title ?? '';
                const filename = `${videoTitle}_${new Date().toISOString()}_comments.csv`;
                const data = encodeURIComponent(csv);
                this.$download(data, filename, { type: 'text/plain' });
                this.snackbar = true;
                this.snackbarMessage = `Video comments data export is complete`;
            });
        },
        async downloadCommentsEntitiesData() {
            await adminAPI(async (api) => api.video.exports.getVideoCommentsEntitiesDataExport({
                vid: this.video.id,
            })).then(({ csv }) => {
                const videoTitle = this.video?.title ?? '';
                const filename = `${videoTitle}_${new Date().toISOString()}_comments-entities.csv`;
                const data = encodeURIComponent(csv);
                this.$download(data, filename, { type: 'text/plain' });
                this.snackbar = true;
                this.snackbarMessage = `Video comments entities data export is complete`;
            });
        },
    },
    subscriptions() {
        const videoId = this.$props.video.id;
        console.log('subscriptions: videoId: ' + videoId);
        const features = this.$db
            .video(videoId)
            .features((ref) => ref.orderBy('createdTime'))
            .observe()
            .pipe(map((items) => items.filter((item) => !item.deletedTime)), startWith(null));
        return {
            features,
        };
    },
});
