import firebase from 'firebase/app';
import auth from './firebase/auth';
import { shareReplay, map, first } from 'rxjs/operators';
import { user } from 'rxfire/auth';
export const { EMAIL_LINK_SIGN_IN_METHOD, EMAIL_PASSWORD_SIGN_IN_METHOD, } = firebase.auth.EmailAuthProvider;
export var Socials;
(function (Socials) {
    Socials["GOOGLE"] = "Google";
    Socials["FACEBOOK"] = "Facebook";
    Socials["TWITTER"] = "Twitter";
})(Socials || (Socials = {}));
export const { GOOGLE_SIGN_IN_METHOD, PROVIDER_ID: GOOGLE_PROVIDER_ID, } = firebase.auth.GoogleAuthProvider;
export const { FACEBOOK_SIGN_IN_METHOD, PROVIDER_ID: FACEBOOK_PROVIDER_ID, } = firebase.auth.FacebookAuthProvider;
export const { TWITTER_SIGN_IN_METHOD, PROVIDER_ID: TWITTER_PROVIDER_ID, } = firebase.auth.TwitterAuthProvider;
export var SIGN_IN_METHODS;
(function (SIGN_IN_METHODS) {
    SIGN_IN_METHODS["google.com"] = "google.com";
    SIGN_IN_METHODS["facebook.com"] = "facebook.com";
    SIGN_IN_METHODS["twitter.com"] = "twitter.com";
    SIGN_IN_METHODS["password"] = "password";
    SIGN_IN_METHODS["emailLink"] = "emailLink";
})(SIGN_IN_METHODS || (SIGN_IN_METHODS = {}));
export const SOCIAL_SIGN_IN_METHODS = [
    SIGN_IN_METHODS['google.com'],
    SIGN_IN_METHODS['facebook.com'],
    SIGN_IN_METHODS['twitter.com'],
];
export const SOCIAL_PROVIDER_IDS = {
    GOOGLE_PROVIDER_ID,
    FACEBOOK_PROVIDER_ID,
    TWITTER_PROVIDER_ID,
};
export var AUTH_PROVIDER_IDS;
(function (AUTH_PROVIDER_IDS) {
    AUTH_PROVIDER_IDS["EmailPasswordAuthProviderID"] = "password";
    AUTH_PROVIDER_IDS["EmailLinkAuthProviderID"] = "emailLink";
    AUTH_PROVIDER_IDS["GoogleAuthProviderID"] = "google.com";
    AUTH_PROVIDER_IDS["FacebookAuthProviderID"] = "facebook.com";
    AUTH_PROVIDER_IDS["TwitterAuthProviderID"] = "twitter.com";
})(AUTH_PROVIDER_IDS || (AUTH_PROVIDER_IDS = {}));
export function getPlainName(providerId) {
    const auth_provider_ids = {
        password: 'Password',
        emailLink: 'Email Link',
        [GOOGLE_PROVIDER_ID]: 'Google',
        [FACEBOOK_PROVIDER_ID]: 'Facebook',
        [TWITTER_PROVIDER_ID]: 'Twitter',
    };
    return auth_provider_ids[providerId] ?? providerId;
}
const user$ = user(auth).pipe(shareReplay(1));
export default {
    user: () => ({
        uid: async () => user$
            .pipe(first(), map((u) => (u ? u.uid : null)))
            .toPromise(),
        admin: Promise.resolve(false),
    }),
    user$: user$,
    async sendSignInLinkToEmail(email, url) {
        const actionCodeSettings = {
            url: url.href,
            handleCodeInApp: true,
        };
        return auth.sendSignInLinkToEmail(email, actionCodeSettings);
    },
    async signOut() {
        return auth.signOut();
    },
    async getCurrentUser(force = false) {
        return new Promise((resolve, reject) => {
            if (auth.currentUser && !force) {
                resolve(auth.currentUser);
            }
            const unsubscribe = auth.onAuthStateChanged((user) => {
                unsubscribe();
                resolve(user);
            }, reject);
        });
    },
    async getToken() {
        return this.getCurrentUser().then((user) => user ? user.getIdToken() : null);
    },
    async userHasPassword() {
        const user = await this.getCurrentUser();
        return !!user?.providerData?.find((p) => p?.providerId === SIGN_IN_METHODS.password);
    },
};
