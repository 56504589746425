import Vue from 'vue';
import TaggingCursor from './TaggingCursor.vue';
function getXY(e) {
    let xy = { pageX: 0, pageY: 0 };
    if (typeof e.clientX !== 'undefined') {
        xy = e;
    }
    else if (e.changedTouches.length) {
        xy = e.changedTouches[0];
    }
    const { pageX, pageY } = xy;
    return { x: pageX, y: pageY };
}
const zeroToOne = (v) => (v > 1 ? 1 : v < 0 ? 0 : v);
export default Vue.extend({
    props: {
        pointOfContact: {
            type: Object,
        },
        overlay: Object,
        container: Object,
    },
    data() {
        return {
            x: 0,
            y: 0,
            fixed: false,
            dragging: false,
            hovering: false,
            offset: {
                xOffset: 0,
                yOffset: 0,
            },
            cursorAbsolutePosition: {
                x: 0,
                y: 0,
            },
        };
    },
    watch: {
        dragging(dragging) {
            this.$emit('dragging', dragging);
        },
    },
    components: {
        TaggingCursor,
    },
    methods: {
        unfix() {
            this.hovering = false;
            this.fixed = false;
            this.dragging = false;
        },
        fix() {
            this.fixed = true;
        },
        getPosition(e) {
            const { left, top, width, height } = this.overlay;
            const { x, y } = getXY(e);
            const { xOffset, yOffset } = this.offset;
            return {
                x: zeroToOne((x - left - xOffset) / width),
                y: zeroToOne(1 - (y - top - yOffset) / height),
            };
        },
        moveCursor(e) {
            if (!this.fixed) {
                const { x, y } = this.getPosition(e);
                this.hovering = true;
                this.x = x;
                this.y = y;
                if (this.dragging) {
                    this.$emit('set-position', { x, y });
                }
            }
        },
        onTap(e) {
            this.fixed = true;
            const { x, y } = this.getPosition(e);
            this.x = x;
            this.y = y;
            this.$emit('set-position', { x, y });
        },
        cancelHovering() {
            this.hovering = false;
        },
        onTouchStartCursor() {
            this.dragging = true;
            this.fixed = false;
        },
        onTouchEndCursor() {
            this.dragging = false;
            this.fixed = true;
        },
        updateOffset() {
            this.offset = {
                xOffset: window.pageXOffset,
                yOffset: window.pageYOffset,
            };
        },
    },
    computed: {
        showCursor() {
            return this.fixed || this.hovering;
        },
    },
    mounted() {
        this.updateOffset();
        window.addEventListener('resize', this.updateOffset, {
            passive: true,
        });
        window.addEventListener('scroll', this.updateOffset, {
            passive: true,
        });
    },
    beforeDestroy() {
        window.removeEventListener('resize', this.updateOffset);
        window.removeEventListener('scroll', this.updateOffset);
    },
});
