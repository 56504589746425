import { captureException, captureMessage } from '@sentry/browser';
import FetchRetry from 'fetch-retry';
import config from '@lumiere/shared/config';
import auth from '../services/auth';
const transformedFetch = FetchRetry(window.fetch, {
    retries: 2,
    retryDelay: (attempt) => (attempt + 1) * 1000,
    retryOn: (_, error, response) => {
        if (error !== null || (response?.status && response.status >= 500)) {
            return true;
        }
    },
});
const baseURL = config.adminAppURL;
async function fetchData(url, options = {}) {
    const fullUrl = `${baseURL}/` + url.replace(/^\//, '');
    const response = await transformedFetch(fullUrl, {
        mode: 'cors',
        credentials: 'include',
        referrerPolicy: 'origin',
        ...options,
        headers: {
            ...options.headers,
            'Content-Type': 'application/json',
        },
    }).catch((e) => {
        captureMessage('@fetch: Error');
        captureException(e);
        throw e;
    });
    const result = await response.text();
    if (!response.ok)
        throw new Error(result);
    try {
        return { data: JSON.parse(result) };
    }
    catch (_e) {
        return { data: result };
    }
}
export { fetchData };
export const reqHeaders = async () => {
    const user = await auth.getCurrentUser();
    const idToken = (await user?.getIdToken()) || '';
    const authorization = `Bearer ${idToken}`;
    const link = getBrowserQueryParam('link') || '';
    const linkAuthorization = `Bearer ${link}`;
    return {
        'X-Requested-With': 'XMLHttpRequest',
        'Authorization': authorization,
        'x-link-authorization': linkAuthorization,
    };
};
/**
 * Retrieve query param from a given url string
 *
 * @param id the query param to retrieve
 * @param url an optional url string. Defaults to window.location.search
 */
export const getBrowserQueryParam = (id, url) => {
    const query = url ? new URL(decodeURIComponent(url)).search : location.search;
    const params = new URLSearchParams(query);
    return params.get(id);
};
