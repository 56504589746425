var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"manage-persons"},[_c('v-dialog',{ref:"managePersonContainer",staticStyle:{"position":"relative"},attrs:{"width":850,"fullscreen":_vm.$vuetify.breakpoint.smAndDown},on:{"click:outside":function($event){_vm.dialog = false}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"text":"","dense":"","x-small":"","ripple":false,"disabled":!_vm.persons.length}},on),[_vm._v(" REVIEW ")])]}},{key:"default",fn:function(ref){
var value = ref.value;
return [(value)?_c('v-card',{staticClass:"manage-persons__container"},[_c('v-card-title',{staticClass:"manage-person__container-title"},[_vm._v(" Review Face Detections "),_c('v-spacer'),_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){_vm.dialog = false}}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1),_c('v-divider'),(_vm.merging || _vm.unmerging)?_c('v-overlay',{attrs:{"absolute":"","color":"white","opacity":0.7}},[_c('v-progress-circular',{staticClass:"mb-12",attrs:{"width":5,"size":60,"indeterminate":"","color":"primary"}})],1):_vm._e(),_c('v-card-text',{staticClass:"manage-persons__container-content"},[_c('v-list',{staticClass:"manage-persons__container-content--list scrollable px-0 mr-n4 pr-3",class:{
              'scrollable__full-height': _vm.$vuetify.breakpoint.smAndDown,
            },style:({
              '--full-height': (_vm.dialogHeight + "px"),
            }),attrs:{"two-lines":"","transition":"scroll-y-transition"}},[_vm._l((_vm.sortedPersons.slice(
                0,
                _vm.renderPersonCount
              )),function(person,index){return [_c('v-list-item',{key:person.id,staticClass:"list-item px-0 py-2",staticStyle:{"position":"relative"}},[_c('v-list-item-action',{staticClass:"d-flex justify-center align-center py-3 px-0"},[_c('v-checkbox',{staticClass:"mx-0",attrs:{"item":person.id,"value":person,"multiple":""},model:{value:(_vm.selectedPersons),callback:function ($$v) {_vm.selectedPersons=$$v},expression:"selectedPersons"}})],1),_c('v-list-content',{staticClass:"px-0 mx-0 ml-n2",staticStyle:{"width":"100%","height":"100%"}},[_c('PersonContentItem',{attrs:{"managementMode":"","person":person,"videoId":_vm.videoId,"videoDuration":_vm.videoDuration,"videoScenes":_vm.videoScenes},on:{"unmerge-persons":function (ref) {
                          var pid = ref.pid;

                          return _vm.unmergePersons(pid);
              }}})],1)],1),(index < _vm.persons.length - 1)?_c('v-divider',{key:index,attrs:{"light":""}}):_vm._e()]}),(_vm.renderPersonCount < _vm.sortedPersons.length)?_c('v-row',{staticClass:"ma-0 mt-3"},[_c('v-col',{directives:[{name:"intersect",rawName:"v-intersect",value:(_vm.triggerIntersect),expression:"triggerIntersect"}],staticStyle:{"height":"100px"}})],1):_c('div',{staticStyle:{"height":"50px"}})],2)],1),(_vm.$can('manage', 'Video.Person'))?_c('div',{staticClass:"manage-persons__container-action-item"},[_c('v-divider'),_c('v-card-actions',{staticClass:"justify-center",staticStyle:{"height":"70px"}},[_c('v-btn',{staticClass:"pa-3",attrs:{"colos":"primary","depressed":"","disabled":_vm.selectedPersons.length < 2,"loading":_vm.merging},on:{"click":_vm.mergeSelectedPersons}},[_vm._v(" Merge Selected Faces "),(_vm.selectedPersons.length > 1)?_c('span',[_vm._v(" ("+_vm._s(_vm.selectedPersons.length)+") ")]):_vm._e()])],1)],1):_vm._e()],1):_vm._e()]}}]),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }