import Vue from 'vue';
import VideoDetailsTitle from './VideoDetailsTitle.vue';
import VideoDetailsPoster from './VideoDetailsPoster.vue';
import VideoDetailsTags from './VideoDetailsTags.vue';
import VideoDetailsLink from './VideoDetailsLink.vue';
import VideoDetailsDescription from './VideoDetailsDescription.vue';
import { VideoPageOptionsEnum, VideoOutputsEnum, } from '@lumiere/db/types';
import VideoViewsTrend from '@/views/workspace/video/VideoViewsTrend.vue';
import { mapGetters, mapState } from 'vuex';
export default Vue.extend({
    name: 'VideoDetails',
    props: {
        video: {
            type: Object,
            required: true,
        },
    },
    computed: {
        ...mapState(['link']),
        ...mapGetters(['userRoleInWorkspace']),
        isPublic() {
            return !!this.$route.query.link;
        },
        showViewerLink() {
            if (this.userRoleInWorkspace) {
                return true;
            }
            if (this.isPublic && this.link) {
                const configOptions = this.link.configuration?.pageOptions?.options;
                if (configOptions) {
                    return configOptions.includes(VideoPageOptionsEnum.SHOW_VIDEO_LINK);
                }
            }
            return false;
        },
        showOverview() {
            if (this.userRoleInWorkspace) {
                return true;
            }
            if (this.isPublic && this.link) {
                const configOutputs = this.link.configuration?.outputs;
                if (configOutputs) {
                    return configOutputs.includes(VideoOutputsEnum.VIEWS);
                }
            }
            return false;
        },
        showDescription() {
            if (this.userRoleInWorkspace) {
                return true;
            }
            return !!this.video.description;
        },
    },
    methods: {},
    subscriptions() {
        return {};
    },
    components: {
        VideoDetailsTitle,
        VideoDetailsPoster,
        VideoDetailsTags,
        VideoDetailsLink,
        VideoDetailsDescription,
        VideoViewsTrend,
    },
});
