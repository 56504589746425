import Vue from 'vue';
import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';
import VideoInsightSentimentTimeline from '../VideoInsightSentimentTimeline.vue';
import VideoInsightExternalDataTrend from './VideoInsightExternalDataTrend.vue';
import { Emotions, FilterActions, MouseEvent, CommentTimeProp, } from '../types';
import VideoInsightEntitiesWordCloud from '../VideoInsightEntitiesWordCloud.vue';
import VideoInsightItemsCounts from '../VideoInsightItemsCounts.vue';
import { colors, emotionColor as emotionColorHelper, PER_LOAD_SIZE, hasNumberEl, sentimentInterpretation, isNumber, } from '../helper';
import adminAPI from '@/services/adminAPI';
import { getBrowserQueryParam } from '@/utils/api';
import VideoInsightSentimentEmotionsStackedBarChart from '../VideoInsightSentimentEmotionsStackedBarChart.vue';
import logger from '@lumiere/shared/services/logger';
import VideoInsightsVideoAIFacesOutput from '../VideoInsightsVideoAIFacesOutput.vue';
export default Vue.extend({
    name: 'VideoInsightExternalData',
    props: {
        video: {
            type: Object,
            required: true,
        },
        feature: {
            type: Object,
            required: true,
        },
        showFacesOutput: Boolean,
    },
    data() {
        return {
            totalInteractions: null,
            loading: false,
            insightData: null,
            filterQuery: '',
            filterAction: '',
            aggregations: null,
            filtering: false,
            zoomMinMax: undefined,
            commentCountMouseAction: { index: 0, event: '' },
            timelineInsightData: null,
            timelineTotalInteractions: null,
            sentimentsLoading: false,
            sentimentsFiltering: false,
            customBucketSize: null,
            filterRatingsMinMax: undefined,
            filterWasChanged: false,
            filterEmotions: undefined,
        };
    },
    methods: {
        async loadResponse(from = 0, filterTerm = '', isFetchingVerbatim = false, minMax, ratingsRange, includedEmotions, partialLoading, size = PER_LOAD_SIZE, skipCommentFilterEvent) {
            if (this.loading)
                return;
            if (filterTerm || isFetchingVerbatim) {
                this.loading = false;
                if (!partialLoading) {
                    this.filtering = true;
                }
            }
            else {
                this.loading = true;
            }
            const { start, stop } = this.triggerTime;
            minMax = { min: start, max: stop, ...minMax };
            const ratings_range = { min: 0, max: 10, ...ratingsRange };
            const _filterEmotions = includedEmotions || this.filterEmotions || [];
            const negative = _filterEmotions.includes(Emotions.NEGATIVE) ? 1 : 0;
            const neutral = _filterEmotions.includes(Emotions.NEUTRAL) ? 1 : 0;
            const positive = _filterEmotions.includes(Emotions.POSITIVE) ? 1 : 0;
            const emotions = [negative, neutral, positive];
            this.$emit('comment-filter', {
                filterTerm,
                ...minMax,
                ...(!skipCommentFilterEvent && {
                    rMin: ratings_range.min,
                    rMax: ratings_range.max,
                    ems: `${negative},${neutral},${positive}`,
                }),
                vctime: null,
                commentId: null,
            });
            try {
                const cid = getBrowserQueryParam('cid');
                const response = await adminAPI((api) => api.video.insights.getVideoExternalDataInsights({
                    vid: this.video.id,
                    fid: this.feature.id,
                    cid,
                    filterTerm,
                    ratingsRange: ratings_range,
                    emotions,
                    minMax,
                    from,
                    size,
                    customBucketSize: this.customBucketSize,
                    standalone_video_filter: getBrowserQueryParam('standalone_video_filter'),
                }));
                if (filterTerm || from === 0) {
                    this.insightData = null;
                }
                let insightData = [...(this.insightData ?? [])];
                let hits = [...(response.hits?.hits ?? [])];
                this.insightData = [...insightData, ...hits];
                this.aggregations = response.aggregations;
                this.totalInteractions = response.hits.total.value;
            }
            catch (e) {
                // do something about the error
                logger.error('loadResponse', e);
            }
            finally {
                this.loading = false;
                this.filtering = false;
            }
            // eslint-disable-next-line @typescript-eslint/no-floating-promises
            this.loadSentimentsResponse(from, filterTerm, isFetchingVerbatim, minMax, ratings_range, emotions, partialLoading, size);
        },
        async loadSentimentsResponse(from = 0, filterTerm = '', isFetchingVerbatim = false, minMax, ratingsRange, includedEmotions, partialLoading, size = PER_LOAD_SIZE) {
            if (this.sentimentsLoading)
                return;
            if (filterTerm || isFetchingVerbatim) {
                this.sentimentsLoading = false;
                if (!partialLoading) {
                    this.sentimentsFiltering = true;
                }
            }
            else {
                this.sentimentsLoading = true;
            }
            const { start, stop } = this.triggerTime;
            minMax = { min: start, max: stop, ...minMax };
            const ratings_range = { min: 0, max: 10, ...ratingsRange };
            const emotions = includedEmotions || [];
            try {
                const cid = getBrowserQueryParam('cid');
                const response = await adminAPI((api) => api.video.insights.getVideoExternalDataInsightsSentimentData({
                    vid: this.video.id,
                    fid: this.feature.id,
                    cid,
                    filterTerm,
                    ratingsRange: ratings_range,
                    emotions,
                    minMax,
                    from,
                    size,
                    standalone_video_filter: getBrowserQueryParam('standalone_video_filter'),
                }));
                if (filterTerm || from === 0) {
                    this.timelineInsightData = null;
                }
                let timelineInsightData = [...(this.timelineInsightData ?? [])];
                let timeline_hits = [
                    ...(response.timeline_hits?.hits ?? []),
                ];
                this.timelineInsightData = [...timelineInsightData, ...timeline_hits];
                this.timelineTotalInteractions = response.timeline_hits.total.value;
            }
            catch (e) {
                // do something about the error
                logger.error('loadSentimentsResponse', e);
            }
            finally {
                this.sentimentsLoading = false;
                this.sentimentsFiltering = false;
            }
            return Promise.resolve(true);
        },
        iconType(emotion) {
            switch (emotion) {
                case Emotions.POSITIVE:
                    return 'mdi-emoticon-happy';
                case Emotions.NEGATIVE:
                    return 'mdi-emoticon-sad';
                default:
                    return 'mdi-emoticon-neutral';
            }
        },
        emotionColor(emotion) {
            return emotionColorHelper(emotion);
        },
        mouseOverEmojiAtIndex(emotion) {
            const color = this.emotionColor(emotion);
            const index = colors.indexOf(color);
            this.commentCountMouseAction = { index, event: MouseEvent.MOUSEOVER };
        },
        mouseLeaveEmojiAtIndex(emotion) {
            const color = this.emotionColor(emotion);
            const index = colors.indexOf(color);
            this.commentCountMouseAction = { index, event: MouseEvent.MOUSELEAVE };
        },
        async filterTermActivated(data) {
            this.filterAction = data.action;
            this.filterQuery = data.query;
            // this.zoomMinMax = {
            //   min: this.triggerTime.start,
            //   max: this.triggerTime.stop,
            // }
            await this.loadResponse(0, data.query, true, this.zoomMinMax, this.filterRatingsMinMax, this.filterEmotions);
        },
        async chartZoomActivated(minMax) {
            this.filterAction = FilterActions.ZOOM;
            await this.loadResponse(0, this.filterQuery, true, minMax, this.filterRatingsMinMax, this.filterEmotions);
            // .then(() => {
            //   this.zoomMinMax = minMax
            // })
            this.zoomMinMax = minMax;
        },
        handleRouteQueryParam() {
            const { filterTerm, min, max, rMin, rMax, ems } = this.$route.query;
            const minMax = {};
            const filterEmotions = [];
            const ratingsMinMax = { min: 0, max: 10 };
            if (hasNumberEl(min)) {
                minMax.min = +min;
            }
            if (hasNumberEl(max)) {
                minMax.max = +max;
            }
            if (hasNumberEl(rMin)) {
                ratingsMinMax.min = +rMin;
                this.filterWasChanged = true;
            }
            if (hasNumberEl(rMax)) {
                ratingsMinMax.max = +rMax;
                this.filterWasChanged = true;
            }
            if (ems) {
                this.filterWasChanged = true;
                const emsArr = ems.split(',');
                if (+emsArr[0] === 1) {
                    filterEmotions.push(Emotions.NEGATIVE);
                }
                if (+emsArr[1] === 1) {
                    filterEmotions.push(Emotions.NEUTRAL);
                }
                if (+emsArr[2] === 1) {
                    filterEmotions.push(Emotions.POSITIVE);
                }
            }
            else {
                filterEmotions.push(Emotions.NEGATIVE, Emotions.NEUTRAL, Emotions.POSITIVE);
            }
            this.zoomMinMax = minMax;
            this.filterRatingsMinMax = ratingsMinMax;
            this.filterEmotions = filterEmotions;
            if (filterTerm) {
                this.filterAction = FilterActions.SEARCH;
                this.filterQuery = filterTerm.toString();
            }
            else {
                void this.loadResponse(0, '', false, minMax, ratingsMinMax, filterEmotions, false, PER_LOAD_SIZE, true);
            }
        },
        onBucketSizeChanged(size) {
            this.customBucketSize = size;
            void this.loadResponse(0, this.filterQuery, true, this.zoomMinMax, this.filterRatingsMinMax, this.filterEmotions);
        },
        onFilterChanged({ emotions, ratingsMinMax }) {
            this.filterEmotions = emotions;
            this.filterRatingsMinMax = ratingsMinMax;
            this.filterWasChanged = true;
            // eslint-disable-next-line @typescript-eslint/no-floating-promises
            this.loadResponse(0, this.filterQuery, true, this.zoomMinMax, this.filterRatingsMinMax, this.filterEmotions);
        },
    },
    computed: {
        emotions() {
            return Emotions;
        },
        sentimentsEmotions() {
            if (!this.insightData)
                return {};
            let emotions = {};
            let emotionType;
            for (let ems of this.insightData) {
                const tempSentiment = ems._source.data.payload.comment
                    .sentiment;
                let sentiment;
                if (Array.isArray(tempSentiment)) {
                    sentiment = tempSentiment[0];
                }
                else {
                    sentiment = tempSentiment;
                }
                if (sentiment?.documentSentiment) {
                    emotionType = sentimentInterpretation(sentiment.documentSentiment);
                    if (!emotions[emotionType]) {
                        emotions[emotionType] = [];
                    }
                    emotions[emotionType].push(ems);
                }
                else {
                    if (!emotions['INDETERMINATE']) {
                        emotions['INDETERMINATE'] = [];
                    }
                    emotions['INDETERMINATE'].push(ems);
                }
            }
            for (let ems in Emotions) {
                if (!emotions[ems]) {
                    emotions[ems] = [];
                }
            }
            return emotions;
        },
        sentimentsEmotionsArray() {
            if (isEmpty(this.sentimentsEmotions))
                return [];
            let sentimentsEmotionsArray = [];
            for (let emotion in this.sentimentsEmotions) {
                for (let insightData of this.sentimentsEmotions[emotion]) {
                    sentimentsEmotionsArray.push({
                        ...insightData._source.data,
                        _id: insightData._id,
                        emotion: emotion,
                        color: this.emotionColor(emotion),
                    });
                }
            }
            let sortedEmotionsArray = sentimentsEmotionsArray.sort((a, b) => {
                return +a?.payload.videoTime - +b?.payload.videoTime;
            });
            return sortedEmotionsArray;
        },
        sentiment_count() {
            return this.aggregations?.sentiment_count?.buckets ?? {};
        },
        comment_count() {
            return this.aggregations?.comment_count?.buckets ?? {};
        },
        inAction() {
            return (this.loading ||
                this.filterAction === FilterActions.ZOOM ||
                !!this.filterQuery);
        },
        commentHasRatings() {
            return isNumber(this.aggregations?.average_rating.value ?? null);
        },
        average_rating() {
            return this.aggregations?.average_rating.value ?? null;
        },
        triggerTime() {
            const { triggers = [], stoppers = [] } = this.feature;
            const videoDuration = this.video.duration;
            let startTime = videoDuration;
            let stopTime = 0;
            triggers.forEach((t) => {
                if ((t.parameters?.time ?? 0) < startTime) {
                    startTime = t.parameters?.time ?? 0;
                }
            });
            stoppers.forEach((t) => {
                if ((t.parameters?.time ?? videoDuration) > stopTime) {
                    stopTime = t.parameters?.time ?? videoDuration;
                }
            });
            return {
                start: startTime,
                stop: stopTime,
            };
        },
        timelineSentimentsEmotions() {
            if (!this.timelineInsightData)
                return {};
            let emotions = {};
            let emotionType;
            for (const ems of this.timelineInsightData) {
                const tempSentiment = ems._source.data.payload.comment
                    .sentiment;
                let sentiment;
                if (Array.isArray(tempSentiment)) {
                    sentiment = tempSentiment[0];
                }
                else {
                    sentiment = tempSentiment;
                }
                if (sentiment?.documentSentiment) {
                    emotionType = sentimentInterpretation(sentiment.documentSentiment);
                    if (!emotions[emotionType]) {
                        emotions[emotionType] = [];
                    }
                    emotions[emotionType].push(ems);
                }
                else {
                    if (!emotions['INDETERMINATE']) {
                        emotions['INDETERMINATE'] = [];
                    }
                    emotions['INDETERMINATE'].push(ems);
                }
            }
            for (let ems in Emotions) {
                if (!emotions[ems]) {
                    emotions[ems] = [];
                }
            }
            return emotions;
        },
        timelineSentimentsEmotionsArray() {
            if (isEmpty(this.timelineSentimentsEmotions))
                return [];
            let timelineSentimentsEmotionsArray = [];
            for (let emotion in this.timelineSentimentsEmotions) {
                for (let insightData of this.timelineSentimentsEmotions[emotion]) {
                    timelineSentimentsEmotionsArray.push({
                        ...insightData._source.data,
                        _id: insightData._id,
                        emotion: emotion,
                        color: this.emotionColor(emotion),
                    });
                }
            }
            const sortedEmotionsArray = timelineSentimentsEmotionsArray.sort((a, b) => {
                return +a?.payload.videoTime - +b?.payload.videoTime;
            });
            return sortedEmotionsArray;
        },
        hasVideoTime() {
            return !!this.feature.options.videoTime;
        },
        CommentTimeProp() {
            return CommentTimeProp;
        },
    },
    mounted() {
        this.handleRouteQueryParam();
    },
    watch: {
        'feature': {
            async handler(newVal, oldVal) {
                if (!isEqual(newVal, oldVal)) {
                    this.filterQuery = '';
                    this.filterAction = '';
                    this.customBucketSize = null;
                    this.zoomMinMax = undefined;
                    await this.loadResponse(0, '');
                }
            },
        },
        'video.eids': {
            handler(eids, oldEids) {
                if (!isEqual(eids, oldEids)) {
                    // refresh the data with the new eids video filter
                    /* eslint-disable @typescript-eslint/no-floating-promises */
                    this.$sleep(1000).then(() => {
                        this.loadResponse(0, '');
                    });
                    /* eslint-enable @typescript-eslint/no-floating-promises */
                }
            },
        },
        '$route.query.cid': {
            handler(c, oc) {
                if (c || oc)
                    void this.loadResponse(0, '');
            },
        },
        '$route.query.standalone_video_filter': {
            handler(v, ov) {
                if (v || ov)
                    void this.loadResponse(0, '');
            },
        },
    },
    components: {
        VideoInsightSentimentTimeline,
        VideoInsightExternalDataTrend,
        VideoInsightEntitiesWordCloud,
        VideoInsightSentimentEmotionsStackedBarChart,
        VideoInsightsVideoAIFacesOutput,
        VideoInsightItemsCounts,
    },
});
