import Vue from 'vue';
import { Routes } from '@/router/router';
export default Vue.extend({
    name: 'ChannelSettingsDeleteChannelBtn',
    props: {
        channel: {
            type: Object,
            required: true,
        },
    },
    methods: {
        deleteChannel() {
            this.$confirm(`Delete '${this.channel.title}'?`, {
                buttonTrueText: 'Delete',
                buttonFalseText: 'Dismiss',
            }).then(async (ok) => {
                if (ok) {
                    await this.$db.channel(this.channel.id).softDelete();
                    this.$router.push({
                        name: Routes.Channels,
                        params: { workspaceId: this.$store.getters.currentWorkspace.id },
                    });
                }
            });
        },
    },
});
