import Vue from 'vue';
import { EmojiPosition } from '@lumiere/db/types';
export default Vue.extend({
    name: 'EmojiPromptAndPosition',
    props: {
        prompt: {
            type: String,
        },
        position: {
            type: String,
            default: EmojiPosition.Center,
        },
    },
    data() {
        return {
            prompt_: this.prompt,
            position_: this.position,
        };
    },
    methods: {
        updatePosition(position) {
            this.position_ = position;
            this.$emit('input', { prompt: this.prompt_, position });
        },
        updatePrompt(prompt) {
            this.prompt_ = prompt;
            this.$emit('input', { prompt, position: this.position_ });
        },
    },
    computed: {
        EmojiPosition: () => EmojiPosition,
        rules() {
            return {
                maxlength: (value) => (!!value && value.length < 50) || 'Use short descriptive prompt',
            };
        },
    },
    components: {},
});
