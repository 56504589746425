var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:[
    'text-prompt-feedback-container',
    { hide: _vm.hide },
    ("text-prompt-feedback-container__" + _vm.size),
    _vm.scaleFeaturesTofit ]},_vm._l((_vm.textPromptFeatures),function(ref){
  var id = ref.id;
  var options = ref.options;
return _c('TextPromptFeedback',_vm._b({key:id,attrs:{"size":_vm.size}},'TextPromptFeedback',options,false))}),1)}
var staticRenderFns = []

export { render, staticRenderFns }