import Vue from 'vue';
import PersonContentItem from '@/components/common/PersonContentItem.vue';
import adminAPI from '@/services/adminAPI';
export default Vue.extend({
    name: 'VideoIntelligenceManagePersons',
    props: {
        persons: { type: Array, required: true },
        videoId: { type: String, required: true },
        videoDuration: { type: Number, required: true },
        videoScenes: {
            type: Object,
        },
    },
    data() {
        return {
            dialog: false,
            selectedPersons: [],
            merging: false,
            renderPersonCount: 10,
            unmerging: false,
            dialogHeight: window.innerHeight - 100,
        };
    },
    computed: {
        sortedPersons() {
            return [...this.persons].sort((a, b) => b.seenDuration - a.seenDuration);
        },
    },
    methods: {
        async mergeSelectedPersons() {
            if (this.selectedPersons.length > 1) {
                this.merging = true;
                const isOverlapping = this.selectionHasOverlaps();
                if (isOverlapping) {
                    const shouldContinue = await this.$confirm(`These people have overlapping detections. Are you sure you want to merge them?`, {
                        buttonTrueText: 'Yes, continue',
                        buttonFalseText: 'Oops, dismiss',
                    });
                    if (!shouldContinue) {
                        this.merging = false;
                        this.selectedPersons = [];
                        return;
                    }
                }
                await adminAPI(async (api) => api.video.videoIntelligence.videoIntelligenceMergePersons({
                    vid: this.videoId,
                    personsIds: this.selectedPersons.map((p) => p.id),
                })).finally(() => {
                    this.merging = false;
                    this.selectedPersons = [];
                });
            }
            else {
                this.$confirm('You have to check more than one persons to merge', {
                    buttonTrueText: 'Dismiss',
                    buttonFalseText: '',
                });
            }
        },
        async unmergePersons(pid) {
            const shouldUnmerge = await this.$confirm(`Unmerging people is not reversible. Do you want to continue?`, {
                buttonTrueText: 'Continue',
                buttonFalseText: 'Dismiss',
            });
            if (shouldUnmerge) {
                this.unmerging = true;
                await adminAPI(async (api) => api.video.videoIntelligence.videoIntelligenceUnmergePersons({
                    vid: this.videoId,
                    pid,
                })).finally(() => {
                    this.unmerging = false;
                    this.selectedPersons = [];
                });
            }
        },
        triggerIntersect(entries) {
            if (entries[0].isIntersecting) {
                this.renderPersonCount += 25;
            }
        },
        selectionHasOverlaps() {
            const selPersons = this.selectedPersons;
            let isOverlapping = false;
            for (let i = 0; i < selPersons.length; i++) {
                for (let j = 1; j < selPersons.length; j++) {
                    if (i === j)
                        continue;
                    isOverlapping = this.checkOverlap(selPersons[i], selPersons[j]);
                    if (isOverlapping)
                        break;
                }
                if (isOverlapping)
                    break;
            }
            return isOverlapping;
        },
        checkOverlap(p1, p2) {
            let isOverlapping = false;
            for (const p1_inst of p1.instances) {
                for (const p2_inst of p2.instances) {
                    if (p2_inst.start - p1_inst.start >= 0 &&
                        p1_inst.end - p2_inst.start > 0) {
                        isOverlapping = true;
                    }
                    if (isOverlapping)
                        break;
                }
                if (isOverlapping)
                    break;
            }
            return isOverlapping;
        },
        getContainerSize() {
            // const dialogContainer = this.$refs.managePersonContainer as HTMLElement
            // // @ts-ignore
            // const dialogRef = dialogContainer?.$refs
            // if (dialogRef?.dialog) {
            //   const { clientHeight } = dialogRef.dialog
            //   if (clientHeight) {
            //     this.dialogHeight = clientHeight - 75
            //   }
            // }
            this.dialogHeight = window.innerHeight - 100;
        },
    },
    mounted() {
        this.getContainerSize();
        window.addEventListener('resize', this.getContainerSize, {
            passive: true,
        });
    },
    watch: {
        dialog(value) {
            if (!value) {
                this.renderPersonCount = 10;
            }
        },
    },
    components: {
        PersonContentItem,
    },
    beforeDestroy() {
        window.removeEventListener('resize', this.getContainerSize);
    },
});
