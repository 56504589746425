var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-app',[_c('v-sheet',{staticClass:"color-sheet",attrs:{"color":_vm.color}},[(!_vm.color)?_c('GradientBG'):_vm._e()],1),_c('v-container',{staticClass:"app-layout",attrs:{"py-md-5":"","px-md-6":"","fluid":""}},[_c('v-row',{staticClass:"app-layout__navbar",attrs:{"align":"center"}},[(!_vm.showOnlyNavbarSearch)?_c('v-col',{attrs:{"cols":_vm.$vuetify.breakpoint.smAndDown ? 3 : 2}},[_c('router-link',{attrs:{"to":_vm.canNavToVideos
              ? {
                  name: _vm.Routes.Videos,
                  params: { workspaceId: _vm.getLastWorkspaceId() },
                }
              : {
                  name: _vm.Routes.Landing,
                }}},[_c('Logo',{attrs:{"white":"","height":"32"}})],1)],1):_vm._e(),_c('v-col',[_c('div',{staticClass:"d-flex align-center"},[(!_vm.showOnlyNavbarSearch)?_vm._t("navbar"):_vm._e(),_vm._t("navbar-search",[_c('GlobalSearchbar',{staticClass:"px-0",on:{"xs-screen-search-prompt-button-clicked":function () { return (_vm.showOnlyNavbarSearch = true); },"show-other-navbar-items":function () { return (_vm.showOnlyNavbarSearch = false); }}})]),(!_vm.showOnlyNavbarSearch)?_vm._t("navbar-menu",[(_vm.$store.getters.isAuthenticated)?_c('UserMenu',{attrs:{"dark":""}}):_vm._e()]):_vm._e()],2)])],1),(_vm.$slots.toolbar)?_c('v-row',[_c('v-col',[_vm._t("toolbar")],2)],1):_vm._e(),(_vm.$slots.container)?_vm._t("container"):_c('LayoutTwoColumns',{scopedSlots:_vm._u([(_vm.$slots.aside)?{key:"aside",fn:function(){return [_vm._t("aside")]},proxy:true}:null,{key:"main",fn:function(){return [_vm._t("main")]},proxy:true}],null,true)})],2),_vm._t("default")],2)}
var staticRenderFns = []

export { render, staticRenderFns }