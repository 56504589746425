import Vue from 'vue';
import * as am4core from '@amcharts/amcharts4/core';
import cloneDeep from 'lodash/cloneDeep';
export default Vue.extend({
    name: 'VideoInsightsChartExportMenu',
    props: {
        chart: Object,
        chart2: Object,
        chartTitle: {
            type: String,
            default: '',
        },
    },
    data() {
        return {
            exportMenu: false,
            loading: false,
        };
    },
    methods: {
        setExportConfig(chart) {
            const o = cloneDeep(chart);
            o.exporting.filePrefix = `Chart Export - ${new Date().toISOString()}`;
            const list = new am4core.List(['date', 'date0', 'date1', 'date*']);
            o.exporting.dateFields = list;
            o.exporting.dateFormat = 'yyyy-MM-dd:hh:mm:s';
            o.exporting.useWebFonts = false;
            // o.exporting.dataFields = {}
            o.exporting.title = this.chartTitle;
            const types = [
                'png',
                'jpg',
                'svg',
                'pdf',
                'csv',
                'xlsx',
                'json',
            ];
            types.forEach((type) => {
                let options = o.exporting.getFormatOptions(type);
                if (type === 'json') {
                    // @ts-ignore
                    options.useTimestamps = true;
                }
                o.exporting.setFormatOptions(type, options);
            });
            return o;
        },
        async exportItem(type) {
            if (this.chart) {
                const o = this.setExportConfig(this.chart);
                await o.exporting.export(type);
                if (['xlsx', 'csv', 'json'].includes(type)) {
                    this.exportItem2(type);
                }
            }
        },
        exportItem2(type) {
            if (this.chart2) {
                const o = this.setExportConfig(this.chart2);
                o.exporting.export(type);
            }
        },
    },
});
