import Vue from 'vue';
import { switchMap, map, pluck, shareReplay, startWith } from 'rxjs/operators';
import adminAPI from '@/services/adminAPI';
import debounce from 'lodash/debounce';
import isEmpty from 'lodash/isEmpty';
import PersonContentItem from '@/components/common/PersonContentItem.vue';
import publicDB from '@/utils/publicDB';
export default Vue.extend({
    name: 'VideoInsightsVideoAIFacesOutput',
    props: {
        videoId: String,
        videoDuration: { type: Number, required: true },
        videoScenes: {
            type: Object,
        },
        showSkeleton: Boolean,
        trigger: {
            type: Object,
        },
        zoomMinMax: {
            type: Object,
        },
    },
    data() {
        return {
            persons: null,
            facesMap: {},
        };
    },
    computed: {
        sortedPersons() {
            const { min, max } = this.zoomRange;
            return this.persons
                ? [...this.persons]
                    .map((p) => {
                    const k = p.instances
                        .filter(({ start, end }) => (start - min >= 0 && max - start > 0) ||
                        (end - min > 0 && max - end >= 0))
                        .map(({ start, end, duration }) => {
                        // compute relative duration
                        if (start - min >= 0 &&
                            max - start > 0 &&
                            end - min > 0 &&
                            max - end >= 0) {
                            return { start, end, duration };
                        }
                        else if (start - min >= 0 && max - start > 0) {
                            return { start, end: max, duration: max - start };
                        }
                        else {
                            return { start: min, end, duration: end - min };
                        }
                    });
                    const total = k.reduce((acc, cur) => acc + (cur.duration || 0), 0);
                    const seenDuration = +total.toFixed(4);
                    const seenDurationRatio = +(total / this.duration).toFixed(4);
                    return { ...p, instances: k, seenDuration, seenDurationRatio };
                })
                    .filter((p) => !!p.instances.length)
                    .sort((a, b) => b.seenDuration - a.seenDuration)
                : [];
        },
        comparablePersonsTo() {
            return (p_) => this.persons ? this.persons.filter((p) => p.id !== p_.id) : [];
        },
        triggerRange() {
            if (!this.trigger)
                return { min: 0, max: this.videoDuration };
            const { start, end } = this.trigger;
            return { min: start, max: end };
        },
        zoomRange() {
            const range = this.triggerRange;
            if (!this.zoomMinMax)
                return range;
            const { min = range.min, max = range.max } = this.zoomMinMax;
            return { min, max };
        },
        duration() {
            const { min, max } = this.zoomRange;
            return max - min;
        },
        isPublic() {
            return !!this.$route.query.link;
        },
    },
    methods: {
        async getFaceDetections() {
            const facesMap = await adminAPI((api) => api.video.videoIntelligence.getVideoIntelligenceFaceDetections({
                vid: this.videoId,
            }));
            this.facesMap = facesMap;
        },
        fetchVideoAIData: debounce(function () {
            this.getFaceDetections();
        }, 500),
    },
    mounted() {
        this.fetchVideoAIData();
    },
    watch: {
        facesMap: {
            handler(facesMap) {
                if (this.persons?.length && isEmpty(facesMap)) {
                    this.fetchVideoAIData();
                }
            },
        },
        persons: {
            handler(persons) {
                if (persons?.length && isEmpty(this.facesMap)) {
                    this.fetchVideoAIData();
                }
            },
        },
    },
    events: {
        resetVideoFacesFilter() { },
    },
    subscriptions() {
        const facesMap$ = this.$watchAsObservable('facesMap', {
            immediate: true,
            deep: true,
        }).pipe(pluck('newValue'));
        const { isPublic } = this;
        const database = isPublic ? publicDB() : this.$db;
        return {
            persons: facesMap$.pipe(switchMap((facesMap) => database
                .video(this.$props.videoId)
                .persons()
                .observe()
                .pipe(map((persons) => {
                return persons.map((person) => {
                    const newPerson = { ...person };
                    newPerson['instances'] = person.faceIds.flatMap((fid) => facesMap[fid]?.instances ?? []);
                    return newPerson;
                });
            }))), shareReplay(1), startWith(null)),
        };
    },
    components: {
        PersonContentItem,
    },
});
