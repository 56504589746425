import Vue from 'vue';
import { Role } from '@lumiere/db/types';
import isEmpty from 'lodash/isEmpty';
export default Vue.extend({
    name: 'AddMemberForm',
    props: {
        value: String,
        searchList: {
            type: Array,
        },
        roles: Array,
        themeColor: String,
        errors: Array,
    },
    data() {
        return {
            email: this.$props.value,
            selectedUsers: {},
            role: Role.EDITOR,
        };
    },
    computed: {
        canSubmit() {
            return !isEmpty(this.selectedUsers);
        },
        isEmpty() {
            return isEmpty;
        },
    },
    methods: {
        submitForm() {
            this.$emit('submit-form', this.selectedUsers);
        },
        selectUser(user) {
            this.email = '';
            const selectedUsers = { ...this.selectedUsers };
            selectedUsers[user.id] = { ...user, role: this.role };
            this.selectedUsers = selectedUsers;
        },
        removeUser(user) {
            const selectedUsers = { ...this.selectedUsers };
            delete selectedUsers[user.id];
            this.selectedUsers = selectedUsers;
        },
        addUserRole(id, role) {
            const selectedUser = {
                ...this.selectedUsers[id],
                role,
            };
            this.selectedUsers = { ...this.selectedUsers, [id]: selectedUser };
        },
    },
    watch: {
        email(val) {
            this.$emit('input', val);
        },
    },
});
