import Vue from 'vue';
import VideoPlayerAdminTimePicker from '../../player/VideoPlayerAdminTimePicker.vue';
import VideoRangeSelectionPreview from '@/components/common/VideoRangeSelectionPreview.vue';
import TimePickerComponent from '@/components/common/TimePickerComponent.vue';
import { VideoTriggerEvent, } from '@lumiere/db/types';
export default Vue.extend({
    name: 'GenericFeatureTriggers',
    props: {
        video: {
            type: Object,
        },
        triggers: {
            type: Array,
        },
        stoppers: {
            type: Array,
        },
    },
    data() {
        return {
            specificStartTime: 0,
            specificStopTime: 0,
            rangeMinSafe: 0,
            rangeMaxSafe: 0,
            rangeMinPicker: 0,
            rangeMaxPicker: 0,
        };
    },
    computed: {
        rangeFromScrubber() {
            return `${this.specificStartTime}:${this.specificStopTime}`;
        },
        rangeFromPickers() {
            return `${this.rangeMinPicker}:${this.rangeMaxPicker}`;
        },
    },
    methods: {
        dispatchInput() {
            const triggers = this.triggers;
            const stoppers = this.stoppers;
            triggers[0].event = VideoTriggerEvent.VideoTime;
            triggers[0].parameters = { time: this.specificStartTime };
            stoppers[0].event = VideoTriggerEvent.VideoTime;
            stoppers[0].parameters = { time: this.specificStopTime };
            const featureTriggers = {
                triggers,
                stoppers,
            };
            this.$emit('dispatch-input', featureTriggers);
        },
        /**
         * Some mux images are not renderable at boundary
         */
        onImgError(_err, whatImg) {
            switch (whatImg) {
                case 'img-1':
                    this.specificStartTime += 0.1;
                    break;
                default:
                    this.specificStopTime -= 1;
                    break;
            }
        },
        onSelectRange(range) {
            const { min, max } = range;
            this.specificStartTime = +min;
            this.specificStopTime = +max;
            this.dispatchInput();
        },
    },
    mounted() {
        // eslint-disable-next-line @typescript-eslint/no-floating-promises
        this.$sleep(0).then(() => {
            const playerRef = this.$refs.videoPlayer;
            if (playerRef) {
                playerRef.pause();
            }
        });
    },
    watch: {
        video: {
            handler(val) {
                this.specificStartTime = this.triggers[0].parameters?.time ?? 0;
                // X() was getting an error displaying the thumbnail for a large video at duration
                this.specificStopTime =
                    this.stoppers[0].parameters?.time ?? val.duration - 0.25; // <-- X =: (1/4 of a sec)
                this.dispatchInput();
            },
            immediate: true,
        },
        rangeFromScrubber: {
            handler(newVal, oldVal) {
                if (newVal != oldVal) {
                    const delta = this.specificStopTime - this.specificStartTime;
                    if (delta > 0) {
                        this.rangeMinSafe = this.specificStartTime;
                        this.rangeMaxSafe = this.specificStopTime;
                        this.rangeMinPicker = this.specificStartTime;
                        this.rangeMaxPicker = this.specificStopTime;
                    }
                }
            },
            immediate: true,
        },
        rangeFromPickers: {
            handler(newVal, oldVal) {
                if (newVal != oldVal) {
                    const delta = this.rangeMaxPicker - this.rangeMinPicker;
                    if (delta > 0) {
                        this.rangeMinSafe = this.rangeMinPicker;
                        this.rangeMaxSafe = this.rangeMaxPicker;
                        this.specificStartTime = this.rangeMinPicker;
                        this.specificStopTime = this.rangeMaxPicker;
                    }
                }
            },
            immediate: true,
        },
    },
    components: {
        VideoPlayerAdminTimePicker,
        VideoRangeSelectionPreview,
        TimePickerComponent,
    },
});
